import * as Edge from '../../../core';

export abstract class AlignmentHelper {
	public static getScoreText(results: Pick<Edge.Models.AlignmentExerciseResult, 'horizontalAlignment'>) {
		const { horizontalAlignment } = results;
		if (horizontalAlignment === 0) {
			return '0';
		}
		return Math.abs(horizontalAlignment) + (horizontalAlignment > 0 ? 'B' : 'A');
	}
}
