import { applyMiddleware, compose, combineReducers, Middleware, Store, createStore as reduxCreateStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import loginReducer from './login/reducers';
import organizationReducer from './organization/reducers';
import teamReducer from './team/reducers';
import profileReducer from './profile/reducers';
import sessionReducer from './session/reducers';
import deviceProfileReducer from './deviceProfile/reducers';
import timeSpanReducer from './timeSpan/reducers';
import {
	levelReducer,
	sportReducer,
	positionReducer,
	publicOrganizationTypeReducer,
	allOrganizationTypeReducer,
	teamTypeReducer,
	teamRoleReducer,
	organizationRoleReducer,
	priceReducer,
} from './staticData';
import permissionReducer from './permissions/reducers';
import apiKeyReducer from './apiKey/reducers';

const rootReducer = combineReducers({
	loginState: loginReducer,
	apiKeyState: apiKeyReducer,
	profileState: profileReducer,
	organizationState: organizationReducer,
	teamState: teamReducer,
	levelState: levelReducer,
	sportState: sportReducer,
	positionState: positionReducer,
	publicOrganizationTypeState: publicOrganizationTypeReducer,
	allOrganizationTypeState: allOrganizationTypeReducer,
	teamTypeState: teamTypeReducer,
	sessionState: sessionReducer,
	deviceProfileState: deviceProfileReducer,
	teamRoleState: teamRoleReducer,
	organizationRoleState: organizationRoleReducer,
	permissionState: permissionReducer,
	priceState: priceReducer,
	timeSpanState: timeSpanReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const getPreloadedState: () => object = () => {
	return {};
};

export const CreateStore = (preloadedState?: Partial<AppState>): Store<AppState> => {
	const middlewares: Middleware[] = [thunkMiddleware];

	const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const enhancer: any = composeEnhancers(applyMiddleware(...middlewares));

	return reduxCreateStore(rootReducer, preloadedState || getPreloadedState(), enhancer);
};

export const store = CreateStore();
