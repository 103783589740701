import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import * as Edge from '../../../core';
import { getTeamsUserCanAddProspect } from '../../../store/permissions/selector';
import { AppState } from '../../../store';

import './index.scss';
import Table from '../../../components/table';
import { withLoadDataDefaultConfig } from '../../../components/loadData';
import { StatsService } from '../../../services/statsService';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';

export interface ProspectEvaluationHistoryProps {
	currentTeam: Edge.Models.Team;
	evaluationResults: Edge.Models.ProspectEvaluationResult[];
}

interface ProspectEvaluationHistoryState {}

class ProspectEvaluationHistoryTable extends Table<Edge.Models.ProspectEvaluationResult> {}

const availableSearches: ((item: Edge.Models.ProspectEvaluationResult) => string | undefined)[] = [
	(item) => item.firstName,
	(item) => item.lastName,
];
const availableSorts: {
	[x: string]: (item: Edge.Models.ProspectEvaluationResult) => any;
} = {
	name: (item) => ((item.firstName || '') + ' ' + (item.lastName || '')).toLowerCase(),
	completed: (item) => item.completed,
};
const pageSize = 5;

export class ProspectEvaluationHistory extends React.PureComponent<
	ProspectEvaluationHistoryProps,
	ProspectEvaluationHistoryState
> {
	constructor(props: ProspectEvaluationHistoryProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { evaluationResults } = this.props;
		return (
			<div className="prospect_evaluation_history">
				<div className="title">
					<h4>Prospect Evaluations</h4>
				</div>
				<div className="inner_wrapper">
					<ProspectEvaluationHistoryTable
						className="prospect_evaluation_history_table"
						items={evaluationResults}
						availableSearches={availableSearches}
						availableSorts={availableSorts}
						searchPlaceholderText="Search Prospects"
						initialSort={['!completed']}
						renderHeader={() => <>&nbsp;</>}
						renderTableHeader={(classNames, toggleSort) => {
							return (
								<tr>
									<th className={classNames('name')} onClick={toggleSort.bind(this, 'name')}>
										Name
									</th>
									<th
										className={classNames('completed')}
										onClick={toggleSort.bind(this, 'completed')}
									>
										Evaluation Date
									</th>
								</tr>
							);
						}}
						renderTableItem={(i) => {
							return (
								<tr>
									<td>
										{i.firstName} {i.lastName}
									</td>
									<td>{moment(i.completed).format(Edge.Constants.DATE_FORMAT)}</td>
								</tr>
							);
						}}
						pagingMode="numbers"
						pageSize={pageSize}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: AppState) {
	const { currentTeam } = getTeamsUserCanAddProspect(state);
	return {
		currentTeam: currentTeam!,
	};
}

export default connect(mapStateToProps)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(ProspectEvaluationHistory, (props) => !!props.currentTeam && !!props.evaluationResults),
		(props) => {
			return { teamId: props.currentTeam && props.currentTeam.id };
		},
		async (props) => {
			const evaluationResults = props.teamId && (await StatsService.getProspectEvaluationResults(props.teamId));
			return { evaluationResults };
		}
	)
);
