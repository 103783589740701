import * as Edge from '../core';

export abstract class SessionService {
	public static async getActiveSession(): Promise<Edge.Models.ActiveSessionResponse> {
		const route = Edge.API.ServerRoute.forAction('sessions', 'me/active');
		const [data, responseDateUtc] = await Edge.API.getWithResponseDate<Edge.Models.BaseSessionResponse>(route);
		return {
			active: data,
			responseDateUtc,
			receivedDateUtc: new Date(),
		} as Edge.Models.ActiveSessionResponse;
	}

	public static async getSession(id: string): Promise<Edge.Models.BaseSessionResponse> {
		const route = Edge.API.ServerRoute.forAction('sessions', id);
		return await Edge.API.get(route);
	}

	public static async getAllSessions(userId: string, teamId?: string): Promise<Edge.Models.BaseSessionResponse[]> {
		const route = Edge.API.ServerRoute.forAction('sessions', `all/${userId}` + ((teamId && `/${teamId}`) || ''));
		return await Edge.API.get(route);
	}

	public static async getPrintableSession(id: string, isProCompare?: boolean): Promise<Edge.Models.File> {
		const isProCompareParam = isProCompare !== undefined ? isProCompare : 'false';
		const route = Edge.API.ServerRoute.forAction('sessions', `${id}/print?isProCompare=${isProCompareParam}`);
		return await Edge.API.getFile(route);
	}

	public static async getCredits(teamId: string): Promise<Edge.Models.MyCreditsResponse> {
		const route = Edge.API.ServerRoute.forAction('sessions', `me/credits/team/${teamId}`);
		const [data, responseDateUtc] = await Edge.API.getWithResponseDate<Edge.Models.MyCreditsResponse>(route);
		data.responseDateUtc = responseDateUtc;
		data.receivedDateUtc = new Date();
		return data;
	}

	public static async startSession(
		command: Edge.Models.StartSessionCommand
	): Promise<Edge.Models.ActiveSessionResponse> {
		const route = Edge.API.ServerRoute.forAction('sessions', `me/start`);
		const [data, responseDateUtc] = await Edge.API.postWithResponseDate<Edge.Models.BaseSessionResponse>(
			route,
			command
		);
		return {
			active: data,
			responseDateUtc,
			receivedDateUtc: new Date(),
		} as Edge.Models.ActiveSessionResponse;
	}

	public static async endSession(command: Edge.Models.EndSessionCommand): Promise<Edge.Models.ActiveSessionResponse> {
		const route = Edge.API.ServerRoute.forAction('sessions', `me/end`);
		const [data, responseDateUtc] = await Edge.API.patchWithResponseDate<Edge.Models.BaseSessionResponse>(
			route,
			command
		);
		return {
			active: data,
			responseDateUtc,
			receivedDateUtc: new Date(),
		} as Edge.Models.ActiveSessionResponse;
	}

	public static async addPlanPhase(
		command: Edge.Models.AddPlanPhaseCommand
	): Promise<Edge.Models.ActiveSessionResponse> {
		const route = Edge.API.ServerRoute.forAction('sessions', `me/addPlanPhase`);
		const [data, responseDateUtc] = await Edge.API.patchWithResponseDate<Edge.Models.BaseSessionResponse>(
			route,
			command
		);
		return {
			active: data,
			responseDateUtc,
			receivedDateUtc: new Date(),
		} as Edge.Models.ActiveSessionResponse;
	}

	private static getRouteName(exerciseTypeId: Edge.Models.ExerciseTypeId) {
		return Object.keys(Edge.Models.ExerciseTypeId)[
			Object.values(Edge.Models.ExerciseTypeId).indexOf(exerciseTypeId)
		].toLowerCase();
	}

	public static async createExerciseResult(
		command: Edge.Models.CreateExerciseResultCommand
	): Promise<Edge.Models.CreateExerciseResultCommand> {
		const route = Edge.API.ServerRoute.forAction(
			'exercises',
			`results/${SessionService.getRouteName(command.exerciseTypeId)}`
		);
		return await Edge.API.post(route, command);
	}

	public static async completeExercise(
		command: Edge.Models.CompleteExerciseCommand
	): Promise<Edge.Models.ActiveSessionResponse> {
		const route = Edge.API.ServerRoute.forAction('exercises', `results/complete`);
		const [data, responseDateUtc] = await Edge.API.patchWithResponseDate<Edge.Models.BaseSessionResponse>(
			route,
			command
		);
		return {
			active: data,
			responseDateUtc,
			receivedDateUtc: new Date(),
		} as Edge.Models.ActiveSessionResponse;
	}

	public static async getOrganizationCredits(id: string): Promise<Edge.Models.CreditsResponse> {
		const route = Edge.API.ServerRoute.forAction('sessions', `credits/organizations/${id}`);
		return await Edge.API.get(route);
	}

	public static async getTeamCredits(id: string): Promise<Edge.Models.CreditsResponse> {
		const route = Edge.API.ServerRoute.forAction('sessions', `credits/teams/${id}`);
		return await Edge.API.get(route);
	}

	public static async reassignSessions(command: Edge.Models.ReassignCreditsRequest): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('sessions', `credits/reassign/sessions`);
		return await Edge.API.patch(route, command);
	}

	public static async reassignSubscriptions(command: Edge.Models.ReassignCreditsRequest): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('sessions', `credits/reassign/subscriptions`);
		return await Edge.API.patch(route, command);
	}

	public static async getLatestPurchasePlan(
		entityType: Edge.Models.EntityType,
		entityid: string,
		organizationId: string
	): Promise<Edge.Models.LatestPurchasePlanResponse | undefined> {
		let path = `${entityType}/${entityid}/latestpurchaseplan`;
		if (entityType !== Edge.Models.EntityType.Organization) {
			path += '/' + organizationId;
		}
		const route = Edge.API.ServerRoute.forAction('sessions', path);
		return await Edge.API.get(route);
	}

	public static async updateSession(command: Edge.Models.UpdatePlanCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('sessions', `me/update/sessions`);
		return await Edge.API.post(route, command);
	}

	public static async updateSubscription(command: Edge.Models.UpdatePlanCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('sessions', `me/update/subscription`);
		return await Edge.API.post(route, command);
	}

	public static async updateSubscriptionEndDate(
		command: Edge.Models.UpdateSubscriptionEndDateCommand
	): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('sessions', `me/update/subscriptionDate`);
		return await Edge.API.post(route, command);
	}

	public static async migrateSessionsAndPlans(command: Edge.Models.MigrateSessionsAndPlansCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('sessions', `migrate`);
		return await Edge.API.post(route, {
			sourceUsername: command.prospectUsername,
			destinationUsername: command.newUsername,
		});
	}

	public static async convertProspectUserToAthlete(command: Edge.Models.ConvertUser): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('sessions', `convert`);
		return await Edge.API.post(route, {
			prospectId: command.prospectId,
			destinationUsername: command.newUserName,
			teamId: command.teamId,
		});
	}
}
