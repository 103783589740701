/* eslint-disable */
$( function() {
	
	VizEdge.TrackingExercise = function() {
	    this.initialize.apply(this, arguments);
	};
	
	_.extend( VizEdge.TrackingExercise.prototype, {
    
    arrowImageIDTemplate: _.template( "arrow-<%= direction %>-<%= type %>" ),
    
    answerRecordedLogMessage: _.template( "<%= status %> answer recorded." ),
                
		initialize: function( mode, debugMode, renderScale, touchControls, sounds, configuration, completeExercise ) {
      
      var canvas = document.createElement('canvas');
      canvas.id = "stage";
      canvas.width = $(window).width() - 40;
      canvas.height = $(window).height() - 200;
      canvas.style.display = "inline";

      $(".stage-wrapper").append(canvas);
			paper.setup(canvas);
      this.canvasEl = canvas;
      
      _.bindAll( this, "onKeyDown", "onExitFullScreen" );
      
      // install keyboard handler
      this.acceptingInput = false;
      this.tool = new paper.Tool();      
      this.keyboard = new VizEdge.KeyboardControl({ keydown: this.onKeyDown });
      
      // constants
      this.imageScales = { small: 0.42, medium: 1, large: 1.18 };
      this.roundTimeout = 2000;
      this.roundPause = 250;
      this.sessionTimeBarY = 540 * renderScale;
      this.directions = ["up","left","right","down"];

      // settings
      this.mode = mode;
      this.debugMode = (debugMode != 'true' ) ? false : true;
      this.completeExercise = completeExercise;

      if( this.debugMode ) {
        this.sessionLength = 30 * 1000; // 30 secs        
      } else {
        this.sessionLength = 60 * 1000; // 1 min
      }

      this.fullScreen = false;
      this.imageScale = 'medium';
      this.flashTime = 600;
      this.fixation = false;
      this.renderScale = renderScale;
      this.touchControls = touchControls;
      
      this.imageScales = _.mapObject( this.imageScales, function( imageScale ) { return imageScale * renderScale; });  

      if (configuration) {
        this.imageScale = configuration.imageScale;
        this.flashTime = configuration.flashTime;
        this.fixation = configuration.fixation;

        var sessionDuration = configuration.sessionDuration;
        this.sessionLength = sessionDuration * 1000; // seconds to milliseconds

        this.setFullScreen(configuration.fullScreen);
      }

      // load sound effects
      this.bubbleSound = sounds.bubble;
      this.buzzerSound = sounds.buzzer;
      this.crystalSound = sounds.crystal;
            
      // no sounds in debug mode
      if( this.debugMode ) {
        Howler.mute();
      }

		},
        
    onKeyDown: function( event ) {
      
      switch(event.key) {        
        case 'left':
          this.selectDirection('left');
          return false;

        case 'right':
          this.selectDirection('right');
          return false;
          
        case 'up':
          this.selectDirection('up');
          return false;

        case 'down':
          this.selectDirection('down');
          return false;
          
        default: 
          return true;
        
      }

    },
    
    initTouchControls: function() {
      if( this.touchControls ) {
        
        // bind touch handlers to exercise events
        var handlers = { left: _.bind( this.selectDirection, this, 'left' ),
                         right: _.bind( this.selectDirection, this, 'right' ),
                         up: _.bind( this.selectDirection, this, 'up' ),
                         down: _.bind( this.selectDirection, this, 'down' )
                        };

        this.dPad = new VizEdge.DPadControl( { showOK: false, handlers: handlers, tool: this.tool } );
      }
    },
    
    startSessionTimer: function() {
      
      // set up a timer for the entire session
      this.sessionTimer = window.setTimeout( _.bind( function() {
        this.endRound();
        this.endSession();        
      }, this), this.sessionLength );    

      // render timer
      this.timerLayer = new paper.Layer();
      this.timerLayer.activate();

      var viewBounds = paper.project.view.bounds;
      var maxTimerBars = 60;
      
      var barSpacing = 5;
      var barWidth = 8;
      var barHeight = 20;
      var oneBarWidth = barWidth + barSpacing;
      var totalBarWidth = oneBarWidth * maxTimerBars;
      var xStartOffset = (viewBounds.width - totalBarWidth) / 2;
      var yOffset = viewBounds.height - barHeight - 10;
      
      for( var i=0; i < maxTimerBars; i++ ) {
        var xOffset = (oneBarWidth * i) + xStartOffset;
        var rectangle = new paper.Rectangle(new paper.Point(xOffset, yOffset), new paper.Size(barWidth, barHeight));
        var shape = new paper.Shape.Rectangle(rectangle);
        if( i == 5 ) { 
          // when we reach this bar, time to turn red
          shape.redMarker = true;
        }
        shape.fillColor = 'green';        
      }
      
      var barInterval = this.sessionLength / maxTimerBars;

      // set up a timer to remove the next bar      
      var nextBarDisappears = function nextBarDisappears() {
        if( !this.timerLayer.isEmpty() ) {

          var bar = this.timerLayer.lastChild;
          
          // if we reach the marked bar, turn red
          if( bar.redMarker ) {
            _.each( this.timerLayer.children, function( bar ) {
              bar.fillColor = 'red';              
            }, this );
          }
          
          bar.remove();          
          paper.view.draw();
                    
          // set up next tick
          window.setTimeout( _.bind( nextBarDisappears, this), barInterval );   
        }
      };

      // start ticking
      window.setTimeout( _.bind( nextBarDisappears, this), barInterval );    
    },
    
    selectDirection: function(direction) {
      // Prevents method from executing if exercise is not ready to accept input
      //  regardless of input source.
      if( !this.acceptingInput ) return;

      if( direction == this.rightAnswer ) {
        this.logger(this.answerRecordedLogMessage( { status: "correct" } ));
        this.crystalSound.play();
        this.score++;
      } else {
        this.logger(this.answerRecordedLogMessage( { status: "wrong" } ));
        this.buzzerSound.play();
      }
      
      this.endRound();
      this.startRound();      
    },
    
    startSession: function() {
            
      this.roundCount = 0;
      this.totalResponseTime = 0;
      this.score = 0;

      this.loadImages();
      new VizEdge.ReadyGo( _.bind( function() {
        this.initTouchControls();
        this.startSessionTimer();
        if( this.fixation ) this.renderFixation();
        this.startRound();
      }, this) );
          
    },
    
    isSessionOver: function() {
      return (this.sessionTimer == null);
    },
    
    isRoundOver: function() {
      return (this.roundTimer == null);
    },
            
    endSession: function() {
            
      if( !this.isRoundOver() ) {
        this.endRound();
      }
                  
      if( this.sessionTimer ) {
        window.clearTimeout( this.sessionTimer );	
        this.sessionTimer = null;
      }

      // clean up
      paper.project.clear();
      paper.view.draw();
      this.tool.remove();
      this.keyboard.remove();

      this.setFullScreen(false);

      this.sendScore();
    },
    
    sendScore: function() {
      this.completeExercise({
        exercise: 'tracking',
        evaluation: this.mode == "evaluate",
        duration: Math.round(this.sessionLength/1000),
        audio: true,
        size: this.imageScale,
        flashTime: this.flashTime,
        fixation: this.fixation,
        responseTime: this.calcAverageResponseTime(),
        scorePercent: this.calcScorePercent(),
      });
    },
    
    calcScorePercent: function() {
      if( this.roundCount > 0 ) {
        return (this.score / this.roundCount) * 100.0;
      } else {
        return 0.0;
      }
    },
    
    calcAverageResponseTime: function() {
      if( this.roundCount > 0 ) {
        return this.totalResponseTime / this.roundCount;
      } else {
        return 0.0;
      }
    },
    
    startRound: function() {
      
      // don't start a new round if the session has ended.
      if( this.isSessionOver() ) return;
                  
      var _startRound = _.bind( function _startRound() {
        
        this.roundPauseTimer = null;
        this.displayNextArrow();

        this.roundTimer = window.setTimeout( _.bind( function() {
          this.endRound();
          this.startRound();
        }, this), this.roundTimeout );
  
        this.acceptingInput = true;  
        this.roundStartTime = this.getTime();
        this.roundCount = this.roundCount + 1;

      }, this);

      if( this.roundCount > 0 ) {
        this.roundPauseTimer = window.setTimeout( _startRound, this.roundPause );  
      } else {
        _startRound();
      }
    
    },
    
    loadImages: function() {
      
      this.arrowLayer = new paper.Layer();
            
      // load arrows
      this.arrowImages = {};
      _.each( this.directions, function(direction) {
        var imageID = this.arrowImageIDTemplate({ direction: direction, type: 'q' });
        var arrowImage = new paper.Raster(imageID);
        arrowImage.scale(this.imageScales[this.imageScale]);
        this.arrowImages[imageID] = new paper.Symbol(arrowImage);
      }, this );
    },
    
    onOK: function() {
      
    },
    
    setFullScreen: function(value) {

      if( !BigScreen.enabled ) return;

      this.fullScreen = value;

      if( this.fullScreen ) {
        if (!BigScreen.element) {
          BigScreen.toggle( null, null, this.onExitFullScreen );
        }
      } else {
        if (BigScreen.element) {
          BigScreen.exit();
        }
        this.onExitFullScreen();
      }

    },

    onExitFullScreen: function() {  
    },
        
    getTime: function() {
      var d = new Date();
      return d.getTime();
    },
    
    logger: function( message ) {
      if( this.debugMode ) {
        console.log(message);
      }      
    },
    
    endRound: function() {
      
      // we must be in a round to accept input
      this.acceptingInput = false;

      // accumulate total response time
      var responseTime  = this.getTime() - this.roundStartTime;
      this.totalResponseTime = responseTime + this.totalResponseTime;

      if( this.roundPauseTimer ) {
        window.clearTimeout( this.roundPauseTimer );	
        this.roundPauseTimer = null;
      }
      
      if( this.roundTimer ) {
        window.clearTimeout( this.roundTimer );	
        this.roundTimer = null;
      }
      
    },    
    
    renderFixation: function() {
      
      this.fixationLayer = new paper.Layer();
      this.fixationLayer.activate();
      
      // display a yellow ball at center of screen
      var scaledCenter = new paper.Point( paper.view.center.x * this.renderScale, (paper.view.center.y-50) * this.renderScale );      
      var fixation = new paper.Shape.Circle( scaledCenter, 10 * this.renderScale );
      fixation.style = { fillColor: 'yellow' };
      
    },
    
    pickArrowPosition: function( arrowSize ) {
      // determine where the arrow can be placed and select location (-110 keeps it off timer bar)
      var viewBounds = paper.project.view.bounds;
      var viewSize = new paper.Size( viewBounds.width, viewBounds.height - 110 );

      var xPosition = _.random( arrowSize.width/2, viewSize.width - (arrowSize.width/2) );
      var yPosition = _.random( arrowSize.height/2, viewSize.height - 5 - (arrowSize.height/2) );

      return new paper.Point(xPosition, yPosition);    
    },
    
		displayNextArrow: function() {
      
      this.rightAnswer = this.directions[_.random(0,this.directions.length-1)];    
      var arrowImageID = this.arrowImageIDTemplate( {direction: this.rightAnswer, type: "q"} );
      var arrowImage = this.arrowImages[arrowImageID];
      var arrowSize = new paper.Size(arrowImage.definition.bounds.size.width+10, arrowImage.definition.bounds.size.height+10 );
                  
      this.arrowLayer.removeChildren();
      this.arrowLayer.activate();
      
      // make sure the chosen position is not under the direction pad control
      var arrowPosition = this.pickArrowPosition(arrowSize);
      while( this.touchControls && this.dPad.intersects( new paper.Rectangle(arrowPosition, arrowSize) ) ) {
       arrowPosition = this.pickArrowPosition(arrowSize);
      }
      
      this.bubbleSound.play();
      var arrow = arrowImage.place(arrowPosition);
      paper.view.draw();          

      // hide the arrow after flashTime milliseconds 
      window.setTimeout( function() {
        arrow.visible = false;
        paper.view.draw();          
      }, this.flashTime);
		}
    					
	});
	
});
			
