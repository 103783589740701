import * as React from 'react';
import AreaNav from '../../components/areaNav';

export interface TrainingAreaNavProps {}

const TrainingAreaNav: React.FunctionComponent<TrainingAreaNavProps> = (props) => (
	<AreaNav
		items={[
			{ to: '/training/myPlan', text: 'My Plan' },
			{ to: '/training/openGym', text: 'Open Gym' },
			{ to: '/training/gameDay', text: 'Game Day' },
		]}
	/>
);
export default TrainingAreaNav;
