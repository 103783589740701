import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Edge from '../../../core';
import FormField from '../../../components/formField';
import './index.scss';
import Error from '../../../components/global/error';
import Loading from '../../../components/loading';

export interface AddAthletesProps {
	initialValues?: AddAthletesValues;
	addAthletes: (athletes: string[]) => Promise<void>;
}

export interface AddAthletesValues {
	athletes: string;
}

function split(value?: string) {
	return (value || '').split(/[ ,;\r\n]/).filter((i) => i);
}

const email = Yup.string().email((e) => `'${e.originalValue}' is not a valid email address`);
const athleteListSchema = Yup.string().test('emails', '', function(this: any, value: string) {
	const { path, createError } = this;

	split(value).forEach((i) => {
		try {
			email.validateSync(i);
		} catch (e) {
			throw createError({ path, message: e.message });
		}
	});

	return true;
});
const athletesSchema = Yup.object({
	athletes: athleteListSchema,
});

export default class AddAthletes extends React.PureComponent<AddAthletesProps> {
	public render(): JSX.Element {
		const { initialValues, addAthletes } = this.props;
		return (
			<div>
				<Formik
					initialValues={Object.assign(
						{},
						{
							athletes: '',
						},
						initialValues || {}
					)}
					validationSchema={athletesSchema}
					validateOnChange={false}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await addAthletes(split(values.athletes));
						} catch (e) {
							actions.setStatus(Edge.API.getErrorMessage(e));
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<AddAthletesValues>) => {
						// #1394: https://dev.azure.com/vizualedge/Edge%20Trainer/_backlogs/backlog/Edge%20Trainer%20Team/Stories/?workitem=1394
						// TODO: can we use a styled button for these?
						/* eslint-disable jsx-a11y/anchor-is-valid */
						return (
							<Form>
								<a
									href="#"
									className="clear-emails-button"
									onClick={(e) => {
										e.preventDefault();
										props.setValues({ athletes: '' });
										props.setFieldTouched('athletes');
									}}
								>
									Clear
								</a>
								<FormField
									name="athletes"
									component="textarea"
									label="Invite Athletes"
									placeholder="Enter email(s)"
								/>
								<p>Enter emails separated by a space or comma</p>
								{props.status && <Error>{props.status}</Error>}
								{props.isSubmitting && <Loading buttonLoader />}
								<button type="submit" disabled={props.isSubmitting}>
									Send Invites
								</button>
							</Form>
						);
						/* eslint-enable jsx-a11y/anchor-is-valid */
					}}
				/>
			</div>
		);
	}
}
