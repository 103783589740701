import * as React from 'react';

import * as Edge from '../core';
import './exerciseLayout.scss';

import { ReactComponent as Logo } from '../assets/images/exercises/logo.svg';

declare var $: any;

export interface ExerciseLayoutProps {
	cleanUpExercise?: () => void;
	completeExercise: () => void;
	endExercise: () => void;
	exerciseName: string;
}

export default class ExerciseLayout extends React.Component<ExerciseLayoutProps> {
	public componentWillUnmount() {
		if (this.props.cleanUpExercise) this.props.cleanUpExercise();
	}

	render(): JSX.Element {
		const { completeExercise, endExercise, exerciseName } = this.props;

		return (
			<div id="play">
				<div className="play-area">
					<div className="trainer_header">
						<div className="logo_wrapper">
							<Logo />
						</div>
						<div className="header_controls">
							{Edge.Configuration.enableCompleteExercise && (
								<button
									className="cta_btn btn_light btn_small complete-exercise-button"
									onClick={completeExercise}
								>
									Complete Exercise
								</button>
							)}
							<div className="exercise_name">{exerciseName.toUpperCase()}</div>
							<button className="cta_btn btn_small end-exercise-button" onClick={endExercise}>
								End Exercise
							</button>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12 stage-wrapper">{this.props.children}</div>
					</div>
				</div>
			</div>
		);
	}
}
