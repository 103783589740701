import moment from 'moment';
import * as _ from 'lodash';

export function timeAdjust(
	serverValue: moment.Moment | Date,
	serverReference: moment.Moment | Date,
	clientReference: moment.Moment | Date
) {
	return moment(serverValue)
		.clone()
		.add(moment(clientReference).diff(moment(serverReference)));
}

export type TimerUpdateCallback = (now: Date) => void;
var timerUpdateCallbacks: TimerUpdateCallback[] = [];
var timerUpdateInterval: NodeJS.Timer | undefined;

export function registerForTimerUpdate(callback: TimerUpdateCallback) {
	if (timerUpdateCallbacks.length === 0) {
		if (timerUpdateInterval) {
			clearInterval(timerUpdateInterval);
		}
		timerUpdateInterval = setInterval(function() {
			const now = new Date();
			_.each(timerUpdateCallbacks, (i) => i(now));
		}, 1000);
	}
	timerUpdateCallbacks = timerUpdateCallbacks.concat([callback]);
	callback(new Date());
	return function() {
		timerUpdateCallbacks = timerUpdateCallbacks.filter((i) => i !== callback);
		if (timerUpdateCallbacks.length === 0 && timerUpdateInterval) {
			clearInterval(timerUpdateInterval);
			timerUpdateInterval = undefined;
		}
	};
}
