import { Dictionary } from 'lodash';

// mapping from keyCode to the logical key we send to listeners
// see https://css-tricks.com/snippets/javascript/javascript-keycodes/ for codes
const KeyCodes: Dictionary<string> = {
	// enter key
	13: 'enter',

	// arrow keys
	37: 'left',
	38: 'up',
	39: 'right',
	40: 'down',

	// WASD
	65: 'left', // a
	68: 'right', // d
	83: 'down', // s
	87: 'up', // w
};

export default class Keyboard {
	static handleKeyDown(event: KeyboardEvent, callback: (direction: string) => void): void {
		const keyCode = event.which || event.keyCode;
		var key = KeyCodes[keyCode];
		if (key) {
			switch (key) {
				case 'left':
				case 'right':
				case 'up':
				case 'down':
					callback(key);
					break;
				default:
					break;
			}
		}
	}
}
