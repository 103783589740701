import * as React from 'react';
import { Configuration } from '../../core';

export interface JsonDebugProps {
	show?: boolean;
	hide?: boolean;
	data: any;
}

const JsonDebug: React.FunctionComponent<JsonDebugProps> = (props) => {
	const shouldRender =
		props.show ||
		(Configuration.showJsonDebug && !props.hide) ||
		(process.env.NODE_ENV === 'development' && !props.hide);
	return shouldRender ? (
		<pre style={{ textAlign: 'left' }}>
			<code>{JSON.stringify(props.data, undefined, 2)}</code>
		</pre>
	) : (
		<></>
	);
};
export default JsonDebug;
