import * as React from 'react';
import classNames from 'classnames';

import { Footer } from '../components/global/footer';

import './greyScreenLayout.scss';

function buildGreyScreen(classNamePrefix: string) {
	return class GreyScreenLayout extends React.Component {
		public render(): JSX.Element {
			return (
				<div className={classNames('greyScreenContainer', classNamePrefix + 'Container')}>
					<div className={classNames('greyScreenContent', classNamePrefix + 'Content')}>
						{this.props.children}
					</div>
					<Footer />
				</div>
			);
		}
	};
}

export const RegisterLayout = buildGreyScreen('register');
export const ChooseProductLayout = buildGreyScreen('chooseSku');
export const GlassesOptionLayout = buildGreyScreen('selectGlasses');
