import * as ReduxThunk from 'redux-thunk';
import * as Edge from './../../core';
import { AppState } from './..';
import { ProfileService } from './../../services/profileService';

import {
	PROFILE_LOADING,
	PROFILE_SUCCESS,
	PROFILE_FAILURE,
	PROFILE_SET_AVATAR_URL,
	PROFILE_SET_REGISTRATION_FLAGS,
} from './types';

// action creators
export const loadProfileAction = () => {
	return {
		type: PROFILE_LOADING,
	};
};

export const profileSuccessAction = (profiles: Edge.Models.Profile) => {
	return {
		type: PROFILE_SUCCESS,
		payload: profiles,
	};
};

export const profileFailureAction = (error: Edge.Models.EdgeError) => {
	return {
		type: PROFILE_FAILURE,
		payload: error,
		error: true,
	};
};

export const setAvatarUrlAction = (updatedUrl: string) => {
	return {
		type: PROFILE_SET_AVATAR_URL,
		payload: updatedUrl,
	};
};

export const setAvatarRegistrationFlagsAction = (flags: Edge.Models.ProfileRegistrationFlags) => {
	return {
		type: PROFILE_SET_REGISTRATION_FLAGS,
		payload: flags,
	};
};

// thunk actions
export const getProfile = (): ReduxThunk.ThunkAction<
	Promise<Edge.Models.Profile>,
	AppState,
	null,
	Edge.Models.EdgeAction
> => async (dispatch) => {
	dispatch(loadProfileAction());

	try {
		const profile = await ProfileService.getMine();
		dispatch(profileSuccessAction(profile));
		return profile;
	} catch (error) {
		dispatch(profileFailureAction(error));
		throw error;
	}
};

export const setAvatarUrl = (
	updatedUrl: string
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(setAvatarUrlAction(updatedUrl));
};

export const setAvatarRegistrationFlags = (
	flags: Edge.Models.ProfileRegistrationFlags
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(setAvatarRegistrationFlagsAction(flags));
};
