import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../../core';
import { AppState } from '../../../store';
import { withLoadDataDefaultConfig } from '../../../components/loadData';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { StatsService } from '../../../services/statsService';
import { Avatar } from '../../../components/avatar';
import { UserService } from '../../../services/userService';
import { ReactComponent as NoteIcon } from '../../../assets/images/icons/Note.svg';

import './index.scss';

export type ScoreCardType = 'team' | 'athlete';

export interface ScoreCardProps {
	type: ScoreCardType;
	currentTeam?: Edge.Models.Team;
	daysTimeSpan?: number;
	results: Edge.Models.ScoreCardResult;
	userId?: string; // potentially passed in via url
	teamId?: string; // potentially passed in via url. Fallback if page is refreshed
	user?: Edge.Models.User;
	position?: Edge.Models.Position;
}

export const ScoreCard: React.FunctionComponent<ScoreCardProps> = (props) => {
	const { averageRecentEdgeScore, averagePreviousEdgeScore, totalSessions, totalHoursTrained } = props.results;
	const { user, position } = props;
	return (
		<div className="team_score">
			<div className="title">
				<h4>{props.type === 'team' ? 'Team Score' : 'Athlete Card'}</h4>
				{props.type === 'athlete' && (
					<div className="icon_wrapper">
						<NoteIcon />
					</div>
				)}
			</div>
			{props.type === 'athlete' && (
				<div className="athlete_tag">
					<Avatar src={user && user.avatarUrl} />
					<div className="athlete_label">
						<div className="athlete_name">{user && `${user.firstName} ${user.lastName}`}</div>
						<div className="athlete_position">{position && position.name}</div>
					</div>
				</div>
			)}
			<div className="inner_wrapper">
				<div className="headline">
					<div className="edge_score">
						<div className="score">
							{averageRecentEdgeScore === 0 ? (
								<span>&mdash;</span>
							) : (
								(averageRecentEdgeScore * 100).toFixed(1)
							)}
						</div>
						<div>Edge Score</div>
					</div>
				</div>
				<div className="score_section">
					<div>Previous Score</div>
					<div className="score">
						{averagePreviousEdgeScore === 0 ? (
							<span>&mdash;</span>
						) : (
							(averagePreviousEdgeScore * 100).toFixed(1)
						)}
					</div>
				</div>
				<div className="score_section">
					<div>Improvement</div>
					<div className="score">
						{averagePreviousEdgeScore === 0 ? (
							<span>&mdash;</span>
						) : (
							(
								((averageRecentEdgeScore - averagePreviousEdgeScore) / averagePreviousEdgeScore) *
								100
							).toFixed(1) + '%'
						)}
					</div>
				</div>
				<div className="score_section">
					<div>Total Sessions</div>
					<div className="score grey">{totalSessions === 0 ? <span>&mdash;</span> : totalSessions}</div>
				</div>
				<div className="score_section">
					<div>Total Hours Trained</div>
					<div className="score grey">
						{totalHoursTrained === 0 ? <span>&mdash;</span> : totalHoursTrained}
					</div>
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state: AppState) {
	const { currentTeam } = state.teamState;
	const { timeSpan } = state.timeSpanState;
	return { currentTeam, daysTimeSpan: timeSpan.days };
}

export default connect(mapStateToProps)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(ScoreCard),
		(props: ScoreCardProps) => {
			return {
				userId: props.userId,
				teamId: props.currentTeam && props.currentTeam.id,
				urlTeamId: props.teamId,
				daysTimeSpan: props.daysTimeSpan!,
				type: props.type,
			};
		},
		async (props) => {
			const teamId = props.teamId || props.urlTeamId;

			if (props.type === 'team') {
				const results = await StatsService.getTeamScoreResults(props.teamId!, props.daysTimeSpan);
				return { results };
			} else {
				const timeSpanDays = 60;
				const data = await UserService.getAthleteCard(props.userId!, timeSpanDays, teamId!);

				const { user, position, latestEdgeScore, previousEdgeScore, totalSessions, totalHoursTrained } = data;
				const results: Edge.Models.ScoreCardResult = {
					averageRecentEdgeScore: latestEdgeScore ? latestEdgeScore : 0,
					averagePreviousEdgeScore: previousEdgeScore ? previousEdgeScore : 0,
					totalSessions,
					totalHoursTrained,
				};

				return { results, user, position };
			}
		}
	)
);
