import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import * as Edge from '../../core';
import ErrorDisplay from '../../components/error/errorDisplay';
import Loading from '../../components/loading';
import ScrollToTopOnMount from '../../components/global/scrollToTopOnMount';

export interface ExercisePageProps<T extends Edge.Models.ExerciseResult> {
	currentProfile: Edge.Models.DeviceProfile;
	completeText: string;
	createResult: (command: T) => Promise<T>;
	completeExercise: (command: T) => void;
	abortExercise: () => void;
}

export interface ExercisePageModeProps<T extends Edge.Models.ExerciseResult> extends ExercisePageProps<T> {
	mode: Edge.Models.ExerciseMode;
}

export enum ExercisePageStage {
	PreExercise,
	Exercise,
	Creating,
	PostExercise,
}

export interface BaseExercisePageProps<
	TConfig extends Edge.Models.ExerciseConfiguration,
	TResult extends Edge.Models.ExerciseResult
> extends ExercisePageModeProps<TResult> {
	initialConfiguration: TConfig;
	lockConfiguration: boolean;
	operationName: string;
	debugMode?: boolean;
	hideExerciseResults: boolean;
}

export interface BaseExercisePageState<
	TConfig extends Edge.Models.ExerciseConfiguration,
	TResult extends Edge.Models.ExerciseResult
> {
	stage: ExercisePageStage;
	configuration: TConfig;
	fullScreen?: boolean;
	result?: TResult;
	error?: Edge.Models.EdgeError;
	aborted?: boolean;
}

export abstract class BaseExercisePage<
	TConfig extends Edge.Models.ExerciseConfiguration,
	TResult extends Edge.Models.ExerciseResult
> extends React.PureComponent<BaseExercisePageProps<TConfig, TResult>, BaseExercisePageState<TConfig, TResult>> {
	constructor(props: BaseExercisePageProps<TConfig, TResult>) {
		super(props);
		this.state = {
			stage: ExercisePageStage.PreExercise,
			configuration: props.initialConfiguration,
		};
	}
	protected abstract renderConfiguration(): JSX.Element;
	protected abstract renderExercise(): JSX.Element;
	protected abstract renderResults(): JSX.Element;
	render(): JSX.Element {
		const { stage } = this.state;
		return (
			<>
				<CSSTransition
					in={stage === ExercisePageStage.Exercise}
					timeout={300}
					classNames="exercise_play"
					unmountOnExit
				>
					{this.renderExercise()}
				</CSSTransition>
				{this.renderUi()}
			</>
		);
	}

	private renderUi = () => {
		const { stage, error } = this.state;
		if (error) {
			if (stage === ExercisePageStage.Creating) {
				return (
					<div>
						<ScrollToTopOnMount />
						<ErrorDisplay message={Edge.API.getErrorMessage(error)} />
						<button className="cta_btn" onClick={this.tryCreateExercise}>
							Retry saving result
						</button>
					</div>
				);
			}
			return <ErrorDisplay message={Edge.API.getErrorMessage(error)} />;
		}

		if (stage === ExercisePageStage.PreExercise) {
			return (
				<>
					<ScrollToTopOnMount />
					{this.renderConfiguration()}
				</>
			);
		}
		if (stage === ExercisePageStage.Exercise) {
			return <ScrollToTopOnMount />;
		}
		if (stage === ExercisePageStage.Creating) {
			return (
				<>
					<ScrollToTopOnMount />
					<Loading />
				</>
			);
		}
		if (stage === ExercisePageStage.PostExercise) {
			return (
				<>
					<ScrollToTopOnMount />
					{this.renderResults()}
				</>
			);
		}

		return <ErrorDisplay message="Unknown state" />;
	};

	protected startExercise = async (config: TConfig, fullScreen: boolean) => {
		this.setState({ stage: ExercisePageStage.Exercise, configuration: config, fullScreen });
	};
	protected retryExercise = async () => {
		this.setState({ stage: ExercisePageStage.Exercise });
	};
	protected abortingExercise = (): Promise<void> => {
		return new Promise((resolve) => {
			this.setState(
				{
					aborted: true,
				},
				resolve
			);
		});
	};
	protected finishExercise = (result: TResult) => {
		if (this.state.aborted) {
			this.props.abortExercise();
			return;
		}
		this.setState(
			{
				result: Object.assign({}, result, {
					responseTimeMilliseconds: Math.max(0, Math.min(99999, result.responseTimeMilliseconds)),
				}),
				stage: ExercisePageStage.Creating,
			},
			() => this.tryCreateExercise()
		);
	};
	protected tryCreateExercise = async () => {
		this.setState({ error: undefined, stage: ExercisePageStage.Creating });
		try {
			const result = await this.props.createResult(this.state.result!);
			this.setState({ result, stage: ExercisePageStage.PostExercise });
		} catch (error) {
			this.setState({ error });
		}
	};
	protected complete = async () => {
		try {
			// Results in the unmounting of this Component
			await this.props.completeExercise(this.state.result!);
		} catch (error) {
			this.setState({ error });
		}
	};
}
