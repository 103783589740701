import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { RegisterViaInviteProps } from './props';
import { logout } from '../../store/login/actions';
import Loading from '../../components/loading';

export interface MergeAccountProps extends RegisterViaInviteProps {
	logout: () => Promise<void>;
}

interface MergeAccountState {
	redirect?: boolean;
}

export class MergeAccount extends React.PureComponent<MergeAccountProps, MergeAccountState> {
	constructor(props: MergeAccountProps) {
		super(props);
		this.state = {};
	}
	public async componentDidMount() {
		await this.props.logout();
		this.setState({ redirect: true });
	}
	public render() {
		if (this.state.redirect) {
			const target = `/login/${encodeURIComponent(`/invite/complete/${this.props.match.params.token}`)}`;
			return <Redirect to={target} />;
		} else {
			return <Loading />;
		}
	}
}

export default connect(
	() => {
		return {};
	},
	{ logout }
)(MergeAccount);
