import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';

import * as Edge from '../../core';
import FormField from '../../components/formField';
import Input from '../../components/global/input';
import Error from '../../components/global/error';
import Loading from '../../components/loading';

export interface AddUserFormProps {
	initialValues?: Partial<AddUserFormValues>;
	onSubmit: (values: AddUserFormValues) => Promise<void>;
	organizationRoles: Edge.Models.Role[];
}

export interface AddUserFormValues {
	email?: string;
	roleId?: string;
}

const addUserSchema = Yup.object().shape({
	email: Yup.string()
		.email('Must be formatted as an email address')
		.required('Email is required'),
	roleId: Yup.string().required('Role is required'),
});

export default class AddUserForm extends React.Component<AddUserFormProps> {
	public render() {
		const { initialValues, organizationRoles, onSubmit } = this.props;
		return (
			<>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							email: '',
							roleId: (organizationRoles[0] || {})!.id,
						},
						initialValues || {}
					)}
					validationSchema={addUserSchema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await onSubmit(values);
						} catch (e) {
							const message = Edge.API.getErrorMessage(e);
							actions.setStatus(message);
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<AddUserFormValues>) => (
						<Form>
							<FormField type="text" name="email" description="Email address" />
							<FormField component="select" name="roleId" description="Role">
								{_.orderBy(organizationRoles, (i) => i.name).map((i) => (
									<option key={i.id} value={i.id}>
										{i.name}
									</option>
								))}
							</FormField>
							{props.status && <Error>{props.status}</Error>}
							{props.isSubmitting && <Loading buttonLoader />}
							<Input
								type="submit"
								disabled={props.isSubmitting}
								value="Add User"
								className="full_width"
							/>
						</Form>
					)}
				/>
			</>
		);
	}
}
