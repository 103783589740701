import * as React from 'react';
import * as Edge from '../../../core';
import Error from '../../../components/global/error';

export interface RemoveOrganizationMemberProps {
	organizationMember: Edge.Models.OrganizationMember;
	onConfirm: () => Promise<void>;
	onCancel: () => void;
}

interface RemoveOrganizationMemberState {
	error?: string;
}

export class RemoveOrganizationMember extends React.PureComponent<
	RemoveOrganizationMemberProps,
	RemoveOrganizationMemberState
> {
	constructor(props: RemoveOrganizationMemberProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { organizationMember, onCancel } = this.props;
		const { error } = this.state;
		return (
			<>
				<p>
					Are you sure you want to remove{' '}
					{organizationMember.user && (
						<strong>
							{organizationMember.user.firstName} {organizationMember.user.lastName}
						</strong>
					)}{' '}
					from this organization?
				</p>
				{error && <Error>{error}</Error>}
				<button onClick={this.onConfirm} className="cta_btn remove_member_btn">
					Yes
				</button>
				<button onClick={onCancel} className="cta_btn remove_member_btn">
					Cancel
				</button>
			</>
		);
	}
	private onConfirm = async () => {
		const { onConfirm } = this.props;
		try {
			this.setState({ error: undefined });
			await onConfirm();
		} catch (e) {
			this.setState({ error: Edge.API.getErrorMessage(e) });
		}
	};
}
export default RemoveOrganizationMember;
