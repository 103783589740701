import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import * as _ from 'lodash';

import * as Edge from '../../core';
import ModalContainer from '../global/modal';
import Loading from '../loading';
import Error from '../global/error';
import { withLoadDataDefaultConfig } from '../loadData';
import errorLoadingWrapperHOC from '../errorLoadingWrapper/errorLoadingWrapperHOC';
import { SessionService } from '../../services/sessionService';
import { generatePurchasePageLink } from '../../pages/checkout';
import { getPriceTitle } from '../../utilities/commerceUtil';
import { AppState } from '../../store';
import AdminMenu from '../adminMenu';
import { CommerceService } from '../../services/commerceService';
import UpdateCurrentSku from './updateCurrentPrice';
import ChangeAssignmentsManually from '../../pages/manageOrganization/assignSeats/changeManually';
import { CreditMode } from '../assignCredits';

import './index.scss';
import 'react-datepicker/dist/react-datepicker.css';

export interface YourCurrentPlanProps {
	entityType: Edge.Models.EntityType;
	entityId: string;
	organization: Edge.Models.Organization;
	latestPurchasePlan?: Edge.Models.LatestPurchasePlanResponse;
	canEdit?: boolean;
	extendSubscription?: () => void;
	addSeats?: () => void;
	isAdmin?: boolean;
	reloadData: () => Promise<void>;
}

interface YourCurrentPlanState {
	subscriptionEndDate?: Date;
	changingEndDate?: Date; // the new plan end date
	updatingCurrentSku?: boolean;
	loading: boolean;
	error?: Edge.Models.EdgeError;
}

export class YourCurrentPlan extends React.Component<YourCurrentPlanProps, YourCurrentPlanState> {
	constructor(props: YourCurrentPlanProps) {
		super(props);
		const { latestPurchasePlan } = this.props;
		const subscription = latestPurchasePlan && latestPurchasePlan.subscription;
		this.state = {
			subscriptionEndDate:
				subscription && subscription.expirationDateUtc
					? moment(subscription.expirationDateUtc)
							.local()
							.toDate()
					: undefined,
			loading: false,
		};
	}
	public render() {
		const { latestPurchasePlan, canEdit, isAdmin, entityId, entityType, organization, reloadData } = this.props;
		const { subscriptionEndDate, changingEndDate, updatingCurrentSku, loading, error } = this.state;
		const creditMode: CreditMode =
			latestPurchasePlan && latestPurchasePlan.subscription ? CreditMode.Subscriptions : CreditMode.Sessions;

		return (
			<div className="your_current_plan">
				{isAdmin && (
					<AdminMenu>
						<button onClick={this.openUpdateCurrentSku}>Update Current Product</button>
					</AdminMenu>
				)}
				{!latestPurchasePlan ? (
					<>
						<p className="no_active">You have no active plan.</p>
						{canEdit && (
							<Link to={this.getLink(Edge.Models.CheckoutSessionType.Initial)} className="cta_btn">
								Find a plan
							</Link>
						)}
					</>
				) : (
					<>
						<div className="section_label">Your Current Plan</div>
						<div className="current_plan_header">
							<div className="text_wrapper">
								<h2>{getPriceTitle(latestPurchasePlan.price)}</h2>
							</div>
							<div className="action_wrapper">
								{canEdit && (
									<Link
										to={this.getLink(
											this.validSubscriptionExists(latestPurchasePlan.subscription)
												? Edge.Models.CheckoutSessionType.Extend
												: Edge.Models.CheckoutSessionType.Initial
										)}
										className="cta_btn"
									>
										{this.validSubscriptionExists(latestPurchasePlan.subscription)
											? `Extend/Upgrade`
											: `Renew/Upgrade`}
									</Link>
								)}
							</div>
						</div>
						<dl>
							{latestPurchasePlan.subscription && (
								<>
									<div className="label_item">
										Duration:
										<span>{latestPurchasePlan.subscription.lifetimeDays} days</span>
									</div>
									<div className="label_item">
										Seats:
										<span>
											{latestPurchasePlan.subscription.seats}
											{latestPurchasePlan.subscription && canEdit && (
												<Link
													to={this.getLink(Edge.Models.CheckoutSessionType.AddSeats)}
													className="underline"
												>
													Add Seats
												</Link>
											)}
										</span>
									</div>
									<div className="label_item">
										Start Date:
										<span>
											{latestPurchasePlan.subscription.startedDateUtc
												? moment(latestPurchasePlan.subscription.startedDateUtc)
														.local()
														.startOf('day')
														.format(Edge.Constants.DATE_FORMAT)
												: 'Not started'}
										</span>
									</div>
									<div className="label_item">
										End Date:
										<span>
											{!subscriptionEndDate ? (
												'Not started'
											) : isAdmin ? (
												<>
													<DatePicker
														selected={subscriptionEndDate}
														onChange={(date: Date) => {
															this.setState({ changingEndDate: date });
														}}
													/>
													&nbsp;{moment(subscriptionEndDate).format('hh:mm a')}
												</>
											) : (
												moment(subscriptionEndDate)
													.subtract(1, 'day')
													.format(Edge.Constants.DATE_FORMAT)
											)}
										</span>
										{changingEndDate && (
											<ModalContainer
												open={true}
												title="Are you sure?"
												onClose={this.closeChangingEndDate}
											>
												<p>{`Do you want to change the end date of this plan to ${moment(
													changingEndDate
												).format(Edge.Constants.DATE_FORMAT)}?`}</p>
												{loading && <Loading buttonLoader />}
												{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
												<button className="cta_btn" onClick={this.setPlanEndDate}>
													Yes
												</button>
												<button
													className="cta_btn btn_light"
													onClick={this.closeChangingEndDate}
												>
													Cancel
												</button>
											</ModalContainer>
										)}
									</div>
								</>
							)}
							{!latestPurchasePlan.subscription && (
								<>
									<div className="label_item">
										Sessions:
										<span>
											{latestPurchasePlan.sessionCreditTotal || 0}
											{canEdit && (
												<Link
													to={this.getLink(Edge.Models.CheckoutSessionType.Initial)}
													className="underline"
												>
													Add Sessions
												</Link>
											)}
										</span>
									</div>
								</>
							)}
							{isAdmin && (
								<ChangeAssignmentsManually
									currentOrganization={organization}
									mode={creditMode}
									reloadData={reloadData}
								/>
							)}
						</dl>
					</>
				)}
				{isAdmin && updatingCurrentSku && (
					<ModalContainer open={true} title={`Update Current Product`} onClose={this.closeUpdateCurrentSku}>
						<UpdateCurrentSku
							entityId={entityId}
							entityType={entityType}
							organizationId={organization.id}
							updateCurrentPrice={this.updateCurrentSku}
							currentPrice={latestPurchasePlan!.price}
							closeModal={this.closeUpdateCurrentSku}
							reloadParent={reloadData}
						/>
					</ModalContainer>
				)}
			</div>
		);
	}

	private validSubscriptionExists = (subscription?: Edge.Models.LatestPurchasePlanSubscription): boolean =>
		!!subscription &&
		!!subscription.expirationDateUtc &&
		new Date(subscription.expirationDateUtc).getTime() >= Date.now();

	private getLink = (orderType: Edge.Models.CheckoutSessionType) => {
		const { entityType, entityId } = this.props;
		return generatePurchasePageLink(entityType, entityId, orderType);
	};

	private setPlanEndDate = async () => {
		this.setState({ loading: true, error: undefined });
		try {
			const { changingEndDate } = this.state;
			await SessionService.updateSubscriptionEndDate({
				entityId: this.props.entityId,
				entityType: this.props.entityType,
				endDateUtc: moment(changingEndDate!)
					.endOf('day')
					.utc(true)
					.toDate(),
			});
			this.setState({ subscriptionEndDate: changingEndDate, changingEndDate: undefined, loading: false });
		} catch (error) {
			this.setState({ error, loading: false });
		}
	};

	private closeChangingEndDate = () => {
		this.setState({ changingEndDate: undefined, error: undefined });
	};

	private openUpdateCurrentSku = () => {
		this.setState({ updatingCurrentSku: true });
	};

	private updateCurrentSku = async (skuId: string) => {
		const { entityType, entityId } = this.props;
		await CommerceService.updateActivePrice({ entityType, entityId, priceId: skuId });
	};

	private closeUpdateCurrentSku = () => {
		this.setState({ updatingCurrentSku: false });
	};
}

function mapStateToProps({ loginState, organizationState }: AppState) {
	return {
		isAdmin: loginState.decodedToken && loginState.decodedToken.is_admin,
		organization: organizationState.currentOrganization!,
	};
}

export default connect(mapStateToProps)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(YourCurrentPlan, undefined, undefined, undefined, {
			loadingOptions: { blockItem: true },
		}),
		(props: YourCurrentPlanProps) => _.pick(props, 'entityType', 'entityId', 'organization'),
		async (props) => {
			return {
				latestPurchasePlan: await SessionService.getLatestPurchasePlan(
					props.entityType,
					props.entityId,
					props.organization.id
				),
			};
		}
	)
);
