import * as Edge from './../../core';
import { DecodedToken } from '../../services/authenticationService';

export interface LoginState {
	authenticated: boolean;
	isLoading: boolean;
	token?: string;
	decodedToken?: DecodedToken;
	error?: string;
}

export interface TokenResult {
	token: string;
}

export const LOGIN_INPROGRESS: string = 'LOGIN_INPROGRESS';
export const LOGIN_SUCCESS: string = 'LOGIN_SUCCESS';
export const LOGIN_CLEAR_PRELOADED_DATA: string = 'LOGIN_CLEAR_PRELOADED_DATA';
export const LOGIN_FAILURE: string = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS: string = 'LOGOUT_SUCCESS';

interface LoginInProgress extends Edge.Models.EdgeAction {
	type: typeof LOGIN_INPROGRESS;
}

interface LoginSuccess extends Edge.Models.EdgeAction<TokenResult> {
	type: typeof LOGIN_SUCCESS;
	payload: TokenResult;
}

interface LoginClearPreloadedData extends Edge.Models.EdgeAction<TokenResult> {
	type: typeof LOGIN_CLEAR_PRELOADED_DATA;
	payload: TokenResult;
}

interface LoginFailure extends Edge.Models.EdgeAction<Edge.Models.EdgeError> {
	type: typeof LOGIN_FAILURE;
	payload: Edge.Models.EdgeError;
}

interface LogoutSuccess extends Edge.Models.EdgeAction<TokenResult> {
	type: typeof LOGOUT_SUCCESS;
}

export type LoginActionTypes = LoginInProgress | LoginSuccess | LoginFailure | LogoutSuccess;
