import * as React from 'react';
import { ReactComponent as Checkmark } from '../../../assets/images/icons/Checkmark.svg';
import './index.scss';
import * as Edge from '../../../core';
import { getPriceFeatures, getPriceTitle } from '../../../utilities/commerceUtil';

export interface CurrentPlanProps {
	price: Edge.Models.PriceDetail;
}

export interface CurrentPlanState {
	showAllFeatures: boolean;
}

const featureSplitPoint = 4;

export default class CurrentPlan extends React.Component<CurrentPlanProps, CurrentPlanState> {
	constructor(CurrentPlanState: any) {
		super(CurrentPlanState);
		this.state = {
			showAllFeatures: false,
		};
	}

	public toggleFeatures = () => {
		this.setState({ showAllFeatures: !this.state.showAllFeatures });
	};

	public render() {
		const { price } = this.props;
		const { showAllFeatures } = this.state;
		const features = getPriceFeatures(price);
		const featuresToShow = showAllFeatures ? features : features.slice(0, featureSplitPoint);
		return (
			<div className="currentPlan">
				<div className="left">
					<h3 className="cardTitle">Your Current Plan</h3>
					<p className="planTitle">{getPriceTitle(price.price)}</p>
				</div>
				<div className="right">
					<p className="secondaryTitle">{price.product.caption}</p>
					{features.length > 0 && (
						<>
							<ul>
								{featuresToShow.map((feature: string, key: number) => (
									<li key={key}>
										<span className="check">
											<Checkmark />
										</span>
										{feature}
									</li>
								))}
							</ul>
							{features.length > featureSplitPoint &&
								(showAllFeatures ? (
									<button className="link_button" onClick={this.toggleFeatures}>
										- Show less features
									</button>
								) : (
									<button className="link_button" onClick={this.toggleFeatures}>
										+ Show more features
									</button>
								))}
						</>
					)}
				</div>
			</div>
		);
	}
}
