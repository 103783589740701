import classNames from 'classnames';
import * as React from 'react';

import { RegisterViaInviteProps } from './props';
import history from '../../services/history';
import CardRegister from '../../components/cardRegister';
import { ReactComponent as PeopleIcon } from '../../assets/images/icons/InvitePeople.svg';

import './prompt.scss';

interface PromptState {
	isCreateNew: boolean;
}
export class Prompt extends React.PureComponent<RegisterViaInviteProps, PromptState> {
	constructor(props: RegisterViaInviteProps) {
		super(props);
		this.state = { isCreateNew: true };
	}
	public render() {
		return (
			<>
				<h1>Choose How to Join Your Team</h1>
				<p className="headerSecondary">Select how you'd like to be added to your team.</p>
				<CardRegister>
					<div className="left invite_item">
						<div className="prompt-join-team">
							<div
								className={classNames('create-new', {
									'is-active': this.state.isCreateNew,
								})}
								onClick={this.onCreateNewClick}
							>
								<div className="prompt_section">
									<div className="text_wrapper">
										<h3>Create new athlete</h3>
										<p>Are you signing up a separate person for a new account?</p>
									</div>
								</div>
								<div className="prompt_section">
									<div className="icon_wrapper">
										<div className="circle_icon" />
										<PeopleIcon />
									</div>
								</div>
							</div>
							<div
								className={classNames('add-to-existing', {
									'is-active': !this.state.isCreateNew,
								})}
								onClick={this.onAddToExistingClick}
							>
								<div className="prompt_section">
									<div className="text_wrapper">
										<h3>Add to existing account</h3>
										<p>Playing a new sport or simply want to add a new team to your account?</p>
									</div>
								</div>
								<div className="prompt_section">
									<div className="icon_wrapper">
										<div className="circle_icon" />
										<PeopleIcon />
									</div>
								</div>
							</div>
						</div>
						<button type="button" onClick={this.continue} className="cta_btn">
							Continue
						</button>
					</div>
				</CardRegister>
			</>
		);
	}
	private onCreateNewClick = () => {
		this.setState({ isCreateNew: true });
	};
	private onAddToExistingClick = () => {
		this.setState({ isCreateNew: false });
	};
	private continue = () => {
		if (this.state.isCreateNew) {
			history.push(`/invite/create/${this.props.match.params.token}`);
		} else {
			history.push(`/invite/merge/${this.props.match.params.token}`);
		}
	};
}
export default Prompt;
