import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { NavigationProps, NavigationItem } from './index';

import './index.scss';

const NavigationListItemContent: (navigationItem: NavigationItem) => JSX.Element = (item) => (
	<>
		{item.image ? (
			<div className="navItem_icon">
				<item.image />
			</div>
		) : null}
		<span>{item.text}</span>
	</>
);

const NavigationListItem: (navigationItem: NavigationItem) => JSX.Element = (item) => {
	const props = item.newWindow ? { target: '_blank' } : {};
	return (
		<div className="navigation_item">
			{item.route && (
				<NavLink to={item.route} activeClassName="active" {...props}>
					<NavigationListItemContent {...item} />
				</NavLink>
			)}
			{item.link && (
				<a href={item.link} {...props}>
					<NavigationListItemContent {...item} />
				</a>
			)}
		</div>
	);
};

export const Navigation: ({ navigationItems }: NavigationProps) => JSX.Element = (props) => (
	<nav id="edgeNavigation">
		<div className="navigation">
			{props.navigationItems.map((item, i) => (
				<NavigationListItem key={i} {...item} />
			))}
		</div>
	</nav>
);
