import * as React from 'react';
import { ReactComponent as GlassesIcon } from '../../../assets/images/icons/glasses.svg';

import './index.scss';

export interface ConfigLayoutProps {
	operationName: React.ReactNode;
	exerciseName: React.ReactNode;
	description?: React.ReactNode;
	headerActions: React.ReactNode;
	requiresGlasses?: boolean;
	instructions: React.ReactNode;
	settings?: React.ReactNode;
	preview?: React.ReactNode;
	videoUrl?: string;
}

const ConfigLayout: React.FunctionComponent<ConfigLayoutProps> = (props) => {
	const {
		operationName,
		exerciseName,
		description,
		headerActions,
		requiresGlasses,
		instructions,
		settings,
		preview,
		videoUrl,
	} = props;
	return (
		<div className="config_layout">
			<div className="config_layout_header">
				<div className="header_text">
					<h2>{operationName}</h2>
					<h3>{exerciseName}</h3>
					<p>{description}</p>
				</div>
				<div className="headerActions">
					<div>{headerActions}</div>
				</div>
			</div>
			<div className="hRule" />
			<div className="config_layout_body">
				<div className="config_layout_details">
					{requiresGlasses && (
						<div className="needs_glasses">
							<h4>
								<GlassesIcon /> 3D Glasses
							</h4>
							<p>
								For accurate recording and optimal use, this exercise requires the use of{' '}
								<span className="highlight">3D Glasses</span>.
							</p>
						</div>
					)}
					<div className="instructions">
						<p>
							<b>Instructions: </b>
						</p>
						{instructions}
					</div>
					{settings && (
						<div className="exercise_settings">
							<h4>Exercise Settings</h4>
							{settings}
						</div>
					)}
				</div>
				{(preview || videoUrl) && (
					<div className="config_layout_preview-and-video">
						{videoUrl ? <iframe className="video" src={videoUrl} title="Instructions" /> : preview}
					</div>
				)}
			</div>
		</div>
	);
};

export default ConfigLayout;
