import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../../core';
import Table from '../../../components/table';
import { Avatar } from '../../../components/avatar';
import { AppState } from '../../../store';
import AdminMenu from '../../../components/adminMenu';

class OrganizationMembersTable extends Table<Edge.Models.OrganizationMember> {}

export interface OrganizationMembersProps {
	organizationMembers: Edge.Models.OrganizationMember[];
	openRemoveMember: (organizationMember: Edge.Models.OrganizationMember) => void;
	openChangeRole: (organizationMember: Edge.Models.OrganizationMember) => void;
	openEditUser: (editingUser: Edge.Models.ManuallyEditedUser) => void;
	openAddUser: () => void;
	isAdmin?: boolean;
}

interface OrganizationMembersState {
	search?: string;
}

const availableSearches: ((item: Edge.Models.OrganizationMember) => string | undefined)[] = [
	(item) => item.user && item.user.firstName,
	(item) => item.user && item.user.lastName,
	(item) => item.organizationRole && item.organizationRole.name,
];
const availableSorts: {
	[x: string]: (item: Edge.Models.OrganizationMember) => any;
} = {
	organizationMember: (item) => item.user && item.user.lastName + ', ' + item.user.firstName,
	role: (item) => item.organizationRole && item.organizationRole.name,
};

export class OrganizationMembers extends React.Component<OrganizationMembersProps, OrganizationMembersState> {
	constructor(props: OrganizationMembersProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { organizationMembers, isAdmin } = this.props;

		return (
			<OrganizationMembersTable
				items={organizationMembers}
				availableSearches={availableSearches}
				availableSorts={availableSorts}
				initialSort={['organizationMember']}
				renderHeader={() => <>Manage Organization</>}
				renderSubHeader={() => (
					<button onClick={this.props.openAddUser} className="add_more_btn add_user_btn">
						Add User
					</button>
				)}
				renderTableHeader={(classNames, toggleSort) => (
					<tr>
						<th>Organization Member</th>
						<th>Role</th>
						<th className="mobileHide" />
					</tr>
				)}
				renderTableItem={(i) => (
					<tr>
						<td>
							{i && i.user && (
								<>
									<Avatar src={i.user.avatarUrl} />
									<span>
										{i.user.lastName}, {i.user.firstName}
									</span>
								</>
							)}
						</td>
						<td>{i.organizationRole.name}</td>
						<td className="mobileHide">
							{isAdmin && (
								<AdminMenu>
									<div
										className="menu_item"
										onClick={() => this.props.openEditUser({ user: i.user, email: i.email })}
									>
										Edit User
									</div>
									<div className="menu_item" onClick={() => this.props.openRemoveMember(i)}>
										Remove from organization
									</div>
									<div className="menu_item" onClick={() => this.props.openChangeRole(i)}>
										Change Role
									</div>
								</AdminMenu>
							)}
						</td>
					</tr>
				)}
				pagingMode="seeMore"
				seeMoreText="See more users"
			/>
		);
	}
}

function mapStateToProps({ loginState }: AppState) {
	return {
		isAdmin: loginState.decodedToken && loginState.decodedToken.is_admin,
	};
}

export default connect(mapStateToProps)(OrganizationMembers);
