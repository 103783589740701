import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as moment from 'moment';

import * as Edge from '../../../core';
import FormField from '../../../components/formField';
import Input from '../../../components/global/input';
import Error from '../../../components/global/error';
import ConfigLayout from '../configLayout';
import { FullScreenService } from '../../../services/fullScreenService';

export interface RecognitionConfigFormProps {
	mode: Edge.Models.ExerciseMode;
	initialConfiguration: Partial<Edge.Models.RecognitionExerciseConfiguration>;
	lockConfiguration: boolean;
	onSubmit: (configuration: Edge.Models.RecognitionExerciseConfiguration, fullScreen: boolean) => Promise<void>;

	operationName: string;
}

export interface RecognitionConfigFormValues {
	durationSeconds: string;
	size: string;
	arrows: string;
	flashTimeMilliseconds: string;
	fullScreen: boolean;
}

const schema = Yup.object().shape({
	sessionDuration: Yup.number(),
	size: Yup.string().required('Arrow Size is required'),
	arrows: Yup.string().required('Number of Arrows is required'),
	flashTimeMilliseconds: Yup.number().required('Flash Time is required'),
	fullScreen: Yup.boolean(),
});

interface InstructionProps {
	config: RecognitionConfigFormValues;
	startText: string;
}

const instructions = ({ config, startText }: InstructionProps) => (
	<ul>
		<li>Red/Blue glasses are NOT worn during this exercise.</li>
		<li>
			After clicking the '{startText}' button, a series of blue arrows will flash on the screen. Once the series
			of arrows have disappeared from the middle of the screen, using the arrow keys, repeat the sequence of the
			direction each arrow is pointing, from left to right.
		</li>
		<li>
			A second row of arrows will appear as you respond, below the original row. A green arrow indicates a correct
			choice, while a red arrow represents an incorrect response.
		</li>
		<li>This exercise is scored based on both speed and accuracy. </li>
		<li>
			This exercise will last {moment.duration(parseInt(config.durationSeconds), 'seconds').humanize()} and you
			have 10 seconds to answer each round.
		</li>
	</ul>
);

export default class RecognitionConfigForm extends React.Component<RecognitionConfigFormProps> {
	public render() {
		const { initialConfiguration, lockConfiguration, onSubmit, operationName, mode } = this.props;
		const startText = mode === 'evaluate' ? 'Start Exercise' : 'Train Recognition';
		return (
			<>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							durationSeconds: (initialConfiguration.durationSeconds || 120).toString() || '120',
							size: (initialConfiguration.size || 2).toString() || '2',
							arrows: (initialConfiguration.arrows || 3).toString() || '3',
							flashTimeMilliseconds:
								(initialConfiguration.flashTimeMilliseconds || 600).toString() || '600',
							fullScreen: false,
						}
					)}
					validationSchema={schema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await onSubmit(
								{
									id: initialConfiguration.id,
									exerciseTypeId: Edge.Models.ExerciseTypeId.Recognition,
									durationSeconds: parseInt(values.durationSeconds),
									size: parseInt(values.size),
									arrows: parseInt(values.arrows),
									flashTimeMilliseconds: parseInt(values.flashTimeMilliseconds),
								},
								values.fullScreen
							);
						} catch (e) {
							actions.setStatus(Edge.API.getErrorMessage(e));
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<RecognitionConfigFormValues>) => (
						<Form>
							<ConfigLayout
								operationName={operationName}
								exerciseName="Recognition"
								headerActions={
									<>
										<Input type="submit" disabled={props.isSubmitting} value={startText} />
										{props.status && <Error>{props.status}</Error>}
										{FullScreenService.isEnabled() && (
											<FormField
												type="checkbox"
												name="fullScreen"
												description="View Full Screen"
											/>
										)}
									</>
								}
								instructions={instructions({ config: props.values, startText })}
								settings={
									<>
										<FormField
											component="select"
											name="durationSeconds"
											description="Session Duration"
											disabled={lockConfiguration}
										>
											<option value="60">1 minute</option>
											<option value="120">2 minutes</option>
											<option value="300">5 minutes</option>
											<option value="600">10 minutes</option>
										</FormField>
										<FormField
											component="select"
											name="arrows"
											description="Number of Arrows"
											disabled={lockConfiguration}
										>
											<option>3</option>
											<option>4</option>
											<option>5</option>
											<option>6</option>
											<option>9</option>
											<option>12</option>
										</FormField>
										<FormField
											component="select"
											name="size"
											description="Arrow Size"
											disabled={lockConfiguration}
										>
											<option value="1">Small</option>
											<option value="2">Medium</option>
											<option value="3">Large</option>
										</FormField>
										<FormField
											component="select"
											name="flashTimeMilliseconds"
											description="Flash Time"
											disabled={lockConfiguration}
										>
											<option value="300">0.3 seconds</option>
											<option value="600">0.6 seconds</option>
											<option value="900">0.9 seconds</option>
											<option value="1000">1 second</option>
											<option value="2000">2 seconds</option>
											<option value="3000">3 seconds</option>
										</FormField>
									</>
								}
								videoUrl={Edge.Constants.VIDEOS.RECOGNITION}
							/>
						</Form>
					)}
				/>
			</>
		);
	}
}
