import * as Edge from '../core';

export abstract class PlanService {
	public static async getMyTrainingPlan(): Promise<Edge.Models.TrainingPlanResult | undefined> {
		const route = Edge.API.ServerRoute.forAction('plans', 'me/training/active');
		return await Edge.API.get(route);
	}
	public static async getUserTrainingPlan(userId: string): Promise<Edge.Models.TrainingPlanResult | undefined> {
		const route = Edge.API.ServerRoute.forAction('plans', `training/active/${userId}`);
		return await Edge.API.get(route);
	}

	public static async getGameDayPlan(): Promise<Edge.Models.GameDayPlanResult | undefined> {
		const route = Edge.API.ServerRoute.forAction('plans', 'me/gameday/active');
		return await Edge.API.get(route);
	}

	public static async getEvaluationPlan(): Promise<Edge.Models.Plan> {
		const route = Edge.API.ServerRoute.forAction('plans', 'evaluation');
		return await Edge.API.get(route);
	}

	public static async getCanEvaluate(): Promise<boolean> {
		const route = Edge.API.ServerRoute.forAction('plans', 'me/canEvaluate');
		return await Edge.API.get(route);
	}

	public static async deleteUserPlan(userId: string): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('plans', `user/${userId}`);
		return await Edge.API.delete(route);
	}
}
