import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Edge from '../../core';
import FormField from '../../components/formField';
import Input from '../../components/global/input';
import Error from '../../components/global/error';
import Loading from '../../components/loading';

export interface OrganizationProfileFormProps {
	initialValues?: Partial<OrganizationProfileFormValues>;
	onSubmit: (values: OrganizationProfileFormValues) => Promise<void>;
}

export interface OrganizationProfileFormValues {
	name?: string;
}

const organizationProfileSchema = Yup.object().shape({
	name: Yup.string().required('Name is required'),
});

export default class OrganizationProfileForm extends React.Component<OrganizationProfileFormProps> {
	public render() {
		const { initialValues, onSubmit } = this.props;
		return (
			<>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							name: '',
						},
						initialValues || {}
					)}
					validationSchema={organizationProfileSchema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await onSubmit(values);
						} catch (e) {
							const message = Edge.API.getErrorMessage(e);
							actions.setStatus(message);
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<OrganizationProfileFormValues>) => (
						<Form>
							<FormField type="text" name="name" description="Name" />
							{props.status && <Error>{props.status}</Error>}
							{props.isSubmitting && <Loading buttonLoader />}
							<Input type="submit" disabled={props.isSubmitting} value="Save" />
						</Form>
					)}
				/>
			</>
		);
	}
}
