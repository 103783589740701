import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as moment from 'moment';

import * as Edge from '../../../core';
import FormField from '../../../components/formField';
import Input from '../../../components/global/input';
import Error from '../../../components/global/error';
import ConfigLayout from '../configLayout';
import { FullScreenService } from '../../../services/fullScreenService';

export interface FlexibilityConfigFormProps {
	mode: Edge.Models.ExerciseMode;
	initialConfiguration: Partial<Edge.Models.FlexibilityExerciseConfiguration>;
	lockConfiguration: boolean;
	onSubmit: (configuration: Edge.Models.FlexibilityExerciseConfiguration, fullScreen: boolean) => Promise<void>;

	operationName: string;
}

export interface FlexibilityConfigFormValues {
	durationSeconds: string;
	size: string;
	level: string;
	fullScreen: boolean;
}

const schema = Yup.object().shape({
	durationSeconds: Yup.number(),
	size: Yup.string().required('Image size is required'),
	level: Yup.number().required('Level is required'),
	fullScreen: Yup.boolean(),
});

interface InstructionProps {
	config: FlexibilityConfigFormValues;
}

const instructions = ({ config }: InstructionProps) => (
	<ul>
		<li>Red/Blue glasses are worn during this exercise.</li>
		<li>
			You will see two red/blue dotted boxes overlapping one another. Within the box, you should be able to
			perceive a 3D diamond shape. The diamond shape will appear at the top, bottom, right or left in the box.
			Determine where the diamond shape is located, and then using the arrow keys, indicate the position where you
			perceive the diamond.
		</li>
		<li>
			During the exercise, the difficulty will increase as you get more correct, which will increase the
			separation of the once overlapping boxes. If you answer incorrectly twice in a row, the boxes will reset to
			their beginning position.
		</li>
		<li>The exercise will last for {moment.duration(parseInt(config.durationSeconds), 'seconds').humanize()}.</li>
	</ul>
);

export default class FlexibilityConfigForm extends React.Component<FlexibilityConfigFormProps> {
	public render() {
		const { initialConfiguration, lockConfiguration, onSubmit, operationName, mode } = this.props;
		const name =
			initialConfiguration.exerciseTypeId === Edge.Models.ExerciseTypeId.Convergence
				? 'Convergence'
				: initialConfiguration.exerciseTypeId === Edge.Models.ExerciseTypeId.Divergence
				? 'Divergence'
				: 'Alternating';
		return (
			<>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							durationSeconds: (initialConfiguration.durationSeconds || 120).toString() || '120',
							size: (initialConfiguration.size || 1).toString() || '1',
							level: (initialConfiguration.level || 1).toString() || '1',
							fullScreen: false,
						}
					)}
					validationSchema={schema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await onSubmit(
								{
									id: initialConfiguration.id,
									exerciseTypeId: initialConfiguration.exerciseTypeId!,
									durationSeconds: parseInt(values.durationSeconds),
									level: parseInt(values.level),
									size: parseInt(values.size),
									// #1396: https://dev.azure.com/vizualedge/Edge%20Trainer/_backlogs/backlog/Edge%20Trainer%20Team/Stories/?workitem=1396
									// TODO: rework this to avoid the cast
								} as Edge.Models.FlexibilityExerciseConfiguration,
								values.fullScreen
							);
						} catch (e) {
							actions.setStatus(Edge.API.getErrorMessage(e));
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<FlexibilityConfigFormValues>) => (
						<Form>
							<ConfigLayout
								operationName={operationName}
								exerciseName={name}
								headerActions={
									<>
										<Input
											type="submit"
											disabled={props.isSubmitting}
											value={mode === 'evaluate' ? 'Start Exercise' : `Train ${name}`}
										/>
										{props.status && <Error>{props.status}</Error>}
										{FullScreenService.isEnabled() && (
											<FormField
												type="checkbox"
												name="fullScreen"
												description="View Full Screen"
											/>
										)}
									</>
								}
								requiresGlasses
								instructions={instructions({ config: props.values })}
								settings={
									<>
										<FormField
											component="select"
											name="durationSeconds"
											description="Session Duration"
											disabled={lockConfiguration}
										>
											<option value="120">2 minutes</option>
											<option value="300">5 minutes</option>
											<option value="600">10 minutes</option>
										</FormField>
										<FormField
											component="select"
											name="size"
											description="Image Size"
											disabled={lockConfiguration}
										>
											<option value="1">Small</option>
											<option value="3">Large</option>
										</FormField>
										<FormField
											component="select"
											name="level"
											description="Level"
											disabled={lockConfiguration}
										>
											<option>1</option>
											<option>2</option>
											<option>3</option>
											<option>4</option>
										</FormField>
									</>
								}
								videoUrl={Edge.Constants.VIDEOS.FLEXIBILITY}
							/>
						</Form>
					)}
				/>
			</>
		);
	}
}
