import * as React from 'react';
import classNames from 'classnames';

import './index.scss';

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
	error?: boolean;
	name?: string;
	id?: string;
}

export class Select extends React.Component<SelectProps> {
	public render(): JSX.Element {
		const {
			className,
			placeholder,
			onChange,
			onBlur,
			value,
			disabled,
			error,
			name,
			id,
			children,
			title,
			defaultValue,
		} = this.props;
		const realClassName = classNames({ error: error }, className);
		const props = {
			...(realClassName && { className: realClassName }),
			...(placeholder && { placeholder }),
			onChange,
			onBlur,
			value,
			disabled,
			name,
			id,
			children,
			title,
			defaultValue,
		};

		return (
			<div className="selectContain">
				<select {...props} />
			</div>
		);
	}
}

export default Select;
