/* eslint-disable */
$( function() {
	
	VizEdge.FlexibilityExercise = function() {
	    this.initialize.apply(this, arguments);
	};
	
	_.extend( VizEdge.FlexibilityExercise.prototype, {
    
    squareImageIDTemplate: _.template( "<%= color %>-<%= direction %><%= number %>" ),
    
    displaySquareLogMessage: _.template( "Displaying <%= direction %> diamond from image set <%= number %>. Blue offset = <%= blueOffset %> Red offset = <%= redOffset %>" ),
    stationChangeLogMessage: _.template( "<%= type %> station score: <%= stationScore %> max station: <%= maxStationScore %> station possible: <%= stationScorePossible %>" ),
    answerRecordedLogMessage: _.template( "<%= status %> answer recorded for <%= type %> image." ),
                
		initialize: function( mode, exerciseType, debugMode, renderScale, touchControls, sounds, configuration, completeExercise ) {
      
      var canvas = document.createElement('canvas');
      canvas.id = "stage";
      canvas.width = $(window).width() - 40;
      canvas.height = $(window).height() - 200;
      canvas.style.display = "inline";

      $(".stage-wrapper").append(canvas);
			paper.setup(canvas);
      this.canvasEl = canvas;
      
      _.bindAll( this, "onKeyDown", "onExitFullScreen" );
      
      // install keyboard handler
      this.acceptingInput = false;
      this.tool = new paper.Tool();      
      this.keyboard = new VizEdge.KeyboardControl({ keydown: this.onKeyDown });
      
      // constants
      this.imageScales = { small: 1, large: 2 };
      this.roundTimeout = 5000;
      this.roundPause = 300;
      this.directions = ["up","left","right","down"];
      this.stationScoreLevels = [152,77,52,39];
      this.debugStations = [1, 3, 8, 21, 34, 55];
      this.renderScale = renderScale;
      this.touchControls = touchControls;
      
      // scale pixel measurements
      this.imageScales = _.mapObject( this.imageScales, function( imageScale ) { return imageScale * renderScale });

      // settings
      this.mode = mode;
      this.exerciseType = exerciseType;
      this.level = 2;
      this.debugMode = (debugMode != 'true' ) ? false : true;
      this.completeExercise = completeExercise;

      if( this.debugMode ) {
        this.sessionLength = 30 * 1000; // 30 secs              
      } else {
        this.sessionLength = 2 * 60 * 1000; // 2 mins        
      }
      this.fullScreen = false;
      this.imageScale = 'large';

      if (configuration) {
        this.level = parseInt(configuration.level);
        
        this.convStationScorePossible = this.stationScoreLevels[this.level-1];
        this.divStationScorePossible = this.stationScoreLevels[this.level-1];
        
        this.imageScale = configuration.imageScale;
        
        var sessionDuration = parseInt(configuration.sessionDuration);
        this.sessionLength = sessionDuration * 1000; // seconds to milliseconds

        this.setFullScreen(configuration.fullScreen);
      }
      
      // load sound effects      
      this.buzzerSound = sounds.buzzer;
      this.crystalSound = sounds.crystal;
            
      // no sounds in debug mode
      if( this.debugMode ) {
        Howler.mute();
      }
      
		},
        
    onKeyDown: function( event ) {
      
      switch(event.key) {        
        case 'left':
          this.selectDirection('left');
          return false;

        case 'right':
          this.selectDirection('right');
          return false;
          
        case 'up':
          this.selectDirection('up');
          return false;

        case 'down':
          this.selectDirection('down');
          return false;
          
        default: 
          return true;
        
      }

    },
    
    startSessionTimer: function() {
      
      // set up a timer for the entire session
      this.sessionTimer = window.setTimeout( _.bind( function() {
        this.endSession();        
      }, this), this.sessionLength );    

      // render timer
      this.timerLayer = new paper.Layer();
      this.timerLayer.activate();
      
      var viewBounds = paper.project.view.bounds;
      var maxTimerBars = 60;
      
      var barSpacing = 5;
      var barWidth = 8;
      var barHeight = 20;
      var oneBarWidth = barWidth + barSpacing;
      var totalBarWidth = oneBarWidth * maxTimerBars;
      var xStartOffset = (viewBounds.width - totalBarWidth) / 2;
      var yOffset = viewBounds.height - barHeight - 10;
      
      for( var i=0; i < maxTimerBars; i++ ) {
        var xOffset = (oneBarWidth * i) + xStartOffset;
        var rectangle = new paper.Rectangle(new paper.Point(xOffset, yOffset), new paper.Size(barWidth, barHeight));
        var shape = new paper.Shape.Rectangle(rectangle);
        if( i == 5 ) { 
          // when we reach this bar, time to turn red
          shape.redMarker = true;
        }
        shape.fillColor = 'green';        
      }
            
      var barInterval = this.sessionLength / maxTimerBars;

      // set up a timer to remove the next bar      
      var nextBarDisappears = function nextBarDisappears() {
        if( !this.timerLayer.isEmpty() ) {

          var bar = this.timerLayer.lastChild;
          
          // if we reach the marked bar, turn red
          if( bar.redMarker ) {
            _.each( this.timerLayer.children, function( bar ) {
              bar.fillColor = 'red';              
            }, this );
          }
          
          bar.remove();
          paper.view.draw();
          
          // set up next tick
          window.setTimeout( _.bind( nextBarDisappears, this), barInterval );   
        }
      };

      // start ticking
      window.setTimeout( _.bind( nextBarDisappears, this), barInterval );    
    },
    
    selectDirection: function(direction) {
      // Prevents method from executing if exercise is not ready to accept input
      //  regardless of input source.
      if( !this.acceptingInput ) return true;

      if( direction == this.currentDirection ) {
        this.correctAnswer();    
        this.crystalSound.play();
      } else {
        this.wrongAnswer();
        this.buzzerSound.play();
      }

      this.endRound();
      this.startRound();
    },
    
    correctAnswer: function() {

        this.logger(this.answerRecordedLogMessage( { status: "correct", type: this.currentExerciseType } )); 

         if (this.currentExerciseType == "convergence") {
             this.convScoreCorrect += 1;
             this.convConsecutiveCorrectAnswers++;
             this.convConsecutiveWrongAnswers = 0;
        
             if (this.convConsecutiveCorrectAnswers >= 2) {
                 this.convConsecutiveCorrectAnswers = 0;
                
                 this.convStationScore = Math.min(this.convStationScore + 1, this.convStationScorePossible);
                 this.convMaxStationScore = Math.max(this.convMaxStationScore, this.convStationScore);
                 this.logger(this.stationChangeLogMessage({ type: this.currentExerciseType, stationScore: this.convStationScore, maxStationScore: this.convMaxStationScore, stationScorePossible: this.convStationScorePossible }));
             }
         }
         else {
             this.divScoreCorrect += 1;
             this.divConsecutiveCorrectAnswers++;
             this.divConsecutiveWrongAnswers = 0;
        
             if (this.divConsecutiveCorrectAnswers >= 2) {
                 this.divConsecutiveCorrectAnswers = 0;
                
                 this.divStationScore = Math.min(this.divStationScore + 1, this.divStationScorePossible);
                 this.divMaxStationScore = Math.max(this.divMaxStationScore, this.divStationScore);
                 this.logger(this.stationChangeLogMessage({ type: this.currentExerciseType, stationScore: this.divStationScore, maxStationScore: this.divMaxStationScore, stationScorePossible: this.divStationScorePossible }));
             }
         }
     },  

     wrongAnswer: function() {
       
         this.logger(this.answerRecordedLogMessage( { status: "wrong", type: this.currentExerciseType } )); 

         if (this.currentExerciseType == "convergence") {
             this.convConsecutiveWrongAnswers++;
             this.convConsecutiveCorrectAnswers = 0;
        
             // reset station score to starting point on two wrong answers
             if (this.convConsecutiveWrongAnswers == 1) {
                 this.convStationScore = Math.max(this.convStationScore - 1, 0);
                 this.logger(this.stationChangeLogMessage({ type: this.currentExerciseType, stationScore: this.convStationScore, maxStationScore: this.convMaxStationScore, stationScorePossible: this.convStationScorePossible }));
             }
            
             if (this.convConsecutiveWrongAnswers >= 2) {
                 this.convStationScore = 0;
                 this.convConsecutiveWrongAnswers = 0;
                 this.logger(this.stationChangeLogMessage({ type: this.currentExerciseType, stationScore: this.convStationScore, maxStationScore: this.convMaxStationScore, stationScorePossible: this.convStationScorePossible }));
             }
         }
         else {
             this.divConsecutiveWrongAnswers++;
             this.divConsecutiveCorrectAnswers = 0;
        
             // reset station score to starting point on two wrong answers
             if (this.divConsecutiveWrongAnswers == 1) {
                 this.divStationScore = Math.max(this.divStationScore - 1, 0);
                 this.logger(this.stationChangeLogMessage({ type: this.currentExerciseType, stationScore: this.divStationScore, maxStationScore: this.divMaxStationScore, stationScorePossible: this.divStationScorePossible }));
             }
            
             if (this.divConsecutiveWrongAnswers >= 2) {
                 this.divStationScore = 0;
                 this.divConsecutiveWrongAnswers = 0;
                 this.logger(this.stationChangeLogMessage({ type: this.currentExerciseType, stationScore: this.divStationScore, maxStationScore: this.divMaxStationScore, stationScorePossible: this.divStationScorePossible }));
             }
         }
     },
    
    startSession: function() {
      
      // exercise score      
      this.convScoreCorrect = 0;
      this.convConsecutiveCorrectAnswers = 0;
      this.convConsecutiveWrongAnswers = 0;
      this.convStationScore = 0;
      this.convStationScorePossible = this.stationScoreLevels[this.level-1];
      this.convMaxStationScore = 0;
    
      this.divScoreCorrect = 0;
      this.divConsecutiveCorrectAnswers = 0;
      this.divConsecutiveWrongAnswers = 0;
      this.divStationScore = 0;
      this.divStationScorePossible = this.stationScoreLevels[this.level-1];
      this.divMaxStationScore = 0;
      
      this.roundCount = 0;
      this.totalResponseTime = 0;
      this.setNumber = 1;
      this.currentExerciseType = (this.exerciseType == 'alternating') ? 'convergence' : this.exerciseType;
                  
      // begin session      
      this.loadImages();
      new VizEdge.ReadyGo( _.bind( function() {
        this.initTouchControls();
        this.startSessionTimer();
        this.startRound();
      }, this) );
          
    },
    
    isSessionOver: function() {
      return (this.sessionTimer == null);
    },
    
    isRoundOver: function() {
      return (this.roundTimer == null);
    },
        
    endSession: function() {      
      
      if( !this.isRoundOver() ) {
        this.endRound();
      }
      
      if( this.sessionTimer ) {
        window.clearTimeout( this.sessionTimer );	
        this.sessionTimer = null;
      }
                  
      // clean up
      paper.project.clear();
      paper.view.draw();
      this.tool.remove();
      this.keyboard.remove();

      this.setFullScreen(false);

      this.sendScore();
    },
    
    sendScore: function() {
      var stationScoreLevel = this.stationScoreLevels[this.level-1];

      this.completeExercise({
        exercise: 'flexibility',
        evaluation: this.mode == "evaluate",
        exerciseType: this.exerciseType,
        size: this.imageScale,
        duration: Math.round(this.sessionLength/1000),
        level: this.level,
        audio: true,
        conv: {
          scorePercent: this.calcScorePercent("convergence"),
          stationScore: this.convMaxStationScore,
          stationMax: stationScoreLevel,
        },
        div: {
          scorePercent: this.calcScorePercent("divergence"),
          stationScore: this.divMaxStationScore,
          stationMax: stationScoreLevel,
        },
      });
    },
    
    calcScorePercent: function( exerciseType ) {
      var score = ( exerciseType == "convergence" ) ? this.convScoreCorrect : this.divScoreCorrect;      
        
      if( this.roundCount > 0 ) {
        return (score / this.roundCount) * 100.0;
      } else {
        return 0.0;
      }
    },
        
    startRound: function() {
      
      // don't start a new round if the session has ended.
      if( this.isSessionOver() ) return;
                  
      var _startRound = _.bind( function _startRound() {
        
        // in debug mode, march things along regardless of the answers
        // if( this.debugMode ) {
        //   var step = this.roundCount % this.debugStations.length;
        //   this.divStationScore = this.debugStations[step];
        //   this.convStationScore = this.debugStations[step];
        //   this.logger( "Overriding div and conv station scores to: "+this.divStationScore );
        // }
        
        this.roundPauseTimer = null;
        this.displayNextSquare();

        this.roundTimer = window.setTimeout( _.bind( function() {
          this.wrongAnswer();     
          this.endRound();
          this.buzzerSound.play();   
          this.startRound();
        }, this), this.roundTimeout );
    
        this.acceptingInput = true;  
        this.roundStartTime = this.getTime();
        this.roundCount = this.roundCount + 1;
        
      }, this);

      if( this.roundCount > 0 ) {
        this.roundPauseTimer = window.setTimeout( _startRound, this.roundPause );  
      } else {
        _startRound();
      }
    
    },
    
    loadImages: function() {
      
      this.squareLayer = new paper.Layer();
      
      // load squares
      this.squareImages = {};
      _.each( this.directions, function(direction) {             
        _.each( ["red", "blue"], function(color) {
          _.each( [1,2], function(number) {
            var imageID = this.squareImageIDTemplate({ color: color, direction: direction, number: number });
            var squareImage = new paper.Raster(imageID);
            squareImage.position = paper.view.center;
            squareImage.scale(this.imageScales[this.imageScale]);
            squareImage.blendMode = 'difference';            
            squareImage.visible = false;
            this.squareImages[imageID] = squareImage;            
          }, this );
        }, this);        
      }, this );
    },
    
    initTouchControls: function() {
      if( this.touchControls ) {
        
        // bind touch handlers to exercise events
        var handlers = { left: _.bind( this.selectDirection, this, 'left' ),
                         right: _.bind( this.selectDirection, this, 'right' ),
                         up: _.bind( this.selectDirection, this, 'up' ),
                         down: _.bind( this.selectDirection, this, 'down' )
                        };

        this.dPad = new VizEdge.DPadControl( { showOK: false, handlers: handlers, tool: this.tool } );
      }
    },
    
    setFullScreen: function(value) {

      if( !BigScreen.enabled ) return;

      this.fullScreen = value;

      if( this.fullScreen ) {
        if (!BigScreen.element) {
          BigScreen.toggle( null, null, this.onExitFullScreen );
        }
      } else {
        if (BigScreen.element) {
          BigScreen.exit();
        }
        this.onExitFullScreen();
      }

    },

    onExitFullScreen: function() {  
    },
        
    getTime: function() {
      var d = new Date();
      return d.getTime();
    },
    
    logger: function( message ) {
      if( this.debugMode ) {
        console.log(message);
      }      
    },
    
    endRound: function() {
      
      // we must be in a round to accept input
      this.acceptingInput = false;

      // accumulate total response time
      var responseTime  = this.getTime() - this.roundStartTime;
      this.totalResponseTime = responseTime + this.totalResponseTime;

      if( this.roundPauseTimer ) {
        window.clearTimeout( this.roundPauseTimer );	
        this.roundPauseTimer = null;
      }
      
      if( this.roundTimer ) {
        window.clearTimeout( this.roundTimer );	
        this.roundTimer = null;
      }
      
      // alternate between image sets 1 and 2
      this.setNumber = (this.setNumber==1) ? 2 : 1;    
      
      if( this.exerciseType == "alternating" ) {
        this.currentExerciseType = (this.currentExerciseType == "convergence" ) ? "divergence" : "convergence";
      }  
      
    },

		displayNextSquare: function() {
      
      // hide any existing squares
      var scaledCenterPoint = new paper.Point(paper.view.center.x*this.renderScale, (paper.view.center.y-50)*this.renderScale);
      _.each( this.squareImages, function(squareImage) {
        squareImage.visible = false;
        squareImage.position = scaledCenterPoint;        
      });
      
      // choose a direction
      this.currentDirection = this.directions[_.random(this.directions.length-1)];
      var blueSquareID = this.squareImageIDTemplate( { direction: this.currentDirection, color: "blue", number: this.setNumber });
      var redSquareID = this.squareImageIDTemplate( { direction: this.currentDirection, color: "red", number: this.setNumber });
      
      // calculate offset
      var scale = this.imageScales[ this.imageScale ] * this.level;
      var offset, blueOffset, redOffset;
      if( this.currentExerciseType == "convergence" ) {
        offset = this.convStationScore * scale;
        blueOffset = Math.round(offset);
        redOffset = Math.round(-offset);
      } else {
        offset = this.divStationScore * scale;
        blueOffset = Math.round(-offset);
        redOffset = Math.round(offset);        
      }

      this.logger(this.displaySquareLogMessage({ direction: this.currentDirection, 
                                                 number: this.setNumber, 
                                                 blueOffset: blueOffset, 
                                                 redOffset: redOffset }));
                                                                                
      // show squares
      var blueSquare = this.squareImages[blueSquareID];
      blueSquare.position.x += blueOffset;
      blueSquare.visible = true;
      
      var redSquare = this.squareImages[redSquareID];
      redSquare.position.x += redOffset;
      redSquare.visible = true;
      
      paper.view.draw();
    }
    					
	});
	
});
			
