import * as Edge from '../../core';

export interface SessionState {
	isLoading: boolean;
	loadingError?: Edge.Models.EdgeError;
	data?: Edge.Models.ActiveSessionResponse;
	isChanging: boolean;
	changingError?: Edge.Models.EdgeError;
}

export const SESSION_GETACTIVE_START = 'SESSION_GETACTIVE_START';
export const SESSION_GETACTIVE_SUCCESS = 'SESSION_GETACTIVE_SUCCESS';
export const SESSION_GETACTIVE_FAILURE = 'SESSION_GETACTIVE_FAILURE';
export const SESSION_CHANGE_START = 'SESSION_CHANGE_START';
export const SESSION_CHANGE_SUCCESS = 'SESSION_CHANGE_SUCCESS';
export const SESSION_CHANGE_FAILURE = 'SESSION_CHANGE_FAILURE';

interface SessionCanStartStart extends Edge.Models.EdgeAction<undefined, typeof SESSION_GETACTIVE_START> {}

interface SessionCanStartSuccess
	extends Edge.Models.EdgeAction<Edge.Models.ActiveSessionResponse, typeof SESSION_GETACTIVE_SUCCESS> {
	recievedAt: Date;
}

interface SessionCanStartFailure
	extends Edge.Models.EdgeAction<Edge.Models.EdgeError, typeof SESSION_GETACTIVE_FAILURE> {}

interface SessionChangeStart extends Edge.Models.EdgeAction<undefined, typeof SESSION_CHANGE_START> {}

interface SessionChangeSuccess
	extends Edge.Models.EdgeAction<Edge.Models.ActiveSessionResponse, typeof SESSION_CHANGE_SUCCESS> {
	recievedAt: Date;
}

interface SessionChangeFailure extends Edge.Models.EdgeAction<Edge.Models.EdgeError, typeof SESSION_CHANGE_FAILURE> {}

export type SessionActionTypes =
	| SessionCanStartStart
	| SessionCanStartSuccess
	| SessionCanStartFailure
	| SessionChangeStart
	| SessionChangeSuccess
	| SessionChangeFailure;
