import * as React from 'react';
import classNames from 'classnames';

import './index.scss';

export interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
	error?: boolean;
}

export class TextArea extends React.Component<TextAreaProps> {
	public render(): JSX.Element {
		const { className, placeholder, onChange, onBlur, type, value, disabled, error, name, id } = this.props;
		const realClassName = classNames('custom-textarea', { error: error }, className);
		const props = {
			...(realClassName && { className: realClassName }),
			...(placeholder && { placeholder }),
			onChange,
			onBlur,
			type,
			value,
			disabled,
			name,
			id,
		};

		return <textarea {...props} />;
	}
}

export default TextArea;
