/* eslint-disable */
$( function() {

  // paper.Tool is sporadically not working, so this is a custom control using a HTML DOM event handler
	VizEdge.KeyboardControl = function() {
    this.initialize.apply(this, arguments);
  };

  // mapping from keyCode to the logical key we send to listeners
  //   see https://css-tricks.com/snippets/javascript/javascript-keycodes/ for codes
  var keyCodes = {
    // enter key
    13: 'enter',

    // arrow keys
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down',

    // WASD
    65: 'left', // a
    68: 'right', // d
    83: 'down', // s
    87: 'up', // w
  };

  _.extend( VizEdge.KeyboardControl.prototype, {

    initialize: function( options ) {
      this.keydown = ( options && options.keydown ) || function() {};
      _.bindAll( this, "handleEvent" );
      document.addEventListener('keydown', this.handleEvent);
    },

    remove: function() {
      document.removeEventListener('keydown', this.handleEvent);
    },

    handleEvent: function(event) {
      var keyCode = event.which || event.keyCode;
      var key = keyCodes[keyCode];
      if (key) {
        this.keydown({ key: key });
        paper.view.update();
      }
    }
  });
});
