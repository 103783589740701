import * as React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import * as Edge from '../../../core';
import { AppState } from '../../../store';
import { getIsNormalUser } from '../../../store/permissions/selector';
import { LoginRedirectService } from '../../../services/loginRedirectService';
import { TermsRedirectService } from '../../../services/termsRedirectService';
import Loading from '../../loading';

export interface AuthenticatedLayoutRouteProps extends RouteProps {
	component?: React.ComponentType<any>;
	render?: (props: RouteComponentProps<any>) => React.ReactNode;
	layout: React.ComponentType<any>;
	isAuthenticated: boolean;
	isLoading: boolean;
	isNormalUser: boolean;
	onlyNormalUsers?: boolean;
	allowAccessWithoutTerms?: boolean;
	profile?: Edge.Models.Profile;
}

/**
 * Wraps `<Route/>` from `react-router-dom` to ensure several things before rendering the component.
 * If the user isn't authenticated, they will be redirected to `/login`.
 * If `onlyNormalUsers` is true, non-normal-users will be shown `<AccessDenied/>`.
 * Unless `allowAccessWithoutTerms` is true, users who have not completed all the terms checkboxes will be redirected to `/terms`.
 */
export const AuthenticatedLayoutRouteComponent = ({
	render,
	component: Component,
	layout: Layout,
	isAuthenticated,
	isLoading,
	isNormalUser,
	onlyNormalUsers,
	allowAccessWithoutTerms,
	profile,
	...rest
}: AuthenticatedLayoutRouteProps) => (
	<Route
		{...rest}
		render={(props) => {
			const loading = (
				<Layout>
					<Loading blockItem />
				</Layout>
			);
			if (isAuthenticated) {
				if (onlyNormalUsers) {
					if (isLoading) {
						// still loading
						return loading;
					}
					if (!isNormalUser) {
						// you need to be a normal user to get here, you're not, and we're done loading the data for that - let's send you back where you should be (evaluation)
						return <Redirect to="/training/evaluation" />;
					}
				}
				if (!allowAccessWithoutTerms && !Edge.Configuration.disablePreload) {
					if (!profile) {
						// still loading the profile
						return loading;
					} else if (
						!profile.agreedToCookiesAtRegistration ||
						!profile.agreedToLicenseAgreementAtRegistration ||
						!profile.wasAtLeast13YearsOldAtRegistration
					) {
						// you haven't agreed to all the terms yet
						return <Redirect to={TermsRedirectService.buildTermsUrl()} />;
					}
				}
				return <Layout>{Component ? <Component {...props} /> : render ? render(props) : null}</Layout>;
			}
			return <Redirect to={LoginRedirectService.buildLoginUrl()} />;
		}}
	/>
);

function mapStateToProps(state: AppState) {
	const { isLoading, isNormalUser } = getIsNormalUser(state);
	const { profileState } = state;
	return {
		isAuthenticated: !!state.loginState.authenticated,
		isLoading,
		isNormalUser,
		profile: profileState.profile,
	};
}

export const AuthenticatedLayoutRoute: React.ComponentType<
	Pick<
		AuthenticatedLayoutRouteProps,
		Exclude<keyof AuthenticatedLayoutRouteProps, 'isAuthenticated' | 'isComplete' | 'isLoading' | 'isNormalUser'>
	>
> = connect(mapStateToProps)(AuthenticatedLayoutRouteComponent);
