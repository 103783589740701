import * as Redux from 'redux';
import { TimeSpanState, TimeSpanActionTypes, UPDATE_TIME_SPAN } from './types';

const initialState: TimeSpanState = {
	timeSpan: {
		days: 90,
	},
};

const timeSpanReducer: Redux.Reducer<TimeSpanState, TimeSpanActionTypes> = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_TIME_SPAN:
			return {
				...state,
				timeSpan: action.payload,
			};
		default:
			return state;
	}
};

export default timeSpanReducer;
