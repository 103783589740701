import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../../core';
import YourCurrentPlan from '../../../components/yourCurrentPlan';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { AppState } from '../../../store';
import ScrollToTopOnMount from '../../../components/global/scrollToTopOnMount';

export interface UserManagePlanProps {
	currentUserId: string;
}

const UserManagePlan: React.FunctionComponent<UserManagePlanProps> = (props) => (
	<>
		<ScrollToTopOnMount />
		<div className="appPageContainer">
			<YourCurrentPlan entityType={Edge.Models.EntityType.User} entityId={props.currentUserId} canEdit />
		</div>
	</>
);

function mapStateToProps({ loginState }: AppState) {
	const { authenticated, decodedToken } = loginState;
	return {
		// we want the Typescript result type of this to be string - the errorLoadingWrapperHOC will make sure we don't load if it's not available
		currentUserId: ((authenticated || undefined) && decodedToken && decodedToken.sub)!,
	};
}

export default connect(mapStateToProps)(errorLoadingWrapperHOC(UserManagePlan, (props) => !!props.currentUserId));
