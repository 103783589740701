import * as Edge from './../core';
import { AuthenticationService } from './authenticationService';

export abstract class UserService {
	public static async login(loginUserCommand: Edge.Models.LoginUserCommand): Promise<void> {
		// login
		const route = Edge.API.ServerRoute.forAction('users', 'login');
		const token: string = await Edge.API.postAnonymous<string>(route, loginUserCommand, {
			globalErrorHandling: false,
		});

		// store token
		AuthenticationService.setToken(token);
	}

	public static async refreshToken(): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('users', 'token');
		const token: string = await Edge.API.post<string>(route, {});

		// store token
		AuthenticationService.setToken(token);
	}

	public static async exists(token: string): Promise<boolean> {
		const route = Edge.API.ServerRoute.forAction('users', `exists/token/${token}`);
		return await Edge.API.getAnonymous(route);
	}

	public static async getInviteTokenMetadata(token: string): Promise<Edge.Models.InviteTokenMetadata> {
		const route = Edge.API.ServerRoute.forAction('users', `token/${token}`);
		return await Edge.API.getAnonymous(route);
	}

	public static async fulfillToken(token: string, positionId?: string): Promise<boolean> {
		const route = Edge.API.ServerRoute.forAction('users', `fulfillToken`);
		const userId = AuthenticationService.getUserId();
		if (!userId) {
			throw new Error('must be logged in');
		}
		return await Edge.API.patch(route, { userId, tokenId: token, positionId });
	}

	public static async uploadAvatar(imageData: string): Promise<string> {
		const route = Edge.API.ServerRoute.forAction('users', `uploadAvatar`);
		return await Edge.API.post(route, { imageData });
	}

	public static async logout(): Promise<void> {
		AuthenticationService.clearToken();
	}

	public static async userExists(username: string): Promise<boolean> {
		const route = Edge.API.ServerRoute.forAction('users', `exists/username/${username}`);
		return await Edge.API.getAnonymous(route, username);
	}

	public static async createUser(command: Edge.Models.CreateUserCommand): Promise<Edge.Models.CreateUserResult> {
		const route = Edge.API.ServerRoute.forAction('users');
		return await Edge.API.postAnonymous(route, command, {
			globalErrorHandling: false,
		});
	}

	public static async createTeamMember(command: Edge.Models.CreateTeamMemberCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('users', 'teamMember');
		return await Edge.API.post(route, command);
	}

	public static async editUser(command: Edge.Models.EditUserCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('users', 'editUser');
		return await Edge.API.patch(route, command);
	}

	public static async createUserAndOrganization(
		command: Edge.Models.CreateUserAndOrganizationCommand
	): Promise<Edge.Models.CreateUserAndOrganizationResult> {
		const route = Edge.API.ServerRoute.forAction('users', 'organizations');
		return await Edge.API.postAnonymous(route, command, {
			globalErrorHandling: false,
		});
	}

	public static async changeTeam(command: Edge.Models.ChangeTeamCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('users', 'changeTeam');
		return await Edge.API.patch(route, command);
	}

	public static async updateMyDetails(command: Edge.Models.UserDetailsCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('users');
		return await Edge.API.patch(route, command);
	}

	public static async forgotPassword(command: Edge.Models.ForgotPasswordCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('users', 'forgotPassword');
		return await Edge.API.patch(route, command);
	}

	public static async resetPassword(command: Edge.Models.ResetPasswordCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('users', 'resetPassword');
		return await Edge.API.patch(route, command);
	}

	public static async forgotUsername(command: Edge.Models.ForgotUsernameCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('users', 'forgotUsername');
		return await Edge.API.patch(route, command);
	}

	public static async changePassword(command: Edge.Models.ChangePasswordCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('users', 'changePassword');
		return await Edge.API.patch(route, command);
	}

	public static async createProspect(command: Edge.Models.CreateProspectCommand): Promise<string> {
		const route = Edge.API.ServerRoute.forAction('users/prospect');
		return await Edge.API.post(route, command);
	}

	public static async getAthleteCard(
		userId: string,
		timeSpanDays: number,
		teamId: string
	): Promise<Edge.Models.AthleteCard> {
		const route = Edge.API.ServerRoute.forAction(`users/athlete/${userId}/${timeSpanDays}/${teamId}`);
		return await Edge.API.get(route);
	}

	public static async getAllUserRoles(): Promise<Edge.Models.UserRoleResponse[]> {
		const route = Edge.API.ServerRoute.forAction('users/roles');
		return await Edge.API.get(route);
	}

	public static async loginProspect(command: Edge.Models.LoginProspectCommand): Promise<string> {
		const route = Edge.API.ServerRoute.forAction('users/login-prospect');
		return await Edge.API.post(route, command);
	}
}
