import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as moment from 'moment';

import * as Edge from '../../../core';
import FormField from '../../../components/formField';
import Input from '../../../components/global/input';
import Error from '../../../components/global/error';
import ConfigLayout from '../configLayout';
import { FullScreenService } from '../../../services/fullScreenService';

export interface PursuitsConfigFormProps {
	mode: Edge.Models.ExerciseMode;
	initialConfiguration: Partial<Edge.Models.PursuitsExerciseConfiguration>;
	lockConfiguration: boolean;
	onSubmit: (configuration: Edge.Models.PursuitsExerciseConfiguration, fullScreen: boolean) => Promise<void>;

	operationName: string;
}

export interface PursuitsConfigFormValues {
	durationSeconds: string;
	size: string;
	speed: string;
	fullScreen: boolean;
}

const schema = Yup.object().shape({
	durationSeconds: Yup.number(),
	size: Yup.string().required('Arrow Size is required'),
	speed: Yup.string().required('Arrow Speed is required'),
	fullScreen: Yup.boolean(),
});

interface InstructionProps {
	config: PursuitsConfigFormValues;
	startText: string;
}

const instructions = ({ config, startText }: InstructionProps) => (
	<ul>
		<li>Red/Blue glasses are NOT worn during this exercise.</li>
		<li>
			After clicking the '{startText}' button, an arrow will appear on the screen and move across the screen in a
			randomized path. Monitor the arrow's movements throughout the length of the exercise.
		</li>
		<li>
			As soon as the arrow changes direction in which it is <strong>pointing</strong>, use the arrow keys to
			indicate which direction the arrow has changed to (up, down, left, or right) as fast as possible.
		</li>
		<li>This exercise is scored based on both speed and accuracy.</li>
		<li>
			This exercise will last {moment.duration(parseInt(config.durationSeconds), 'seconds').humanize()} and you
			have <strong>3 seconds</strong> to answer each round.
		</li>
	</ul>
);

export default class PursuitsConfigForm extends React.Component<PursuitsConfigFormProps> {
	public render() {
		const { initialConfiguration, lockConfiguration, onSubmit, operationName, mode } = this.props;
		const startText = mode === 'evaluate' ? 'Start Exercise' : 'Train Pursuits';
		return (
			<Formik
				initialValues={Object.assign(
					{},
					{
						/** anything not specified here won't show an error message after an attempted submit */
						durationSeconds: (initialConfiguration.durationSeconds || 60).toString() || '60',
						size:
							(initialConfiguration.size || Edge.Models.ExerciseSize.Medium).toString() ||
							Edge.Models.ExerciseSize.Medium.toString(),
						speed:
							(initialConfiguration.speed || Edge.Models.ExerciseSpeed.Normal).toString() ||
							Edge.Models.ExerciseSpeed.Normal.toString(),
						fullScreen: false,
					}
				)}
				validationSchema={schema}
				onSubmit={async (values, actions) => {
					actions.setStatus(undefined);
					try {
						await onSubmit(
							{
								id: initialConfiguration.id,
								exerciseTypeId: Edge.Models.ExerciseTypeId.Pursuits,
								durationSeconds: parseInt(values.durationSeconds),
								size: parseInt(values.size),
								speed: parseInt(values.speed),
							},
							values.fullScreen
						);
					} catch (e) {
						actions.setStatus(Edge.API.getErrorMessage(e));
					}
					actions.setSubmitting(false);
				}}
				render={(props: FormikProps<PursuitsConfigFormValues>) => (
					<Form>
						<ConfigLayout
							operationName={operationName}
							exerciseName="Pursuits"
							headerActions={
								<>
									<Input type="submit" disabled={props.isSubmitting} value={startText} />
									{props.status && <Error>{props.status}</Error>}
									{FullScreenService.isEnabled() && (
										<FormField type="checkbox" name="fullScreen" description="View Full Screen" />
									)}
								</>
							}
							instructions={instructions({ config: props.values, startText })}
							settings={
								<>
									<FormField
										component="select"
										name="durationSeconds"
										description="Session Duration"
										disabled={lockConfiguration}
									>
										<option value="60">1 minute</option>
										<option value="120">2 minutes</option>
										<option value="180">3 minutes</option>
										<option value="300">5 minutes</option>
									</FormField>
									<FormField
										component="select"
										name="size"
										description="Arrow Size"
										disabled={lockConfiguration}
									>
										{Object.keys(Edge.Models.ExerciseSize)
											.filter((size) => typeof Edge.Models.ExerciseSize[size as any] === 'number')
											.map((size) => ({
												key: +Edge.Models.ExerciseSize[size as any],
												value: size.replace(/([A-Z])/g, ' $1').trim(), // formats `VeryFast` as `Very Fast`
											}))
											.filter((size) => size.key > 0)
											.map((size) => (
												<option key={size.key} value={size.key}>
													{size.value}
												</option>
											))}
									</FormField>
									<FormField
										component="select"
										name="speed"
										description="Arrow Speed"
										disabled={lockConfiguration}
									>
										{Object.keys(Edge.Models.ExerciseSpeed)
											.filter(
												(speed) => typeof Edge.Models.ExerciseSpeed[speed as any] === 'number'
											)
											.map((speed) => ({
												key: +Edge.Models.ExerciseSpeed[speed as any],
												value: speed.replace(/([A-Z])/g, ' $1').trim(), // formats `VeryFast` as `Very Fast`
											}))
											.filter((speed) => speed.key > Edge.Models.ExerciseSpeed.None)
											.map((speed) => (
												<option key={speed.key} value={speed.key}>
													{speed.value}
												</option>
											))}
									</FormField>
								</>
							}
							videoUrl={Edge.Constants.VIDEOS.PURSUITS}
						/>
					</Form>
				)}
			/>
		);
	}
}
