import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as Edge from '../../../core';
import { AppState } from '../../../store';
import Table from '../../../components/table';
import Error from '../../../components/global/error';

import './index.scss';
import AdminMenu from '../../../components/adminMenu';
import ModalContainer from '../../../components/global/modal';
import { KeysService } from '../../../services/keysService';
import Select from '../../../components/global/select';
import { getApiKeys, updateApiKey } from '../../../store/apiKey/actions';
import { ApiKeyUpdateType } from '../../../store/apiKey/types';
import Input from '../../../components/global/input';

class ManageKeysTable extends Table<Edge.Models.ApiKey> {}

interface CreateableApiKey {
	entityId: string;
	type: string;
	name: string;
}

export interface ManageKeysProps {
	currentOrganizationId?: string;
	apiKeys?: Edge.Models.ApiKey[]; // List of keys already created
	createableApiKeys?: CreateableApiKey[]; // List of keys that user can still create

	getApiKeys: (id: string) => Promise<void>; // Redux Action
	updateApiKey: (update: ApiKeyUpdateType) => Promise<void>; // Redux Action
	reloadData: () => Promise<void>; // from withLoadDataDefaultConfig
}

interface ManageKeysState {
	creatingKey?: boolean; // Opens Create Key modal
	createKeyCommand?: Edge.Models.EditKeyCommand; // Stores key to create from modal Select
	keyToRefresh?: Edge.Models.ApiKey; // Opens Refresh Key modal, stores key to refresh
	keyToToggle?: Edge.Models.ApiKey; // Opens Toggle Key modal, stores key to toggle
	keyEntityIdToAnim?: string; // Animates row that was recently created/updated
	error?: Edge.Models.EdgeError; // Displays error for server call exceptions
}

export class ManageKeys extends React.PureComponent<ManageKeysProps, ManageKeysState> {
	constructor(props: ManageKeysProps) {
		super(props);
		this.state = {};
	}

	public render() {
		const { currentOrganizationId, apiKeys, createableApiKeys } = this.props;
		const { creatingKey, keyToRefresh, keyToToggle, keyEntityIdToAnim, error } = this.state;

		// Redirect to parent page if there was an issue with loading data
		if (!apiKeys || !createableApiKeys) return <Redirect to="/manage-organization" />;

		return (
			<>
				<ManageKeysTable
					className="keys_table"
					items={apiKeys!}
					initialSort={['type_name']}
					renderHeader={() => <p>Organization ID: {currentOrganizationId}</p>}
					renderSubHeader={() => (
						<>
							{createableApiKeys!.length > 0 ? (
								<button onClick={this.openCreateKey} className="add_more_btn create_key_btn">
									Create Key
								</button>
							) : (
								<p className="create_key_btn">All keys have been created</p>
							)}
						</>
					)}
					renderTableHeader={(classNames) => (
						<tr>
							<th className={classNames('type_name')}>Type: Name</th>
							<th className={classNames('token')}>Key Token</th>
							<th className={classNames('status')}>Status</th>
							<th className={classNames('last_enable')}>Last Enabled</th>
							<th className="mobileHide" />
						</tr>
					)}
					renderTableItem={(i: Edge.Models.ApiKey) => (
						<tr>
							<td className={i.entityId === keyEntityIdToAnim ? 'fade_in' : ''}>
								{i.type}:<br />
								{i.name}
							</td>
							<td className={i.entityId === keyEntityIdToAnim ? 'fade_in' : ''}>{i.token}</td>
							<td className={i.entityId === keyEntityIdToAnim ? 'fade_in' : ''}>
								{i.isEnabled ? 'Enabled' : 'Disabled'}
							</td>
							<td className={i.entityId === keyEntityIdToAnim ? 'fade_in' : ''}>
								{moment(i.lastEnabledUtc).format(Edge.Constants.DATE_FORMAT)}
							</td>
							<td className="mobileHide">
								<AdminMenu>
									<div className="menu_item" onClick={() => this.openRefreshToken(i)}>
										Refresh Token
									</div>
									<div className="menu_item" onClick={() => this.openToggleKey(i)}>
										{`${i.isEnabled ? 'Disable' : 'Enable'} Key`}
									</div>
								</AdminMenu>
							</td>
						</tr>
					)}
					renderNoItems={() => (
						<tr>
							<td colSpan={5}>No keys found for this organization</td>
						</tr>
					)}
					pagingMode="seeMore"
					seeMoreText="See more keys"
					pageSize={10}
				/>

				{/* Create Key modal */}
				{creatingKey && createableApiKeys!.length > 0 && (
					<ModalContainer title="Create API Key" open={true} onClose={() => this.closeCreateKey()}>
						<p>Select the entity to create the API key for from the drop down below:</p>
						<form onSubmit={this.createKey}>
							<Select defaultValue="default" onChange={this.handleCreateKeyChange}>
								<option key="default" value="default" hidden disabled>
									Select entity
								</option>
								{createableApiKeys &&
									createableApiKeys.length > 0 &&
									createableApiKeys.map((entity, it) => (
										<option key={it + 1} value={entity.entityId}>
											{entity.type}: {entity.name}
										</option>
									))}
							</Select>
							{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
							<Input disabled={!!!this.state.createKeyCommand} value="Create" type="submit" />
						</form>
					</ModalContainer>
				)}

				{/* Refresh Key modal */}
				{keyToRefresh && (
					<ModalContainer title="Refresh API Key Token" open={true} onClose={() => this.closeRefreshToken()}>
						<p>
							Refresh the API Key token for {keyToRefresh.type} {keyToRefresh.name}?
						</p>
						{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
						<Input value="Refresh" type="submit" onClick={this.refreshToken} />
					</ModalContainer>
				)}

				{/* Toggle Key modal */}
				{keyToToggle && (
					<ModalContainer
						title={`${keyToToggle.isEnabled ? 'Disable' : 'Enable'} API Key`}
						open={true}
						onClose={() => this.closeToggleKey()}
					>
						<p>
							{keyToToggle.isEnabled ? 'Disable' : 'Enable'} the API key for {keyToToggle.type}{' '}
							{keyToToggle.name}?
						</p>
						{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
						<Input
							value={keyToToggle.isEnabled ? 'Disable' : 'Enable'}
							type="submit"
							onClick={this.toggleKey}
						/>
					</ModalContainer>
				)}
			</>
		);
	}

	// Create Key methods
	private openCreateKey = () => {
		this.setState({ creatingKey: true, keyEntityIdToAnim: undefined });
	};

	private closeCreateKey = (animate?: boolean) => {
		this.setState({
			creatingKey: false,
			createKeyCommand: undefined,
			keyEntityIdToAnim:
				animate && this.state.createKeyCommand ? this.state.createKeyCommand.entityId : undefined,
			error: undefined,
		});
	};

	private handleCreateKeyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		// Finds the createable key from the list by entity ID
		const keyToCreate =
			this.props.createableApiKeys && this.props.createableApiKeys.find((i) => i.entityId === e.target.value);

		this.setState({
			createKeyCommand: keyToCreate
				? { entityType: keyToCreate!.type, entityId: keyToCreate!.entityId }
				: undefined,
		});
	};

	private createKey = (e: React.FormEvent) => {
		e.preventDefault();
		const command = this.state.createKeyCommand;

		if (command) {
			const promise = new Promise(async (resolve, reject) => {
				try {
					await KeysService.createKey(command);
					await this.props.getApiKeys(this.props.currentOrganizationId!);

					resolve();
				} catch (error) {
					reject(error);
				}
			});

			promise.then(() => this.closeCreateKey(true));
			promise.catch((error) => this.setState({ error }));
		} else
			this.setState({
				error: {
					name: 'CommandUndefined',
					message: 'Undefined command when creating key',
				},
			});
	};

	// Refresh Token methods
	private openRefreshToken = (key: Edge.Models.ApiKey) => {
		this.setState({ keyToRefresh: key, keyEntityIdToAnim: undefined });
	};

	private closeRefreshToken = (animate?: boolean) => {
		this.setState({
			keyToRefresh: undefined,
			keyEntityIdToAnim: animate && this.state.keyToRefresh ? this.state.keyToRefresh.entityId : undefined,
			error: undefined,
		});
	};

	private refreshToken = () => {
		const key = this.state.keyToRefresh;

		if (key) {
			const command = { entityType: key.type, entityId: key.entityId } as Edge.Models.EditKeyCommand;

			const promise = new Promise(async (resolve, reject) => {
				try {
					const newTokenKey = await KeysService.refreshKey(command);
					await this.props.updateApiKey({ id: key.id, token: newTokenKey.token });

					resolve();
				} catch (error) {
					reject(error);
				}
			});

			promise.then(() => this.closeRefreshToken(true));
			promise.catch((error) => this.setState({ error }));
		} else
			this.setState({
				error: {
					name: 'KeyUndefined',
					message: 'Undefined key when refreshing token',
				},
			});
	};

	// Toggle Key methods
	private openToggleKey = (key: Edge.Models.ApiKey) => {
		this.setState({ keyToToggle: key, keyEntityIdToAnim: undefined });
	};

	private closeToggleKey = (animate?: boolean) => {
		this.setState({
			keyToToggle: undefined,
			keyEntityIdToAnim: animate && this.state.keyToToggle ? this.state.keyToToggle.entityId : undefined,
			error: undefined,
		});
	};

	private toggleKey = () => {
		const key = this.state.keyToToggle;

		if (key) {
			const command = { entityType: key.type, entityId: key.entityId } as Edge.Models.EditKeyCommand;

			const promise = new Promise(async (resolve, reject) => {
				try {
					const toggledKey = await KeysService.toggleKey(command);
					await this.props.updateApiKey({
						id: key.id,
						isEnabled: toggledKey.isEnabled,
						lastEnabledUtc: toggledKey.lastEnabledUtc,
					});

					resolve();
				} catch (error) {
					reject(error);
				}
			});

			promise.then(() => this.closeToggleKey(true));
			promise.catch((error) => this.setState({ error }));
		} else
			this.setState({
				error: {
					name: 'KeyUndefined',
					message: 'Undefined key when toggling',
				},
			});
	};
}

function mapStateToProps({ apiKeyState, organizationState, teamState }: AppState) {
	const { currentOrganization } = organizationState;
	const { teams } = teamState;
	const { apiKeys } = apiKeyState;

	const organizationTeams =
		currentOrganization && teams && teams!.filter((i) => i.organizationId === currentOrganization!.id);

	// Concats current Org with org teams and filters by which ones already have a key
	const createableApiKeys =
		currentOrganization &&
		organizationTeams &&
		apiKeys &&
		[
			{ entityId: currentOrganization!.id, type: 'Organization', name: currentOrganization!.name },
			...organizationTeams!.map((i) => ({ entityId: i.id, type: 'Team', name: i.name })),
		].filter((c) => !apiKeys!.find((k) => k.entityId === c.entityId));

	return {
		apiKeys,
		createableApiKeys,
		currentOrganizationId: currentOrganization && currentOrganization!.id,
	};
}

export default connect(
	mapStateToProps,
	{
		getApiKeys,
		updateApiKey,
	}
)(ManageKeys);
