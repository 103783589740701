import * as React from 'react';
import { connect } from 'react-redux';

import CreateTeam, { CreateTeamProps, CreateTeamValues } from './createTeam';
import * as Edge from '../../../core';
import { AppState } from '../../../store';
import { getLevels, getSports } from '../../../store/staticData';
import { TeamService } from '../../../services/teamService';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';

export interface CreateTeamContainerProps extends Pick<CreateTeamProps, Exclude<keyof CreateTeamProps, 'onSubmit'>> {
	afterCreate: (team: Edge.Models.Team) => Promise<void>;
	getLevels: () => void;
	getSports: () => void;
	isLoading: boolean;
	loadingError?: Edge.Models.EdgeError;
	organizationId?: string;
}

export class CreateTeamContainer extends React.Component<CreateTeamContainerProps> {
	public render() {
		return <CreateTeam {...this.props} onSubmit={this.onSubmit} />;
	}
	private onSubmit = async (values: CreateTeamValues) => {
		const { organizationId, afterCreate } = this.props;
		const team = await TeamService.create({
			eventPageUrl: values.eventPageUrl,
			levelId: values.levelId!,
			organizationId: organizationId!,
			sportId: values.sportId!,
			teamName: values.teamName!,
			teamTypeId: values.teamTypeId,
		});
		if (afterCreate) {
			await afterCreate(team);
		}
	};
}

function mapStateToProps({ organizationState, levelState, sportState }: AppState) {
	return {
		organizationId: organizationState.currentOrganization && organizationState.currentOrganization!.id,
		isLoading: levelState.isLoading || sportState.isLoading,
		loadingError: levelState.loadingError || sportState.loadingError,
		levels: levelState.data!,
		sports: sportState.data!,
	};
}

export default connect(
	mapStateToProps,
	{
		getLevels,
		getSports,
	}
)(
	errorLoadingWrapperHOC(CreateTeamContainer, (props) => !!props.sports && !!props.levels, undefined, (props) => {
		if (!props.levels) {
			props.getLevels();
		}
		if (!props.sports) {
			props.getSports();
		}
	})
);
