export default class Drawing {
	static fixCanvasDpi = (ctx: CanvasRenderingContext2D): void => {
		const scaledHeight = +getComputedStyle(ctx.canvas)
			.getPropertyValue('height')
			.slice(0, -2);
		const scaledWidth = +getComputedStyle(ctx.canvas)
			.getPropertyValue('width')
			.slice(0, -2);

		ctx.canvas.setAttribute('height', (scaledHeight * window.devicePixelRatio).toString());
		ctx.canvas.setAttribute('width', (scaledWidth * window.devicePixelRatio).toString());
	};

	static loadImageAsync = async (src: string): Promise<HTMLImageElement> => {
		const image = new Image();
		image.src = src;
		return new Promise((res) => {
			image.onload = () => {
				res(image);
			};
		});
	};

	static loadSvgAsImg = (src: Document): HTMLImageElement => {
		const serialized = new XMLSerializer().serializeToString(src);
		const image = new Image();
		const blob = new Blob([serialized], { type: 'image/svg+xml' });
		image.src = URL.createObjectURL(blob);

		return image;
	};
}
