import * as ReduxThunk from 'redux-thunk';
import * as Edge from './../../core';
import { AppState } from './..';
import { setCurrentTeam } from '../team/actions';
import { OrganizationService } from './../../services/organizationService';

import {
	ORGANIZATIONS_LOADING,
	ORGANIZATIONS_SUCCESS,
	ORGANIZATIONS_FAILURE,
	SET_CURRENT_ORGANIZATION,
	UPDATE_ORGANIZATION,
	REMOVE_ORGANIZATION,
	OrganizationUpdateType,
} from './types';
import AnalyticsService from '../../services/analyticsService';

// action creators
export const loadOrganizationsAction = () => {
	return {
		type: ORGANIZATIONS_LOADING,
	};
};

export const organizationsSuccessAction = (organizations: Edge.Models.Organization[]) => {
	return {
		type: ORGANIZATIONS_SUCCESS,
		payload: organizations,
	};
};

export const organizationsFailureAction = (error: Edge.Models.EdgeError) => {
	return {
		type: ORGANIZATIONS_FAILURE,
		payload: error,
		error: true,
	};
};

export const setCurrentOrganizationAction = (organization: Edge.Models.Organization) => {
	return {
		type: SET_CURRENT_ORGANIZATION,
		payload: organization,
	};
};

export const updateOrganizationAction = (organization: OrganizationUpdateType) => {
	return {
		type: UPDATE_ORGANIZATION,
		payload: organization,
	};
};

export const removeOrganizationAction = (id: string) => {
	return {
		type: REMOVE_ORGANIZATION,
		payload: id,
	};
};

// thunk actions
export const getOrganizations = (): ReduxThunk.ThunkAction<
	Promise<void>,
	AppState,
	null,
	Edge.Models.EdgeAction
> => async (dispatch) => {
	dispatch(loadOrganizationsAction());

	try {
		const organizations = await OrganizationService.getAll();
		dispatch(organizationsSuccessAction(organizations));
	} catch (error) {
		dispatch(organizationsFailureAction(error));
	}
};

export const setCurrentOrganization = (
	organization: Edge.Models.Organization
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(setCurrentTeam(undefined));
	dispatch(setCurrentOrganizationAction(organization));
	AnalyticsService.event('set_organization', {
		organizationId: organization.id,
	});
};

export const updateOrganization = (
	updates: OrganizationUpdateType
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(updateOrganizationAction(updates));
};

export const removeOrganization = (
	id: string
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(removeOrganizationAction(id));
};
