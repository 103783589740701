import * as Edge from './../../core';

export interface TeamState {
	teams?: Edge.Models.Team[];
	currentTeam?: Edge.Models.Team;
	isLoading: boolean;
	loadingError?: Edge.Models.EdgeError;
}

export const TEAMS_LOADING = 'TEAMS_LOADING';
export const TEAMS_SUCCESS = 'TEAMS_SUCCESS';
export const TEAMS_FAILURE = 'TEAMS_FAILURE';
export const SET_CURRENT_TEAM = 'SET_CURRENT_TEAM';
export const UPDATE_TEAM = 'UPDATE_TEAM';

interface TeamsLoading extends Edge.Models.EdgeAction<typeof TEAMS_LOADING> {
	type: typeof TEAMS_LOADING;
}

interface TeamsSuccess extends Edge.Models.EdgeAction<Edge.Models.Team[], typeof TEAMS_SUCCESS> {
	type: typeof TEAMS_SUCCESS;
	payload: Edge.Models.Team[];
}

interface TeamsFailure extends Edge.Models.EdgeAction<Edge.Models.EdgeError, typeof TEAMS_FAILURE> {
	type: typeof TEAMS_FAILURE;
	payload: Edge.Models.EdgeError;
}

interface SetCurrentTeam extends Edge.Models.EdgeAction<Edge.Models.Team, typeof SET_CURRENT_TEAM> {
	type: typeof SET_CURRENT_TEAM;
	payload: Edge.Models.Team;
}

interface UpdateTeam extends Edge.Models.EdgeAction<TeamUpdateType, typeof UPDATE_TEAM> {
	type: typeof UPDATE_TEAM;
	payload: TeamUpdateType;
}

export type TeamActionTypes = TeamsLoading | TeamsSuccess | TeamsFailure | SetCurrentTeam | UpdateTeam;

export type TeamUpdateType = Pick<Partial<Edge.Models.Team>, Exclude<keyof Edge.Models.Team, 'id'>> &
	Pick<Edge.Models.Team, 'id'>;
