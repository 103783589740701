export const colorRanges = [
	{ min: 0, max: 10, color: '#8a0f0f', label: 'Very Poor' },
	{ min: 11, max: 25, color: '#EE2F4A', label: 'Poor' },
	{ min: 26, max: 40, color: '#fda621', label: 'Fair' },
	{ min: 41, max: 60, color: '#5CCAEA', label: 'Average' },
	{ min: 61, max: 75, color: '#004168', label: 'Good' },
	{ min: 76, max: 90, color: '#7cbd3d', label: 'Excellent' },
	{ min: 91, max: 100, color: '#128600', label: 'Superior' },
];

export const getColor = (score: number) => {
	const range = colorRanges.find((range) => score >= range.min && score <= range.max);

	if (range) return range.color;

	return 'black';
};
