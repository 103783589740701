import { createSelector } from 'reselect';
import { AppState } from '..';
import { UserPermissionService } from '../../services/userPermissionService';
import * as Edge from '../../core';
import TeamTypeService from '../../services/teamTypeService';

const getPermissionState = ({ permissionState }: AppState) => permissionState;
const getLoginState = ({ loginState }: AppState) => loginState;
const getTeamState = ({ teamState }: AppState) => teamState;
const getOrganizationState = ({ organizationState }: AppState) => organizationState;

export const getUserPermissionService = createSelector(
	[getLoginState, getPermissionState],
	(loginState, permissionState) => new UserPermissionService(loginState, permissionState)
);

export type AccessibleTeamFilter = (team: Edge.Models.Team, permissions: UserPermissionService) => boolean;
export interface AccessibleTeams {
	currentTeam?: Edge.Models.Team;
	teams?: Edge.Models.Team[];
}
/**
 * Creates a selector that uses the supplied filter to return only the teams matching the filter that are on the current organization.
 * @param filter Only includes teams for which this returns true.  Is passed the team and a UserPermissionService that can be used for security checks.
 */
export const createGetAccessibleTeams = (filter: AccessibleTeamFilter) =>
	createSelector(
		[getOrganizationState, getTeamState, getUserPermissionService],
		(organizationState, teamState, permissions) => {
			if (!organizationState || !teamState || !permissions) {
				return { teams: undefined, currentTeam: undefined };
			}
			const { currentOrganization } = organizationState;
			const { teams, currentTeam } = teamState;

			if (!currentOrganization || !teams) {
				return { teams: undefined, currentTeam: undefined };
			}

			return {
				teams: teams.filter((i) => i.organizationId === currentOrganization.id && filter(i, permissions)),
				currentTeam:
					currentTeam &&
					currentTeam.organizationId === currentOrganization.id &&
					filter(currentTeam, permissions)
						? currentTeam
						: undefined,
			};
		}
	);

/**
 * Calls @function createGetAccessibleTeams() with a filter that filter in teams that satisfy the following conditions:
 * 1. User has a team role within @param team that allows for evaluating AND
 * 2. @param team type is NOT of type ProspectOnly.
 */
export const getTeamsUserCanTrain = createGetAccessibleTeams(
	(team, permissions) =>
		permissions.getTeamRole(team).canEvaluate && team.type && !TeamTypeService.isProspectOnlyTeam(team.type)
);

/**
 * Calls @function createGetAccessibleTeams() with a filter that filter in teams that satisfy the following conditions:
 * 1. User has a team role within @param team that allows for evaluating AND
 * 2. User is NOT a non-prospect in a prospect-only team.
 */
export const getTeamsUserCanEval = createGetAccessibleTeams((team, permissions) => {
	const role = permissions.getTeamRole(team);
	if (!role.canEvaluate) return false;

	// Blocks normal users on prospect-only teams.
	if (role.isNormalUser && team.type && TeamTypeService.isProspectOnlyTeam(team.type)) {
		return false;
	}

	// Returns true here when person is either a prospect or is not in a prospect-only team.
	return true;
});

/**
 * Calls @function createGetAccessibleTeams() with a filter that filter in teams that satisfy the following conditions:
 * 1. User has a team role within @param team that allows for team editing.
 */
export const getTeamsUserCanEdit = createGetAccessibleTeams((team, permissions) =>
	permissions.hasTeamAccess(Edge.Models.PermissionLevels.Edit, team)
);

/**
 * Calls @function createGetAccessibleTeams() with a filter that filter in teams that satisfy the following conditions:
 * 1. User has a team role within @param team that allows for adding prospects AND
 * 2. @param team type is of type ProspectOnly.
 */

export const getTeamsUserCanAddProspect = createGetAccessibleTeams(
	(team, permissions) =>
		permissions.getTeamRole(team).canAddProspects && team.type && TeamTypeService.isProspectOnlyTeam(team.type)
);

/**
 * Calls @function createGetAccessibleTeams() with a filter that filter in all teams.
 */
export const getAllTeams = createGetAccessibleTeams(() => true);

export const getIsNormalUser = createSelector(
	[getOrganizationState, getAllTeams, getUserPermissionService],
	(organizationState, teamsResult, permissions) => {
		if (!organizationState || !teamsResult || !permissions) {
			return { isLoading: true, isNormalUser: false };
		}
		const { currentOrganization } = organizationState;
		const { teams } = teamsResult;

		if (!currentOrganization || !teams) {
			if (Edge.Configuration.disablePreload) {
				return { isLoading: false, isNormalUser: true };
			}
			return { isLoading: true, isNormalUser: false };
		}

		return {
			isLoading: permissions.isLoading(),
			isNormalUser:
				teams!.some((i) => permissions.getTeamRole(i).isNormalUser) ||
				(currentOrganization && permissions.getOrganizationRole(currentOrganization).isNormalUser) ||
				false,
		};
	}
);
