import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../../core';
import { withLoadDataDefaultConfig } from '../../../components/loadData';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import ExerciseList from '../exerciseList';
import PlanPageBase from '../planPageBase';
import StartButton from '../startButton';
import { PlanService } from '../../../services/planService';
import CompleteEvaluation from './completeEvaluation';
import './index.scss';
import JsonDebug from '../../../components/jsonDebug';
import { CanEvalTeamsBanner } from '../../../components/teamBanner';
import { getTeamsUserCanEval } from '../../../store/permissions/selector';
import { AppState } from '../../../store';
import { setCurrentTeamFromCache } from '../../../store/team/actions';

export interface EvaluationProps {
	currentOrganization?: Edge.Models.Organization;
	currentTeam?: Edge.Models.Team;
	plan: Edge.Models.Plan;
	setCurrentTeamFromCache: (
		currentOrganizationId: string,
		hasCurrentTeam: boolean,
		teams?: Edge.Models.Team[]
	) => void;
	teams?: Edge.Models.Team[];
}

export class Evaluation extends React.PureComponent<EvaluationProps> {
	public componentDidMount() {
		this.setCurrentTeamFromCache();
	}

	public componentDidUpdate() {
		this.setCurrentTeamFromCache();
	}

	public render() {
		const { plan, currentTeam } = this.props;
		return (
			<PlanPageBase
				title="Your Evaluation"
				operationName="Evaluation"
				hideAreaNav
				currentTeam={currentTeam}
				banner={<CanEvalTeamsBanner />}
				plan={plan}
				planPhase={plan.phases[0]}
				renderPlan={this.renderBody}
				renderResults={this.renderResults}
			/>
		);
	}
	private renderResults = () => {
		return <CompleteEvaluation />;
	};
	private renderBody = (trainNow: () => void) => {
		const { plan } = this.props;
		return (
			<div className="evaluation">
				<p>
					Ready to evaluate your visual processing skills? During this evaluation, a series of exercises will
					determine how your visual skills measure up. The Edge Trainer will display your score after each
					exercise. At the end of your evaluation, you will receive a detailed report, analyzing your visual
					processing skills. Your results will also be stored in the 'Stats' section so you may track your
					progress as you train.
				</p>
				<p>
					Starting this evaluation will use one of your sessions if you are on a session-based plan. If you
					are on a subscription-based plan, your subscription will start after you have completed your
					evaluation. This session will be available for up to 45 minutes. Most evaluations take approximately
					15 minutes to complete.
				</p>
				<p>NOTE: These products are not medical devices and have not been reviewed by the FDA.</p>
				<StartButton onClick={trainNow} planPhase={plan.phases[0]} />
				<ExerciseList configs={plan.phases[0].exerciseConfigurations} />
				<JsonDebug data={plan} />
			</div>
		);
	};

	private setCurrentTeamFromCache = () => {
		const { currentOrganization, currentTeam, setCurrentTeamFromCache, teams } = this.props;
		setCurrentTeamFromCache(currentOrganization ? currentOrganization.id : '', !!currentTeam, teams);
	};
}

function mapStateToProps(state: AppState) {
	const { currentOrganization } = state.organizationState;
	return {
		currentOrganization,
		...getTeamsUserCanEval(state),
	};
}

export default connect(
	mapStateToProps,
	{
		setCurrentTeamFromCache,
	}
)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(Evaluation),
		() => {},
		async () => {
			return {
				plan: await PlanService.getEvaluationPlan(),
			};
		}
	)
);
