import * as React from 'react';
import classNames from 'classnames';

import * as Edge from '../../../core';
import ExerciseResults from '../../../components/exerciseResults';

import './index.scss';

export interface ExerciseResultItem {
	exercise: string;
	correctPercent?: number;
	scoreText?: string;
	responseTimeMilliseconds?: number;
	percentile?: number;
	textRating: string;
	description?: string;
}

export interface ExerciseResultLayoutProps {
	className?: string;
	results: Edge.Models.ExerciseResult[];
	exerciseName: React.ReactNode;
	description: React.ReactNode;
	moreDescription?: React.ReactNode;
	headerActions: React.ReactNode;
	hideExerciseResults: boolean;
}

const ExerciseResultLayout: React.FunctionComponent<ExerciseResultLayoutProps> = (props) => {
	const {
		className,
		results,
		exerciseName,
		description,
		moreDescription,
		headerActions,
		hideExerciseResults
	} = props;
	const [expanded, setExpanded] = React.useState(false);
	return (
		<div className={classNames('exercise_result_layout', className)}>
			<div className="header_area">
				<h2>{exerciseName}</h2>
				<h3>Exercise Complete</h3>
				<div className="desc_wrapper">
					<p>
						{description}
						{moreDescription &&
							(expanded ? (
								<div className="more_description">{moreDescription}</div>
							) : (
								<button className="cta_btn" onClick={() => setExpanded(!expanded)}>
									See More
								</button>
							))}
					</p>
					<div className="header_actions">{headerActions}</div>
				</div>
			</div>
			{!hideExerciseResults && <ExerciseResults results={results} showRank={true} />}
		</div>
	);
};

export default ExerciseResultLayout;
