import * as React from 'react';
import { ReactComponent as Logo } from '../../../assets/images/VZELogoFooter.svg';

import './index.scss';

export class Footer extends React.Component {
	public render(): JSX.Element {
		return (
			<footer className="footer">
				<nav className="footer_links">
					<a href="https://vizualedge.com/" target="_blank" rel="noopener noreferrer" className="footer_logo">
						<Logo />
					</a>
					<a href="https://vizualedge.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
						PRIVACY POLICY AND TERMS OF SERVICE
					</a>
				</nav>
				<p>{new Date().getFullYear()} © Copyright Vizual Edge, LLC. All Rights Reserved.</p>
			</footer>
		);
	}
}
