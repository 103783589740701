import * as React from 'react';
import { connect } from 'react-redux';

import CreateAccountForm from '../../components/createAccountForm';
import { CreateAccountFormValues } from '../../components/createAccountForm/createAccountForm';
import { UserService } from '../../services/userService';
import history from '../../services/history';
import * as Edge from '../../core';
import CardRegisterWrapper from './cardRegisterWrapper';
import { login } from '../../store/login/actions';
import ScrollToTopOnMount from '../../components/global/scrollToTopOnMount';
import BlockRegistration from '../../components/redirectExternal/blockRegistration';
import { CheckoutProps } from '../checkout/models';

export interface Page1Props extends CheckoutProps {
	login: (command: Edge.Models.LoginUserCommand) => Promise<void>;
}

export class Page1 extends React.Component<Page1Props> {
	public render() {
		if (!this.props.model.price) {
			return <BlockRegistration />;
		}
		return (
			<CardRegisterWrapper {...this.props} allowEdit>
				<ScrollToTopOnMount />
				<CreateAccountForm onSubmit={this.createAccount} includeOrganizationFields />
			</CardRegisterWrapper>
		);
	}
	private createAccount = async (values: CreateAccountFormValues) => {
		const userExists = await UserService.userExists(values.username!);
		if (userExists) {
			throw new Error(Edge.Constants.USER_EXISTS_ERROR_MESSAGE);
		}
		const result = await UserService.createUserAndOrganization(
			Object.assign({
				email: values.email!,
				firstName: values.firstName!,
				lastName: values.lastName!,
				username: values.username!,
				password: values.password!,
				wasAtLeast13YearsOldAtRegistration: values.wasAtLeast13YearsOldAtRegistration!,
				agreedToCookiesAtRegistration: values.agreedToCookiesAtRegistration!,
				agreedToLicenseAgreementAtRegistration: values.agreedToLicenseAgreementAtRegistration!,
				recaptchaConfirmation: values.recaptchaConfirmation!,
				organizationName: values.organizationName!,
				isIndividual: true,
				levelId: values.levelId!,
				sportId: values.sportId!,
				positionId: values.positionId,
			})
		);
		await this.props.onChange({ organizationId: result.organizationId });
		await this.props.login({
			username: values.username!,
			password: values.password!,
		});

		// if we have a SKU, proceed immediately to purchase process
		if (this.props.model.price) {
			history.push(`/register/page-2`);
		} else {
			history.push(`/`);
		}
	};
}

export default connect(
	undefined,
	{
		login,
	}
)(Page1);
