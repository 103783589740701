import * as React from 'react';
import CardRegister from '../../components/cardRegister';
import TotalCost from '../../components/totalCost';
import CurrentPlan from './currentPlan';
import { CheckoutProps } from '../checkout/models';

export interface CardRegisterWrapperProps extends Pick<CheckoutProps, 'model' | 'onChange'> {
	allowEdit?: boolean;
}

export default class CardRegisterWrapper extends React.Component<CardRegisterWrapperProps> {
	public render() {
		return (
			<CardRegister>
				<div className="left">{this.props.children}</div>
				{this.props.model && this.props.model.price && (
					<div className="right">
						<CurrentPlan price={this.props.model.price} />
						<div className="hRule" />
						<TotalCost
							price={this.props.model.price.price}
							quantity={this.props.model.quantity}
							onQuantityChanged={this.onQuantityChanged}
							numberOfGlasses={this.props.model.numberOfGlasses!}
							coupon={this.props.model.coupon}
							allowEdit={this.props.allowEdit}
							overrideSkuPrice={this.props.model.overrideProductPrice}
						/>
					</div>
				)}
			</CardRegister>
		);
	}
	private onQuantityChanged = (quantity: number) => {
		this.props.onChange({ quantity: quantity });
	};
}
