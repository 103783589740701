import * as React from 'react';
import enhanceWithClickOutside from 'react-click-outside';

import AcctDropdown from './acctDropdown';
import { AppState } from '../../../store';
import { connect } from 'react-redux';
import { Avatar } from '../../avatar';

interface HeaderState {
	isActive: boolean;
}
interface HeaderProps {
	name?: string;
	avatarUrl?: string;
}

export class AcctSection extends React.Component<HeaderProps, HeaderState> {
	constructor(props: HeaderProps) {
		super(props);
		this.state = { isActive: false };
	}
	handleClickOutside = () => {
		this.setState({ isActive: false });
	};

	toggleActive = () => {
		this.setState({ isActive: !this.state.isActive });
	};
	public render(): JSX.Element {
		return (
			<div
				className={`header_section header_dropdownbtn header_acct ${this.state.isActive ? 'active' : ''}`}
				onClick={this.toggleActive}
			>
				<Avatar src={this.props.avatarUrl} />
				<span className="acct_name">{this.props.name}</span>
				<div className="dropdown_chevron" />
				<AcctDropdown />
			</div>
		);
	}
}

function mapStateToProps({ loginState, profileState }: AppState) {
	const { decodedToken } = loginState;
	return {
		name: decodedToken && `${decodedToken.given_name} ${decodedToken.family_name}`,
		avatarUrl: profileState && profileState.profile && profileState.profile.avatarUrl,
	};
}

export default connect(mapStateToProps)(enhanceWithClickOutside(AcctSection));
