import * as React from 'react';
import Cropper from 'react-cropper';
import { ModalContainer } from '../global/modal';
import Error from '../global/error';
import * as Edge from '../../core';

import 'cropperjs/dist/cropper.css';
import './index.scss';

interface ImageUploaderProps {
	uploadImage: (imageSource: string) => Promise<void>;
}

interface ImageUploaderState {
	croppingAvatar: boolean;
	imageString?: string;
	errorMessage?: string;
}

export class ImageUploader extends React.Component<ImageUploaderProps, ImageUploaderState> {
	constructor(props: ImageUploaderProps) {
		super(props);
		this.state = {
			croppingAvatar: false,
		};
	}

	private getImage = (onChangeEvent: React.ChangeEvent<HTMLInputElement>) => {
		if (onChangeEvent.target.files && onChangeEvent.target.files[0]) {
			const imageFile: File = onChangeEvent.target.files[0];

			// confirm the file is an image
			if (imageFile.type.startsWith('image/')) {
				let reader = new FileReader();
				reader.onload = (loadedEvent: any) => {
					// got the uploaded image, begin cropping
					this.setState({
						croppingAvatar: true,
						imageString: loadedEvent.target.result,
					});
				};

				// get image string
				reader.readAsDataURL(imageFile);

				// reset the file input (if the user selects an image, closes without saving, then chooses same image)
				onChangeEvent.target.value = '';
			} else {
				this.setState({ errorMessage: 'Error: Invalid image file.' });
			}
		}
	};

	private saveImage = async () => {
		var cropper = this.refs.cropper as Cropper;
		var imageSource = cropper.getCroppedCanvas().toDataURL('image/jpeg');

		var image = new Image();
		image.onload = async () => {
			imageSource = this.resizeImage(
				image,
				Edge.Constants.IMAGES.MAX_IMAGE_WIDTH,
				Edge.Constants.IMAGES.MAX_IMAGE_WIDTH
			);

			if (imageSource.length <= Edge.Constants.IMAGES.MAX_IMAGE_SIZE) {
				await this.props.uploadImage(imageSource.split(',')[1]);
			} else {
				this.setState({ errorMessage: 'Error: File size too large.' });
			}
			this.closeModal();
		};
		image.src = imageSource;
	};

	private resizeImage(image: HTMLImageElement, width: number, height: number): string {
		var canvas = document.createElement('canvas');
		canvas.width = width;
		canvas.height = height;
		canvas.getContext('2d')!.drawImage(image, 0, 0, width, height);
		return canvas.toDataURL('image/jpeg');
	}

	private closeModal = () => {
		this.setState({ croppingAvatar: false });
	};

	public render(): JSX.Element {
		return (
			<div className="image_uploader">
				<label className="button_image_upload cta_btn btn_bright">
					{this.props.children}
					<input
						className="hidden_file_input"
						type="file"
						onChange={this.getImage}
						onClick={() => this.setState({ errorMessage: undefined })}
						accept={'image/*'}
						multiple={false}
					/>
				</label>
				{this.state.errorMessage && <Error>{this.state.errorMessage}</Error>}
				{this.state.croppingAvatar && (
					<ModalContainer open={true} title="Crop Your Photo" onClose={this.closeModal}>
						<Cropper
							ref="cropper"
							src={this.state.imageString}
							style={{ height: 400, width: '100%' }}
							aspectRatio={16 / 16}
						/>
						<div className="button_save_photo_wrapper">
							<button onClick={this.saveImage} className="button_image_upload">
								Save
							</button>
						</div>
					</ModalContainer>
				)}
			</div>
		);
	}
}

export default ImageUploader;
