import * as React from 'react';
import classNames from 'classnames';

import './index.scss';

export interface TabNavItem {
	onClick: (e: React.MouseEvent) => void;
	text: string;
}

export interface TabNavProps {
	items: TabNavItem[];
	current: number;
}

export default class TabNav extends React.Component<TabNavProps> {
	public render() {
		return (
			<ul className="area-navigation">
				{this.props.items.map((item, ix) => {
					const { text, ...rest } = item;
					return (
						<li key={ix}>
							{/* eslint-disable jsx-a11y/anchor-is-valid */}
							<a href="#" {...rest} className={classNames({ active: ix === this.props.current })}>
								{text}
							</a>
							{/* eslint-enable jsx-a11y/anchor-is-valid */}
						</li>
					);
				})}
			</ul>
		);
	}
}
