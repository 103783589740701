import * as React from 'react';
import * as Edge from '../../../core';
import moment from 'moment';
import queryString from 'query-string';

import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { withLoadDataDefaultConfig } from '../../../components/loadData';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { SessionService } from '../../../services/sessionService';
import { UserService } from '../../../services/userService';
import { StatsService } from '../../../services/statsService';
import JsonDebug from '../../../components/jsonDebug';
import ExerciseResults from '../../../components/exerciseResults';
import ScrollToTopOnMount from '../../../components/global/scrollToTopOnMount';
import RankChart from '../../../components/rankChart';
import { getSessionChartItems, getSessionWithProChartItems } from '../../../services/evaluationChartService';
import DownloadButton from '../../../components/downloadButton';
import pdfBanner from '../../../assets/images/pdf_banner.jpg';

import Growth from '../../../assets/images/icons/Growth.jpg';
import Medal from '../../../assets/images/icons/Medal.svg';
import Order from '../../../assets/images/icons/Order.svg';
import EATGraph from '../../../assets/images/EAT_graphic.svg';
import { getColor, colorRanges } from '../helper';
import { TeamService } from '../../../services/teamService';

export interface EvaluationResultsProps {
	currentTeam?: Edge.Models.Team;
	daysTimeSpan?: number;
	position?: Edge.Models.Position;
	proPercentiles?: Edge.Models.ProPercentile[];
	results: Edge.Models.ScoreCardResult;
	session: Edge.Models.BaseSessionResponse;
	teams?: Edge.Models.Team[];
}

function EvaluationResults({ proPercentiles, session, position, results, currentTeam }: EvaluationResultsProps) {
	const isProCompare = !!proPercentiles;
	const { averageRecentEdgeScore, averagePreviousEdgeScore } = results;

	const getPrintableSession = () => {
		return SessionService.getPrintableSession(session.sessionId);
	};

	const getDefaultFileName = () => {
		return `evaluation_${session.sessionId}_${moment(new Date()).format(
			Edge.Constants.FILE_DATE_FORMAT
		)}${isProCompare && '_pro'}.pdf`;
	};

	return (
		<div className="evaluation-results">
			<div className={'first-page'}>
				<ScrollToTopOnMount />
				<img className="no_screen evaluation_results_banner" alt="banner" src={pdfBanner} />
				<div className="top_header">
					<div>
						<h3>Your Edge Test Report</h3>
						<h3 className={'athlete_name no screen'}>
							{session.user && `${session.user.firstName} ${session.user.lastName}`}
							{' - '}
							{session.expiresUtc && moment(session.expiresUtc).format(Edge.Constants.DATE_FORMAT)}
							{isProCompare && ' (Pro Comparison)'}
						</h3>
					</div>

					<div className={'user_info'}>
						{currentTeam && <h4>{currentTeam.sport.name}</h4>}
						{currentTeam && <h4>{currentTeam.level.name}</h4>}
						{position && <h4>{position.name}</h4>}
					</div>

					<div className="download_pdf no_print">
						<DownloadButton defaultFileName={getDefaultFileName()} onClick={getPrintableSession}>
							Download PDF
						</DownloadButton>
					</div>
				</div>
				<p>
					The Edge Score is a comprehensive score out of 100 that takes the core-six visual skills into
					account, providing a benchmark number for assessing an athlete's overall visual and cognitive
					ability.
				</p>
				<div className="rank_item">
					{session.edgeScore !== undefined && session.edgeScore !== null && (
						<>
							<div className="summary">
								<div className={'summary_item'}>
									<h4>Edge Score</h4>
									<div className="rank_text">{(session.edgeScore * 100).toFixed(1)}</div>
									<img src={Order} alt={'Order icon'} />
								</div>
								<div className={'summary_item'}>
									<h4>Overall Rating</h4>
									<div
										className="rank_text"
										style={{
											color: `${getColor(
												session.explanationPercentile ? session.explanationPercentile * 100 : 0
											)}`,
										}}
									>
										{`${session.explanationRankingText}`.toUpperCase()}
									</div>
									<img src={Medal} alt={'Medal icon'} />
								</div>
								<div className={'summary_item'}>
									<h4>Growth</h4>
									<div className="score_section">
										<div className="rank_text">
											{results && averagePreviousEdgeScore === 0 ? (
												<span>N/A</span>
											) : (
												<span>
													{averageRecentEdgeScore > averagePreviousEdgeScore ? '+' : '-'}
													{Math.abs(
														((averageRecentEdgeScore - averagePreviousEdgeScore) /
															averagePreviousEdgeScore) *
															100
													).toFixed(1) + '%'}
												</span>
											)}
										</div>
									</div>
									<img src={Growth} alt={'Growth icon'} />
									<div>
										Since last evaluation <br />
										(N/A if first evaluation)
									</div>
								</div>
							</div>
						</>
					)}
				</div>
				<div className="gradient_line" />
				<div className="ranking_scale">
					<div>
						<h1>Percentile Ranks</h1>
						<p>
							Vizual Edge compares your evaluation scores to peers in your sport and level. The graph on
							the right depicts your current percentile rankings for each visual skill from this Edge
							Test.
						</p>
						<div className={'eat_graph'}>
							<img src={EATGraph} alt={'EAT Graph'} />
						</div>
					</div>

					<div className="rank_item">
						<RankChart
							data={
								isProCompare
									? getSessionWithProChartItems(session, proPercentiles!)
									: getSessionChartItems(session)
							}
							seriesLabels={['pro', 'youth']}
						/>
					</div>
				</div>
				<div className={'rank_legend page_break'}>{renderedColors}</div>
				<img className=" no_screen evaluation_results_banner" alt="banner" src={pdfBanner} />
			</div>
			<div>
				<ExerciseResults
					results={session.exerciseResults.filter((i) => i.complete)}
					proPercentiles={
						proPercentiles && proPercentiles!.filter((i) => i.type === Edge.Models.PercentileType.Exercise)
					}
				/>
			</div>
			<JsonDebug data={session} hide />
		</div>
	);
}

interface EvaluationResultsWrapperRouteProps {
	id: string;
	isProCompare?: string;
}

export interface EvaluationResultsWrapperProps
	extends EvaluationResultsProps,
		RouteComponentProps<EvaluationResultsWrapperRouteProps> {}

const EvaluationResultsWrapper: React.FunctionComponent<EvaluationResultsWrapperProps> = (props) => {
	const { session, proPercentiles, position, results, currentTeam } = props;
	return (
		<EvaluationResults
			session={session}
			proPercentiles={proPercentiles}
			position={position}
			results={results}
			currentTeam={currentTeam}
		/>
	);
};

const renderedColors = colorRanges.map((color) => {
	return (
		<div className="rendered_colors" key={color.label}>
			<svg className={'color_square'}>
				<rect width={'2.5rem'} height={'2.5rem'} fill={color.color} />
			</svg>
			<div>{color.label}</div>
			<div>
				{color.min === 0 && '(<11)'}
				{color.min === 91 && '(91+)'}
				{color.min !== 0 && color.min !== 91 && `(${color.min} - ${color.max})`}
			</div>
		</div>
	);
});

function mapStateToProps(state: AppState) {
	const { timeSpan } = state.timeSpanState;

	return { daysTimeSpan: timeSpan.days };
}

export default connect(mapStateToProps)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(EvaluationResultsWrapper),
		(props: EvaluationResultsWrapperProps) => {
			const query = queryString.parse(props.location.search);

			const isProCompareParam =
				query && (Array.isArray(query.isProCompare) ? query.isProCompare[0] : query.isProCompare);

			return {
				id: props.match.params.id,
				isProCompare: isProCompareParam && isProCompareParam.toLowerCase() === 'true',
				daysTimeSpan: props.daysTimeSpan!,
			};
		},
		async (props) => {
			const session = await SessionService.getSession(props.id);

			const data = await UserService.getAthleteCard(session.user!.id, props.daysTimeSpan, session.teamId);

			const { user, position, latestEdgeScore, previousEdgeScore, totalSessions, totalHoursTrained } = data;
			const results: Edge.Models.ScoreCardResult = {
				averageRecentEdgeScore: latestEdgeScore ? latestEdgeScore : 0,
				averagePreviousEdgeScore: previousEdgeScore ? previousEdgeScore : 0,
				totalSessions,
				totalHoursTrained,
			};

			const currentTeam = (await TeamService.getAll()).find((x) => x.id === session.teamId);

			return {
				currentTeam,
				results,
				user,
				position,
				session: await SessionService.getSession(props.id),
				proPercentiles: props.isProCompare && (await StatsService.getProPercentiles(props.id)),
			};
		}
	)
);
