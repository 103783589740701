import * as React from 'react';

import Input from '../../components/global/input';
import Loading from '../../components/loading';

export interface EnterEmailProps {
	email: string;
	loading: boolean;
	sendEmail: () => Promise<void>;
	updateEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const EnterEmail: React.FunctionComponent<EnterEmailProps> = (props) => {
	return (
		<>
			<div>Please enter the email address associated with your username.</div>
			<label htmlFor="Email">Confirm Email</label>
			<Input
				name="Email"
				id="Email"
				type="email"
				placeholder="Email"
				value={props.email}
				onChange={props.updateEmail}
				maxLength={256}
			/>
			{props.loading && <Loading buttonLoader />}
			<Input onClick={props.sendEmail} disabled={props.loading} type="submit" value="Confirm" />
			<a href="/forgotUsername">Forgot your username?</a>
		</>
	);
};

export default EnterEmail;
