import { createSelector } from 'reselect';
import { AppState } from '..';
import moment from 'moment';

const getSessionState = ({ sessionState }: AppState) => sessionState;

export const activeSessionSelector = createSelector(
	[getSessionState],
	(sessionState) => {
		if (!sessionState || !sessionState.data || !sessionState.data.active) {
			return Object.assign({}, sessionState, {
				getRemainingTime: (now?: Date) => undefined as moment.Duration | undefined,
			});
		}

		const { active, receivedDateUtc, responseDateUtc } = sessionState.data;

		return Object.assign({}, sessionState, {
			getRemainingTime: (now?: Date) =>
				moment.duration(
					moment(active.expiresUtc).diff(responseDateUtc) - moment(now || new Date()).diff(receivedDateUtc)
				),
		});
	}
);
