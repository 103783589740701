import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';

import * as Edge from '../../core';
import FormField from '../formField';
import Input from '../../components/global/input';
import Error from '../global/error';
import TermsFormFields from './termsFormFields';
import Loading from '../loading';

export interface CreateAccountFormProps {
	includeOrganizationFields?: boolean;
	organizationTypes: Edge.Models.OrganizationType[];
	levels: Edge.Models.Level[];
	sports: Edge.Models.Sport[];
	positions: Edge.Models.Position[];
	sportId?: string;
	roleId?: string;
	initialValues?: Partial<CreateAccountFormValues>;
	onSubmit: (values: CreateAccountFormValues) => Promise<void>;
}

export interface CreateAccountFormValues {
	firstName?: string;
	lastName?: string;
	email?: string;
	organizationName?: string;
	isIndividual?: boolean;
	organizationTypeId?: string;
	levelId?: string;
	sportId?: string;
	positionId?: string;
	username?: string;
	password?: string;
	passwordConfirmation?: string;
	wasAtLeast13YearsOldAtRegistration?: boolean;
	agreedToCookiesAtRegistration?: boolean;
	agreedToLicenseAgreementAtRegistration?: boolean;
	recaptchaConfirmation?: string | null;
}

const normalRequiredField = Yup.string()
	.max(100, 'Too long')
	.required();
const uniqueRequiredField = normalRequiredField.min(4, 'Too short');

const baseFields = {
	firstName: normalRequiredField.required('First name is required'),
	lastName: normalRequiredField.required('Last name is required'),
	email: normalRequiredField.email().required('Email is required'),
	username: uniqueRequiredField.required('Username is required'),
	password: Edge.Validation.password('Password'),
	passwordConfirmation: Yup.string()
		.required('Password is required')
		.oneOf([Yup.ref('password')], 'Passwords must match'),
	wasAtLeast13YearsOldAtRegistration: Yup.boolean().oneOf([true], 'You must be at least 13 years old.'),
	agreedToCookiesAtRegistration: Yup.boolean().oneOf([true], 'Your consent for us to use cookies is required.'),
	agreedToLicenseAgreementAtRegistration: Yup.boolean().oneOf(
		[true],
		'Compliance with our license agreement is required.'
	),
	recaptchaConfirmation: Yup.string().required('reCAPTCHA is required'),
};

const organizationFields = {
	isIndividual: Yup.boolean(),
	organizationName: uniqueRequiredField.required('Organization Name is required'),
	organizationTypeId: Yup.string().when('isIndividual', {
		is: true,
		then: Yup.string(),
		otherwise: Yup.string().required('Organization type is required'),
	}),
	levelId: Yup.string().when('isIndividual', {
		is: false,
		then: Yup.string(),
		otherwise: Yup.string().required('Level is required'),
	}),
	sportId: Yup.string().when('isIndividual', {
		is: false,
		then: Yup.string(),
		otherwise: Yup.string().required('Sport is required'),
	}),
};

const createAccountWithOrganizationSchema = Yup.object().shape(Object.assign({}, baseFields, organizationFields));
const createAccountSchema = Yup.object().shape(Object.assign({}, baseFields));

export default class CreateAccountForm extends React.Component<CreateAccountFormProps> {
	public render() {
		const {
			organizationTypes,
			positions,
			sports,
			levels,
			initialValues,
			onSubmit,
			includeOrganizationFields,
		} = this.props;
		return (
			<>
				<h3 className="cardTitle">Your Information</h3>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							firstName: '',
							lastName: '',
							email: '',
							isIndividual: true,
							organizationName: '',
							organizationTypeId: '',
							levelId: '',
							sportId: '',
							positionId: '',
							username: '',
							password: '',
							passwordConfirmation: '',
							wasAtLeast13YearsOldAtRegistration: false,
							agreedToCookiesAtRegistration: false,
							agreedToLicenseAgreementAtRegistration: false,
							recaptchaConfirmation: '',
						},
						initialValues || {}
					)}
					validationSchema={
						includeOrganizationFields ? createAccountWithOrganizationSchema : createAccountSchema
					}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await onSubmit(values);
						} catch (e) {
							const message = Edge.API.getErrorMessage(e);
							actions.setStatus(message);
							if (message === Edge.Constants.USER_EXISTS_ERROR_MESSAGE) {
								actions.setFieldError('username', message);
							}
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<CreateAccountFormValues>) => (
						<Form>
							<FormField type="text" name="firstName" description="First name" />
							<FormField type="text" name="lastName" description="Last name" />
							<FormField type="email" name="email" description="Email" />
							{this.props.includeOrganizationFields && (
								<>
									<FormField
										description="Organization Name"
										name="organizationName"
										placeholder={Edge.Constants.ORGANIZATION_NAME_PLACEHOLDER}
										type="text"
									/>
									{props.values.isIndividual ? (
										<>
											<FormField component="select" name="levelId" label="Level">
												<option key={-1} value={''} />
												{_.orderBy(levels, (l: Edge.Models.Level) => l.name).map(
													(l: Edge.Models.Level) => (
														<option key={l.id} value={l.id}>
															{l.id === Edge.Models.LevelId.Youth
																? Edge.Constants.YOUTH_LEVEL_LONG_DESCRIPTION
																: l.name}
														</option>
													)
												)}
											</FormField>
											<FormField component="select" name="sportId" label="Sport">
												<option key={-1} value={''} />
												{_.orderBy(sports, (i) => i.name).map((t, ix) => {
													return (
														<option key={ix} value={t.id}>
															{t.name}
														</option>
													);
												})}
											</FormField>
											{positions.filter((position) => position.sportId === props.values.sportId)
												.length > 0 && (
												<FormField component="select" name="positionId" label="Position">
													<option key={-1} value={''} />
													{_.orderBy(
														positions.filter(
															(position) => position.sportId === props.values.sportId
														),
														(i) => i.name
													).map((t, ix) => {
														return (
															<option key={ix} value={t.id}>
																{t.name}
															</option>
														);
													})}
												</FormField>
											)}
										</>
									) : (
										<>
											<FormField
												component="select"
												name="organizationTypeId"
												label="Organization Type"
											>
												<option key={-1} value={''} />
												{_.orderBy(organizationTypes, (i) => i.name).map((t, ix) => {
													return (
														<option key={ix} value={t.id}>
															{t.name}
														</option>
													);
												})}
											</FormField>
										</>
									)}
								</>
							)}
							{!this.props.includeOrganizationFields &&
								this.props.sportId &&
								this.props.roleId === Edge.Models.RoleId.Athlete &&
								positions.filter((position) => position.sportId === this.props.sportId).length > 0 && (
									<FormField component="select" name="positionId" label="Position">
										<option key={-1} value={''} />
										{_.orderBy(
											positions.filter((position) => position.sportId === props.values.sportId),
											(i) => i.name
										).map((t, ix) => {
											return (
												<option key={ix} value={t.id}>
													{t.name}
												</option>
											);
										})}
									</FormField>
								)}
							<FormField type="text" name="username" description="Username" />
							<FormField type="password" name="password" description="Password" />
							<FormField type="password" name="passwordConfirmation" description="Password (repeat)" />
							<TermsFormFields />
							<FormField component="recaptcha" name="recaptchaConfirmation" />
							<div className="hRule" />
							{props.status && <Error>{props.status}</Error>}
							{props.isSubmitting && <Loading buttonLoader />}
							<Input type="submit" disabled={props.isSubmitting} value="submit" />
							<p>
								By submitting this form, you confirm that you agree to the storing and processing of
								your personal data by Vizual Edge as described in our <a href="/">Terms of Service</a>{' '}
								and <a href="/"> Privacy Policy.</a>
							</p>
						</Form>
					)}
				/>
			</>
		);
	}
}
