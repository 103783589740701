import * as Edge from './../../core';

export interface DeviceProfileState {
	isLoading: boolean;
	currentProfile?: Edge.Models.DeviceProfile;
	profiles?: Edge.Models.DeviceProfile[];
	loadingError?: Edge.Models.EdgeError;
}

export const DEVICEPROFILE_GET_START = 'DEVICEPROFILE_GET_START';
export const DEVICEPROFILE_GET_SUCCESS = 'DEVICEPROFILE_GET_SUCCESS';
export const DEVICEPROFILE_GET_FAILURE = 'DEVICEPROFILE_GET_FAILURE';
export const DEVICEPROFILE_SET = 'DEVICEPROFILE_GET_SET';

interface DeviceProfileGetStart extends Edge.Models.EdgeAction<undefined, typeof DEVICEPROFILE_GET_START> {}

interface DeviceProfileGetSuccess
	extends Edge.Models.EdgeAction<Edge.Models.DeviceProfile[], typeof DEVICEPROFILE_GET_SUCCESS> {
	payload: Edge.Models.DeviceProfile[];
	currentProfile?: Edge.Models.DeviceProfile;
}

interface DeviceProfileGetFailure
	extends Edge.Models.EdgeAction<Edge.Models.EdgeError, typeof DEVICEPROFILE_GET_FAILURE> {}

interface DeviceProfileSet extends Edge.Models.EdgeAction<Edge.Models.DeviceProfile, typeof DEVICEPROFILE_SET> {}

export type DeviceProfileActionTypes =
	| DeviceProfileGetStart
	| DeviceProfileGetSuccess
	| DeviceProfileGetFailure
	| DeviceProfileSet;
