import * as React from 'react';
import { Link } from 'react-router-dom';
import enhanceWithClickOutside from 'react-click-outside';

import Navigation from '../navigation';
import { ReactComponent as Logo } from '../../../assets/images/logo.svg';

interface HeaderState {
	isActive: boolean;
}

export class Hamburger extends React.Component<{}, HeaderState> {
	constructor(HeaderState: any) {
		super(HeaderState);
		this.state = { isActive: false };
	}
	handleClickOutside = () => {
		this.setState({ isActive: false });
	};

	toggleActive = () => {
		this.setState({ isActive: !this.state.isActive });
	};
	public render(): JSX.Element {
		return (
			<div className={`vze_header_navbtn ${this.state.isActive ? 'active' : ''}`} onClick={this.toggleActive}>
				<div className="navbtn_burger" />
				<Link to="/">
					<div className="logo_wrapper">
						<Logo />
					</div>
				</Link>
				<span className="navbtn_text">Vizual Edge</span>
				<Navigation />
			</div>
		);
	}
}

export default enhanceWithClickOutside(Hamburger);
