import * as React from 'react';
import { connect } from 'react-redux';

import TeamMembers from './teamMembers';
import * as Edge from '../../core';
import ModalContainer from '../../components/global/modal';
import AssignRoles, { AssignRolesValues } from './assignRoles';
import { TeamService } from '../../services/teamService';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import AddAthletes from './addAthletes';
import CreateTeamMember, { CreateTeamMemberFormProps } from './createTeamMember';
import EmailTeam from './emailTeam';
import RemoveTeamMember from './removeTeamMember';
import ChangeOrganization from './changeOrganization';
import ChangeEventPageUrl from './changeEventPageUrl';
import { getTeams, updateTeam } from '../../store/team/actions';
import { PositionService } from '../../services/positionService';
import { UserService } from '../../services/userService';
import AdminMenu from '../../components/adminMenu';
import { AppState } from '../../store';

import { ReactComponent as AddUser } from '../../assets/images/icons/AddUser.svg';
import { ReactComponent as Envelope } from '../../assets/images/icons/Envelope.svg';
import { TeamUpdateType } from '../../store/team/types';

export interface ManageTeamsEditTeamProps {
	canEdit: boolean;
	isAdmin?: boolean;
	permission: Edge.Models.PermissionLevel;
	positions: Edge.Models.Position[];
	team: Edge.Models.Team;
	teamMembers: Edge.Models.TeamMember[];

	getTeams: () => Promise<Edge.Models.Team[]>;
	insufficientCredits: () => void;
	reloadData: () => Promise<void>;
	updateTeam: (updates: TeamUpdateType) => Promise<void>;
}
export interface ManageTeamsEditTeamState {
	addAthletes?: boolean;
	creatingCoach?: boolean;
	assignCoaches?: boolean;
	assignScouts?: boolean;
	creatingAthlete?: boolean;
	emailTeam?: boolean;
	changingOrganization?: boolean;
	changingEventPageUrl?: boolean;
	removingTeamMember?: Edge.Models.TeamMember;
}

export class ManageTeamsEditTeam extends React.PureComponent<ManageTeamsEditTeamProps, ManageTeamsEditTeamState> {
	constructor(props: ManageTeamsEditTeamProps) {
		super(props);
		this.state = {};
	}
	public render(): JSX.Element {
		const { team, teamMembers, positions } = this.props;

		const isEvent = team.type.id === Edge.Models.TeamTypeId.Event;
		const isProspectOnly = isEvent || team.type.id === Edge.Models.TeamTypeId.ProspectOnly;

		const {
			addAthletes,
			creatingCoach,
			assignCoaches,
			assignScouts,
			creatingAthlete,
			emailTeam,
			changingOrganization,
			changingEventPageUrl,
			removingTeamMember,
		} = this.state;

		return (
			<>
				<div className="editTeamPanel">
					<div className="btnGroup">
						<button onClick={this.openAssignCoaches}>
							<span className="plus_symbol">+</span>
							<span>Invite coach(es)</span>
						</button>
						<button onClick={this.openCreateCoach}>
							<span className="icon-wrapper">
								<AddUser />
							</span>
							<span>Create coach</span>
						</button>
						{isProspectOnly ? (
							<button onClick={this.openAssignScouts}>
								<span className="icon-wrapper">
									<AddUser />
								</span>
								<span>Assign scout(s)</span>
							</button>
						) : (
							<>
								<button onClick={this.openAddAthletes}>
									<span className="plus_symbol">+</span>
									<span>Invite athlete(s)</span>
								</button>
								<button onClick={this.openCreateAthlete}>
									<span className="icon-wrapper">
										<AddUser />
									</span>
									<span>Create athlete</span>
								</button>
							</>
						)}
						<button onClick={this.openEmailTeam}>
							<span className="icon-wrapper">
								<Envelope />
							</span>
							<span>Email team</span>
						</button>
						{this.props.isAdmin && (
							<AdminMenu>
								<button onClick={this.openChangeOrganization}>Change Organization</button>
								{isEvent && <button onClick={this.openChangeEventPageUrl}>Edit Event Page URL</button>}
							</AdminMenu>
						)}
					</div>
				</div>

				{addAthletes && (
					<ModalContainer open title="Invite Athlete(s) via email" onClose={this.closeAddAthletes}>
						<AddAthletes addAthletes={this.addAthletes} />
					</ModalContainer>
				)}
				{assignCoaches && (
					<ModalContainer open title="Assign Coaches" onClose={this.closeAssignCoaches}>
						<AssignRoles roleName="Coach" assignRoles={this.assignCoaches} />
					</ModalContainer>
				)}
				{assignScouts && (
					<ModalContainer open title="Assign Scouts" onClose={this.closeAssignScouts}>
						<AssignRoles roleName="Scout" assignRoles={this.assignScouts} />
					</ModalContainer>
				)}
				{creatingAthlete && (
					<ModalContainer open title="Create New Athlete" onClose={this.closeCreateAthlete}>
						<CreateTeamMember
							positions={positions}
							createTeamMember={this.createTeamMember}
							teamRoleId={Edge.Models.RoleId.Athlete}
							onClose={this.closeCreateAthlete}
						/>
					</ModalContainer>
				)}
				{creatingCoach && (
					<ModalContainer open title="Create New Coach" onClose={this.closeCreateCoach}>
						<CreateTeamMember
							createTeamMember={this.createTeamMember}
							teamRoleId={Edge.Models.RoleId.Coach}
							onClose={this.closeCreateCoach}
						/>
					</ModalContainer>
				)}
				{emailTeam && (
					<ModalContainer open title="Email Team" onClose={this.closeEmailTeam}>
						<EmailTeam emailTeam={this.emailTeam} />
					</ModalContainer>
				)}
				{changingOrganization && (
					<ModalContainer open title="Change Organization" onClose={this.closeChangeOrganization}>
						<ChangeOrganization changeOrganization={this.changeOrganization} />
					</ModalContainer>
				)}
				{isEvent && changingEventPageUrl && (
					<ModalContainer open title="Change Event Url" onClose={this.closeChangeEventPageUrl}>
						<ChangeEventPageUrl
							currentEventPageUrl={team.eventPageUrl}
							changeEventPageUrl={this.changeEventPageUrl}
						/>
					</ModalContainer>
				)}
				{removingTeamMember && (
					<ModalContainer open title="Remove Team Member" onClose={this.closeRemoveTeamMember}>
						<RemoveTeamMember
							teamMember={removingTeamMember}
							onConfirm={this.removeTeamMember}
							onCancel={this.closeRemoveTeamMember}
						/>
					</ModalContainer>
				)}

				<TeamMembers
					team={team}
					teamMembers={teamMembers}
					addAthlete={this.openAddAthletes}
					addScout={this.openAssignScouts}
					removeTeamMember={this.openRemoveTeamMember}
					positions={positions}
					isProspectOnly={isProspectOnly}
					reloadData={this.props.reloadData}
				/>
			</>
		);
	}

	private assignScouts = async (values: AssignRolesValues) => {
		const { team } = this.props;
		const { insufficientCredits } = await TeamService.addMembers({
			teamId: team.id,
			roleId: Edge.Constants.ROLES.SCOUT,
			emails: values.users.filter((i) => i),
		});
		this.closeAssignScouts();
		this.props.reloadData();
		this.checkInsufficientCredits(insufficientCredits);
	};
	private openAssignScouts = () => {
		this.setState({ assignScouts: true });
	};
	private closeAssignScouts = () => {
		this.setState({ assignScouts: false });
	};

	private assignCoaches = async (values: AssignRolesValues) => {
		const { team } = this.props;
		const { insufficientCredits } = await TeamService.addMembers({
			teamId: team.id,
			roleId: Edge.Constants.ROLES.COACH,
			emails: values.users.filter((i) => i),
		});
		this.closeAssignCoaches();
		this.props.reloadData();
		this.checkInsufficientCredits(insufficientCredits);
	};
	private openAssignCoaches = () => {
		this.setState({ assignCoaches: true });
	};
	private closeAssignCoaches = () => {
		this.setState({ assignCoaches: false });
	};

	private addAthletes = async (athletes: string[]) => {
		const { team } = this.props;
		const { insufficientCredits } = await TeamService.addMembers({
			teamId: team.id,
			roleId: Edge.Constants.ROLES.ATHLETE,
			emails: athletes,
		});
		this.closeAddAthletes();
		this.props.reloadData();
		this.checkInsufficientCredits(insufficientCredits);
	};
	private openAddAthletes = () => {
		this.setState({ addAthletes: true });
	};
	private closeAddAthletes = () => {
		this.setState({ addAthletes: false });
	};

	private closeCreateCoach = () => {
		this.setState({
			creatingCoach: false,
		});
	};

	public openCreateCoach = () => {
		this.setState({
			creatingCoach: true,
		});
	};

	private createTeamMember = async (
		values: CreateTeamMemberFormProps,
		onClose: () => void,
		teamRoleId?: Edge.Models.RoleId
	) => {
		const { firstName, lastName, email, positionId, username, password } = values;
		const teamId = this.props.team.id;
		await UserService.createTeamMember({
			firstName,
			lastName,
			email,
			username,
			password,
			teamId,
			positionId,
			teamRoleId,
		});
		onClose();
		this.props.reloadData();
	};

	private openCreateAthlete = () => {
		this.setState({ creatingAthlete: true });
	};
	private closeCreateAthlete = () => {
		this.setState({ creatingAthlete: false });
	};

	private emailTeam = async (message: string) => {
		const { team } = this.props;
		await TeamService.emailTeam({
			teamId: team.id,
			message: message,
		});
		this.closeEmailTeam();
		this.props.reloadData();
	};
	private openEmailTeam = () => {
		this.setState({ emailTeam: true });
	};
	private closeEmailTeam = () => {
		this.setState({ emailTeam: false });
	};

	private changeOrganization = async (newOrganizationId: string) => {
		await TeamService.changeOrganization({ teamId: this.props.team.id, newOrganizationId });
		await this.props.getTeams();
		this.closeChangeOrganization();
	};
	private openChangeOrganization = () => {
		this.setState({ changingOrganization: true });
	};
	private closeChangeOrganization = () => {
		this.setState({ changingOrganization: false });
	};

	private changeEventPageUrl = async (newEventUrl: string) => {
		await TeamService.update({ teamId: this.props.team.id, eventPageUrl: newEventUrl });
		await this.props.updateTeam({ id: this.props.team.id, eventPageUrl: newEventUrl });

		this.closeChangeEventPageUrl();
	};
	private openChangeEventPageUrl = () => {
		this.setState({ changingEventPageUrl: true });
	};
	private closeChangeEventPageUrl = () => {
		this.setState({ changingEventPageUrl: false });
	};

	private removeTeamMember = async () => {
		const { team } = this.props;
		const { removingTeamMember } = this.state;
		await TeamService.removeMember(team.id, removingTeamMember!.id);
		this.closeRemoveTeamMember();
		this.props.reloadData();
	};
	private openRemoveTeamMember = (teamMember: Edge.Models.TeamMember) => {
		this.setState({ removingTeamMember: teamMember });
	};
	private closeRemoveTeamMember = () => {
		this.setState({ removingTeamMember: undefined });
	};

	private checkInsufficientCredits = (insufficientCredits: boolean) => {
		if (insufficientCredits) {
			this.props.insufficientCredits();
		}
	};
}

function mapStateToProps(state: AppState) {
	const isAdmin = state.loginState.decodedToken && state.loginState.decodedToken.is_admin;
	return { isAdmin };
}

export default connect(
	mapStateToProps,
	{ getTeams, updateTeam }
)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(ManageTeamsEditTeam, undefined, undefined, undefined, {
			loadingOptions: { blockItem: true },
		}),
		(props: ManageTeamsEditTeamProps) => {
			return { team: props.team };
		},
		async ({ team }: { team: Edge.Models.Team }) => {
			const positions = (team.sport && (await PositionService.getAllForSport(team.sport.id))) || [];
			const teamMembers = await TeamService.getMembers(team.id);
			return { teamMembers, positions };
		}
	)
);
