import * as React from 'react';
import AppPageContainer from '../../../components/pageContainer';
import PageTitle from '../../../components/pageTitle';
import { AuthenticationService } from '../../../services';
import { connect } from 'react-redux';
import { switchToToken } from '../../../store/login/actions';
import history from '../../../services/history';

export interface EvaluationCompleteProps {
	switchToToken: (token: string, redirectPath: string) => Promise<void>;
}

interface EvaluationCompleteState {}

export class EvaluationComplete extends React.PureComponent<EvaluationCompleteProps, EvaluationCompleteState> {
	constructor(props: EvaluationCompleteProps) {
		super(props);
		this.state = {};
	}
	public render() {
		return (
			<React.Fragment>
				<PageTitle title="Evaluation Complete" />
				<AppPageContainer>
					<p>Your evaluation has been submitted.</p>
					<button className="cta_btn" onClick={this.returnToScout}>
						Return to scout dashboard
					</button>
				</AppPageContainer>
			</React.Fragment>
		);
	}
	private returnToScout = async () => {
		// switch back to the scout token
		const token = AuthenticationService.restoreToken();
		if (token) {
			await this.props.switchToToken(token, '/dashboard/scout');
		} else {
			history.push('/dashboard/scout');
		}
	};
}
export default connect(
	undefined,
	{
		switchToToken,
	}
)(EvaluationComplete);
