import * as React from 'react';

interface ManageTeamsCreateTeamProps {
	createTeam: () => void;
}

export class ManageTeamsCreateTeam extends React.PureComponent<ManageTeamsCreateTeamProps> {
	public render(): JSX.Element {
		return (
			<div>
				<div className="intro_cta_label">Create Your Team</div>
				<div className="intro_cta_wrapper">
					<div className={`intro_cta create_new_cta`} onClick={this.props.createTeam}>
						<span className="plus_symbol">+</span> Create new team
					</div>
					<div className="cta_follow_up">
						Welcome to Vizual Edge Trainer! Please select the link above to begin setting up your team.
					</div>
				</div>
			</div>
		);
	}
}

export default ManageTeamsCreateTeam;
