/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-script-url */
import * as React from 'react';
import { BaseExercise, BaseExerciseProps } from '../baseExercise';
import * as Edge from '../../../core';
import ExerciseLayout from '../../../layouts/exerciseLayout';

declare var VizEdge: any;
declare var $: any;

interface TrackingProps
	extends BaseExerciseProps<Edge.Models.TrackingExerciseResult, Edge.Models.TrackingExerciseConfiguration> {}

interface TrackingConfiguration {
	fullScreen: boolean;
	imageScale: 'small' | 'medium' | 'large';
	flashTime: number; // ms
	fixation: boolean;
	sessionDuration: number; // seconds
}

interface TrackingResult {
	exercise: 'tracking';
	evaluation: boolean;
	duration: number; // seconds
	audio: boolean;
	size: 'small' | 'medium' | 'large';
	flashTime: number; // ms
	fixation: boolean;
	scorePercent: number; // out of 100
	responseTime: number; // ms
}

export class Tracking extends BaseExercise<
	TrackingProps,
	Edge.Models.TrackingExerciseResult,
	Edge.Models.TrackingExerciseConfiguration,
	{}
> {
	createSession() {
		const { configuration } = this.props;
		return this.doCreateSession(
			this.props.mode,
			this.props.debugMode ? 'true' : 'false',
			this.props.renderScale,
			this.props.touchControls,
			{
				fullScreen: this.props.fullScreen,
				imageScale: ['', 'small', 'medium', 'large'][configuration.size] as any,
				flashTime: configuration.flashTimeMilliseconds,
				fixation: configuration.fixation,
				sessionDuration: configuration.durationSeconds,
			},
			this.completeExercise
		);
	}

	/**
	 * Typed wrapper over `new VizEdge.TrackingExercise`.
	 */
	private doCreateSession = (
		mode: 'train' | 'evaluate',
		debugMode: 'true' | 'false',
		renderScale: number,
		touchControls: boolean,
		configuration: TrackingConfiguration,
		completeExercise: (result: TrackingResult) => void
	) => {
		return new VizEdge.TrackingExercise(
			mode,
			debugMode,
			renderScale,
			touchControls,
			{ bubble: this.bubbleSound, buzzer: this.buzzerSound, crystal: this.crystalSound },
			configuration,
			completeExercise
		);
	};

	private completeExercise = (result: TrackingResult) => {
		const { configuration, completeExercise } = this.props;
		completeExercise({
			exerciseTypeId: Edge.Models.ExerciseTypeId.Tracking,
			audio: result.audio,
			exerciseConfigurationId: configuration.id,
			size:
				result.size === 'small'
					? Edge.Models.ExerciseSize.Small
					: result.size === 'medium'
					? Edge.Models.ExerciseSize.Medium
					: Edge.Models.ExerciseSize.Large,
			correctPercent: result.scorePercent / 100, // JS is out of 100, server is out of 1
			fixation: result.fixation,
			flashTimeMilliseconds: result.flashTime,
			durationSeconds: result.duration,
			responseTimeMilliseconds: result.responseTime,
		});
	};

	public render(): JSX.Element {
		return (
			<ExerciseLayout
				endExercise={this.endSession}
				completeExercise={this.completeSession}
				exerciseName="Tracking"
			>
				<div id="images">
					<img id="arrow-down-q" src="/images/arrow-down-q.gif" alt="Arrow down q" />
					<img id="arrow-left-q" src="/images/arrow-left-q.gif" alt="Arrow left q" />
					<img id="arrow-right-q" src="/images/arrow-right-q.gif" alt="Arrow right q" />
					<img id="arrow-up-q" src="/images/arrow-up-q.gif" alt="Arrow up q" />

					<img id="dpad-arrow" src="/images/dpad-arrow.png" alt="Dpad arrow" />
				</div>
			</ExerciseLayout>
		);
	}
}
