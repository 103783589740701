import * as React from 'react';
import classNames from 'classnames';
import { Bar } from '@vx/shape';
import { Group } from '@vx/group';
import { scaleLinear } from '@vx/scale';

import { getColor } from '../../pages/training/helper';

import './index.scss';

export interface ChartItem {
	label: string;
	value: number;
	value2?: number;
}

export interface RankChartProps {
	data: ChartItem[];
	seriesLabels?: string[]; // displays as ['series2', 'series1']; only used if "ChartItem.Any((i) => i.value2 != null)"
	minValue: number;
	maxValue: number;
	axisLabels: number[];
	height: number;
	width: number;
}

export interface StrippedBarProps {
	rectWidth: number;
	rectHeight: number;
	stroke: string;
	yCenter: number;
	xCenter: number;
}

interface RankChartState {}

// configuration variables for things that can't really be controlled via CSS
const margin = { top: 0, right: 12, bottom: 0, left: 0 };
const legendSize = 60;
const legendColorSize = 25;
const itemLabelWidth = 180;
const itemLabelHeight = 16;
const valueLabelHeight = 40;
const valueLabelSpacing = 20;
const barWidthFactor = 0.8;
const barLabelSpacing = 10;
const barOutsideMax = 0.8;
const badValueAxisOffsetMin = -0.11;
const badValueAxisOffsetMax = -0.02;

function correctForValueAxis(axisLabels: number[], value: number) {
	for (var i in axisLabels) {
		const label = axisLabels[i];
		if (value > label + badValueAxisOffsetMin && value < label + badValueAxisOffsetMax) {
			// we're in a bad spot - move to a safe spot
			return label + badValueAxisOffsetMax;
		}
	}

	// this spot isn't a problem - keep it
	return value;
}

export const StrippedBar: React.FC<StrippedBarProps> = ({ rectWidth, rectHeight, yCenter, xCenter }) => {
	return (
		<svg>
			<defs>
				<pattern id="strippedPattern" patternUnits="userSpaceOnUse" width="4" height="4">
					<path
						d="M-1,1 l2,-2
           M0,4 l4,-4
           M3,5 l2,-2"
					/>
				</pattern>
			</defs>
			<rect
				fill="url(#strippedPattern)"
				stroke="transparent"
				strokeWidth={8}
				x={xCenter}
				y={yCenter}
				width={rectWidth}
				height={rectHeight}
			/>
		</svg>
	);
};

export class RankChart extends React.PureComponent<RankChartProps, RankChartState> {
	static defaultProps = {
		minValue: 0,
		maxValue: 1,
		axisLabels: [0, 0.2, 0.4, 0.6, 0.8, 1.0],
		height: 400,
		width: 500,
	};

	constructor(props: RankChartProps) {
		super(props);
		this.state = {};
	}

	public render() {
		const { minValue, maxValue, height, width, axisLabels, data } = this.props;

		const value2HasValue = (i: ChartItem) => i.value2 !== undefined && i.value2 !== null;
		const has2Series = data.some(value2HasValue);
		const legendHeight = has2Series ? legendSize : 0;

		// Builds functions that accepts a value within property domain
		//  and returns a corresponding value from within property range.
		const valueScale = scaleLinear({
			range: [0, width - margin.left - margin.right - itemLabelWidth],
			domain: [minValue, maxValue],
		});
		const itemScale = scaleLinear({
			range: [0, height - margin.top - margin.bottom - valueLabelHeight],
			domain: [0, data.length || 1],
		});

		const barWidth = itemScale(barWidthFactor);

		return (
			<svg
				className={classNames('rank_chart', { has_legend: !!has2Series })}
				viewBox={`0 0 ${width} ${height + legendHeight}`}
			>
				{/* Legend */}
				{has2Series && (
					<Group className="legend" top={height} left={valueScale(0.5)}>
						{this.props.seriesLabels &&
							this.props.seriesLabels.map((i, ix) => {
								return (
									<Group key={ix} left={valueScale(ix * 0.5)}>
										<svg>
											<rect x={0} y={0} width={legendColorSize} height={legendColorSize} />
											{i === 'pro' && (
												<StrippedBar
													rectWidth={legendColorSize}
													rectHeight={legendColorSize}
													stroke={'white'}
													yCenter={0}
													xCenter={0}
												/>
											)}
										</svg>
										<text
											className="label"
											x={legendColorSize + 10}
											y={(legendSize - legendColorSize) / 2}
										>
											{i}
										</text>
									</Group>
								);
							})}
					</Group>
				)}
				{/* Chart Body */}

				<Group className="rank_graph" top={margin.top} left={margin.left}>
					{/* Horizontal Lines */}
					<Group className="value_grid" top={margin.top} left={itemLabelWidth}>
						{axisLabels.map((i, ix) => {
							return (
								<line
									key={ix}
									className="value_grid_item"
									x1={valueScale(i)}
									x2={valueScale(i)}
									y1={0}
									y2={height - margin.bottom - valueLabelHeight - margin.top}
								/>
							);
						})}
					</Group>
					{/* Line Labels */}
					<Group
						className="value_labels"
						top={height - margin.bottom - valueLabelHeight}
						left={itemLabelWidth}
					>
						{axisLabels.map((i, ix) => {
							return (
								<text key={ix} className="value_label_item" x={valueScale(i)} y={valueLabelSpacing}>
									{(i * 100).toFixed(0)}
								</text>
							);
						})}
					</Group>

					{/* Bars */}
					<Group className="bars" top={margin.top} left={itemLabelWidth}>
						{/* Bar */}
						{data.map((i, ix) => {
							const yCenter = itemScale(ix + 0.5) - barWidth / 2;
							return value2HasValue(i) ? (
								<React.Fragment key={ix}>
									<svg>
										<Bar
											fill={getColor(i.value2 ? i.value2 * 100 : 0)}
											x={0}
											y={yCenter}
											width={valueScale(i.value2)}
											height={barWidth / 2}
										/>
										<StrippedBar
											stroke={'white'}
											rectWidth={valueScale(i.value2)}
											rectHeight={barWidth / 2}
											xCenter={0}
											yCenter={yCenter}
										/>
									</svg>

									<Bar
										fill={getColor(i.value * 100)}
										x={0}
										y={yCenter + barWidth / 2}
										width={valueScale(i.value)}
										height={barWidth / 2}
									/>
								</React.Fragment>
							) : (
								<Bar
									key={ix}
									className="bar"
									style={{ fill: getColor(i.value * 100) }}
									x={0}
									y={yCenter}
									width={valueScale(i.value)}
									height={barWidth}
								/>
							);
						})}
						{/* Bar Label */}
						{data.map((i, ix) => {
							const yCenter = itemScale(ix + 0.5) + itemLabelHeight / 2;

							const series1Inside = i.value > barOutsideMax;
							const x1 = series1Inside
								? valueScale(i.value) - barLabelSpacing
								: valueScale(correctForValueAxis(axisLabels, i.value)) + barLabelSpacing;

							const series2Inside = i.value2 && i.value2 > barOutsideMax;
							const x2 =
								i.value2 &&
								(series2Inside
									? valueScale(i.value2) - barLabelSpacing
									: valueScale(correctForValueAxis(axisLabels, i.value2)) + barLabelSpacing);

							return value2HasValue(i) ? (
								<React.Fragment key={ix}>
									<text
										className={classNames('bar_label', 'series2', {
											bar_label_inside: series2Inside,
										})}
										x={x2}
										y={yCenter - (itemLabelHeight * 3) / 4}
									>
										{(i.value2 ? i.value2 * 100 : 0).toFixed(0)}
									</text>
									<text
										className={classNames('bar_label', 'series1', {
											bar_label_inside: series1Inside,
										})}
										x={x1}
										y={yCenter + (itemLabelHeight * 2) / 4}
									>
										{(i.value * 100).toFixed(0)}
									</text>
								</React.Fragment>
							) : (
								<text
									key={ix}
									className={classNames('bar_label', { bar_label_inside: series1Inside })}
									x={x1}
									y={yCenter}
								>
									{(i.value * 100).toFixed(0)}
								</text>
							);
						})}
					</Group>
					{/* Item Labels */}
					<Group className="item_labels" top={margin.top}>
						{data.map((i, ix) => {
							const yCenter = itemScale(ix + 0.5);
							return (
								<text key={ix} className="item_label" x={0} y={yCenter + itemLabelHeight / 2}>
									{i.label}
								</text>
							);
						})}
					</Group>
				</Group>
			</svg>
		);
	}
}

export default RankChart;
