import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../../../core';
import Input from '../../../../components/global/input';
import { TeamService } from '../../../../services/teamService';
import { TeamUpdateType } from '../../../../store/team/types';
import { updateTeam } from '../../../../store/team/actions';
import { ReactComponent as PencilIcon } from '../../../../assets/images/icons/Pencil.svg';

import './index.scss';

export interface TeamMembersTableHeaderProps {
	team: Edge.Models.Team;
	updateTeam: (updates: TeamUpdateType) => Promise<void>;
}

interface TeamMembersTableHeaderState {
	isEditing: boolean;
	name: string;
}

export class TeamMembersTableHeader extends React.Component<TeamMembersTableHeaderProps, TeamMembersTableHeaderState> {
	constructor(props: TeamMembersTableHeaderProps) {
		super(props);
		this.state = {
			isEditing: false,
			name: props.team.name,
		};
	}

	public render(): JSX.Element {
		const { team } = this.props;
		return (
			<div className="team_title">
				{!this.state.isEditing ? (
					<>
						<span>{team.name}</span>
						<div className="icon-wrapper" onClick={() => this.setState({ isEditing: true })}>
							<PencilIcon />
						</div>
					</>
				) : (
					<>
						<Input
							ref="editTeamNameInput"
							type="text"
							maxLength={100}
							value={this.state.name}
							onChange={(e) => {
								this.setState({ name: e.target.value });
							}}
							className="edit_team_input"
						/>
						<div className="save_btn cta_btn btn_small" onClick={this.updateTeam}>
							Save
						</div>
					</>
				)}
			</div>
		);
	}

	private updateTeam = async () => {
		const { id } = this.props.team;
		const { name } = this.state;
		await TeamService.update({ teamId: id, teamName: name });
		await this.props.updateTeam({ id, name });
		this.setState({ isEditing: false });
	};
}

export default connect(
	null,
	{ updateTeam }
)(TeamMembersTableHeader);
