import * as React from 'react';

import { BaseExercisePage } from '../baseExercisePage';
import { Recognition } from './recognition';
import * as Edge from '../../../core';

import RecognitionResults from './results';
import RecognitionConfigForm from './configForm';

export default class RecognitionPage extends BaseExercisePage<
	Edge.Models.RecognitionExerciseConfiguration,
	Edge.Models.RecognitionExerciseResult
> {
	protected renderConfiguration(): JSX.Element {
		const { lockConfiguration, operationName, mode } = this.props;
		const { configuration } = this.state;

		return (
			<RecognitionConfigForm
				mode={mode}
				operationName={operationName}
				onSubmit={this.startExercise}
				initialConfiguration={configuration}
				lockConfiguration={lockConfiguration}
			/>
		);
	}
	protected renderExercise(): JSX.Element {
		const { currentProfile, mode } = this.props;
		const { configuration, fullScreen } = this.state;

		return (
			<Recognition
				mode={mode}
				debugMode={this.props.debugMode}
				renderScale={currentProfile.renderScale}
				touchControls={currentProfile.touchControls}
				fullScreen={fullScreen!}
				completeExercise={this.finishExercise}
				abortingExercise={this.abortingExercise}
				configuration={configuration}
			/>
		);
	}
	protected renderResults(): JSX.Element {
		const { hideExerciseResults } = this.props;

		return (
			<RecognitionResults
				results={this.state.result!}
				complete={this.complete}
				retry={this.retryExercise}
				completeText={this.props.completeText}
				hideExerciseResults={hideExerciseResults}
			/>
		);
	}
}
