/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-script-url */
import * as Edge from '../../../core';
import * as Helper from './helper';
import { Animate, Drawing, Vector } from '../../../exercises';

const CircleDistanceFromCenter: number = 100;
// Equal to SVG height/width attrs.
const DefaultCircleDiam: number = 50;
// Associates DPad directions to indices. Drives the circle count on-screen.
const Directions: string[] = ['up', 'right', 'down', 'left'];
// Assumes that `ExerciseSize` has 4 values. should probably refactor to accommodate.
const ImageScales: number[] = [0, 1.0, 1.2, 1.4];

interface CircleParam {
	circle?: HTMLImageElement;
	direction: string;
	position: Vector;
}
/**
 * Sets up four static circles at the center of the canvas that change contrast on question timeout or user input.
 *
 * @see ContrastSensitivityExercise.draw() for how this componet draws elements.
 *
 * The contrast of all circles but one are set to -100%. The circle that doesn't have its contrast reduced
 * is the first correct answer and will continue to decrease as the station # increases.
 */
export default class ContrastSensitivityExerciseComponent {
	// Canvas
	private ctx: CanvasRenderingContext2D = null!;

	// Exercise
	private circleDiam: number;
	private circleParams: CircleParam[] = [];
	private contrast: number; // Starts at 0, -1 for a completely gray image.
	private correctCircle?: number;
	private contrastIncrement: number;

	constructor(contrastIncrement: number, exerciseSize: Edge.Models.ExerciseSize, renderScale: number) {
		this.circleDiam = DefaultCircleDiam * ImageScales[exerciseSize] * renderScale;
		this.contrastIncrement = contrastIncrement;

		this.contrast = this.calculateContrast(1);

		Directions.forEach((direction: string, i: number) => {
			this.circleParams[i] = {
				direction: direction,
				position: new Vector(0, 0, 0),
			};
		});
	}

	public draw = (): void => {
		// Draws border and circles.
		this.drawCircles();
	};

	public getCorrectCircleDirection = (): string => {
		return this.circleParams[this.correctCircle!].direction;
	};

	public initialize = async (ctx: CanvasRenderingContext2D, height: number, width: number): Promise<void> => {
		this.ctx = ctx;

		const centerX = width / 2;
		const centerY = height / 2;

		// Initializes circle images and positions.
		this.circleParams.forEach(async (c: CircleParam, i: number) => {
			const coordinate: { x: number; y: number } = Helper.getCircleCoordinate(
				i,
				centerX,
				centerY,
				CircleDistanceFromCenter
			);

			c.position.init(coordinate.x, coordinate.y, 0);

			c.circle = await Drawing.loadImageAsync('/images/circle-spacial-freq.svg');
		}, this);
	};

	public setupNextQuestion = (stationCurr: number): void => {
		this.contrast = this.calculateContrast(stationCurr);
		this.correctCircle = this.getNewCorrectCircle(this.correctCircle);
	};

	public updatePosition = (elapsedTime: number): void => {
		// Does nothing.
	};

	// Calculates contrast reduction percent.
	// Returns a float ranging from (100 - StartingContrast)% ~ 100%
	private calculateContrast = (stationCurr: number): number => {
		return (Helper.StartingContrast + (stationCurr - 1) * this.contrastIncrement) / 100 - 1;
	};

	private drawCircles = (): void => {
		// Adds an extra unit to the diameter to avoid object clipping.
		const drawingObjectSize: number = this.circleDiam + 1;

		this.ctx.save();

		this.circleParams.forEach((c: CircleParam, i: number) => {
			const x: number = c.position.x - this.circleDiam / 2;
			const y: number = c.position.y - this.circleDiam / 2;

			this.ctx.drawImage(c.circle!, x, y, drawingObjectSize, drawingObjectSize);

			const bits: ImageData = Animate.generateContrastImageData(
				this.ctx.getImageData(x, y, drawingObjectSize, drawingObjectSize),
				this.correctCircle === i ? this.contrast : 0
			);

			this.ctx.putImageData(bits, x, y);
		}, this);

		this.ctx.restore();
	};

	// Generates next correct circle randomly; never consecutive.
	private getNewCorrectCircle = (previous?: number): number => {
		let next: number;

		do next = Math.round(Math.random() * 3);
		while (typeof previous !== 'undefined' && previous === next);

		return next;
	};
}
