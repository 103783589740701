import * as React from 'react';
import classNames from 'classnames';
import * as _ from 'lodash';

import * as Edge from '../../core';
import Select from '../../components/global/select';
import { Constants } from '../../core';

export interface TeamPickerProps {
	className?: string;
	currentTeam?: Edge.Models.Team;
	teams?: Edge.Models.Team[];
	placeholderForCurrentTeam?: string;
	createTeam?: () => void;
	setCurrentTeam: (team: Edge.Models.Team) => void;
}

export default class TeamPicker extends React.Component<TeamPickerProps> {
	public render(): JSX.Element {
		const { currentTeam, teams, createTeam, placeholderForCurrentTeam } = this.props;
		return (
			<Select
				value={currentTeam && currentTeam.id}
				onChange={this.changeTeam}
				className={classNames(this.props.className)}
			>
				{teams &&
					teams.length > 0 &&
					_.orderBy(teams, (i) => i.name).map((team, ix) =>
						placeholderForCurrentTeam && currentTeam && team.id === currentTeam.id ? (
							<option key={currentTeam.id} disabled value={currentTeam.id} hidden>
								{placeholderForCurrentTeam}
							</option>
						) : (
							<option key={team.id} value={team.id}>
								{team.name}
							</option>
						)
					)}
				{createTeam && (
					<option key={'_'} value="_">
						Create New Team
					</option>
				)}
			</Select>
		);
	}
	private changeTeam = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (e.target.value === '_') {
			const { createTeam } = this.props;
			if (createTeam) {
				createTeam();
			}
		} else {
			const currentTeam = (this.props.teams || []).filter((i) => i.id === e.target.value)[0];
			this.props.setCurrentTeam(currentTeam);

			// Allows for the persistence of selected teams when using this component.
			const orgTeamIdsString = localStorage.getItem(Constants.LOCALSTORAGE_ORG_CURRENT_TEAM_IDS);
			const orgTeamIds = !!orgTeamIdsString ? JSON.parse(orgTeamIdsString) : {};
			Object.assign(orgTeamIds, {
				[currentTeam.organizationId]: currentTeam.id,
			});
			localStorage.setItem(Constants.LOCALSTORAGE_ORG_CURRENT_TEAM_IDS, JSON.stringify(orgTeamIds));
		}
	};
}
