import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import * as Edge from '../../../core';
import { AlignmentHelper } from '../../exercises/align/alignHelper';
import { AlignmentExerciseResult } from '../../../core/models';
import { ReactComponent as ArrowUp } from '../../../assets/images/icons/ArrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../assets/images/icons/ArrowDown.svg';
import { ReactComponent as NoteIcon } from '../../../assets/images/icons/Note.svg';
import * as StrUtil from '../../../utilities/strUtil';

import './index.scss';

export interface SummaryProps {
	results: Edge.Models.EvaluationResultSummary[];
	alignmentResult: AlignmentExerciseResult;
}

export const Summary: React.FunctionComponent<SummaryProps> = (props) => {
	const { results, alignmentResult } = props;

	const edgeScore = results[0].edgeScore!;
	const previousScore = results.length > 1 ? results[1].edgeScore : null;
	const alignment = AlignmentHelper.getScoreText(alignmentResult);

	// Calculate last eval
	const lastEvalDate = moment(results[0].completedDateUtc)
		.local()
		.startOf('day');
	const daysSinceLastEval = moment()
		.startOf('day')
		.diff(lastEvalDate, 'days');

	return (
		<div className="score_summary">
			<div className="title">
				<h4>Summary</h4>
				<div className="icon_wrapper">
					<NoteIcon />
				</div>
			</div>
			<div className="edge_score">
				<div className="score">{(edgeScore * 100).toFixed(1)}</div>
				<div>Edge Score</div>
			</div>
			{previousScore && (
				<>
					<div className="score_section">
						<div>Previous Score</div>
						<div className="score">
							{(previousScore * 100).toFixed(1)}
							<span className="icon_wrapper">
								{edgeScore > previousScore ? <ArrowUp /> : <ArrowDown />}
							</span>
						</div>
					</div>
					<div className="score_section">
						<div>Improvement</div>
						<div className="score">
							{(((edgeScore - previousScore) / previousScore) * 100).toFixed(1)}%
							<span className="icon_wrapper">
								{edgeScore > previousScore ? <ArrowUp /> : <ArrowDown />}
							</span>
						</div>
					</div>
				</>
			)}
			<div className="score_section">
				<div>Alignment</div>
				<div className="score">
					{alignment} <span className="subtext">{alignmentResult.explanationRankingText}</span>
				</div>
			</div>
			<div className="score_section">
				<div>Last Evaluation</div>
				<div className="score">
					{daysSinceLastEval <= 10 ? '0' + daysSinceLastEval : daysSinceLastEval}{' '}
					<span className="subtext">{StrUtil.pluralize('Day', daysSinceLastEval)} Ago</span>
				</div>
			</div>
			<div className="progress_details">
				<Link to="/stats" className="cta_btn btn_light btn_small">
					View Stats
				</Link>
			</div>
		</div>
	);
};

export default Summary;
