import * as React from 'react';

import { BaseExercisePage } from '../baseExercisePage';
import Pursuits from './pursuits';
import * as Edge from '../../../core';

import PursuitsResults from './results';
import PursuitsConfigForm from './configForm';

export default class PursuitsPage extends BaseExercisePage<
	Edge.Models.PursuitsExerciseConfiguration,
	Edge.Models.PursuitsExerciseResult
> {
	protected renderConfiguration(): JSX.Element {
		const { lockConfiguration, operationName, mode } = this.props;
		const { configuration } = this.state;

		return (
			<PursuitsConfigForm
				mode={mode}
				operationName={operationName}
				onSubmit={this.startExercise}
				initialConfiguration={configuration}
				lockConfiguration={lockConfiguration}
			/>
		);
	}

	protected renderExercise(): JSX.Element {
		const { currentProfile, mode } = this.props;
		const { configuration, fullScreen } = this.state;

		return (
			<Pursuits
				mode={mode}
				debugMode={this.props.debugMode}
				renderScale={currentProfile.renderScale}
				touchControls={currentProfile.touchControls}
				fullScreen={fullScreen!}
				completeExercise={this.finishExercise}
				abortingExercise={this.abortingExercise}
				configuration={configuration}
			/>
		);
	}

	protected renderResults(): JSX.Element {
		const { hideExerciseResults } = this.props;

		return (
			<PursuitsResults
				results={this.state.result!}
				complete={this.complete}
				retry={this.retryExercise}
				completeText={this.props.completeText}
				hideExerciseResults={hideExerciseResults}
			/>
		);
	}
}
