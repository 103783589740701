import * as ReduxThunk from 'redux-thunk';
import * as Edge from './../../core';
import { AppState } from './..';
import { UserService } from './../../services/userService';
import { AuthenticationService } from './../../services/authenticationService';
import history from '../../services/history';

import {
	TokenResult,
	LOGIN_INPROGRESS,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	LOGOUT_SUCCESS,
	LOGIN_CLEAR_PRELOADED_DATA,
} from './types';
import { getOrganizations } from '../organization/actions';
import { getTeams } from '../team/actions';
import { getProfile } from '../profile/actions';
import AnalyticsService from '../../services/analyticsService';
import { getAllStaticData } from '../staticData';
import { getPermissions } from '../permissions/actions';
import { getDeviceProfiles } from '../deviceProfile/actions';
import { getActiveSession, clearCachedSession } from '../session/actions';

// action creators
export const loginInProgress = () => {
	return {
		type: LOGIN_INPROGRESS,
	};
};

export const loginSuccess = (tokenResult: TokenResult) => {
	return {
		type: LOGIN_SUCCESS,
		payload: tokenResult,
	};
};

export const loginClearPreloadedData = () => {
	return {
		type: LOGIN_CLEAR_PRELOADED_DATA,
	};
};

export const loginFailure = (error: Edge.Models.EdgeError) => {
	return {
		type: LOGIN_FAILURE,
		payload: error,
		error: true,
	};
};

export const logoutSuccess = () => {
	return {
		type: LOGOUT_SUCCESS,
		payload: {},
	};
};

// thunk actions
const handleLoginError = (
	error: Edge.Models.EdgeError
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	if (error && error.message && (error.message as string).indexOf('401') >= 0) {
		dispatch(loginFailure(new Error('Invalid username or password')));
		return;
	}

	AnalyticsService.exception(error);

	// failed to login
	dispatch(loginFailure(error));
};

export const login = (
	loginUserCommand: Edge.Models.LoginUserCommand
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	// login in progress
	dispatch(loginInProgress());

	try {
		// attempt to login
		AnalyticsService.login(loginUserCommand.username);
		await UserService.login(loginUserCommand);
		const token: string = (await AuthenticationService.getToken()) || Edge.Constants.EMPTY_STRING;

		// successfully logged in
		dispatch(loginClearPreloadedData());
		dispatch(loginSuccess({ token } as TokenResult));

		await dispatch(preloadData());
	} catch (error) {
		dispatch(handleLoginError(error));
	}
};

/**
 * Sets up a session with the supplied token
 * @param token The token to set in AuthenticationService and store in redux state
 * @param redirectPath The URL to redirect to after the loading+preload is finished
 */
export const switchToToken = (
	newToken: string,
	redirectPath?: string
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	try {
		// use the saved token
		AuthenticationService.setToken(newToken);

		// successfully logged in
		dispatch(loginClearPreloadedData());
		dispatch(loginSuccess({ token: newToken } as TokenResult));

		if (redirectPath) {
			history.push(redirectPath);
		}

		await dispatch(preloadData());
	} catch (error) {
		dispatch(handleLoginError(error));
	}
};

export const refreshToken = (): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (
	dispatch
) => {
	try {
		await UserService.refreshToken();
		const token: string = (await AuthenticationService.getToken()) || Edge.Constants.EMPTY_STRING;

		// successfully logged in
		dispatch(loginSuccess({ token } as TokenResult));
	} catch (error) {
		dispatch(handleLoginError(error));
	}
};

export const preloadData = (): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (
	dispatch
) => {
	if (Edge.Configuration.disablePreload) {
		return Promise.resolve();
	}

	// preload data, but ignore errors
	await Promise.all([
		dispatch(getProfile()).catch((i) => i),
		dispatch(getOrganizations()).catch((i) => i),
		dispatch(getTeams()).catch((i) => i),
		dispatch(getAllStaticData()).catch((i) => i),
		dispatch(getPermissions()).catch((i) => i),
		dispatch(getDeviceProfiles()).catch((i) => i),
		dispatch(getActiveSession()).catch((i) => i),
	]);
};

export const resumeFromCache = (): ReduxThunk.ThunkAction<
	Promise<void>,
	AppState,
	null,
	Edge.Models.EdgeAction
> => async (dispatch) => {
	if (AuthenticationService.isAuthenticated()) {
		const token = AuthenticationService.getToken();
		if (token) {
			AuthenticationService.setToken(token);
			dispatch(loginClearPreloadedData());
			dispatch(loginSuccess({ token } as TokenResult));
			await dispatch(preloadData());
		}
	}
};

export const logout = (): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (
	dispatch
) => {
	AnalyticsService.logout();
	await UserService.logout();
	dispatch(clearCachedSession());
	dispatch(logoutSuccess());
};
