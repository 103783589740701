import * as Redux from 'redux';
import * as Edge from './../../core';
import {
	ProfileState,
	ProfileActionTypes,
	PROFILE_LOADING,
	PROFILE_SUCCESS,
	PROFILE_FAILURE,
	PROFILE_SET_AVATAR_URL,
	PROFILE_SET_REGISTRATION_FLAGS,
} from './types';
import { LOGIN_CLEAR_PRELOADED_DATA } from '../login/types';

const initialState: ProfileState = {
	isLoading: false,
};

const profileReducer: Redux.Reducer<ProfileState> = (
	state = initialState,
	action: ProfileActionTypes
): ProfileState => {
	switch (action.type) {
		case LOGIN_CLEAR_PRELOADED_DATA:
			return initialState;
		case PROFILE_LOADING:
			return {
				...state,
				isLoading: true,
				loadingError: undefined,
			};
		case PROFILE_SUCCESS:
			return {
				...state,
				isLoading: false,
				profile: action.payload,
				loadingError: undefined,
			};
		case PROFILE_FAILURE:
			return {
				...state,
				isLoading: false,
				loadingError: action.payload,
				profile: undefined,
			};
		case PROFILE_SET_AVATAR_URL:
			return {
				...state,
				profile: Object.assign({}, state.profile || ({} as Edge.Models.Profile), {
					avatarUrl: action.payload,
				}),
			};
		case PROFILE_SET_REGISTRATION_FLAGS:
			return {
				...state,
				profile: Object.assign({}, state.profile || {}, action.payload),
			};
		default:
			return state;
	}
};

export default profileReducer;
