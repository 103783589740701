import * as React from 'react';
import { NavLink } from 'react-router-dom';

import './index.scss';

export interface AreaNavItem {
	to: string;
	exact?: boolean;
	text: string;
}

export interface AreaNavProps {
	items: AreaNavItem[];
}

export default class AreaNav extends React.Component<AreaNavProps> {
	public render() {
		return (
			<ul className="area-navigation">
				{this.props.items.map((item, ix) => {
					const { text, ...rest } = item;
					return (
						<li key={ix}>
							<NavLink {...rest} activeClassName="active">
								{text}
							</NavLink>
						</li>
					);
				})}
			</ul>
		);
	}
}
