import * as React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import * as Edge from '../../../../core';
import Table from '../../../../components/table';
import { withLoadDataDefaultConfig } from '../../../../components/loadData';
import errorLoadingWrapperHOC from '../../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { StatsService } from '../../../../services/statsService';
import { AlignmentHelper } from '../../../../pages/exercises/align/alignHelper';
import { AppState } from '../../../../store';
import { getTeamsUserCanEdit } from '../../../../store/permissions/selector';
import DownloadButton from '../../../../components/downloadButton';
import { SessionService } from '../../../../services/sessionService';
import ConvertUser from '../../convertUser';

import './index.scss';

interface TeamStatsTableProps {
	positions: Edge.Models.Position[];
	stats?: Edge.Models.TeamMemberStats[];
	team?: Edge.Models.Team;
	currentTeams?: Edge.Models.Team[];
	currentOrganization?: Edge.Models.Organization;
}

interface TeamStatsTableState {
	showConvertUser: boolean;
	teamMember?: Edge.Models.TeamMemberStats;
}

const availableSorts: { [x: string]: (item: Edge.Models.TeamMemberStats) => any } = {
	lastName: (item) => item.user && item.user.lastName,
	date: (item) => item.completedDateUtc,
	position: (item) => item.position && item.position.name,
	edgeScore: (item) => item.edgeScore,
	depth: (item) => item.depth,
	convergence: (item) => item.convergenceStation,
	divergence: (item) => item.divergenceStation,
	tracking: (item) => item.trackingResponseTime,
	recognition: (item) => item.recognitionResponseTime,
	alignment: (item) => item.alignment && Math.abs(item.alignment),
};

const availableSearches: ((item: Edge.Models.TeamMemberStats) => string | undefined)[] = [
	(item) => `${item.user!.firstName} ${item.user!.lastName}`,
];

export class TeamStatsTable extends React.Component<TeamStatsTableProps, TeamStatsTableState> {
	constructor(props: TeamStatsTableProps) {
		super(props);
		this.state = {
			showConvertUser: false,
		};
	}

	public render() {
		const pageSize = 20;

		const { showConvertUser, teamMember } = this.state;
		const { stats, currentTeams, team, currentOrganization } = this.props;

		return (
			<div className="coach_stats_table">
				<Table
					items={stats || []}
					renderHeader={() => <></>}
					searchPlaceholderText={`Search by Name`}
					availableSorts={availableSorts}
					availableSearches={availableSearches}
					initialSort={['!date']}
					renderTableHeader={(classNames, toggleSort) => {
						return (
							<tr>
								<th className={classNames('lastName')} onClick={toggleSort.bind(this, 'lastName')}>
									Name
								</th>
								<th className={classNames('date')} onClick={toggleSort.bind(this, 'date')}>
									Date
								</th>
								<th className={classNames('position')} onClick={toggleSort.bind(this, 'position')}>
									Position
								</th>
								<th className={classNames('edgeScore')} onClick={toggleSort.bind(this, 'edgeScore')}>
									Score
								</th>
								<th className={classNames('alignment')} onClick={toggleSort.bind(this, 'alignment')}>
									Align
								</th>
								<th className={classNames('depth')} onClick={toggleSort.bind(this, 'depth')}>
									Depth
								</th>
								<th
									className={classNames('convergence')}
									onClick={toggleSort.bind(this, 'convergence')}
								>
									Conv
								</th>
								<th className={classNames('divergence')} onClick={toggleSort.bind(this, 'divergence')}>
									Div
								</th>
								<th
									className={classNames('recognition')}
									onClick={toggleSort.bind(this, 'recognition')}
								>
									Rec
								</th>
								<th className={classNames('tracking')} onClick={toggleSort.bind(this, 'tracking')}>
									Track
								</th>
								<th />
								{this.isEventOrProspect() && <th />}
							</tr>
						);
					}}
					renderTableItem={(i) => {
						return i.user ? (
							<tr>
								<td>
									<Link to={`/stats/${i.user.id}/${team!.id}`}>
										{i.user.firstName.substring(0, 1)}. {i.user.lastName}
									</Link>
								</td>
								<td>
									{i.completedDateUtc &&
										moment(i.completedDateUtc).format(Edge.Constants.DATE_FORMAT)}
								</td>
								<td>{i.position && i.position.name}</td>
								<td>{(i.edgeScore * 100).toFixed(1)}</td>
								<td>
									{i.alignment &&
										AlignmentHelper.getScoreText({
											horizontalAlignment: i.alignment,
										})}
								</td>
								<td>{i.depth && (i.depth * 100).toFixed(0)}%</td>
								<td>
									{i.convergence && `${i.convergenceStation} (${(i.convergence * 100).toFixed(0)}%)`}
								</td>
								<td>
									{i.divergence && `${i.divergenceStation} (${(i.divergence * 100).toFixed(0)}%)`}
								</td>
								<td>
									{i.recognition &&
										`${(i.recognitionResponseTime! / 1000).toFixed(2)} (${(
											i.recognition * 100
										).toFixed(0)}%)`}
								</td>
								<td>
									{i.tracking &&
										`${(i.trackingResponseTime! / 1000).toFixed(2)} (${(i.tracking * 100).toFixed(
											0
										)}%)`}
								</td>
								<td className="download_column">
									<DownloadButton
										defaultFileName={`evaluation_result_${i.evaluationSessionId}_${moment(
											new Date()
										).format(Edge.Constants.FILE_DATE_FORMAT)}.pdf`}
										onClick={this.getPrintableSession.bind(this, i.evaluationSessionId)}
									/>
								</td>
								{this.isEventOrProspect() && (
									<td>
										<button
											className="convert_button cta_btn"
											onClick={() => {
												this.handleConvertModal();
												this.setState({
													teamMember: i,
												});
											}}
										>
											Convert
										</button>
									</td>
								)}
							</tr>
						) : (
							<></>
						);
					}}
					renderNoItems={() => (
						<tr>
							<td colSpan={4}>No stats to show.</td>
						</tr>
					)}
					pagingMode="numbers"
					pageSize={pageSize}
				/>
				{showConvertUser && (
					<ConvertUser
						teamMember={teamMember}
						currentTeams={currentTeams}
						handleConvertModal={this.handleConvertModal}
						currentOrganization={currentOrganization}
					/>
				)}
			</div>
		);
	}

	private handleConvertModal = () => {
		this.setState({
			showConvertUser: !this.state.showConvertUser,
		});
	};

	private isEventOrProspect = () => {
		const { team } = this.props;

		return (
			team &&
			(team.type.id === Edge.Models.TeamTypeId.ProspectOnly || team.type.id === Edge.Models.TeamTypeId.Event)
		);
	};

	private getPrintableSession = (id: string) => {
		return SessionService.getPrintableSession(id);
	};
}

function mapStateToProps(state: AppState) {
	const { currentTeam } = getTeamsUserCanEdit(state);
	const { teams } = state.teamState;
	const { currentOrganization } = state.organizationState;

	return {
		team: currentTeam,
		currentTeams: teams,
		currentOrganization: currentOrganization,
	};
}

export default connect(mapStateToProps)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(TeamStatsTable, (props: TeamStatsTableProps) => !!props.team),
		(props: TeamStatsTableProps) => {
			return {
				team: props.team,
				currentTeams: props.currentTeams,
				currentOrganization: props.currentOrganization,
			};
		},
		async (props) => {
			if (!props.team) {
				return;
			}
			const stats = await StatsService.getTeamMemberStats(props.team.id);
			return {
				team: props.team,
				stats,
				currentTeams: props.currentTeams,
				currentOrganization: props.currentOrganization,
			};
		}
	)
);
