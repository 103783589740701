import * as Redux from 'redux';
import * as _ from 'lodash';
import * as Edge from './../../core';
import {
	OrganizationState,
	OrganizationActionTypes,
	ORGANIZATIONS_LOADING,
	ORGANIZATIONS_SUCCESS,
	ORGANIZATIONS_FAILURE,
	SET_CURRENT_ORGANIZATION,
	UPDATE_ORGANIZATION,
	OrganizationUpdateType,
	REMOVE_ORGANIZATION,
} from './types';
import { CacheService } from '../../services/cacheService';
import { LOGIN_CLEAR_PRELOADED_DATA } from '../login/types';

const initialState: OrganizationState = {
	isLoading: false,
};

const loginReducer: Redux.Reducer<OrganizationState> = (
	state = initialState,
	action: OrganizationActionTypes
): OrganizationState => {
	switch (action.type) {
		case LOGIN_CLEAR_PRELOADED_DATA:
			return initialState;
		case ORGANIZATIONS_LOADING:
			return {
				...state,
				isLoading: true,
				loadingError: undefined,
			};
		case ORGANIZATIONS_SUCCESS:
			const payload = _.orderBy(action.payload, 'name');
			const defaultOrgId = CacheService.get(Edge.Constants.CACHE_CURRENT_ORGANIZATION_ID) as string;
			const organization =
				(state.currentOrganization
					? payload.filter((i) => i.id === state.currentOrganization!.id)[0]
					: undefined) ||
				(defaultOrgId ? payload.filter((i) => i.id === defaultOrgId)[0] : undefined) ||
				payload[0];
			if (organization) {
				CacheService.set(
					Edge.Constants.CACHE_CURRENT_ORGANIZATION_ID,
					organization && organization.id,
					Edge.Constants.CACHE_CURRENT_ORGANIZATION_ID_EXPIRATION_MINUTES
				);
			}
			return {
				...state,
				isLoading: false,
				organizations: payload,
				currentOrganization: organization,
				loadingError: undefined,
			};
		case ORGANIZATIONS_FAILURE:
			return {
				...state,
				isLoading: false,
				loadingError: action.payload as Edge.Models.EdgeError,
				organizations: undefined,
				currentOrganization: undefined,
			};
		case SET_CURRENT_ORGANIZATION:
			const currentOrganization = action.payload;
			if (currentOrganization) {
				CacheService.set(
					Edge.Constants.CACHE_CURRENT_ORGANIZATION_ID,
					currentOrganization.id,
					Edge.Constants.CACHE_CURRENT_ORGANIZATION_ID_EXPIRATION_MINUTES
				);
			}
			return {
				...state,
				currentOrganization:
					(currentOrganization
						? (state.organizations || []).filter((i) => i.id === currentOrganization.id)[0]
						: undefined) || (state.organizations || [])[0],
			};
		case UPDATE_ORGANIZATION:
			const updates = action.payload as OrganizationUpdateType;
			const applyUpdate = (item: Edge.Models.Organization) => {
				if (item.id === updates.id) {
					return Object.assign({}, item, updates);
				}
				return item;
			};
			return {
				...state,
				organizations: state.organizations && state.organizations.map(applyUpdate),
				currentOrganization: state.currentOrganization && applyUpdate(state.currentOrganization),
			};
		case REMOVE_ORGANIZATION:
			const id = action.payload as string;
			const newOrgs = state.organizations && state.organizations.filter((i) => i.id !== id);
			return {
				...state,
				organizations: newOrgs,
				currentOrganization:
					state.currentOrganization && state.currentOrganization.id === id
						? newOrgs && newOrgs[0]
						: state.currentOrganization,
			};
		default:
			return state;
	}
};

export default loginReducer;
