import * as React from 'react';

import * as Edge from '../../../../core';
import { Avatar } from '../../../../components/avatar';

export interface TopPerformerRowProps {
	index: number;
	topPerformer: Edge.Models.TopPerformer;
}

export const TopPerformerRow: React.FunctionComponent<TopPerformerRowProps> = (props) => {
	const { firstName, lastName, avatarUrl } = props.topPerformer.user;
	const { lastScore, totalSessions } = props.topPerformer;
	return (
		<div className="performers_row">
			<div>
				<div className="rank">{props.index}</div>
				<Avatar src={avatarUrl} size="small" />
				<div>
					{firstName} {lastName}
				</div>
			</div>
			<div>{totalSessions === 0 ? <div>&mdash;</div> : totalSessions}</div>
			<div className="last_score">{lastScore === 0 ? <div>&mdash;</div> : (lastScore * 100).toFixed(1)}</div>
		</div>
	);
};
