import * as React from 'react';
import classNames from 'classnames';

import './index.scss';

const Info: React.FunctionComponent = (props) => {
	return <div className={classNames('info-component')}>{props.children}</div>;
};

export default Info;
