import * as React from 'react';
import './../../legacy/bundles/jqueryBundle';
import './../../legacy/bundles/vendorBundle';
import './../../legacy/bundles/vizedgeBundle';
import * as Edge from '../../core';
import { Howl } from 'howler';

declare var $: any;

export interface BaseExerciseProps<T extends Edge.Models.ExerciseResult, TC extends Edge.Models.ExerciseConfiguration> {
	debugMode?: boolean;
	mode: Edge.Models.ExerciseMode;
	renderScale: number;
	touchControls: boolean;
	fullScreen: boolean;
	configuration: TC;
	abortingExercise: () => Promise<void>;
	completeExercise: (command: T) => void;
}

/**
 * TODO #1583: https://dev.azure.com/vizualedge/Edge%20Trainer/_workitems/edit/1583
 * Implement the methods below as abstract
 *
 *	cleanUp: () => void;
 *	fixedUpdate: (timestamp: DOMHighResTimeStamp) => void;
 *
 *	recordResponse: (correct: boolean, responseTime?: number) => void;
 *	render: () => JSX.Element;
 *	selectDirection: (direction: string) => void;
 *	setupQuestion: () => void;
 *	startExercise: () => Promise<void>;
 *	endExercise: () => Promise<void>;
 *	onStarterFinishCallback: () => void;
 */

export abstract class BaseExercise<
	TExerciseProps extends BaseExerciseProps<TResult, TConfig>,
	TResult extends Edge.Models.ExerciseResult,
	TConfig extends Edge.Models.ExerciseConfiguration,
	TExerciseState
> extends React.Component<TExerciseProps, TExerciseState> {
	protected session: Edge.Models.ExerciseSession = null!;

	// Sounds.
	protected buzzerSound: Howl = new Howl({ src: ['/audio/bzzt.mp3', 'audio/bzzt.ogg'] });
	protected bubbleSound: Howl = new Howl({ src: ['/audio/bubble.mp3', 'audio/bubble.ogg'] });
	protected crystalSound: Howl = new Howl({ src: ['/audio/crystal.mp3', 'audio/crystal.ogg'] });

	// Abstract methods.
	protected abstract createSession(): Edge.Models.ExerciseSession;

	public componentDidMount() {
		$(document).ready(async () => {
			// wait for the images (if any) to be loaded
			await Promise.all(
				Array.from<HTMLImageElement>(document.querySelectorAll('#images img')).map((imageElement) => {
					if (imageElement.complete) {
						return Promise.resolve();
					}
					return new Promise<void>((resolve) => {
						imageElement.onload = () => resolve();
						imageElement.onerror = () => resolve();
					});
				})
			);

			// images are loaded, it's safe to start the session
			this.session = this.createSession();
			this.session.startSession();
		});
	}

	protected endSession = async () => {
		await this.props.abortingExercise();
		if (this.session) {
			this.session.endSession();
		}
	};

	protected completeSession = () => {
		if (this.session) {
			this.session.endSession();
		}
	};
}
