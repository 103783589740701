import * as React from 'react';
import classNames from 'classnames';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	error?: boolean;
}

export class Input extends React.Component<InputProps> {
	public render(): JSX.Element {
		const {
			className,
			placeholder,
			onChange,
			onBlur,
			onClick,
			type,
			value,
			disabled,
			error,
			name,
			id,
			checked,
			min,
			max,
			maxLength,
		} = this.props;
		const realClassName = classNames({ error: error }, className);
		const props = {
			...(realClassName && { className: realClassName }),
			...(placeholder && { placeholder }),
			onChange,
			onBlur,
			onClick,
			type,
			value,
			disabled,
			name,
			id,
			checked,
			min,
			max,
			maxLength,
		};

		return <input {...props} />;
	}
}

export default Input;
