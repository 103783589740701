import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { connect } from 'react-redux';

import * as Edge from '../../core';
import AreaNav, { AreaNavItem } from '../../components/areaNav';
import PageTitle from '../../components/pageTitle';
import UserInformation from './userInformation';
import ManagePlan from './managePlan';
import { AppState } from '../../store';

import './index.scss';

const userInfo: AreaNavItem = { to: '/my-account/user-information', text: 'User Information' };
const managePlan: AreaNavItem = { to: '/my-account/manage-plan', text: 'Manage Plan' };

interface MyAccountProps {
	currentOrganization?: Edge.Models.Organization;
}

export class MyAccount extends React.Component<MyAccountProps> {
	public render(): JSX.Element {
		const { currentOrganization } = this.props;
		const showManagePlan = currentOrganization && currentOrganization.userPurchasable;
		const items = [userInfo];
		if (showManagePlan) {
			items.push(managePlan);
		}
		return (
			<div className="my_account">
				<PageTitle title="My Account" />
				<AreaNav items={items} />
				<Switch>
					<Route path="/my-account/user-information" component={UserInformation} />
					{currentOrganization && currentOrganization.userPurchasable && (
						<Route path="/my-account/manage-plan" component={ManagePlan} />
					)}
					<Redirect from="/my-account" to="/my-account/user-information" />
				</Switch>
			</div>
		);
	}
}

function mapStateToProps({ organizationState }: AppState) {
	return { currentOrganization: organizationState.currentOrganization };
}

export default connect(mapStateToProps)(MyAccount);
