import React, { useState } from 'react';
import classNames from 'classnames';
import * as Edge from '../../../core';
import './index.scss';
import { getPriceTitle, getPriceFeatures } from '../../../utilities/commerceUtil';
import * as StrUtil from '../../../utilities/strUtil';
import _ from 'lodash';

export interface ChooseProductProps {
	prices: Edge.Models.OrganizationAllowedPrice[];
	proratePercent?: number | null;
	currentPrice?: Edge.Models.Price;
	count?: number;
	onSelectPrice: (price: Edge.Models.OrganizationAllowedPrice) => void;
}

const getPriceTypeOrder = (priceType: Edge.Models.PriceType) => {
	switch (priceType) {
		case Edge.Models.PriceTypes.Subscription:
			return 1;
		case Edge.Models.PriceTypes.Sessions:
			return 2;
		case Edge.Models.PriceTypes.Glasses:
			return 3;
	}
};

const getPriceTypes = (prices: Edge.Models.OrganizationAllowedPrice[]) => {
	return _.orderBy(_.uniq(prices.map((i) => i.price.priceType)), getPriceTypeOrder);
};

const ChooseProduct: React.FC<ChooseProductProps> = (props) => {
	const [currentPriceType, setCurrentPriceType] = useState<Edge.Models.PriceTypes>(
		getPriceTypes(props.prices)[0] || Edge.Models.PriceTypes.Subscription
	);

	const { prices, currentPrice, count, onSelectPrice } = props;
	const priceTypes = getPriceTypes(prices);

	const priceSuffix = (price: Edge.Models.Price) => {
		switch (price.priceType) {
			case Edge.Models.PriceTypes.Subscription:
				return '/user';
			case Edge.Models.PriceTypes.Sessions:
				return `/${price.sessions} ${StrUtil.pluralize('session', price.sessions)}`;
			default:
				return '';
		}
	};

	return (
		<div className="choose_product">
			<ul className="product_types">
				{priceTypes.map((i: Edge.Models.PriceTypes, ix: number) => (
					<li
						className={classNames('product_type', { is_selected: i === currentPriceType })}
						key={ix}
						onClick={() => setCurrentPriceType(i)}
					>
						{
							prices.find((ii: Edge.Models.OrganizationAllowedPrice) => ii.price.priceType === i)!.product
								.name
						}
					</li>
				))}
			</ul>
			{currentPrice && (
				<div className="current_product">
					<h4>
						Your current plan: <span className="current_product_value">{getPriceTitle(currentPrice)}</span>
					</h4>
				</div>
			)}
			<div className="products">
				{_.orderBy(
					prices.filter((i: Edge.Models.OrganizationAllowedPrice) => i.price.priceType === currentPriceType),
					(i: Edge.Models.OrganizationAllowedPrice) => i.price.unitAmount
				).map((i: Edge.Models.OrganizationAllowedPrice, ix: number) => {
					const price = i.price.unitAmount;
					const totalPrice = count && price * count;
					return (
						<div
							key={ix}
							className={classNames('product', {
								is_current: currentPrice && i.price.id === currentPrice.id,
							})}
							onClick={() => onSelectPrice(i)}
						>
							<div className="product_title_area">
								<h3>{getPriceTitle(i.price)}</h3>
								<h4>
									${price.toFixed(0)}
									{priceSuffix(i.price)}
									{count && ` * ${count}`}
									{totalPrice && ` = $${totalPrice.toFixed(0)}`}
								</h4>
							</div>
							<ul className="features">
								{getPriceFeatures(i).map((i: string, ix: number) => (
									<li key={ix}>{i}</li>
								))}
							</ul>
							<div className="cta_btn btn_bright">SELECT</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default ChooseProduct;
