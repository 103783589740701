import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { AppState } from '../../../store';
import AppPageContainer from '../../../components/pageContainer';
import { SessionService } from '../../../services/sessionService';

import AreaNav from '../../../components/areaNav';
import { Switch, Route } from 'react-router-dom';
import { TeamService } from '../../../services/teamService';
import MigrateSessions from './migrateSessions';
import MigrateUser from './migrateUser';
import { MigrateSessionsToNewUserFormValues } from './migrateSessions';
import { MigrateUserTeamRoleToTeamFormValues } from './migrateUser';

interface AdminOperationsProps {
	isAdmin?: boolean;
}

const navItems = [
	{ to: '/admin/migrate-prospect', text: 'Migrate Prospect' },
	{ to: '/admin/migrate-user', text: 'Migrate User' },
];

export const AdminOperations: React.FunctionComponent<AdminOperationsProps> = (props) => {
	if (!props.isAdmin) return <Redirect to="/" />;

	const migrateSessionsAndPlansToNewUser = async (values: MigrateSessionsToNewUserFormValues) => {
		await SessionService.migrateSessionsAndPlans({
			prospectUsername: values.prospectUsername,
			newUsername: values.newUsername,
		});
	};

	const migrateUserTeamRoleToTeam = async (values: MigrateUserTeamRoleToTeamFormValues) => {
		await TeamService.migrateUserTeamRoleToTeam({
			userTeamRoleId: values.userTeamRoleId,
			newTeamId: values.newTeamId,
		});
	};

	return (
		<>
			<AreaNav items={navItems} />
			<AppPageContainer>
				<Switch>
					<Route
						path="/admin/migrate-prospect"
						render={() => <MigrateSessions onSubmit={migrateSessionsAndPlansToNewUser} />}
					/>
					<Route
						path="/admin/migrate-user"
						render={() => <MigrateUser onSubmit={migrateUserTeamRoleToTeam} />}
					/>
					<Redirect to="/admin/migrate-prospect" />
				</Switch>
			</AppPageContainer>
		</>
	);
};

function mapStateToProps({ loginState }: AppState) {
	return {
		isAdmin: loginState.decodedToken && loginState.decodedToken.is_admin,
	};
}

export default connect(mapStateToProps)(AdminOperations);
