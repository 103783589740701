import * as React from 'react';

import './loginLayout.scss';

export const LoginLayout: React.SFC = (props) => {
	return (
		<div className="loginBackground">
			<div className="loginContainer">{props.children}</div>
		</div>
	);
};

export default LoginLayout;
