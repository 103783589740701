import * as React from 'react';

import * as Edge from '../../../core';
import YourCurrentPlan from '../../../components/yourCurrentPlan';
import OrganizationAssignSeats from '../assignSeats';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { AppState } from '../../../store';
import { connect } from 'react-redux';
import ScrollToTopOnMount from '../../../components/global/scrollToTopOnMount';
import AvailablePrices from '../availablePrices';

export interface OrganizationManagePlanProps {
	isAdmin?: boolean;
	currentOrganization: Edge.Models.Organization;
}

const OrganizationManagePlan: React.FunctionComponent<OrganizationManagePlanProps> = (props) => (
	<>
		<ScrollToTopOnMount />
		<YourCurrentPlan
			entityType={Edge.Models.EntityType.Organization}
			entityId={props.currentOrganization.id}
			canEdit
		/>
		<OrganizationAssignSeats />
		{props.isAdmin && <AvailablePrices organization={props.currentOrganization} />}
	</>
);

function mapStateToProps(state: AppState) {
	const isAdmin = state.loginState.decodedToken && state.loginState.decodedToken.is_admin;
	return {
		isAdmin,
		currentOrganization: state.organizationState.currentOrganization!,
	};
}

export default connect(mapStateToProps)(
	errorLoadingWrapperHOC(OrganizationManagePlan, (props) => !!props.currentOrganization, undefined, undefined, {
		loadingOptions: { blockItem: true },
	})
);
