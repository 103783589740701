import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as Edge from '../../../core';
import ExerciseList from '../exerciseList';
import PlanPageBase from '../planPageBase';
import StartButton from '../startButton';
import ErrorDisplay from '../../../components/error/errorDisplay';
import JsonDebug from '../../../components/jsonDebug';
import { CanTrainTeamsBanner } from '../../../components/teamBanner';
import { AppState } from '../../../store';
import { getTeamsUserCanTrain } from '../../../store/permissions/selector';

export interface GameDayTrainingProps {
	planResult?: Edge.Models.GameDayPlanResult;
	currentTeam?: Edge.Models.Team;
}

export class GameDayTraining extends React.PureComponent<GameDayTrainingProps> {
	public render() {
		const { plan, currentPhase } = this.getPhase();
		const { currentTeam } = this.props;
		return (
			<PlanPageBase
				banner={<CanTrainTeamsBanner />}
				currentTeam={currentTeam}
				gameMode={Edge.Models.GameMode.GameDay}
				isTraining
				operationName="Game Day"
				plan={plan}
				planPhase={currentPhase}
				renderPlan={this.renderBody}
				renderResults={this.renderResults}
				title="Game Day"
			/>
		);
	}
	private renderResults = () => {
		return <Redirect to="/training/gameDay-redirect" />;
	};
	private renderBody = (trainNow: () => void) => {
		const { plan, currentPhase } = this.getPhase();
		if (!currentPhase) {
			return <ErrorDisplay>Unable to find current phase</ErrorDisplay>;
		}
		return (
			<div className="gameday-plan">
				<StartButton isTraining onClick={trainNow} planPhase={currentPhase} />
				<ExerciseList configs={currentPhase.exerciseConfigurations} />
				<JsonDebug data={plan} />
			</div>
		);
	};

	private getPhase = () => {
		const { planResult } = this.props;
		if (!planResult || !planResult.plan) {
			return { plan: undefined, currentPhase: undefined };
		}
		const plan = planResult.plan;
		const planIndex = plan.phases.findIndex((i) => i.id === planResult.currentPlanPhaseId);
		const currentPhase = plan.phases[planIndex];
		return { plan, currentPhase };
	};
}

function mapStateToProps(state: AppState) {
	return {
		currentTeam: getTeamsUserCanTrain(state).currentTeam,
	};
}

export default connect(mapStateToProps)(GameDayTraining);
