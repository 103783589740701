import * as React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';

import * as Edge from '../../../core';
import TabNav, { TabNavItem } from '../../../components/areaNav/tabNav';
import AthleteStatsEdgeScore from '../edgeScore';
import AthleteStatsTrainingTable from './trainingTable';
import { withLoadDataDefaultConfig } from '../../../components/loadData';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { SessionService } from '../../../services/sessionService';

import './detail.scss';
import { PlanService } from '../../../services/planService';
import TrainingPlan from '../../dashboard/trainingPlan';
import { AppState } from '../../../store';

export interface AthleteDetailProps {
	currentTeam?: Edge.Models.Team;
	sessions: Edge.Models.BaseSessionResponse[];
	teamId: string;
	trainingPlanResponse: Edge.Models.TrainingPlanResult;
	userId: string;
	viewingSelf: boolean;
}

interface AthleteDetailState {
	activeTab: number;
}

export class AthleteDetail extends React.PureComponent<AthleteDetailProps, AthleteDetailState> {
	private static readonly edgeScoreTabId = 0;
	private static readonly trainingTabId = 1;
	private static readonly progressTabId = 2;

	private readonly tabItems: TabNavItem[];

	constructor(props: AthleteDetailProps) {
		super(props);
		this.state = {
			activeTab: this.GetDefaultActiveTab(),
		};
		this.tabItems = [
			{ text: 'Edge Score', onClick: this.gotoTab.bind(this, AthleteDetail.edgeScoreTabId) },
			{ text: 'History', onClick: this.gotoTab.bind(this, AthleteDetail.trainingTabId) },
			{ text: 'Progress', onClick: this.gotoTab.bind(this, AthleteDetail.progressTabId) },
		];
	}
	public render() {
		const { sessions, viewingSelf, trainingPlanResponse, currentTeam } = this.props;
		const evaluationSessions = sessions.filter(
			(session) => session.sessionTypeId === Edge.Models.SessionTypeId.Evaluate
		);
		const trainingSessions = sessions.filter(
			(session) => session.sessionTypeId === Edge.Models.SessionTypeId.Train
		);
		return (
			<div className="athlete_stats_detail">
				<TabNav items={this.tabItems} current={this.state.activeTab} />
				<div className="athlete_stats_detail_content">
					{this.state.activeTab === AthleteDetail.edgeScoreTabId && (
						<AthleteStatsEdgeScore
							currentTeam={currentTeam}
							sessions={evaluationSessions}
							viewingSelf={viewingSelf}
						/>
					)}
					{this.state.activeTab === AthleteDetail.trainingTabId && (
						<AthleteStatsTrainingTable sessions={trainingSessions} />
					)}
					{this.state.activeTab === AthleteDetail.progressTabId && (
						<TrainingPlan
							currentTeam={currentTeam}
							trainingPlanResponse={trainingPlanResponse}
							viewingSelf={viewingSelf}
						/>
					)}
				</div>
			</div>
		);
	}

	private gotoTab = (activeTab: number, e: React.MouseEvent) => {
		e.preventDefault();
		this.setState({ activeTab });
	};

	private GetDefaultActiveTab(): number {
		const { currentTeam } = this.props;

		if (currentTeam && currentTeam.type.id === Edge.Models.TeamTypeId.StationMode) {
			return AthleteDetail.trainingTabId;
		}
		return AthleteDetail.edgeScoreTabId;
	}
}

function mapStateToProps({ teamState }: AppState) {
	const currentTeam = teamState.currentTeam;
	return {
		currentTeam,
	};
}

export default connect(mapStateToProps)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(AthleteDetail, undefined, undefined, undefined, { loadingOptions: { blockItem: true } }),
		(props: AthleteDetailProps) => _.pick(props, 'userId', 'teamId', 'viewingSelf'),
		async (props) => {
			const teamId = !props.viewingSelf ? props.teamId : undefined;
			return {
				sessions: await SessionService.getAllSessions(props.userId, teamId),
				trainingPlanResponse: await PlanService.getUserTrainingPlan(props.userId),
			};
		}
	)
);
