import * as React from 'react';
import classNames from 'classnames';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import * as Edge from '../../../core';
import { getDeviceProfiles } from '../../../store/deviceProfile/actions';
import { ReactComponent as GearIcon } from '../../../assets/images/icons/Gear.svg';
import DeviceProfileList from './list';

import './index.scss';

export interface DeviceProfilesProps {
	isLoading: boolean;
	getDeviceProfiles: () => void;
	profiles?: Edge.Models.DeviceProfile[];
	currentProfile?: Edge.Models.DeviceProfile;
}

interface DeviceProfilesState {
	showingList?: boolean;
}

export class DeviceProfiles extends React.PureComponent<DeviceProfilesProps, DeviceProfilesState> {
	constructor(props: DeviceProfilesProps) {
		super(props);
		this.state = {};
	}

	public render() {
		const { profiles, currentProfile } = this.props;
		const { showingList } = this.state;

		return (
			<>
				<div className="deviceprofiles">
					<button
						className={classNames('deviceprofile_calibrate_btn', {
							deviceprofile_hascurrentprofile: !!currentProfile,
						})}
						onClick={this.showList}
					>
						<span className="icon_wrapper">
							<GearIcon />
						</span>
						Calibrate Device
					</button>
				</div>
				{showingList && (
					<DeviceProfileList profiles={profiles} currentProfile={currentProfile} onClose={this.closeList} />
				)}
			</>
		);
	}

	private showList = () => {
		this.setState({ showingList: true });
	};

	private closeList = () => {
		this.setState({ showingList: false });
	};
}

function mapStateToProps({ deviceProfileState }: AppState) {
	return deviceProfileState;
}

export default connect(
	mapStateToProps,
	{
		getDeviceProfiles,
	}
)(
	errorLoadingWrapperHOC(
		DeviceProfiles,
		(props) => !props.isLoading && !!props.profiles,
		() => undefined,
		(props) => {
			if (!props.isLoading && !props.profiles) {
				props.getDeviceProfiles();
			}
		}
	)
);
