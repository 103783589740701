import * as Edge from './../core';

export abstract class OrganizationService {
	public static async getAll(): Promise<Edge.Models.Organization[]> {
		const route = Edge.API.ServerRoute.forAction('organizations');
		return await Edge.API.get(route);
	}

	public static async getMembers(id: string): Promise<Edge.Models.OrganizationMember[]> {
		const route = Edge.API.ServerRoute.forAction(`organizations/${id}/users`);
		return await Edge.API.get(route);
	}

	public static async addMembers(
		command: Edge.Models.AddUsersToOrganizationCommand
	): Promise<Edge.Models.OrganizationMember[]> {
		const route = Edge.API.ServerRoute.forAction(`organizations/users`);
		return await Edge.API.post(route, command);
	}

	public static async removeMember(id: string, userId: string): Promise<void> {
		const route = Edge.API.ServerRoute.forAction(`organizations/${id}/users/${userId}`);
		return await Edge.API.delete(route);
	}

	public static async update(command: Edge.Models.EditOrganizationCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('organizations');
		return await Edge.API.patch(route, command);
	}

	public static async updateAdminSettings(command: Edge.Models.UpdateAdminSettingsCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('organizations/adminSettings');
		return await Edge.API.patch(route, command);
	}

	public static async deactivate(id: string): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('organizations', id);
		return await Edge.API.delete(route);
	}

	public static async uploadLogo(organizationId: string, imageData: string): Promise<string> {
		const route = Edge.API.ServerRoute.forAction('organizations', `uploadLogo`);
		return await Edge.API.post(route, { organizationId, imageData });
	}

	public static async create(command: Edge.Models.CreateOrganizationCommand): Promise<Edge.Models.Organization> {
		const route = Edge.API.ServerRoute.forAction('organizations');
		return await Edge.API.post(route, command);
	}
}
