import * as Edge from './../../core';

export interface OrganizationState {
	organizations?: Edge.Models.Organization[];
	currentOrganization?: Edge.Models.Organization;
	isLoading: boolean;
	loadingError?: Edge.Models.EdgeError;
}

export const ORGANIZATIONS_LOADING = 'ORGANIZATIONS_LOADING';
export const ORGANIZATIONS_SUCCESS = 'ORGANIZATIONS_SUCCESS';
export const ORGANIZATIONS_FAILURE = 'ORGANIZATIONS_FAILURE';
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const REMOVE_ORGANIZATION = 'REMOVE_ORGANIZATION';

interface OrganizationsLoading extends Edge.Models.EdgeAction<undefined, typeof ORGANIZATIONS_LOADING> {
	type: typeof ORGANIZATIONS_LOADING;
}

interface OrganizationsSuccess
	extends Edge.Models.EdgeAction<Edge.Models.Organization[], typeof ORGANIZATIONS_SUCCESS> {
	type: typeof ORGANIZATIONS_SUCCESS;
	payload: Edge.Models.Organization[];
}

interface OrganizationsFailure extends Edge.Models.EdgeAction<Edge.Models.EdgeError, typeof ORGANIZATIONS_FAILURE> {
	type: typeof ORGANIZATIONS_FAILURE;
	payload: Edge.Models.EdgeError;
}

interface SetCurrentOrganization
	extends Edge.Models.EdgeAction<Edge.Models.Organization, typeof SET_CURRENT_ORGANIZATION> {
	type: typeof SET_CURRENT_ORGANIZATION;
	payload: Edge.Models.Organization;
}

interface UpdateOrganization extends Edge.Models.EdgeAction<OrganizationUpdateType, typeof UPDATE_ORGANIZATION> {
	type: typeof UPDATE_ORGANIZATION;
	payload: OrganizationUpdateType;
}

interface RemoveOrganization extends Edge.Models.EdgeAction<string, typeof REMOVE_ORGANIZATION> {
	type: typeof REMOVE_ORGANIZATION;
	payload: string;
}

export type OrganizationActionTypes =
	| OrganizationsLoading
	| OrganizationsSuccess
	| OrganizationsFailure
	| SetCurrentOrganization
	| UpdateOrganization
	| RemoveOrganization;
export type OrganizationUpdateType = Pick<
	Partial<Edge.Models.Organization>,
	Exclude<keyof Edge.Models.Organization, 'id'>
> &
	Pick<Edge.Models.Organization, 'id'>;
