import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';

import * as Edge from '../../../core';
import RankChart from '../../../components/rankChart';
import Select from '../../../components/global/select';
import { getSessionChartItems } from '../../../services/evaluationChartService';
import ExerciseResults from '../../../components/exerciseResults';

import './index.scss';
import DownloadButton from '../../../components/downloadButton';
import { SessionService } from '../../../services/sessionService';
import Input from '../../../components/global/input';
import { AppState } from '../../../store';
import { connect } from 'react-redux';

interface EdgeScoreProps {
	currentTeam?: Edge.Models.Team;
	isCurrTeamYouth?: boolean;
	sessions: Edge.Models.BaseSessionResponse[];
	viewingSelf: boolean;
}

interface EdgeScoreState {
	currentIndex?: number;
	isProCompare?: boolean;
}

export class AthleteStatsEdgeScore extends React.Component<EdgeScoreProps, EdgeScoreState> {
	constructor(props: EdgeScoreProps) {
		super(props);

		const currentIndex = props.sessions.length > 0 ? 0 : undefined;
		const isProCompare = false;

		this.state = {
			currentIndex,
			isProCompare,
		};
	}

	public render(): JSX.Element {
		const { currentIndex, isProCompare } = this.state;
		const sessions = _.orderBy(this.props.sessions, 'expiresUtc', 'desc');

		const { currentTeam } = this.props;

		if (currentTeam && currentTeam.type.id === Edge.Models.TeamTypeId.StationMode) {
			return (
				<div className="my_plan_comp">
					Not available in Station Mode. To upgrade this account, please contact{' '}
					<a href={'mailto:support@vizualedge.com'}>support@vizualedge.com</a>.
				</div>
			);
		}

		if (sessions.length < 1) {
			return <>There are no evaluation results to display.</>;
		}

		const currentSession = sessions[this.state.currentIndex!];

		return (
			<div className="edge_score_stat">
				<div className="inner_content">
					<div className="content_header">
						<div>
							<div className="score_number">
								{currentSession.edgeScore === undefined || currentSession.edgeScore === null
									? 0
									: (currentSession.edgeScore * 100).toFixed(1)}
							</div>
							<div className="score_label">Edge Score</div>
						</div>
						<div>
							<Select value={currentIndex} onChange={this.changeEvaluation}>
								{sessions.map((session, ix) => (
									<option key={ix} value={ix}>
										{moment(session.expiresUtc).format(Edge.Constants.DATE_FORMAT)}
									</option>
								))}
							</Select>
							<div className="download_pdf no_print">
								<DownloadButton
									defaultFileName={this.getDefaultFileName(currentSession)}
									onClick={this.getPrintableSession}
								>
									Download PDF
								</DownloadButton>
								{this.props.isCurrTeamYouth && (
									<>
										<Input
											type="checkbox"
											name="proCompare"
											defaultChecked={isProCompare}
											onChange={this.onProCompareChecked}
										/>
										<label className="checkbox_label" htmlFor="proCompare">
											Compare with Pros
										</label>
									</>
								)}
							</div>
						</div>
					</div>
					<div className="edge_score_graph">
						{this.props.viewingSelf && (
							<>
								<h2>Skills</h2>
								<h6>Percentage Rank</h6>
								<RankChart data={getSessionChartItems(currentSession)} />
							</>
						)}
					</div>
					<ExerciseResults results={currentSession.exerciseResults.filter((i) => i.complete)} />
				</div>
			</div>
		);
	}

	private getPrintableSession = async () => {
		const sessions = _.orderBy(this.props.sessions, 'expiresUtc', 'desc');
		if (!sessions) {
			return undefined;
		}
		const { currentIndex, isProCompare } = this.state;

		const currentSession = sessions[currentIndex!];
		if (!sessions) {
			return undefined;
		}

		return await SessionService.getPrintableSession(currentSession.sessionId, isProCompare);
	};

	private getDefaultFileName = (currentSession: Edge.Models.BaseSessionResponse) => {
		const { isProCompare } = this.state;

		return `evaluation_${currentSession.sessionId}_${moment(new Date()).format(
			Edge.Constants.FILE_DATE_FORMAT
		)}${isProCompare && '_pro'}.pdf`;
	};

	private onProCompareChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ isProCompare: !this.state.isProCompare });
	};

	private changeEvaluation = (event: React.ChangeEvent<HTMLSelectElement>) => {
		this.setState({ currentIndex: +event.target.value });
	};
}

function mapStateToProps({ teamState }: AppState) {
	const isCurrTeamYouth = teamState.currentTeam && teamState.currentTeam!.level.id === Edge.Models.LevelId.Youth;

	return {
		isCurrTeamYouth,
	};
}

export default connect(mapStateToProps)(AthleteStatsEdgeScore);
