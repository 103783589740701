import * as Edge from './../core';

export abstract class StaticDataService {
	public static async getSports(): Promise<Edge.Models.Sport[]> {
		const route = Edge.API.ServerRoute.forAction('sports');
		return await Edge.API.get(route);
	}

	public static async getLevels(): Promise<Edge.Models.Level[]> {
		const route = Edge.API.ServerRoute.forAction('levels');
		return await Edge.API.get(route);
	}

	public static async getPositions(): Promise<Edge.Models.Position[]> {
		const route = Edge.API.ServerRoute.forAction('positions');
		return await Edge.API.get(route);
	}

	public static async getAllOrganizationTypes(): Promise<Edge.Models.OrganizationType[]> {
		const route = Edge.API.ServerRoute.forAction('organizationTypes/all');
		return await Edge.API.get(route);
	}

	public static async getPublicOrganizationTypes(): Promise<Edge.Models.OrganizationType[]> {
		const route = Edge.API.ServerRoute.forAction('organizationTypes/public');
		return await Edge.API.get(route);
	}

	public static async getTeamTypes(): Promise<Edge.Models.TeamType[]> {
		const route = Edge.API.ServerRoute.forAction('teamTypes');
		return await Edge.API.get(route);
	}

	public static async getTeamRoles(): Promise<Edge.Models.Role[]> {
		const route = Edge.API.ServerRoute.forAction('teams/roles');
		return await Edge.API.get(route);
	}

	public static async getOrganizationRoles(): Promise<Edge.Models.Role[]> {
		const route = Edge.API.ServerRoute.forAction('organizations/roles');
		return await Edge.API.get(route);
	}

	public static async getPrices(): Promise<Edge.Models.Price[]> {
		const route = Edge.API.ServerRoute.forAction('commerce/prices');
		return await Edge.API.get(route);
	}
}
