import * as Edge from '../core';

// Tag: New Exercise
// Add new icon file to `../assets/images/icons/` and import here.
// See [Exercise Icon page](https://dev.azure.com/vizualedge/Edge%20Trainer/_wiki/wikis/Vizual%20Edge%20Documentation/11/exercise_icons).
import { ReactComponent as AlignmentIcon } from '../assets/images/icons/Alignment.svg';
import { ReactComponent as ContrastSensitivityIcon } from '../assets/images/icons/ContrastSensitivity.svg';
import { ReactComponent as ContrastTrackingIcon } from '../assets/images/icons/ContrastTracking.svg';
import { ReactComponent as ConvergenceIcon } from '../assets/images/icons/Convergence.svg';
import { ReactComponent as DepthIcon } from '../assets/images/icons/DepthPerception.svg';
import { ReactComponent as DivergenceIcon } from '../assets/images/icons/Divergence.svg';
import { ReactComponent as PursuitsIcon } from '../assets/images/icons/Pursuits.svg';
import { ReactComponent as TrackingIcon } from '../assets/images/icons/Tracking.svg';
import { ReactComponent as RecognitionIcon } from '../assets/images/icons/Recognition.svg';

export interface ExerciseMetadata {
	defaultOrder: number; // Results order.
	description: string;
	implementedDate: Date; // Estimated date of exercise publication.
	logo: typeof AlignmentIcon;
	title: string;
	usesGlasses: boolean;
}

const convergenceDescription =
	'Convergence is the ability to focus on objects within close proximity and judge their movement.';
const divergenceDescription =
	'Divergence is the ability to locate objects in the distance, impacting an athlete’s ability to anticipate and react.';

// Tag: New Exercise
// Add array entry for new exercise with desired metadata.
/**
 * Defines exercise metadata for exercise list pages.
 */
const metadata: (ExerciseMetadata & { exerciseTypeId: Edge.Models.ExerciseTypeId })[] = [
	{
		defaultOrder: 1,
		description:
			'Proper Alignment creates no difference between the perceived location and the actual location of an object.',
		implementedDate: new Date(2010, 0, 1),
		exerciseTypeId: Edge.Models.ExerciseTypeId.Alignment,
		logo: AlignmentIcon,
		title: 'Alignment',
		usesGlasses: true,
	},
	{
		defaultOrder: 2,
		description:
			'Depth Perception uses both eyes to locate objects in space to judge their distance, speed, and direction.',
		implementedDate: new Date(2010, 0, 1),
		exerciseTypeId: Edge.Models.ExerciseTypeId.Depth,
		logo: DepthIcon,
		title: 'Depth Perception',
		usesGlasses: true,
	},
	{
		defaultOrder: 3,
		description: convergenceDescription,
		implementedDate: new Date(2010, 0, 1),
		exerciseTypeId: Edge.Models.ExerciseTypeId.Convergence,
		logo: ConvergenceIcon,
		title: 'Convergence',
		usesGlasses: true,
	},
	{
		defaultOrder: 4,
		description: divergenceDescription,
		implementedDate: new Date(2010, 0, 1),
		exerciseTypeId: Edge.Models.ExerciseTypeId.Divergence,
		logo: DivergenceIcon,
		title: 'Divergence',
		usesGlasses: true,
	},
	{
		defaultOrder: 5,
		description: `${convergenceDescription} ${divergenceDescription}`,
		implementedDate: new Date(2010, 0, 1),
		exerciseTypeId: Edge.Models.ExerciseTypeId.Alternating,
		logo: ConvergenceIcon,
		title: 'Alternating',
		usesGlasses: true,
	},
	{
		defaultOrder: 6,
		description:
			'Recognition is the ability to observe, process and recall a series of visual targets, and respond properly.',
		implementedDate: new Date(2010, 0, 1),
		exerciseTypeId: Edge.Models.ExerciseTypeId.Recognition,
		logo: RecognitionIcon,
		title: 'Recognition',
		usesGlasses: false,
	},
	{
		defaultOrder: 7,
		description:
			'Tracking allows you to follow an object while continuously monitoring all other aspects of the game.',
		implementedDate: new Date(2010, 0, 1),
		exerciseTypeId: Edge.Models.ExerciseTypeId.Tracking,
		logo: TrackingIcon,
		title: 'Tracking',
		usesGlasses: false,
	},
	{
		defaultOrder: 8,
		description: `Pursuits are smooth eye movements that involve following or tracking a moving target and responding properly, which is important for an athlete's ability to continuously follow a ball or puck.`,
		implementedDate: new Date(2020, 8, 1),
		exerciseTypeId: Edge.Models.ExerciseTypeId.Pursuits,
		logo: PursuitsIcon,
		title: 'Pursuits',
		usesGlasses: false,
	},
	{
		defaultOrder: 9,
		description:
			'Contrast tracking measures the ability to distinguish subtle light-dark differences in moving objects.',
		implementedDate: new Date(2021, 1, 1),
		exerciseTypeId: Edge.Models.ExerciseTypeId.ContrastTracking,
		logo: ContrastTrackingIcon,
		title: 'Contrast Tracking',
		usesGlasses: false,
	},
	{
		defaultOrder: 10,
		description:
			'Contrast sensitivity measures the ability to distinguish subtle light-dark differences in objects.',
		implementedDate: new Date(2020, 11, 1),
		exerciseTypeId: Edge.Models.ExerciseTypeId.ContrastSensitivity,
		logo: ContrastSensitivityIcon,
		title: 'Contrast Sensitivity',
		usesGlasses: false,
	},
];

// Tag: New Exercise
// Add array entry for new exercise with desired default values.
/**
 * Defines default values for Open Gym exercises.
 * Ordered alphabetically (exercise name).
 */
const openGymExercises: Edge.Models.ExerciseConfiguration[] = [
	{
		durationSeconds: 120,
		exerciseTypeId: Edge.Models.ExerciseTypeId.Alternating,
		level: 1,
		size: Edge.Models.ExerciseSize.Small,
	},
	{
		durationSeconds: 120,
		exerciseTypeId: Edge.Models.ExerciseTypeId.ContrastSensitivity,
		level: 1,
		size: Edge.Models.ExerciseSize.Medium,
	},
	{
		durationSeconds: 120,
		exerciseTypeId: Edge.Models.ExerciseTypeId.ContrastTracking,
		level: 1,
		size: Edge.Models.ExerciseSize.Medium,
		speed: Edge.Models.ExerciseSpeed.None,
		trackingMode: Edge.Models.TrackingMode.Saccades,
	},
	{
		durationSeconds: 120,
		exerciseTypeId: Edge.Models.ExerciseTypeId.Convergence,
		level: 1,
		size: Edge.Models.ExerciseSize.Small,
	},
	{
		durationSeconds: 120,
		exerciseTypeId: Edge.Models.ExerciseTypeId.Depth,
		level: 1,
		size: Edge.Models.ExerciseSize.Small,
	},
	{
		durationSeconds: 120,
		exerciseTypeId: Edge.Models.ExerciseTypeId.Divergence,
		level: 1,
		size: Edge.Models.ExerciseSize.Small,
	},
	{
		durationSeconds: 120,
		exerciseTypeId: Edge.Models.ExerciseTypeId.Pursuits,
		size: Edge.Models.ExerciseSize.Medium,
		speed: Edge.Models.ExerciseSpeed.Normal,
	},
	{
		arrows: 3,
		durationSeconds: 120,
		exerciseTypeId: Edge.Models.ExerciseTypeId.Recognition,
		flashTimeMilliseconds: 600,
		size: Edge.Models.ExerciseSize.Medium,
	},
	{
		durationSeconds: 60,
		exerciseTypeId: Edge.Models.ExerciseTypeId.Tracking,
		fixation: false,
		flashTimeMilliseconds: 600,
		size: Edge.Models.ExerciseSize.Medium,
	},
];

export abstract class ExerciseMetadataService {
	public static getMetadata(
		exercise: Pick<Edge.Models.ExerciseConfiguration, 'exerciseTypeId'>
	): ExerciseMetadata | undefined {
		return metadata.filter((i) => i.exerciseTypeId === exercise.exerciseTypeId)[0];
	}

	public static getOpenGymExercises(): Edge.Models.ExerciseConfiguration[] {
		return openGymExercises;
	}

	public static getModeName = (mode: Edge.Models.TrackingMode): string => {
		return Object.keys(Edge.Models.TrackingMode)[Object.values(Edge.Models.TrackingMode).indexOf(mode)];
	};

	public static getSizeName(size: Edge.Models.ExerciseSize): string {
		switch (size) {
			case Edge.Models.ExerciseSize.None:
				return '-';
			case Edge.Models.ExerciseSize.Small:
				return 'S';
			case Edge.Models.ExerciseSize.Medium:
				return 'M';
			case Edge.Models.ExerciseSize.Large:
				return 'L';
			default:
				return (size as any).toString();
		}
	}

	public static getSpeedName(speed: Edge.Models.ExerciseSpeed): string {
		switch (speed) {
			case Edge.Models.ExerciseSpeed.None:
				return '-';
			case Edge.Models.ExerciseSpeed.Slow:
				return 'Slow';
			case Edge.Models.ExerciseSpeed.Normal:
				return 'Normal';
			case Edge.Models.ExerciseSpeed.Fast:
				return 'Fast';
			case Edge.Models.ExerciseSpeed.VeryFast:
				return 'Very fast';
			default:
				return (speed as any).toString();
		}
	}
	/**
	 * Determines the settings data to display in stats/training page.
	 *
	 * @param result result data for the exercise.
	 * @return string of settings data to display.
	 */
	public static getConfigDescription(result: Edge.Models.ExerciseResult) {
		// Tag: New Exercise
		// Add switch case along with settings data to return (if not already defined).
		switch (result.exerciseTypeId) {
			case Edge.Models.ExerciseTypeId.Alternating:
			case Edge.Models.ExerciseTypeId.Convergence:
			case Edge.Models.ExerciseTypeId.Depth:
			case Edge.Models.ExerciseTypeId.Divergence:
			case Edge.Models.ExerciseTypeId.ContrastSensitivity:
				return `level: ${result.level}, size: ${this.getSizeName(result.size)}`;
			case Edge.Models.ExerciseTypeId.ContrastTracking:
				return `level: ${result.level}, mode: ${this.getModeName(
					result.trackingMode
				)}, size: ${this.getSizeName(result.size)}, speed: ${this.getSpeedName(result.speed)}`;
			case Edge.Models.ExerciseTypeId.Pursuits:
				return `speed: ${this.getSpeedName(result.speed)}, size: ${this.getSizeName(result.size)}`;
			case Edge.Models.ExerciseTypeId.Recognition:
				return `size: ${this.getSizeName(result.size)}, arrows: ${result.arrows}, flashTime: ${(
					result.flashTimeMilliseconds / 1000
				).toFixed(2)}s`;
			case Edge.Models.ExerciseTypeId.Tracking:
				return `size: ${this.getSizeName(result.size)}, fixation: ${
					result.fixation ? 'yes' : 'no'
				}, flashTime: ${(result.flashTimeMilliseconds / 1000).toFixed(2)}s`;
			case Edge.Models.ExerciseTypeId.Alignment:
			default:
				return '-';
		}
	}
}
