import * as React from 'react';
import classNames from 'classnames';
import { FormikContext, Field, ErrorMessage, FieldConfig, GenericFieldHTMLAttributes, getIn, connect } from 'formik';
import {
	CardElement,
	CardNumberElement,
	CardCVCElement,
	CardExpiryElement,
	ReactStripeElements,
} from 'react-stripe-elements';

import * as Edge from '../../core';
import Input from '../global/input';
import Select from '../global/select';
import TextArea from '../global/textarea';
import './index.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import RenderScale from '../renderScale';

export interface FormFieldProps extends FieldConfig {
	label?: React.ReactNode;
	description?: string;
	instructions?: React.ReactNode;
}

export interface FormFieldPropsWithContext extends FormFieldProps {
	formik: FormikContext<any>;
}

export class FormField extends React.Component<FormFieldPropsWithContext & GenericFieldHTMLAttributes> {
	public render() {
		const {
			name,
			className,
			description,
			placeholder: passedPlaceholder,
			label: passedLabel,
			instructions,
			formik,
			...rest
		} = this.props;
		const placeholder = passedPlaceholder || description;
		const label = passedLabel || (description && <>{description}</>);
		const isErrored = getIn(formik.errors, name) && getIn(formik.touched, name);
		return (
			<div
				className={classNames(
					'form-field',
					rest.type && `form-field-type-${rest.type}`,
					typeof rest.component === 'string' && `form-field-component-${rest.component}`,
					className,
					{ error: isErrored }
				)}
			>
				{label && <label htmlFor={this.props.name}>{label}</label>}
				{instructions}
				<Field id={name} name={name} placeholder={placeholder} {...rest} component={this.getComponent()} />
				<ErrorMessage name={name} render={(error) => <div className="error-message">{error}</div>} />
			</div>
		);
	}
	private getComponent = () => {
		if (this.props.component && typeof this.props.component !== 'string') {
			return this.props.component;
		}
		switch (this.props.component) {
			case 'select':
				return SelectFormField;
			case 'textarea':
				return TextAreaFormField;
			case 'recaptcha':
				return RecaptchaFormField;
			case 'card':
				return CardElementFormField;
			case 'cardNumber':
				return CardNumberElementFormField;
			case 'cardCVC':
				return CardCVCElementFormField;
			case 'cardExpiry':
				return CardExpiryElementFormField;
			case 'renderScale':
				return RenderScaleFormField;
			default:
				switch (this.props.type) {
					case 'checkbox':
						return CheckboxFormField;
					default:
						return InputFormField;
				}
		}
	};
}
interface RecaptchaFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
	field: React.InputHTMLAttributes<HTMLInputElement>;
}

class RecaptchaFormField extends React.Component<RecaptchaFormFieldProps> {
	public render() {
		const { onChange, onBlur, name } = this.props.field;
		return (
			<ReCAPTCHA
				sitekey={Edge.Configuration.recaptchaKey}
				onChange={(value) => {
					// formik expects onChange to be called when the change is complete, and onBlur to be called when focus is lost.
					// since there's really no focus concept for this control, we just fire both when the recaptcha is complete
					if (onChange) {
						// need to look like a react event like Formik expects
						(onChange as any)({ target: { name, value } });
					}
					if (onBlur) {
						(onBlur as any)();
					}
				}}
			/>
		);
	}
}

interface RenderScaleFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
	field: React.InputHTMLAttributes<HTMLInputElement>;
}

class RenderScaleFormField extends React.Component<RenderScaleFormFieldProps> {
	public render() {
		const { onChange, onBlur, name, value } = this.props.field;
		return (
			<RenderScale
				value={value as number}
				onChange={(value) => {
					// formik expects onChange to be called when the change is complete, and onBlur to be called when focus is lost.
					// since there's really no focus concept for this control, we just call both on value change
					if (onChange) {
						// need to look like a react event like Formik expects
						(onChange as any)({ target: { name, value } });
					}
					if (onBlur) {
						(onBlur as any)();
					}
				}}
			/>
		);
	}
}

function customInputComponentBuilder<P extends React.InputHTMLAttributes<THtmlElement>, THtmlElement>(
	Component: React.ComponentClass<P>
) {
	interface InputFormFieldProps {
		field: React.InputHTMLAttributes<THtmlElement>;
	}

	return class InputFormField extends React.Component<InputFormFieldProps & P> {
		render() {
			const { field, ...rest } = this.props;
			return (
				<Component
					{...rest as P}
					{...field}
					{...field && field.onChange && rest.onChange && { onChange: this.onChange }}
				/>
			);
		}
		private onChange = (e: React.ChangeEvent<THtmlElement>) => {
			const { field, ...rest } = this.props;
			field.onChange!(e);
			rest.onChange!(e);
		};
	};
}

class CheckboxInput extends React.Component<
	React.InputHTMLAttributes<HTMLInputElement> & { form: FormikContext<any> }
> {
	public render() {
		const checked = !!this.props.form.values[this.props.name || this.props.id!];
		return <Input {...this.props} checked={checked} />;
	}
}

const InputFormField = customInputComponentBuilder(Input);
const CheckboxFormField = customInputComponentBuilder(CheckboxInput);
const SelectFormField = customInputComponentBuilder(Select);
const TextAreaFormField = customInputComponentBuilder(TextArea);

function stripeElementBuilder(Component: React.ComponentType<ReactStripeElements.ElementProps>) {
	interface StripeElementFormFieldProps {
		field: React.InputHTMLAttributes<HTMLInputElement>;
		formik: FormikContext<any>;
	}
	interface StripeElementFormFieldState {
		lastError?: string;
	}

	class StripeElementFormField extends React.Component<StripeElementFormFieldProps, StripeElementFormFieldState> {
		constructor(props: StripeElementFormFieldProps) {
			super(props);
			this.state = {};
		}
		render() {
			return (
				<>
					<Component
						onChange={this.onChange}
						onBlur={this.onBlur}
						style={{ base: { color: '#646464', '::placeholder': { color: '#7d7d7d' } } }}
					/>
					{this.state.lastError && <div className="error-message">{this.state.lastError}</div>}
				</>
			);
		}
		private onChange = (event: stripe.elements.ElementChangeResponse) => {
			// console.log('onChange', event);
			this.setState({ lastError: event.error && event.error.message });
		};
		private onBlur = (event: stripe.elements.ElementChangeResponse) => {
			// failed attempt to wire validation up to formik directly - may explore further in the future, so leaving this here for that
			// console.log('onBlur', event);
			// const { lastError } = this.state;
			// this.props.formik.setFieldTouched(this.props.field.name!, true);
			// this.props.formik.setFieldError(this.props.field.name!, lastError || '');
			// console.log(this.props.formik.values, this.props.formik.errors);
			// this.props.formik.validate
		};
	}

	return connect(StripeElementFormField);
}

const CardElementFormField = stripeElementBuilder(CardElement);
const CardNumberElementFormField = stripeElementBuilder(CardNumberElement);
const CardCVCElementFormField = stripeElementBuilder(CardCVCElement);
const CardExpiryElementFormField = stripeElementBuilder(CardExpiryElement);

export default connect<FormFieldProps & GenericFieldHTMLAttributes, FormFieldPropsWithContext>(FormField);
