import * as React from 'react';

import * as Edge from '../../core';
import Table from '../../components/table';
import Error from '../../components/global/error';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import PageTitle from '../../components/pageTitle';
import AppPageContainer from '../../components/pageContainer';

import './index.scss';
import { getPrices } from '../../store/staticData';
import { CommerceService } from '../../services/commerceService';
import ModalContainer from '../../components/global/modal';
import moment from 'moment';
import Loading from '../../components/loading';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import { getPriceTitle, getPriceTitleCount } from '../../utilities/commerceUtil';
import ScrollToTopOnMount from '../../components/global/scrollToTopOnMount';

class CheckoutsTable extends Table<Edge.Models.CheckoutSessionSummary> {}
class CheckoutDetailsTable extends Table<Edge.Models.GetOrderDetail> {}

type PromiseResolve = (orderDetail: Edge.Models.GetOrderDetail[]) => void;
type PromiseReject = (error: Edge.Models.EdgeError) => void;

interface CheckoutProps {
	checkoutSummaries: Edge.Models.CheckoutSessionSummary[];
	prices?: Edge.Models.Price[];
	getPrices: () => Promise<void>;
}

interface CheckoutsState {
	checkoutIdToShow?: string;
	checkoutDetails?: Edge.Models.GetOrderDetail[];
	isLoadingDetail?: boolean;
	error?: Edge.Models.EdgeError;
}

const availableSearches: ((item: Edge.Models.CheckoutSessionSummary) => string | undefined)[] = [
	(item) => item.id, // Not a table column but still searchable
	(item) => item.recipient,
	(item) => moment(item.createdAtUtc).format(Edge.Constants.DATE_FORMAT),
	(item) => item.price,
	(item) => item.checkoutSessionType,
];

const availableSorts: { [x: string]: (item: Edge.Models.CheckoutSessionSummary) => any } = {
	recipient: (item) => item.recipient.toLowerCase(),
	createdAt: (item) => item.createdAtUtc,
	checkoutType: (item) => item.checkoutSessionType,
	price: (item) => item.amount,
};

export class Checkouts extends React.PureComponent<CheckoutProps, CheckoutsState> {
	constructor(props: CheckoutProps) {
		super(props);
		this.state = {};
	}

	public render() {
		const { checkoutSummaries } = this.props;
		const { checkoutIdToShow, checkoutDetails, isLoadingDetail, error } = this.state;

		return (
			<>
				<ScrollToTopOnMount />
				<PageTitle title="Order History" />
				<AppPageContainer>
					<CheckoutsTable
						className="orders_table"
						items={checkoutSummaries!}
						availableSearches={availableSearches}
						availableSorts={availableSorts}
						searchPlaceholderText="Search by Order/Product ID, Type, Recipient, Date"
						initialSort={['!createdAt']}
						renderHeader={() => <></>}
						renderTableHeader={(classNames, toggleSort) => (
							<tr>
								<th className={classNames('recipient')} onClick={toggleSort.bind(this, 'recipient')}>
									Recipient
								</th>
								<th className={classNames('createdAt')} onClick={toggleSort.bind(this, 'createdAt')}>
									Created At
								</th>
								{/* Not sortable */}
								<th className={classNames('sku')}>Product Name, Qty</th>
								<th className={classNames('orderType')} onClick={toggleSort.bind(this, 'orderType')}>
									Order Type
								</th>
								<th className={classNames('price')} onClick={toggleSort.bind(this, 'price')}>
									Price
								</th>
							</tr>
						)}
						renderTableItem={(i: Edge.Models.CheckoutSessionSummary) => (
							// Sets row as "unclickable" if Id is empty
							<tr
								className={i.id.length === 0 ? 'no_detail' : ''}
								onClick={() => this.openShowDetail(i.id)}
							>
								<td>{i.recipient}</td>
								<td>{moment(i.createdAtUtc).format(Edge.Constants.DATE_FORMAT)}</td>
								<td>
									{this.getPriceTitleCount(i.price, i.quantity)}
									{i.glassesQuantity > 0 && <br />}
									{this.getGlassesPriceTitleCount(i.glassesQuantity)}
								</td>
								<td>{i.checkoutSessionType}</td>
								<td>${i.amount.toFixed(2)}</td>
							</tr>
						)}
						renderNoItems={() => (
							<tr>
								<td colSpan={5}>No order history to show.</td>
							</tr>
						)}
						pagingMode="seeMore"
						seeMoreText="See more orders"
					/>

					{/* Show Detail Modal */}
					{checkoutIdToShow && (
						<ModalContainer title="Order Detail" open={true} onClose={this.closeShowDetail}>
							{isLoadingDetail && <Loading />}
							{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
							{checkoutDetails && (
								<CheckoutDetailsTable
									className={'detail_table'}
									items={checkoutDetails!}
									renderTableHeader={() => <></>}
									renderTableItem={(o: Edge.Models.GetOrderDetail) => (
										<tr>
											<td>{o.label}</td>
											<td className="right_align">
												{o.values.map((s, i) => (
													<React.Fragment key={i}>
														{o.label.toLowerCase() === 'sku' ? this.getPriceTitle(s) : s}
														{i !== o.values.length - 1 && <br />}
													</React.Fragment>
												))}
											</td>
										</tr>
									)}
									pagingMode="none"
								/>
							)}
						</ModalContainer>
					)}
				</AppPageContainer>
			</>
		);
	}

	// Show Detail methods
	private openShowDetail = (orderIdToShow: string) => {
		// If ID is empty, do nothing
		if (orderIdToShow.length === 0) return;

		this.setState({
			checkoutIdToShow: orderIdToShow,
			checkoutDetails: undefined,
			isLoadingDetail: true,
			error: undefined,
		});

		this.loadDetail(orderIdToShow);
	};

	private closeShowDetail = () => {
		this.setState({
			checkoutIdToShow: undefined,
			checkoutDetails: undefined,
			isLoadingDetail: undefined,
			error: undefined,
		});
	};

	private loadDetail = (orderIdToShow: string) => {
		const promise = new Promise(async (resolve: PromiseResolve, reject: PromiseReject) => {
			try {
				const details = await CommerceService.getOrderAndCheckoutSessionDetail(orderIdToShow);
				resolve(details);
			} catch (e) {
				reject(e);
			}
		});

		promise.then((checkoutDetails) => {
			this.setState({
				checkoutDetails: checkoutDetails,
				isLoadingDetail: false,
			});
		});
		promise.catch((error) =>
			this.setState({
				error,
				isLoadingDetail: false,
			})
		);
	};

	// Converts Product ID to Product title
	private getPriceTitle = (priceId: string) => {
		const prices = this.props.prices;
		const price = prices && prices!.find((i) => i.id === priceId);

		if (price) return getPriceTitle(price);

		return priceId;
	};

	// Converts Product ID and quantity to Product name and count
	private getPriceTitleCount = (priceId: string, priceQty: number) => {
		const prices = this.props.prices;
		const price = prices && prices!.find((i) => i.id === priceId);

		if (price) return getPriceTitleCount(price, priceQty);

		return `${priceId} (${priceQty})`;
	};

	// Converts glasses quantity to a readable string
	private getGlassesPriceTitleCount = (qty: number) => {
		if (qty > 0)
			return getPriceTitleCount({ priceType: Edge.Models.PriceTypes.Glasses } as Edge.Models.GlassesPrice, qty);

		return false;
	};
}

function mapStateToProps({ priceState }: AppState) {
	const prices = priceState.data;

	return {
		prices: prices,
	};
}

export default connect(
	mapStateToProps,
	{
		getPrices: getPrices,
	}
)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(
			Checkouts,
			(props: CheckoutProps) => !!props.prices,
			undefined,
			(props: CheckoutProps) => {
				if (!props.prices) props.getPrices();
			},
			{ loadingOptions: { blockItem: true } }
		),
		(props: CheckoutProps) => {},
		async () => {
			const checkoutSummaries = await CommerceService.getOrdersAndCheckoutsSummaries();
			return { checkoutSummaries: checkoutSummaries };
		}
	)
);
