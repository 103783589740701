import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';

import { getUserPermissionService } from '../../store/permissions/selector';
import { AppState } from '../../store';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import * as Edge from '../../core';
import AccessDenied from '../../components/global/accessDenied';
import AppPageContainer from '../../components/pageContainer';
import PageTitle from '../../components/pageTitle';
import AreaNav from '../../components/areaNav';
import ManagePlan from './managePlan';
import MyOrganization from './myOrganization';
import ManageKeys from './manageKeys';
import { getApiKeys } from '../../store/apiKey/actions';
import { withLoadDataDefaultConfig } from '../../components/loadData';

export interface ManageOrganizationProps {
	currentOrganization: Edge.Models.Organization;
	permissions?: Edge.Models.AssignedRole;
	isAdmin?: boolean;

	getApiKeys: (id: string) => Promise<void>;
}

const navItems = [
	{ to: '/manage-organization', text: 'My Organization', exact: true },
	{ to: '/manage-organization/manage-plan', text: 'Manage Plan' },
	{ to: '/manage-organization/manage-keys', text: 'Manage Keys' },
];

export const ManageOrganization: React.FunctionComponent<ManageOrganizationProps> = (props) => {
	const { permissions, isAdmin } = props;
	// if we add a readonly org-level permission, lower this to read and make sure read is supported on the rest of the page
	if (permissions && permissions.organizationPermissions < Edge.Models.PermissionLevels.Edit) {
		return <AccessDenied />;
	}

	const permittedItems = navItems.filter((i) => isAdmin || i.text !== 'Manage Keys');

	return (
		<>
			<PageTitle title="Organization Settings" />
			<AreaNav items={permittedItems} />
			<AppPageContainer>
				<Switch>
					{isAdmin && <Route path="/manage-organization/manage-keys" component={ManageKeys} />}
					<Route path="/manage-organization/manage-plan" component={ManagePlan} />
					<Route path="/manage-organization" component={MyOrganization} />
					<Redirect to="/manage-organization" />
				</Switch>
			</AppPageContainer>
		</>
	);
};

function mapStateToProps(state: AppState) {
	const { organizationState } = state;
	const { currentOrganization } = organizationState;
	const permissions = getUserPermissionService(state);
	const isAdmin = state.loginState.decodedToken && state.loginState.decodedToken!.is_admin;

	return {
		isLoading: organizationState.isLoading || !permissions.ready(),
		loadingError: organizationState.loadingError || permissions.loadingError(),
		currentOrganization: currentOrganization!,
		permissions: (currentOrganization && permissions.getOrganizationRole(currentOrganization.id))!,
		isAdmin,
	};
}

export default connect(
	mapStateToProps,
	{
		getApiKeys,
	}
)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(ManageOrganization),
		(props: ManageOrganizationProps) => {
			return {
				id: props.currentOrganization && props.currentOrganization.id,
				isAdmin: props.isAdmin,
				getApiKeys: props.getApiKeys,
			};
		},
		async ({
			id,
			isAdmin,
			getApiKeys,
		}: {
			id: string;
			isAdmin?: boolean;
			getApiKeys: (id: string) => Promise<void>;
		}) => {
			if (isAdmin) await getApiKeys(id);
			return {};
		}
	)
);
