import * as React from 'react';
import ErrorHandler from './errorHandler';

export function errorHandlerHOC<P>(Component: React.ComponentType<P>) {
	return class ErrorHandlerHOC extends React.Component<P> {
		public render() {
			return <ErrorHandler render={() => <Component {...this.props} />} />;
		}
	};
}
