import * as React from 'react';
import { connect } from 'react-redux';

import { logout } from '../../store/login/actions';
import { Redirect } from 'react-router';

export interface LogoutProps {
	logout: () => Promise<void>;
}

interface LogoutState {
	redirect: boolean;
}

export class Logout extends React.Component<LogoutProps, LogoutState> {
	constructor(props: LogoutProps) {
		super(props);
		this.state = {
			redirect: false,
		};
	}

	public async componentDidMount() {
		await this.props.logout();
		this.setState({
			redirect: true,
		});
	}

	public render(): JSX.Element | null {
		if (this.state.redirect) {
			return <Redirect to="/" />;
		}

		return null;
	}
}

export default connect(
	undefined,
	{
		logout,
	}
)(Logout);
