import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import AppPageContainer from '../../../../components/pageContainer';

import * as Edge from '../../../../core';
import FormField from '../../../../components/formField';
import Input from '../../../../components/global/input';
import Info from '../../../../components/global/info';
import Loading from '../../../../components/loading';

import '../index.scss';

export interface MigrateSessionsProps {
	onSubmit: (v: MigrateSessionsToNewUserFormValues) => Promise<void>;
}

export interface MigrateSessionsToNewUserFormValues {
	prospectUsername: string;
	newUsername: string;
}

const schema = Yup.object().shape({
	prospectUsername: Yup.string().required('Prospect username is required'),
	newUsername: Yup.string().required('New username is required'),
});

export const MigrateSessions: React.FunctionComponent<MigrateSessionsProps> = (props) => (
	<AppPageContainer>
		<div className="migrate">
			<h4>Migrate prospect sessions to new user</h4>
			<hr />
			<Formik
				initialValues={{
					/** anything not specified here won't show an error message after an attempted submit */
					prospectUsername: '',
					newUsername: '',
				}}
				validationSchema={schema}
				onSubmit={async (values, actions) => {
					actions.setStatus(undefined);
					try {
						await props.onSubmit(values);
						actions.setStatus('Success');
					} catch (e) {
						const message = Edge.API.getErrorMessage(e);
						actions.setStatus(message);
					} finally {
						actions.setSubmitting(false);
					}
				}}
				render={(props: FormikProps<MigrateSessionsToNewUserFormValues>) => (
					<Form>
						<FormField type="text" name="prospectUsername" description="Prospect username" />
						<FormField type="text" name="newUsername" description="New username" />
						{props.status && <Info>{props.status}</Info>}
						{props.isSubmitting && <Loading buttonLoader />}
						<Input type="submit" disabled={props.isSubmitting} value="Migrate" />
					</Form>
				)}
			/>
		</div>
	</AppPageContainer>
);

export default MigrateSessions;
