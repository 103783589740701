import React, { useEffect, useState } from 'react';
import PurchaseGlasses from '../../components/purchaseGlasses';
import CardRegisterWrapper from './cardRegisterWrapper';
import ScrollToTopOnMount from '../../components/global/scrollToTopOnMount';
import BlockRegistration from '../../components/redirectExternal/blockRegistration';
import { CommerceService } from '../../services/commerceService';
import * as Edge from '../../core';
import { CheckoutProps } from '../checkout/models';
import history from '../../services/history';

export interface Page2Props extends CheckoutProps {
	prices: Edge.Models.Price[];
}

function Page2(props: Page2Props) {
	const [url, setUrl] = useState<string>();

	useEffect(() => {
		if (url) {
			window.location.href = url;
		}
	}, [url]);

	const { model } = props;
	if (!model.price) {
		return <BlockRegistration />;
	}

	return (
		<CardRegisterWrapper {...props} allowEdit>
			<ScrollToTopOnMount />
			<PurchaseGlasses
				initialValues={{
					glassesNumber: props.model.numberOfGlasses || 0,
				}}
				onSubmit={async (values) => {
					const checkout = await CommerceService.createCheckoutSession({
						glassesQuantity: values.glassesNumber!,
						country: values.country,
						price: model.price!.price.id,
						quantity: model.quantity!,
						entityType: Edge.Models.EntityType.Organization,
						entityId: model.organizationId!,
						organizationId: model.organizationId!,
						checkoutSessionType: Edge.Models.CheckoutSessionType.Initial,
						successUrl: `${origin}/checkout-successful`,
						cancelUrl: 'https://vizualedge.com/signup/',
					});

					setUrl(checkout.url);
				}}
			/>
		</CardRegisterWrapper>
	);
}

export default Page2;
