import * as React from 'react';
import { Form, Formik, FormikProps, FieldArray } from 'formik';
import * as Yup from 'yup';

import * as Edge from '../../core';
import FormField from '../../components/formField';
import Error from '../../components/global/error';
import Loading from '../../components/loading';

export interface AssignRolesProps {
	roleName: string;
	initialValues?: AssignRolesValues;
	assignRoles: (Roles: AssignRolesValues) => Promise<void>;
}

export interface AssignRolesValues {
	users: string[];
}

const userSchema = Yup.string().email('Must be a valid email address');
const usersSchema = Yup.array(userSchema);
const assignRolesSchema = Yup.object({
	users: usersSchema,
});

export default class AssignRoles extends React.PureComponent<AssignRolesProps> {
	public render(): JSX.Element {
		const { initialValues, assignRoles, roleName } = this.props;
		return (
			<div>
				<Formik
					initialValues={Object.assign(
						{},
						{
							users: ['', '', ''],
						},
						initialValues || {}
					)}
					validationSchema={assignRolesSchema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await assignRoles(values);
						} catch (e) {
							actions.setStatus(Edge.API.getErrorMessage(e));
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<AssignRolesValues>) => {
						return (
							<Form>
								<FieldArray
									name="users"
									validateOnChange={false}
									render={(arrayHelpers) => (
										<div>
											{props.values.users.map((user, index) => (
												<div key={index}>
													<FormField
														name={`users.${index}`}
														label={`Assign ${roleName}`}
														placeholder="Email"
														type="email"
													/>
												</div>
											))}
											<button type="button" onClick={() => arrayHelpers.push('')}>
												+ Add a {roleName}
											</button>
										</div>
									)}
								/>
								{props.status && <Error>{props.status}</Error>}
								{props.isSubmitting && <Loading buttonLoader />}
								<button type="submit" disabled={props.isSubmitting}>
									Send Invites
								</button>
							</Form>
						);
					}}
				/>
			</div>
		);
	}
}
