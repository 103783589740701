import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import * as Edge from '../../core';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import { CommerceService } from '../../services/commerceService';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { CheckoutProps } from '../checkout/models';

interface PriceRouteProps {
	priceId?: string;
	couponId?: string;
}

export interface LandingPageProps extends RouteComponentProps<PriceRouteProps>, CheckoutProps {}

/**
 * This page's job is to grab the Product from the route, load the Product onto the registration model, and redirect to the real first page
 */
export class LandingPage extends React.Component<LandingPageProps> {
	public render() {
		return <Redirect to="/register/page-1" />;
	}
}

export default withLoadDataDefaultConfig(
	errorLoadingWrapperHOC(LandingPage, undefined, (message, error) => Edge.API.getStatus(error) === 404, undefined, {
		loadingOptions: { blockItem: true },
	}),
	(props: LandingPageProps) => {
		const { priceId, couponId } = props.match.params;
		return { priceId, couponId };
	},
	async (params: PriceRouteProps, props: LandingPageProps) => {
		const { priceId, couponId } = params;

		if (!priceId) {
			return {};
		}

		const price = priceId ? await CommerceService.getPriceDetail(priceId) : undefined;
		const coupon = couponId
			? await CommerceService.getCoupon(couponId).catch(() => {
					// Invalid coupon
					return undefined;
			  })
			: undefined;
		await props.onChange({ price, coupon });
		return {};
	}
);
