import * as React from 'react';
import * as Edge from '../../../core';
import Error from '../../../components/global/error';

export interface RemoveTeamMemberProps {
	teamMember: Edge.Models.TeamMember;
	onConfirm: () => Promise<void>;
	onCancel: () => void;
}

interface RemoveTeamMemberState {
	error?: string;
}

export class RemoveTeamMember extends React.PureComponent<RemoveTeamMemberProps, RemoveTeamMemberState> {
	constructor(props: RemoveTeamMemberProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { teamMember, onCancel } = this.props;
		const { error } = this.state;
		return (
			<>
				<p>
					Are you sure you want to remove{' '}
					{teamMember.user && (
						<strong>
							{teamMember.user.firstName} {teamMember.user.lastName}
						</strong>
					)}{' '}
					from this team?
				</p>
				{error && <Error>{error}</Error>}
				<button onClick={this.onConfirm} className="cta_btn remove_member_btn">
					Yes
				</button>
				<button onClick={onCancel} className="cta_btn remove_member_btn">
					Cancel
				</button>
			</>
		);
	}
	private onConfirm = async () => {
		const { onConfirm } = this.props;
		try {
			this.setState({ error: undefined });
			await onConfirm();
		} catch (e) {
			this.setState({ error: Edge.API.getErrorMessage(e) });
		}
	};
}
export default RemoveTeamMember;
