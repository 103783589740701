import * as React from 'react';
import * as FileSaver from 'file-saver';
import classNames from 'classnames';

import * as Edge from '../../core';
import Loading from '../loading';
import Error from '../global/error';

import './index.scss';

export interface DownloadButtonProps {
	className?: string;
	defaultFileName: string;
	onClick: () => Promise<Edge.Models.File | undefined>;
}

interface DownloadButtonState {
	loading?: boolean;
	error?: Edge.Models.EdgeError;
}

export class DownloadButton extends React.PureComponent<DownloadButtonProps, DownloadButtonState> {
	constructor(props: DownloadButtonProps) {
		super(props);
		this.state = {};
	}

	public render() {
		const { className } = this.props;
		const { loading, error } = this.state;
		return (
			<div className={classNames('download_button', className)}>
				{loading && <Loading buttonLoader />}
				<button className="cta_btn btn_bright btn_small" onClick={this.onClick} disabled={loading}>
					{this.props.children}
				</button>
				{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
			</div>
		);
	}

	private onClick = async () => {
		const { onClick, defaultFileName } = this.props;
		try {
			this.setState({ loading: true, error: undefined });

			const response = await onClick();
			if (response) {
				FileSaver.saveAs(response.blob, response.filename || defaultFileName);
			}

			this.setState({ loading: false });
		} catch (error) {
			this.setState({ loading: false, error });
		}
	};
}
export default DownloadButton;
