import * as React from 'react';

import './index.scss';

export interface RedirectExternalProps {
	to: string;
}

export class RedirectExternal extends React.PureComponent<RedirectExternalProps> {
	public componentDidMount() {
		window.location.href = this.props.to;
	}
	public render() {
		return <div className="redirect_external">{this.props.children}</div>;
	}
}
export default RedirectExternal;
