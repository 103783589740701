import * as React from 'react';
import * as _ from 'lodash';

import * as Edge from '../../core';
import Loading from '../loading';
import Error from '../global/error';
import Select from '../global/select';
import { withLoadDataDefaultConfig } from '../loadData';
import errorLoadingWrapperHOC from '../errorLoadingWrapper/errorLoadingWrapperHOC';
import { CommerceService } from '../../services/commerceService';

interface UpdateCurrentPriceProps {
	entityId: string;
	entityType: Edge.Models.EntityType;
	organizationId: string;
	currentPrice: Edge.Models.Price;
	organizationPrices: Edge.Models.OrganizationAllowedPrice[];
	updateCurrentPrice: (priceId: string) => Promise<void>;
	closeModal: () => void;
	reloadParent: () => Promise<void>;
}

interface UpdateCurrentPriceState {
	priceId: string;
	loading?: boolean;
	error?: Edge.Models.EdgeError;
}

export class UpdateCurrentPrice extends React.Component<UpdateCurrentPriceProps, UpdateCurrentPriceState> {
	constructor(props: UpdateCurrentPriceProps) {
		super(props);
		const { currentPrice, organizationPrices } = this.props;
		this.state = {
			priceId: currentPrice ? currentPrice.id : this.getPrices(organizationPrices)[0].id,
		};
	}

	public render(): JSX.Element {
		const { organizationPrices } = this.props;
		const { loading, error } = this.state;

		const prices = this.getPrices(organizationPrices);

		return (
			<div>
				<Select value={this.state.priceId} onChange={this.priceChanged}>
					{prices.map((i, ix) => (
						<option key={ix} value={i.id}>
							{i.priceType === Edge.Models.PriceTypes.Subscription
								? `${i.months} Months`
								: i.priceType === Edge.Models.PriceTypes.Sessions
								? `${i.sessions} Sessions`
								: undefined}
						</option>
					))}
				</Select>
				{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
				{loading && <Loading buttonLoader />}
				<button onClick={this.updateCurrentPrice} className="cta_btn">
					Submit
				</button>
			</div>
		);
	}

	private getPrices(organizationPrices: Edge.Models.OrganizationAllowedPrice[]) {
		var prices = organizationPrices
			.map((os) => {
				return os.price;
			})
			.filter((price) => price.priceType !== Edge.Models.PriceTypes.Glasses);

		return _.orderBy(
			_.orderBy(prices, (i) =>
				i.priceType === Edge.Models.PriceTypes.Subscription
					? i.months
					: i.priceType === Edge.Models.PriceTypes.Sessions
					? i.sessions
					: undefined
			),
			(i) => i.priceType
		);
	}

	private updateCurrentPrice = async () => {
		this.setState({ loading: true, error: undefined });
		try {
			await this.props.updateCurrentPrice(this.state.priceId);
			this.props.closeModal();
			this.setState({ loading: false });
			await this.props.reloadParent();
		} catch (error) {
			this.setState({ error, loading: false });
		}
	};

	private priceChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
		this.setState({ priceId: event.target.value });
	};
}

export default withLoadDataDefaultConfig(
	errorLoadingWrapperHOC(UpdateCurrentPrice, (props: UpdateCurrentPriceProps) => !!props.organizationPrices),
	(props: UpdateCurrentPriceProps) => {
		return {
			entityId: props.entityId,
			entityType: props.entityType,
			organizationId: props.organizationId,
		};
	},
	async (props) => {
		const organizationPrices = await CommerceService.getOrganizationAllowedPrices(
			props.organizationId,
			props.entityType,
			props.entityId
		);
		return { organizationPrices: organizationPrices };
	}
);
