import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import * as Edge from '../../../core';
import Error from '../../../components/global/error';
import Select from '../../../components/global/select';
import Input from '../../../components/global/input';
import { AppState } from '../../../store';

import './index.scss';

export interface ChangeOrganizationProps {
	currentOrganizationId?: string;
	organizations?: Edge.Models.Organization[];
	changeOrganization: (newOrganizationId: string) => Promise<void>;
}

interface ChangeOrganizationState {
	newOrganizationId: string;
	error?: Edge.Models.EdgeError;
}

export class ChangeOrganization extends React.PureComponent<ChangeOrganizationProps, ChangeOrganizationState> {
	constructor(props: ChangeOrganizationProps) {
		super(props);
		this.state = {
			newOrganizationId: this.props.currentOrganizationId!,
		};
	}
	public render(): JSX.Element {
		const { organizations } = this.props;
		const { error } = this.state;
		return (
			<>
				<p>
					Note: This team can not have any remaining valid credits. If it does, please unassign them back to
					the organization level before attempting to move this team to another organization.
				</p>
				<Select value={this.state.newOrganizationId} onChange={this.organizationChanged}>
					{_.sortBy(organizations, 'name').map((i, ix) => (
						<option key={ix} value={i.id}>
							{i.name}
						</option>
					))}
				</Select>
				{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
				<Input onClick={this.changeOrganization} value="Save" type="submit" />
			</>
		);
	}

	private changeOrganization = async () => {
		try {
			await this.props.changeOrganization(this.state.newOrganizationId);
		} catch (error) {
			this.setState({ error });
		}
	};

	private organizationChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		this.setState({ newOrganizationId: e.target.value });
	};
}

function mapStateToProps(state: AppState) {
	const currentOrganizationId = state.organizationState.currentOrganization!.id;
	const { organizations } = state.organizationState;
	return { currentOrganizationId, organizations };
}

export default connect(mapStateToProps)(ChangeOrganization);
