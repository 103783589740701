import * as Edge from './../../core';

export interface PermissionState {
	permissions?: Permissions;
	isLoading: boolean;
	loadingError?: Edge.Models.EdgeError;
}

export interface Permissions {
	organizations: { [organizationId: string]: Edge.Models.AssignedRole };
	teams: { [teamId: string]: Edge.Models.AssignedRole };
}

export const PERMISSIONS_LOADING = 'PERMISSIONS_LOADING';
export const PERMISSIONS_SUCCESS = 'PERMISSIONS_SUCCESS';
export const PERMISSIONS_FAILURE = 'PERMISSIONS_FAILURE';

interface PermissionsLoading extends Edge.Models.EdgeAction<void, typeof PERMISSIONS_LOADING> {
	type: typeof PERMISSIONS_LOADING;
}

export interface PermissionsSuccess
	extends Edge.Models.EdgeAction<Edge.Models.AssignedRoles, typeof PERMISSIONS_SUCCESS> {
	type: typeof PERMISSIONS_SUCCESS;
	payload: Edge.Models.AssignedRoles;
}

interface PermissionsFailure extends Edge.Models.EdgeAction<Edge.Models.EdgeError, typeof PERMISSIONS_FAILURE> {
	type: typeof PERMISSIONS_FAILURE;
	payload: Edge.Models.EdgeError;
}

export type PermissionActionTypes = PermissionsLoading | PermissionsSuccess | PermissionsFailure;
