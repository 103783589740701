import * as Redux from 'redux';

import {
	SessionState,
	SessionActionTypes,
	SESSION_GETACTIVE_START,
	SESSION_GETACTIVE_SUCCESS,
	SESSION_GETACTIVE_FAILURE,
	SESSION_CHANGE_START,
	SESSION_CHANGE_SUCCESS,
	SESSION_CHANGE_FAILURE,
} from './types';
import { LOGIN_CLEAR_PRELOADED_DATA } from '../login/types';

const initialState: SessionState = {
	isLoading: false,
	loadingError: undefined,
	data: undefined,
	isChanging: false,
	changingError: undefined,
};

const sessionReducer: Redux.Reducer<SessionState> = (
	state = initialState,
	action: SessionActionTypes
): SessionState => {
	switch (action.type) {
		case LOGIN_CLEAR_PRELOADED_DATA:
			return initialState;
		case SESSION_GETACTIVE_START:
			return {
				...state,
				isLoading: true,
				loadingError: undefined,
			};
		case SESSION_GETACTIVE_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.payload,
				loadingError: undefined,
			};
		case SESSION_GETACTIVE_FAILURE:
			return {
				...state,
				isLoading: false,
				loadingError: action.payload,
			};
		case SESSION_CHANGE_START:
			return {
				...state,
				isChanging: true,
				changingError: undefined,
			};
		case SESSION_CHANGE_SUCCESS:
			return {
				...state,
				isChanging: false,
				data: action.payload,
				changingError: undefined,
			};
		case SESSION_CHANGE_FAILURE:
			return {
				...state,
				isChanging: false,
				changingError: action.payload,
			};
		default:
			return state;
	}
};

export default sessionReducer;
