import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import countries from 'country-list';
import * as Yup from 'yup';
import * as _ from 'lodash';

import * as Edge from '../../core';
import FormField from '../formField';
import Input from '../../components/global/input';
import './index.scss';
import { ReactComponent as Glasses } from '../../assets/images/icons/glasses.svg';
import Error from '../global/error';
import Loading from '../loading';

export interface PurchaseGlassesFormProps<> {
	initialValues?: Partial<GlassesOptionFormValues>;
	onSubmit: (values: GlassesOptionFormValues) => Promise<void>;
}

export interface GlassesOptionFormValues {
	glassesNumber: number;
	country: string;
}

const defaultInitialValues: GlassesOptionFormValues = {
	glassesNumber: 0,
	country: 'US',
};

const glassesOptionFormSchema = Yup.object().shape({
	glassesNumber: Yup.number().required('Number of glasses is required'),
	country: Yup.string().when('glassesNumber', {
		is: (value: number) => value === 0,
		then: Yup.string().notRequired(),
		otherwise: Yup.string().required('Country is required.'),
	}),
});

const countryList = _.orderBy(countries.getData(), (i) => i.code === Edge.Constants.US_COUNTRY_CODE, 'desc');

function PurchaseGlasses({ initialValues, onSubmit }: PurchaseGlassesFormProps) {
	return (
		<div className="purchaseGlasses">
			<h3 className="cardTitle">Order Vizual Edge 3D Glasses</h3>
			<p>
				An average number of 1 glasses per player is recommended.{' '}
				<span>Note: Additional glasses can be purchased at any time.</span>
			</p>
			<Formik
				initialValues={_.merge({}, defaultInitialValues, initialValues || {}) as GlassesOptionFormValues}
				validationSchema={glassesOptionFormSchema}
				onSubmit={async (values, actions) => {
					actions.setStatus(undefined);
					actions.setSubmitting(true);
					try {
						await onSubmit(values);
					} catch (e) {
						actions.setStatus(Edge.API.getErrorMessage(e));
					}
					actions.setSubmitting(false);
				}}
				render={(props: FormikProps<GlassesOptionFormValues>) => (
					<Form>
						<div className="icon">
							<Glasses />
						</div>
						<FormField
							component="input"
							type="number"
							name="glassesNumber"
							label="Number of Glasses"
							min={0}
						/>
						{props.values.glassesNumber !== undefined &&
							props.values.glassesNumber > 0 &&
							renderCountryList()}
						{props.status && <Error>{props.status}</Error>}
						{props.isSubmitting && <Loading buttonLoader />}
						<Input type="submit" disabled={props.isSubmitting} value="submit" />
					</Form>
				)}
			/>
		</div>
	);
}

const renderCountryList = (): JSX.Element => {
	return (
		<>
			<h3 className={'cardTitle'}>SELECT SHIPPING DESTINATION (COUNTRY)</h3>

			<FormField component="select" name={'country'} label="Country">
				{countryList.map((c: { code: string; name: string }, ix: number) => {
					return (
						<option key={ix} value={c.code}>
							{c.name}
						</option>
					);
				})}
			</FormField>
		</>
	);
};

export default PurchaseGlasses;
