import * as React from 'react';
import { connect } from 'react-redux';

import OrgSection from './orgSection';
import * as Edge from '../../../core';
import { AppState } from '../../../store';
import { setCurrentOrganization } from '../../../store/organization/actions';
import { Redirect } from 'react-router';

export interface OrgSectionContainerProps {
	organizations?: Edge.Models.Organization[];
	currentOrganization?: Edge.Models.Organization;
	setCurrentOrganization: (organization: Edge.Models.Organization) => void;
}

interface OrgSectionContainerState {
	isActive: boolean;
	redirectToOrganization?: Edge.Models.Organization;
}

export class OrgSectionContainer extends React.Component<OrgSectionContainerProps, OrgSectionContainerState> {
	constructor(props: OrgSectionContainerProps) {
		super(props);
		this.state = { isActive: false };
	}
	public render(): JSX.Element {
		const { organizations, currentOrganization } = this.props;
		const { redirectToOrganization } = this.state;

		if (redirectToOrganization && currentOrganization && redirectToOrganization.id === currentOrganization.id) {
			return <Redirect to="/" />;
		}

		return (
			<OrgSection
				organizations={organizations}
				currentOrganization={currentOrganization}
				setCurrentOrganization={this.setCurrentOrganization}
			/>
		);
	}

	private setCurrentOrganization = (redirectToOrganization: Edge.Models.Organization) => {
		this.setState({ redirectToOrganization });
		this.props.setCurrentOrganization(redirectToOrganization);
	};
}

function mapStateToProps(state: AppState) {
	const { currentOrganization, organizations } = state.organizationState;
	return {
		currentOrganization,
		organizations,
	};
}

export default connect(
	mapStateToProps,
	{
		setCurrentOrganization,
	}
)(OrgSectionContainer);
