import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { OrganizationState } from '../../../store/organization/types';

import { Header } from './index';

export interface HeaderContainerProps
	extends Pick<OrganizationState, 'organizations' | 'currentOrganization' | 'isLoading'> {
	loadingErrorText?: string;
}

export class HeaderContainer extends React.Component<HeaderContainerProps> {
	public componentDidMount() {}

	public render() {
		return <Header {...this.props} />;
	}
}

function mapStateToProps({
	organizationState,
}: AppState): Pick<HeaderContainerProps, 'organizations' | 'currentOrganization' | 'isLoading' | 'loadingErrorText'> {
	const { loadingError, ...rest } = organizationState;
	return {
		...rest,
		loadingErrorText: loadingError ? loadingError.message || loadingError.toString() : undefined,
	};
}

export default connect(mapStateToProps)(HeaderContainer);
