import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import * as Edge from './../../../core';
import Select from '../../../components/global/select';
import { CanEditTeamsBanner } from '../../../components/teamBanner';
import { AppState } from '../../../store';
import { updateTimeSpan } from '../../../store/timeSpan/actions';

import './index.scss';
import { StatsService } from '../../../services/statsService';
import DownloadButton from '../../../components/downloadButton';

interface TimePickerItem {
	label: string;
	days: number;
}

const timePickerItems: TimePickerItem[] = [
	{ label: 'Last 7 days', days: 7 },
	{ label: 'Last 14 days', days: 14 },
	{ label: 'Last 30 days', days: 30 },
	{ label: 'Last 60 days', days: 60 },
	{ label: 'Last 90 days', days: 90 },
	{ label: 'Last 180 days', days: 180 },
	{ label: 'Year-to-date', days: moment().dayOfYear() - 1 },
	{ label: 'Last 12 months', days: new Date().getFullYear() % 4 === 0 ? 366 : 365 },
];

export interface ConfigurationBannerProps {
	timeSpan: Edge.Models.TimeSpan;
	currentTeamId?: string;
	updateTimeSpan: (timeSpan: Edge.Models.TimeSpan) => void;
}

export class ConfigurationBanner extends React.Component<ConfigurationBannerProps, {}> {
	public render(): JSX.Element {
		const { timeSpan, currentTeamId } = this.props;

		return (
			<CanEditTeamsBanner className="dashboard_configuration_banner">
				<div className="vertical">
					<DownloadButton
						defaultFileName={`training_usage_${timeSpan.days}_${currentTeamId ? currentTeamId : ''}.pdf`}
						onClick={this.getPrintableTrainingUsage}
					>
						Training Usage PDF
					</DownloadButton>
					<div className="horizontal">
						<span className="label_desc">Period</span>
						<Select
							onChange={this.changeTimeSpan}
							value={this.props.timeSpan.days}
							className="dashboard_time_picker"
						>
							{timePickerItems.map((item, i) => (
								<option key={i} value={item.days}>
									{item.label}
								</option>
							))}
						</Select>
					</div>
				</div>
			</CanEditTeamsBanner>
		);
	}

	private getPrintableTrainingUsage = () => {
		const timeSpanDays = this.props.timeSpan.days;
		const teamId = this.props.currentTeamId || '';
		return StatsService.getPrintableTrainingUsage(teamId, timeSpanDays);
	};

	private changeTimeSpan = (e: React.ChangeEvent<HTMLSelectElement>) => {
		this.props.updateTimeSpan({ days: +e.target.value });
	};
}

const mapStateToProps = (state: AppState) => {
	return {
		timeSpan: state.timeSpanState.timeSpan,
		currentTeamId: state.teamState.currentTeam && state.teamState.currentTeam.id,
	};
};

export default connect(
	mapStateToProps,
	{
		updateTimeSpan,
	}
)(ConfigurationBanner);
