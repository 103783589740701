import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as Edge from '../../../core';
import AssignCredits, { CreditMode } from '../../../components/assignCredits';
import { AppState } from '../../../store';
import { getAllTeams } from '../../../store/permissions/selector';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { Avatar } from '../../../components/avatar';
import { generatePurchasePageLink } from '../../checkout';

import './index.scss';

export interface OrganizationAssignSeatsProps {
	currentOrganization: Edge.Models.Organization;
	teams: Edge.Models.Team[];
}

interface OrganizationAssignSeatsState {}

export class OrganizationAssignSeats extends React.PureComponent<
	OrganizationAssignSeatsProps,
	OrganizationAssignSeatsState
> {
	constructor(props: OrganizationAssignSeatsProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { currentOrganization, teams } = this.props;
		return (
			<>
				<AssignCredits
					allowMultipleSubscriptions
					sourceEntityId={currentOrganization.id}
					sourceEntityType={Edge.Models.EntityType.Organization}
					destinationEntityType={Edge.Models.EntityType.Team}
					render={(props) => {
						// #1395: https://dev.azure.com/vizualedge/Edge%20Trainer/_backlogs/backlog/Edge%20Trainer%20Team/Stories/?workitem=1395
						// TODO: does this even make sense to have here?
						const link = generatePurchasePageLink(
							Edge.Models.EntityType.Organization,
							currentOrganization.id,
							props.mode === CreditMode.Subscriptions
								? Edge.Models.CheckoutSessionType.AddSeats
								: Edge.Models.CheckoutSessionType.Initial
						);
						return (
							<>
								<div className="assign_seats">
									<div className="assign_seats_header">
										<div className="text_wrapper">
											<h1>
												Assign {props.mode === CreditMode.Subscriptions ? 'Seats' : 'Sessions'}
											</h1>
											{props.renderHeader()}
											{props.renderError()}
										</div>
										<div className="action_wrapper">
											<Link to={link} className="cta_btn">
												Add {props.mode === CreditMode.Subscriptions ? 'Seats' : 'Sessions'}
											</Link>
										</div>
									</div>
									<table>
										<tbody>
											{teams.map((t, i) => {
												return (
													<tr key={i}>
														<td>
															<Avatar size="small" src={t.logoUrl} />
															<span className="name">{t.name}</span>
														</td>
														<td>{props.renderItem(t.id)}</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</>
						);
					}}
				/>
			</>
		);
	}
}

function mapStateToProps(state: AppState) {
	const { organizationState, teamState } = state;
	const { currentOrganization } = organizationState;
	const teamsSelectorResult = getAllTeams(state);

	return {
		isLoading: organizationState.isLoading || teamState.isLoading,
		loadingError: organizationState.loadingError || teamState.loadingError,
		currentOrganization: currentOrganization!,
		teams: teamsSelectorResult && teamsSelectorResult.teams!,
	};
}

export default connect(mapStateToProps)(
	errorLoadingWrapperHOC(
		OrganizationAssignSeats,
		(props) => !!props.currentOrganization && !!props.teams,
		undefined,
		undefined,
		{ loadingOptions: { blockItem: true } }
	)
);
