import * as React from 'react';
import classNames from 'classnames';

import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import * as Edge from '../../../core';
import {
	getDeviceProfiles,
	setCurrentDeviceProfile,
	createDeviceProfile,
	updateDeviceProfile,
} from '../../../store/deviceProfile/actions';
import { ModalContainer } from '../../../components/global/modal';
import EditDeviceProfile from './edit';
import Table from '../../../components/table';

import './index.scss';

export interface DeviceProfileListProps {
	onClose?: () => void;

	userId?: string;
	isLoading: boolean;
	getDeviceProfiles: () => void;
	profiles?: Edge.Models.DeviceProfile[];
	currentProfile?: Edge.Models.DeviceProfile;
	setCurrentDeviceProfile: (profile: Edge.Models.DeviceProfile) => void;
	createDeviceProfile: (profile: Edge.Models.DeviceProfile) => Promise<Edge.Models.DeviceProfile>;
	updateDeviceProfile: (profile: Edge.Models.DeviceProfile) => Promise<Edge.Models.DeviceProfile>;
}

interface DeviceProfileListState {
	editing?: Partial<Edge.Models.DeviceProfile>;
}

class DeviceProfileTable extends Table<Edge.Models.DeviceProfile> {}

export class DeviceProfileList extends React.PureComponent<DeviceProfileListProps, DeviceProfileListState> {
	constructor(props: DeviceProfileListProps) {
		super(props);
		this.state = {};
	}

	public render() {
		const { profiles, currentProfile, onClose, userId } = this.props;
		const { editing } = this.state;

		if (!profiles) {
			return null;
		}

		return (
			<div className="deviceprofiles_list">
				{editing ? (
					<ModalContainer onClose={this.closeEdit} open={true} title="Calibrate Your Device">
						<EditDeviceProfile profile={editing} onSubmit={this.onSubmitEdit} />
					</ModalContainer>
				) : (
					<ModalContainer onClose={onClose && this.props.onClose} open={true} title="Your Devices">
						<button className="cta_btn" onClick={this.newProfile}>
							Create New Profile
						</button>
						<DeviceProfileTable
							items={profiles}
							renderTableHeader={() => (
								<tr>
									<th>Name</th>
									<th />
								</tr>
							)}
							renderNoItems={() => (
								<tr>
									<td colSpan={2}>No profiles currently exist</td>
								</tr>
							)}
							renderTableItem={(item) => (
								<tr
									className={classNames({ isSelected: item === currentProfile })}
									onClick={this.setCurrentProfile.bind(this, item)}
								>
									<td>{item.name}</td>
									<td>
										{userId === item.userId && (
											<button className="cta_btn" onClick={this.editProfile.bind(this, item)}>
												Edit
											</button>
										)}
									</td>
								</tr>
							)}
							pagingMode="none"
						/>
					</ModalContainer>
				)}
			</div>
		);
	}

	private onClose = () => {
		const { onClose } = this.props;
		if (onClose) {
			onClose();
		}
	};

	private closeEdit = () => {
		this.setState({ editing: undefined });
	};

	private onSubmitEdit = async (profile: Edge.Models.DeviceProfile) => {
		const { editing } = this.state;
		const { setCurrentDeviceProfile, updateDeviceProfile, createDeviceProfile } = this.props;
		const newProfile =
			editing && editing.id ? await updateDeviceProfile(profile) : await createDeviceProfile(profile);
		setCurrentDeviceProfile(newProfile);
		this.onClose();
		this.setState({ editing: undefined });
	};

	private newProfile = () => {
		this.setState({ editing: { renderScale: 1 } });
	};

	private editProfile = (profile: Edge.Models.DeviceProfile, e: React.MouseEvent) => {
		this.setState({ editing: profile });
		e.preventDefault();
	};

	private setCurrentProfile = (profile: Edge.Models.DeviceProfile, e: React.MouseEvent) => {
		if (e.defaultPrevented) {
			return;
		}
		this.props.setCurrentDeviceProfile(profile);
		this.onClose();
	};
}

function mapStateToProps({ deviceProfileState, loginState }: AppState) {
	const userId = loginState.decodedToken && loginState.decodedToken.sub;
	return { ...deviceProfileState, userId };
}

export default connect(
	mapStateToProps,
	{
		getDeviceProfiles,
		setCurrentDeviceProfile,
		updateDeviceProfile,
		createDeviceProfile,
	}
)(
	errorLoadingWrapperHOC(
		DeviceProfileList,
		(props) => !props.isLoading && !!props.profiles,
		() => undefined,
		(props) => {
			if (!props.isLoading && !props.profiles) {
				props.getDeviceProfiles();
			}
		}
	)
);
