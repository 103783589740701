import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { Configuration } from '../core/configuration';
import history from '../services/history';

declare const gtag: (...params: any[]) => void;
const appInsights = Configuration.disableAnalytics
	? undefined
	: new ApplicationInsights({
			config: {
				instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY!,
				disableFetchTracking: false,
				enableCorsCorrelation: true,
			},
	  });

if (appInsights) {
	appInsights.loadAppInsights();

	appInsights.context.application.build = Configuration.version;
	history.listen((location, action) => {
		if (action !== 'PUSH' && action !== 'REPLACE') {
			return;
		}

		// slight timeout to let the navigation happen first
		setTimeout(() => {
			gtag('event', 'page_view');
			appInsights.trackPageView({});
		}, 100);
	});
}

export default class AnalyticsService {
	public static login(username: string) {
		gtag('event', 'login');
		if (appInsights) {
			appInsights.setAuthenticatedUserContext(username);
		}
	}
	public static logout() {
		if (appInsights) {
			appInsights.clearAuthenticatedUserContext();
		}
	}
	public static event(name: string, props?: any) {
		gtag('event', name, props);
		if (appInsights) {
			appInsights.trackEvent({ name }, props);
		}
	}
	public static exception(error: Error) {
		if (appInsights) {
			appInsights.trackException({ error });
		}
	}
}
