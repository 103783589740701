import * as axios from 'axios';
import { AxiosRequestConfig } from 'axios';
import * as _ from 'lodash';

import { AuthenticationService } from './../services/authenticationService';
import { Configuration } from './configuration';
import * as Constants from './constants';
import history from '../services/history';
import { store } from '../store';
import { logout } from '../store/login/actions';
import AnalyticsService from '../services/analyticsService';
import * as Edge from '../core';

class ServerRoute {
	private constructor(controller: string, action?: string) {
		this.controller = controller;
		this.action = action;

		if (!this.controller) {
			throw new Error(`Expected controller argument to be defined.`);
		}
	}

	public static forAction(controller: string, action?: string): ServerRoute {
		const result: ServerRoute = new ServerRoute(controller, action);

		result.fullUrl = '/' + controller + (action ? '/' + action : '');

		return result;
	}

	private readonly controller: string;
	private readonly action?: string;
	private fullUrl?: string;

	public url(): string {
		return this.fullUrl || Constants.EMPTY_STRING;
	}
}

const instance = axios.default;
instance.interceptors.request.use((config: RequestConfig) => {
	const token: string = AuthenticationService.getToken() || '';
	const defaultHeaders: any = {
		'Content-Type': 'application/json',
	};
	if (config.authTokenOverride) {
		defaultHeaders['Authorization'] = `Bearer ${config.authTokenOverride}`;
	} else if (token && config.sendAuthentication !== false) {
		defaultHeaders['Authorization'] = `Bearer ${token}`;
	}

	return Object.assign({}, config, {
		headers: Object.assign({}, defaultHeaders, config.headers || {}),
	});
});

instance.interceptors.response.use(undefined, async (result) => {
	const { response, config } = result;

	if (config && config.globalErrorHandling === false) {
		throw result;
	}

	AnalyticsService.exception(result);

	if (response && response.status === 401) {
		if (history.location.pathname !== '/login') {
			await store.dispatch(logout() as any);
		}
	}

	throw result;
});

interface RequestConfig extends AxiosRequestConfig {
	sendAuthentication?: boolean;
	authTokenOverride?: string;
	globalErrorHandling?: boolean;
}

async function get<TResult>(routeData: ServerRoute, queryString?: string, config?: RequestConfig): Promise<TResult> {
	return (await instance.get(makeUrl(routeData, queryString), config)).data;
}

async function getFile(
	routeData: ServerRoute,
	queryString?: string,
	configOrUndefined?: RequestConfig
): Promise<Edge.Models.File> {
	const config = Object.assign({ responseType: 'blob' }, configOrUndefined || {});
	const response = await instance.get(makeUrl(routeData, queryString), config);
	const blob = response.data as Blob;
	const contentDisposition: string = response.headers['content-disposition'];
	if (!contentDisposition) {
		console.warn("Wasn't able to get Content-Disposition header - maybe an issue with CORS?");
	}
	// https://stackoverflow.com/a/23054920
	const match = contentDisposition && contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
	const filename = match ? match[1] : undefined;
	return { blob, filename };
}

async function getWithResponseDate<TResult>(
	routeData: ServerRoute,
	queryString?: string,
	config?: RequestConfig
): Promise<[TResult, Date]> {
	const response = await instance.get(makeUrl(routeData, queryString), config);
	const data = response.data as TResult;
	if (!response.headers.date) {
		console.warn("Wasn't able to get Date header - maybe an issue with CORS?");
	}
	return [data, (response.headers.date && new Date(response.headers.date)) || new Date()];
}

async function getAnonymous<TResult>(
	routeData: ServerRoute,
	queryString?: string,
	config?: RequestConfig
): Promise<TResult> {
	return (await instance.get(makeUrl(routeData, queryString), Object.assign({}, config || {}, {
		sendAuthentication: false,
	}) as any)).data;
}

async function post<TResult>(routeData: ServerRoute, payload: any, config?: RequestConfig): Promise<TResult> {
	return (await instance.post(makeUrl(routeData), payload, config)).data;
}

async function postWithResponseDate<TResult>(
	routeData: ServerRoute,
	payload: any,
	config?: RequestConfig
): Promise<[TResult, Date]> {
	const response = await instance.post(makeUrl(routeData), payload, config);
	const data = response.data as TResult;
	const date = response.headers.Date || response.headers.date;
	if (!date) {
		console.warn("Wasn't able to get Date header - maybe an issue with CORS?");
	}
	return [data, date || new Date()];
}

async function postAnonymous<TResult>(routeData: ServerRoute, payload: any, config?: RequestConfig): Promise<TResult> {
	return (await instance.post(makeUrl(routeData), payload, Object.assign({}, config || {}, {
		sendAuthentication: false,
	}) as any)).data;
}

async function patch<TResult>(routeData: ServerRoute, payload: any, config?: RequestConfig): Promise<TResult> {
	return (await instance.patch(makeUrl(routeData), payload, config)).data;
}

async function put<TResult>(routeData: ServerRoute, payload: any, config?: RequestConfig): Promise<TResult> {
	return (await instance.put(makeUrl(routeData), payload, config)).data;
}

async function patchWithResponseDate<TResult>(
	routeData: ServerRoute,
	payload: any,
	config?: RequestConfig
): Promise<[TResult, Date]> {
	const response = await instance.patch(makeUrl(routeData), payload, config);
	const data = response.data as TResult;
	const date = response.headers.Date || response.headers.date;
	if (!date) {
		console.warn("Wasn't able to get Date header - maybe an issue with CORS?");
	}
	return [data, date || new Date()];
}

async function deleteMethod<TResult>(routeData: ServerRoute, config?: RequestConfig): Promise<TResult> {
	return (await instance.delete(makeUrl(routeData), config)).data;
}

function getErrorMessage(e: Edge.Models.EdgeError) {
	if (
		e &&
		e.response &&
		(e.response.status === 400 || e.response.status === 404 || e.response.status === 409) &&
		e.response.data
	) {
		if (typeof e.response.data === 'string') {
			return e.response.data;
		}
		const { errors, title } = e.response.data;
		if (errors) {
			const message = _.values(errors).join(', ');
			if (message) {
				return message;
			}
		}
		return title;
	}
	return e && e.message;
}

function getStatus(e: Edge.Models.EdgeError) {
	if (e && e.response && e.response.status) {
		return e.response.status;
	}
	return undefined;
}

function makeUrl(routeData: ServerRoute, queryString?: string | null) {
	const baseUrl: string = Configuration.api;
	let url: string = `${baseUrl}api${routeData.url()}`;

	if (queryString && queryString.length > 0) {
		url += `?${queryString}`;
	}

	return url;
}

export default {
	ServerRoute,
	get,
	getFile,
	getWithResponseDate,
	getAnonymous,
	post,
	postWithResponseDate,
	postAnonymous,
	patch,
	patchWithResponseDate,
	put,
	delete: deleteMethod,
	axios: instance,
	getErrorMessage,
	getStatus,
};
