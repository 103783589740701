import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { Navigation } from './navigation';
import * as Edge from '../../../core';

// Icon Imports
import { ReactComponent as HouseIcon } from '../../../assets/images/icons/House.svg';
import { ReactComponent as TargetIcon } from '../../../assets/images/icons/Target.svg';
import { ReactComponent as BriefcaseIcon } from '../../../assets/images/icons/Briefcase.svg';
import { ReactComponent as NotebookIcon } from '../../../assets/images/icons/Notebook.svg';
import { ReactComponent as GearIcon } from '../../../assets/images/icons/Gear.svg';
import { ReactComponent as StatsIcon } from '../../../assets/images/icons/Stats.svg';
import { ReactComponent as UserIcon } from '../../../assets/images/icons/User.svg';
import { mergePermissions } from '../../../store/permissions/reducers';
import { PermissionLevels } from '../../../core/models';
import { UserPermissionService } from '../../../services/userPermissionService';
import { getUserPermissionService } from '../../../store/permissions/selector';
import TeamTypeService from '../../../services/teamTypeService';

export interface NavigationProps {
	navigationItems: NavigationItem[];
}

export interface NavigationItem {
	route?: string;
	link?: string;
	newWindow?: boolean;
	text: string;
	image?: any;
}

const mapStateToProps = (state: AppState) => {
	return {
		navigationItems: getNavigationItems(state, getUserPermissionService(state)),
	};
};

const getNavigationItems: (state: AppState, permissions: UserPermissionService) => NavigationItem[] = (
	{ organizationState, teamState, loginState },
	permissions
) => {
	const { currentOrganization } = organizationState;
	if (!permissions.ready() || !currentOrganization || !teamState.teams) {
		return [];
	}

	// Teams
	const teams = teamState.teams!.filter((i) => currentOrganization && i.organizationId === currentOrganization.id);
	const nonProspectTeams = teams.filter((i) => i.type && !TeamTypeService.isProspectOnlyTeam(i.type));

	// User permissions
	const orgPermission = permissions.getOrganizationRole(currentOrganization.id);
	const bestTeamPermission = teams.map(permissions.getTeamRole).reduce(mergePermissions, undefined);
	const bestNonProspectTeamPermission = nonProspectTeams
		.map(permissions.getTeamRole)
		.reduce(mergePermissions, undefined);

	// User flags
	const isAdmin = loginState.decodedToken && loginState.decodedToken.is_admin;
	const isDistributor = loginState.decodedToken && loginState.decodedToken.is_distributor;

	const isNormalUser =
		(bestTeamPermission && bestTeamPermission.isNormalUser) || (orgPermission && orgPermission.isNormalUser);

	const canTrain =
		(bestNonProspectTeamPermission && bestNonProspectTeamPermission.canEvaluate) ||
		(orgPermission && orgPermission.canEvaluate && nonProspectTeams.length > 0);

	const canCreateTeam = orgPermission && orgPermission.organizationPermissions >= PermissionLevels.Edit;
	const canViewTeamSettings = bestTeamPermission && bestTeamPermission.teamPermissions >= PermissionLevels.Details;
	const canViewOrganizationSettings =
		orgPermission && orgPermission.organizationPermissions >= PermissionLevels.Details;

	// #1421: https://dev.azure.com/vizualedge/Edge%20Trainer/_backlogs/backlog/Edge%20Trainer%20Team/Stories/?workitem=1421
	// TODO: add new icons
	if (isNormalUser) {
		return [
			{ route: '/dashboard', text: 'Dashboard', image: HouseIcon },
			canTrain && { route: '/training', text: 'Training', image: TargetIcon },
			(canViewTeamSettings || canCreateTeam || canTrain) && { route: '/stats', text: 'Stats', image: StatsIcon },
			{ link: Edge.Constants.URL_RESOURCES, newWindow: true, text: 'Resources', image: NotebookIcon },
			(canViewTeamSettings || canCreateTeam) && {
				route: '/manage-teams',
				text: 'Team Settings',
				image: BriefcaseIcon,
			},
			canViewOrganizationSettings && {
				route: '/manage-organization',
				text: 'Organization Settings',
				image: GearIcon,
			},
			(isAdmin || isDistributor) && { route: '/organizations', text: 'All Organizations', image: UserIcon },
			isAdmin && { route: '/user-roles', text: 'All User Roles', image: UserIcon },
			isAdmin && { route: '/order-history', text: 'Order History', image: UserIcon },
			isAdmin && { route: '/admin_edge_scores', text: 'All Evaluations', image: UserIcon },
			isAdmin && { route: '/admin', text: 'Admin', image: UserIcon },
		].filter((i) => i) as NavigationItem[];
	}

	const canEvaluate =
		(bestTeamPermission && bestTeamPermission.canEvaluate) ||
		(orgPermission && orgPermission.canEvaluate && teams.length > 0);

	return [canEvaluate && { route: '/training/evaluation', text: 'Evaluation', image: TargetIcon }].filter(
		(i) => i
	) as NavigationItem[];
};

export default connect(
	mapStateToProps,
	{}
)(Navigation);
