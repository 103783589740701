import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Edge from '../../../core';
import FormField from '../../../components/formField';
import './index.scss';
import Error from '../../../components/global/error';

export interface EmailTeamProps {
	initialValues?: EmailTeamValues;
	emailTeam: (message: string) => Promise<void>;
}

export interface EmailTeamValues {
	message: string;
}

const emailSchema = Yup.object({
	message: Yup.string().required('Message is required'),
});

export default class EmailTeam extends React.PureComponent<EmailTeamProps> {
	public render(): JSX.Element {
		const { initialValues, emailTeam } = this.props;
		return (
			<div>
				<Formik
					initialValues={Object.assign(
						{},
						{
							message: '',
						},
						initialValues || {}
					)}
					validationSchema={emailSchema}
					validateOnChange={false}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await emailTeam(values.message);
						} catch (e) {
							actions.setStatus(Edge.API.getErrorMessage(e));
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<EmailTeamValues>) => {
						return (
							<Form>
								<FormField
									name="message"
									component="textarea"
									label="Enter message"
									placeholder="Enter message"
								/>
								{props.status && <Error>{props.status}</Error>}
								<button type="submit" disabled={props.isSubmitting}>
									Send Message
								</button>
							</Form>
						);
					}}
				/>
			</div>
		);
	}
}
