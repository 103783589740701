import * as ReduxThunk from 'redux-thunk';
import * as Edge from './../../core';
import { AppState } from './..';
import { DeviceProfileService } from '../../services/deviceProfileService';
import {
	DEVICEPROFILE_GET_START,
	DEVICEPROFILE_GET_SUCCESS,
	DEVICEPROFILE_GET_FAILURE,
	DEVICEPROFILE_SET,
} from './types';

// action creators
const deviceProfileGetStart = () => {
	return {
		type: DEVICEPROFILE_GET_START,
	};
};

export const deviceProfileGetSuccess = (
	deviceProfiles: Edge.Models.DeviceProfile[],
	currentProfile?: Edge.Models.DeviceProfile
) => {
	return {
		type: DEVICEPROFILE_GET_SUCCESS,
		payload: deviceProfiles,
		currentProfile,
	};
};

export const deviceProfileGetFailure = (error: Edge.Models.EdgeError) => {
	return {
		type: DEVICEPROFILE_GET_FAILURE,
		payload: error,
		error: true,
	};
};

export const deviceProfileSet = (currentProfile: Edge.Models.DeviceProfile) => {
	return {
		type: DEVICEPROFILE_SET,
		payload: currentProfile,
	};
};

function getDefaultCurrentDeviceProfileKey({ loginState }: AppState) {
	return loginState.decodedToken && `DEVICE_PROFILE_DEFAULT_${loginState.decodedToken.sub}`;
}

function getDefaultCurrentDeviceProfile(state: AppState, profiles: Edge.Models.DeviceProfile[]) {
	const key = getDefaultCurrentDeviceProfileKey(state);
	if (key) {
		const id = localStorage.getItem(key);
		if (id) {
			return profiles.filter((i) => i.id === id)[0] || profiles[0];
		}
	}
	return profiles[0];
}

export const mergeDeviceProfileIntoExisting = (
	newProfile: Edge.Models.DeviceProfile
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch, getState) => {
	const { profiles, currentProfile } = getState().deviceProfileState;
	if (profiles) {
		const newProfiles = profiles.filter((i) => i.id !== newProfile.id).concat([newProfile]);
		const newCurrentProfile = currentProfile && currentProfile.id === newProfile.id ? newProfile : currentProfile;
		dispatch(deviceProfileGetSuccess(newProfiles, newCurrentProfile));
	}
};

// thunk actions
export const getDeviceProfiles = (): ReduxThunk.ThunkAction<
	Promise<void>,
	AppState,
	null,
	Edge.Models.EdgeAction
> => async (dispatch, getState) => {
	dispatch(deviceProfileGetStart());

	try {
		const profiles = await DeviceProfileService.getProfiles();
		dispatch(deviceProfileGetSuccess(profiles, getDefaultCurrentDeviceProfile(getState(), profiles)));
	} catch (error) {
		dispatch(deviceProfileGetFailure(error));
	}
};

export const setCurrentDeviceProfile = (
	profile: Edge.Models.DeviceProfile
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch, getState) => {
	dispatch(deviceProfileSet(profile));
	const key = getDefaultCurrentDeviceProfileKey(getState());
	if (key) {
		localStorage.setItem(key, profile.id);
	}
};

export const updateDeviceProfile = (
	profile: Edge.Models.DeviceProfile
): ReduxThunk.ThunkAction<Promise<Edge.Models.DeviceProfile>, AppState, null, Edge.Models.EdgeAction> => async (
	dispatch
) => {
	const newProfile = await DeviceProfileService.updateProfile(profile);
	await dispatch(mergeDeviceProfileIntoExisting(newProfile));
	return newProfile;
};

export const createDeviceProfile = (
	profile: Edge.Models.DeviceProfile
): ReduxThunk.ThunkAction<Promise<Edge.Models.DeviceProfile>, AppState, null, Edge.Models.EdgeAction> => async (
	dispatch
) => {
	const newProfile = await DeviceProfileService.createProfile(profile);
	await dispatch(mergeDeviceProfileIntoExisting(newProfile));
	return newProfile;
};
