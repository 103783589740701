import * as React from 'react';
import PageTitle from '../pageTitle';
import ContactSupportLink from '../contactSupport';

export interface ErrorDisplayProps {
	title?: string;
	message?: string;
}

export default class ErrorDisplay extends React.PureComponent<ErrorDisplayProps> {
	public render() {
		const { title, message } = this.props;
		return (
			<div className="error">
				<PageTitle title={title || 'An error has occurred'} />
				{message && <p>{message}</p>}
				<p>
					Please try again or <ContactSupportLink />.
				</p>
			</div>
		);
	}
}
