import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import * as Edge from '../../core';
import PageTitle from '../../components/pageTitle';
import { AppState } from '../../store';
import { UserPermissionService } from '../../services/userPermissionService';
import { getTeamsUserCanEdit, getUserPermissionService, getTeamsUserCanTrain } from '../../store/permissions/selector';
import { setCurrentTeam } from '../../store/team/actions';
import CoachStats from './coach';
import ErrorDisplay from '../../components/error/errorDisplay';
import Loading from '../../components/loading';
import AppPageContainer from '../../components/pageContainer';
import AccessDenied from '../../components/global/accessDenied';
import { TeamService } from '../../services/teamService';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { PermissionLevels } from '../../core/models';

export interface StatsProps {
	userId: string;
	permissions: UserPermissionService;
	currentOrganization?: Edge.Models.Organization;
	editableTeams?: Edge.Models.Team[];
	trainableTeams?: Edge.Models.Team[];
	currentTeam?: Edge.Models.Team;
	teamMembers?: Edge.Models.TeamMember[];
	teamAccess: (team: Edge.Models.Team) => Edge.Models.PermissionLevel;
	setCurrentTeam: (team: Edge.Models.Team) => void;
	isLoading?: boolean;
	loadingError?: Edge.Models.EdgeError;
}

export class Stats extends React.Component<StatsProps> {
	public render(): JSX.Element {
		if (this.props.loadingError) {
			return <ErrorDisplay />;
		}

		return (
			<div>
				<PageTitle title="Stats" />
				<AppPageContainer>{!this.props.isLoading && this.getStats()}</AppPageContainer>
			</div>
		);
	}

	private getStats = () => {
		const { userId, currentOrganization, editableTeams, trainableTeams, permissions } = this.props;

		if (!currentOrganization || !editableTeams || !trainableTeams) {
			return <Loading />;
		}

		const orgAccess = permissions.getOrganizationAccess(currentOrganization.id);
		if (editableTeams.length > 0 || orgAccess >= Edge.Models.PermissionLevels.Details) {
			return <CoachStats teams={editableTeams} />;
		}

		if (trainableTeams.length > 0) {
			const currentTeam = trainableTeams[0];
			if (!this.props.currentTeam) {
				this.props.setCurrentTeam(currentTeam);
			}

			const teamId = this.props.currentTeam ? this.props.currentTeam.id : currentTeam.id;
			return <Redirect to={`/stats/${userId}/${teamId}`} />;
		}

		// the user doesn't have org admin rights, and doens't have any trainable or editable teams - ie. this is a scout
		return <AccessDenied />;
	};
}

function mapStateToProps(state: AppState) {
	const { currentOrganization } = state.organizationState;
	const { teams: editableTeams } = getTeamsUserCanEdit(state);
	const { teams: trainableTeams } = getTeamsUserCanTrain(state);
	const currentTeam = state.teamState.currentTeam;
	const permissions = getUserPermissionService(state);
	const userId = state.loginState.decodedToken && state.loginState.decodedToken.sub;
	const teamAccess = permissions.getTeamAccess;

	return {
		userId: userId!,
		isLoading: state.teamState.isLoading || state.organizationState.isLoading || permissions.isLoading(),
		loadingError: state.teamState.loadingError || state.organizationState.loadingError,
		currentOrganization: currentOrganization,
		editableTeams,
		trainableTeams,
		currentTeam,
		permissions,
		teamAccess,
	};
}

export default connect(
	mapStateToProps,
	{
		setCurrentTeam,
	}
)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(Stats, undefined, undefined, undefined, {
			loadingOptions: { blockItem: true },
		}),
		(props: StatsProps) => props,
		async (props) => {
			if (props.currentTeam) {
				// Permissions check that mirrors check in endpoint /teams/{teamId}/users
				if (props.teamAccess(props.currentTeam) >= PermissionLevels.Details) {
					const teamMembers = props.currentTeam && (await TeamService.getMembers(props.currentTeam.id));
					return { teamMembers };
				}
			}

			return props;
		}
	)
);
