import * as React from 'react';
import * as _ from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';

import * as Edge from '../../../core';
import CollapsibleTable from '../../../components/collapsibleTable';
import { AlignmentHelper } from '../../exercises/align/alignHelper';
import { ExerciseMetadata, ExerciseMetadataService } from '../../../services/exerciseMetadataService';

import './trainingTable.scss';

export interface AthleteStatsTrainingTableProps {
	sessions: Edge.Models.BaseSessionResponse[];
}

export type ExerciseResultWithSessionAndMetadata = Edge.Models.ExerciseResultWithSession & {
	metadata?: ExerciseMetadata;
};

class ExerciseResultTable extends CollapsibleTable<ExerciseResultWithSessionAndMetadata, string> {}

function alignment(value: number) {
	return AlignmentHelper.getScoreText({ horizontalAlignment: value });
}

function groupKey(item: ExerciseResultWithSessionAndMetadata) {
	return (item.metadata && item.metadata.title) || '';
}

function groupSort(groupKey: string) {
	return groupKey;
}

function formatValue<T>(value: T | undefined | null, transform?: (value: T) => React.ReactNode): React.ReactNode {
	if (value === null || value === undefined) return <>-</>;
	else return transform ? transform(value) : value;
}

function sort(item: ExerciseResultWithSessionAndMetadata) {
	return item.session.expiresUtc;
}

function transformPercent(value: number) {
	return (value * 100).toFixed(1);
}

function transformSeconds(value: number) {
	// we store '0' for the exercise types that don't have a value
	if (value === 0) return <>-</>;
	else return (value / 1000).toFixed(2) + 's';
}

const withSessionsAndMetadata = createSelector<
	Edge.Models.BaseSessionResponse[],
	Edge.Models.BaseSessionResponse[],
	ExerciseResultWithSessionAndMetadata[]
>(
	(i) => i,
	(sessions) => {
		return _.flatMap(sessions, (s) =>
			s.exerciseResults.map((i) => {
				const metadata = ExerciseMetadataService.getMetadata(i);
				return { ...i, session: s, metadata };
			})
		);
	}
);

const AthleteStatsTrainingTable: React.FunctionComponent<AthleteStatsTrainingTableProps> = (props) => {
	const items = withSessionsAndMetadata(props.sessions);

	return (
		<ExerciseResultTable
			className="athlete_stats_training_table"
			sort={sort}
			sortDirection={'desc'}
			groupKey={groupKey}
			groupSort={groupSort}
			items={items}
			renderTableHeader={({ collapseColumn }) => {
				return (
					<tr>
						{collapseColumn()}
						<th>Exercise</th>
						<th>Percent</th>
						<th>Station/&#8203;Time</th>
						<th>Settings</th>
						<th>Date</th>
					</tr>
				);
			}}
			renderNoItems={() => 'No exercises'}
			renderTableItem={(item, { collapseColumn, toggleCallback, isFirstRow }) => {
				return (
					<tr onClick={isFirstRow ? toggleCallback : undefined}>
						{collapseColumn()}
						<td>{isFirstRow && item.metadata && item.metadata.title}</td>
						<td>
							{item.exerciseTypeId === Edge.Models.ExerciseTypeId.Alignment
								? formatValue(item.horizontalAlignment, alignment)
								: formatValue(item.correctPercent, transformPercent)}
						</td>
						<td>
							{/*
								// Tag: New Exercise
								// Add condition for new exercise if a different `Station/Time` value is needed.
							*/}
							{(item.exerciseTypeId === Edge.Models.ExerciseTypeId.ContrastSensitivity ||
								item.exerciseTypeId === Edge.Models.ExerciseTypeId.ContrastTracking) &&
								`${formatValue(item.stationMax)}/`}
							{item.exerciseTypeId === Edge.Models.ExerciseTypeId.Alternating
								? `C: ${formatValue(item.convergenceStationScore)} D: ${formatValue(
										item.divergenceStationScore
								  )}`
								: item.exerciseTypeId === Edge.Models.ExerciseTypeId.Convergence
								? formatValue(item.convergenceStationScore)
								: item.exerciseTypeId === Edge.Models.ExerciseTypeId.Divergence
								? formatValue(item.divergenceStationScore)
								: formatValue(item.responseTimeMilliseconds, transformSeconds)}
						</td>
						<td className="athlete_stats_training_table_settings">
							{ExerciseMetadataService.getConfigDescription(item)}
						</td>
						<td>{moment(item.session.expiresUtc).format(Edge.Constants.DATE_FORMAT)}</td>
					</tr>
				);
			}}
		/>
	);
};
export default AthleteStatsTrainingTable;
