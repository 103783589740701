import * as React from 'react';
import ScrollToTopOnMount from '../scrollToTopOnMount';

export class AccessDenied extends React.PureComponent {
	public render() {
		return (
			<>
				<ScrollToTopOnMount />
				<h2>Access denied</h2>
			</>
		);
	}
}
export default AccessDenied;
