// Constants
const UnscaledBarHeight = 40;
const BarSpacing = 8;
const UnscaledBarWidth = 14;
const MaxTimeBars = 60; // 60 is a good number because it's a multiple of seconds (e.g., 1 min is one bar per second)
const YOffset = 50;

export default class Timer {
	public barHeight: number;
	public totalBarWidth: number;
	public posXLeft: number;
	public posYTop: number;

	private ctx: CanvasRenderingContext2D;
	private renderScale: number;
	private sessionLength: number;
	private timeRemaining: number;

	private barWithSpaceWidth: number;
	private barWidth: number;

	constructor(ctx: CanvasRenderingContext2D, sessionLength: number, renderScale: number) {
		this.ctx = ctx;
		this.renderScale = renderScale;
		this.timeRemaining = sessionLength;
		this.sessionLength = sessionLength;

		const viewBounds = {
			height: this.ctx.canvas.height,
			width: this.ctx.canvas.width,
		};

		const barSpacing = BarSpacing * this.renderScale;
		this.barWidth = UnscaledBarWidth * this.renderScale;
		this.barHeight = UnscaledBarHeight * this.renderScale;

		this.barWithSpaceWidth = this.barWidth + barSpacing;
		this.totalBarWidth = this.barWithSpaceWidth * MaxTimeBars;

		this.posXLeft = (viewBounds.width - this.totalBarWidth) / 2;
		this.posYTop = viewBounds.height - this.barHeight - YOffset;
	}

	public draw = (): void => {
		const actualTimerBars = (this.timeRemaining / this.sessionLength) * MaxTimeBars;

		this.ctx.save();

		for (let i = 0; i < actualTimerBars; i++) {
			const posX = this.barWithSpaceWidth * i + this.posXLeft;

			// Turns last 6 bars red.
			this.ctx.fillStyle = i > 5 ? 'green' : 'red';
			this.ctx.fillRect(posX, this.posYTop, this.barWidth, this.barHeight);
		}

		this.ctx.restore();
	};

	public elapseTime = (elapsed: number): void => {
		this.timeRemaining -= elapsed;
	};
}
