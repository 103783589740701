import * as React from 'react';

// based on https://reacttraining.com/react-router/web/guides/scroll-restoration
/**
 * Will scroll to the top when this component is mounted.  Does not render it's children.
 */
export default class ScrollToTopOnMount extends React.Component {
	public componentDidMount() {
		window.scrollTo(0, 0);
	}
	public render() {
		return null;
	}
}
