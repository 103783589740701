import * as React from 'react';

import FormField from '../formField';

const TermsFormFields: React.FunctionComponent<{}> = (props) => (
	<>
		<FormField
			type="checkbox"
			name="wasAtLeast13YearsOldAtRegistration"
			description="I am at least 13 years old."
		/>
		<FormField
			type="checkbox"
			name="agreedToCookiesAtRegistration"
			description="I consent to the collection of cookies."
		/>
		<FormField
			type="checkbox"
			name="agreedToLicenseAgreementAtRegistration"
			description="I consent to the End User License Agreement."
			label={
				<>
					I consent to the{' '}
					<a
						href="https://vizualedge.com/privacy-policy"
						target="_blank"
						rel="noopener noreferrer"
						className="eula_link"
					>
						End User License Agreement
					</a>
					.
				</>
			}
		/>
	</>
);
export default TermsFormFields;
