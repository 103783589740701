import * as React from 'react';
import classNames from 'classnames';

import './index.scss';

const Error: React.FunctionComponent = (props) => {
	return <div className={classNames('error-component')}>{props.children}</div>;
};

export default Error;
