import * as Edge from './../../core';

export interface ApiKeyState {
	apiKeys?: Edge.Models.ApiKey[];
	isLoading: boolean;
	loadingError?: Edge.Models.EdgeError;
}

export const APIKEYS_LOADING = 'KEYS_LOADING';
export const APIKEYS_SUCCESS = 'KEYS_SUCCESS';
export const APIKEYS_FAILURE = 'KEYS_FAILURE';
export const UPDATE_APIKEY = 'UPDATE_KEY';

interface ApiKeysLoading extends Edge.Models.EdgeAction<undefined, typeof APIKEYS_LOADING> {
	type: typeof APIKEYS_LOADING;
}

interface ApiKeysSuccess extends Edge.Models.EdgeAction<Edge.Models.ApiKey[], typeof APIKEYS_SUCCESS> {
	type: typeof APIKEYS_SUCCESS;
	payload: Edge.Models.ApiKey[];
}

interface ApiKeysFailure extends Edge.Models.EdgeAction<Edge.Models.EdgeError, typeof APIKEYS_FAILURE> {
	type: typeof APIKEYS_FAILURE;
	payload: Edge.Models.EdgeError;
}

interface UpdateApiKeys extends Edge.Models.EdgeAction<ApiKeyUpdateType, typeof UPDATE_APIKEY> {
	type: typeof UPDATE_APIKEY;
	payload: ApiKeyUpdateType;
}

export type ApiKeyActionTypes = ApiKeysLoading | ApiKeysSuccess | ApiKeysFailure | UpdateApiKeys;

// Creates a type that allows all undefined properties to be undefined
export type ApiKeyUpdateType = Pick<Partial<Edge.Models.ApiKey>, Exclude<keyof Edge.Models.ApiKey, 'id'>> &
	Pick<Edge.Models.ApiKey, 'id'>;
