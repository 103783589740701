import * as React from 'react';
import { connect } from 'react-redux';
import * as Edge from '../../../core';
import { AppState } from '../../../store';
import { endSession } from '../../../store/session/actions';
import ErrorDisplay from '../../../components/error/errorDisplay';
import Loading from '../../../components/loading';
import history from '../../../services/history';
import { getIsNormalUser } from '../../../store/permissions/selector';

export interface CompleteEvaluationProps {
	session?: Edge.Models.BaseSessionResponse;
	endSession: (command: Edge.Models.EndSessionCommand) => Promise<void>;

	isNormalUser: boolean;
}

interface CompleteEvaluationState {
	error?: Edge.Models.EdgeError;
}

export class CompleteEvaluation extends React.PureComponent<CompleteEvaluationProps, CompleteEvaluationState> {
	constructor(props: CompleteEvaluationProps) {
		super(props);
		this.state = {};
	}

	public async componentDidMount() {
		this.endSession();
	}

	public render() {
		const { error } = this.state;
		if (error) {
			return (
				<>
					<ErrorDisplay>{Edge.API.getErrorMessage(error)}</ErrorDisplay>
					<div>
						<button className="cta_btn" onClick={this.endSession}>
							Retry
						</button>
					</div>
				</>
			);
		}

		return <Loading />;
	}

	private endSession = async () => {
		this.setState({ error: undefined });
		try {
			const { session, endSession, isNormalUser } = this.props;
			if (session) {
				await endSession({ sessionId: session.sessionId, abandon: false });

				// the session is now completed, so our control won't get rendered anymore, so we need to redirect manually
				if (isNormalUser) {
					history.push(`/training/evaluation/${session.sessionId}`);
				} else {
					history.push(`/training/evaluation/complete`);
				}
			} else {
				throw new Error('there is no evaluation session to complete');
			}
		} catch (e) {
			this.setState({ error: e });
		}
	};
}

function mapStateToProps(state: AppState) {
	const { sessionState } = state;
	return Object.assign(
		{
			session: sessionState && sessionState.data && sessionState.data.active,
		},
		getIsNormalUser(state)
	);
}

export default connect(
	mapStateToProps,
	{ endSession }
)(CompleteEvaluation);
