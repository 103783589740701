import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../core';
import { AppState } from '../../store';
import { setAvatarUrl } from '../../store/profile/actions';
import { UserService } from '../../services/userService';
import { ChangeImageSection } from '../../components/changeImageSection';
import UserInfoForm from './userInfoForm';
import UserPasswordForm, { UserPasswordFormValues } from './userPasswordForm';
import { refreshToken } from '../../store/login/actions';
import ScrollToTopOnMount from '../../components/global/scrollToTopOnMount';

interface UserInformationProps {
	firstName?: string;
	lastName?: string;
	email?: string;
	avatarUrl?: string;
	username?: string;
	setAvatarUrl: (updatedUrl: string) => Promise<void>;
	refreshToken: () => Promise<void>;
}

interface UserInformationState {}

export class UserInformation extends React.Component<UserInformationProps, UserInformationState> {
	private uploadImage = async (imageData: string): Promise<void> => {
		const url = await UserService.uploadAvatar(imageData);
		await this.props.setAvatarUrl(url);
	};

	public render(): JSX.Element {
		const { firstName, lastName, email, username } = this.props;
		return (
			<div className="sectionMargin">
				<ScrollToTopOnMount />
				<ChangeImageSection src={this.props.avatarUrl} uploadImage={this.uploadImage} />
				<UserInfoForm
					initialValues={{ firstName, lastName, email }}
					username={username}
					onSubmit={this.submitUserDetails}
				/>
				<UserPasswordForm onSubmit={this.submitChangePassword} />
				<div className="contact">
					In order to delete your Vizual Edge account, please reach out to{' '}
					<a href={'mailto:support@vizualedge.com'}>support@vizualedge.com</a> for assistance.
				</div>
			</div>
		);
	}

	private submitUserDetails = async (values: Edge.Models.UserDetailsCommand) => {
		await UserService.updateMyDetails(values);
		await this.props.refreshToken();
	};

	private submitChangePassword = async (values: UserPasswordFormValues) => {
		if (values.newPassword !== values.newPasswordConfirm) {
			throw new Error("New passwords don't match");
		}
		await UserService.changePassword(_.pick(values, ['currentPassword', 'newPassword']));
		this.setState({
			passwordFormKey: Math.random()
				.toString()
				.substr(3),
		});
	};
}

function mapStateToProps({ loginState, profileState }: AppState) {
	const { decodedToken } = loginState;
	return {
		email: decodedToken && decodedToken.email,
		firstName: decodedToken && decodedToken.given_name,
		lastName: decodedToken && decodedToken.family_name,
		avatarUrl: profileState && profileState.profile && profileState.profile.avatarUrl,
		username: decodedToken && decodedToken.unique_name,
	};
}

export default connect(
	mapStateToProps,
	{ setAvatarUrl, refreshToken }
)(UserInformation);
