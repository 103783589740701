import * as React from 'react';
import * as Edge from '../../../core';
import * as Yup from 'yup';
import { Formik, FormikProps, Form } from 'formik';
import FormField from '../../../components/formField';
import Input from '../../../components/global/input';
import Error from '../../../components/global/error';
import Loading from '../../../components/loading';
import Pdf from '../../../assets/Calibration.pdf';

export interface EditDeviceProfileProps {
	profile: Partial<Edge.Models.DeviceProfile>;
	onSubmit: (profile: Edge.Models.DeviceProfile) => Promise<void>;
}

interface EditDeviceProfileState {}

interface EditDeviceProfileValues
	extends Pick<Edge.Models.DeviceProfile, Exclude<keyof Edge.Models.DeviceProfile, 'id'>> {}

const schema = Yup.object().shape({
	name: Yup.string().required('Name is required'),
	touchControls: Yup.boolean().required('Enable touch controls is required'),
	renderScale: Yup.number().required('Render scale is required'),
});

export class EditDeviceProfile extends React.PureComponent<EditDeviceProfileProps, EditDeviceProfileState> {
	constructor(props: EditDeviceProfileProps) {
		super(props);
		this.state = {};
	}
	public render() {
		return (
			<>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							name: '',
							touchControls: false,
							renderScale: 1.0,
						},
						(this.props.profile as EditDeviceProfileValues) || {}
					)}
					validationSchema={schema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await this.onSubmit(values);
						} catch (e) {
							const message = Edge.API.getErrorMessage(e);
							actions.setStatus(message);
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<EditDeviceProfileValues>) => (
						<Form>
							<FormField
								type="text"
								name="name"
								description="Enter Device Name"
								placeholder="Enter Name"
							/>
							<FormField type="checkbox" name="touchControls" description="Enable Touch Controls" />
							<FormField
								component="renderScale"
								name="renderScale"
								description="Adjust Render Scale"
								instructions={
									<p>
										Please adjust the line below until it measures one inch long on your device's
										display surface.
									</p>
								}
							/>
							<p>
								{/* eslint-disable jsx-a11y/anchor-is-valid */}
								<a target="_blank" rel="noopener noreferrer" href={Pdf}>
									Download PDF
								</a>{' '}
								to print with a one inch ruler and instructions.
								{/* eslint-enable jsx-a11y/anchor-is-valid */}
							</p>
							{props.status && <Error>{props.status}</Error>}
							{props.isSubmitting && <Loading buttonLoader />}
							<Input type="submit" disabled={props.isSubmitting} value="submit" />
						</Form>
					)}
				/>
			</>
		);
	}
	private onSubmit = async (values: EditDeviceProfileValues) => {
		const { onSubmit, profile } = this.props;
		return await onSubmit(Object.assign({}, values, { id: profile.id }) as Edge.Models.DeviceProfile);
	};
}
export default EditDeviceProfile;
