import * as React from 'react';
import { Switch, Route } from 'react-router';

import CreateAccount from './createAccount';
import Prompt from './prompt';
import MergeAccount from './mergeAccount';
import DetectAccount from './detectAccount';
import Complete from './complete';

import './index.scss';

export default class RegisterViaInvite extends React.Component {
	public render() {
		return (
			<>
				<Switch>
					<Route path="/invite/prompt/:token" component={Prompt} />
					<Route path="/invite/create/:token" component={CreateAccount} />
					<Route path="/invite/merge/:token" component={MergeAccount} />
					<Route path="/invite/complete/:token/:positionId?" component={Complete} />
					<Route exact path="/invite/:token" component={DetectAccount} />
				</Switch>
			</>
		);
	}
}
