import * as _ from 'lodash';

import * as Edge from '../core';
import { ChartItem } from '../components/rankChart';
import { ExerciseMetadataService } from './exerciseMetadataService';

// Converts Edge Score and exercise percentiles into single-series chart item(s)
export const getSessionChartItems = (session: Edge.Models.BaseSessionResponse): ChartItem[] => {
	return [
		{
			label: 'Edge Score',
			value: session.explanationPercentile || 0,
		},
	].concat(
		_.sortBy(
			_.flatMap(session.exerciseResults.filter((r) => r.complete), (i) => {
				const metadata = ExerciseMetadataService.getMetadata(i);
				if (!metadata) {
					return [];
				}
				return [
					{
						label: metadata.title,
						value: i.explanationPercentile || 0,
						order: metadata.defaultOrder,
					},
				];
			}),
			(i) => i.order
		)
	);
};

// Converts Edge Score and exercise percentiles (including corresponding Pro values) into
//  two-series chart item(s)
export const getSessionWithProChartItems = (
	session: Edge.Models.BaseSessionResponse,
	proPercentiles: Edge.Models.ProPercentile[]
): ChartItem[] => {
	const proEdgeScorePercentile = proPercentiles.find((i) => i.type === Edge.Models.PercentileType.EdgeScore);
	const proExercisePercentiles = proPercentiles.filter((i) => i.type === Edge.Models.PercentileType.Exercise);
	const resultsWithProCompare: Edge.Models.ResultWithProCompare[] = _.chain(
		session.exerciseResults.filter((r) => r.complete)
	)
		.keyBy('id')
		.merge(_.keyBy(proExercisePercentiles, 'id'))
		.values()
		.value();

	return [
		{
			label: 'Edge Score',
			value: session.explanationPercentile || 0,
			value2: proEdgeScorePercentile ? proEdgeScorePercentile!.percentilePro : 0,
		},
	].concat(
		_.sortBy(
			_.flatMap(resultsWithProCompare, (i) => {
				const metadata = ExerciseMetadataService.getMetadata(i);
				if (!metadata) {
					return [];
				}
				return [
					{
						label: metadata.title,
						value: i.explanationPercentile || 0,
						value2: i.percentilePro, // is undefined if data not available
						order: metadata.defaultOrder,
					},
				];
			}),
			(i) => i.order
		)
	);
};
