import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';

export interface LayoutRouteProps extends RouteProps {
	component: React.ComponentType<any>;
	layout: React.ComponentType<any>;
}

export const LayoutRoute = ({ component: Component, layout: Layout, ...rest }: LayoutRouteProps) => (
	<Route
		{...rest}
		render={(props) => (
			<Layout>
				<Component {...props} />
			</Layout>
		)}
	/>
);
