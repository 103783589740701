import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Edge from '../../../core';
import FormField from '../../../components/formField';
import Input from '../../../components/global/input';
import Error from '../../../components/global/error';
import ConfigLayout from '../configLayout';
import { FullScreenService } from '../../../services/fullScreenService';

export interface AlignmentConfigFormProps {
	mode: Edge.Models.ExerciseMode;
	initialConfiguration: Partial<Edge.Models.AlignmentExerciseConfiguration>;
	lockConfiguration: boolean;
	onSubmit: (configuration: Edge.Models.AlignmentExerciseConfiguration, fullScreen: boolean) => Promise<void>;

	operationName: string;
}

export interface AlignmentConfigFormValues {
	fullScreen: boolean;
}

const schema = Yup.object().shape({
	fullScreen: Yup.boolean(),
});

const instructions = (
	<>
		<ul>
			<li>Red/Blue glasses are worn during this exercise. </li>
			<li>
				A red image (VE logo) and a blue box will appear on the screen. Using the arrow keys on the keyboard
				move the red image so that it is centered in the blue box. Once you have aligned the image in the center
				of the box, press 'Enter' on the keyboard.
			</li>
			<li>0 is a perfect score and 1 is within a normal range, whether it's Before (B) or After (A)</li>
		</ul>
	</>
);

export default class AlignmentConfigForm extends React.Component<AlignmentConfigFormProps> {
	public render() {
		const { initialConfiguration, onSubmit, operationName, mode } = this.props;
		return (
			<>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							fullScreen: false,
						}
					)}
					validationSchema={schema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await onSubmit(
								{
									id: initialConfiguration.id,
									exerciseTypeId: Edge.Models.ExerciseTypeId.Alignment,
									// not used past this point
									durationSeconds: 0,
									size: 0,
								},
								values.fullScreen
							);
						} catch (e) {
							actions.setStatus(Edge.API.getErrorMessage(e));
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<AlignmentConfigFormValues>) => (
						<Form>
							<ConfigLayout
								operationName={operationName}
								exerciseName="Alignment"
								headerActions={
									<>
										<Input
											type="submit"
											disabled={props.isSubmitting}
											value={mode === 'evaluate' ? 'Start Exercise' : 'Train Alignment'}
										/>
										{props.status && <Error>{props.status}</Error>}
										{FullScreenService.isEnabled() && (
											<FormField
												type="checkbox"
												name="fullScreen"
												description="View Full Screen"
											/>
										)}
									</>
								}
								requiresGlasses
								instructions={instructions}
								videoUrl={Edge.Constants.VIDEOS.ALIGNMENT}
							/>
						</Form>
					)}
				/>
			</>
		);
	}
}
