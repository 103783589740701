import * as React from 'react';

export interface SeeMorePagingProps<T> {
	seeMoreText?: string;
	pageSize: number;
	items: T[];
	render: (items: T[]) => React.ReactNode;
}

interface SeeMorePagingState {
	showCount: number;
}

export class SeeMorePaging<T> extends React.PureComponent<SeeMorePagingProps<T>, SeeMorePagingState> {
	constructor(props: SeeMorePagingProps<T>) {
		super(props);
		this.state = {
			showCount: props.pageSize,
		};
	}
	public render() {
		const { toRender, filteredLength } = this.itemsToRender();
		return (
			<React.Fragment>
				{this.props.render(toRender)}
				<div className="edge-table-footer">
					{filteredLength > toRender.length && (
						<button onClick={this.seeMore} className="add_more_btn">
							{this.props.seeMoreText}
						</button>
					)}
				</div>
			</React.Fragment>
		);
	}

	private seeMore = () => {
		this.setState({ showCount: this.state.showCount + this.props.pageSize });
	};

	private itemsToRender = () => {
		let { items } = this.props;
		const { showCount } = this.state;

		const toRender = items.slice(0, showCount);

		return { toRender, filteredLength: items.length };
	};
}

export default SeeMorePaging;
