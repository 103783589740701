import * as React from 'react';

import * as Edge from '../../core';
import EnterUsername from './enterUsername';
import EnterEmail from './enterEmail';
import Error from '../../components/global/error';
import Info from '../../components/global/info';
import { UserService } from '../../services/userService';
import { ReactComponent as Logo } from '../../assets/images/VZELogoLogin.svg';

import '../forgot/index.scss';

export interface ForgotPasswordProps {}

export interface ForgotPasswordState {
	enteringUsername: boolean; // the user is either on the 'Enter Username' screen or the 'Enter Email' screen at any given time
	username: string;
	email: string;
	loading: boolean;
	success: boolean;
	error?: Edge.Models.EdgeError;
}

export class ForgotPassword extends React.Component<ForgotPasswordProps, ForgotPasswordState> {
	constructor(props: ForgotPasswordProps) {
		super(props);
		this.state = {
			enteringUsername: true,
			username: '',
			email: '',
			loading: false,
			success: false,
		};
	}

	public render() {
		const { enteringUsername, username, email, loading, error, success } = this.state;
		return (
			<div className="forgot_password forgot">
				<div className="logo">
					<div className="icon">
						<Logo />
					</div>
					<h6>Vizual Edge</h6>
				</div>
				<div className="forgot_title">Forgot your password?</div>
				{enteringUsername ? (
					<EnterUsername
						username={username}
						updateUsername={this.updateUsername}
						changePage={this.changePage}
					/>
				) : (
					<EnterEmail
						email={email}
						updateEmail={this.updateEmail}
						sendEmail={this.sendEmail}
						loading={loading}
					/>
				)}
				{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
				{success && (
					<Info>Thank you. An email with instructions on how to reset your password has been sent.</Info>
				)}
			</div>
		);
	}

	private updateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ email: event.target.value, error: undefined });
	};

	private updateUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ username: event.target.value, error: undefined });
	};

	private changePage = () => {
		if (this.state.username.length < 1) {
			this.setState({ error: { name: '', message: 'Username is required.' } });
		} else {
			this.setState({ enteringUsername: false });
		}
	};

	private sendEmail = async () => {
		if (this.state.email.length < 1) {
			this.setState({ error: { name: '', message: 'Email is required.' } });
		} else {
			this.setState({ loading: true, error: undefined, success: false });
			try {
				await UserService.forgotPassword({ username: this.state.username, email: this.state.email });
				this.setState({ success: true });
			} catch (error) {
				this.setState({ error });
			}
			this.setState({ loading: false });
		}
	};
}

export default ForgotPassword;
