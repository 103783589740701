import _ from 'lodash';
import Random from './random';
import Vector from './vector';

// Constants

// Default for requestAnimationFrame(); code does not allow for any other value without significant refactoring.
export const FpsTarget: number = 60;

// Interfaces

// Defines travel area for animated object.
export interface Boundary {
	bottom: number;
	left: number;
	right: number;
	top: number;
}

// Methods

/**
 * Clamps x or y position if the object is heading towards out-of-bounds.
 *
 * @param positionCurrent Current position of object.
 * @param vectorCurrent Current travel vector of object.
 * @param boundary Travel bounds of object.
 */
const getClampedNewPosition = (positionCurrent: Vector, vectorCurrent: Vector, boundary: Boundary): Vector => {
	let candidate = positionCurrent.add(vectorCurrent);

	const crossedBounds = {
		bottom: candidate.y > boundary.bottom,
		left: candidate.x < boundary.left,
		right: candidate.x > boundary.right,
		top: candidate.y < boundary.top,
	};

	if (crossedBounds.bottom) {
		candidate.y = Math.min(boundary.bottom, candidate.y);
	} else if (crossedBounds.left) {
		candidate.x = Math.max(boundary.left, candidate.x);
	} else if (crossedBounds.right) {
		candidate.x = Math.min(boundary.right, candidate.x);
	} else if (crossedBounds.top) {
		candidate.y = Math.max(boundary.top, candidate.y);
	}

	return candidate;
};

/**
 * Returns full range if no borders have been crossed, clamps otherwise.
 *
 * @param positionCurrent Current position of object.
 * @param boundary Travel bounds of object.
 * @param buffer Distance from any side of the boundary in which the object will always attempt to move back to boundary center.
 * @returns (min, max) range as an object.
 */
const getClampedVectorRange = (
	positionCurrent: Vector,
	boundary: Boundary,
	buffer: number
): { min: number; max: number } => {
	const crossedBoundaries = {
		bottom: positionCurrent.y >= boundary.bottom - buffer,
		left: positionCurrent.x <= boundary.left + buffer,
		right: positionCurrent.x >= boundary.right - buffer,
		top: positionCurrent.y <= boundary.top + buffer,
	};

	if (!_.some(_.values(crossedBoundaries))) return { min: 0, max: 2 * Math.PI };
	else {
		if (crossedBoundaries.top && crossedBoundaries.left) return { min: Math.PI * 0.1, max: Math.PI * 0.4 };
		if (crossedBoundaries.top && crossedBoundaries.right) return { min: Math.PI * 0.6, max: Math.PI * 0.9 };
		if (crossedBoundaries.top) return { min: Math.PI * 0.25, max: Math.PI * 0.75 };

		if (crossedBoundaries.bottom && crossedBoundaries.left) return { min: -Math.PI * 0.4, max: -Math.PI * 0.1 };
		if (crossedBoundaries.bottom && crossedBoundaries.right) return { min: Math.PI * 1.1, max: Math.PI * 1.4 };
		if (crossedBoundaries.bottom) return { min: -Math.PI * 0.75, max: -Math.PI * 0.25 };

		if (crossedBoundaries.left) return { min: -Math.PI * 0.25, max: Math.PI * 0.25 };
		if (crossedBoundaries.right) return { min: Math.PI * 0.75, max: Math.PI * 1.25 };

		throw new Error('Unmapped region found');
	}
};

/**
 * Manipulates image data to reduce its contrast value.
 * @param imageData The image to reduce contrast of.
 * @param contrastPercent Reduction percent of image contrast; expects -1.0 ~ 0.
 */
export const generateContrastImageData = (imageData: ImageData, contrastPercent: number): ImageData => {
	const contrastHex = contrastPercent * 255;
	const factor = (contrastHex + 255) / (255.01 - contrastHex); // Adds 0.01 to avoid /0 error.

	let data = imageData.data;
	for (let i = 0; i < data.length; i += 4) {
		data[i] = factor * (data[i] - 128) + 128;
		data[i + 1] = factor * (data[i + 1] - 128) + 128;
		data[i + 2] = factor * (data[i + 2] - 128) + 128;
	}

	return imageData;
};

/**
 * Generates starting next vector coordinate; never (0, 0).
 * @returns (x, y) coordinate as an object.
 */
export const getStartingVectorCoordinate = (): { x: number; y: number } => {
	let x: number, y: number;

	do {
		x = Math.round(Math.random() * 2) - 1;
		y = Math.round(Math.random() * 2) - 1;
	} while (x === 0 && y === 0);

	return { x, y };
};

/**
 * Updates position and vector for one object and one frame.
 * Expects requestAnimationFrame() to repeatedly call this while animation is occuring.
 *
 * @param boundary Non-crossable boundary of the object being animated.
 * @param buffer Distance from any side of the boundary in which the object will always attempt to move back to boundary center.
 * @param framesRemaining Number of frames remaining for the current requestAnimationFrame() iteration.
 * @param positionCurrent Current position of object.
 * @param radiansPerFrame Amount of radians the object will travel per frame.
 * @param speed Speed of object.
 * @param vectorCurrent Current travel vector of object.
 * @param vectorNext Vector being used to calculate the current vector.
 * @returns New values to continue the animation loop.
 */
export const updatePositionAndVector = (
	boundary: Boundary,
	buffer: number,
	framesRemaining: number,
	positionCurrent: Vector,
	radiansPerFrame: number,
	speed: number,
	vectorCurrent: Vector,
	vectorNext: Vector
): {
	framesToNext: number;
	positionCurrent: Vector;
	vectorCurrent: Vector;
	vectorNext: Vector;
} => {
	// Determines the angles of current and next vectors.
	const radiansCurrent: number = vectorCurrent.toPhi2D();
	const radiansNext: number = vectorNext.toPhi2D();

	const radiansDistance: number = Math.abs(radiansNext - radiansCurrent);
	const framesToNext: number = radiansDistance / radiansPerFrame;

	const crossProduct: number = vectorCurrent.cross(vectorNext).z;
	const turnDirection: number = crossProduct > 0 ? 1 : -1;

	// Determines degrees to travel during this loop; either one frame or the entire next turn.
	const radiansDelta: number =
		(framesRemaining > framesToNext ? radiansDistance : framesRemaining * radiansPerFrame) * turnDirection;

	// Turns vector incrementally.
	const radiansNextFromCurrent: number = radiansCurrent + radiansDelta;

	vectorCurrent = Vector.fromAngles(0, radiansNextFromCurrent)
		.unit()
		.multiply(speed);

	// Updates circle position.
	positionCurrent = getClampedNewPosition(positionCurrent, vectorCurrent, boundary);

	// Sets a new vector if the previous travel is complete.
	if (radiansDistance <= Math.abs(radiansDelta)) {
		const clampedVectorRange = getClampedVectorRange(positionCurrent, boundary, buffer);
		const rangeWidth: number = clampedVectorRange.max - clampedVectorRange.min;

		const radiansSkew: number = (radiansNextFromCurrent / (Math.PI * 2) - 0.5 + 1) % 1;
		const random: number = rangeWidth < Math.PI * 2 ? Math.random() : Random.gaussian(3, radiansSkew);

		const radiansNewNext = clampedVectorRange.min + random * rangeWidth;

		// Sets a new next vector at random once established.
		// If within one radius of a boundary, choose new vector from a range of angles.
		vectorNext = Vector.fromAngles(0, radiansNewNext)
			.unit()
			.multiply(speed);
	}

	return {
		framesToNext,
		positionCurrent,
		vectorCurrent,
		vectorNext,
	};
};

/**
 * Scales canvas.
 * @param ctx Current HTML canvas 2D context.
 * @param scale Percent value to scale canvas to; maintains aspect ratio.
 */
export const scaleCanvas = (ctx: CanvasRenderingContext2D, scale: number): void => {
	ctx.setTransform(
		scale, // horizontal scale
		0, // horizontal skew
		0, // vertical skew
		scale, // vertical scale
		(-ctx.canvas.width * scale) / 2 + ctx.canvas.width / 2, // horizontal moving
		(-ctx.canvas.height * scale) / 2 + ctx.canvas.height / 2 // vertical moving
	);
};
