import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../../core';
import Hamburger from './hamburger';
import AcctSection from './acctSection';
import OrgSectionContainer from './orgSectionContainer';
import SessionTimer from '../sessionTimer';
import AddSessionsLink from './addSessions';
import { AppState } from '../../../store';

import './index.scss';

interface HeaderProps {
	currentOrganization?: Edge.Models.Organization;
}

export class Header extends React.Component<HeaderProps> {
	public render(): JSX.Element {
		const { currentOrganization } = this.props;
		return (
			<header id="vze_Header" className="vze_header">
				<Hamburger />
				<SessionTimer className="header_section" />
				{currentOrganization && currentOrganization.userPurchasable && (
					<AddSessionsLink className="header_section header_upgrade" />
				)}
				{/*
				<div className="header_section header_notifications">
					<div className="header_bellwrapper">
						Bell
						<div className="header_notifications_counter">
							2
						</div>
					</div>
				</div>
				*/}
				<OrgSectionContainer />
				<AcctSection />
			</header>
		);
	}
}

function mapStateToProps({ organizationState }: AppState) {
	return { currentOrganization: organizationState.currentOrganization };
}

export default connect(mapStateToProps)(Header);
