import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import classNames from 'classnames';

import { Avatar } from '../avatar';
import * as Edge from '../../core';
import TeamPicker from '../teamPicker';
import Loading from '..//loading';
import ErrorDisplay from '../error/errorDisplay';
import { AppState } from '../../store';
import { setCurrentTeam } from '../../store/team/actions';
import { UserPermissionService } from '../../services/userPermissionService';
import {
	getUserPermissionService,
	getTeamsUserCanEdit,
	getTeamsUserCanTrain,
	AccessibleTeams,
	getTeamsUserCanAddProspect,
	getTeamsUserCanEval,
	getAllTeams,
} from '../../store/permissions/selector';

import './index.scss';

export interface ConfigurationBannerProps {
	className?: string;
	permissions: UserPermissionService;
	currentTeam?: Edge.Models.Team;
	teams?: Edge.Models.Team[];
	currentOrganization?: Edge.Models.Organization;
	isLoading?: boolean;
	loadingError?: Edge.Models.EdgeError;
	setCurrentTeam: (team?: Edge.Models.Team) => void;
}

export interface ConfigurationBannerState {}

export class ConfigurationBanner extends React.PureComponent<ConfigurationBannerProps, ConfigurationBannerState> {
	public componentDidMount() {
		this.ensureCurrentTeam();
	}

	public componentDidUpdate(prevProps: ConfigurationBannerProps) {
		if (
			this.props &&
			prevProps &&
			(this.props.teams !== prevProps.teams || this.props.currentTeam !== prevProps.currentTeam)
		) {
			this.ensureCurrentTeam();
		}
	}

	public render(): JSX.Element {
		const { currentTeam, teams, currentOrganization, isLoading, loadingError, permissions } = this.props;
		if (!teams || isLoading) {
			return <Loading />;
		}

		if (teams.length > 0 && !currentTeam) {
			// we don't have a current team, but we should - wait for it to be set by ensureCurrentTeam
			return <Loading />;
		}

		if (loadingError) {
			return <ErrorDisplay message={Edge.API.getErrorMessage(loadingError)} />;
		}

		if (!currentTeam) {
			if (permissions.hasOrganizationAccess(Edge.Models.PermissionLevels.Edit, currentOrganization)) {
				return <Redirect to="/manage-teams" />;
			} else {
				return <Redirect to="/my-account" />;
			}
		}

		return (
			<div className={classNames('team_banner', this.props.className)}>
				<div>
					<Avatar src={currentTeam.logoUrl} size="small" />
					<h3>{currentTeam.name}</h3>
					{teams.length > 1 && (
						<TeamPicker
							className="team_picker"
							teams={teams}
							currentTeam={currentTeam}
							setCurrentTeam={this.props.setCurrentTeam}
							placeholderForCurrentTeam="Change team"
						/>
					)}
				</div>
				{this.props.children}
			</div>
		);
	}

	// #1393: https://dev.azure.com/vizualedge/Edge%20Trainer/_backlogs/backlog/Edge%20Trainer%20Team/Stories/?workitem=1393
	// TODO: ensuring should occur on pages rather than components
	// Remove once it's determined that pages that use this component
	//  ensures currentTeam is set.
	private ensureCurrentTeam = () => {
		const { currentTeam, teams } = this.props;
		if (!currentTeam && teams && teams.length > 0) {
			this.props.setCurrentTeam(teams[0]);
		}
	};
}

const createTeamBanner = (teamsSelector: (state: AppState) => AccessibleTeams) => {
	function mapStateToProps(state: AppState) {
		const { organizationState, teamState } = state;
		const { teams, currentTeam } = teamsSelector(state);

		return {
			teams,
			currentTeam,
			currentOrganization: organizationState.currentOrganization,
			permissions: getUserPermissionService(state),
			isLoading: teamState.isLoading || organizationState.isLoading,
			loadingError: teamState.loadingError || organizationState.loadingError,
		};
	}

	return connect(
		mapStateToProps,
		{
			setCurrentTeam,
		}
	)(ConfigurationBanner);
};

export const CanTrainTeamsBanner = createTeamBanner(getTeamsUserCanTrain);
export const CanEvalTeamsBanner = createTeamBanner(getTeamsUserCanEval);
export const CanEditTeamsBanner = createTeamBanner(getTeamsUserCanEdit);
export const CanAddProspectTeamsBanner = createTeamBanner(getTeamsUserCanAddProspect);
export const AllTeamsBanner = createTeamBanner(getAllTeams);
