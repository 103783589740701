import * as React from 'react';

import * as Edge from '../../core';
import Input from '../../components/global/input';
import Error from '../../components/global/error';
import Info from '../../components/global/info';
import Loading from '../../components/loading';
import { UserService } from '../../services/userService';
import { ReactComponent as Logo } from '../../assets/images/VZELogoLogin.svg';

import '../forgot/index.scss';

export interface ForgotUsernameProps {}

export interface ForgotUsernameState {
	email: string;
	loading: boolean;
	success: boolean;
	error?: Edge.Models.EdgeError;
}

export class ForgotUsername extends React.Component<ForgotUsernameProps, ForgotUsernameState> {
	constructor(props: ForgotUsernameProps) {
		super(props);
		this.state = {
			email: '',
			loading: false,
			success: false,
		};
	}

	public render() {
		const { email, loading, error, success } = this.state;
		return (
			<div className="forgot_username forgot">
				<div className="logo">
					<div className="icon">
						<Logo />
					</div>
					<h6>Vizual Edge</h6>
				</div>
				<div className="forgot_title">Forgot your username?</div>
				<div className="forgot_message">
					Don't worry! Just type in the email you registered to Vizual Edge and we'll send over your
					information.
				</div>
				<label htmlFor="Email">Email</label>
				<Input
					name="Email"
					id="Email"
					type="email"
					placeholder="Email"
					value={email}
					onChange={this.handleChange}
					maxLength={256}
				/>
				{loading && <Loading buttonLoader />}
				<Input onClick={this.sendEmail} disabled={loading} type="submit" value="Send" />
				<a href="/forgotPassword">Forgot your password?</a>
				{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
				{success && (
					<Info>
						Thank you. An email with information regarding your username and password has been sent.
					</Info>
				)}
			</div>
		);
	}

	private handleChange: (e: any) => void = (e) => {
		this.setState({ email: e.target.value });
	};

	private sendEmail = async () => {
		this.setState({ loading: true, error: undefined, success: false });
		try {
			await UserService.forgotUsername({ email: this.state.email });
			this.setState({ success: true });
		} catch (error) {
			this.setState({ error });
		}
		this.setState({ loading: false });
	};
}

export default ForgotUsername;
