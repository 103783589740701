import * as Edge from './../core';

export abstract class TeamService {
	public static async addMembers(
		command: Edge.Models.AddTeamMembersCommand
	): Promise<Edge.Models.AddTeamMembersCommandResponse> {
		const route = Edge.API.ServerRoute.forAction('teams', 'users');
		return await Edge.API.post(route, command);
	}

	public static async assignUserToPosition(command: Edge.Models.AssignUserToPositionCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('teams', 'assignToPosition');
		return await Edge.API.post(route, command);
	}

	public static async changeOrganization(command: Edge.Models.ChangeOrganizationCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('teams', 'changeOrganization');
		return await Edge.API.patch(route, command);
	}

	public static async create(command: Edge.Models.CreateTeamCommand): Promise<Edge.Models.Team> {
		const route = Edge.API.ServerRoute.forAction('teams');
		return await Edge.API.post(route, command);
	}

	public static async deactivate(id: string): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('teams', id);
		return await Edge.API.delete(route);
	}

	public static async emailTeam(command: Edge.Models.EmailTeamCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('teams', 'emailTeam');
		return await Edge.API.post(route, command);
	}

	public static async emailUser(command: Edge.Models.EmailUserCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('teams', 'emailUser');
		return await Edge.API.post(route, command);
	}

	public static async get(id: string): Promise<Edge.Models.Team> {
		const route = Edge.API.ServerRoute.forAction('teams', id);
		return await Edge.API.get(route);
	}

	public static async getAll(): Promise<Edge.Models.Team[]> {
		const route = Edge.API.ServerRoute.forAction('teams');
		return await Edge.API.get(route);
	}

	public static async getMembers(id: string): Promise<Edge.Models.TeamMember[]> {
		const route = Edge.API.ServerRoute.forAction('teams', `${id}/users`);
		return await Edge.API.get(route);
	}

	public static async getProspects(id: string, unevaluatedOnly: boolean = true): Promise<Edge.Models.TeamProspect[]> {
		const route = Edge.API.ServerRoute.forAction('teams', `${id}/prospects?unevaluatedOnly=${unevaluatedOnly}`);
		return await Edge.API.get(route);
	}

	public static async migrateUserTeamRoleToTeam(command: Edge.Models.MigrateUserTeamRoleCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('teams', 'migrate');
		return await Edge.API.patch(route, command);
	}

	public static async removeMember(teamId: string, userTeamRoleId: string): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('teams', `${teamId}/users/${userTeamRoleId}`);
		return await Edge.API.delete(route);
	}

	public static async update(command: Edge.Models.EditTeamCommand): Promise<Edge.Models.Team> {
		const route = Edge.API.ServerRoute.forAction('teams');
		return await Edge.API.patch(route, command);
	}

	public static async uploadLogo(teamId: string, imageData: string): Promise<string> {
		const route = Edge.API.ServerRoute.forAction('teams', 'uploadLogo');
		return await Edge.API.post(route, { teamId, imageData });
	}
}
