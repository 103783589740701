import * as ReduxThunk from 'redux-thunk';

import create from './create';
import * as Edge from '../../core';
import { StaticDataService } from '../../services/staticDataService';

const sports = create(() => StaticDataService.getSports(), 'SPORTS_LOADING', 'SPORTS_SUCCESS', 'SPORTS_FAILURE');

const getSports = sports.get;
const sportReducer = sports.reducer;

const levels = create(() => StaticDataService.getLevels(), 'LEVELS_LOADING', 'LEVELS_SUCCESS', 'LEVELS_FAILURE');

const getLevels = levels.get;
const levelReducer = levels.reducer;

const positions = create(
	() => StaticDataService.getPositions(),
	'POSITIONS_LOADING',
	'POSITIONS_SUCCESS',
	'POSITIONS_FAILURE'
);

const getPositions = positions.get;
const positionReducer = positions.reducer;

const publicOrganizationTypes = create(
	() => StaticDataService.getPublicOrganizationTypes(),
	'PUBLIC_ORGANIZATIONTYPES_LOADING',
	'PUBLIC_ORGANIZATIONTYPES_SUCCESS',
	'PUBLIC_ORGANIZATIONTYPES_FAILURE'
);

const getPublicOrganizationTypes = publicOrganizationTypes.get;
const publicOrganizationTypeReducer = publicOrganizationTypes.reducer;

const allOrganizationTypes = create(
	() => StaticDataService.getAllOrganizationTypes(),
	'ALL_ORGANIZATIONTYPES_LOADING',
	'ALL_ORGANIZATIONTYPES_SUCCESS',
	'ALL_ORGANIZATIONTYPES_FAILURE'
);

const getAllOrganizationTypes = allOrganizationTypes.get;
const allOrganizationTypeReducer = allOrganizationTypes.reducer;

const teamTypes = create(
	() => StaticDataService.getTeamTypes(),
	'TEAMTYPES_LOADING',
	'TEAMTYPES_SUCCESS',
	'TEAMTYPES_FAILURE'
);

const getTeamTypes = teamTypes.get;
const teamTypeReducer = teamTypes.reducer;

const teamRoles = create(
	() => StaticDataService.getTeamRoles(),
	'TEAMROLES_LOADING',
	'TEAMROLES_SUCCESS',
	'TEAMROLES_FAILURE'
);

const getTeamRoles = teamRoles.get;
const teamRoleReducer = teamRoles.reducer;

const organizationRoles = create(
	() => StaticDataService.getOrganizationRoles(),
	'ORGANIZATIONROLES_LOADING',
	'ORGANIZATIONROLES_SUCCESS',
	'ORGANIZATIONROLES_FAILURE'
);

const getOrganizationRoles = organizationRoles.get;
const organizationRoleReducer = organizationRoles.reducer;

const prices = create(() => StaticDataService.getPrices(), 'PRICES_LOADING', 'PRICES_SUCCESS', 'PRICES_FAILURE');

const getPrices = prices.get;
const priceReducer = prices.reducer;

interface StaticDataState {
	levelState: ReturnType<typeof levelReducer>;
	sportState: ReturnType<typeof sportReducer>;
	positionState: ReturnType<typeof positionReducer>;
	teamTypeState: ReturnType<typeof teamTypeReducer>;
}

const getAllStaticData = (): ReduxThunk.ThunkAction<
	Promise<void>,
	StaticDataState,
	null,
	Edge.Models.EdgeAction
> => async (dispatch, getState) => {
	const state = getState();
	const toLoad = [];

	// don't load things we already have (ie. after registration)
	if (!state.sportState || !state.sportState.data) {
		toLoad.push(dispatch(getSports()));
	}
	if (!state.levelState || !state.levelState.data) {
		toLoad.push(dispatch(getLevels()));
	}
	if (!state.positionState || !state.positionState.data) {
		toLoad.push(dispatch(getPositions()));
	}
	if (!state.teamTypeState || !state.teamTypeState.data) {
		toLoad.push(dispatch(getTeamTypes()));
	}

	await Promise.all(toLoad);
};

export {
	getAllStaticData,
	getSports,
	sportReducer,
	getLevels,
	levelReducer,
	getPositions,
	positionReducer,
	getPublicOrganizationTypes,
	publicOrganizationTypeReducer,
	getAllOrganizationTypes,
	allOrganizationTypeReducer,
	getTeamTypes,
	teamTypeReducer,
	getTeamRoles,
	teamRoleReducer,
	getOrganizationRoles,
	organizationRoleReducer,
	getPrices,
	priceReducer,
};
