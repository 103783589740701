import * as ReduxThunk from 'redux-thunk';
import * as Edge from './../../core';
import { AppState } from './..';
import { TeamService } from './../../services/teamService';

import { TEAMS_LOADING, TEAMS_SUCCESS, TEAMS_FAILURE, SET_CURRENT_TEAM, UPDATE_TEAM, TeamUpdateType } from './types';

// action creators
export const loadTeamsAction = () => {
	return {
		type: TEAMS_LOADING,
	};
};

export const teamsSuccessAction = (teams: Edge.Models.Team[]) => {
	return {
		type: TEAMS_SUCCESS,
		payload: teams,
	};
};

export const teamsFailureAction = (error: Edge.Models.EdgeError) => {
	return {
		type: TEAMS_FAILURE,
		payload: error,
		error: true,
	};
};

export const setCurrentTeamAction = (team?: Edge.Models.Team) => {
	return {
		type: SET_CURRENT_TEAM,
		payload: team,
	};
};

export const updateTeamAction = (team: TeamUpdateType) => {
	return {
		type: UPDATE_TEAM,
		payload: team,
	};
};

// thunk actions
export const getTeams = (): ReduxThunk.ThunkAction<
	Promise<Edge.Models.Team[]>,
	AppState,
	null,
	Edge.Models.EdgeAction
> => async (dispatch) => {
	dispatch(loadTeamsAction());

	try {
		const teams = await TeamService.getAll();
		dispatch(teamsSuccessAction(teams));
		return teams;
	} catch (error) {
		dispatch(teamsFailureAction(error));
		throw error;
	}
};

export const setCurrentTeam = (
	team?: Edge.Models.Team
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(setCurrentTeamAction(team ? team : undefined));
};

export const setCurrentTeamFromCache = (
	currentOrganizationId: string,
	hasCurrentTeam: boolean,
	teams?: Edge.Models.Team[]
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	if (!hasCurrentTeam) {
		if (!!teams && teams.length > 0) {
			const orgTeamIdsString = localStorage.getItem(Edge.Constants.LOCALSTORAGE_ORG_CURRENT_TEAM_IDS);
			const orgTeamIds: { [orgId: string]: string } = !!orgTeamIdsString ? JSON.parse(orgTeamIdsString) : {};

			const orgTeamId = orgTeamIds[currentOrganizationId];
			const team = !!orgTeamId && teams.find((t: Edge.Models.Team) => t.id === orgTeamId);
			dispatch(setCurrentTeamAction(team || teams[0]));
		}
	}
};

export const updateTeam = (
	updates: TeamUpdateType
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(updateTeamAction(updates));
};
