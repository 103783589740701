import * as React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../../assets/images/VZELogoLogin.svg';
import Input from '../../components/global/input';
import Error from '../../components/global/error';
import Loading from '../../components/loading';

interface LoginProps {
	isLoading: boolean;
	username: string;
	onUsernameChange: React.ChangeEventHandler;
	password: string;
	onPasswordChange: React.ChangeEventHandler;
	onSubmit: React.FormEventHandler;
	errorText?: string;
}

export class Login extends React.Component<LoginProps> {
	public render(): JSX.Element {
		const { onSubmit, isLoading, username, password, onUsernameChange, onPasswordChange, errorText } = this.props;
		return (
			<div className="login">
				<div className="login_logo">
					<div className="icon">
						<Logo />
					</div>
					<h6>Vizual Edge</h6>
				</div>
				<h2>Login to Vizual Edge Trainer</h2>
				<form onSubmit={onSubmit}>
					<label htmlFor="Username">Username</label>
					<Input
						name="Username"
						id="Username"
						placeholder="Username"
						type="text"
						value={username}
						onChange={onUsernameChange}
					/>
					<label htmlFor="Password">Password</label>
					<Input
						name="Password"
						id="Password"
						placeholder="Password"
						type="password"
						value={password}
						onChange={onPasswordChange}
					/>
					{errorText && <Error>{errorText}</Error>}
					{isLoading && <Loading buttonLoader invertColors />}
					<Input type="submit" value="Login" disabled={isLoading} />
				</form>
				<p>
					Don't have an account?{' '}
					<Link to="/register" className="loginLink">
						Sign up
					</Link>
				</p>
				<div className="forgotLinks">
					<Link to="/forgotUsername">Forgot your username?</Link>
					<div className="dot" />
					<Link to="/forgotPassword">Forgot your password?</Link>
				</div>
			</div>
		);
	}
}
