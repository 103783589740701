import * as React from 'react';
import Input from '../input';

import './index.scss';

export interface NumericInputProps {
	value: number;
	onChange: (value: number) => void;
	min?: number;
	max?: number;
}

interface NumericInputState {}

export class NumericInput extends React.PureComponent<NumericInputProps, NumericInputState> {
	constructor(props: NumericInputProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { value, min, max } = this.props;
		return (
			<div className="numeric-input">
				<button
					className="numeric-input-down"
					onClick={this.onDown}
					disabled={min !== undefined && value <= min}
				>
					-
				</button>
				<Input type="number" value={value} {...min && { min }} {...max && { max }} onChange={this.onChange} />
				<button className="numeric-input-up" onClick={this.onUp} disabled={max !== undefined && value >= max}>
					+
				</button>
			</div>
		);
	}
	private fireChanged = (value: number) => {
		const { min, max, onChange } = this.props;
		if (min !== undefined) {
			value = Math.max(value, min);
		}
		if (max !== undefined) {
			value = Math.min(value, max);
		}
		onChange(value);
	};
	private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.fireChanged(parseInt(event.target.value) || 0);
	};
	private onDown = () => {
		this.fireChanged(this.props.value - 1);
	};
	private onUp = () => {
		this.fireChanged(this.props.value + 1);
	};
}
export default NumericInput;
