/* eslint-disable */
$( function() {
	
	VizEdge.AlignExercise = function() {
	    this.initialize.apply(this, arguments);
	};
	
	_.extend( VizEdge.AlignExercise.prototype, {
    
    instructions: "Align the logo in the square.",
                    
		initialize: function( debugMode, renderScale, touchControls, configuration, completeExercise ) {
      
      var canvas = document.createElement('canvas');
      canvas.id = "stage";
      canvas.width = $(window).width() - 40;
      canvas.height = $(window).height() - 200;
      canvas.style.display = "inline";

      $(".stage-wrapper").append(canvas);
			paper.setup(canvas);
      this.canvasEl = canvas;
      
      _.bindAll( this, "onFrame" );
            
      // constants
      this.directions = ["up","left","right","down"];
      this.blue = "#0000b3";
      this.textColor = "#3266cc";
      this.minStartDistance = 100 * renderScale;
      this.velocity = 0;
      this.maxVelocity = 3.0 * renderScale;
            
      // pixel offset to diopter converson table
      this.pixelsToDiopters = [ 3, 7, 12, 18, 26, 33, 43, 55, 71, 80, 86, 93, 100, 109, 9999 ];

      this.renderScale = renderScale;
      this.pixelsToDiopters = _.map( this.pixelsToDiopters, function( scale ) { return scale * renderScale; } );
      
      // settings
      this.sessionLength = 180 * 1000; // 3 mins
      this.debugMode = (debugMode != 'true' ) ? false : true;
      this.touchControls = touchControls;
      this.completeExercise = completeExercise;
      
      if (configuration) {
        this.setFullScreen(configuration.fullScreen);
      }

		},
    
    startSessionTimer: function() {      
      // set up a timer for the entire session
      this.sessionTimer = window.setTimeout( _.bind( function() {
        this.endSession();        
      }, this), this.sessionLength );
    },
        
    startSession: function() {
            
      this.totalResponseTime = 0;
      this.horzAlignment = 0;
      this.vertAlignment = 0;
      this.targetLogo = new paper.Raster("target-logo");
      this.targetLogo.visible = false;
      this.targetLogo.scaling = new paper.Point( this.renderScale, this.renderScale );
      
      new VizEdge.ReadyGo( _.bind( function() {
        this.startSessionTimer();
        this.startRound();
      }, this) );
                
    },
    
    setFullScreen: function(value) {

      if( !BigScreen.enabled ) return;

      this.fullScreen = value;

      if( this.fullScreen ) {
        if (!BigScreen.element) {
          BigScreen.toggle( null, null, this.onExitFullScreen );
        }
      } else {
        if (BigScreen.element) {
          BigScreen.exit();
        }
        this.onExitFullScreen();
      }

    },

    onExitFullScreen: function() {  
    },
        
    isSessionOver: function() {
      return (this.sessionTimer == null);
    },
    
    isRoundOver: function() {
      return !this.roundInProgress;
    },

    endSession: function() {
      
      if( this.isSessionOver() ) {
        return;
      }
            
      if( !this.isRoundOver() ) {
        this.endRound();
      }
                        
      if( this.sessionTimer ) {
        window.clearTimeout( this.sessionTimer );	
        this.sessionTimer = null;
      }

      // clean up
      paper.project.clear();
      paper.view.draw();       

      this.setFullScreen(false);

      this.sendScore();
    },
    
    sendScore: function() {
      var hypoThreshold = 20 * this.renderScale;
      var hyperThreshold = -20 * this.renderScale;

      var hypoAligned = false;
      var hyperAligned = false;

      if (this.vertAlignment != 0) {
        if (this.vertAlignment >= hypoThreshold ) {
          hypoAligned = true;
          vertAlignmentText = "Your eyes are hypo-aligned.";
        } else if (this.vertAlignment <= hyperThreshold ) {
          hyperAligned = true;
          vertAlignmentText = "Your eyes are hyper-aligned.";
        }
      }
      
      this.completeExercise({
        exercise: 'align',
        audio: true,
        evaluation: true,
        horizAlign: this.calcPrismDioptersSigned(),
        vertAlign: this.vertAlignment,
        hypoAligned: hypoAligned,
        hyperAligned: hyperAligned,
      });
    },

    calcPrismDiopters: function() {

      var diff = Math.abs(this.horzAlignment);       
      var diopters = null;

      for (var i = 0; i < this.pixelsToDiopters.length; i++) {
        if(diff < this.pixelsToDiopters[i]) {
          diopters = i;
          break;
        }
      }

      if( diopters == null) {
        diopters = this.pixelsToDiopters.length;
      }

      return diopters;
    },
    
    calcPrismDioptersSigned: function() {

      var diopters = this.calcPrismDiopters();

      if (this.horzAlignment >= 0) {
        return diopters;
      }
      else {
        return -diopters;
      }
    },
    
    calculateAlignment: function() {
      this.horzAlignment = Math.floor( this.targetRectangle.position.x - this.targetLogo.position.x );
      this.vertAlignment = Math.floor( this.targetRectangle.position.y - this.targetLogo.position.y );
    },
    
    startRound: function() {
      
      // don't start a new round if the session has ended.
      if( this.isSessionOver() ) return;
                  
      this.render();
      paper.view.on('frame', this.onFrame );

      this.roundInProgress = true;
      this.roundStartTime = this.getTime();
    },
        
    getTime: function() {
      var d = new Date();
      return d.getTime();
    },
    
    logger: function( message ) {
      if( this.debugMode ) {
        console.log(message);
      }      
    },
    
    endRound: function() {

      this.calculateAlignment();       
      
      // we must be in a round to accept input
      this.roundInProgress = false;
      paper.view.off('frame', this.onFrame );

      // accumulate total response time
      var responseTime  = this.getTime() - this.roundStartTime;
      this.totalResponseTime = responseTime + this.totalResponseTime;  
    },
    
    
    moveTargetLogo: function( direction ) {
      
      var newPosition = new paper.Point( this.targetLogo.position );
                  
      if( direction == 'left' ) {
        newPosition.x -= this.velocity; 
      }
      
      if( direction == 'right' ) {
        newPosition.x += this.velocity;
      }
      
      if( direction == 'up' ) {
        newPosition.y -= this.velocity;
      }
      
      if( direction == 'down' ) {
        newPosition.y += this.velocity;
      }
      
      var yOffset = 40 * this.renderScale;
      var viewBounds = paper.view.bounds;
      var logoSize = this.targetLogo.bounds.size;
      var minX = logoSize.width/2;
      var minY = logoSize.height/2;
      var maxX = viewBounds.width - (logoSize.width/2);
      var maxY = viewBounds.height - (logoSize.height/2) - yOffset;
      
      // keep the logo on the stage
      newPosition.x = Math.max( newPosition.x, minX ); 
      newPosition.y = Math.max( newPosition.y, minY );       
      newPosition.x = Math.min( newPosition.x, maxX ); 
      newPosition.y = Math.min( newPosition.y, maxY ); 
      this.targetLogo.position = newPosition;
                  
    },
    
    directionSelected: function( direction ) {
      return paper.Key.isDown(direction) || (this.touchControls && this.dPad.isActive(direction));
    },
    
    onFrame: function(event) {
      
      var keyPressed = false;
                  
      if( this.directionSelected('left') || this.directionSelected('a')) {
        this.moveTargetLogo('left');
        keyPressed = true;      
      }
      
      if( this.directionSelected('right') || this.directionSelected('d') ) {
        this.moveTargetLogo('right');
        keyPressed = true;      
      }
      
      if( this.directionSelected('up') || this.directionSelected('w') ) {
        this.moveTargetLogo('up');
        keyPressed = true;      
      }
      
      if( this.directionSelected('down') || this.directionSelected('s') ) {
        this.moveTargetLogo('down');
        keyPressed = true;      
      }
      
      if( paper.Key.isDown('enter') || (this.touchControls && this.dPad.isActive('ok')) ) {
        this.endSession();
      }
      
      if( (this.touchControls && this.dPad.mouseDown == false) && keyPressed == false ) {
        this.velocity = 0;
      }
            
      // regulate velocity of logo
      var accelerationFactor = 0.1 * this.renderScale;
      if( keyPressed ) {
        this.velocity += (this.velocity < this.maxVelocity) ? accelerationFactor : 0;
      } else {
        this.velocity = accelerationFactor;
      }
      
    },
            
    pickStartPoint: function() {
      // draw target logo in random location
      var logoSize = this.targetLogo.bounds.size;
      var viewBounds = paper.project.view.bounds;
      var yOffset = 40 * this.renderScale;    
      var xPosition = _.random( logoSize.width/2, viewBounds.width - (logoSize.width/2) );
      var yPosition = _.random( logoSize.height/2, viewBounds.height - (logoSize.height/2) - yOffset );
      return new paper.Point( xPosition, yPosition );
    },
            
		render: function() {
      
      // don't display dpad if touch controls are off
      if( this.touchControls ) {
        this.dPad = new VizEdge.DPadControl();
      }
      
      // make sure the chosen position is minStartDistance from target and not under the direction pad control
      this.targetLogo.position = this.pickStartPoint();
      while( paper.view.center.getDistance(this.targetLogo.position) < this.minStartDistance || 
             (this.touchControls && this.dPad.intersects(this.targetLogo.bounds)) ) {
               this.targetLogo.position = this.pickStartPoint();
      }
      
      // draw target logo in selected location
      this.targetLogo.visible = true;
      
      // draw blue rectangle in center of screen
      var targetSize = new paper.Size( this.targetLogo.bounds.size );
      var targetPosition = new paper.Point( paper.view.center.x - targetSize.width/2, paper.view.center.y - targetSize.height/2 );
      this.targetRectangle = new paper.Path.Rectangle( targetPosition, targetSize );
      this.targetRectangle.style = { strokeColor: this.blue };
      
      // render the instructions
      var text = new paper.PointText(new paper.Point(paper.view.center.x, 20));
      text.justification = 'center';
      text.fillColor = this.textColor;
      text.content = this.instructions;
      text.fontSize = 24;
            
      paper.view.draw();
		}
    					
	});
	
});
			
