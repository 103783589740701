import * as React from 'react';
import * as _ from 'lodash';

import * as Edge from '../../core';
import { withLoadDataDefaultConfig } from '../loadData';
import errorLoadingWrapperHOC from '../errorLoadingWrapper/errorLoadingWrapperHOC';
import { SessionService } from '../../services/sessionService';
import NumericInput from '../global/numericInput';
import ShowError from '../global/error';
import * as StrUtil from '../../utilities/strUtil';

type SourceEntityType = 'Organization' | 'Team';
type CreditType = 'session' | 'subscription';
export enum CreditMode {
	Sessions = 'sessions',
	Subscriptions = 'subscriptions',
}

export interface AssignCreditsProps {
	allowMultipleSubscriptions?: boolean;
	sourceEntityId: string;
	sourceEntityType: SourceEntityType;
	destinationEntityType: 'Team' | 'UserTeamRole';
	render: (props: RenderProps) => React.ReactNode;

	creditAssignments: Edge.Models.CreditsResponse;

	reloadData: () => Promise<void>;
}

export interface RenderProps {
	mode: CreditMode;
	renderHeader: () => React.ReactNode;
	renderItem: (destinationEntityId: string) => React.ReactNode;
	renderError: () => React.ReactNode;
	reloadData: () => Promise<void>;
}

interface CreditChange {
	destinationEntityId: string;
	type: CreditType;
	credits: number;
}

interface AssignCreditsState {
	queuedChanges: CreditChange[];
	applyingChanges: CreditChange[];
	appliedChanges: CreditChange[];
	error?: Edge.Models.EdgeError;
}

/**
 * This control is designed to wrap a table displaying the things that can be assigned credits.
 * Render your inner control in the `render` callback passed to this, and use the methods passed to get the parts this control supplies.
 */
export class AssignCredits extends React.PureComponent<AssignCreditsProps, AssignCreditsState> {
	private saveChanges: (() => void) & _.Cancelable;
	constructor(props: AssignCreditsProps) {
		super(props);
		this.state = {
			queuedChanges: [],
			applyingChanges: [],
			appliedChanges: [],
		};
		this.saveChanges = _.debounce(this.saveChangesToServer, 500);
	}
	private saveChangesToServer = async () => {
		if (this.state.applyingChanges.length) {
			// if there's a save already in progress, it'll send our changes for us
			return;
		}
		// so long as we don't get an error and still have work to do, keep doing it
		//   `this.state` will be updated each time through the loop because of the delay by the call to the server
		while (this.state.queuedChanges.length) {
			await this.saveChangeToServer();
		}
	};

	/* saves a single change to the server */
	private saveChangeToServer = async () => {
		const changes = _.values(_.groupBy(this.state.queuedChanges, (i) => `${i.destinationEntityId}_${i.type}`))[0];
		if (!changes) {
			return;
		}
		this.setState({
			queuedChanges: _.difference(this.state.queuedChanges, changes),
			applyingChanges: [...this.state.applyingChanges, ...changes],
			error: undefined,
		});
		const { destinationEntityId, type } = changes[0];
		const credits = _.sumBy(changes, (i) => i.credits);
		const toApply = { destinationEntityId, type, credits };
		try {
			if (credits !== 0) {
				let command: Edge.Models.ReassignCreditsRequest = {
					sourceEntityId: this.props.sourceEntityId,
					sourceEntityType: this.props.sourceEntityType,
					destinationEntityId,
					destinationEntityType: this.props.destinationEntityType,
					credits,
				};
				if (credits < 0) {
					// need to move the credits in the other direction
					command = {
						sourceEntityId: command.destinationEntityId,
						sourceEntityType: command.destinationEntityType,
						destinationEntityId: command.sourceEntityId,
						destinationEntityType: command.sourceEntityType,
						credits: -credits,
					};
				}
				await (type === 'session'
					? SessionService.reassignSessions(command)
					: SessionService.reassignSubscriptions(command));
			}
			this.setState({
				appliedChanges: [...this.state.appliedChanges, toApply],
				applyingChanges: _.difference(this.state.applyingChanges, changes),
			});
		} catch (e) {
			this.setState({
				queuedChanges: [...this.state.queuedChanges, toApply],
				applyingChanges: _.difference(this.state.applyingChanges, changes),
				error: e,
			});
			throw e;
		}
	};
	componentWillUnmount() {
		this.saveChanges.flush();
	}
	public render() {
		const mode = this.creditMode();
		const { creditAssignments, allowMultipleSubscriptions, reloadData } = this.props;
		const { queuedChanges, applyingChanges, appliedChanges } = this.state;
		const allChanges = [...queuedChanges, ...applyingChanges, ...appliedChanges];
		const sessionChanges = allChanges.filter((i) => i.type === 'session');
		const subscriptionChanges = allChanges.filter((i) => i.type === 'subscription');

		const sessionsAvailable = creditAssignments.entity.sessionCredits - _.sumBy(sessionChanges, (i) => i.credits);
		const sessionsTotal =
			creditAssignments.entity.sessionCredits + _.sumBy(creditAssignments.children, (i) => i.sessionCredits);
		const subscriptionsAvailable =
			creditAssignments.entity.subscriptionCredits - _.sumBy(subscriptionChanges, (i) => i.credits);
		const subscriptionsTotal =
			creditAssignments.entity.subscriptionCredits +
			_.sumBy(creditAssignments.children, (i) => i.subscriptionCredits);

		// we couldn't reuse the above data used to build these if we put these as instance methods
		const renderHeader = () => {
			const sessionsText = `${sessionsAvailable}/${sessionsTotal} ${StrUtil.pluralize(
				'Session',
				sessionsAvailable
			)}`;
			const subscriptionsText = `${subscriptionsAvailable}/${subscriptionsTotal} ${StrUtil.pluralize(
				'Seat',
				subscriptionsAvailable
			)}`;

			switch (mode) {
				case CreditMode.Sessions:
					return <>{sessionsText} Available</>;
				case CreditMode.Subscriptions:
					return <>{subscriptionsText} Available</>;
				default:
					throw new Error('Invalid mode');
			}
		};

		const renderItem = (id: string) => {
			const assignment = creditAssignments.children.filter((i) => i.entityId === id)[0];
			const sessions =
				(assignment ? assignment.sessionCredits : 0) +
				_.sumBy(sessionChanges.filter((i) => i.destinationEntityId === id), (i) => i.credits);
			const subscriptions =
				(assignment ? assignment.subscriptionCredits : 0) +
				_.sumBy(subscriptionChanges.filter((i) => i.destinationEntityId === id), (i) => i.credits);

			const sessionsControl = (
				<NumericInput
					value={sessions}
					min={0}
					max={sessions + sessionsAvailable}
					onChange={(newValue) => {
						this.setState(
							{
								queuedChanges: [
									...this.state.queuedChanges,
									{
										destinationEntityId: id,
										type: 'session',
										credits: newValue - sessions,
									},
								],
							},
							this.saveChanges
						);
					}}
				/>
			);
			const subscriptionsControl = (
				<NumericInput
					value={subscriptions}
					min={0}
					max={
						allowMultipleSubscriptions
							? subscriptions + subscriptionsAvailable
							: Math.min(1, subscriptions + subscriptionsAvailable)
					}
					onChange={(newValue) => {
						this.setState(
							{
								queuedChanges: [
									...this.state.queuedChanges,
									{
										destinationEntityId: id,
										type: 'subscription',
										credits: newValue - subscriptions,
									},
								],
							},
							this.saveChanges
						);
					}}
				/>
			);

			switch (mode) {
				case CreditMode.Sessions:
					return <>{sessionsControl}</>;
				case CreditMode.Subscriptions:
					return <>{subscriptionsControl}</>;
				default:
					throw new Error('Invalid mode');
			}
		};

		const renderError = () => {
			const { error } = this.state;
			return error && <ShowError>{Edge.API.getErrorMessage(error)}</ShowError>;
		};

		return this.props.render({
			mode,
			renderHeader,
			renderItem,
			renderError,
			reloadData,
		});
	}

	private creditMode: () => CreditMode = () => {
		const { creditAssignments } = this.props;
		const credits = [creditAssignments.entity, ...creditAssignments.children];
		const sessions = _.sumBy(credits, (i) => i.sessionCredits);
		const subscriptions = _.sumBy(credits, (i) => i.subscriptionCredits);
		if (subscriptions > 0) {
			return CreditMode.Subscriptions;
		} else if (sessions > 0) {
			return CreditMode.Sessions;
		} else {
			// default to subscriptions if neither exist
			return CreditMode.Subscriptions;
		}
	};
}

export default withLoadDataDefaultConfig(
	errorLoadingWrapperHOC(AssignCredits, undefined, undefined, undefined, { loadingOptions: { blockItem: true } }),
	(props: AssignCreditsProps) => {
		return { sourceEntityId: props.sourceEntityId, sourceEntityType: props.sourceEntityType };
	},
	async ({ sourceEntityId, sourceEntityType }: { sourceEntityId: string; sourceEntityType: SourceEntityType }) => {
		const creditAssignments = await (sourceEntityType === 'Organization'
			? SessionService.getOrganizationCredits(sourceEntityId)
			: SessionService.getTeamCredits(sourceEntityId));
		return { creditAssignments };
	}
);
