export default class Random {
	// iterations is number of iterations of Math.random
	// skew will push the mean by `skew` (e.g., -0.25 will center it on 0.25)
	static gaussian = (iterations: number, skew: number): number => {
		let result = 0;
		for (var i = 0; i < iterations; i++) {
			result += Math.random();
		}
		let rand = result / iterations + skew;
		if (rand > 1) rand -= 1; // fit to 0-1 range
		return rand;
	};
}
