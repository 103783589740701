import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import AppPageContainer from '../../../../components/pageContainer';

import * as Edge from '../../../../core';
import FormField from '../../../../components/formField';
import Input from '../../../../components/global/input';
import Info from '../../../../components/global/info';
import Loading from '../../../../components/loading';

import '../index.scss';

export interface MigrateUserProps {
	onSubmit: (v: MigrateUserTeamRoleToTeamFormValues) => Promise<void>;
}

export interface MigrateUserTeamRoleToTeamFormValues {
	userTeamRoleId: string;
	newTeamId: string;
}

const schema = Yup.object().shape({
	userTeamRoleId: Yup.string().required('User team role ID is required'),
	newTeamId: Yup.string().required('New team ID is required'),
});

export const MigrateUser: React.FunctionComponent<MigrateUserProps> = (props) => (
	<AppPageContainer>
		<div className="migrate">
			<h4>Migrate user to another team</h4>
			<hr />
			<Formik
				initialValues={{
					/** anything not specified here won't show an error message after an attempted submit */
					userTeamRoleId: '',
					newTeamId: '',
				}}
				validationSchema={schema}
				onSubmit={async (values, actions) => {
					actions.setStatus(undefined);
					try {
						await props.onSubmit(values);
						actions.setStatus('Success');
					} catch (e) {
						const message = Edge.API.getErrorMessage(e);
						actions.setStatus(message);
					} finally {
						actions.setSubmitting(false);
					}
				}}
				render={(props: FormikProps<MigrateUserTeamRoleToTeamFormValues>) => (
					<Form>
						<FormField type="text" name="userTeamRoleId" description="User Team Role ID" />
						<FormField type="text" name="newTeamId" description="New Team ID" />
						{props.status && <Info>{props.status}</Info>}
						{props.isSubmitting && <Loading buttonLoader />}
						<Input type="submit" disabled={props.isSubmitting} value="Migrate" />
					</Form>
				)}
			/>
		</div>
	</AppPageContainer>
);

export default MigrateUser;
