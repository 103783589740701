import * as React from 'react';
import classNames from 'classnames';

import './index.scss';

export interface MainContent {
	className?: string;
}

export class AppPageContainer extends React.Component<MainContent> {
	public render(): JSX.Element {
		return <div className={classNames('appPageContainer', this.props.className)}>{this.props.children}</div>;
	}
}

export default AppPageContainer;
