import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as moment from 'moment';

import * as Edge from '../../../core';
import FormField from '../../../components/formField';
import Input from '../../../components/global/input';
import Error from '../../../components/global/error';
import ConfigLayout from '../configLayout';
import { FullScreenService } from '../../../services/fullScreenService';

export interface TrackingConfigFormProps {
	mode: Edge.Models.ExerciseMode;
	initialConfiguration: Partial<Edge.Models.TrackingExerciseConfiguration>;
	lockConfiguration: boolean;
	onSubmit: (configuration: Edge.Models.TrackingExerciseConfiguration, fullScreen: boolean) => Promise<void>;

	operationName: string;
}

export interface TrackingConfigFormValues {
	durationSeconds: string;
	size: string;
	flashTimeMilliseconds: string;
	fixation: string;
	fullScreen: boolean;
}

const schema = Yup.object().shape({
	durationSeconds: Yup.number(),
	size: Yup.string().required('Arrow Size is required'),
	flashTimeMilliseconds: Yup.string().required('Flash Time is required'),
	fixation: Yup.string().required('Fixation is required'),
	fullScreen: Yup.boolean(),
});

interface InstructionProps {
	config: TrackingConfigFormValues;
	startText: string;
}

const instructions = ({ config, startText }: InstructionProps) => (
	<ul>
		<li>Red/Blue glasses are NOT worn during this exercise.</li>
		<li>
			After clicking the '{startText}' button, an arrow will flash at a random position on the screen. Using the
			arrow keys, indicate the direction each arrow is pointing (up, down, left, or right) as fast as possible.
			You do NOT need to wait for the arrow to disappear from the screen.
		</li>
		<li>This exercise is scored based on both speed and accuracy. </li>
		<li>
			This exercise will last {moment.duration(parseInt(config.durationSeconds), 'seconds').humanize()} and you
			have 3 seconds to answer each round.
		</li>
	</ul>
);

export default class TrackingConfigForm extends React.Component<TrackingConfigFormProps> {
	public render() {
		const { initialConfiguration, lockConfiguration, onSubmit, operationName, mode } = this.props;
		const startText = mode === 'evaluate' ? 'Start Exercise' : 'Train Tracking';
		return (
			<>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							durationSeconds: (initialConfiguration.durationSeconds || 60).toString() || '60',
							size: (initialConfiguration.size || 2).toString() || '2',
							flashTimeMilliseconds:
								(initialConfiguration.flashTimeMilliseconds || 600).toString() || '600',
							fixation: (initialConfiguration.fixation || false).toString() || 'false',
							fullScreen: false,
						}
					)}
					validationSchema={schema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await onSubmit(
								{
									id: initialConfiguration.id,
									exerciseTypeId: Edge.Models.ExerciseTypeId.Tracking,
									durationSeconds: parseInt(values.durationSeconds),
									size: parseInt(values.size),
									flashTimeMilliseconds: parseInt(values.flashTimeMilliseconds),
									fixation: values.fixation === 'true',
								},
								values.fullScreen
							);
						} catch (e) {
							actions.setStatus(Edge.API.getErrorMessage(e));
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<TrackingConfigFormValues>) => (
						<Form>
							<ConfigLayout
								operationName={operationName}
								exerciseName="Tracking"
								headerActions={
									<>
										<Input type="submit" disabled={props.isSubmitting} value={startText} />
										{props.status && <Error>{props.status}</Error>}
										{FullScreenService.isEnabled() && (
											<FormField
												type="checkbox"
												name="fullScreen"
												description="View Full Screen"
											/>
										)}
									</>
								}
								instructions={instructions({ config: props.values, startText })}
								settings={
									<>
										<FormField
											component="select"
											name="durationSeconds"
											description="Session Duration"
											disabled={lockConfiguration}
										>
											<option value="60">1 minute</option>
											<option value="120">2 minutes</option>
											<option value="300">5 minutes</option>
											<option value="600">10 minutes</option>
										</FormField>
										<FormField
											component="select"
											name="size"
											description="Arrow Size"
											disabled={lockConfiguration}
										>
											<option value="1">Small</option>
											<option value="2">Medium</option>
											<option value="3">Large</option>
										</FormField>
										<FormField
											component="select"
											name="flashTimeMilliseconds"
											description="Flash Time"
											disabled={lockConfiguration}
										>
											<option value="100">0.1 seconds</option>
											<option value="300">0.3 seconds</option>
											<option value="600">0.6 seconds</option>
											<option value="900">0.9 seconds</option>
											<option value="1000">1 second</option>
											<option value="2000">2 seconds</option>
											<option value="3000">3 seconds</option>
										</FormField>
										<FormField
											component="select"
											name="fixation"
											description="Display Fixation"
											disabled={lockConfiguration}
										>
											<option value="true">On</option>
											<option value="false">Off</option>
										</FormField>
									</>
								}
								videoUrl={Edge.Constants.VIDEOS.TRACKING}
							/>
						</Form>
					)}
				/>
			</>
		);
	}
}
