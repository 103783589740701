import * as React from 'react';
import { Route } from 'react-router-dom';

import ConfigurationBanner from '../configurationBanner';
import TrainingBanner from '../trainingBanner';
import ScoreCard from '../scoreCard';
import TopPerformers from '../topPerformers';
import MainRow from '../../../layouts/baseLayouts/mainRow';
import TeamExercises from '../teamExercises';
import { Switch } from 'react-router';
import ScoutDashboard from '../scoutDashboard';

export class CoachDashboard extends React.Component {
	public render(): JSX.Element {
		return (
			<Switch>
				<Route path="/dashboard/scout" component={ScoutDashboard} />
				<>
					<MainRow>
						<ConfigurationBanner />
					</MainRow>
					<MainRow>
						<ScoreCard type="team" />
						<TopPerformers />
						<TeamExercises />
					</MainRow>
					<MainRow>
						<TrainingBanner />
					</MainRow>
				</>
			</Switch>
		);
	}
}

export default CoachDashboard;
