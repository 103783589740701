import * as React from 'react';
import * as _ from 'lodash';

import * as Edge from '../../../core';
import Error from '../../../components/global/error';
import Select from '../../../components/global/select';
import Input from '../../../components/global/input';
import { ModalContainer } from '../../../components/global/modal';
import { UserService } from '../../../services/userService';

import './index.scss';

export interface ChangeTeamProps {
	userTeamRoleBeingChanged: string;
	currentTeamId: string;
	teams: Edge.Models.Team[];
	reloadData: () => Promise<void>;
	onClose: () => void;
}

interface ChangeTeamState {
	newTeamId: string;
	error?: Edge.Models.EdgeError;
}

export class ChangeTeam extends React.PureComponent<ChangeTeamProps, ChangeTeamState> {
	constructor(props: ChangeTeamProps) {
		super(props);
		this.state = {
			newTeamId: this.props.currentTeamId,
		};
	}
	public render(): JSX.Element {
		const { teams } = this.props;
		const { error } = this.state;

		const disableSave = teams.length <= 1;

		return (
			<ModalContainer
				open={true}
				title="Change Team"
				onClose={() => {
					this.props.onClose();
				}}
			>
				<p>
					Note: This user can not have any valid credits remaining that are linked to his/her role with this
					team. If he/she does, please unassign them before attempting to move this user to another team.
				</p>
				<Select disabled={disableSave} value={this.state.newTeamId} onChange={this.teamChanged}>
					{_.sortBy(teams, 'name').map((i, ix) => (
						<option key={ix} value={i.id}>
							{i.name}
						</option>
					))}
				</Select>
				{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
				{disableSave && <Error>No other teams available.</Error>}
				<Input disabled={disableSave} onClick={this.changeTeam} value="Save" type="submit" />
			</ModalContainer>
		);
	}

	private changeTeam = async () => {
		this.setState({ error: undefined });
		try {
			await UserService.changeTeam({
				userTeamRoleId: this.props.userTeamRoleBeingChanged,
				newTeamId: this.state.newTeamId,
			});
			this.props.reloadData();
			this.props.onClose();
		} catch (error) {
			this.setState({ error });
		}
	};

	private teamChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		this.setState({ newTeamId: e.target.value });
	};
}

export default ChangeTeam;
