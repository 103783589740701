import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router';

import TermsForm, { TermsFormValues } from './termsForm';
import { ProfileService } from '../../services/profileService';
import { setAvatarRegistrationFlags } from '../../store/profile/actions';
import * as Edge from '../../core';

import './index.scss';

export interface TermsPageProps extends RouteComponentProps<TermsRouteProps> {
	setAvatarRegistrationFlags: (flags: Edge.Models.ProfileRegistrationFlags) => void;
}

interface TermsRouteProps {
	from?: string;
}

interface TermsPageState {
	isComplete?: boolean;
}

export class TermsPage extends React.PureComponent<TermsPageProps, TermsPageState> {
	constructor(props: TermsPageProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { match } = this.props;
		if (this.state.isComplete) {
			const target = match.params.from ? decodeURIComponent(match.params.from) : '/';
			return <Redirect to={target} />;
		}
		return (
			<div className="terms_page">
				<div className="title">
					<h4>Terms and Conditions</h4>
				</div>
				<div className="inner_wrapper">
					<p>Before using the system, you must agree to the following terms:</p>
					<TermsForm onSubmit={this.updateProfile} />
				</div>
			</div>
		);
	}
	private updateProfile = async (values: TermsFormValues) => {
		// update server, then update UI
		await ProfileService.updateMine(values);
		this.props.setAvatarRegistrationFlags(values);
		this.setState({ isComplete: true });
	};
}

export default connect(
	() => {
		return {};
	},
	{
		setAvatarRegistrationFlags,
	}
)(TermsPage);
