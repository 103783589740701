import * as Redux from 'redux';
import * as Edge from './../../core';
import {
	LoginState,
	LoginActionTypes,
	TokenResult,
	LOGIN_INPROGRESS,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	LOGOUT_SUCCESS,
} from './types';
import { AuthenticationService } from '../../services';

const initialState: LoginState = {
	authenticated: false,
	isLoading: false,
	token: '',
	decodedToken: undefined,
	error: '',
};

const loginReducer: Redux.Reducer<LoginState> = (state = initialState, action: LoginActionTypes): LoginState => {
	switch (action.type) {
		case LOGIN_INPROGRESS:
			return {
				...state,
				authenticated: false,
				isLoading: true,
				error: '',
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				authenticated: true,
				isLoading: false,
				token: ((action.payload || {}) as TokenResult).token,
				decodedToken: AuthenticationService.decodeToken(((action.payload || {}) as TokenResult).token),
				error: '',
			};
		case LOGIN_FAILURE:
			return {
				...state,
				authenticated: false,
				isLoading: false,
				error: Edge.API.getErrorMessage((action.payload || {}) as Error),
			};
		case LOGOUT_SUCCESS:
			return {
				...state,
				authenticated: false,
				isLoading: false,
				token: '',
				decodedToken: undefined,
				error: '',
			};
		default:
			return state;
	}
};

export default loginReducer;
