import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router';

import PageTitle from '../../components/pageTitle';
import { AppState } from '../../store';
import history from '../../services/history';
import * as Edge from '../../core';
import ErrorLoadingWrapper from '../../components/errorLoadingWrapper';
import { getTeams, setCurrentTeam } from '../../store/team/actions';
import { getPermissions } from '../../store/permissions/actions';
import TeamPicker from '../../components/teamPicker';
import CreateTeam from './createTeam';
import CreateTeamForm from './createTeamForm';
import EditTeam from './editTeam';
import ModalContainer from '../../components/global/modal';
import AppPageContainer from '../../components/pageContainer';

import './index.scss';
import AccessDenied from '../../components/global/accessDenied';
import { UserPermissionService } from '../../services/userPermissionService';
import { getUserPermissionService } from '../../store/permissions/selector';

export interface ManageTeamRouteProps {
	teamId?: string;
}

export interface ManageTeamsProps extends RouteComponentProps<ManageTeamRouteProps> {
	permissions: UserPermissionService;
	currentOrganization?: Edge.Models.Organization;
	globalCurrentTeam?: Edge.Models.Team;
	teams?: Edge.Models.Team[];
	isLoading?: boolean;
	loadingError?: Edge.Models.EdgeError;
	getPermissions: () => Promise<void>;
	getTeams: () => Promise<Edge.Models.Team[]>;
	setCurrentTeam: (team: Edge.Models.Team) => void;
}

interface ManageTeamsState {
	isCreatingTeam: boolean;
	insufficientCredits: boolean;
}

export class ManageTeams extends React.Component<ManageTeamsProps, ManageTeamsState> {
	constructor(props: ManageTeamsProps) {
		super(props);
		this.state = {
			isCreatingTeam: false,
			insufficientCredits: false,
		};
	}
	public componentDidMount() {
		if (!this.props.isLoading && !this.props.teams) {
			this.props.getTeams();
		}
		this.updateGlobalCurrentTeam();
	}
	public componentDidUpdate() {
		this.updateGlobalCurrentTeam();
	}
	public render(): JSX.Element {
		const { isLoading, loadingError, permissions } = this.props;

		return (
			<>
				<PageTitle title="Manage Teams" />
				<ErrorLoadingWrapper
					isLoading={isLoading || !this.props.teams || permissions.isLoading()}
					isErrored={!!loadingError}
					loadingOptions={{ blockItem: true }}
					render={() => (
						<AppPageContainer className="manageTeams">
							{this.renderBody(permissions)}
							{this.state.isCreatingTeam && (
								<ModalContainer title="Create Team" open={true} onClose={this.cancelCreateTeam}>
									<CreateTeamForm afterCreate={this.teamCreated} />
								</ModalContainer>
							)}
						</AppPageContainer>
					)}
				/>
			</>
		);
	}
	private updateGlobalCurrentTeam = () => {
		const { globalCurrentTeam, match, teams, permissions } = this.props;
		const { teamId } = match.params;

		if (teams && permissions) {
			const viewableTeams = teams.filter(
				permissions.hasTeamAccess.bind(this, Edge.Models.PermissionLevels.Details)
			);
			const team = viewableTeams.filter((i) => i.id === teamId)[0];
			if (team && !(globalCurrentTeam && globalCurrentTeam.id === team.id)) {
				this.props.setCurrentTeam(team);
			}
		}
	};
	private redirectToTeam(team: Edge.Models.Team) {
		return <Redirect to={`/manage-teams/${team.id}`} />;
	}
	private renderBody = (permissions: UserPermissionService) => {
		const { globalCurrentTeam, match, teams, currentOrganization } = this.props;
		const { teamId } = match.params;

		const viewableTeams = teams!.filter(permissions.hasTeamAccess.bind(this, Edge.Models.PermissionLevels.Details));
		const team = viewableTeams!.filter((i) => i.id === teamId)[0];
		const globalTeam = viewableTeams!.filter((i) => globalCurrentTeam && i.id === globalCurrentTeam.id)[0];
		const canCreateTeam =
			permissions.hasOrganizationAccess(Edge.Models.PermissionLevels.Edit, currentOrganization) || undefined;

		if (!team) {
			// they didn't specify a valid team, let's see if we can redirect to a valid one
			if (globalTeam) {
				return this.redirectToTeam(globalTeam);
			}
			if (viewableTeams.length > 0) {
				// let's just pick the first one (maybe we should have some 'default team' logic at some point?)
				return this.redirectToTeam(viewableTeams[0]);
			}

			// no teams available - make them create a new one if they have the rights to
			if (!canCreateTeam) {
				return <AccessDenied />;
			}
			return <CreateTeam createTeam={this.createTeam} />;
		}

		// ok, now we know we have a selected team - check security and render the page
		// if we ever add a readonly role for teams, we need to make sure this page works in read mode, but for now, just protect it
		if (!permissions.hasTeamAccess(Edge.Models.PermissionLevels.Edit, team)) {
			return <AccessDenied />;
		}

		return (
			<>
				<div className="intro_cta_label">Select Team</div>
				<TeamPicker
					currentTeam={team}
					teams={viewableTeams}
					setCurrentTeam={this.setTeam}
					createTeam={canCreateTeam && this.createTeam}
				/>
				<EditTeam
					team={team}
					permission={permissions.getTeamAccess(team)}
					insufficientCredits={() => this.setState({ insufficientCredits: true })}
				/>
				{this.state.insufficientCredits && (
					<ModalContainer
						open={true}
						title="Insufficient Credits"
						onClose={this.closeInsufficientCreditsModal}
					>
						<p>
							You have invited more users than you have sessions/seats. Some of the users invited will not
							receive a session/seat. Please purchase more and assign them to those users so that they can
							use the Edge Trainer platform.
						</p>
						<button
							className="cta_btn insufficient_credits_btn"
							onClick={this.closeInsufficientCreditsModal}
						>
							OK
						</button>
					</ModalContainer>
				)}
			</>
		);
	};
	private setTeam = (team: Edge.Models.Team) => {
		history.push(`/manage-teams/${team.id}`);
	};
	private createTeam = () => {
		// delay this a tiny bit so this event is fully-processed before the dialog pops up
		setTimeout(() => {
			this.setState({ isCreatingTeam: true });
		}, 50);
	};
	private cancelCreateTeam = () => {
		this.setState({ isCreatingTeam: false });
	};
	private teamCreated = async (team: Edge.Models.Team) => {
		const { getTeams } = this.props;
		const teams = await getTeams();
		await this.props.getPermissions();
		const newTeam = teams.filter((i) => i.id === team.id)[0];
		this.props.setCurrentTeam(newTeam);
		this.setState({ isCreatingTeam: false });
		this.setTeam(newTeam);
	};
	private closeInsufficientCreditsModal = () => {
		this.setState({ insufficientCredits: false });
	};
}

function mapStateToProps(state: AppState) {
	const { organizationState, teamState } = state;
	const { isLoading, loadingError } = teamState;
	const { currentTeam, teams } = teamState;
	const { currentOrganization } = organizationState;

	return {
		isLoading,
		loadingError,
		currentOrganization,
		permissions: getUserPermissionService(state),
		teams: currentOrganization && teams && teams.filter((i) => i.organizationId === currentOrganization!.id),
		globalCurrentTeam: currentTeam,
	};
}

export default connect(
	mapStateToProps,
	{
		getPermissions,
		getTeams,
		setCurrentTeam,
	}
)(ManageTeams);
