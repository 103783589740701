import * as Edge from './../../core';

export interface ProfileState {
	profile?: Edge.Models.Profile;
	isLoading: boolean;
	loadingError?: Edge.Models.EdgeError;
}

export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILURE = 'PROFILE_FAILURE';
export const PROFILE_SET_AVATAR_URL = 'PROFILE_SET_AVATAR_URL';
export const PROFILE_SET_REGISTRATION_FLAGS = 'PROFILE_SET_REGISTRATION_FLAGS';

interface ProfilesLoading extends Edge.Models.EdgeAction<undefined, typeof PROFILE_LOADING> {
	type: typeof PROFILE_LOADING;
}

interface ProfilesSuccess extends Edge.Models.EdgeAction<Edge.Models.Profile, typeof PROFILE_SUCCESS> {
	type: typeof PROFILE_SUCCESS;
	payload: Edge.Models.Profile;
}

interface ProfilesFailure extends Edge.Models.EdgeAction<Edge.Models.EdgeError, typeof PROFILE_FAILURE> {
	type: typeof PROFILE_FAILURE;
	payload: Edge.Models.EdgeError;
}

interface ProfileSetAvatarUrl extends Edge.Models.EdgeAction<string, typeof PROFILE_SET_AVATAR_URL> {
	type: typeof PROFILE_SET_AVATAR_URL;
	payload: string;
}

interface ProfileSetRegistrationFlags
	extends Edge.Models.EdgeAction<Edge.Models.ProfileRegistrationFlags, typeof PROFILE_SET_REGISTRATION_FLAGS> {
	type: typeof PROFILE_SET_REGISTRATION_FLAGS;
	payload: Edge.Models.ProfileRegistrationFlags;
}

export type ProfileActionTypes =
	| ProfilesLoading
	| ProfilesSuccess
	| ProfilesFailure
	| ProfileSetAvatarUrl
	| ProfileSetRegistrationFlags;
