import * as Edge from './../core';

export abstract class PositionService {
	public static async getAll(): Promise<Edge.Models.Position[]> {
		const route = Edge.API.ServerRoute.forAction(`positions`);
		return await Edge.API.get(route);
	}

	public static async getAllForSport(sportId: string): Promise<Edge.Models.Position[]> {
		const route = Edge.API.ServerRoute.forAction(`positions/${sportId}`);
		return await Edge.API.get(route);
	}
}
