import * as React from 'react';
import * as _ from 'lodash';

export interface NumberPagingProps<T> {
	pageSize: number;
	items: T[];
	render: (items: T[]) => React.ReactNode;
}

interface NumberPagingState {
	currentPage: number; // base 0
	pageChanged: boolean;
}

export class NumberPaging<T> extends React.PureComponent<NumberPagingProps<T>, NumberPagingState> {
	constructor(props: NumberPagingProps<T>) {
		super(props);
		this.state = {
			currentPage: 0,
			pageChanged: false,
		};
	}

	public render() {
		const { toRender, currentPage, pageCount } = this.itemsToRender();
		const { showFirstLast, rangeMin, rangeMax } = this.paramsCalc(currentPage, pageCount);

		// renders nothing if there's no need for pagination
		if (pageCount <= 1) return this.props.render(toRender);

		return (
			<React.Fragment>
				{this.props.render(toRender)}
				<div className="edge-table-footer see_more_paging">
					{showFirstLast && (
						<button disabled={currentPage <= 0} onClick={this.setPage.bind(this, 0)}>
							&lt;&lt; First
						</button>
					)}
					<button disabled={currentPage <= 0} onClick={this.setPage.bind(this, currentPage - 1)}>
						&lt; Prev
					</button>
					{_.range(rangeMin, rangeMax).map((page) => (
						<button
							key={page}
							className="number"
							disabled={currentPage === page}
							onClick={this.setPage.bind(this, page)}
						>
							{page + 1}
						</button>
					))}
					<button disabled={currentPage >= pageCount - 1} onClick={this.setPage.bind(this, currentPage + 1)}>
						Next &gt;
					</button>
					{showFirstLast && (
						<button
							disabled={currentPage >= pageCount - 1}
							onClick={this.setPage.bind(this, pageCount - 1)}
						>
							Last &gt;&gt;
						</button>
					)}
				</div>
			</React.Fragment>
		);
	}

	private setPage = (currentPage: number) => {
		let { items, pageSize } = this.props;
		const pageCount = Math.ceil(items.length / pageSize);

		this.setState(
			{
				currentPage: Math.max(0, Math.min(currentPage, pageCount - 1)),
				pageChanged: true,
			},
			this.forceScrollToBottom
		);
	};

	// Force scroll to bottom to prevent page jumping around when navigating
	//  from a non-fully populated page to a populated page.
	private forceScrollToBottom = () => {
		const { pageChanged } = this.state;

		if (pageChanged) {
			window.scrollTo(0, document.body.scrollHeight);
			this.setState({ pageChanged: false });
		}
	};

	private itemsToRender = () => {
		let { items, pageSize } = this.props;
		let { currentPage } = this.state;
		const pageCount = Math.ceil(items.length / pageSize);
		currentPage = Math.max(0, Math.min(currentPage, pageCount - 1));

		const toRender = items.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

		return { toRender, currentPage, pageCount };
	};

	private paramsCalc = (currentPage: number, pageCount: number) => {
		const btnLimit = 9; // Always use odd number here

		const showFirstLast = pageCount > btnLimit;
		const rangeMin = Math.min(Math.max(0, pageCount - btnLimit), Math.max(0, currentPage - (btnLimit - 1) / 2));
		const rangeMax = Math.min(pageCount, Math.max(btnLimit, currentPage + (btnLimit + 1) / 2));

		return { showFirstLast, rangeMin, rangeMax };
	};
}

export default NumberPaging;
