import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as _ from 'lodash';

import { AppState } from '../../../store';
import { activeSessionSelector } from '../../../store/session/selector';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import * as Edge from '../../../core';
import { getAllTeams } from '../../../store/permissions/selector';
import { setCurrentOrganization } from '../../../store/organization/actions';
import { setCurrentTeam } from '../../../store/team/actions';
import { Redirect } from 'react-router';
import ErrorDisplay from '../../../components/error/errorDisplay';
import Loading from '../../../components/loading';

export interface RedirectToCurrentProps {
	isLoading: boolean;
	loadingError?: Edge.Models.EdgeError;
	status: Edge.Models.ActiveSessionResponse;
	getRemainingTime: () => moment.Duration | undefined;

	trainingPlanResult?: Edge.Models.TrainingPlanResult;
	gameDayPlanResult?: Edge.Models.GameDayPlanResult;

	currentOrganization: Edge.Models.Organization;
	organizations: Edge.Models.Organization[];
	teams: Edge.Models.Team[];
	currentTeam?: Edge.Models.Team;
	allTeams: Edge.Models.Team[];
	setCurrentOrganization: (organization: Edge.Models.Organization) => void;
	setCurrentTeam: (team: Edge.Models.Team) => void;
}

interface RedirectToCurrentState {
	linkTarget?: string;
	error?: Edge.Models.EdgeError;
}

/**
 * Redirects a user to the screen where their current session is launched from
 */
export class RedirectToCurrent extends React.PureComponent<RedirectToCurrentProps, RedirectToCurrentState> {
	constructor(props: RedirectToCurrentProps) {
		super(props);
		this.state = {};
	}
	public componentDidMount() {
		this.doUpdate();
	}
	public componentDidUpdate(prevProps: RedirectToCurrentProps) {
		if (!_.isEqualWith(this.props, prevProps, (v1, v2) => v1 === v2)) {
			this.doUpdate();
		}
	}
	private doUpdate() {
		const {
			status,
			currentOrganization,
			organizations,
			currentTeam,
			allTeams,
			setCurrentOrganization,
			setCurrentTeam,
			getRemainingTime,
			trainingPlanResult,
			gameDayPlanResult,
		} = this.props;
		if (status && allTeams && organizations) {
			if (status.active) {
				const remaining = getRemainingTime();
				if (remaining && remaining.asSeconds() > 0) {
					const targetTeam = allTeams.filter((i) => i.id === status.active!.teamId)[0];
					if (targetTeam) {
						if (currentOrganization && currentOrganization.id === targetTeam.organizationId) {
							if (currentTeam && currentTeam.id === targetTeam.id) {
								// we're on the right team and org - do the redirect
								const linkTarget =
									status.active.sessionTypeId === Edge.Models.SessionTypeId.Evaluate
										? '/training/evaluation'
										: status.active.planPhaseId && status.active.completionPercent < 1
										? trainingPlanResult &&
										  trainingPlanResult.currentPlanPhaseId === status.active.planPhaseId
											? '/training/myPlan'
											: gameDayPlanResult &&
											  gameDayPlanResult.currentPlanPhaseId === status.active.planPhaseId
											? '/training/gameDay'
											: '/training/openGym'
										: '/training/openGym';
								this.setState({ linkTarget, error: undefined });
								return;
							}

							// right org, wrong team, set the team
							setCurrentTeam(targetTeam);
							return;
						}

						// wrong org, set the org
						const organization = organizations.filter((i) => i.id === targetTeam.organizationId)[0];
						if (organization) {
							setCurrentOrganization(organization);
							return;
						}

						this.setState({ error: new Error(`Cannot find organization ${targetTeam.organizationId}`) });
						return;
					}

					this.setState({ error: new Error(`Cannot find team ${status.active!.teamId}`) });
					return;
				}
			}

			if (currentTeam && currentTeam.type.id === Edge.Models.TeamTypeId.StationMode) {
				this.setState({
					linkTarget: '/training/openGym',
					error: undefined,
				});
				return;
			}
			// no active session - link back to MyPlan (even if you don't have a plan - we want you to get prompted to create one)
			this.setState({
				linkTarget: '/training/myPlan',
				error: undefined,
			});
			return;
		}

		// we don't have the info we need yet, so just keep showing the loading thing
	}

	public render() {
		const { linkTarget, error } = this.state;

		if (linkTarget) {
			return <Redirect to={linkTarget} />;
		}
		if (error) {
			return <ErrorDisplay message={Edge.API.getErrorMessage(error)} />;
		}

		return <Loading />;
	}
}

function mapStateToProps(state: AppState) {
	const { isLoading, loadingError, data, getRemainingTime } = activeSessionSelector(state);
	const { teams, currentTeam } = getAllTeams(state);

	return {
		isLoading,
		loadingError,
		status: data!,
		getRemainingTime,
		currentOrganization: state.organizationState.currentOrganization!,
		organizations: state.organizationState.organizations!,
		teams: teams!,
		currentTeam,
		allTeams: state.teamState.teams!,
	};
}

export default connect(
	mapStateToProps,
	{
		setCurrentOrganization,
		setCurrentTeam,
	}
)(errorLoadingWrapperHOC(RedirectToCurrent, (props) => !!props.status && !!props.currentOrganization && !!props.teams));
