import * as React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import * as Edge from '../../core';
import { AppState } from '../../store';
import { setCurrentTeam } from '../../store/team/actions';
import { getUserPermissionService, getTeamsUserCanEval } from '../../store/permissions/selector';
import AccessDenied from '../../components/global/accessDenied';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import Evaluation from './evaluation';
import TrainingWithPlans from './trainingWithPlans';
import { AuthenticatedLayoutRoute } from '../../components/global/routing/authenticatedLayoutRoute';
import EmptyLayout from '../../layouts/emptyLayout';

export interface TrainingProps extends RouteComponentProps<{ teamId?: string }> {
	evaluatableTeams?: Edge.Models.Team[];
}

export class Training extends React.Component<TrainingProps> {
	public render(): JSX.Element {
		const { evaluatableTeams } = this.props;
		return (
			<Switch>
				{evaluatableTeams && evaluatableTeams.length > 0 && (
					<Route path="/training/evaluation" component={Evaluation} />
				)}

				<AuthenticatedLayoutRoute layout={EmptyLayout} onlyNormalUsers component={TrainingWithPlans} />

				<Route component={AccessDenied} />
			</Switch>
		);
	}
}

function mapStateToProps(state: AppState) {
	const { organizationState, teamState } = state;
	const { teams: evaluatableTeams } = getTeamsUserCanEval(state);
	const permissions = getUserPermissionService(state);
	return {
		isLoading: organizationState.isLoading || teamState.isLoading || !permissions.ready(),
		isLoadingError: organizationState.loadingError || teamState.loadingError || permissions.loadingError(),
		evaluatableTeams,
	};
}

export default connect(
	mapStateToProps,
	{
		setCurrentTeam,
	}
)(
	errorLoadingWrapperHOC(Training, (props) => !!props.evaluatableTeams, undefined, undefined, {
		loadingOptions: { blockItem: true },
	})
);
