import * as React from 'react';
import * as _ from 'lodash';
import classNames from 'classnames';

import './index.scss';

export interface RenderTableHeaderArgs {
	collapseColumn: () => React.ReactNode;
}
export interface RenderTableItemArgs extends RenderTableHeaderArgs {
	toggleCallback: () => void;
	isFirstRow: boolean;
}

export interface CollapsibleTableProps<T, G> {
	className?: string;
	sort: (item1: T) => any;
	sortDirection?: 'asc' | 'desc';
	groupKey: (item: T) => G;
	groupSort: (groupKey: G) => any;
	items: T[];
	renderHeader?: () => React.ReactNode;
	renderTableHeader: (args: RenderTableHeaderArgs) => React.ReactNode;
	renderTableItem: (item: T, args: RenderTableItemArgs) => React.ReactNode;
	renderNoItems?: () => React.ReactNode;
}

interface CollapsibleTableState {
	expanded: number[];
}

export class CollapsibleTable<T, G> extends React.Component<CollapsibleTableProps<T, G>, CollapsibleTableState> {
	constructor(props: CollapsibleTableProps<T, G>) {
		super(props);
		this.state = {
			expanded: [],
		};
	}

	public render() {
		const { className } = this.props;
		const { expanded } = this.state;
		const toRender = this.groupsToRender();
		return (
			<div className={classNames('edge_table_collapsible', className)}>
				{this.props.renderHeader && (
					<div className="edge-table-header">
						<h2>{this.props.renderHeader()}</h2>
					</div>
				)}
				<table className="edge_table_collapsible_table">
					<thead>{this.props.renderTableHeader({ collapseColumn: this.renderButtonHeader })}</thead>
					{toRender.map((g, gix) => {
						const isExpanded = expanded.some((i) => i === gix);
						return (
							<tbody key={gix} className={classNames({ expanded: isExpanded })}>
								{g.map((i, ix) => {
									const toggleCallback = (isExpanded ? this.collapse : this.expand).bind(this, gix);
									const collapseColumn =
										ix === 0
											? this.renderButton.bind(this, isExpanded, toggleCallback)
											: this.renderButtonPlaceholder;
									const args: RenderTableItemArgs = {
										collapseColumn,
										toggleCallback,
										isFirstRow: ix === 0,
									};
									return (
										<React.Fragment key={ix}>{this.props.renderTableItem(i, args)}</React.Fragment>
									);
								})}
							</tbody>
						);
					})}
					{toRender.length === 0 && this.props.renderNoItems && <tbody>{this.props.renderNoItems()}</tbody>}
				</table>
			</div>
		);
	}

	private renderButtonHeader = () => {
		return <th className="expand_collapse" />;
	};
	private renderButton = (isExpanded: boolean, toggleCallback: () => void) => {
		return (
			<td className="expand_collapse">
				<button
					className="cta_btn btn_light"
					onClick={toggleCallback}
					title={isExpanded ? 'collapse' : 'expand'}
				/>
			</td>
		);
	};
	private renderButtonPlaceholder = () => {
		return <td className="expand_collapse" />;
	};
	private expand = (ix: number) => {
		this.setState({
			expanded: [...this.state.expanded, ix],
		});
	};
	private collapse = (ix: number) => {
		this.setState({
			expanded: this.state.expanded.filter((i) => i !== ix),
		});
	};

	private groupsToRender = () => {
		const { items, groupKey, groupSort, sort, sortDirection } = this.props;

		const groups: T[][] = _.values(_.groupBy(items, groupKey)).map((i) =>
			_.orderBy<T>(i, sort, sortDirection || 'asc')
		);
		const sortedGroups: T[][] = _.sortBy<T[]>(groups, (i) => groupSort(groupKey(i[0])));

		return sortedGroups;
	};
}

export default CollapsibleTable;
