import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../../core';
import { AppState } from '../../../store';
import TeamStatsTable from './teamStatsTable';
import { CanEditTeamsBanner } from '../../../components/teamBanner';
import DownloadButton from '../../../components/downloadButton';
import { StatsService } from '../../../services/statsService';
import moment from 'moment';

interface CoachStatsProps {
	currentTeam?: Edge.Models.Team;
	teams: Edge.Models.Team[];
}

export class CoachStats extends React.Component<CoachStatsProps> {
	public render(): JSX.Element {
		const { currentTeam } = this.props;

		return (
			<>
				<CanEditTeamsBanner>
					<DownloadButton
						defaultFileName={`stats_${currentTeam && currentTeam.id}_${moment(new Date()).format(
							Edge.Constants.FILE_DATE_FORMAT
						)}.csv`}
						onClick={() => {
							return StatsService.getTeamMemberStatsCsv(currentTeam!.id);
						}}
					>
						Download CSV
					</DownloadButton>
				</CanEditTeamsBanner>
				<TeamStatsTable />
			</>
		);
	}
}

function mapStateToProps({ teamState }: AppState) {
	return { currentTeam: teamState.currentTeam };
}

export default connect(mapStateToProps)(CoachStats);
