import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Edge from '../../core';
import FormField from '../../components/formField';
import Input from '../../components/global/input';
import Error from '../../components/global/error';
import Loading from '../../components/loading';
import { UserService } from '../../services/userService';
import { ReactComponent as Logo } from '../../assets/images/VZELogoLogin.svg';

import '../forgot/index.scss';

export interface ResetPasswordRouteProps {
	code: string;
	userId: string;
}

export interface ResetPasswordFormValues {
	password: string;
	confirmPassword: string;
}

export interface ResetPasswordProps extends RouteComponentProps<ResetPasswordRouteProps> {
	initialValues?: Partial<ResetPasswordFormValues>;
}

export interface ResetPasswordState {
	success: boolean;
}

const schema = Yup.object().shape({
	password: Edge.Validation.password().required('This field is required.'),
	confirmPassword: Yup.string()
		.required('This field is required.')
		.oneOf([Yup.ref('password')], 'Passwords must match.'),
});

export class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
	constructor(props: ResetPasswordProps) {
		super(props);
		this.state = {
			success: false,
		};
	}

	public render() {
		return (
			<div className="reset_password forgot">
				<div className="logo">
					<div className="icon">
						<Logo />
					</div>
					<h6>Vizual Edge</h6>
				</div>
				{this.state.success ? (
					<>
						<div className="forgot_title">Password Reset Successful!</div>
						<div className="forgot_message">
							You can now use your new password to log into your account.
						</div>
						<Link to="/login">Login</Link>
					</>
				) : (
					<>
						<div className="forgot_message">Reset Your Password</div>
						<Formik
							initialValues={Object.assign(
								{},
								{
									/** anything not specified here won't show an error message after an attempted submit */
									password: '',
									confirmPassword: '',
								},
								this.props.initialValues || {}
							)}
							validationSchema={schema}
							onSubmit={async (values, actions) => {
								actions.setStatus(undefined);
								try {
									const { userId, code } = this.props.match.params;
									await UserService.resetPassword({ newPassword: values.password, userId, code });
									this.setState({ success: true });
								} catch (e) {
									actions.setStatus({ error: Edge.API.getErrorMessage(e) });
								}
								actions.setSubmitting(false);
							}}
							render={(props: FormikProps<Partial<ResetPasswordFormValues>>) => (
								<Form>
									<FormField
										component="input"
										type="password"
										name="password"
										label="New Password"
										placeholder="Enter a new password"
									/>
									<FormField
										component="input"
										type="password"
										name="confirmPassword"
										label="Confirm New Password"
										placeholder="Confirm your new password"
									/>
									{props.isSubmitting && <Loading buttonLoader />}
									<Input type="submit" disabled={props.isSubmitting} value="submit" />
									{props.status && props.status.error && <Error>{props.status.error}</Error>}
								</Form>
							)}
						/>
					</>
				)}
			</div>
		);
	}
}

export default ResetPassword;
