/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-script-url */
import * as React from 'react';
import { BaseExercise, BaseExerciseProps } from '../baseExercise';
import * as Edge from '../../../core';
import ExerciseLayout from '../../../layouts/exerciseLayout';

declare var VizEdge: any;
declare var $: any;

interface FlexibilityProps
	extends BaseExerciseProps<Edge.Models.FlexibilityExerciseResult, Edge.Models.FlexibilityExerciseConfiguration> {}

interface FlexibilityConfiguration {
	fullScreen: boolean;
	level: string; // integer
	imageScale: 'small' | 'large';
	sessionDuration: number; // seconds
}

interface FlexibilityResult {
	exercise: 'flexibility';
	evaluation: boolean;
	exerciseType: type;
	size: 'small' | 'large';
	duration: number; // seconds
	level: number;
	audio: boolean;
	conv: FlexibilityPartResult;
	div: FlexibilityPartResult;
}

interface FlexibilityPartResult {
	scorePercent: number; // out of 100
	stationScore: number;
	stationMax: number;
}

type type = 'alternating' | 'convergence' | 'divergence';

function typeToId(type: type) {
	switch (type) {
		case 'alternating':
			return Edge.Models.ExerciseTypeId.Alternating;
		case 'convergence':
			return Edge.Models.ExerciseTypeId.Convergence;
		case 'divergence':
			return Edge.Models.ExerciseTypeId.Divergence;
		default:
			return undefined;
	}
}

function idToType(id: Edge.Models.ExerciseTypeId): type | undefined {
	switch (id) {
		case Edge.Models.ExerciseTypeId.Alternating:
			return 'alternating';
		case Edge.Models.ExerciseTypeId.Convergence:
			return 'convergence';
		case Edge.Models.ExerciseTypeId.Divergence:
			return 'divergence';
		default:
			return undefined;
	}
}

export class Flexibility extends BaseExercise<
	FlexibilityProps,
	Edge.Models.FlexibilityExerciseResult,
	Edge.Models.FlexibilityExerciseConfiguration,
	{}
> {
	createSession() {
		const { configuration } = this.props;
		return this.doCreateSession(
			this.props.mode,
			idToType(configuration.exerciseTypeId) || 'alternating',
			this.props.debugMode ? 'true' : 'false',
			this.props.renderScale,
			this.props.touchControls,
			{
				fullScreen: this.props.fullScreen,
				level: configuration.level.toString(),
				imageScale: ['', 'small', '', 'large'][configuration.size] as any,
				sessionDuration: configuration.durationSeconds,
			},
			this.completeExercise
		);
	}

	/**
	 * Typed wrapper over `new VizEdge.FlexibilityExercise`.
	 */
	private doCreateSession = (
		mode: 'train' | 'evaluate',
		exerciseType: type,
		debugMode: 'true' | 'false',
		renderScale: number,
		touchControls: boolean,
		configuration: FlexibilityConfiguration,
		completeExercise: (result: FlexibilityResult) => void
	) => {
		return new VizEdge.FlexibilityExercise(
			mode,
			exerciseType,
			debugMode,
			renderScale,
			touchControls,
			{ buzzer: this.buzzerSound, crystal: this.crystalSound },
			configuration,
			completeExercise
		);
	};

	private completeExercise = (result: FlexibilityResult) => {
		const { configuration, completeExercise } = this.props;

		completeExercise({
			exerciseTypeId: typeToId(result.exerciseType),
			audio: result.audio,
			exerciseConfigurationId: configuration.id,
			level: configuration.level,
			size: result.size === 'large' ? Edge.Models.ExerciseSize.Large : Edge.Models.ExerciseSize.Small,
			durationSeconds: result.duration,

			stationMax: result.conv ? result.conv.stationMax : result.div ? result.div.stationMax : 0,
			convergenceCorrectPercent: result.conv ? result.conv.scorePercent / 100 : undefined,
			divergenceCorrectPercent: result.div ? result.div.scorePercent / 100 : undefined,
			convergenceStationScore: result.conv ? result.conv.stationScore : undefined,
			divergenceStationScore: result.div ? result.div.stationScore : undefined,

			// N/A for this one
			responseTimeMilliseconds: 0,
			correctPercent: 0,

			// #1396: https://dev.azure.com/vizualedge/Edge%20Trainer/_backlogs/backlog/Edge%20Trainer%20Team/Stories/?workitem=1396
			// TODO: rework this to avoid the cast
		} as Edge.Models.FlexibilityExerciseResult);
	};

	public render(): JSX.Element {
		const { configuration } = this.props;
		const exerciseName = idToType(configuration.exerciseTypeId) || 'Alternating Flexibility';

		return (
			<ExerciseLayout
				endExercise={this.endSession}
				completeExercise={this.completeSession}
				exerciseName={exerciseName}
			>
				<div id="images">
					<img id="blue-down1" src="/images/blue-down1.png" alt="Blue down1" />
					<img id="blue-down2" src="/images/blue-down2.png" alt="Blue down2" />
					<img id="blue-left1" src="/images/blue-left1.png" alt="Blue left1" />
					<img id="blue-left2" src="/images/blue-left2.png" alt="Blue left2" />
					<img id="blue-right1" src="/images/blue-right1.png" alt="Blue right1" />
					<img id="blue-right2" src="/images/blue-right2.png" alt="Blue right2" />
					<img id="blue-up1" src="/images/blue-up1.png" alt="Blue up1" />
					<img id="blue-up2" src="/images/blue-up2.png" alt="Blue up2" />

					<img id="red-down1" src="/images/red-down1.png" alt="Red down1" />
					<img id="red-down2" src="/images/red-down2.png" alt="Red down2" />
					<img id="red-left1" src="/images/red-left1.png" alt="Red left1" />
					<img id="red-left2" src="/images/red-left2.png" alt="Red left2" />
					<img id="red-right1" src="/images/red-right1.png" alt="Red right1" />
					<img id="red-right2" src="/images/red-right2.png" alt="Red right2" />
					<img id="red-up1" src="/images/red-up1.png" alt="Red up1" />
					<img id="red-up2" src="/images/red-up2.png" alt="Red up2" />
					<img id="dpad-arrow" src="/images/dpad-arrow.png" alt="Dpad arrow" />
				</div>
			</ExerciseLayout>
		);
	}
}
