import * as React from 'react';
import enhanceWithClickOutside from 'react-click-outside';

import OrgDropdown from './orgDropdown';
import * as Edge from '../../../core';
import { Avatar } from '../../avatar';

interface HeaderState {
	isActive: boolean;
}

export interface OrgSectionProps {
	organizations?: Edge.Models.Organization[];
	currentOrganization?: Edge.Models.Organization;
	setCurrentOrganization: (organization: Edge.Models.Organization) => void;
}

export class OrgSection extends React.Component<OrgSectionProps, HeaderState> {
	constructor(props: OrgSectionProps) {
		super(props);
		this.state = { isActive: false };
	}
	handleClickOutside = () => {
		this.setState({ isActive: false });
	};

	toggleActive = () => {
		this.setState({ isActive: !this.state.isActive });
	};
	public render(): JSX.Element {
		const { organizations, currentOrganization, setCurrentOrganization } = this.props;
		return (
			<div
				className={`header_section header_dropdownbtn header_org ${this.state.isActive ? 'active' : ''}`}
				onClick={this.toggleActive}
			>
				{currentOrganization ? (
					<>
						<Avatar src={currentOrganization.logoUrl} />
						<span className="org_name">{currentOrganization.name}</span>
						<div className="dropdown_chevron" />
						<OrgDropdown
							organizations={organizations}
							currentOrganization={currentOrganization}
							setCurrentOrganization={setCurrentOrganization}
						/>
					</>
				) : null}
			</div>
		);
	}
}

export default enhanceWithClickOutside(OrgSection);
