/* eslint-disable */
$( function() {
	
	VizEdge.DPadControl = function() {
	    this.initialize.apply(this, arguments);
	};
	
	_.extend( VizEdge.DPadControl.prototype, {
                            
		initialize: function( options ) {			

      // constants
      this.directionButtonColor = "#3266cc";
      this.okButtonColor = 'green';
      
      this.dPadMode = ( options && options.mode == 'left-right' ) ? 'left-right' : 'cardinal';
      this.showOK = ( options && options.showOK == false ) ? false : true; 
      
      this.render();
      
      this.handlers = ( options && options.handlers ) ? options.handlers : {};
            
      _.bindAll( this, "update" );
      
      var tool = ( options && options.tool ) ? options.tool : new paper.Tool();
      tool.on('mousedown', this.update );
      tool.on('mouseup', this.update );
      tool.on('mousemove', this.update );
      tool.activate(); 
                
		},
        
    render: function() {
      var currentLayer = paper.project.activeLayer;
            
      // keep our rendering in a separate layer
      this.dPadLayer = new paper.Layer();
      this.dPadLayer.activate();
      
      var buttonRadius = 30;
      var buttonStrokeWidth = 4;
      var dPadWidth = buttonRadius * 3.5;
      var dPadHeight = buttonRadius * 3.5;
      var viewBounds = paper.project.view.bounds;
      var dPadCenter = new paper.Point( viewBounds.right - dPadWidth, viewBounds.bottom - dPadHeight - 30 );
      
      var leftX = dPadCenter.x - (dPadWidth/2);
      var centerX = dPadCenter.x;
      var rightX = dPadCenter.x + (dPadWidth/2);
      var topY = dPadCenter.y - (dPadHeight/2);
      var middleY = dPadCenter.y;
      var bottomY = dPadCenter.y + (dPadHeight/2);
      
      this.dPadBounds = new paper.Rectangle( leftX, topY, dPadWidth*2, dPadHeight*2 )
      
      var rotationStep;
      
      if( this.dPadMode == 'cardinal' ) {
        this.buttons = {
            right:  new paper.Shape.Circle( new paper.Point(rightX, middleY), buttonRadius ),
            up:     new paper.Shape.Circle( new paper.Point(centerX, topY), buttonRadius ),
            left:   new paper.Shape.Circle( new paper.Point(leftX, middleY), buttonRadius ),
            down:   new paper.Shape.Circle( new paper.Point(centerX, bottomY), buttonRadius )
        };
        rotationStep = 90;
      } else {
        this.buttons = {
            right:  new paper.Shape.Circle( new paper.Point(rightX, middleY), buttonRadius ),
            left:   new paper.Shape.Circle( new paper.Point(leftX, middleY), buttonRadius )
        };
        rotationStep = 180;
      }

      // use this group to calc the bounding box 
      this.buttonGroup = new paper.Group(_.values(this.buttons));
      
      // OK Button
      if( this.showOK ) {
        this.okButton = new paper.Shape.Circle( new paper.Point(viewBounds.left + buttonRadius*2, viewBounds.bottom - dPadHeight - 30), buttonRadius*1.5 )
        this.okButton.strokeColor = this.okButtonColor;
        this.okButton.strokeWidth = buttonStrokeWidth;
      
      
        // OK button text
        this.okText = new paper.PointText(this.okButton.position.x, this.okButton.position.y+5);
        this.okText.justification = 'center';
        this.okText.fillColor = 'white';
        this.okText.content = "OK";
        this.okText.fontSize = 14;

        this.buttonGroup.addChild(this.okButton);
      }
                  
      // add arrows and color
      this.arrows = {}; 
      var buttonRotation = 0;
      _.each( this.buttons, function( button, direction ) {
        button.strokeColor = this.directionButtonColor;
        button.strokeWidth = buttonStrokeWidth;
        
        var arrow = new paper.Raster( "dpad-arrow" );
        arrow.rotate(buttonRotation);
        arrow.position = button.position;
        this.arrows[direction] = arrow;
        
        // rotate counter clockwise 
        buttonRotation -= rotationStep;        
      }, this); 
      
      // return to the current layer
      currentLayer.activate();
      
    },
    
    intersects: function( targetBounds ) {
      return this.dPadBounds.intersects(targetBounds);
    },
    
    isActive: function( button ) {
      return this.mouseDown && this.activeButton == button;      
    },
    
    update: function( event ) {

      if( event.type == 'mousedown' ) {
        this.mouseDown = true;
      } else if( event.type == 'mouseup' ) {
        this.mouseDown = false;
      }
      
      // respond to arrow buttons
      this.activeButton = null;
      _.each( this.buttons, function( button, direction ) {
        if( this.mouseDown && button.contains(event.point) ) {
          button.fillColor = this.directionButtonColor;
          button.strokeColor = 'white';
          this.activeButton = direction;
          // only activate when mouse is first depressed, not on move
          if( event.type == 'mousedown' && this.handlers[direction] ) this.handlers[direction]();
        } else {
          button.fillColor = 'black';
          button.strokeColor = this.directionButtonColor;
        }
      }, this);
      
      // respond to the OK button
      if( this.showOK ) {
        if( this.mouseDown && this.okButton.contains(event.point) ){
          this.okButton.fillColor = this.okButtonColor;
          this.okButton.strokeColor = 'white';
          this.activeButton = 'ok';
          if( this.handlers.ok ) this.handlers.ok();
        } else {
          this.okButton.fillColor = 'black';
          this.okButton.strokeColor = this.okButtonColor;
        }
      }
      
    }
                        					
	});
	
});
			
