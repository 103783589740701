import * as React from 'react';

import * as Edge from '../../../core';
import Error from '../../../components/global/error';
import Input from '../../../components/global/input';

import './index.scss';

export interface ChangeEventPageUrlProps {
	changeEventPageUrl: (newEventPageUrl: string) => Promise<void>;
	currentEventPageUrl?: string;
}

interface ChangeEventPageUrlState {
	error?: Edge.Models.EdgeError;
	newEventPageUrl: string;
}

export class ChangeEventPageUrl extends React.PureComponent<ChangeEventPageUrlProps, ChangeEventPageUrlState> {
	constructor(props: ChangeEventPageUrlProps) {
		super(props);
		this.state = {
			newEventPageUrl: this.props.currentEventPageUrl || '',
		};
	}
	public render(): JSX.Element {
		const { newEventPageUrl, error } = this.state;

		return (
			<>
				<p>Change this Event Team's Event Page URL.</p>
				<Input
					ref="editEventPageUrlInput"
					type="text"
					maxLength={100}
					value={newEventPageUrl}
					onChange={this.organizationChanged}
				/>
				{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
				<Input onClick={this.changeEventPageUrl} value="Save" type="submit" />
			</>
		);
	}

	private changeEventPageUrl = async () => {
		try {
			await this.props.changeEventPageUrl(this.state.newEventPageUrl);
		} catch (error) {
			this.setState({ error });
		}
	};

	private organizationChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ newEventPageUrl: e.target.value });
	};
}

export default ChangeEventPageUrl;
