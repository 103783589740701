import * as React from 'react';
import MainRow from '../../../layouts/baseLayouts/mainRow';
import { CanAddProspectTeamsBanner } from '../../../components/teamBanner';
import CreateProspect from '../createProspect';
import ProspectEvaluationHistory from '../prospectEvaluationHistory';
import DeviceProfiles from '../../training/deviceProfiles';

export class ScoutDashboard extends React.Component {
	public render(): JSX.Element {
		return (
			<>
				<MainRow>
					<CanAddProspectTeamsBanner>
						<div>
							<DeviceProfiles />
						</div>
					</CanAddProspectTeamsBanner>
				</MainRow>
				<MainRow>
					<CreateProspect />
					<ProspectEvaluationHistory />
				</MainRow>
			</>
		);
	}
}

export default ScoutDashboard;
