import * as React from 'react';
import { AppState } from '../../../store';
import { connect } from 'react-redux';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import * as Edge from '../../../core';
import { completeExercise } from '../../../store/session/actions';
import { Link } from 'react-router-dom';
import { getDeviceProfiles } from '../../../store/deviceProfile/actions';
import { SessionService } from '../../../services/sessionService';
import DeviceProfileList from '../deviceProfiles/list';
import ScrollToTopOnMount from '../../../components/global/scrollToTopOnMount';

// Tag: New Exercise
// Create a new directory for each new exercise: `src/pages/exercises/${newExercise}`.
// Each exercise must at least contain files configForm, index, results, ${newExercise}. Follow pattern
//  used in Contrast exercises (which may mean splitting the ${newExercise} file into two).
import Align from '../../exercises/align';
import ContrastSensitivity from '../../exercises/contrastSensitivity';
import ContrastTracking from '../../exercises/contrastTracking';
import Depth from '../../exercises/depth';
import Flexibility from '../../exercises/flexibility';
import Pursuits from '../../exercises/pursuits';
import Recognition from '../../exercises/recognition';
import Tracking from '../../exercises/tracking';

export interface RenderExerciseProps {
	activeSessionResponse: Edge.Models.ActiveSessionResponse;
	completeText: string;
	currentProfile?: Edge.Models.DeviceProfile;
	exercise: Edge.Models.ExerciseConfiguration;
	gameMode?: Edge.Models.GameMode;
	hideExerciseResults: boolean;
	operationName: string;
	onComplete: () => void;
	onAbort: () => void;
	completeExercise: (command: Edge.Models.CompleteExerciseCommand) => Promise<void>;
}

interface RenderExerciseState {}

export class RenderExercise extends React.PureComponent<RenderExerciseProps, RenderExerciseState> {
	constructor(props: RenderExerciseProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const {
			activeSessionResponse,
			currentProfile,
			exercise,
			completeText,
			hideExerciseResults,
			operationName,
		} = this.props;
		if (!activeSessionResponse.active) {
			return (
				<div className="no-active-session">
					You don't have an active training or evaluation session.
					<Link to="/training">Go to a team</Link> to start one.
				</div>
			);
		}

		if (!currentProfile) {
			return (
				<>
					<ScrollToTopOnMount />
					<p>Please select your device, or create a new one.</p>
					<DeviceProfileList />
				</>
			);
		}

		const sharedProps = {
			key: exercise.id || exercise.exerciseTypeId,
			currentProfile,
			completeText,
			mode:
				activeSessionResponse.active.sessionTypeId.toLowerCase() === Edge.Models.SessionTypeId.Evaluate
					? Edge.Models.SessionType.Evaluate
					: Edge.Models.SessionType.Train,
			completeExercise: this.completeExercise,
			abortExercise: this.props.onAbort,
			createResult: this.createResult,
			lockConfiguration: !!exercise.id,
			operationName,
		};

		// Tag: New Exercise
		// Add switch case entry.
		switch (exercise.exerciseTypeId) {
			case Edge.Models.ExerciseTypeId.Alignment:
				return (
					<Align initialConfiguration={exercise} hideExerciseResults={hideExerciseResults} {...sharedProps} />
				);
			case Edge.Models.ExerciseTypeId.ContrastSensitivity:
				return (
					<ContrastSensitivity
						initialConfiguration={exercise}
						hideExerciseResults={hideExerciseResults}
						{...sharedProps}
					/>
				);
			case Edge.Models.ExerciseTypeId.ContrastTracking:
				return (
					<ContrastTracking
						initialConfiguration={exercise}
						hideExerciseResults={hideExerciseResults}
						{...sharedProps}
					/>
				);
			case Edge.Models.ExerciseTypeId.Depth:
				return (
					<Depth initialConfiguration={exercise} hideExerciseResults={hideExerciseResults} {...sharedProps} />
				);
			case Edge.Models.ExerciseTypeId.Alternating:
			case Edge.Models.ExerciseTypeId.Convergence:
			case Edge.Models.ExerciseTypeId.Divergence:
				return (
					<Flexibility
						initialConfiguration={exercise}
						hideExerciseResults={hideExerciseResults}
						{...sharedProps}
					/>
				);
			case Edge.Models.ExerciseTypeId.Pursuits:
				return (
					<Pursuits
						initialConfiguration={exercise}
						hideExerciseResults={hideExerciseResults}
						{...sharedProps}
					/>
				);
			case Edge.Models.ExerciseTypeId.Recognition:
				return (
					<Recognition
						initialConfiguration={exercise}
						hideExerciseResults={hideExerciseResults}
						{...sharedProps}
					/>
				);
			case Edge.Models.ExerciseTypeId.Tracking:
				return (
					<Tracking
						initialConfiguration={exercise}
						hideExerciseResults={hideExerciseResults}
						{...sharedProps}
					/>
				);
			default:
				return <div>Not implemented yet</div>;
		}
	}

	private createResult = async <T extends Edge.Models.ExerciseResult>(
		exerciseResult: T
	): Promise<T & Edge.Models.CreateExerciseResultCommand> => {
		const sessionId =
			this.props.activeSessionResponse &&
			this.props.activeSessionResponse.active &&
			this.props.activeSessionResponse.active!.sessionId;
		if (!sessionId) {
			throw new Error('There is no active session');
		}
		const gameMode = this.props.gameMode;
		const command = Object.assign(
			{
				sessionId,
				complete: !exerciseResult.exerciseConfigurationId,
				gameMode,
			},
			exerciseResult
		) as Edge.Models.CreateExerciseResultCommand;
		const result = await SessionService.createExerciseResult(command);
		return result as T & Edge.Models.CreateExerciseResultCommand;
	};

	private completeExercise = async <T extends Edge.Models.ExerciseResult>(command: T): Promise<void> => {
		const sessionId =
			this.props.activeSessionResponse &&
			this.props.activeSessionResponse.active &&
			this.props.activeSessionResponse.active!.sessionId;
		if (!sessionId) {
			throw new Error('There is no active session');
		}
		if (command.exerciseConfigurationId) {
			await this.props.completeExercise({
				sessionId,
				exerciseConfigurationId: command.exerciseConfigurationId,
				gameMode: command.gameMode,
			});
		}
		this.props.onComplete();
	};
}

function mapStateToProps({ sessionState, deviceProfileState, teamState }: AppState) {
	return {
		isLoading: sessionState.isLoading || deviceProfileState.isLoading,
		loadingError: sessionState.loadingError || deviceProfileState.loadingError,
		sessionIsLoading: sessionState.isLoading,
		deviceProfileIsLoading: deviceProfileState.isLoading,
		activeSessionResponse: sessionState.data!,
		profiles: deviceProfileState.profiles,
		currentProfile: deviceProfileState.currentProfile,
		hideExerciseResults: !!teamState.currentTeam && teamState.currentTeam.hideExerciseResults,
	};
}

interface LoadingProps {
	sessionIsLoading: boolean;

	deviceProfileIsLoading: boolean;
	profiles?: Edge.Models.DeviceProfile[];
	getDeviceProfiles: () => void;
}

export default connect(
	mapStateToProps,
	{
		getDeviceProfiles,
		completeExercise,
	}
)(
	errorLoadingWrapperHOC<RenderExerciseProps, RenderExerciseProps & LoadingProps>(
		RenderExercise,
		(props) =>
			!props.sessionIsLoading &&
			!!props.activeSessionResponse &&
			!props.deviceProfileIsLoading &&
			!!props.profiles
	)
);
