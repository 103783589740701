import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// the above lines must be the first ones in this file - https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill#internet-explorer-11

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Edge from './core';
import App from './App';
import { unregister } from './serviceWorker';

import './assets/fonts/fonts.css';
import './index.scss';

import './services/analyticsService';

if (process.env.NODE_ENV !== 'production') {
	console.log(Edge.Constants.Company + ': Configuration', Edge.Configuration);
}

// render application
ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

// get rid of any orphaned service worker registration
unregister();
