import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import * as Edge from '../../../core';
import { getTeamsUserCanTrain } from '../../../store/permissions/selector';
import { CanTrainTeamsBanner } from '../../../components/teamBanner';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import ExerciseList from '../exerciseList';
import StartButton from '../startButton';
import AppPageContainer from '../../../components/pageContainer';
import PageTitle from '../../../components/pageTitle';
import TrainingAreaNav from '../trainingAreaNav';
import RenderExercise from '../renderExercise';
import DeviceProfiles from '../deviceProfiles';
import { ExerciseMetadataService } from '../../../services/exerciseMetadataService';
import { AppState } from '../../../store';
import { activeSessionSelector } from '../../../store/session/selector';
import SessionExpiredModal from '../../../components/sessionExpiredModal';

export interface OpenGymTrainingProps {
	currentTeam?: Edge.Models.Team;
	getRemainingTime: (now?: Date) => moment.Duration | undefined;
}

interface OpenGymTrainingState {
	currentExercise?: Edge.Models.ExerciseConfiguration;
	sessionExpired?: boolean;
}

export class OpenGymTraining extends React.PureComponent<OpenGymTrainingProps, OpenGymTrainingState> {
	constructor(props: OpenGymTrainingProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { currentTeam } = this.props;
		const { currentExercise, sessionExpired } = this.state;
		if (currentExercise) {
			return (
				<RenderExercise
					completeText="Continue Training"
					gameMode={Edge.Models.GameMode.OpenGym}
					exercise={currentExercise}
					onComplete={this.onComplete}
					onAbort={this.onComplete} /* we treat abort and complete the same */
					operationName="Training"
				/>
			);
		}
		return (
			<>
				<PageTitle title="Open Gym" />
				<TrainingAreaNav />
				<AppPageContainer>
					<DeviceProfiles />
					<CanTrainTeamsBanner />
					{currentTeam && this.renderBody()}
					{sessionExpired && <SessionExpiredModal onClose={this.closeSessionExpired} />}
				</AppPageContainer>
			</>
		);
	}
	private closeSessionExpired = () => {
		this.setState({ sessionExpired: false });
	};
	private onComplete = () => {
		const remaining = this.props.getRemainingTime();
		this.setState({ currentExercise: undefined, sessionExpired: !(remaining && remaining.asMilliseconds() > 0) });
	};
	private renderBody = () => {
		return (
			<div className="open-gym">
				<h3>Choose your exercise</h3>
				<ExerciseList
					configs={ExerciseMetadataService.getOpenGymExercises()}
					renderExerciseLineButton={(config: Edge.Models.ExerciseConfiguration) => (
						<StartButton isTraining onClick={() => this.trainNow(config)} />
					)}
				/>
			</div>
		);
	};
	private trainNow = (config: Edge.Models.ExerciseConfiguration) => {
		this.setState({
			currentExercise: config,
		});
	};
}

function mapStateToProps(state: AppState) {
	const { currentTeam } = getTeamsUserCanTrain(state);
	const { getRemainingTime } = activeSessionSelector(state);
	return {
		currentTeam,
		getRemainingTime,
	};
}

export default connect(mapStateToProps)(errorLoadingWrapperHOC(OpenGymTraining));
