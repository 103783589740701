import * as React from 'react';
import * as _ from 'lodash';

import * as Edge from '../../core';
import { getPriceTitle } from '../../utilities/commerceUtil';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { CommerceService } from '../../services/commerceService';
import Input from '../../components/global/input';
import Loading from '../../components/loading';
import Error from '../../components/global/error';
import Info from '../../components/global/info';

import './availableSkus.scss';

interface AvailablePricesProps {
	organization: Edge.Models.Organization;
	response: Edge.Models.FullCommerceRestrictionsResponse[];
}

interface AvailablePricesState {
	prices: Edge.Models.FullCommerceRestrictionsResponse[];
	loading: boolean;
	success: boolean;
	error?: Edge.Models.EdgeError;
}

export class AvailablePrices extends React.Component<AvailablePricesProps, AvailablePricesState> {
	constructor(props: AvailablePricesProps) {
		super(props);
		this.state = {
			prices: props.response,
			loading: false,
			success: false,
		};
	}

	public render(): JSX.Element {
		const { prices, loading, success, error } = this.state;

		return (
			<div className="available_products">
				<h2>Available Products</h2>
				<table>
					<thead>
						<tr>
							<th>Prices</th>
							<th>Enabled</th>
							<th>Discount Code</th>
						</tr>
					</thead>
					<tbody>
						{this.orderPrices(prices).map((item, index) => {
							return (
								<tr key={index}>
									<td>{getPriceTitle(item.price)}</td>
									<td>
										<Input
											type="checkbox"
											checked={item.active}
											onChange={this.updateActive.bind(this, item.price.id)}
										/>
									</td>
									<td>
										<Input
											type="text"
											value={item.discount ? item.discount.discountCode : ''}
											onChange={this.updateDiscountCode.bind(this, item.price.id)}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
				{loading && <Loading buttonLoader />}
				<button className="cta_btn" onClick={this.savePriceRestrictions}>
					Save
				</button>
				{success && <Info>Price settings for {this.props.organization.name} were updated successfully.</Info>}
			</div>
		);
	}

	private updateDiscountCode = (priceId: string, event: React.ChangeEvent<HTMLInputElement>) => {
		const previousModel = this.state.prices;
		previousModel.filter((price) => price.price.id === priceId)[0].discount = {
			discountCode: event.target.value,
		};
		this.setState({ prices: previousModel, success: false });
	};

	private updateActive = (priceId: string, event: React.ChangeEvent<HTMLInputElement>) => {
		const previousModel = this.state.prices;
		previousModel.filter((price) => price.price.id === priceId)[0].active = event.target.checked;
		this.setState({ prices: previousModel, success: false });
	};

	private orderPrices = (response: Edge.Models.FullCommerceRestrictionsResponse[]) => {
		// first order by number of units, then by type
		return _.orderBy(
			_.orderBy(response, (i) =>
				i.price.priceType === Edge.Models.PriceTypes.Subscription
					? i.price.months
					: i.price.priceType === Edge.Models.PriceTypes.Sessions
					? i.price.sessions
					: undefined
			),
			(i) => i.price.priceType
		);
	};

	private savePriceRestrictions = async () => {
		const priceDiscount: Edge.Models.PriceDiscountPair[] = this.state.prices
			.filter((price) => price.active)
			.map((item) => {
				return {
					priceId: item.price.id,
					discountCode: item.discount && item.discount.discountCode ? item.discount.discountCode : undefined,
				};
			});

		this.setState({ loading: true, error: undefined, success: false });
		try {
			await CommerceService.updateOrganizationAllowedPrices({
				organizationId: this.props.organization.id,
				priceDiscount: priceDiscount,
			});
			this.setState({ loading: false, success: true });
		} catch (error) {
			this.setState({ error, loading: false });
		}
	};
}

export default withLoadDataDefaultConfig(
	errorLoadingWrapperHOC(AvailablePrices),
	(props: AvailablePricesProps) => {
		return { organizationId: props.organization.id };
	},
	async (props) => {
		const response = await CommerceService.getFullOrganizationAllowedPrices(props.organizationId);
		return { response };
	}
);
