import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as Edge from '../../../core';
import { AppState } from '../../../store';

import './dropdown.scss';
import './acctDropdown.scss';

interface AcctDropdownProps {
	currentOrganization?: Edge.Models.Organization;
}

export class AcctDropdown extends React.Component<AcctDropdownProps> {
	public render(): JSX.Element {
		const { currentOrganization } = this.props;
		return (
			<div className="header_dropdown acct_dropdown">
				{currentOrganization && currentOrganization.userPurchasable && (
					<Link to="/my-account/manage-plan" className="header_dropdown_item">
						Upgrade / Add Sessions
					</Link>
				)}
				<Link to="/my-account" className="header_dropdown_item">
					My Account
				</Link>
				<Link to="/logout" className="header_dropdown_item logout">
					Logout
				</Link>
			</div>
		);
	}
}

function mapStateToProps({ organizationState }: AppState) {
	return { currentOrganization: organizationState.currentOrganization };
}

export default connect(mapStateToProps)(AcctDropdown);
