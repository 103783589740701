import React, { useEffect, useState } from 'react';

import PurchaseGlasses from '../../../components/purchaseGlasses';
import { CommerceService } from '../../../services/commerceService';
import * as Edge from '../../../core';
import { CheckoutSession } from '../index';

interface GlassesOptionProps {
	checkout: CheckoutSession;
	setError: React.Dispatch<React.SetStateAction<Edge.Models.EdgeError | undefined>>;
}

export default function GlassesOption(props: GlassesOptionProps) {
	const [url, setUrl] = useState<string>();

	const { checkout, setError } = props;

	useEffect(() => {
		if (url) {
			window.location.href = url;
		}
	}, [url]);

	return (
		<PurchaseGlasses
			initialValues={{
				glassesNumber: checkout.glassesQuantity || 0,
			}}
			onSubmit={async (values) => {
				try {
					const checkoutSession = await CommerceService.createCheckoutSession({
						glassesQuantity: values.glassesNumber,
						price: checkout.price,
						quantity: checkout.quantity,
						entityType: checkout.entityType,
						entityId: checkout.entityId,
						organizationId: checkout.organizationId,
						checkoutSessionType: Edge.Models.CheckoutSessionType.Initial,
						successUrl: checkout.successUrl,
						cancelUrl: checkout.cancelUrl,
						country: values.country,
					});

					setUrl(checkoutSession.url);
				} catch (error) {
					setError(error);
				}
			}}
		/>
	);
}
