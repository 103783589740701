import * as ReduxThunk from 'redux-thunk';
import * as Edge from './../../core';
import { AppState } from './..';
import { PermissionService } from './../../services/permissionService';

import { PERMISSIONS_LOADING, PERMISSIONS_SUCCESS, PERMISSIONS_FAILURE } from './types';

// action creators
export const loadPermissionsAction = () => {
	return {
		type: PERMISSIONS_LOADING,
	};
};

export const permissionsSuccessAction = (permissions: Edge.Models.AssignedRoles) => {
	return {
		type: PERMISSIONS_SUCCESS,
		payload: permissions,
	};
};

export const permissionsFailureAction = (error: Edge.Models.EdgeError) => {
	return {
		type: PERMISSIONS_FAILURE,
		payload: error,
		error: true,
	};
};

// thunk actions
export const getPermissions = (): ReduxThunk.ThunkAction<
	Promise<void>,
	AppState,
	null,
	Edge.Models.EdgeAction
> => async (dispatch) => {
	dispatch(loadPermissionsAction());

	try {
		const roles = await PermissionService.getMyRoles();
		dispatch(permissionsSuccessAction(roles));
	} catch (error) {
		dispatch(permissionsFailureAction(error));
	}
};
