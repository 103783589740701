import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../../core';
import Table from '../../../components/table';
import { Avatar } from '../../../components/avatar';
import AssignCredits from '../../../components/assignCredits';
import { TeamService } from '../../../services/teamService';
import Select from '../../../components/global/select';
import TeamMembersTableHeader from './tableHeader';
import AdminMenu from '../../../components/adminMenu';
import ChangeTeam from '../changeTeam';
import { AppState } from '../../../store';
import RemoveTrainingPlan from '../removeTrainingPlan';
import EditUser from '../../admin/editUser';
import EmailUser from '../emailUser/emailUser';
import { UserPermissionService } from '../../../services/userPermissionService';
import { getUserPermissionService } from '../../../store/permissions/selector';

class TeamMembersTable extends Table<Edge.Models.TeamMember> {}

export interface TeamMembersProps {
	team: Edge.Models.Team;
	teamMembers: Edge.Models.TeamMember[];
	removeTeamMember: (teamMember: Edge.Models.TeamMember) => void;
	addAthlete: () => void;
	addScout: () => void;
	isProspectOnly: boolean;
	positions: Edge.Models.Position[];
	permissions: UserPermissionService;
	isAdmin?: boolean;
	teams?: Edge.Models.Team[];
	reloadData: () => Promise<void>;
}

interface TeamMembersState {
	search?: string;
	changingPositions: string[];
	changedPositions: { [id: string]: Edge.Models.Position | undefined };
	changedPositionErrors: { [id: string]: Edge.Models.EdgeError | undefined };
	emailingUser?: string; // the UserId of the user being emailed
	changingTeam?: string; // the UserTeamRoleId being changed
	editingAthlete?: Edge.Models.ManuallyEditedUser; // the user being edited
	removingTrainingPlan?: string; // the UserId whose plan is being removed
}

// searching/sorting by position is problematic due to the way we're handling edits, and it's not specifically requested, so ignoring for now
const availableSearches: ((item: Edge.Models.TeamMember) => string | undefined)[] = [
	(item) => item.user && item.user.firstName,
	(item) => item.user && item.user.lastName,
];
const availableSorts: { [x: string]: (item: Edge.Models.TeamMember) => any } = {
	teamMember: (item) => item.user && item.user.lastName + ', ' + item.user.firstName,
};

function canHavePosition(item: Edge.Models.TeamMember) {
	return item.teamRole && !(item.teamRole.name === 'Coach' || item.teamRole.name === 'Scout');
}

export class TeamMembers extends React.Component<TeamMembersProps, TeamMembersState> {
	constructor(props: TeamMembersProps) {
		super(props);
		this.state = {
			changingPositions: [],
			changedPositions: {},
			changedPositionErrors: {},
		};
	}
	public render() {
		const { teamMembers, team, positions, isProspectOnly, permissions, isAdmin, teams } = this.props;
		const {
			changingPositions,
			changedPositions,
			changedPositionErrors,
			emailingUser,
			changingTeam,
			editingAthlete,
			removingTrainingPlan,
		} = this.state;

		const viewableTeams = teams!.filter(permissions.hasTeamAccess.bind(this, Edge.Models.PermissionLevels.Details));
		const canEdit = permissions.hasTeamAccess(Edge.Models.PermissionLevels.Edit, team);

		return (
			<>
				<AssignCredits
					sourceEntityId={team.id}
					sourceEntityType="Team"
					destinationEntityType="UserTeamRole"
					render={(props) => {
						return (
							<TeamMembersTable
								items={teamMembers}
								availableSearches={availableSearches}
								availableSorts={availableSorts}
								initialSort={['teamMember']}
								renderHeader={() => <TeamMembersTableHeader team={team} />}
								searchPlaceholderText="Search Users"
								renderSubHeader={() => (
									<>
										{props.renderHeader()}
										{props.renderError()}
									</>
								)}
								renderTableHeader={(classNames, toggleSort) => {
									return (
										<tr>
											<th
												className={classNames('teamMember')}
												onClick={toggleSort.bind(this, 'teamMember')}
											>
												Team Member
											</th>
											<th>Position</th>
											<th className="mobileHide">
												{props.mode === 'sessions' ? 'Sessions' : 'Seat'}
											</th>
											<th className="mobileHide">Last Session</th>
											<th className="mobileHide">Last Evaluation</th>
											<th className="mobileHide" />
										</tr>
									);
								}}
								renderTableItem={(i) => {
									const position = (i.user && changedPositions[i.user.id]) || i.position;
									const isPositionChanging = changingPositions.some(
										(ii) => !!i.user && ii === i.user.id
									);
									const error = i.user && changedPositionErrors[i.user.id];
									return (
										<tr>
											<td className="avatar_container">
												{i && i.user ? (
													<>
														<Avatar src={i.user.avatarUrl} />
														<span>
															{i.user.lastName}, {i.user.firstName}
														</span>
													</>
												) : (
													<>{i.email}</>
												)}
											</td>
											<td>
												{i && !i.user ? (
													<>Invited</>
												) : i && !canHavePosition(i) ? (
													i.teamRole && i.teamRole.name
												) : (
													<Select
														error={!!error}
														title={error && Edge.API.getErrorMessage(error)}
														value={(position && position.id) || ''}
														onChange={this.changePosition.bind(this, i)}
														disabled={isPositionChanging}
													>
														<option hidden disabled value="" />
														{_.orderBy(positions, (i) => i.name).map((i, ix) => (
															<option key={ix} value={i.id}>
																{i.name}
															</option>
														))}
													</Select>
												)}
											</td>
											<td className="mobileHide">{props.renderItem(i.id)}</td>
											<td className="mobileHide">
												{i.lastSession && moment(i.lastSession).format('M/DD/YYYY')}
											</td>
											<td className="mobileHide">
												{i.lastEvaluation && moment(i.lastEvaluation).format('M/DD/YYYY')}
											</td>
											<td className="mobileHide">
												<AdminMenu>
													<div
														className="menu_item"
														onClick={() =>
															this.setState({ emailingUser: i.user && i.user.id })
														}
													>
														Email User
													</div>
													{canEdit && (
														<div
															className="menu_item"
															onClick={() => {
																this.setState({ changingTeam: i.id });
															}}
														>
															Change Team
														</div>
													)}
													<div
														className="menu_item"
														onClick={this.props.removeTeamMember.bind(this, i)}
													>
														Remove from Team
													</div>
													{canEdit && (
														<div
															className="menu_item"
															onClick={() => {
																this.setState({
																	editingAthlete: { user: i.user!, email: i.email },
																});
															}}
														>
															Edit User
														</div>
													)}
													{isAdmin && (
														<div
															className="menu_item"
															onClick={() => {
																this.setState({
																	removingTrainingPlan: i.user && i.user.id,
																});
															}}
														>
															Remove Training Plan
														</div>
													)}
												</AdminMenu>
											</td>
										</tr>
									);
								}}
								renderNoItems={() => (
									<tr>
										<td colSpan={7}>
											{isProspectOnly ? (
												<button className="addAthletes" onClick={this.props.addScout}>
													+ Add scout(s)
												</button>
											) : (
												<button className="addAthletes" onClick={this.props.addAthlete}>
													+ Add athlete(s)
												</button>
											)}
										</td>
									</tr>
								)}
								pagingMode="seeMore"
								seeMoreText="See more athletes"
							/>
						);
					}}
				/>
				{emailingUser && (
					<EmailUser
						entityId={this.props.team.id}
						entityType={Edge.Models.EntityType.Team}
						userId={emailingUser}
						onClose={() => this.setState({ emailingUser: undefined })}
					/>
				)}
				{canEdit && changingTeam && (
					<ChangeTeam
						userTeamRoleBeingChanged={changingTeam}
						currentTeamId={this.props.team.id}
						teams={viewableTeams}
						reloadData={this.props.reloadData}
						onClose={() => {
							this.setState({ changingTeam: undefined });
						}}
					/>
				)}
				{canEdit && editingAthlete && (
					<EditUser
						user={editingAthlete.user}
						email={editingAthlete.email}
						reloadData={this.props.reloadData}
						onClose={() => {
							this.setState({ editingAthlete: undefined });
						}}
					/>
				)}
				{isAdmin && removingTrainingPlan && (
					<RemoveTrainingPlan
						userId={removingTrainingPlan}
						onClose={() => {
							this.setState({ removingTrainingPlan: undefined });
						}}
					/>
				)}
			</>
		);
	}

	private changePosition = async (member: Edge.Models.TeamMember, e: React.ChangeEvent<HTMLSelectElement>) => {
		const { positions, team } = this.props;

		const position = positions.filter((i) => i.id === e.target.value)[0];
		if (!position) {
			return;
		}
		const oldPosition = member.position;
		const user = member.user;
		if (!user) {
			return;
		}
		try {
			this.setState({
				changingPositions: [...this.state.changingPositions, user.id],
				changedPositions: Object.assign({}, this.state.changedPositions, {
					[user.id]: position,
				}),
				changedPositionErrors: Object.assign({}, this.state.changedPositions, {
					[user.id]: undefined,
				}),
			});
			await TeamService.assignUserToPosition({
				teamId: team.id,
				userTeamRoleId: member.id,
				positionId: position.id,
			});
			this.setState({
				changingPositions: _.difference(this.state.changingPositions, [user.id]),
			});
		} catch (e) {
			this.setState({
				changingPositions: _.difference(this.state.changingPositions, [user.id]),
				changedPositions: Object.assign({}, this.state.changedPositions, {
					[user.id]: oldPosition,
				}),
				changedPositionErrors: Object.assign({}, this.state.changedPositions, {
					[user.id]: e,
				}),
			});
		}
	};
}

function mapStateToProps(state: AppState) {
	const { loginState, teamState } = state;

	const isAdmin = loginState.decodedToken && loginState.decodedToken.is_admin;
	const currentOrganizationId = teamState.currentTeam && teamState.currentTeam.organizationId;

	const teams = teamState.teams && teamState.teams.filter((team) => team.organizationId === currentOrganizationId);

	return {
		isAdmin,
		permissions: getUserPermissionService(state),
		teams,
	};
}

export default connect(mapStateToProps)(TeamMembers);
