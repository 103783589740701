import * as React from 'react';
import ScaleIndicator, { scaleToPixels } from './scaleIndicator';

import './index.scss';

export interface RenderScaleProps {
	value: number;
	onChange: (value: number) => void;
}

const delta = 1 / scaleToPixels;
const min = 50 / scaleToPixels;
const max = 300 / scaleToPixels;

export class RenderScale extends React.PureComponent<RenderScaleProps> {
	constructor(props: RenderScaleProps) {
		super(props);
		this.state = {};
	}
	public render() {
		return (
			<div className="render_scale_field">
				<ScaleIndicator scale={this.props.value} />
				<div className="render_scale_field_btns">
					<button onClick={this.decrement}>-</button>
					<button onClick={this.increment}>+</button>
				</div>
			</div>
		);
	}
	private decrement = (e: React.MouseEvent) => {
		e.preventDefault();
		this.props.onChange(Math.max(min, this.props.value - delta));
	};
	private increment = (e: React.MouseEvent) => {
		e.preventDefault();
		this.props.onChange(Math.min(max, this.props.value + delta));
	};
}
export default RenderScale;
