import classNames from 'classnames';
import * as React from 'react';
import { matchPath, RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as _ from 'lodash';

import './index.scss';

export class PageSwitcher extends React.Component<RouteComponentProps> {
	public render() {
		const pages = _.range(1, 6).map((p) => `/register/page-${p}`);
		const isOnPage = pages.map((p) => !!matchPath(this.props.location.pathname, { path: p }));
		const shouldShow = pages.map((i, ix) => _.some(isOnPage.slice(ix)));
		// you can never navigate back to the first page
		if (!isOnPage[0]) {
			shouldShow[0] = false;
		}
		return (
			<ul className="register-page-switcher">
				{pages.map((p, ix) => (
					<li key={ix}>
						<NavLink
							to={p}
							activeClassName="active circle"
							className={classNames({ disabled: !shouldShow[ix] })}
						/>
					</li>
				))}
			</ul>
		);
	}
}

export default withRouter(PageSwitcher);
