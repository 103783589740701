import * as React from 'react';
import { Link } from 'react-router-dom';

import './noPlan.scss';

export interface NoPlanProps {}

export const NoPlan: React.FunctionComponent<NoPlanProps> = (props) => (
	<>
		<p className="no_plan_message">
			You don't have a training plan yet - once you complete an evaluation, you'll be assigned a training plan and
			be able to use Game Day and Open Gym.
		</p>
		<Link to="/training/evaluation" className="cta_btn no_plan_btn">
			Take Evaluation
		</Link>
	</>
);
export default NoPlan;
