import * as React from 'react';
import _ from 'lodash';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Edge from '../../../core';
import Error from '../../../components/global/error';
import FormField from '../../../components/formField';
import Input from '../../../components/global/input';
import Loading from '../../../components/loading';

export interface LoginProspectFormProps {
	onSubmit: (values: LoginProspectFormValues) => Promise<void>;
	prospects?: Edge.Models.TeamProspect[];
}

export interface LoginProspectFormValues {
	prospectUserId: string;
}

const LoginProspectForm = (props: LoginProspectFormProps) => {
	const loginProspectSchema = () =>
		Yup.object().shape({
			prospectUserId: Yup.string().required('Prospect is required'),
		});

	const { onSubmit, prospects } = props;
	return (
		<Formik
			initialValues={Object.assign(
				{},
				{
					/** anything not specified here won't show an error message after an attempted submit */
					prospectUserId: '',
				}
			)}
			validationSchema={loginProspectSchema()}
			onSubmit={async (values, actions) => {
				actions.setStatus(undefined);
				console.log(values.prospectUserId);
				try {
					await onSubmit(values);
				} catch (e) {
					const message = Edge.API.getErrorMessage(e);
					actions.setStatus(message);
				}
				actions.setSubmitting(false);
			}}
			render={(props: FormikProps<LoginProspectFormValues>) => (
				<Form>
					<FormField component="select" name="prospectUserId" description="Prospect" defaultValue={undefined}>
						<option value={undefined}>Select Prospect</option>
						{_.orderBy(prospects, (p) => `${p.lastName}, ${p.firstName}`).map((p) => (
							<option key={p.id} value={p.id}>
								{`${p.lastName}, ${p.firstName} (${p.redactedEmail})`}
							</option>
						))}
					</FormField>
					{props.status && <Error>{props.status}</Error>}
					{props.isSubmitting && <Loading buttonLoader />}
					<Input type="submit" disabled={props.isSubmitting} value="login" />
				</Form>
			)}
		/>
	);
};

export default LoginProspectForm;
