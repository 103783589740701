import * as React from 'react';

import './index.scss';

export interface CardRegisterProps {}

const CardRegister: React.SFC<CardRegisterProps> = (props) => {
	return (
		<div className="registerCard">
			<div className="registerCard_wrapper">{props.children}</div>
		</div>
	);
};

export default CardRegister;
