import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Edge from '../../../core';
import FormField from '../../../components/formField';
import Error from '../../../components/global/error';
import Loading from '../../../components/loading';
import ModalContainer from '../../../components/global/modal';
import { TeamService } from '../../../services/teamService';

export interface EmailUserProps {
	entityId: string;
	entityType: Edge.Models.EntityType;
	userId: string;
	initialValues?: EmailUserValues;
	onClose: () => void;
}

export interface EmailUserValues {
	message: string;
}

const emailSchema = Yup.object({
	message: Yup.string().required('Message is required'),
});

export default class EmailUser extends React.PureComponent<EmailUserProps> {
	public render(): JSX.Element {
		const { initialValues, onClose } = this.props;
		return (
			<ModalContainer open={true} title="Email User" onClose={onClose}>
				<Formik
					initialValues={Object.assign(
						{},
						{
							message: '',
						},
						initialValues || {}
					)}
					validationSchema={emailSchema}
					validateOnChange={false}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await this.emailUser(values.message);
						} catch (e) {
							actions.setStatus(Edge.API.getErrorMessage(e));
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<EmailUserValues>) => {
						return (
							<Form>
								<FormField
									name="message"
									component="textarea"
									label="Enter message"
									placeholder="Enter message"
								/>
								{props.status && <Error>{props.status}</Error>}
								{props.isSubmitting && <Loading buttonLoader />}
								<button type="submit" disabled={props.isSubmitting}>
									Send Message
								</button>
							</Form>
						);
					}}
				/>
			</ModalContainer>
		);
	}

	private emailUser = async (message: string) => {
		const { userId, entityId, entityType } = this.props;
		await TeamService.emailUser({ entityId, entityType, userId, message });
		this.props.onClose();
	};
}
