import * as React from 'react';

import * as Edge from '../core';
import { Footer } from '../components/global/footer';
import HeaderContainer from '../components/global/header/container';

import './authenticatedLayout.scss';

export default class LoggedInLayout extends React.Component {
	public render(): JSX.Element {
		return (
			<div className="App">
				{Edge.Configuration.enableBuildNumberInUI && (
					<div className="buildVersion">{Edge.Configuration.version}</div>
				)}
				<HeaderContainer />
				<main className="mainContent">
					{this.props.children}
					<Footer />
				</main>
			</div>
		);
	}
}
