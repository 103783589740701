import Drawing from './drawing';

interface Point {
	x: number;
	y: number;
}

interface Circle extends Point {
	radius: number;
}
interface HitBox extends Circle {
	id: string;
}

interface Button extends Point {
	rotation: number;
}

interface ButtonSet {
	down: Button;
	left: Button;
	right: Button;
	up: Button;
}

const ButtonStrokeWidth = 4;
const YOffset = 30;

export default class DPad {
	public dPadCenter: Point;
	public dPadHeight: number;
	public dPadWidth: number;
	public okBtnCenter: Point;
	public okBtnRadius: number;

	private arrowImage: HTMLImageElement | null = null;
	private buttonRadius: number;
	private ctx: CanvasRenderingContext2D;
	private dPadButtons: ButtonSet;
	private hitBoxes: HitBox[] = [];
	private renderScale: number;
	private showOkBtn: boolean;

	private callback: (direction: string) => void;

	// Debug
	private showDebugBoundaries: boolean = false;

	constructor(
		ctx: CanvasRenderingContext2D,
		showOkBtn: boolean,
		callback: (direction: string) => void,
		renderScale: number
	) {
		this.callback = callback;
		this.ctx = ctx;
		this.renderScale = renderScale;
		this.showOkBtn = showOkBtn;

		this.loadArrowImage();

		this.buttonRadius = 40 * this.renderScale;
		this.okBtnRadius = this.buttonRadius * 1.5;

		this.dPadHeight = this.buttonRadius * 3.5 + this.buttonRadius * 2;
		this.dPadWidth = this.buttonRadius * 3.5 + this.buttonRadius * 2;

		// Declares space between the centers of buttons left/right, down/up.
		const btnCenterSpacingH = this.dPadWidth - this.buttonRadius * 2;
		const btnCenterSpacingV = this.dPadHeight - this.buttonRadius * 2;

		this.dPadCenter = {
			x: this.ctx.canvas.width - btnCenterSpacingV,
			y: this.ctx.canvas.height - btnCenterSpacingH - YOffset,
		};

		this.okBtnCenter = {
			x: this.buttonRadius * 2,
			y: this.ctx.canvas.height - btnCenterSpacingH - this.buttonRadius,
		};

		this.dPadButtons = {
			down: {
				rotation: 270,
				x: this.dPadCenter.x,
				y: this.dPadCenter.y + btnCenterSpacingH / 2,
			},
			left: {
				rotation: 0,
				x: this.dPadCenter.x - btnCenterSpacingV / 2,
				y: this.dPadCenter.y,
			},
			right: {
				rotation: 180,
				x: this.dPadCenter.x + btnCenterSpacingV / 2,
				y: this.dPadCenter.y,
			},
			up: {
				rotation: 90,
				x: this.dPadCenter.x,
				y: this.dPadCenter.y - btnCenterSpacingH / 2,
			},
		};

		Object.entries(this.dPadButtons).forEach((keyValuePair) => {
			this.hitBoxes!.push({
				id: keyValuePair[0],
				radius: this.buttonRadius,
				x: keyValuePair[1].x,
				y: keyValuePair[1].y,
			});
		});

		this.hitBoxes.push({
			id: 'ok',
			radius: this.buttonRadius * 1.5,
			x: this.okBtnCenter.x,
			y: this.okBtnCenter.y,
		});
	}

	public draw = (showTouchControls: boolean): void => {
		if (!showTouchControls) return;

		if (this.showOkBtn) this.drawOkBtn();
		if (this.showDebugBoundaries) this.drawDebugBoundaries();
		this.drawDPad();
	};

	public handleCanvasClicked = (event: MouseEvent): void => {
		if (this.hitBoxes === null) return;

		const boundingRectangle = this.ctx.canvas.getBoundingClientRect();
		const point = {
			x: (event.clientX - boundingRectangle.left) * window.devicePixelRatio,
			y: (event.clientY - boundingRectangle.top) * window.devicePixelRatio,
		};

		this.hitBoxes.forEach((hb) => {
			if (this.intersectsCircle(point, hb)) {
				this.callback(hb.id);
			}
		});
	};

	private drawDebugBoundaries = (): void => {
		this.ctx.save();

		// Draws absolute border.
		this.ctx.strokeStyle = 'red';
		this.ctx.strokeRect(
			this.dPadButtons.left.x - this.buttonRadius,
			this.dPadButtons.up.y - this.buttonRadius,
			this.dPadWidth,
			this.dPadHeight
		);

		this.ctx.restore();
	};

	private drawDPad = (): void => {
		Object.values(this.dPadButtons).forEach((button: Button) => {
			this.ctx.save();
			this.ctx.translate(button.x, button.y); // translate to the center of the image
			this.ctx.rotate((button.rotation * Math.PI) / 180); // rotate by 90 degrees each time
			this.ctx.translate(-button.x, -button.y); // translate back to original origin (upper left)
			this.ctx.drawImage(
				this.arrowImage!,
				button.x - this.buttonRadius / 2,
				button.y - this.buttonRadius / 2,
				this.buttonRadius,
				this.buttonRadius
			);
			this.ctx.strokeStyle = 'black';
			this.ctx.lineWidth = ButtonStrokeWidth;
			this.ctx.beginPath();
			this.ctx.arc(button.x, button.y, this.buttonRadius, 0, 2 * Math.PI);
			this.ctx.stroke();
			this.ctx.restore();
		});
	};

	private drawOkBtn = (): void => {
		// Draws button circle.
		this.ctx.save();

		this.ctx.strokeStyle = 'green';
		this.ctx.lineWidth = ButtonStrokeWidth;
		this.ctx.beginPath();
		this.ctx.arc(
			this.okBtnCenter.x,
			this.okBtnCenter.y,
			this.okBtnRadius,
			0, // start angle (radians)
			2 * Math.PI // end angle (radians)
		);
		this.ctx.stroke();

		// Draws. OK text.
		this.ctx.fillStyle = 'black';
		this.ctx.font = '32px Arial';
		this.ctx.textAlign = 'center';
		this.ctx.textBaseline = 'middle';
		this.ctx.fillText('OK', this.okBtnCenter.x, this.okBtnCenter.y);

		this.ctx.restore();
	};

	private loadArrowImage = async (): Promise<void> => {
		this.arrowImage = await Drawing.loadImageAsync('/images/arrow-left-black.svg');
	};

	private intersectsCircle = (point: Point, circle: Circle) => {
		return Math.sqrt((point.x - circle.x) ** 2 + (point.y - circle.y) ** 2) < circle.radius;
	};
}
