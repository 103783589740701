import * as React from 'react';
import * as _ from 'lodash';

import * as Edge from '../../core';
import { AlignmentHelper } from '../../pages/exercises/align/alignHelper';
import { getColor } from '../../pages/training/helper';

import classNames from 'classnames';

import Precision from '../../assets/images/icons/Precision.svg';
import StopWatch from '../../assets/images/icons/Stopwatch.svg';

import './index.scss';

// Props for this Component
export interface ExerciseResultsProps {
	results: Edge.Models.ExerciseResult[];
	proPercentiles?: Edge.Models.ProPercentile[];
	userId?: string;
	showRank?: boolean;
}

// Configuration values to show/hide certain JSX elements in renderLine
interface ResultConfig {
	hasPercentile: boolean;
	isProCompare: boolean;
	convergence: boolean;
	divergence: boolean;
	hasTime: boolean;
	showRank: boolean;
}

// Tag: New Exercise
// May need to add a new data point.
/**
 * Properties used on every line
 */
interface ResultLine {
	correctPercent?: number;
	scoreText?: string;
	title: string;
	exerciseTypeId: Edge.Models.ExerciseTypeId;
	level?: number;
	explanationPercentile?: number | null;
	explanationRankingText?: string | null;
	explanationDescription?: string | null;
	percentilePro?: number | null;
	rankingTextPro?: string | null;
	descriptionPro?: string | null;
	convergenceStationScore?: number;
	divergenceStationScore?: number;
	responseTimeMilliseconds?: number;
}

/**
 * Creates renderable element for every exercise list item.
 *
 * @param param0 ResultConfig
 * @param param1 ResultLine
 * @param index of exercise in list.
 */
const renderLine = (
	{ hasPercentile, showRank }: ResultConfig,
	{
		correctPercent,
		scoreText,
		title,
		explanationPercentile,
		explanationRankingText,
		explanationDescription,
		percentilePro,
		rankingTextPro,
		convergenceStationScore,
		divergenceStationScore,
		responseTimeMilliseconds,
		exerciseTypeId,
		level,
	}: ResultLine,
	index: number
) => {
	const getSuffix = (number: number) => {
		switch (number % 10) {
			case 1:
				return 'st';
			case 2:
				return 'nd';
			case 3:
				return 'rd';
			default:
				return 'th';
		}
	};

	// Station scores for levels from 1 to 4
	// Level 1 out of 152
	// Level 2 out of 77
	// Level 3 out of 53
	// Level 4 out of 39

	const stationMaxScore = [152, 77, 52, 39];

	// Tag: New Exercise
	// May need changes if new data points are required for the new exercise.
	return (
		<React.Fragment key={index}>
			<div className={'internal_container'}>
				<div className={'results_item'}>
					<h4>{title}</h4>
					{explanationDescription && <p className={'results_header'}>{explanationDescription}</p>}
				</div>
				<div className={'results_item'}>
					{scoreText && <h3 className="text">{scoreText}</h3>}
					{divergenceStationScore !== undefined && <h3 className="text">{divergenceStationScore}</h3>}
					{convergenceStationScore !== undefined && <h3 className="text">{convergenceStationScore}</h3>}
					{responseTimeMilliseconds !== undefined && correctPercent !== undefined && (
						<div className={classNames('row', 'group')}>
							<div className={'text'}>
								<span>{(responseTimeMilliseconds! / 1000).toFixed(2)}s</span>
								<img src={StopWatch} alt={'stopwatch icon'} />
							</div>
							<div className={'text'}>
								<span>{(correctPercent! * 100).toFixed(0)}%</span>
								<img src={Precision} alt={'Precision icon'} />
							</div>
						</div>
					)}
				</div>
				{exerciseTypeId === Edge.Models.ExerciseTypeId.Alignment && (
					<div className={'result_item rank_description'}>
						<span>Out of 14, 0 is perfect Alignment.</span>
						<span>A = After, B = Before.</span>
					</div>
				)}
				{(exerciseTypeId === Edge.Models.ExerciseTypeId.Convergence ||
					exerciseTypeId === Edge.Models.ExerciseTypeId.Divergence ||
					exerciseTypeId === Edge.Models.ExerciseTypeId.Alternating) &&
					level && (
						<div className={'result_item rank_description'}>
							Station Score Out of {stationMaxScore[level - 1]} (Level {level})
						</div>
					)}

				{!showRank && (
					<div className={classNames('percentiles_text', 'results_item')}>
						<div className={'row'}>
							<h3>Your Percentile Rank:</h3>
							<span>
								<h3 style={{ color: getColor(explanationPercentile! * 100) }}>
									{hasPercentile && !!explanationPercentile
										? (explanationPercentile * 100).toFixed(0)
										: 0}
								</h3>
								<sup style={{ color: getColor(explanationPercentile! * 100) }}>
									{hasPercentile &&
										(!!explanationPercentile && getSuffix(explanationPercentile * 100))}
								</sup>
							</span>

							{explanationRankingText && (
								<h3 style={{ color: getColor(explanationPercentile! * 100) }}>
									{explanationRankingText}
								</h3>
							)}
						</div>
						{rankingTextPro && (
							<div className={'row'}>
								<h3>Your Percentile Rank vs Pro:</h3>
								<span>
									<h3 style={{ color: getColor(percentilePro! * 100) }}>
										{hasPercentile && !!percentilePro ? (percentilePro * 100).toFixed(0) : 0}
									</h3>
									<sup style={{ color: getColor(percentilePro! * 100) }}>
										{hasPercentile && (!!percentilePro && getSuffix(percentilePro * 100))}
									</sup>
								</span>

								<h3 style={{ color: getColor(percentilePro! * 100) }}>{rankingTextPro}</h3>
							</div>
						)}
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

const createLines = (result: Edge.Models.ResultWithProCompare): ResultLine[] => {
	if (!result) {
		return [];
	}

	const baseResult = _.pick(
		result,
		'explanationPercentile',
		'explanationRankingText',
		'explanationDescription',
		'percentilePro',
		'rankingTextPro',
		'descriptionPro'
	);

	switch (result.exerciseTypeId) {
		case Edge.Models.ExerciseTypeId.Alignment:
			return [
				Object.assign({}, baseResult, {
					title: 'Alignment',
					scoreText: AlignmentHelper.getScoreText(result),
					exerciseTypeId: result.exerciseTypeId,
				}),
			];
		case Edge.Models.ExerciseTypeId.Alternating:
			return [
				Object.assign({}, baseResult, {
					title: 'Convergence',
					correctPercent: result.convergenceCorrectPercent,
					convergenceStationScore: result.convergenceStationScore,
					exerciseTypeId: result.exerciseTypeId,
					level: result.level,
				}),
				Object.assign({}, baseResult, {
					title: 'Divergence',
					correctPercent: result.divergenceCorrectPercent,
					divergenceStationScore: result.divergenceStationScore,
					exerciseTypeId: result.exerciseTypeId,
					level: result.level,
				}),
			];
		case Edge.Models.ExerciseTypeId.ContrastSensitivity:
			return [
				Object.assign({}, baseResult, {
					title: 'Contrast Sensitivity',
					correctPercent: result.correctPercent,
					responseTimeMilliseconds: result.responseTimeMilliseconds,
					stationMax: result.stationMax || 1,
					exerciseTypeId: result.exerciseTypeId,
					level: result.level,
				}),
			];
		case Edge.Models.ExerciseTypeId.ContrastTracking:
			return [
				Object.assign({}, baseResult, {
					title: 'Contrast Tracking',
					correctPercent: result.correctPercent,
					responseTimeMilliseconds: result.responseTimeMilliseconds,
					stationMax: result.stationMax || 1,
					exerciseTypeId: result.exerciseTypeId,
					level: result.level,
				}),
			];
		case Edge.Models.ExerciseTypeId.Convergence:
			return [
				Object.assign({}, baseResult, {
					title: 'Convergence',
					correctPercent: result.convergenceCorrectPercent,
					convergenceStationScore: result.convergenceStationScore,
					exerciseTypeId: result.exerciseTypeId,
					level: result.level,
				}),
			];
		case Edge.Models.ExerciseTypeId.Depth:
			return [
				Object.assign({}, baseResult, {
					title: 'Depth Perception',
					correctPercent: result.correctPercent,
					responseTimeMilliseconds: result.responseTimeMilliseconds,
					exerciseTypeId: result.exerciseTypeId,
					level: result.level,
				}),
			];
		case Edge.Models.ExerciseTypeId.Divergence:
			return [
				Object.assign({}, baseResult, {
					title: 'Divergence',
					correctPercent: result.divergenceCorrectPercent,
					divergenceStationScore: result.divergenceStationScore,
					exerciseTypeId: result.exerciseTypeId,
					level: result.level,
				}),
			];
		case Edge.Models.ExerciseTypeId.Pursuits:
			return [
				Object.assign({}, baseResult, {
					title: 'Pursuits',
					correctPercent: result.correctPercent,
					responseTimeMilliseconds: result.responseTimeMilliseconds,
					exerciseTypeId: result.exerciseTypeId,
				}),
			];
		case Edge.Models.ExerciseTypeId.Recognition:
			return [
				Object.assign({}, baseResult, {
					title: 'Recognition',
					correctPercent: result.correctPercent,
					responseTimeMilliseconds: result.responseTimeMilliseconds,
					exerciseTypeId: result.exerciseTypeId,
				}),
			];
		case Edge.Models.ExerciseTypeId.Tracking:
			return [
				Object.assign({}, baseResult, {
					title: 'Tracking',
					correctPercent: result.correctPercent,
					responseTimeMilliseconds: result.responseTimeMilliseconds,
					exerciseTypeId: result.exerciseTypeId,
				}),
			];
		default:
			throw new Error(`Unexpected exercise type id: ${(result as any).exerciseTypeId}`);
	}
};

/**
 * Determines display order of exercises.
 * @param result exercise results that may include Pro Comparison data.
 */
const resultOrder = (result: Edge.Models.ResultWithProCompare) => {
	// Tag: New Exercise
	// Append switch case that returns a higher number than existing returns.
	switch (result.exerciseTypeId) {
		case Edge.Models.ExerciseTypeId.Alignment:
			return 1;
		case Edge.Models.ExerciseTypeId.Depth:
			return 2;
		case Edge.Models.ExerciseTypeId.Convergence:
			return 3;
		case Edge.Models.ExerciseTypeId.Divergence:
			return 4;
		case Edge.Models.ExerciseTypeId.Alternating:
			return 5;
		case Edge.Models.ExerciseTypeId.Recognition:
			return 6;
		case Edge.Models.ExerciseTypeId.Tracking:
			return 7;
		case Edge.Models.ExerciseTypeId.Pursuits:
			return 8;
		case Edge.Models.ExerciseTypeId.ContrastTracking:
			return 9;
		case Edge.Models.ExerciseTypeId.ContrastSensitivity:
			return 10;
		default:
			// `as any` necessary here as we *should* never hit this case
			throw new Error(`Unexpected exercise type id: ${(result as any).exerciseTypeId}`);
	}
};

const ExerciseResults: React.FunctionComponent<ExerciseResultsProps> = (props) => {
	// Determines whether an additional <td/> element is required for every exercise
	const isProCompare = !!props.proPercentiles;

	// Merges ExerciseResult and ProExercisePercentile objects into one ResultWithProCompare.
	// Casts ExerciseResult[] to ResultProCompare[] if proPercentile values is undefined.
	const resultsWithProCompare = isProCompare
		? _.chain(props.results)
				.keyBy('id')
				.merge(_.keyBy(props.proPercentiles, 'id'))
				.values()
				.value()
		: (props.results as Edge.Models.ResultWithProCompare[]);

	const lines = _.flatMap(_.orderBy(resultsWithProCompare, resultOrder, props.showRank), createLines);

	// Determines whether at least one percentile value exists in exercises
	const hasPercentile = lines.some((i) => i.explanationPercentile !== undefined && i.explanationPercentile !== null);

	// Determines whether at least one convergence score value exists in exercises
	const convergence = lines.some(
		(i) => i.convergenceStationScore !== undefined && i.convergenceStationScore !== null
	);

	// Determines whether at least one divergence score value exists in exercises
	const divergence = lines.some((i) => i.divergenceStationScore !== undefined && i.divergenceStationScore !== null);

	// Determines whether at least one response time value exists in exercises
	const hasTime = lines.some((i) => !!i.responseTimeMilliseconds);

	const showRank = props.showRank ? props.showRank : false;

	return (
		<div className="exercise_results">
			<div className={'result_container'}>
				{lines.map(
					renderLine.bind(null, {
						showRank,
						hasPercentile,
						isProCompare,
						convergence,
						divergence,
						hasTime,
					})
				)}
			</div>
		</div>
	);
};

export default ExerciseResults;
