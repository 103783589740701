import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';

import * as Edge from '../../core';
import FormField from '../../components/formField';
import Error from '../../components/global/error';
import Input from '../../components/global/input';
import Loading from '../../components/loading';

export interface CreateTeamMemberProps {
	positions?: Edge.Models.Position[];
	initialValues?: CreateTeamMemberFormProps;
	createTeamMember: (
		values: CreateTeamMemberFormProps,
		onClose: () => void,
		teamRoleId?: Edge.Models.RoleId
	) => Promise<void>;
	teamRoleId?: Edge.Models.RoleId;

	onClose: () => void;
}

export interface CreateTeamMemberFormProps {
	firstName: string;
	lastName: string;
	email: string;
	username: string;
	password: string;
	passwordConfirmation: string;
	positionId?: string;
}

const normalRequiredField = Yup.string()
	.max(100, 'Too long')
	.required();
const uniqueRequiredField = normalRequiredField.min(4, 'Too short');

const schema = Yup.object({
	firstName: normalRequiredField.required('First name is required.'),
	lastName: normalRequiredField.required('Last name is required.'),
	email: normalRequiredField.email().required('Email is required.'),
	username: uniqueRequiredField.required('Username is required.'),
	password: Edge.Validation.password('Password'),
	passwordConfirmation: Yup.string()
		.required('This field is required.')
		.oneOf([Yup.ref('password')], 'Passwords must match.'),
	positionId: Yup.string(),
});

export default class CreateTeamMember extends React.PureComponent<CreateTeamMemberProps> {
	public render(): JSX.Element {
		const { positions, initialValues, createTeamMember, teamRoleId, onClose } = this.props;
		return (
			<div>
				<Formik
					initialValues={Object.assign(
						{},
						{
							firstName: '',
							lastName: '',
							email: '',
							username: '',
							password: '',
							passwordConfirmation: '',
						},
						initialValues || {}
					)}
					validationSchema={schema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await createTeamMember(values, onClose, teamRoleId);
						} catch (e) {
							actions.setStatus(Edge.API.getErrorMessage(e));
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<CreateTeamMemberFormProps>) => {
						return (
							<Form>
								<FormField type="text" name="firstName" description="First name" />
								<FormField type="text" name="lastName" description="Last name" />
								<FormField type="email" name="email" description="Email" />
								{positions && positions.length > 0 && (
									<FormField component="select" name="positionId" label="Position">
										<option key={-1} value={''} />
										{_.orderBy(positions, (i) => i.name).map((p, ix) => {
											return (
												<option key={ix} value={p.id}>
													{p.name}
												</option>
											);
										})}
									</FormField>
								)}
								<FormField type="text" name="username" description="Username" />
								<FormField type="password" name="password" description="Password" />
								<FormField
									type="password"
									name="passwordConfirmation"
									description="Password (repeat)"
								/>
								{props.status && <Error>{props.status}</Error>}
								{props.isSubmitting && <Loading buttonLoader />}
								<Input type="submit" disabled={props.isSubmitting} value="submit" />
							</Form>
						);
					}}
				/>
			</div>
		);
	}
}
