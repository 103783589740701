import * as React from 'react';
import classNames from 'classnames';
import { Redirect } from 'react-router-dom';
import * as _ from 'lodash';

import * as Edge from '../../../core';
import ExerciseList from '../exerciseList';
import StartButton from '../startButton';
import PlanPageBase from '../planPageBase';
import ErrorDisplay from '../../../components/error/errorDisplay';
import JsonDebug from '../../../components/jsonDebug';
import AccordionGroup, { AccordionItem } from '../../../components/accordionGroup';

import './index.scss';
import { CanTrainTeamsBanner } from '../../../components/teamBanner';
import { getTeamsUserCanTrain } from '../../../store/permissions/selector';
import { AppState } from '../../../store';
import { connect } from 'react-redux';

export interface MyPlanTrainingProps {
	planResult?: Edge.Models.TrainingPlanResult;
	currentTeam?: Edge.Models.Team;
}

export class MyPlanTraining extends React.PureComponent<MyPlanTrainingProps> {
	public render() {
		const { plan, currentPhase } = this.getPhase();
		const { currentTeam } = this.props;
		return (
			<PlanPageBase
				banner={<CanTrainTeamsBanner />}
				currentTeam={currentTeam}
				gameMode={Edge.Models.GameMode.MyPlan}
				isTraining
				plan={plan}
				planPhase={currentPhase}
				renderPlan={this.renderBody}
				renderResults={this.renderResults}
				title="My Plan"
			/>
		);
	}
	private renderResults = () => {
		return <Redirect to="/training/myPlan-redirect" />;
	};
	private renderBody = (trainNow: () => void) => {
		const { plan, planIndex, currentPhase, sessions } = this.getPhase();
		if (!plan || !currentPhase || planIndex === undefined) {
			return <ErrorDisplay>Unable to determine current plan phase</ErrorDisplay>;
		}
		const sessionsByPhase = _.groupBy((sessions || []).filter((i) => !!i.planPhaseId), (i) => i.planPhaseId);

		const items = plan.phases.map((i, ix) => {
			const sessions = sessionsByPhase[i.id] || [];
			const isComplete =
				ix < planIndex
					? true
					: ix > planIndex
					? false
					: sessions.filter((i) => i.completionPercent >= 1).length >= i.requiredSessions;
			const daysRemaining =
				ix < planIndex
					? 0
					: ix > planIndex
					? i.durationDays
					: this.props.planResult!.remainingDaysOfCurrentPlan;
			return this.renderPhase(i, `Week ${ix + 1}`, isComplete, sessions, daysRemaining, () => {
				if (i === currentPhase) {
					return <StartButton isTraining onClick={trainNow} planPhase={currentPhase} />;
				}
				return <React.Fragment />;
			});
		});

		let renderedItems = items.slice(planIndex);
		const completedItems = items.slice(0, planIndex);
		if (completedItems.length > 0) {
			renderedItems = renderedItems.concat([
				{
					isSpacer: true,
					content: <h3 className="completed_phases">Completed Weeks</h3>,
				},
				...completedItems,
			]);
		}

		return (
			<div className="my_plan">
				<AccordionGroup items={renderedItems} />
				<JsonDebug data={this.props.planResult} />
			</div>
		);
	};

	private renderPhase = (
		phase: Edge.Models.PlanPhase,
		phaseName: string,
		isComplete: boolean,
		sessions: Edge.Models.BaseSessionResponse[],
		daysRemaining: number,
		button: () => React.ReactNode
	): AccordionItem => {
		const complete = sessions.filter((i) => i.completionPercent >= 1);
		return {
			isSpacer: false,
			header: (
				<div className="phase">
					<div className="phase_name">{phaseName}</div>
					<div className="session_count">
						{complete.length}/{phase.requiredSessions} sessions
					</div>
					<div className="days_remaining">{Math.ceil(daysRemaining)} days remaining</div>
					<div className={classNames('complete_status', { isComplete })}>
						<span className="bubble" />
						{isComplete ? <span>Series Complete</span> : <span>Series Incomplete</span>}
					</div>
					<div className="action_button">{!isComplete && button()}</div>
				</div>
			),
			body: (
				<>
					<ExerciseList configs={phase.exerciseConfigurations} />
				</>
			),
		};
	};

	private getPhase = () => {
		const { planResult } = this.props;
		if (!planResult || !planResult.plan) {
			return { plan: undefined, planIndex: undefined, currentPhase: undefined, sessions: undefined };
		}
		const plan = planResult.plan;
		const planIndex = plan.phases.findIndex((i) => i.id === planResult.currentPlanPhaseId);
		const currentPhase = plan.phases[planIndex];
		return { plan, planIndex, currentPhase, sessions: planResult.sessions };
	};
}

function mapStateToProps(state: AppState) {
	return {
		currentTeam: getTeamsUserCanTrain(state).currentTeam,
	};
}

export default connect(mapStateToProps)(MyPlanTraining);
