import * as Edge from '../../../core';
import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import ExerciseLine from '../exerciseLine';

export interface ExerciseListProps {
	configs: Edge.Models.ExerciseConfiguration[];
	isAdmin?: boolean;
	renderExerciseLineButton?: (config: Edge.Models.ExerciseConfiguration) => JSX.Element;
}

interface ExerciseListState {}

export class ExerciseList extends React.PureComponent<ExerciseListProps, ExerciseListState> {
	constructor(props: ExerciseListProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { configs, isAdmin, renderExerciseLineButton } = this.props;
		return (
			<div className="exercise-list">
				{configs.map((i, ix) => (
					<ExerciseLine key={ix} config={i} isAdmin={isAdmin}>
						{renderExerciseLineButton && renderExerciseLineButton(i)}
					</ExerciseLine>
				))}
			</div>
		);
	}
}

const mapStateToProps = ({ loginState }: AppState) => {
	return {
		isAdmin: loginState.decodedToken && loginState.decodedToken.is_admin,
	};
};

export default connect(mapStateToProps)(ExerciseList);
