// lib
import './../js/lib/jquery.min.js';

declare global {
  interface Window {
    jQuery: any,
    $: any
  }
}
window.jQuery = window.$ = require('./../js/lib/jquery.min.js');
