import * as React from 'react';

import * as Edge from '../../../core';
import Error from '../../../components/global/error';
import { ModalContainer } from '../../../components/global/modal';
import Loading from '../../../components/loading';
import { PlanService } from '../../../services/planService';

export interface RemoveTrainingPlanProps {
	userId: string;
	onClose: () => void;
}

interface RemoveTrainingPlanState {
	loading: boolean;
	error?: Edge.Models.EdgeError;
}

export class RemoveTrainingPlan extends React.PureComponent<RemoveTrainingPlanProps, RemoveTrainingPlanState> {
	constructor(props: RemoveTrainingPlanProps) {
		super(props);
		this.state = {
			loading: false,
		};
	}
	public render(): JSX.Element {
		const { loading, error } = this.state;
		return (
			<ModalContainer
				open={true}
				title="Are you sure?"
				onClose={() => {
					this.props.onClose();
				}}
			>
				<p>Are you sure you want to manually remove this user's training plan?</p>
				{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
				{loading && <Loading buttonLoader />}
				<button className="cta_btn remove_member_btn" onClick={this.removeTrainingPlan}>
					Yes
				</button>
				<button className="cta_btn btn_light remove_member_btn" onClick={this.props.onClose}>
					Cancel
				</button>
			</ModalContainer>
		);
	}

	private removeTrainingPlan = async () => {
		this.setState({ loading: true, error: undefined });
		try {
			await PlanService.deleteUserPlan(this.props.userId);
			this.setState({ loading: false });
			this.props.onClose();
		} catch (error) {
			this.setState({ error, loading: false });
		}
	};
}

export default RemoveTrainingPlan;
