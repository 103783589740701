import * as React from 'react';

import * as Edge from '../../../core';

import './dropdown.scss';
import './orgDropdown.scss';
import { Avatar } from '../../avatar';
import { Link } from 'react-router-dom';

export interface OrgDropdownItemProps {
	organization: Edge.Models.Organization;
	onClick: React.MouseEventHandler;
}

export class OrgDropdownItem extends React.Component<OrgDropdownItemProps> {
	public render(): JSX.Element {
		const { organization, onClick } = this.props;
		// #1394: https://dev.azure.com/vizualedge/Edge%20Trainer/_backlogs/backlog/Edge%20Trainer%20Team/Stories/?workitem=1394
		// TODO: can we use a styled button for these?
		/* eslint-disable jsx-a11y/anchor-is-valid */
		return (
			<a className="header_dropdown_item" onClick={onClick}>
				<Avatar src={organization.logoUrl} />
				{organization.name}
			</a>
		);
		/* eslint-enable jsx-a11y/anchor-is-valid */
	}
}

export interface OrgDropdownProps {
	organizations?: Edge.Models.Organization[];
	currentOrganization?: Edge.Models.Organization;
	setCurrentOrganization: (organization: Edge.Models.Organization) => void;
}

const maxItems = 5;

export class OrgDropdown extends React.Component<OrgDropdownProps> {
	public render(): JSX.Element {
		const { organizations, currentOrganization } = this.props;
		return (
			<div className="header_dropdown org_dropdown">
				{(organizations || []).slice(0, maxItems).map((organization, ix) => {
					if (currentOrganization && organization.id === currentOrganization.id) {
						return null;
					}
					return (
						<OrgDropdownItem
							key={ix}
							organization={organization}
							onClick={this.setCurrentOrganization.bind(this, ix)}
						/>
					);
				})}
				{organizations && organizations.length > maxItems && (
					<Link to="/organizations" className="header_dropdown_item header_dropdown_item_all_organizations">
						All Organizations ({organizations.length})
					</Link>
				)}
			</div>
		);
	}
	private setCurrentOrganization = (ix: number) => {
		this.props.setCurrentOrganization((this.props.organizations || [])[ix]);
	};
}

export default OrgDropdown;
