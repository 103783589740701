// Must be above >= 0.
export const StartingContrast: number = 64;

export const getCircleCoordinate = (
	index: number,
	cX: number,
	cY: number,
	distance: number
): {
	x: number;
	y: number;
} => {
	let x: number = cX,
		y: number = cY;

	switch (index) {
		// Up
		case 0:
			y -= distance;
			break;
		// Right
		case 1:
			x += distance;
			break;
		// Down
		case 2:
			y += distance;
			break;
		// Left
		case 3:
			x -= distance;
			break;
	}

	return { x, y };
};
