import * as React from 'react';
import { RegisterViaInviteProps } from './props';
import { UserService } from '../../services/userService';
import { Redirect } from 'react-router';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';

export interface DetectAccountProps extends RegisterViaInviteProps {
	userExists: boolean;
}

export const DetectAccount: React.FunctionComponent<DetectAccountProps> = (props) => {
	if (props.userExists) {
		return <Redirect to={`/invite/prompt/${props.match.params.token}`} />;
	} else {
		return <Redirect to={`/invite/create/${props.match.params.token}`} />;
	}
};

export default withLoadDataDefaultConfig(
	errorLoadingWrapperHOC(
		DetectAccount,
		() => true,
		(message, error) => message === 'Token not found.' || message === 'This token has already been fulfilled.'
	),
	(props) => {
		return { token: props.match.params.token };
	},
	async ({ token }: { token: string }) => {
		return { userExists: await UserService.exists(token) };
	}
);
