import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';

import * as Edge from '../../core';
import FormField from '../formField';
import Input from '../../components/global/input';
import Error from '../global/error';
import Loading from '../loading';
import { withLoadDataDefaultConfig } from '../loadData';
import errorLoadingWrapperHOC from '../errorLoadingWrapper/errorLoadingWrapperHOC';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { StaticDataService } from '../../services/staticDataService';

export interface CreateOrganizationFormProps {
	isAdmin?: boolean;
	organizationTypes: Edge.Models.OrganizationType[];
	initialValues?: Partial<CreateOrganizationFormValues>;
	onSubmit: (values: CreateOrganizationFormValues) => Promise<void>;
}

export interface CreateOrganizationFormValues {
	organizationName: string;
	organizationTypeId: string;
}

const normalRequiredField = Yup.string()
	.max(100, 'Too long')
	.required();
const uniqueRequiredField = normalRequiredField.min(4, 'Too short');

const createOrganizationSchema = Yup.object().shape({
	organizationName: uniqueRequiredField.required('Organization Name is required'),
	organizationTypeId: Yup.string().required('Organization type is required'),
});

export class CreateOrganizationForm extends React.Component<CreateOrganizationFormProps> {
	public render() {
		const { organizationTypes, initialValues, onSubmit } = this.props;
		return (
			<>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							organizationName: '',
							organizationTypeId: '',
						},
						initialValues || {}
					)}
					validationSchema={createOrganizationSchema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await onSubmit(values);
						} catch (e) {
							const message = Edge.API.getErrorMessage(e);
							actions.setStatus(message);
							if (message === Edge.Constants.USER_EXISTS_ERROR_MESSAGE) {
								actions.setFieldError('username', message);
							}
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<CreateOrganizationFormValues>) => (
						<Form>
							<FormField
								description="Name"
								name="organizationName"
								placeholder={Edge.Constants.ORGANIZATION_NAME_PLACEHOLDER}
								type="text"
							/>
							<FormField component="select" name="organizationTypeId" label="Organization Type">
								<option key={-1} value={''} />
								{_.orderBy(organizationTypes, (i) => i.name).map((t, ix) => {
									return (
										<option key={ix} value={t.id}>
											{t.name}
										</option>
									);
								})}
							</FormField>
							<div className="hRule" />
							{props.status && <Error>{props.status}</Error>}
							{props.isSubmitting && <Loading buttonLoader />}
							<Input type="submit" disabled={props.isSubmitting} value="submit" />
						</Form>
					)}
				/>
			</>
		);
	}
}

function mapStateToProps({ loginState }: AppState) {
	return {
		isAdmin: loginState.decodedToken && loginState.decodedToken.is_admin,
	};
}
export default connect(mapStateToProps)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(CreateOrganizationForm, (props) => !!props.organizationTypes),
		(props) => _.pick(props, 'isAdmin'),
		async ({ isAdmin }) => {
			const organizationTypes = await (isAdmin
				? StaticDataService.getAllOrganizationTypes()
				: StaticDataService.getPublicOrganizationTypes());
			return { organizationTypes };
		}
	)
);
