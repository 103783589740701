import * as React from 'react';
import CreateAccountForm, { CreateAccountFormProps } from './createAccountForm';
import errorLoadingWrapperHOC from '../errorLoadingWrapper/errorLoadingWrapperHOC';
import { connect } from 'react-redux';
import * as Edge from '../../core';
import { getPublicOrganizationTypes, getLevels, getSports, getPositions } from '../../store/staticData';
import { AppState } from '../../store';
import './index.scss';
export * from './createAccountForm';

const CreateAccountFormWithLoading = errorLoadingWrapperHOC(
	CreateAccountForm,
	(props) => {
		return props.organizationTypes && props.levels && props.sports && props.positions && true;
	},
	undefined,
	undefined,
	{ loadingOptions: { blockItem: true } }
);

interface CreateAccountContainerProps
	extends Pick<
		CreateAccountFormProps,
		Exclude<keyof CreateAccountFormProps, 'organizationTypes' | 'levels' | 'sports' | 'positions'>
	> {
	publicOrganizationTypeIsLoading: boolean;
	levelIsLoading: boolean;
	sportIsLoading: boolean;
	positionIsLoading: boolean;
	loadingError?: Edge.Models.EdgeError;
	organizationTypes?: Edge.Models.OrganizationType[];
	getPublicOrganizationTypes: () => Promise<void>;
	levels?: Edge.Models.Level[];
	getLevels: () => Promise<void>;
	sports?: Edge.Models.Sport[];
	getSports: () => Promise<void>;
	positions?: Edge.Models.Position[];
	getPositions: () => Promise<void>;
}

class CreateAccountFormContainer extends React.Component<CreateAccountContainerProps> {
	public componentDidMount() {
		if (!this.props.publicOrganizationTypeIsLoading && !this.props.organizationTypes) {
			this.props.getPublicOrganizationTypes();
		}
		if (!this.props.levelIsLoading && !this.props.levels) {
			this.props.getLevels();
		}
		if (!this.props.sportIsLoading && !this.props.sports) {
			this.props.getSports();
		}
		if (!this.props.positionIsLoading && !this.props.positions) {
			this.props.getPositions();
		}
	}
	public render() {
		const {
			organizationTypes,
			levels,
			sports,
			positions,
			publicOrganizationTypeIsLoading,
			levelIsLoading,
			sportIsLoading,
			positionIsLoading,
			...rest
		} = this.props;
		return (
			<CreateAccountFormWithLoading
				isLoading={publicOrganizationTypeIsLoading || levelIsLoading || sportIsLoading}
				organizationTypes={organizationTypes!}
				levels={levels!}
				sports={sports!}
				positions={positions!}
				{...rest}
			/>
		);
	}
}

function mapStateToProps({ publicOrganizationTypeState, levelState, sportState, positionState }: AppState) {
	return {
		publicOrganizationTypeIsLoading: publicOrganizationTypeState.isLoading,
		levelIsLoading: levelState.isLoading,
		sportIsLoading: sportState.isLoading,
		positionIsLoading: positionState.isLoading,
		loadingError:
			publicOrganizationTypeState.loadingError ||
			levelState.loadingError ||
			sportState.loadingError ||
			positionState.loadingError,
		organizationTypes: publicOrganizationTypeState.data,
		levels: levelState.data,
		sports: sportState.data,
		positions: positionState.data,
	};
}

export default connect(
	mapStateToProps,
	{
		getPublicOrganizationTypes,
		getLevels,
		getSports,
		getPositions,
	}
)(CreateAccountFormContainer);
