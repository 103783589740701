import * as ReduxThunk from 'redux-thunk';
import * as Edge from './../../core';
import { AppState } from './..';

import { APIKEYS_LOADING, APIKEYS_SUCCESS, APIKEYS_FAILURE, UPDATE_APIKEY, ApiKeyUpdateType } from './types';
import { KeysService } from '../../services/keysService';

// action creators
export const loadApiKeysAction = () => {
	return {
		type: APIKEYS_LOADING,
	};
};

export const apiKeysSuccessAction = (apikeys: Edge.Models.ApiKey[]) => {
	return {
		type: APIKEYS_SUCCESS,
		payload: apikeys,
	};
};

export const apiKeysFailureAction = (error: Edge.Models.EdgeError) => {
	return {
		type: APIKEYS_FAILURE,
		payload: error,
		error: true,
	};
};

export const updateApiKeyAction = (apiKey: ApiKeyUpdateType) => {
	return {
		type: UPDATE_APIKEY,
		payload: apiKey,
	};
};

// thunk actions
export const getApiKeys = (
	id: string
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(loadApiKeysAction());

	try {
		const keys = await KeysService.getKeys(id);
		dispatch(apiKeysSuccessAction(keys));
	} catch (error) {
		dispatch(apiKeysFailureAction(error));
	}
};

export const updateApiKey = (
	update: ApiKeyUpdateType
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(updateApiKeyAction(update));
};
