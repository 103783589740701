import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import * as Edge from '../../../core';
import FormField from '../../../components/formField';
import Input from '../../../components/global/input';
import Error from '../../../components/global/error';
import ConfigLayout from '../configLayout';
import { FullScreenService } from '../../../services/fullScreenService';

export interface DepthConfigFormProps {
	mode: Edge.Models.ExerciseMode;
	initialConfiguration: Partial<Edge.Models.DepthExerciseConfiguration>;
	lockConfiguration: boolean;
	onSubmit: (configuration: Edge.Models.DepthExerciseConfiguration, fullScreen: boolean) => Promise<void>;

	operationName: string;
}

export interface DepthConfigFormValues {
	durationSeconds: string;
	size: string;
	level: string;
	fullScreen: boolean;
}

const schema = Yup.object().shape({
	durationSeconds: Yup.number(),
	size: Yup.string().required('Circle size is required'),
	level: Yup.number().required('Level is required'),
	fullScreen: Yup.boolean(),
});

interface InstructionProps {
	config: DepthConfigFormValues;
	startText: string;
	mode: Edge.Models.ExerciseMode;
}

const instructions = ({ config, startText, mode }: InstructionProps) => (
	<ul>
		<li>Red/Blue glasses are worn during this exercise.</li>
		<li>
			When you press '{startText}' the first set of circles will appear on the screen. One of the five circles
			will appear different from the others. Using the keyboard arrow keys, move the main arrow underneath the
			circle that appears to float at a different level than the others, then press 'Enter' on the keyboard.
		</li>
		<li>
			After each response, a new line of circles will appear. In each set, identify the circle that floats at a
			different level than the others. Strive to correctly identify the circle in each set.
		</li>
		{mode === 'evaluate' ? (
			<li>There will be 8 sets of circles and you have 10 seconds to identify the correct circle in each set.</li>
		) : (
			<li>
				This exercise will last {moment.duration(parseInt(config.durationSeconds), 'seconds').humanize()} and
				you have 10 seconds to identify the correct circle in each set.
			</li>
		)}
	</ul>
);

export default class DepthConfigForm extends React.Component<DepthConfigFormProps> {
	public render() {
		const { initialConfiguration, lockConfiguration, onSubmit, operationName, mode } = this.props;
		const startText = mode === 'evaluate' ? 'Start Exercise' : 'Train Depth Perception';
		return (
			<>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							durationSeconds: (initialConfiguration.durationSeconds || 120).toString() || '120',
							size: (initialConfiguration.size || 1).toString() || '1',
							level: (initialConfiguration.level || 1).toString() || '1',
							fullScreen: false,
						}
					)}
					validationSchema={schema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await onSubmit(
								{
									id: initialConfiguration.id,
									exerciseTypeId: Edge.Models.ExerciseTypeId.Depth,
									durationSeconds: parseInt(values.durationSeconds),
									level: parseInt(values.level),
									size: parseInt(values.size),
								},
								values.fullScreen
							);
						} catch (e) {
							actions.setStatus(Edge.API.getErrorMessage(e));
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<DepthConfigFormValues>) => (
						<Form>
							<ConfigLayout
								operationName={operationName}
								exerciseName="Depth Perception"
								headerActions={
									<>
										<Input type="submit" disabled={props.isSubmitting} value={startText} />
										{props.status && <Error>{props.status}</Error>}
										{FullScreenService.isEnabled() && (
											<FormField
												type="checkbox"
												name="fullScreen"
												description="View Full Screen"
											/>
										)}
									</>
								}
								requiresGlasses
								instructions={instructions({ config: props.values, startText, mode })}
								settings={
									<>
										<FormField
											component="select"
											name="durationSeconds"
											description="Session Duration"
											disabled={lockConfiguration}
										>
											<option value="120">2 minutes</option>
											<option value="300">5 minutes</option>
											<option value="600">10 minutes</option>
										</FormField>
										<FormField
											component="select"
											name="size"
											description="Circle Size"
											disabled={lockConfiguration}
										>
											<option value="1">Small</option>
											<option value="2">Medium</option>
											<option value="3">Large</option>
										</FormField>
										<FormField
											component="select"
											name="level"
											description="Level"
											disabled={lockConfiguration}
										>
											<option>1</option>
											<option>2</option>
											<option>3</option>
											<option>4</option>
										</FormField>
									</>
								}
								videoUrl={Edge.Constants.VIDEOS.DEPTH}
							/>
						</Form>
					)}
				/>
			</>
		);
	}
}
