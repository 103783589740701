import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { getActiveSession, startSession, endSession } from '../../../store/session/actions';
import { AppState } from '../../../store';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import * as Edge from '../../../core';
import Error from '../../../components/global/error';
import { Link } from 'react-router-dom';
import { activeSessionSelector } from '../../../store/session/selector';
import Loading from '../../../components/loading';

export interface StartButtonProps {
	currentTeam?: Edge.Models.Team;
	isChanging: boolean;
	isLoading: boolean;
	isTraining: boolean;
	loadingError?: Edge.Models.EdgeError;
	planPhase?: Edge.Models.PlanPhase;
	status?: Edge.Models.ActiveSessionResponse;

	endSession: (command: Edge.Models.EndSessionCommand) => Promise<void>;
	getActiveSession: () => void;
	getRemainingTime: (now?: Date) => moment.Duration | undefined;
	onClick: () => void;
	setCurrentTeam: (team: Edge.Models.Team) => void;
	startSession: (command: Edge.Models.StartSessionCommand) => Promise<void>;
}

interface StartButtonState {
	changingError?: Edge.Models.EdgeError;
	isThisChanging?: boolean;
}

export class StartButton extends React.PureComponent<StartButtonProps, StartButtonState> {
	constructor(props: StartButtonProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { currentTeam, isTraining, isChanging, planPhase } = this.props;
		const { changingError, isThisChanging } = this.state;
		if (!currentTeam) {
			return null;
		}
		const session = this.activeSession();
		const sessionTypeId = this.sessionTypeId();
		const className = classNames({ 'train-now': isTraining, 'eval-now': !isTraining });

		if (session) {
			if (session.teamId !== currentTeam.id) {
				return (
					<div className={className}>
						<p>You have an active session with another team.</p>
						<button className="cta_btn" onClick={this.switchTeam} disabled={isChanging}>
							Switch
						</button>
						{isThisChanging && <Loading buttonLoader />}
						{changingError && <Error>{Edge.API.getErrorMessage(changingError)}</Error>}
					</div>
				);
			}
			if (
				session.sessionTypeId !== sessionTypeId ||
				(planPhase && session.planPhaseId && session.planPhaseId !== planPhase.id)
			) {
				return (
					<div className={className}>
						<p>You have a session of a different type active.</p>
						<button className="cta_btn" onClick={this.endSession} disabled={isChanging}>
							End Active Session
						</button>
						{isThisChanging && <Loading buttonLoader />}
						{changingError && <Error>{Edge.API.getErrorMessage(changingError)}</Error>}
					</div>
				);
			}
		}
		const isInProgress = session && planPhase && session.planPhaseId === planPhase.id;
		if (isInProgress && session!.completionPercent >= 1) {
			return (
				<div className={className}>
					<p>
						You've already completed this plan in this session. You can continue training in{' '}
						<Link to="/training/openGym">Open Gym</Link>.
					</p>
					{changingError && <Error>{Edge.API.getErrorMessage(changingError)}</Error>}
				</div>
			);
		}
		return (
			<div className={className}>
				<button className="cta_btn" onClick={this.onClick} disabled={isChanging}>
					{isInProgress ? (
						<>Resume {isTraining ? 'Training' : 'Evaluation'}</>
					) : (
						<>{isTraining ? 'Train' : 'Evaluate'} now</>
					)}
				</button>
				{isThisChanging && <Loading buttonLoader />}
				{changingError && <Error>{Edge.API.getErrorMessage(changingError)}</Error>}
				{!session && <span className="note">NOTE: Starting this set will use one of your sessions.</span>}
			</div>
		);
	}
	private endSession = () => {
		const session = this.activeSession();
		if (session) {
			try {
				this.props.endSession({ sessionId: session.sessionId, abandon: true });
			} catch (changingError) {
				this.setState({ changingError });
				throw changingError;
			}
		} else {
			// session is inactive - force an update
			this.props.getActiveSession();
		}
	};
	private switchTeam = () => {
		const session = this.activeSession();
		if (session) {
			this.props.setCurrentTeam({ id: session.teamId } as Edge.Models.Team);
		} else {
			// session is inactive - force an update
			this.props.getActiveSession();
		}
	};
	private activeSession = () => {
		const { status, getRemainingTime } = this.props;
		const remainingTime = getRemainingTime();
		if (status && status.active && remainingTime && remainingTime.asMilliseconds() > 0) {
			return status.active;
		}
		return undefined;
	};
	private sessionTypeId = () => {
		const { isTraining } = this.props;
		return isTraining ? Edge.Models.SessionTypeId.Train : Edge.Models.SessionTypeId.Evaluate;
	};
	private onClick = async () => {
		const { isTraining, currentTeam, startSession, onClick } = this.props;

		const activeSession = this.activeSession();
		if (!activeSession) {
			this.setState({ isThisChanging: true });
			try {
				await startSession({
					sessionTypeId: isTraining ? Edge.Models.SessionTypeId.Train : Edge.Models.SessionTypeId.Evaluate,
					teamId: currentTeam!.id,
				});
				this.setState({ isThisChanging: false });
			} catch (changingError) {
				this.setState({ changingError, isThisChanging: false });
				throw changingError;
			}
		}

		onClick();
	};
}

function mapStateToProps(state: AppState) {
	const { isLoading, loadingError, data, isChanging, getRemainingTime } = activeSessionSelector(state);
	return {
		isLoading,
		loadingError,
		isChanging,
		status: data,
		getRemainingTime,
		currentTeam: state.teamState.currentTeam,
	};
}

export default connect(
	mapStateToProps,
	{
		getActiveSession,
		startSession,
		endSession,
	}
)(errorLoadingWrapperHOC(StartButton, (props) => !!props.status));
