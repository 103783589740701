import * as Edge from './../core';

export abstract class ProfileService {
	public static async getMine(): Promise<Edge.Models.Profile> {
		const route = Edge.API.ServerRoute.forAction('users', 'me');
		return await Edge.API.get(route);
	}

	public static async updateMine(command: Edge.Models.ProfileRegistrationFlags): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('users', 'me');
		await Edge.API.patch(route, command);
	}
}
