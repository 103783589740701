import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { AppState } from '../../store';
import { getTeams, setCurrentTeamFromCache } from '../../store/team/actions';
import { getUserPermissionService } from '../../store/permissions/selector';
import { UserPermissionService } from '../../services/userPermissionService';
import * as Edge from '../../core';
import AthleteDashboard from './athleteDashboard';
import CoachDashboard from './coachDashboard';
import ErrorDisplay from '../../components/error/errorDisplay';
import PageTitle from '../../components/pageTitle';
import ScoutDashboard from './scoutDashboard';

export interface DashboardProps {
	currentOrganization?: Edge.Models.Organization;
	currentTeam?: Edge.Models.Team;
	getTeams: () => Promise<Edge.Models.Team[]>;
	isLoading?: boolean;
	loadingError?: Edge.Models.EdgeError;
	permissions: UserPermissionService;
	setCurrentTeamFromCache: (
		currentOrganizationId: string,
		hasCurrentTeam: boolean,
		teams?: Edge.Models.Team[]
	) => void;
	teams?: Edge.Models.Team[];
}

export class Dashboard extends React.Component<DashboardProps, {}> {
	public componentDidMount() {
		if (!this.props.isLoading && !this.props.teams) {
			this.props.getTeams();
		}
		this.setCurrentTeamFromCache();
	}

	public componentDidUpdate() {
		this.setCurrentTeamFromCache();
	}

	public render(): JSX.Element {
		if (this.props.loadingError) {
			return <ErrorDisplay />;
		}

		return (
			<div>
				<></>
				<PageTitle title="Dashboard" />
				{!this.props.isLoading && this.getDashboard()}
			</div>
		);
	}

	private getDashboard = () => {
		const { currentOrganization, teams, permissions } = this.props;
		var { currentTeam } = this.props;

		if (!currentOrganization) {
			return <ErrorDisplay />;
		}

		if (!teams || teams.length === 0) {
			if (permissions.getOrganizationAccess(currentOrganization) === Edge.Models.PermissionLevels.Edit) {
				return <Redirect to="/manage-teams" />;
			} else {
				return <Redirect to="/my-account" />;
			}
		}

		if (!currentTeam) {
			return <React.Fragment />;
		}

		if (currentTeam.type.id === Edge.Models.TeamTypeId.StationMode) {
			return <Redirect to={'/stats'} />;
		}

		// Check if this is an "Individual" organization
		if (
			currentOrganization.organizationType &&
			currentOrganization.organizationType.id === Edge.Models.OrganizationTypeId.Individual
		) {
			return <AthleteDashboard />;
		}

		const teamRole = permissions.getTeamRole(currentTeam);
		if (
			teamRole &&
			teamRole.teamPermissions === Edge.Models.PermissionLevels.Edit &&
			currentTeam.type.id !== Edge.Models.TeamTypeId.Event
		) {
			return <CoachDashboard />;
		}

		if (teamRole && teamRole.canAddProspects) {
			return <ScoutDashboard />;
		}

		const canEvaluate: boolean = permissions.getTeamRole(currentTeam).canEvaluate;
		if (canEvaluate) {
			return <AthleteDashboard />;
		}

		if (permissions.getOrganizationAccess(currentOrganization) === Edge.Models.PermissionLevels.Edit) {
			return <Redirect to="/manage-teams" />;
		} else {
			return <Redirect to="/my-account" />;
		}
	};

	private setCurrentTeamFromCache = () => {
		const { currentOrganization, currentTeam, setCurrentTeamFromCache, teams } = this.props;
		setCurrentTeamFromCache(currentOrganization ? currentOrganization.id : '', !!currentTeam, teams);
	};
}

function mapStateToProps(state: AppState) {
	const { organizationState, teamState } = state;
	const { currentTeam, teams } = teamState;
	const { currentOrganization } = organizationState;

	let accessibleTeams =
		currentOrganization && teams && teams.filter((i) => i.organizationId === currentOrganization!.id);
	accessibleTeams = accessibleTeams
		? accessibleTeams.filter(
				(team) => getUserPermissionService(state).getTeamAccess(team) >= Edge.Models.PermissionLevels.List
		  )
		: undefined;
	const permissions = getUserPermissionService(state);

	return {
		permissions,
		isLoading: teamState.isLoading || organizationState.isLoading || permissions.isLoading(),
		loadingError: teamState.loadingError || organizationState.loadingError,
		currentOrganization,
		teams: accessibleTeams,
		currentTeam,
	};
}

export default connect(
	mapStateToProps,
	{
		getTeams,
		setCurrentTeamFromCache,
	}
)(Dashboard);
