import * as _ from 'lodash';

import * as Edge from '../core';
import * as StrUtil from './strUtil';

export const getPriceTitle = (price: Edge.Models.Price) => {
	switch (price.priceType) {
		case Edge.Models.PriceTypes.Subscription:
			return `${price.months}-Month Subscription`;
		case Edge.Models.PriceTypes.Sessions:
			return `${price.sessions}-Session Plan`;
		case Edge.Models.PriceTypes.Glasses:
			return '3D Glasses';
		default:
			return 'new item';
	}
};

export const getPriceTitleCount = (price: Edge.Models.Price, count: number) => {
	let unit: string = '';

	switch (price.priceType) {
		case Edge.Models.PriceTypes.Subscription:
			unit = 'seat';
			break;
		case Edge.Models.PriceTypes.Sessions:
			unit = 'set';
			break;
		case Edge.Models.PriceTypes.Glasses:
			unit = 'pair';
			break;
		default:
			unit = 'unit';
	}

	return `${getPriceTitle(price)}, ${count} ${StrUtil.pluralize(unit, count)}`;
};

export const getPriceFeatures = (price: Edge.Models.PriceDetail) => {
	return _.orderBy(
		_.filter(_.keys(price.price.metadata), (key: string) => {
			return Number.isInteger(parseInt(key));
		}),
		(i: string) => i
	).map((ix: string) => price.price.metadata[ix]);
};
