import classNames from 'classnames';
import * as React from 'react';
import * as _ from 'lodash';

import './index.scss';
import * as Edge from '../../core';
import Input from '../global/input';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { getPriceTitle } from '../../utilities/commerceUtil';
import * as StrUtil from '../../utilities/strUtil';

export interface TotalCostProps {
	order?: Edge.Models.CreateCheckoutSessionResponse;
	price: Edge.Models.Price;
	overrideSkuPrice?: number;
	coupon?: Edge.Models.Coupon;
	quantity: number;
	numberOfGlasses: number;
	onQuantityChanged?: (quantity: number) => void;
	allSkus: Edge.Models.Price[];
	allowEdit?: boolean;
}

const isPrimary = (i?: Edge.Models.Price) => {
	return (
		i && (i.priceType === Edge.Models.PriceTypes.Sessions || i.priceType === Edge.Models.PriceTypes.Subscription)
	);
};

const orderQuantityLine = (item: Edge.Models.CheckoutSessionItemResponse, sku?: Edge.Models.Price) => {
	const { amount, quantity, type } = item;
	let { description } = item;
	if (type === Edge.Models.CheckoutSessionItemType.Shipping) {
		description = 'Shipping';
	}
	const perItemPrice = Math.ceil((amount / quantity) * 100) / 100;
	if (!sku || !isPrimary(sku)) {
		if (item.amount) {
			if (item.quantity) {
				return `$${perItemPrice} x ${quantity} ${description}`;
			} else {
				return `$${amount} ${description}`;
			}
		} else {
			if (quantity) {
				return `${quantity} ${description}`;
			} else {
				return `${description}`;
			}
		}
	}
	switch (sku.priceType) {
		case Edge.Models.PriceTypes.Subscription:
			return `${perItemPrice} x ${item.quantity} ${StrUtil.pluralize('seat', item.quantity)}`;
		case Edge.Models.PriceTypes.Sessions:
			return `${perItemPrice} x ${item.quantity}`;
		default:
			return undefined;
	}
};

export class TotalCostBody extends React.PureComponent<TotalCostProps> {
	public render() {
		const { order, price, allSkus, quantity, numberOfGlasses, overrideSkuPrice } = this.props;
		if (order) {
			const skuById = _.mapValues(_.groupBy(allSkus, (i) => i.id), (i) => i[0]);
			const withSku = order.checkoutSessionItems.map((i) => {
				return { sku: skuById[i.id], item: i };
			});
			const primaryItems = withSku.filter((i) => isPrimary(i.sku));
			const secondaryItems = withSku.filter((i) => !isPrimary(i.sku));
			return (
				<>
					{primaryItems.map((i, ix) => (
						<div className="totalCostCard" key={ix}>
							<div className="titleContain">
								<p className="title">{getPriceTitle(i.sku)}</p>
								<p className="secondary">${orderQuantityLine(i.item, i.sku)}</p>
							</div>
							<div className="costContain">
								<p className="cost">${i.item.amount}</p>
							</div>
						</div>
					))}
					{secondaryItems.length > 0 && (
						<div className="totalCostCard">
							<div className="titleContain">
								<p className="title">Total After Glasses</p>
								{secondaryItems.map((i, ix) => {
									return (
										<p className="secondary" key={ix}>
											{orderQuantityLine(i.item, i.sku)}
										</p>
									);
								})}
							</div>
							<div className="costContain">
								<p className="cost">${order.amount}</p>
							</div>
						</div>
					)}
				</>
			);
		} else {
			const glassesSku = allSkus.filter((i) => i.priceType === Edge.Models.PriceTypes.Glasses)[0];
			const amount = overrideSkuPrice === undefined ? price.unitAmount : overrideSkuPrice;
			return (
				<>
					<div className="totalCostCard">
						<div className="titleContain">
							<p className="title">{getPriceTitle(price)}</p>
							<p className="secondary">${this.quantityLine(price, quantity, amount)}</p>
						</div>
						<div className="costContain">
							<p className={classNames('cost', { oldCost: this.props.coupon })}>
								${this.totalPrice(amount * quantity, undefined)}
							</p>
						</div>
						{this.props.coupon && (
							<>
								<div />
								<div className="costContain">
									<p className="cost">${this.totalPrice(amount * quantity, this.props.coupon)}</p>
								</div>
							</>
						)}
					</div>
					{!!numberOfGlasses && !!glassesSku && (
						<div className="totalCostCard">
							<div className="titleContain">
								<p className="title">{getPriceTitle(glassesSku)}</p>
								<p className="secondary">
									${glassesSku.unitAmount} x {numberOfGlasses}
								</p>
							</div>
							<div className="costContain">
								<p
									className={classNames('cost', {
										oldCost: this.props.coupon && this.props.coupon.percentOff,
									})}
								>
									${this.totalPrice(glassesSku.unitAmount * numberOfGlasses, undefined)}
								</p>
							</div>
							{/* since we're only showing the price for this line, any flat-off amount will be in the first line */}
							{this.props.coupon && this.props.coupon.percentOff && (
								<>
									<div />
									<div className="costContain">
										<p className="cost">
											$
											{this.totalPrice(
												glassesSku.unitAmount * numberOfGlasses,
												this.props.coupon
											)}
										</p>
									</div>
								</>
							)}
						</div>
					)}
				</>
			);
		}
	}

	private quantityLine = (sku: Edge.Models.Price, quantity: number, price: number) => {
		const quantityRender = this.props.allowEdit ? (
			<Input type="number" value={quantity} onChange={this.onQuantityChange} min={1} />
		) : (
			quantity
		);
		switch (sku.priceType) {
			case Edge.Models.PriceTypes.Subscription:
				return (
					<span>
						{price} x {quantityRender} {StrUtil.pluralize('Seat', quantity)}
					</span>
				);
			case Edge.Models.PriceTypes.Sessions:
				return (
					<span>
						{price} x {quantityRender} Sets of {sku.sessions}
					</span>
				);
			case Edge.Models.PriceTypes.Glasses:
				return (
					<span>
						{price} x {quantityRender} Glasses
					</span>
				);
			default:
				return undefined;
		}
	};

	private onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (this.props.onQuantityChanged) {
			const newValue = parseInt(e.target.value);
			if (newValue > 0) {
				this.props.onQuantityChanged(newValue);
			}
		}
	};

	private totalPrice = (originalPrice: number, coupon?: Edge.Models.Coupon) => {
		var discountedPrice = originalPrice;

		if (coupon) {
			if (coupon.amountOff) {
				discountedPrice = originalPrice - coupon.amountOff;

				// If less than zero, return 0
				discountedPrice = discountedPrice >= 0 ? discountedPrice : 0;
			} else if (coupon.percentOff) {
				discountedPrice = originalPrice * (1 - coupon.percentOff / 100);
			} else {
				discountedPrice = originalPrice;
			}
		}

		return discountedPrice.toFixed(2);
	};
}

const TotalCost: React.SFC<TotalCostProps> = (props) => {
	return (
		<div className="totalCost">
			<h3 className="cardTitle">Total Cost</h3>
			<TotalCostBody {...props} />
		</div>
	);
};

export default connect(({ priceState }: AppState) => {
	return { allSkus: priceState.data! };
})(TotalCost);
