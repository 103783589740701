import * as Redux from 'redux';
import * as _ from 'lodash';
import * as Edge from './../../core';
import {
	TeamState,
	TeamActionTypes,
	TEAMS_LOADING,
	TEAMS_SUCCESS,
	TEAMS_FAILURE,
	SET_CURRENT_TEAM,
	UPDATE_TEAM,
	TeamUpdateType,
} from './types';
import { LOGIN_CLEAR_PRELOADED_DATA } from '../login/types';

const initialState: TeamState = {
	isLoading: false,
};

const teamReducer: Redux.Reducer<TeamState> = (state = initialState, action: TeamActionTypes): TeamState => {
	switch (action.type) {
		case LOGIN_CLEAR_PRELOADED_DATA:
			return initialState;
		case TEAMS_LOADING:
			return {
				...state,
				isLoading: true,
				loadingError: undefined,
			};
		case TEAMS_SUCCESS:
			const payload = _.orderBy(action.payload, 'name');
			return {
				...state,
				isLoading: false,
				teams: payload,
				currentTeam: state.currentTeam ? payload.filter((i) => i.id === state.currentTeam!.id)[0] : undefined,
				loadingError: undefined,
			};
		case TEAMS_FAILURE:
			return {
				...state,
				isLoading: false,
				loadingError: action.payload as Edge.Models.EdgeError,
				teams: undefined,
				currentTeam: undefined,
			};
		case SET_CURRENT_TEAM:
			const currentTeam = action.payload as (Edge.Models.Team | undefined);
			return {
				...state,
				currentTeam: currentTeam ? (state.teams || []).filter((i) => i.id === currentTeam.id)[0] : undefined,
			};
		case UPDATE_TEAM:
			const updates = action.payload as TeamUpdateType;
			const applyUpdate = (item: Edge.Models.Team) => {
				if (item.id === updates.id) {
					return Object.assign({}, item, updates);
				}
				return item;
			};
			return {
				...state,
				teams: state.teams && state.teams.map(applyUpdate),
				currentTeam: state.currentTeam && applyUpdate(state.currentTeam),
			};
		default:
			return state;
	}
};

export default teamReducer;
