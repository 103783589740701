import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Edge from '../../../core';
import FormField from '../../../components/formField';
import Input from '../../../components/global/input';
import Error from '../../../components/global/error';
import { ModalContainer } from '../../../components/global/modal';
import Loading from '../../../components/loading';
import { UserService } from '../../../services/userService';

export interface EditUserProps {
	initialValues?: Partial<EditUserValues>;
	user: Edge.Models.User;
	email?: string;
	onClose: () => void;
	reloadData: () => Promise<void>;
}

interface EditUserValues {
	firstName?: string;
	lastName?: string;
	email?: string;
	password?: string;
	passwordConfirmation?: string;
}

const normalRequiredField = Yup.string()
	.max(100, 'Too long.')
	.required();

const editUserSchema = Yup.object().shape({
	firstName: normalRequiredField.required('First name is required'),
	lastName: normalRequiredField.required('Last name is required'),
	email: normalRequiredField.email().required('Email is required'),
	password: Edge.Validation.optionalPassword('Password').oneOf(
		[Yup.ref('passwordConfirmation')],
		'Passwords must match.'
	),
	passwordConfirmation: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match.'),
});

export class EditUser extends React.PureComponent<EditUserProps> {
	public render(): JSX.Element {
		const { firstName, lastName } = this.props.user;
		const { initialValues, email } = this.props;
		return (
			<ModalContainer
				open={true}
				title="Edit User"
				onClose={() => {
					this.props.onClose();
				}}
			>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							firstName,
							lastName,
							email,
						},
						initialValues || {}
					)}
					validationSchema={editUserSchema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await this.editUser(values);
						} catch (e) {
							actions.setStatus(Edge.API.getErrorMessage(e));
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<EditUserValues>) => (
						<Form>
							<FormField component="input" type="text" name="firstName" label="First Name" />
							<FormField component="input" type="text" name="lastName" label="Last Name" />
							<FormField component="input" type="text" name="email" label="Email" />
							<FormField component="input" type="password" name="password" label="Password (Optional)" />
							<FormField
								component="input"
								type="password"
								name="passwordConfirmation"
								label="Confirm Password"
							/>
							{props.status && <Error>{props.status}</Error>}
							{props.isSubmitting && <Loading buttonLoader />}
							<Input type="submit" disabled={props.isSubmitting} value="submit" />
						</Form>
					)}
				/>
			</ModalContainer>
		);
	}

	private editUser = async (values: EditUserValues) => {
		const { firstName, lastName, email, password } = values;
		await UserService.editUser({
			userId: this.props.user.id,
			firstName: firstName!,
			lastName: lastName!,
			email: email!,
			newPassword: password!,
		});
		this.props.onClose();
		await this.props.reloadData();
	};
}

export default EditUser;
