import * as React from 'react';

import * as Edge from '../../../core';

import './index.scss';

export interface TrainingPlanProps {
	currentTeam?: Edge.Models.Team;
	trainingPlanResponse: Edge.Models.TrainingPlanResult;
	viewingSelf: boolean;
}

export const TrainingPlan: React.FunctionComponent<TrainingPlanProps> = (props) => {
	const {
		currentTeam,
		trainingPlanResponse: { userFirstName, plan },
		viewingSelf,
	} = props;

	if (currentTeam && currentTeam.type.id === Edge.Models.TeamTypeId.StationMode) {
		return (
			<div className="my_plan_comp">
				Not available in Station Mode. To upgrade this account, please contact{' '}
				<a href={'mailto:support@vizualedge.com'}>support@vizualedge.com</a>.
			</div>
		);
	}

	if (!plan) {
		return (
			<div className="my_plan_comp my_plan_cold_state">
				{viewingSelf
					? "You don't have a training plan yet. Upon completion of your next evaluation, you will be assigned one."
					: "This athlete doesn't have a training plan yet. One will be assigned upon the completion of the next evaluation."}
			</div>
		);
	}

	const currentPlanPhaseIndex = plan.phases.findIndex(
		(phase) => phase.id === props.trainingPlanResponse.currentPlanPhaseId
	);
	const currentPlanPhase = plan.phases[currentPlanPhaseIndex];
	const completedSessionsOfCurrentPhase = props.trainingPlanResponse.sessions.filter(
		(session) =>
			session.completionPercent >= 1 && session.planPhaseId === props.trainingPlanResponse.currentPlanPhaseId
	).length;

	return (
		<div className="my_plan_comp">
			<h4>{viewingSelf ? 'My Plan' : `${userFirstName ? userFirstName : 'Athlete'}'s Plan`}</h4>
			<div className="table_wrapper">
				<table>
					<thead>
						<tr>
							<th className="sessions">Sessions</th>
							<th className="main_block">
								<div className="exercises">Exercises</div>
								<div className="sets">Sets</div>
								<div className="remaining">Remaining</div>
								<div className="days">Days</div>
								<div className="status">Status</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{plan.phases.map((phase, phaseIndex) => (
							<tr key={phaseIndex}>
								<td className="sessions">Week {phaseIndex + 1}</td>
								<td className="main_block">
									<div className="exercises">{phase.exerciseConfigurations.length}</div>
									<div className="sets">{phase.requiredSessions}</div>
									<div className="remaining">
										{Array.from({ length: phase.requiredSessions }, (_, index) => {
											return phaseIndex < currentPlanPhaseIndex ? (
												<span className="positive_bubble bubble" key={index} />
											) : phaseIndex > currentPlanPhaseIndex ? (
												<span className="negative_bubble bubble" key={index} />
											) : completedSessionsOfCurrentPhase > index ? (
												<span className="positive_bubble bubble" key={index} />
											) : (
												<span className="remaining_bubble bubble" key={index}>
													{index + 1}
												</span>
											);
										})}
									</div>
									<div className="days">
										{phaseIndex < currentPlanPhaseIndex
											? ''
											: phaseIndex > currentPlanPhaseIndex
											? phase.durationDays
											: Math.ceil(props.trainingPlanResponse.remainingDaysOfCurrentPlan)}
									</div>
									<div className="status">
										{phaseIndex < currentPlanPhaseIndex ||
										(currentPlanPhaseIndex === phaseIndex &&
											currentPlanPhase &&
											currentPlanPhase.requiredSessions <= completedSessionsOfCurrentPhase) ? (
											<div className="complete">Complete</div>
										) : phaseIndex > currentPlanPhaseIndex ? (
											<div className="not_started">Not Started</div>
										) : (
											<div className="in_progress">In Progress</div>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default TrainingPlan;
