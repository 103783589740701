import * as Edge from '../core';

export abstract class StatsService {
	public static async getEvaluationResults(
		startDateUtc: Date,
		endDateUtc: Date,
		query?: string,
		organizationId?: string,
		teamId?: string
	): Promise<Edge.Models.EvaluationResult[]> {
		var action = `filteredEvaluationResults/${startDateUtc.toJSON()}/${endDateUtc.toJSON()}`;
		action = this.updateEvaluationResultsUrl(action, query, organizationId, teamId);
		const route = Edge.API.ServerRoute.forAction('stats', action);
		return await Edge.API.get(route);
	}

	public static async getEvaluationResultsCsv(
		startDateUtc: Date,
		endDateUtc: Date,
		query?: string,
		organizationId?: string,
		teamId?: string
	): Promise<Edge.Models.File> {
		var action = `filteredEvaluationResults-csv/${startDateUtc.toJSON()}/${endDateUtc.toJSON()}`;
		action = this.updateEvaluationResultsUrl(action, query, organizationId, teamId);
		const route = Edge.API.ServerRoute.forAction('stats', action);
		return await Edge.API.getFile(route);
	}

	public static async getProPercentiles(sessionId: string): Promise<Edge.Models.ProPercentile[]> {
		const route = Edge.API.ServerRoute.forAction('stats', `pro/${sessionId}`);
		return await Edge.API.get(route);
	}

	public static async getMyEvaluationResults(): Promise<Edge.Models.EvaluationResultSummary[]> {
		const route = Edge.API.ServerRoute.forAction('stats', 'me/evaluationResults');
		return await Edge.API.get(route);
	}

	public static async getSkillScores(): Promise<Edge.Models.SkillScores> {
		const route = Edge.API.ServerRoute.forAction('stats', 'me/skillScores');
		return await Edge.API.get(route);
	}

	public static async getTeamScoreResults(
		teamId: string,
		timeSpanDays: number
	): Promise<Edge.Models.ScoreCardResult> {
		const route = Edge.API.ServerRoute.forAction('stats', `evaluationResults/${teamId}/${timeSpanDays}`);
		return await Edge.API.get(route);
	}

	public static async getTopPerformers(teamId: string, timeSpanDays: number): Promise<Edge.Models.TopPerformer[]> {
		const route = Edge.API.ServerRoute.forAction('stats', `topPerformers/${teamId}/${timeSpanDays}`);
		return await Edge.API.get(route);
	}

	public static async getTeamExerciseResults(
		teamId: string,
		timeSpanDays: number
	): Promise<Edge.Models.TeamExerciseResult> {
		const route = Edge.API.ServerRoute.forAction('stats', `teamExercises/${teamId}/${timeSpanDays}`);
		return await Edge.API.get(route);
	}

	public static async getTrainingUsage(
		teamId: string,
		timeSpanDays: number
	): Promise<Edge.Models.TrainingUsageResponse> {
		const route = Edge.API.ServerRoute.forAction('stats', `trainingUsage/${teamId}/${timeSpanDays}`);
		return await Edge.API.get(route);
	}

	public static async getPrintableTrainingUsage(teamId: string, timeSpanDays: number): Promise<Edge.Models.File> {
		const route = Edge.API.ServerRoute.forAction('stats', `trainingUsage/${teamId}/${timeSpanDays}/print`);
		return await Edge.API.getFile(route);
	}

	public static async getTeamMemberStats(teamId: string): Promise<Edge.Models.TeamMemberStats[]> {
		const route = Edge.API.ServerRoute.forAction('stats', `${teamId}`);
		return await Edge.API.get(route);
	}

	public static async getTeamMemberStatsCsv(teamId: string): Promise<Edge.Models.File> {
		const route = Edge.API.ServerRoute.forAction('stats', `${teamId}/csv`);
		return await Edge.API.getFile(route);
	}

	public static async getProspectEvaluationResults(teamId: string): Promise<Edge.Models.ProspectEvaluationResult[]> {
		const route = Edge.API.ServerRoute.forAction('stats', `prospectResults/${teamId}`);
		return await Edge.API.get(route);
	}

	private static updateEvaluationResultsUrl(route: string, query?: string, organizationId?: string, teamId?: string) {
		var queryString = '';

		// build query string
		if (query && query.trim()) queryString += `&query=${encodeURIComponent(query)}`;
		if (organizationId) queryString += `&organizationId=${organizationId}`;
		if (teamId) queryString += `&teamId=${teamId}`;

		// replace the first query string character '&' with '?'
		if (queryString) queryString = '?' + queryString.substring(1, queryString.length);

		return route + queryString;
	}
}
