import * as React from 'react';
import classNames from 'classnames';

import * as Edge from '../../core';
import './index.scss';

export interface SingleScoreProps {
	value: number;
	size: 'small' | 'large';
	zeroValue: number;
	fullValue: number;
	increment: number;
	speed: number;
	precision: number;
}

interface SingleScoreState {
	currentValue: number;
}

export default class SingleScore extends React.PureComponent<SingleScoreProps, SingleScoreState> {
	static defaultProps: Pick<SingleScoreProps, Exclude<keyof SingleScoreProps, 'value'>> = {
		zeroValue: 0,
		size: 'large',
		fullValue: 100,
		increment: 3.32,
		speed: 10,
		precision: 1,
	};
	private timeout?: number;
	constructor(props: SingleScoreProps) {
		super(props);
		this.state = {
			currentValue: props.zeroValue,
		};
	}

	public componentDidMount() {
		this.start();
	}

	public componentDidUpdate(prevProps: SingleScoreProps) {
		if (
			this.props.zeroValue !== prevProps.zeroValue ||
			this.props.fullValue !== prevProps.fullValue ||
			this.props.increment !== prevProps.increment ||
			this.props.speed !== prevProps.speed
		) {
			this.start();
		}
	}

	public render() {
		const { size } = this.props;
		const number =
			this.props.precision === undefined
				? this.state.currentValue
				: this.state.currentValue.toFixed(this.props.precision);
		const pct = Math.min(
			100,
			Math.max(0, Math.ceil((this.state.currentValue / (this.props.fullValue || 100)) * 100))
		);
		return (
			<div className={classNames('single-score', { small: size === 'small', large: size === 'large' })}>
				<div className="number">{number}</div>
				<svg className="graph">
					<linearGradient id="gradient1">
						<stop offset="0%" className="gradient-50" />
						<stop offset="100%" className="gradient-100" />
					</linearGradient>
					<linearGradient id="gradient2">
						<stop offset="0%" className="gradient-50" />
						<stop offset="100%" className="gradient-0" />
					</linearGradient>

					<circle className="circle-bg" />
					<circle className={'circle2 fill-' + pct} />
					<circle className={'circle1 fill-' + pct} />
				</svg>
			</div>
		);
	}

	private start = () => {
		if (this.timeout) {
			window.clearTimeout(this.timeout);
		}
		if (Edge.Configuration.disableScoreAnimation) {
			this.setState({ currentValue: this.props.value });
			return;
		}
		this.setState(
			{
				currentValue: this.props.zeroValue,
			},
			this.queueIncrement
		);
	};

	private doIncrement = () => {
		if (this.props.increment > 0 && this.state.currentValue >= this.props.value) {
			return;
		}
		if (this.props.increment < 0 && this.state.currentValue <= this.props.value) {
			return;
		}
		if (this.props.increment === 0) {
			return;
		}

		this.setState(
			{
				currentValue:
					this.props.increment > 0
						? Math.min(this.state.currentValue + this.props.increment, this.props.value)
						: Math.max(this.state.currentValue + this.props.increment, this.props.value),
			},
			this.queueIncrement
		);
	};

	private queueIncrement = () => {
		this.timeout = window.setTimeout(this.doIncrement, this.props.speed);
	};
}
