import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import * as Edge from '../../core';
import GameDayTraining from './gameDay';
import OpenGymTraining from './openGym';
import MyPlanTraining from './myPlan';
import RedirectToCurrent from './redirectToCurrent';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { PlanService } from '../../services/planService';

export interface TrainingWithPlansProps {
	trainingPlanResult?: Edge.Models.TrainingPlanResult;
	gameDayPlanResult?: Edge.Models.GameDayPlanResult;
}

interface TrainingWithPlansState {}

export class TrainingWithPlans extends React.PureComponent<TrainingWithPlansProps, TrainingWithPlansState> {
	constructor(props: TrainingWithPlansProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { trainingPlanResult, gameDayPlanResult } = this.props;
		return (
			<Switch>
				<Route path="/training/myPlan" render={() => <MyPlanTraining planResult={trainingPlanResult} />} />
				<Route path="/training/openGym" component={OpenGymTraining} />
				<Route path="/training/gameDay" render={() => <GameDayTraining planResult={gameDayPlanResult} />} />

				<Route
					path="/training/current"
					render={() => (
						<RedirectToCurrent
							trainingPlanResult={trainingPlanResult}
							gameDayPlanResult={gameDayPlanResult}
						/>
					)}
				/>

				{/* these are to avoid a redirect loop when finishing gameday or training plan */}
				<Redirect path="/training/myPlan-redirect" to="/training/myPlan" />
				<Redirect path="/training/gameDay-redirect" to="/training/gameDay" />

				{/** let RedirectToCurrent figure out what the best page to be on is **/}
				<Redirect to="/training/current" />
			</Switch>
		);
	}
}

export default withLoadDataDefaultConfig(
	errorLoadingWrapperHOC(TrainingWithPlans, undefined, undefined, undefined, { loadingOptions: { blockItem: true } }),
	() => {},
	async () => {
		const [trainingPlanResult, gameDayPlanResult] = await Promise.all([
			PlanService.getMyTrainingPlan(),
			PlanService.getGameDayPlan(),
		]);
		return { trainingPlanResult, gameDayPlanResult };
	}
);
