import * as React from 'react';
import { connect } from 'react-redux';
import './index.scss';

import { AppState } from '../../../store';
import { AuthenticationService } from '../../../services';
import { getTeamsUserCanAddProspect } from '../../../store/permissions/selector';
import { getUserPermissionService } from '../../../store/permissions/selector';
import { PositionService } from '../../../services/positionService';
import { switchToToken } from '../../../store/login/actions';
import { TeamService } from '../../../services/teamService';
import { UserPermissionService } from '../../../services/userPermissionService';
import { UserService } from '../../../services/userService';
import { withLoadDataDefaultConfig } from '../../../components/loadData';
import * as Edge from '../../../core';
import CreateProspectForm, { CreateProspectFormValues } from './createProspectForm';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import LoginProspectForm, { LoginProspectFormValues } from './loginProspectForm';
import OrganizationTypeService from '../../../services/organizationTypeService';
import TabNav, { TabNavItem } from '../../../components/areaNav/tabNav';
import TeamTypeService from '../../../services/teamTypeService';

export interface CreateProspectProps {
	canListProspects: boolean;
	currentTeam: Edge.Models.Team;
	isEvent: boolean;
	permissions: UserPermissionService;
	positions?: Edge.Models.Position[];
	prospects?: Edge.Models.TeamProspect[];
	switchToToken: (token: string, redirectPath: string) => Promise<void>;
}

interface CreateProspectState {
	activeTab: number;
}

export class CreateProspect extends React.PureComponent<CreateProspectProps, CreateProspectState> {
	private static readonly loginProspectTabId = 0;
	private static readonly createProspectTabId = 1;

	private readonly tabItems: TabNavItem[];

	constructor(props: CreateProspectProps) {
		super(props);
		this.state = {
			activeTab: CreateProspect.loginProspectTabId,
		};
		this.tabItems = [
			{ text: 'Login', onClick: this.gotoTab.bind(this, CreateProspect.loginProspectTabId) },
			{ text: 'Create', onClick: this.gotoTab.bind(this, CreateProspect.createProspectTabId) },
		];
	}

	public render() {
		const { isEvent, positions, prospects } = this.props;

		return (
			<div className="create_prospect">
				{prospects && prospects.length > 0 ? (
					<>
						<TabNav items={this.tabItems} current={this.state.activeTab} />
						<div className="athlete_stats_detail_content">
							<div className="inner_wrapper">
								{this.state.activeTab === CreateProspect.loginProspectTabId && (
									<LoginProspectForm onSubmit={this.loginProspect} prospects={prospects} />
								)}
								{this.state.activeTab === CreateProspect.createProspectTabId && (
									<CreateProspectForm
										isEvent={isEvent}
										onSubmit={this.createProspect}
										positions={positions}
									/>
								)}
							</div>
						</div>
					</>
				) : (
					<>
						<div className="title">
							<h4>Create Prospect</h4>
						</div>
						<div className="inner_wrapper">
							<CreateProspectForm
								isEvent={isEvent}
								onSubmit={this.createProspect}
								positions={positions}
							/>
						</div>
					</>
				)}
			</div>
		);
	}
	private createProspect = async (values: CreateProspectFormValues) => {
		const prospectToken = await UserService.createProspect({
			email: values.email,
			firstName: values.firstName,
			lastName: values.lastName,
			positionId: values.positionId,
			teamId: this.props.currentTeam.id,
		});
		this.switchTokens(prospectToken);
	};

	private gotoTab = (activeTab: number, e: React.MouseEvent) => {
		e.preventDefault();
		this.setState({ activeTab });
	};

	private loginProspect = async (values: LoginProspectFormValues) => {
		const prospectToken = await UserService.loginProspect({
			prospectUserId: values.prospectUserId,
			teamId: this.props.currentTeam.id,
		});
		this.switchTokens(prospectToken);
	};

	private switchTokens = async (token: string) => {
		// stash old token and log in with the new one
		AuthenticationService.stashToken();
		await this.props.switchToToken(token, '/training/evaluation');

		// User will be prompted for registration profile fields via /terms before they get to the evaluation screen
	};
}

function mapStateToProps(state: AppState) {
	const { currentOrganization } = state.organizationState;
	const { currentTeam } = getTeamsUserCanAddProspect(state);
	const permissions = getUserPermissionService(state);

	const canListProspects =
		currentTeam &&
		permissions.getTeamAccess(currentTeam) >= Edge.Models.PermissionLevels.List &&
		OrganizationTypeService.isEvent(currentOrganization);
	const isEvent = TeamTypeService.isEventTeam(currentTeam!.type);

	return {
		canListProspects: !!canListProspects,
		currentTeam: currentTeam!,
		isEvent,
		permissions,
	};
}

export default connect(
	mapStateToProps,
	{
		switchToToken,
	}
)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(CreateProspect, undefined, undefined, undefined, {
			loadingOptions: { blockItem: true },
		}),
		(props: CreateProspectProps) => {
			return {
				canListProspects: props.canListProspects,
				isEvent: props.isEvent,
				sportId: (props.currentTeam && props.currentTeam.sport.id) || '',
				teamId: (props.currentTeam && props.currentTeam.id) || '',
			};
		},
		async ({
			canListProspects,
			isEvent,
			sportId,
			teamId,
		}: {
			canListProspects: boolean;
			isEvent: boolean;
			sportId: string;
			teamId: string;
		}) => {
			const positions = (isEvent && (await PositionService.getAllForSport(sportId))) || undefined;
			const prospects = (canListProspects && (await TeamService.getProspects(teamId))) || [];
			return {
				positions,
				prospects,
			};
		}
	)
);
