// general
export const Company: string = 'VizualEdge';
export const EMPTY_STRING: string = '';

// cache keys
export const CACHE_EDGE_TOKEN: string = 'edge_token';
export const CACHE_EDGE_TOKEN_ORIGINAL: string = 'edge_token_original';
export const CACHE_CURRENT_ORGANIZATION_ID: string = 'orgid';
export const CACHE_CURRENT_DEVICEPROFILE_ID: string = 'deviceprofileid';

// cache expiration
export const CACHE_CURRENT_ORGANIZATION_ID_EXPIRATION_MINUTES: number = 60 * 24 * 30; // 30 days
export const CACHE_CURRENT_DEVICEPROFILE_ID_EXPIRATION_MINUTES: number = 60 * 24 * 180; // 180 days

export const ROLES = {
	COACH: 'a60314f1-0bbf-4376-b757-ed9d352f2721',
	ATHLETE: 'c3a9cd6e-529c-4105-ab12-61e95ffda5b4',
	SCOUT: '66ed2d8a-d2ed-4274-8db6-983f960638a9',
	PROSPECT: 'd22362fc-8c2e-4090-9960-db8cf2b66b16',
};

export const USER_EXISTS_ERROR_MESSAGE = 'User already exists.';
export const ORGANIZATION_NAME_PLACEHOLDER = 'E.g. John Smith Baseball or Chicago HS Football';
export const YOUTH_LEVEL_LONG_DESCRIPTION = 'Youth (Under 18)';

export const STRIPE = {
	SCRIPT_URL: 'https://js.stripe.com/v3/',
	ELEMENT_ID: 'stripe-js',
};

export const IMAGES = {
	MAX_IMAGE_SIZE: 300000,
	MAX_IMAGE_WIDTH: 200,
};

export const US_COUNTRY_CODE = 'US';

export const US_STATES = [
	{ data: 'AL', label: 'Alabama' },
	{ data: 'AK', label: 'Alaska' },
	{ data: 'AZ', label: 'Arizona' },
	{ data: 'AR', label: 'Arkansas' },
	{ data: 'AA', label: 'Armed Forces America' },
	{ data: 'AE', label: 'Armed Forces Europe' },
	{ data: 'AP', label: 'Armed Forces Pacific' },
	{ data: 'CA', label: 'California' },
	{ data: 'CO', label: 'Colorado' },
	{ data: 'CT', label: 'Connecticut' },
	{ data: 'DE', label: 'Delaware' },
	{ data: 'DC', label: 'District of Columbia' },
	{ data: 'FL', label: 'Florida' },
	{ data: 'GA', label: 'Georgia' },
	{ data: 'HI', label: 'Hawaii' },
	{ data: 'ID', label: 'Idaho' },
	{ data: 'IL', label: 'Illinois' },
	{ data: 'IN', label: 'Indiana' },
	{ data: 'IA', label: 'Iowa' },
	{ data: 'KS', label: 'Kansas' },
	{ data: 'KY', label: 'Kentucky' },
	{ data: 'LA', label: 'Louisiana' },
	{ data: 'ME', label: 'Maine' },
	{ data: 'MD', label: 'Maryland' },
	{ data: 'MA', label: 'Massachusetts' },
	{ data: 'MI', label: 'Michigan' },
	{ data: 'MN', label: 'Minnesota' },
	{ data: 'MS', label: 'Mississippi' },
	{ data: 'MO', label: 'Missouri' },
	{ data: 'MT', label: 'Montana' },
	{ data: 'NE', label: 'Nebraska' },
	{ data: 'NV', label: 'Nevada' },
	{ data: 'NH', label: 'New Hampshire' },
	{ data: 'NJ', label: 'New Jersey' },
	{ data: 'NM', label: 'New Mexico' },
	{ data: 'NY', label: 'New York' },
	{ data: 'NC', label: 'North Carolina' },
	{ data: 'ND', label: 'North Dakota' },
	{ data: 'OH', label: 'Ohio' },
	{ data: 'OK', label: 'Oklahoma' },
	{ data: 'OR', label: 'Oregon' },
	{ data: 'PA', label: 'Pennsylvania' },
	{ data: 'RI', label: 'Rhode Island' },
	{ data: 'SC', label: 'South Carolina' },
	{ data: 'SD', label: 'South Dakota' },
	{ data: 'TN', label: 'Tennessee' },
	{ data: 'TX', label: 'Texas' },
	{ data: 'UT', label: 'Utah' },
	{ data: 'VT', label: 'Vermont' },
	{ data: 'VA', label: 'Virginia' },
	{ data: 'WA', label: 'Washington' },
	{ data: 'WV', label: 'West Virginia' },
	{ data: 'WI', label: 'Wisconsin' },
	{ data: 'WY', label: 'Wyoming' },
];

export const DATE_FORMAT = 'M/DD/YYYY';
export const FILE_DATE_FORMAT = 'DD-MM-YYYY';

export const REGISTER_NO_SKU_URL = 'https://vizualedge.com/contact';

export const LOCALSTORAGE_DISABLE_ANALYTICS = 'DISABLE_ANALYTICS';
export const LOCALSTORAGE_DISABLE_AUTOMATIC_TOKEN_REFRESH = 'DISABLE_AUTOMATIC_TOKEN_REFRESH';
export const LOCALSTORAGE_DISABLE_PRELOAD = 'DISABLE_PRELOAD';
export const LOCALSTORAGE_DISABLE_SCORE_ANIMATION = 'DISABLE_SCORE_ANIMATION';
export const LOCALSTORAGE_DOM_ROOT_CLASS = 'DOM_ROOT_CLASS';
export const LOCALSTORAGE_ENABLE_COMPLETE_EXERCISE = 'ENABLE_COMPLETE_EXERCISE';
export const LOCALSTORAGE_ORG_CURRENT_TEAM_IDS = 'ORG_CURRENT_TEAM_IDS';
export const LOCALSTORAGE_SHOW_JSON_DEBUG = 'SHOW_JSON_DEBUG';

export const URL_RESOURCES = 'https://vizualedge.com/resources';

export const VIDEOS = {
	ALIGNMENT: 'https://www.youtube.com/embed/mxIzKcfqqbw',
	CONTRAST_SENSITIVITY: 'https://www.youtube.com/embed/HSvifUosmeA',
	CONTRAST_TRACKING: 'https://www.youtube.com/embed/Adk87gZegh4',
	DEPTH: 'https://www.youtube.com/embed/jIyiuUZjdfg',
	FLEXIBILITY: 'https://www.youtube.com/embed/4ydDZdP-TI8',
	PURSUITS: 'https://www.youtube.com/embed/qNW9CYqv6Hw',
	RECOGNITION: 'https://www.youtube.com/embed/z3hkkDTH_7U',
	TRACKING: 'https://www.youtube.com/embed/_NiY5TWQYfA',
};
