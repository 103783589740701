// lib
import './../js/lib/underscore.min.js';
import './../js/lib/paper-full.js';
// howler has been replaced via npm package since importing it loads it to the global namespace
import './../js/lib/bigscreen.min.js';

declare global {
  interface Window {
    _: any,
    paper: any,
    BigScreen: any
  }
}
window._ = require('./../js/lib/underscore.min.js');
window.paper = require('./../js/lib/paper-full.js');
window.BigScreen = require('./../js/lib/bigscreen.min.js');
