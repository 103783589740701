import * as React from 'react';
import AnalyticsService from '../../services/analyticsService';
import ErrorDisplay from './errorDisplay';

export interface ErrorHandlerProps {
	errorComponent?: React.ComponentClass;
	render: () => JSX.Element;
}

interface ErrorHandlerState {
	hasRenderError: boolean;
}

export default class ErrorHandler extends React.Component<ErrorHandlerProps, ErrorHandlerState> {
	constructor(props: ErrorHandlerProps) {
		super(props);
		this.state = { hasRenderError: false };
	}
	public static getDerivedStateFromError(e: Error) {
		AnalyticsService.exception(e);
		console.error(e);
		return { hasRenderError: true };
	}
	public render() {
		const { hasRenderError } = this.state;
		const { errorComponent: ErrorComponent, render } = this.props;

		if (hasRenderError) {
			if (ErrorComponent) {
				return <ErrorComponent />;
			} else {
				return <ErrorDisplay />;
			}
		}

		return render();
	}
}
