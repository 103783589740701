import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as Edge from '../../../core';
import { AppState } from '../../../store';
import { UserPermissionService } from '../../../services/userPermissionService';
import { getUserPermissionService } from '../../../store/permissions/selector';
import { withLoadDataDefaultConfig } from '../../../components/loadData';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { SessionService } from '../../../services/sessionService';
import { PlanService } from '../../../services/planService';

import './index.scss';

export interface TrainingBannerProps {
	credits: Edge.Models.MyCreditsResponse;
	currentTeam?: Edge.Models.Team;
	permissions: UserPermissionService;
	canEvaluate: boolean;
}

export class TrainingBanner extends React.PureComponent<TrainingBannerProps, {}> {
	public render(): JSX.Element {
		const { sessionCredits, subscriptionCredits } = this.props.credits;

		if (!this.canEvaluate()) {
			return <React.Fragment />;
		}

		return (
			<div className="training_banner">
				<div>
					<h3>Edge Trainer</h3>
					{!subscriptionCredits && (
						<span className="sessions_remaining_label">
							<span className="number_of_sessions">{sessionCredits}</span>
							<span> Sessions Remaining</span>
						</span>
					)}
				</div>
				<div>
					{this.props.canEvaluate && (
						<Link to="/training/evaluation" className="cta_btn btn_bright btn_small">
							Take Evaluation
						</Link>
					)}
					<Link to="/training" className="cta_btn btn_small">
						Start Training
					</Link>
				</div>
			</div>
		);
	}

	private canEvaluate = () => {
		return this.props.permissions.getTeamRole(this.props.currentTeam).canEvaluate;
	};
}

function mapStateToProps(state: AppState) {
	const { currentTeam } = state.teamState;
	const permissions = getUserPermissionService(state);
	return { currentTeam, permissions };
}

export default connect(mapStateToProps)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(TrainingBanner, (props) => !!props.currentTeam),
		(props) => {
			return { teamId: props.currentTeam!.id };
		},
		async (props) => {
			const [credits, canEvaluate] = await Promise.all([
				SessionService.getCredits(props.teamId),
				PlanService.getCanEvaluate(),
			]);
			return { credits, canEvaluate };
		}
	)
);
