import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';

import * as Edge from '../../../core';
import PageTitle from '../../../components/pageTitle';
import ScoreCard from '../../dashboard/scoreCard';
import { AppState } from '../../../store';
import AthleteDetail from './detail';
import MainRow from '../../../layouts/baseLayouts/mainRow';
import { AllTeamsBanner } from '../../../components/teamBanner';

import './index.scss';
import { withLoadDataDefaultConfig } from '../../../components/loadData';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { TeamService } from '../../../services/teamService';
import { getUserPermissionService } from '../../../store/permissions/selector';
import { setCurrentTeam } from '../../../store/team/actions';
import { PermissionLevels } from '../../../core/models';

interface AthleteStatsRouteProps {
	userId: string;
	teamId: string;
}

interface AthleteStatsProps extends RouteComponentProps<AthleteStatsRouteProps> {
	userId?: string;
	teams?: Edge.Models.Team[];
	currentTeam?: Edge.Models.Team;
	teamMembers?: Edge.Models.TeamMember[];
	teamAccess: (team: Edge.Models.Team) => Edge.Models.PermissionLevel;
	setCurrentTeam: (team: Edge.Models.Team) => void;
}

export class AthleteStats extends React.Component<AthleteStatsProps> {
	public componentDidMount() {
		this.ensureSetCurrentTeam(this.props.match.params.teamId);
	}

	public render(): JSX.Element {
		const { match } = this.props;
		// Mirrors check in Stats component
		const hasOneMember = this.props.teamMembers && this.props.teamMembers.length === 1;
		const viewingSelf = match.params.userId === this.props.userId;

		return (
			<div className="athlete_stats">
				<PageTitle title="Stats" />
				<MainRow>
					{viewingSelf || hasOneMember ? (
						<AllTeamsBanner />
					) : (
						<Link className="back_btn" to="/stats">
							Back to Team
						</Link>
					)}
				</MainRow>
				<MainRow>
					<ScoreCard {...match.params} type="athlete" />
					<AthleteDetail {...match.params} viewingSelf={viewingSelf} />
				</MainRow>
			</div>
		);
	}

	private ensureSetCurrentTeam(teamId: string) {
		if (!this.props.currentTeam && this.props.teams) {
			this.props.setCurrentTeam(this.props.teams.filter((i) => i.id === teamId)[0]);
		}
	}
}

function mapStateToProps(state: AppState) {
	const userId = state.loginState.decodedToken && state.loginState.decodedToken.sub;
	const permissions = getUserPermissionService(state);
	const teamAccess = permissions.getTeamAccess;
	const { teams, currentTeam } = state.teamState;
	return { teamAccess, teams, currentTeam, userId };
}

export default connect(
	mapStateToProps,
	{
		setCurrentTeam,
	}
)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(AthleteStats, undefined, undefined, undefined, {
			loadingOptions: { blockItem: true },
		}),
		(props: AthleteStatsProps) => props,
		async (props) => {
			if (props.currentTeam) {
				// Permissions check that mirrors check in endpoint /teams/{teamId}/users
				if (props.teamAccess(props.currentTeam) >= PermissionLevels.Details) {
					const teamMembers = props.currentTeam && (await TeamService.getMembers(props.currentTeam.id));
					return { teamMembers };
				}
			}

			return props;
		}
	)
);
