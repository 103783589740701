import * as ReduxThunk from 'redux-thunk';
import * as Edge from './../../core';
import { UPDATE_TIME_SPAN } from './types';
import { AppState } from '..';

export const updateTimeSpanAction = (timeSpan: Edge.Models.TimeSpan) => {
	return {
		type: UPDATE_TIME_SPAN,
		payload: timeSpan,
	};
};

export const updateTimeSpan = (
	timeSpan: Edge.Models.TimeSpan
): ReduxThunk.ThunkAction<void, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(updateTimeSpanAction(timeSpan));
};
