import * as Edge from './../../core';

export interface TimeSpanState {
	timeSpan: Edge.Models.TimeSpan;
}

export const UPDATE_TIME_SPAN = 'UPDATE_TIME_SPAN';

interface UpdateTimeSpan extends Edge.Models.EdgeAction<Edge.Models.TimeSpan, typeof UPDATE_TIME_SPAN> {
	payload: Edge.Models.TimeSpan;
}

export type TimeSpanActionTypes = UpdateTimeSpan;
