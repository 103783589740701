import * as React from 'react';

import * as Edge from '../../../core';
import Error from '../../../components/global/error';
import { ModalContainer, ModalContainerProps } from '../modal';

import './index.scss';
import Loading from '../../loading';

export interface ConfirmModalProps extends Pick<ModalContainerProps, Exclude<keyof ModalContainerProps, 'onClose'>> {
	onConfirm: () => Promise<void>;
	onCancel: () => void;
	confirmText?: string;
	cancelText?: string;
}

export interface ConfirmModalState {
	isConfirming?: boolean;
	confirmError?: Edge.Models.EdgeError;
}

export default class ConfirmModal extends React.PureComponent<ConfirmModalProps, ConfirmModalState> {
	constructor(props: ConfirmModalProps) {
		super(props);
		this.state = {};
	}
	public render(): JSX.Element {
		const { onConfirm, onCancel, confirmText, cancelText, ...rest } = this.props;
		const { isConfirming, confirmError } = this.state;
		return (
			<ModalContainer {...rest} onClose={onCancel}>
				{this.props.children}
				{confirmError && <Error>{confirmError}</Error>}
				{isConfirming && <Loading buttonLoader />}
				<div className="confirmBtnWrapper">
					<button className="cta_btn" disabled={isConfirming} onClick={this.confirm}>
						{confirmText || 'Confirm'}
					</button>
					<button className="cta_btn" disabled={isConfirming} onClick={onCancel}>
						{cancelText || 'Cancel'}
					</button>
				</div>
			</ModalContainer>
		);
	}

	private confirm = async () => {
		try {
			this.setState({ isConfirming: true });
			await this.props.onConfirm();
			this.setState({ isConfirming: false });
		} catch (confirmError) {
			this.setState({ confirmError, isConfirming: false });
		}
	};
}
