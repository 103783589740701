import * as React from 'react';
import { Router, BrowserRouter } from 'react-router-dom';

import history from '../../services/history';

export default class CustomBrowserRouter extends BrowserRouter {
	public render() {
		return <Router history={history}>{this.props.children}</Router>;
	}
}
