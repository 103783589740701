import * as Edge from './../core';
import { LoginState } from '../store/login/types';
import { PermissionState } from '../store/permissions/types';
import { mergePermissions } from '../store/permissions/reducers';

const noAccess: Edge.Models.AssignedRole = {
	entityId: 'noAccess',
	canEvaluate: false,
	canAddProspects: false,
	isNormalUser: false,
	organizationPermissions: Edge.Models.PermissionLevels.Forbidden,
	teamPermissions: Edge.Models.PermissionLevels.Forbidden,
};

const globalAdminAccess: Edge.Models.AssignedRole = {
	entityId: 'globalAdminAccess',
	canEvaluate: false,
	canAddProspects: true,
	isNormalUser: true,
	organizationPermissions: Edge.Models.PermissionLevels.Edit,
	teamPermissions: Edge.Models.PermissionLevels.Edit,
};

export class UserPermissionService {
	// eslint-disable-next-line
	constructor(private loginState: LoginState, private permissionState: PermissionState) {}

	public isLoading = () => {
		return this.loginState.isLoading || this.permissionState.isLoading;
	};

	public ready = () => {
		return (
			!this.isLoading() &&
			this.loginState.authenticated &&
			this.loginState.decodedToken &&
			this.permissionState.permissions
		);
	};

	public loadingError = () => {
		return this.loginState.error || this.permissionState.loadingError;
	};

	public getOrganizationRole = (
		idOrObject?: Edge.Models.Organization | string | undefined
	): Edge.Models.AssignedRole => {
		if (!idOrObject) {
			return noAccess;
		}
		const id = typeof idOrObject === 'string' ? idOrObject : idOrObject.id;
		if (!this.ready()) {
			return noAccess;
		}
		const { decodedToken } = this.loginState;
		const permissions = this.permissionState.permissions!.organizations[id];
		return mergePermissions(permissions || noAccess, decodedToken!.is_admin ? globalAdminAccess : noAccess);
	};

	public getTeamRole = (idOrObject?: Edge.Models.Team | string | undefined): Edge.Models.AssignedRole => {
		if (!idOrObject) {
			return noAccess;
		}
		const id = typeof idOrObject === 'string' ? idOrObject : idOrObject.id;
		if (!this.ready()) {
			return noAccess;
		}
		const { decodedToken } = this.loginState;
		const permissions = this.permissionState.permissions!.teams[id];
		return mergePermissions(permissions || noAccess, decodedToken!.is_admin ? globalAdminAccess : noAccess);
	};

	public getOrganizationAccess = (idOrObject?: Edge.Models.Organization | string | undefined) => {
		return this.getOrganizationRole(idOrObject).organizationPermissions;
	};

	public getTeamAccess = (idOrObject?: Edge.Models.Team | string | undefined) => {
		return this.getTeamRole(idOrObject).teamPermissions;
	};

	public hasOrganizationAccess = (
		permissionLevel: Edge.Models.PermissionLevel,
		idOrObject: Edge.Models.Organization | string | undefined
	) => {
		return this.getOrganizationAccess(idOrObject) >= permissionLevel;
	};

	public hasTeamAccess = (
		permissionLevel: Edge.Models.PermissionLevel,
		idOrObject: Edge.Models.Team | string | undefined
	) => {
		return this.getTeamAccess(idOrObject) >= permissionLevel;
	};
}
