/* eslint-disable */
$( function() {
	
	VizEdge.ReadyGo = function() {
	    this.initialize.apply(this, arguments);
	};
	
	_.extend( VizEdge.ReadyGo.prototype, {
				
		initialize: function( whenDone ) {			
      this.whenDone = whenDone;  
      
      _.bindAll( this, "onFrame", "zoomStep", "finalStep" );
      
      var goStep = _.partial( this.zoomStep, "Go!", this.finalStep );  
      this.zoomStep( "Ready!", goStep );
      
      paper.view.on('frame', this.onFrame );
      
    },
    
    zoomStep: function( text, nextStep ) {
      
      var zoomText = new paper.PointText(paper.view.center);
      zoomText.content = text;   
      zoomText.fillColor = "#3266cc";
      zoomText.fontSize = 32;
      
      this.currentStep = { sprite: zoomText, nextStep: nextStep };
    },
    
    finalStep: function() {
      this.currentStep = null;
      paper.view.off('frame', this.onFrame );
      this.whenDone();
    },
        
    onFrame: function(event) {
      
      if( this.currentStep ) {
        var sprite = this.currentStep.sprite;
             
        if( sprite.opacity > 0.0 ) {
          sprite.scale(1.03);
          
          if( sprite.scaling.x > 4 ) {
            sprite.opacity -= 0.03;
          }

          if( sprite.opacity <= 0.0 ) {
            sprite.opacity = 0.0;
            this.currentStep.nextStep();
          }
        }
      }
      
    }
    					
	});
	
});
			
