import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../core';
import CardRegister from '../../components/cardRegister';
import CreateAccountForm from '../../components/createAccountForm';
import { CreateAccountFormValues } from '../../components/createAccountForm/createAccountForm';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import { UserService } from '../../services/userService';
import { login } from '../../store/login/actions';
import history from '../../services/history';

import { RegisterViaInviteProps } from './props';

export interface CreateAccountProps extends RegisterViaInviteProps {
	login: (command: Edge.Models.LoginUserCommand) => Promise<void>;
	sportId?: string;
	roleId?: string;
}

export class CreateAccount extends React.Component<CreateAccountProps> {
	public render() {
		return (
			<>
				<h1>Create An Account</h1>
				<p className="headerSecondary">Provide us with your information.</p>
				<CardRegister>
					<div className="left">
						<CreateAccountForm onSubmit={this.createAccount} sportId={this.props.sportId} />
					</div>
				</CardRegister>
			</>
		);
	}
	private createAccount = async (values: CreateAccountFormValues) => {
		await UserService.createUser({
			email: values.email!,
			firstName: values.firstName!,
			lastName: values.lastName!,
			username: values.username!,
			password: values.password!,
			wasAtLeast13YearsOldAtRegistration: values.wasAtLeast13YearsOldAtRegistration!,
			agreedToCookiesAtRegistration: values.agreedToCookiesAtRegistration!,
			agreedToLicenseAgreementAtRegistration: values.agreedToLicenseAgreementAtRegistration!,
			recaptchaConfirmation: values.recaptchaConfirmation!,
		});
		await this.props.login({
			username: values.username!,
			password: values.password!,
		});
		history.push(`/invite/complete/${this.props.match.params.token}/${values.positionId}`);
	};
}

export default connect(
	() => {
		return {};
	},
	{ login }
)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(
			CreateAccount,
			() => true,
			(message, error) => message === 'Token not found.' || message === 'This token has already been fulfilled.'
		),
		(props) => {
			return { token: props.match.params.token };
		},
		async ({ token }: { token: string }) => {
			const metadata = await UserService.getInviteTokenMetadata(token);
			return metadata;
		}
	)
);
