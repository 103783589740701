import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import moment from 'moment';
import * as _ from 'lodash';
import DatePicker from 'react-datepicker';

import * as Edge from '../../../core';
import { AppState } from '../../../store';
import Select from '../../../components/global/select';
import Input from '../../../components/global/input';
import Error from '../../../components/global/error';
import Loading from '../../../components/loading';
import Table from '../../../components/table';
import { AlignmentHelper } from '../../exercises/align/alignHelper';
import { StatsService } from '../../../services/statsService';
import AppPageContainer from '../../../components/pageContainer';
import DownloadButton from '../../../components/downloadButton';
import { SessionService } from '../../../services/sessionService';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

interface AdminEdgeScoresProps {
	isAdmin?: boolean;
	organizations?: Edge.Models.Organization[];
	teams?: Edge.Models.Team[];
}

interface AdminEdgeScoresState {
	results?: Edge.Models.EvaluationResult[];
	organization?: Edge.Models.Organization;
	team?: Edge.Models.Team;
	startDate: Date;
	endDate: Date;
	name?: string;
	loading: boolean;
	error?: Edge.Models.EdgeError;
}

const EMPTY_VALUE = '_';
const pageSize = 50;

const availableSearches: ((item: Edge.Models.EvaluationResult) => string | undefined)[] = [
	(item) => `${item.user.firstName} ${item.user.lastName}`,
	(item) => item.teamName,
];

export class AdminEdgeScores extends React.Component<AdminEdgeScoresProps, AdminEdgeScoresState> {
	constructor(props: AdminEdgeScoresProps) {
		super(props);
		this.state = {
			startDate: moment()
				.subtract(1, 'week')
				.toDate(),
			endDate: new Date(),
			loading: false,
		};
	}
	public render(): JSX.Element {
		const { isAdmin, organizations, teams } = this.props;
		const { results, organization, team, startDate, endDate, name, loading, error } = this.state;

		if (!isAdmin) {
			return <Redirect to="/" />;
		}

		return (
			<>
				<div className="admin_score_search">
					<AppPageContainer>
						<div className="select_line">
							<div>
								<div className="admin_search_label">Select Organization</div>
								<Select
									value={organization ? organization.id : EMPTY_VALUE}
									onChange={this.organizationChanged}
								>
									<option key={EMPTY_VALUE} value={EMPTY_VALUE} />
									{_.sortBy(organizations, 'name').map((i, ix) => (
										<option key={ix} value={i.id}>
											{i.name}
										</option>
									))}
								</Select>
							</div>
							{organization && (
								<div>
									<div className="admin_search_label">Select Team (optional)</div>
									<Select value={team ? team.id : EMPTY_VALUE} onChange={this.teamChanged}>
										<option key={EMPTY_VALUE} value={EMPTY_VALUE} />
										{_.sortBy(
											teams
												? teams.filter((team) => team.organizationId === organization.id)
												: [],
											'name'
										).map((i, ix) => (
											<option key={ix} value={i.id}>
												{i.name}
											</option>
										))}
									</Select>
								</div>
							)}
						</div>
						<div className="date_wrapper">
							<div className="admin_search_label">Select Date Range</div>
							<DatePicker
								selected={startDate}
								selectsStart
								startDate={startDate}
								endDate={endDate}
								onChange={this.startDateChanged}
								maxDate={endDate}
							/>
							<DatePicker
								selected={endDate}
								selectsEnd
								startDate={startDate}
								endDate={endDate}
								onChange={this.endDateChanged}
								minDate={startDate}
							/>
						</div>
						<div>
							<div className="admin_search_label">Enter Name</div>
							<Input className="name_input" type="text" value={name} onChange={this.nameChanged} />
						</div>
						{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
						{loading && <Loading buttonLoader />}
						<div className="get_results_buttons">
							<button className="cta_btn" onClick={this.getResults}>
								Get Results
							</button>
							<DownloadButton
								className="admin_score_search_download"
								defaultFileName={this.getDefaultFilename('evaluations', 'csv')}
								onClick={this.getResultsCsv}
							>
								Get Results as CSV
							</DownloadButton>
						</div>

						<Table
							items={results || []}
							renderHeader={() => 'Edge Scores'}
							availableSearches={availableSearches}
							searchPlaceholderText={'Search'}
							renderTableHeader={(classNames, toggleSort) => {
								return (
									<tr>
										<th>Name</th>
										<th>Team</th>
										<th>Date</th>
										<th>Edge</th>
										<th>Align</th>
										<th>Depth</th>
										<th>Conv</th>
										<th>Station</th>
										<th>Div</th>
										<th>Station</th>
										<th>Rec</th>
										<th>r/t</th>
										<th>Track</th>
										<th>r/t</th>
										<th />
									</tr>
								);
							}}
							renderTableItem={(i) => {
								return i.user ? (
									<tr>
										<td>
											{i.user.lastName}, {i.user.firstName}
										</td>
										<td>{i.teamName}</td>
										<td>
											{moment(i.completedDateUtc)
												.local()
												.startOf('day')
												.format(Edge.Constants.DATE_FORMAT)}
										</td>
										<td>{i.edgeScore && (i.edgeScore * 100).toFixed(1)}</td>
										<td>
											{i.eyeAlignment &&
												AlignmentHelper.getScoreText({ horizontalAlignment: i.eyeAlignment })}
										</td>
										<td>{i.depth && (i.depth * 100).toFixed(1)}</td>
										<td>{i.convergencePercentage && (i.convergencePercentage * 100).toFixed(1)}</td>
										<td>{i.convergenceStationScore}</td>
										<td>{i.divergencePercentage && (i.divergencePercentage * 100).toFixed(1)}</td>
										<td>{i.divergenceStationScore}</td>
										<td>{i.recognitionPercentage && (i.recognitionPercentage * 100).toFixed(1)}</td>
										<td>
											{i.recognitionResponseTime && (i.recognitionResponseTime / 1000).toFixed(2)}
										</td>
										<td>{i.trackingPercentage && (i.trackingPercentage * 100).toFixed(1)}</td>
										<td>{i.trackingResponseTime && (i.trackingResponseTime / 1000).toFixed(2)}</td>
										<td className="download_column">
											<DownloadButton
												defaultFileName={this.getDefaultFilename('evaluation_result', 'pdf')}
												onClick={this.getPrintableSession.bind(this, i.id)}
											/>
										</td>
									</tr>
								) : (
									<></>
								);
							}}
							renderNoItems={() => (
								<tr>
									<td colSpan={4}>No results.</td>
								</tr>
							)}
							pagingMode="numbers"
							pageSize={pageSize}
						/>
					</AppPageContainer>
				</div>
			</>
		);
	}

	private getPrintableSession = (id: string) => {
		return SessionService.getPrintableSession(id);
	};

	private getDefaultFilename = (type: string, ext: string) => {
		const { organization } = this.state;
		const name = organization ? organization.name : 'unknown';

		return `${type}_${name}_${moment().format(Edge.Constants.FILE_DATE_FORMAT)}.${ext}`;
	};

	private getResults = async () => {
		const { organization, team, name, startDate, endDate } = this.state;
		if (!startDate || !endDate) {
			this.setState({ error: { name: '', message: 'Start and end date are required.' } });
			return;
		}
		this.setState({ loading: true, error: undefined });
		try {
			const organizationId = organization ? organization.id : undefined;
			const teamId = team ? team.id : undefined;
			const results = await StatsService.getEvaluationResults(
				moment(startDate)
					.startOf('day')
					.toDate(),
				moment(endDate)
					.endOf('day')
					.toDate(),
				name,
				organizationId,
				teamId
			);
			this.setState({ results, loading: false });
		} catch (error) {
			this.setState({ error, loading: false });
		}
	};

	private getResultsCsv = async () => {
		const { organization, team, name, startDate, endDate } = this.state;
		if (!startDate || !endDate) {
			this.setState({ error: { name: '', message: 'Start and end date are required.' } });
			return;
		}
		const organizationId = organization ? organization.id : undefined;
		const teamId = team ? team.id : undefined;
		return StatsService.getEvaluationResultsCsv(
			moment(startDate)
				.startOf('day')
				.toDate(),
			moment(endDate)
				.endOf('day')
				.toDate(),
			name,
			organizationId,
			teamId
		);
	};

	private organizationChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
		if (event.target.value === EMPTY_VALUE) {
			this.setState({ organization: undefined });
		} else {
			this.setState({
				organization: this.props.organizations!.filter((org) => org.id === event.target.value)[0],
			});
		}
	};

	private teamChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
		if (event.target.value === EMPTY_VALUE) {
			this.setState({ team: undefined });
		} else {
			this.setState({ team: this.props.teams!.filter((team) => team.id === event.target.value)[0] });
		}
	};

	private startDateChanged = (date: Date) => {
		this.setState({ startDate: date });
	};

	private endDateChanged = (date: Date) => {
		this.setState({ endDate: date });
	};

	private nameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ name: event.target.value });
	};
}

function mapStateToProps({ loginState, organizationState, teamState }: AppState) {
	return {
		isAdmin: loginState.decodedToken && loginState.decodedToken.is_admin,
		teams: teamState.teams,
		organizations: organizationState.organizations,
	};
}

export default connect(mapStateToProps)(AdminEdgeScores);
