import * as Constants from './constants';

function getLocalStorageBool(key: string): boolean {
	const value = localStorage.getItem(key);
	return (value && value.toLowerCase() === 'true') || false;
}

export const Configuration = {
	api: process.env.REACT_APP_API || Constants.EMPTY_STRING,
	disableAnalytics: getLocalStorageBool(Constants.LOCALSTORAGE_DISABLE_ANALYTICS),
	disableAutomaticTokenRefresh: getLocalStorageBool(Constants.LOCALSTORAGE_DISABLE_AUTOMATIC_TOKEN_REFRESH),
	disablePreload: getLocalStorageBool(Constants.LOCALSTORAGE_DISABLE_PRELOAD),
	disableScoreAnimation: getLocalStorageBool(Constants.LOCALSTORAGE_DISABLE_SCORE_ANIMATION),
	domRootClass: localStorage.getItem(Constants.LOCALSTORAGE_DOM_ROOT_CLASS) || undefined,
	enableBuildNumberInUI:
		(process.env.REACT_APP_ENABLE_BUILD_NUMBER_IN_UI || Constants.EMPTY_STRING).toLowerCase() === 'true',
	enableCompleteExercise: getLocalStorageBool(Constants.LOCALSTORAGE_ENABLE_COMPLETE_EXERCISE),
	recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY || Constants.EMPTY_STRING,
	showJsonDebug: getLocalStorageBool(Constants.LOCALSTORAGE_SHOW_JSON_DEBUG),
	stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY || Constants.EMPTY_STRING,
	teamEventPageUrl: process.env.REACT_APP_TEAM_EVENT_PAGE_URL || Constants.EMPTY_STRING,
	version: process.env.REACT_APP_VERSION || Constants.EMPTY_STRING,
};
