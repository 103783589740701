import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../../core';
import { TeamExerciseRow } from './teamExerciseRow';
import { AppState } from '../../../store';
import { withLoadDataDefaultConfig } from '../../../components/loadData';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { StatsService } from '../../../services/statsService';

import './index.scss';

export interface TeamExercisesProps {
	currentTeam?: Edge.Models.Team;
	daysTimeSpan?: number;
	convergence: Edge.Models.TeamExercise;
	divergence: Edge.Models.TeamExercise;
	depth: Edge.Models.TeamExercise;
	tracking: Edge.Models.TeamExercise;
	recognition: Edge.Models.TeamExercise;
}

export const TeamExercises: React.FunctionComponent<TeamExercisesProps> = (props) => {
	const { convergence, divergence, depth, tracking, recognition } = props;
	return (
		<div className="team_score">
			<div className="title_row">
				<h4>Team Sessions</h4>
				<div>Total Sessions</div>
				<div>Last Score</div>
			</div>
			<TeamExerciseRow title="Depth" exercise={depth} />
			<div className="title_row">
				<h4>Team Sessions</h4>
				<div>Total Sessions</div>
				<div>Last Score</div>
				<div>Avg. Station Score</div>
			</div>
			<TeamExerciseRow title="Convergence" exercise={convergence} showConvergenceStationScore />
			<TeamExerciseRow title="Divergence" exercise={divergence} showDivergenceStationScore />
			<div className="title_row">
				<h4>Team Sessions</h4>
				<div>Total Sessions</div>
				<div>Last Score</div>
				<div>Avg. Time</div>
			</div>
			<TeamExerciseRow title="Tracking" exercise={tracking} showResponseTime />
			<TeamExerciseRow title="Recognition" exercise={recognition} showResponseTime />
		</div>
	);
};

function mapStateToProps(state: AppState) {
	const { currentTeam } = state.teamState;
	const { timeSpan } = state.timeSpanState;
	return { currentTeam, daysTimeSpan: timeSpan && timeSpan.days };
}

export default connect(mapStateToProps)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(TeamExercises, (props) => !!props.currentTeam && !!props.daysTimeSpan),
		(props) => {
			return { teamId: props.currentTeam!.id, daysTimeSpan: props.daysTimeSpan! };
		},
		async (props) => {
			return await StatsService.getTeamExerciseResults(props.teamId, props.daysTimeSpan);
		}
	)
);
