import * as React from 'react';
import { Link } from 'react-router-dom';

import * as Edge from '../../../core';
import TrainingBanner from '../trainingBanner';
import Summary from '../summary';
import SkillScores from '../skillScores';
import TrainingPlan from '../trainingPlan';
import MainRow from '../../../layouts/baseLayouts/mainRow';
import { withLoadDataDefaultConfig } from '../../../components/loadData';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { StatsService } from '../../../services/statsService';
import { PlanService } from '../../../services/planService';

import { ReactComponent as HockeyIcon } from '../../../assets/images/hockey.svg';
import { ReactComponent as BaseballIcon } from '../../../assets/images/baseball.svg';
import { ReactComponent as TennisIcon } from '../../../assets/images/tennis.svg';

import './index.scss';

export interface AthleteDashboardProps {
	evaluationResults: Edge.Models.EvaluationResultSummary[];
	skillScores: Edge.Models.SkillScores;
	trainingPlanResponse: Edge.Models.TrainingPlanResult;
}

interface AthleteDashboardState {}

export class AthleteDashboard extends React.PureComponent<AthleteDashboardProps, AthleteDashboardState> {
	public render(): JSX.Element {
		const { evaluationResults, skillScores, trainingPlanResponse } = this.props;
		const viewingSelf = true; // AthleteDashboard will always display TrainingPlan for self.

		return evaluationResults.length > 0 ? (
			<>
				<MainRow>
					<TrainingBanner />
				</MainRow>
				<MainRow>
					<Summary results={evaluationResults} alignmentResult={skillScores.alignment} />
					<div>
						<SkillScores results={skillScores} />
						<TrainingPlan trainingPlanResponse={trainingPlanResponse} viewingSelf={viewingSelf} />
					</div>
				</MainRow>
			</>
		) : (
			<div className="athlete_dashboard cold">
				<div className="container">
					<div className="text_block">
						<h2>Welcome to the Edge Trainer</h2>
						<p>
							Start your evaluation to assess your core visual skills. You will receive a custom training
							program to begin the path of an improved visual system after you complete your initial
							evaluation.
						</p>
						<Link to="/training/evaluation" className="cta_btn btn_small take_eval_btn">
							Take Evaluation
						</Link>
					</div>
					<div className="video_container">
						<div className="big_icon_wrapper baseball">
							<BaseballIcon />
						</div>
						<div className="big_icon_wrapper tennis">
							<TennisIcon />
						</div>
						<div className="big_icon_wrapper hockey">
							<HockeyIcon />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withLoadDataDefaultConfig(
	errorLoadingWrapperHOC(AthleteDashboard),
	(props) => {},
	async () => {
		const [evaluationResults, skillScores, trainingPlanResponse] = await Promise.all([
			StatsService.getMyEvaluationResults(),
			StatsService.getSkillScores(),
			PlanService.getMyTrainingPlan(),
		]);
		return { evaluationResults, skillScores, trainingPlanResponse };
	}
);
