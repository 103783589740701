/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-script-url */
import * as React from 'react';
import { BaseExercise, BaseExerciseProps } from '../baseExercise';
import * as Edge from '../../../core';
import ExerciseLayout from '../../../layouts/exerciseLayout';

declare var VizEdge: any;
declare var $: any;

interface RecognitionProps
	extends BaseExerciseProps<Edge.Models.RecognitionExerciseResult, Edge.Models.RecognitionExerciseConfiguration> {}

interface RecognitionConfiguration {
	fullScreen: boolean;
	imageScale: 'small' | 'medium' | 'large';
	numArrows: number; // number
	flashTime: number; // ms
	sessionDuration: number; // seconds
}

interface RecognitionResult {
	exercise: 'recognize';
	evaluation: boolean;
	duration: number; // seconds
	audio: boolean;
	arrows: number;
	size: 'small' | 'medium' | 'large';
	flashTime: number; // ms
	scorePercent: number; // out of 100
	responseTime: number; // ms
}

export class Recognition extends BaseExercise<
	RecognitionProps,
	Edge.Models.RecognitionExerciseResult,
	Edge.Models.RecognitionExerciseConfiguration,
	{}
> {
	createSession() {
		const { configuration } = this.props;
		return this.doCreateSession(
			this.props.mode,
			this.props.debugMode ? 'true' : 'false',
			this.props.renderScale,
			this.props.touchControls,
			{
				fullScreen: this.props.fullScreen,
				imageScale: ['', 'small', 'medium', 'large'][configuration.size] as any,
				numArrows: configuration.arrows,
				flashTime: configuration.flashTimeMilliseconds,
				sessionDuration: configuration.durationSeconds,
			},
			this.completeExercise
		);
	}

	/**
	 * Typed wrapper over `new VizEdge.RecognitionExercise`.
	 */
	private doCreateSession = (
		mode: 'train' | 'evaluate',
		debugMode: 'true' | 'false',
		renderScale: number,
		touchControls: boolean,
		configuration: RecognitionConfiguration,
		completeExercise: (result: RecognitionResult) => void
	) => {
		return new VizEdge.RecognitionExercise(
			mode,
			debugMode,
			renderScale,
			touchControls,
			{ bubble: this.bubbleSound, buzzer: this.buzzerSound, crystal: this.crystalSound },
			configuration,
			completeExercise
		);
	};

	private completeExercise = (result: RecognitionResult) => {
		const { configuration, completeExercise } = this.props;
		completeExercise({
			exerciseTypeId: Edge.Models.ExerciseTypeId.Recognition,
			audio: result.audio,
			exerciseConfigurationId: configuration.id,
			size:
				result.size === 'small'
					? Edge.Models.ExerciseSize.Small
					: result.size === 'medium'
					? Edge.Models.ExerciseSize.Medium
					: Edge.Models.ExerciseSize.Large,
			arrows: result.arrows,
			correctPercent: result.scorePercent / 100, // JS is out of 100, server is out of 1
			flashTimeMilliseconds: result.flashTime,
			durationSeconds: result.duration,
			responseTimeMilliseconds: result.responseTime,
		});
	};

	public render(): JSX.Element {
		return (
			<ExerciseLayout
				endExercise={this.endSession}
				completeExercise={this.completeSession}
				exerciseName="Recognition"
			>
				<div id="images">
					<img id="arrow-down-q" src="/images/arrow-down-q.gif" alt="Arrow down q" />
					<img id="arrow-left-q" src="/images/arrow-left-q.gif" alt="Arrow left q" />
					<img id="arrow-right-q" src="/images/arrow-right-q.gif" alt="Arrow right q" />
					<img id="arrow-up-q" src="/images/arrow-up-q.gif" alt="Arrow up q" />

					<img id="arrow-down-correct" src="/images/arrow-down-correct.gif" alt="Arrow down correct" />
					<img id="arrow-left-correct" src="/images/arrow-left-correct.gif" alt="Arrow left correct" />
					<img id="arrow-right-correct" src="/images/arrow-right-correct.gif" alt="Arrow right correct" />
					<img id="arrow-up-correct" src="/images/arrow-up-correct.gif" alt="Arrow up correct" />

					<img id="arrow-down-wrong" src="/images/arrow-down-wrong.gif" alt="Arrow down wrong" />
					<img id="arrow-left-wrong" src="/images/arrow-left-wrong.gif" alt="Arrow left wrong" />
					<img id="arrow-right-wrong" src="/images/arrow-right-wrong.gif" alt="Arrow right wrong" />
					<img id="arrow-up-wrong" src="/images/arrow-up-wrong.gif" alt="Arrow up wrong" />

					<img id="dpad-arrow" src="/images/dpad-arrow.png" alt="Dpad arrow" />
				</div>
			</ExerciseLayout>
		);
	}
}
