import * as React from 'react';
import Loading, { LoadingProps } from '../loading';
import ErrorHandler from '../error/errorHandler';
import ErrorDisplay from '../error/errorDisplay';

export interface ErrorLoadingWrapperProps {
	isLoading?: boolean;
	isErrored?: boolean;
	errorTitle?: string;
	errorMessage?: string;
	loadingOptions?: LoadingProps;
	render: () => JSX.Element;
}

export default class ErrorLoadingWrapper extends React.PureComponent<ErrorLoadingWrapperProps> {
	public render() {
		const { isLoading, isErrored, errorTitle, errorMessage, render, loadingOptions } = this.props;

		if (isErrored) {
			return <ErrorDisplay title={errorTitle} message={errorMessage} />;
		}
		if (isLoading) {
			return <Loading {...loadingOptions} />;
		}
		return <ErrorHandler render={render} />;
	}
}
