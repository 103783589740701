import * as React from 'react';

import './index.scss';
import ScrollToTopOnMount from '../global/scrollToTopOnMount';

export interface PageTitleProps {
	title?: string;
}

export class PageTitle extends React.Component<PageTitleProps> {
	public render(): JSX.Element {
		return (
			<div className="pageTitle">
				<ScrollToTopOnMount />
				<h1>{this.props.title}</h1>
			</div>
		);
	}
}

export default PageTitle;
