import * as React from 'react';
import * as Edge from '../../core';
import ErrorLoadingWrapper from '.';
import { LoadingProps } from '../loading';

export interface ErrorLoadingWrapperProps {
	isLoading: boolean;
	loadingError?: Edge.Models.EdgeError | string;
}

export interface ErrorLoadingWrapperSettings {
	loadingOptions?: LoadingProps;
}

/**
 * Wraps a given component in a @see ErrorLoadingWrapper with the isLoading and loadingError component props (and the result of hasRequiredProps) used to determine the loading/error states.
 * @param WrappedComponent The component to render if this is not loading or errored
 * @param hasRequiredProps Should return true if all the properties required to render the component are populated (useful when a parent object may momentarily render this component with isLodaing: false and null/undefined objects, like on initial render, before componentDidMount runs to start data loads)
 */
export default function errorLoadingWrapperHOC<
	TComponentProps,
	TIncomingProps extends TComponentProps = TComponentProps
>(
	WrappedComponent: React.ComponentType<TComponentProps>,
	hasRequiredProps: (props: TIncomingProps) => boolean = () => true,
	showMessage: (message: string, error: Edge.Models.EdgeError) => boolean | string | undefined = () => undefined,
	componentDidMount: (props: TIncomingProps & ErrorLoadingWrapperProps) => void = () => {},
	settings?: ErrorLoadingWrapperSettings
) {
	return class ErrorHandlerHOC extends React.Component<TIncomingProps & ErrorLoadingWrapperProps> {
		public componentDidMount() {
			componentDidMount(this.props);
		}

		public render() {
			const { isLoading, loadingError, ...rest } = this.props;
			const error = loadingError as Edge.Models.EdgeError;
			const message = error && Edge.API.getErrorMessage(error);
			const showMessageResult = message && showMessage && showMessage(message, error);
			const messageToShow =
				showMessageResult === false ? undefined : showMessageResult === true ? message : showMessageResult;
			if (process.env.NODE_ENV === 'development') {
				if (error && !messageToShow) {
					console.warn('Not showing message', {
						message,
						showMessageResult,
						error,
					});
				}
			}
			return (
				<ErrorLoadingWrapper
					isLoading={isLoading || !hasRequiredProps(rest as TIncomingProps)}
					isErrored={!!loadingError}
					errorMessage={messageToShow}
					loadingOptions={settings && settings.loadingOptions}
					render={() => <WrappedComponent {...rest as TComponentProps} />}
				/>
			);
		}
	};
}
