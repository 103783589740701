import * as Redux from 'redux';
import * as _ from 'lodash';
import * as Edge from './../../core';
import {
	ApiKeyState,
	ApiKeyActionTypes,
	APIKEYS_LOADING,
	APIKEYS_SUCCESS,
	APIKEYS_FAILURE,
	UPDATE_APIKEY,
	ApiKeyUpdateType,
} from './types';
import { LOGIN_CLEAR_PRELOADED_DATA } from '../login/types';

const initialState: ApiKeyState = {
	isLoading: false,
};

const apiKeyReducer: Redux.Reducer<ApiKeyState> = (state = initialState, action: ApiKeyActionTypes): ApiKeyState => {
	switch (action.type) {
		case LOGIN_CLEAR_PRELOADED_DATA:
			return initialState;
		case APIKEYS_LOADING:
			return {
				...state,
				isLoading: true,
				loadingError: undefined,
			};
		case APIKEYS_SUCCESS:
			const payload = _.orderBy(action.payload, 'type');

			return {
				...state,
				apiKeys: payload,
				isLoading: false,
				loadingError: undefined,
			};
		case APIKEYS_FAILURE:
			return {
				...state,
				apiKeys: undefined,
				isLoading: false,
				loadingError: action.payload as Edge.Models.EdgeError,
			};
		case UPDATE_APIKEY:
			const update = action.payload as ApiKeyUpdateType;
			const applyUpdate = (item: Edge.Models.ApiKey) => {
				if (item.id === update.id) return Object.assign({}, item, update);
				else return item;
			};

			return {
				...state,
				apiKeys: state.apiKeys && state.apiKeys.map(applyUpdate),
			};
		default:
			return state;
	}
};

export default apiKeyReducer;
