import * as React from 'react';
import { Link } from 'react-router-dom';
import ContactSupportLink from '../../components/contactSupport';
import { ReactComponent as NotFoundImage } from '../../assets/images/404.svg';

import './index.scss';

export interface NotFoundPageProps {}

const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = (props) => (
	<div className="not-found-page">
		<div className="split_container">
			<div>
				<h1>Ouch!</h1>
				<p className="main_message">We can't seem to find the page you're looking for.</p>
				<p>Error Code: 404</p>
				<p>
					Head back to our <Link to="/">homepage</Link> for further details or <ContactSupportLink />.
				</p>
			</div>
			<div className="image_wrapper">
				<NotFoundImage />
			</div>
		</div>
	</div>
);
export default NotFoundPage;
