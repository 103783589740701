import classNames from 'classnames';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../../store';
import { getActiveSession, endSession } from '../../../store/session/actions';
import * as Edge from '../../../core';
import { errorHandlerHOC } from '../../error/errorHandlerHOC';

import './index.scss';
import { Link } from 'react-router-dom';
import { activeSessionSelector } from '../../../store/session/selector';
import ConfirmModal from '../confirmModal';

export interface SessionTimerProps {
	className?: string;
	isLoading: boolean;
	loadingError?: Edge.Models.EdgeError;
	status?: Edge.Models.ActiveSessionResponse;
	getActiveSession: () => Promise<void>;
	endSession: (command: Edge.Models.EndSessionCommand) => Promise<void>;
	getRemainingTime: (now?: Date) => moment.Duration | undefined;
}

interface SessionTimerState {
	now: Date;
	endingSession?: string;
}

export class SessionTimer extends React.PureComponent<SessionTimerProps, SessionTimerState> {
	private unregisterForTimerUpdate?: () => void;
	constructor(props: SessionTimerProps) {
		super(props);
		this.state = {
			now: new Date(),
		};
	}
	public componentDidMount() {
		this.updateTimer();
	}
	public componentWillUnmount() {
		if (this.unregisterForTimerUpdate) {
			this.unregisterForTimerUpdate();
			this.unregisterForTimerUpdate = undefined;
		}
	}
	public componentDidUpdate() {
		this.updateTimer();
	}
	public render() {
		const { isLoading, loadingError, status, getRemainingTime } = this.props;
		const { now, endingSession } = this.state;

		const className = classNames('session_timer', this.props.className);

		if (isLoading || !status) {
			return <React.Fragment />;
		}
		if (loadingError) {
			return (
				<div className={className}>
					<span className="error">Error</span>
				</div>
			);
		}
		if (status.active) {
			const remaining = getRemainingTime(now)!;
			if (remaining && remaining.asMilliseconds() > 0) {
				return (
					<div className={className}>
						<Link to={`/training/current`}>
							{Math.floor(remaining.asMinutes())}:{_.padStart(remaining.seconds().toString(), 2, '0')}
						</Link>
						<button className="end_evaluation" onClick={this.showEndSession}>
							{status.active.sessionTypeId === Edge.Models.SessionTypeId.Evaluate
								? 'End Evaluation'
								: 'End Training'}
						</button>
						{endingSession && status.active.sessionId === endingSession && this.renderEndSessionModal()}
					</div>
				);
			}
		}
		return null;
	}

	private renderEndSessionModal = () => {
		return (
			<ConfirmModal
				open={true}
				onConfirm={this.endSession}
				onCancel={this.closeEndSession}
				confirmText="Yes"
				cancelText="No"
			>
				<p className="sessionTimerHeadline">Are you sure you want to end this session?</p>
			</ConfirmModal>
		);
	};

	private showEndSession = () => {
		const { status } = this.props;
		if (status && status.active && status.active.sessionId) {
			this.setState({ endingSession: status.active.sessionId });
		}
	};

	private closeEndSession = () => {
		this.setState({ endingSession: undefined });
	};

	private endSession = async () => {
		const { endSession, getActiveSession, status } = this.props;
		if (status && status.active) {
			await endSession({ sessionId: status.active.sessionId, abandon: true });
		}
		await getActiveSession();
	};

	private updateTimer = () => {
		if (this.props.status && this.props.status.active) {
			if (!this.unregisterForTimerUpdate) {
				this.unregisterForTimerUpdate = Edge.Time.registerForTimerUpdate((now) => this.setState({ now }));
			}
		} else {
			if (this.unregisterForTimerUpdate) {
				this.unregisterForTimerUpdate();
				this.unregisterForTimerUpdate = undefined;
			}
		}
	};
}

function mapStateToProps(state: AppState) {
	const { isLoading, loadingError, data, getRemainingTime } = activeSessionSelector(state);
	return {
		isLoading,
		loadingError,
		status: data,
		getRemainingTime,
	};
}

export default connect(
	mapStateToProps,
	{
		getActiveSession,
		endSession,
	}
)(errorHandlerHOC(SessionTimer));
