import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import * as Edge from '../../../core';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import PageTitle from '../../../components/pageTitle';
import AppPageContainer from '../../../components/pageContainer';
import TrainingAreaNav from '../trainingAreaNav';
import RenderExercise from '../renderExercise';
import DeviceProfiles from '../deviceProfiles';
import { AppState } from '../../../store';
import { addPlanPhase, getActiveSession } from '../../../store/session/actions';
import { activeSessionSelector } from '../../../store/session/selector';
import moment from 'moment';
import NoPlan from '../noPlan';
import ErrorDisplay from '../../../components/error/errorDisplay';
import SessionExpiredModal from '../../../components/sessionExpiredModal';

export interface PlanPageBaseProps {
	banner: React.ReactNode;
	gameMode?: Edge.Models.GameMode;
	hideAreaNav?: boolean;
	isTraining?: boolean;
	plan?: Edge.Models.Plan;
	planPhase?: Edge.Models.PlanPhase;
	operationName?: string;
	title: string;
	renderResults: () => JSX.Element;
	renderPlan: (trainNow: () => void) => JSX.Element;

	activeSession?: Edge.Models.ActiveSessionResponse;
	currentTeam?: Edge.Models.Team;

	addPlanPhase: (command: Edge.Models.AddPlanPhaseCommand) => Promise<void>;
	getRemainingTime: () => moment.Duration | undefined;
	getActiveSession: () => Promise<void>;
}

interface PlanPageBaseState {
	exercises?: Edge.Models.ExerciseConfiguration[];
	exerciseIndex?: number;
	trainNowError?: Edge.Models.EdgeError;
	sessionExpired?: boolean;
}

export class PlanPageBase extends React.PureComponent<PlanPageBaseProps, PlanPageBaseState> {
	constructor(props: PlanPageBaseProps) {
		super(props);
		this.state = {};
	}

	public componentDidMount() {
		this.updateExerciseOrder();
	}

	public componentDidUpdate(prevProps: PlanPageBaseProps) {
		if (!this.props || !prevProps) return;

		if (
			this.props.planPhase !== prevProps.planPhase ||
			(this.props.activeSession !== prevProps.activeSession &&
				(!prevProps.activeSession || !prevProps.activeSession.active))
		) {
			// #1390: https://dev.azure.com/vizualedge/Edge%20Trainer/_sprints/backlog/Edge%20Trainer%20Team/Edge%20Trainer/Ongoing/Sprint%202?workitem=1390
			// Resets Exercise Index when session has ended.
			if (this.props.activeSession && _.isEmpty(this.props.activeSession.active)) {
				this.resetExerciseIndex();
			}

			// trigger on change to planPhase, or if there previously was no active session
			this.updateExerciseOrder();
		}
	}

	public render() {
		const {
			activeSession,
			banner,
			currentTeam,
			gameMode,
			hideAreaNav,
			isTraining,
			operationName,
			plan,
			planPhase,
			renderPlan,
			renderResults,
			title,
		} = this.props;
		const { exercises, exerciseIndex, sessionExpired } = this.state;

		if (
			!sessionExpired &&
			currentTeam &&
			exercises &&
			exerciseIndex !== undefined &&
			activeSession &&
			activeSession.active
		) {
			const exercise = exercises[exerciseIndex];
			if (exercise) {
				const prefix = exerciseIndex === exercises.length - 1 ? 'Complete' : 'Continue';
				const suffix = isTraining ? 'training' : 'evaluation';
				return (
					<RenderExercise
						operationName={operationName || `Exercise ${exerciseIndex + 1}`}
						exercise={exercise}
						onComplete={this.moveToNextExercise}
						onAbort={this.leaveExercises}
						completeText={prefix + ' ' + suffix}
						gameMode={gameMode}
					/>
				);
			}
			return renderResults();
		}

		return (
			<>
				<PageTitle title={title} />
				{hideAreaNav || <TrainingAreaNav />}
				<AppPageContainer>
					{currentTeam && currentTeam.type.id === Edge.Models.TeamTypeId.StationMode ? (
						<div className="my_plan_comp">
							Not available in Station Mode. To upgrade this account, please contact{' '}
							<a href={'mailto:support@vizualedge.com'}>support@vizualedge.com</a>.
						</div>
					) : (
						<>
							<DeviceProfiles />
							{banner}
							{currentTeam && plan && planPhase && renderPlan(this.trainNow)}
							{!plan && <NoPlan />}
							{plan && !planPhase && <ErrorDisplay message="Unable to determine current plan phase" />}
							{sessionExpired && <SessionExpiredModal onClose={this.sessionExpiredClosed} />}
						</>
					)}
				</AppPageContainer>
			</>
		);
	}

	private trainNow = async () => {
		try {
			const { addPlanPhase, planPhase, activeSession } = this.props;
			if (activeSession && activeSession.active) {
				await addPlanPhase({
					sessionId: activeSession.active.sessionId,
					planPhaseId: planPhase!.id,
				});
				this.moveToNextExercise();
			} else {
				throw new Error('No active session');
			}
		} catch (e) {
			this.setState({ trainNowError: e });
		}
	};

	private leaveExercises = () => {
		this.setState({ exerciseIndex: undefined });
	};

	private moveToNextExercise = () => {
		const { getRemainingTime } = this.props;
		const { exercises } = this.state;
		const completed = this.getCompletedIds();
		const next = completed.length;

		// if we're going to render another exercise, make sure the session hasn't expired yet
		if (exercises && next < exercises.length) {
			const remaining = getRemainingTime();
			if (!remaining || remaining.asMilliseconds() < 0) {
				this.setState({ sessionExpired: true, exerciseIndex: undefined });
				return;
			}
		}

		this.setState({ exerciseIndex: next });
	};

	private sessionExpiredClosed = async () => {
		await this.props.getActiveSession();
		this.setState({ sessionExpired: false });
	};

	private getCompletedIds = () => {
		const { activeSession } = this.props;
		return ((activeSession &&
			activeSession.active &&
			activeSession.active.exerciseResults
				.map((r) => r.complete && r.exerciseConfigurationId)
				.filter((i) => !!i)) ||
			[]) as string[];
	};

	private generateExerciseOrder = () => {
		const { planPhase } = this.props;
		if (!planPhase) {
			return undefined;
		}
		const completed = this.getCompletedIds();
		const randomized = _.orderBy(planPhase.exerciseConfigurations, [
			(i) => (_.some(completed, (ii) => i.id === ii) ? 0 : 1),
			(i) => (planPhase.useSequenceOrder ? i.sequenceOrder || 0 : _.random()),
		]);

		return randomized;
	};

	private updateExerciseOrder = () => {
		this.setState({ exercises: this.generateExerciseOrder() });
	};

	private resetExerciseIndex = () => {
		this.setState({ exerciseIndex: undefined });
	};
}

function mapStateToProps(state: AppState) {
	const { data, getRemainingTime } = activeSessionSelector(state);
	return {
		activeSession: data,
		getRemainingTime,
	};
}

export default connect(
	mapStateToProps,
	{
		addPlanPhase,
		getActiveSession,
	}
)(errorLoadingWrapperHOC(PlanPageBase));
