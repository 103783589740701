import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../core';
import ModalContainer from '../global/modal';
import { activeSessionSelector } from '../../store/session/selector';

export interface SessionExpiredModalProps {
	onClose: () => void;
	active?: Pick<Edge.Models.BaseSessionResponse, 'sessionTypeId' | 'planPhaseId'>;
}

export const SessionExpiredModal: React.FunctionComponent<SessionExpiredModalProps> = (props) => {
	const sessionType = props.active
		? props.active.sessionTypeId === Edge.Models.SessionTypeId.Evaluate
			? 'evaluation session'
			: 'training session'
		: 'session';
	const continueMessage = props.active
		? props.active.sessionTypeId === Edge.Models.SessionTypeId.Evaluate
			? 'In order to continue, you need start another session and restart your evaluation.'
			: props.active.planPhaseId
			? 'In order to continue training, you need start another session and restart your plan.'
			: 'In order to continue training, you need to start another session.'
		: 'In order to continue, you need to start another session.';
	return (
		<ModalContainer onClose={props.onClose} open title="Session Expired">
			<p>Your {sessionType} has expired.</p>
			<p>{continueMessage}</p>
			<button className="cta_btn" onClick={props.onClose}>
				Ok
			</button>
		</ModalContainer>
	);
};
export default connect(activeSessionSelector)(SessionExpiredModal);
