import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../core';
import { AppState } from '../../store';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import Table from '../../components/table';
import { Avatar } from '../../components/avatar';
import { setCurrentOrganization, getOrganizations } from '../../store/organization/actions';
import { Redirect } from 'react-router';
import PageTitle from '../../components/pageTitle';
import AppPageContainer from '../../components/pageContainer';

import './index.scss';
import { AdminMenu } from '../../components/adminMenu';
import CreateOrganizationForm, { CreateOrganizationFormValues } from '../../components/createOrganizationForm';
import ModalContainer from '../../components/global/modal';
import { OrganizationService } from '../../services/organizationService';
import { getPermissions } from '../../store/permissions/actions';

class OrganizationsTable extends Table<Edge.Models.Organization> {}

export interface OrganizationSearchProps {
	currentOrganization?: Edge.Models.Organization;
	organizations?: Edge.Models.Organization[];
	isAdmin?: boolean;
	isDistributor?: boolean;

	setCurrentOrganization: (organization: Edge.Models.Organization) => void;
	getOrganizations: () => Promise<void>;
	getPermissions: () => Promise<void>;
}

interface OrganizationSearchState {
	creatingOrganization?: boolean;
	redirectToOrganization?: Edge.Models.Organization;
}

const availableSearches: ((item: Edge.Models.Organization) => string | undefined)[] = [
	(item) => item.name,
	(item) => item.distributor && item.distributor.name,
];
const availableSorts: {
	[x: string]: (item: Edge.Models.Organization) => any;
} = {
	name: (item) => (item.name || '').toLowerCase(),
	distributor: (item) => ((item.distributor && item.distributor.name) || '').toLowerCase(),
};

export class OrganizationSearch extends React.PureComponent<OrganizationSearchProps, OrganizationSearchState> {
	constructor(props: OrganizationSearchProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { organizations, currentOrganization, isAdmin, isDistributor } = this.props;
		const { redirectToOrganization, creatingOrganization } = this.state;
		const showDistributors = !!isAdmin;

		if (redirectToOrganization && currentOrganization && redirectToOrganization.id === currentOrganization.id) {
			return <Redirect to="/" />;
		}

		return (
			<>
				<PageTitle title="Organization Search" />
				<AppPageContainer>
					{creatingOrganization && (
						<ModalContainer open={true} onClose={this.closeCreateOrganization}>
							<CreateOrganizationForm onSubmit={this.createOrganization} />
						</ModalContainer>
					)}
					<OrganizationsTable
						className="organization_search_table"
						items={organizations!}
						availableSearches={availableSearches}
						availableSorts={availableSorts}
						searchPlaceholderText="Search Organizations"
						initialSort={['name']}
						renderHeader={() => <>&nbsp;</>}
						renderAfterSearch={() =>
							(isAdmin || isDistributor) && (
								<AdminMenu>
									<button onClick={this.openCreateOrganization}>Create Organization</button>
								</AdminMenu>
							)
						}
						renderTableHeader={(classNames, toggleSort) => {
							return (
								<tr>
									<th className={classNames('name')} onClick={toggleSort.bind(this, 'name')}>
										Organization
									</th>
									{showDistributors && (
										<th
											className={classNames('distributor')}
											onClick={toggleSort.bind(this, 'distributor')}
										>
											Distributor
										</th>
									)}
								</tr>
							);
						}}
						renderTableItem={(i) => {
							return (
								<tr onClick={this.setCurrentOrganization.bind(this, i)}>
									<td>
										<Avatar src={i.logoUrl} />
										<span>{i.name}</span>
									</td>
									{showDistributors && <td>{i.distributor && i.distributor.name}</td>}
								</tr>
							);
						}}
						pagingMode="numbers"
						pageSize={7}
					/>
				</AppPageContainer>
			</>
		);
	}

	private setCurrentOrganization = (redirectToOrganization: Edge.Models.Organization) => {
		this.setState({ redirectToOrganization });
		this.props.setCurrentOrganization(redirectToOrganization);
	};

	private openCreateOrganization = () => {
		this.setState({ creatingOrganization: true });
	};
	private closeCreateOrganization = () => {
		this.setState({ creatingOrganization: false });
	};
	private createOrganization = async (values: CreateOrganizationFormValues) => {
		await OrganizationService.create(values);
		await Promise.all([this.props.getOrganizations(), this.props.getPermissions()]);
		this.setState({ creatingOrganization: false });
	};
}

function mapStateToProps({ organizationState, loginState }: AppState) {
	return Object.assign({}, organizationState, {
		isAdmin: loginState.decodedToken && loginState.decodedToken.is_admin,
		isDistributor: loginState.decodedToken && loginState.decodedToken.is_distributor,
	});
}

export default connect(
	mapStateToProps,
	{ setCurrentOrganization, getOrganizations, getPermissions }
)(errorLoadingWrapperHOC(OrganizationSearch, (props) => !!props.organizations));
