import * as Redux from 'redux';

import * as Edge from '../../core';
import { CacheService } from '../../services/cacheService';

import {
	DeviceProfileState,
	DeviceProfileActionTypes,
	DEVICEPROFILE_GET_START,
	DEVICEPROFILE_GET_SUCCESS,
	DEVICEPROFILE_GET_FAILURE,
	DEVICEPROFILE_SET,
} from './types';
import { LOGIN_CLEAR_PRELOADED_DATA } from '../login/types';

// todo - temporary for preloading device profile state
const initialState: DeviceProfileState = {
	isLoading: false,
};

const deviceProfileReducer: Redux.Reducer<DeviceProfileState, DeviceProfileActionTypes> = (
	state = initialState,
	action: DeviceProfileActionTypes
): DeviceProfileState => {
	switch (action.type) {
		case LOGIN_CLEAR_PRELOADED_DATA:
			return initialState;
		case DEVICEPROFILE_GET_START:
			return {
				...state,
				isLoading: true,
				loadingError: undefined,
			};
		case DEVICEPROFILE_GET_SUCCESS:
			const { payload } = action;
			const defaultProfileId = CacheService.get(Edge.Constants.CACHE_CURRENT_DEVICEPROFILE_ID) as string;
			const profile =
				(state.currentProfile ? payload.filter((i) => i.id === state.currentProfile!.id)[0] : undefined) ||
				(defaultProfileId ? payload.filter((i) => i.id === defaultProfileId)[0] : undefined);
			return {
				...state,
				isLoading: false,
				profiles: action.payload,
				currentProfile: profile,
			};
		case DEVICEPROFILE_GET_FAILURE:
			return {
				...state,
				isLoading: false,
				loadingError: action.payload,
			};
		case DEVICEPROFILE_SET:
			const currentProfile = action.payload;
			if (currentProfile) {
				CacheService.set(
					Edge.Constants.CACHE_CURRENT_DEVICEPROFILE_ID,
					currentProfile.id,
					Edge.Constants.CACHE_CURRENT_DEVICEPROFILE_ID_EXPIRATION_MINUTES
				);
			}
			return {
				...state,
				currentProfile: currentProfile,
			};
		default:
			return state;
	}
};

export default deviceProfileReducer;
