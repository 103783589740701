import React from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import ModalContainer from '../../../components/global/modal';
import * as Edge from '../../../core';
import { CreateTeamMemberFormProps } from '../../manageTeams/createTeamMember';
import { UserService } from '../../../services/userService';
import { SessionService } from '../../../services/sessionService';
import history from '../../../services/history';
import Input from '../../../components/global/input';
import { FormField } from '../../../components/formField';
import Error from '../../../components/global/error';

import './index.scss';

declare module 'yup' {
	interface StringSchema {
		userNameExists(errorMessage: string, options?: { max?: number }): StringSchema;
	}
}

Yup.addMethod(Yup.string, 'userNameExists', function(errorMessage, options = {}) {
	const { max } = options;

	return this.test('username', errorMessage, async function(value) {
		if (!value) {
			return this.createError({ path: 'username', message: 'Username is required' });
		}

		if (max && value.length > max) {
			return this.createError({ path: 'username', message: `Username must be at most ${max} characters long` });
		}

		const userExists = await UserService.userExists(value);
		return userExists ? this.createError({ path: 'username', message: errorMessage }) : true;
	});
});

interface ConvertUserProps {
	currentTeams?: Edge.Models.Team[];
	teamMember?: Edge.Models.TeamMemberStats;
	currentOrganization?: Edge.Models.Organization;

	handleConvertModal: () => void;
}

const normalRequiredField = Yup.string()
	.max(100, 'Too long')
	.required();

const uniqueRequiredField = normalRequiredField.min(4, 'Too short').userNameExists('Username already exists.');

const schema = Yup.object({
	firstName: normalRequiredField.required('First name is required.'),
	lastName: normalRequiredField.required('Last name is required.'),
	email: normalRequiredField.email().required('Email is required.'),
	username: uniqueRequiredField,
	password: Edge.Validation.password('Password'),
	passwordConfirmation: Yup.string()
		.required('This field is required.')
		.oneOf([Yup.ref('password')], 'Passwords must match.'),
	teamId: Yup.string().required('Please select a team.'),
});

export interface ConvertUserFormProps extends CreateTeamMemberFormProps {
	teamId?: string;
}

interface ConvertUserState {
	modalPosition: number;
}

export class ConvertUser extends React.Component<ConvertUserProps, ConvertUserState> {
	modalTitles: { [key: number]: string } = {
		0: 'Assign to a Team',
		1: 'Create New Athlete',
		2: 'Ready to convert this Prospect?',
		3: 'Prospect Successfully Converted',
	};
	private readonly formikRef: React.RefObject<Formik<ConvertUserFormProps>>;
	constructor(props: ConvertUserProps) {
		super(props);
		this.formikRef = React.createRef<Formik<ConvertUserFormProps>>();
		this.state = {
			modalPosition: 0,
		};
	}

	public render(): JSX.Element {
		const { teamMember, currentTeams, currentOrganization, handleConvertModal } = this.props;

		const { modalPosition } = this.state;

		return (
			<ModalContainer open title={this.modalTitles[modalPosition]} onClose={() => handleConvertModal()}>
				<Formik
					ref={this.formikRef}
					initialValues={Object.assign(
						{},
						{
							firstName: (teamMember && teamMember.user && teamMember.user.firstName) || '',
							lastName: (teamMember && teamMember.user && teamMember.user.lastName) || '',
							email: (teamMember && teamMember.user && teamMember.user.email) || '',
							username: '',
							password: '',
							passwordConfirmation: '',
							teamId: '',
						}
					)}
					validationSchema={schema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await this.convertToNewAthlete(values, teamMember!.user, values.teamId);
							this.progressModal();
						} catch (e) {
							actions.setStatus(Edge.API.getErrorMessage(e));
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<ConvertUserFormProps>) => {
						return (
							<Form className="convert_user_modal_flow">
								{modalPosition === 0 && (
									<>
										<p>
											Select one of your created teams to assign the new user to. If you would
											like to assign them to a brand new team, please first create it under TEAM
											SETTINGS
										</p>
										<Field component="select" name="teamId" label={'Select team'}>
											{currentTeams &&
												currentTeams.filter(
													(i) => i.type.id === Edge.Models.TeamTypeId.Standard
												).length > 0 &&
												_.orderBy(currentTeams, (i) => i.name)
													.filter(
														(i) =>
															i.type.id === Edge.Models.TeamTypeId.Standard &&
															i.organizationId ===
																(currentOrganization && currentOrganization.id)
													)
													.map((team) => (
														<>
															<option key={'selectTeam'} value={''} hidden>
																Select Team
															</option>
															<option key={team.id} value={team.id}>
																{team.name}
															</option>
														</>
													))}
											:
											<option key={'noTeam'} value={''} hidden>
												No team to select
											</option>
										</Field>
										{props.errors.teamId && (
											<div className={'warning_message'}>{props.errors.teamId}</div>
										)}
										<div className="select_team">
											<Input
												className="cta_btn btn_bright btn_small"
												type={'button'}
												value={'CANCEL'}
												onClick={handleConvertModal}
											/>
											<Input
												className="cta_btn btn_small"
												type={'button'}
												value={'NEXT'}
												onClick={() => {
													if (props.values.teamId !== '') {
														this.progressModal();
													}
												}}
											/>
										</div>
									</>
								)}
								{modalPosition === 1 && (
									<>
										<FormField
											description="First name"
											disabled={teamMember && teamMember.user && teamMember.user.firstName !== ''}
											formik={props}
											name="firstName"
											type="text"
										/>
										<FormField
											description="Last name"
											disabled={teamMember && teamMember.user && teamMember.user.lastName !== ''}
											formik={props}
											name="lastName"
											type="text"
										/>
										<FormField
											description="Email"
											disabled={teamMember && teamMember.user && !!teamMember.user.email}
											formik={props}
											name="email"
											type="email"
										/>
										<FormField formik={props} type="text" name="username" description="Username" />
										<FormField
											description="Password"
											formik={props}
											name="password"
											type="password"
										/>
										<FormField
											description="Password (repeat)"
											formik={props}
											name="passwordConfirmation"
											type="password"
										/>
										<div className={'left_align'}>
											<Input
												className={'cta_btn btn_small'}
												type={'button'}
												value={'Submit'}
												onClick={() => {
													if (Object.keys(props.errors).length === 0) {
														this.progressModal();
													}
												}}
											/>
										</div>
									</>
								)}

								{modalPosition === 2 && (
									<div className="confirmation_dialog">
										<div className="inputs">
											<Input
												className="cta_btn btn_bright btn_small"
												type={'button'}
												value={'NO'}
												onClick={handleConvertModal}
											/>
											<Input type={'submit'} className="cta_btn btn_small" value={'YES'} />
										</div>
										{props.status && <Error>{props.status}</Error>}
									</div>
								)}

								{modalPosition === 3 && (
									<>
										<div className="success_dialog">
											<div>
												Prospect Name: <span>{props.values.username}</span>
											</div>
											<div>
												Assigned Team: <span>{this.getTeamName(props.values!.teamId)}</span>{' '}
											</div>
										</div>
										<div>
											<button className="cta_btn btn_small" onClick={this.progressModal}>
												Done
											</button>
										</div>
									</>
								)}
							</Form>
						);
					}}
				/>
			</ModalContainer>
		);
	}

	private getTeamName = (teamId: string | undefined) => {
		const selectedTeam = this.props.currentTeams!.find((team) => team.id === teamId);

		if (selectedTeam) {
			return selectedTeam.name;
		}
	};
	private convertToNewAthlete = async (
		values: CreateTeamMemberFormProps,
		prospectUser?: Edge.Models.User,
		teamId?: string
	) => {
		const { firstName, lastName, email, username, password } = values;

		await UserService.createTeamMember({
			firstName,
			lastName,
			email,
			username,
			password,
			teamId,
		});

		if (prospectUser && this.props.currentOrganization) {
			await SessionService.convertProspectUserToAthlete({
				newUserName: username,
				prospectId: prospectUser.id,
				teamId: teamId,
			});
		}
	};

	private progressModal = () => {
		if (this.state.modalPosition < 3) {
			this.setState({
				modalPosition: this.state.modalPosition + 1,
			});
		} else {
			history.push('/stats');
		}
	};
}

export default ConvertUser;
