import * as React from 'react';
import classNames from 'classnames';

import './index.scss';

export interface LoadingProps {
	blockItem?: boolean;
	buttonLoader?: boolean;
	invertColors?: boolean;
}

export default class Loading extends React.PureComponent<LoadingProps> {
	public render() {
		return (
			<div
				className={classNames('loading', {
					block: this.props.blockItem,
					btn_loading: this.props.buttonLoader,
					invert_colors: this.props.invertColors,
				})}
			>
				<div className="loading_bubble" />
				<div className="loading_bubble" />
				<div className="loading_bubble" />
			</div>
		);
	}
}
