import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Edge from '../../core';
import FormField from '../../components/formField';
import Input from '../../components/global/input';

import './index.scss';
import Error from '../../components/global/error';
import Info from '../../components/global/info';
import Loading from '../../components/loading';

export interface UserInfoFormProps {
	initialValues?: Partial<Edge.Models.UserDetailsCommand>;
	onSubmit: (values: Edge.Models.UserDetailsCommand) => Promise<void>;
	username?: string;
}

const UserInfoFormSchema = Yup.object().shape({
	firstName: Yup.string().required('First Name is required'),
	lastName: Yup.string().required('Last Name is required'),
	email: Yup.string()
		.required('Email is required')
		.email('Email must be formatted like an email address'),
});

export default class UserInfoForm extends React.Component<UserInfoFormProps> {
	public render() {
		const { initialValues, onSubmit, username } = this.props;
		return (
			<div className="formWrap">
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							firstName: '',
							lastName: '',
							email: '',
						},
						initialValues || {}
					)}
					validationSchema={UserInfoFormSchema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await onSubmit(values as Edge.Models.UserDetailsCommand);
							actions.setStatus({
								success: 'Your information has been updated',
							});
						} catch (e) {
							actions.setStatus({ error: Edge.API.getErrorMessage(e) });
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<Partial<Edge.Models.UserDetailsCommand>>) => (
						<Form>
							<FormField component="input" type="text" name="firstName" label="First Name" />
							<FormField component="input" type="text" name="lastName" label="Last Name" />
							<FormField
								component="input"
								type="text"
								name="username"
								label="Username"
								placeholder={username}
								disabled
							/>
							<FormField component="input" type="email" name="email" label="Email" />
							{props.status && props.status.error && <Error>{props.status.error}</Error>}
							{props.status && props.status.success && <Info>{props.status.success}</Info>}
							{props.isSubmitting && <Loading buttonLoader />}
							<div className="buttonContain">
								<Input type="submit" disabled={props.isSubmitting} value="submit" />
							</div>
						</Form>
					)}
				/>
			</div>
		);
	}
}
