import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { AppState } from '../../../store';
import * as Edge from '../../../core';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import ScrollToTopOnMount from '../../../components/global/scrollToTopOnMount';
import OrganizationProfileForm, { OrganizationProfileFormValues } from '../organizationProfileForm';
import { OrganizationService } from '../../../services/organizationService';
import { OrganizationUpdateType } from '../../../store/organization/types';
import { updateOrganization, removeOrganization } from '../../../store/organization/actions';
import { withLoadDataDefaultConfig } from '../../../components/loadData';
import OrganizationMembers from '../organizationMembers';
import { ModalContainer } from '../../../components/global/modal';
import RemoveOrganizationMember from '../removeOrganizationMember';
import AddUserForm, { AddUserFormValues } from '../addUserForm';
import { getOrganizationRoles } from '../../../store/staticData';
import { getPermissions } from '../../../store/permissions/actions';
import { getUserPermissionService } from '../../../store/permissions/selector';
import AccessDenied from '../../../components/global/accessDenied';
import ChangeImageSection from '../../../components/changeImageSection';
import AdminMenu from '../../../components/adminMenu';
import UpdateOrganization from '../updateOrganization';

import './index.scss';
import EditUser from '../../admin/editUser';
import Select from '../../../components/global/select';
import Input from '../../../components/global/input';

export interface ManageOrganizationProps {
	isAdmin?: boolean;
	currentOrganization: Edge.Models.Organization;
	permissions: Edge.Models.AssignedRole;
	organizationMembers: Edge.Models.OrganizationMember[];
	isLoading: boolean;
	loadingError?: Edge.Models.EdgeError;
	reloadData: () => Promise<void>;
	updateOrganization: (updates: OrganizationUpdateType) => Promise<void>;
	removeOrganization: (id: string) => Promise<void>;

	organizationRoles: Edge.Models.Role[];
	getOrganizationRoles: () => Promise<void>;

	currentUserId?: string;
	getPermissions: () => Promise<void>;
}

interface ManageOrganizationState {
	removingMember?: Edge.Models.OrganizationMember;
	addingUser?: boolean;
	editingUser?: Edge.Models.ManuallyEditedUser;
	newRoleMember?: Edge.Models.OrganizationMember;
	newRole?: Edge.Models.Role;
	editingSettings?: boolean;
	error?: string;
}

export class ManageOrganization extends React.PureComponent<ManageOrganizationProps, ManageOrganizationState> {
	constructor(props: ManageOrganizationProps) {
		super(props);
		this.state = {};
	}
	public render(): JSX.Element {
		const { isAdmin, currentOrganization, organizationMembers, organizationRoles, permissions } = this.props;
		const { removingMember, addingUser, editingUser, newRoleMember, newRole, editingSettings } = this.state;

		// if we add a readonly org-level permission, lower this to read and make sure read is supported on the rest of the page
		if (permissions.organizationPermissions < Edge.Models.PermissionLevels.Edit) {
			return <AccessDenied />;
		}

		return (
			<div className="manage_organization">
				<ScrollToTopOnMount />
				<div className="sectionMargin">
					{isAdmin && (
						<>
							{currentOrganization.distributor && (
								<div className="distributor">Distributor: {currentOrganization.distributor.name}</div>
							)}
							<AdminMenu>
								<button onClick={this.openAdminSettings}>Settings</button>
							</AdminMenu>
						</>
					)}
					<ChangeImageSection src={currentOrganization.logoUrl} uploadImage={this.uploadImage} />
					<OrganizationProfileForm
						initialValues={{ name: currentOrganization.name }}
						onSubmit={this.profileSubmit}
					/>
				</div>
				<div className="sectionMargin">
					<OrganizationMembers
						organizationMembers={organizationMembers}
						openAddUser={this.openAddUser}
						openEditUser={this.openEditUser}
						openRemoveMember={this.openRemoveMember}
						openChangeRole={this.openChangeRole}
					/>
					<br />
					<Link className="cta_btn" to="/manage-organization/manage-plan">
						Manage Plan
					</Link>
				</div>
				<div className="sectionMargin">
					In order to deactivate your organization, please reach out to{' '}
					<a href={'mailto:support@vizualedge.com'}>support@vizualedge.com</a> for assistance.
				</div>

				{/* Admin Settings Modal */}
				{editingSettings && (
					<ModalContainer open={true} title="Admin Settings" onClose={this.closeAdminSettings}>
						<UpdateOrganization
							userPurchasable={currentOrganization.userPurchasable!}
							updateOrganization={this.updateAdminSettings}
							closeAdminOptions={this.closeAdminSettings}
						/>
					</ModalContainer>
				)}

				{/* Add User Modal */}
				{addingUser && (
					<ModalContainer open={true} title="Add User" onClose={this.closeAddMember}>
						<AddUserForm organizationRoles={organizationRoles} onSubmit={this.addMember} />
					</ModalContainer>
				)}

				{/* Edit User Modal */}
				{isAdmin && editingUser && (
					<EditUser
						user={editingUser.user}
						email={editingUser.email}
						reloadData={this.props.reloadData}
						onClose={this.closeEditUser}
					/>
				)}

				{/* Remove Member Modal */}
				{removingMember && (
					<ModalContainer open={true} title="Remove Organization Member" onClose={this.closeRemoveMember}>
						<RemoveOrganizationMember
							organizationMember={removingMember}
							onConfirm={this.removeMember}
							onCancel={this.closeRemoveMember}
						/>
					</ModalContainer>
				)}

				{/* Change Role Modal */}
				{isAdmin && newRoleMember && (
					<ModalContainer title="Change User Role" open={true} onClose={this.closeChangeRole}>
						<form onSubmit={this.changeUserRole}>
							<p>Select new User Role from the dropdown below.</p>
							<Select defaultValue={newRoleMember.organizationRole.id} onChange={this.onChangeRole}>
								{_.orderBy(organizationRoles!, (i) => i.name).map((i) => (
									<option key={i.id} value={i.id}>
										{i.name}
									</option>
								))}
							</Select>
							<Input className="cta_btn" disabled={!!!newRole} value="Change" type="submit" />
						</form>
					</ModalContainer>
				)}
			</div>
		);
	}

	// Admin Options methods
	private openAdminSettings = () => {
		this.setState({ editingSettings: true });
	};

	private closeAdminSettings = () => {
		this.setState({ editingSettings: false });
	};

	private updateAdminSettings = async (userPurchasable: boolean) => {
		await OrganizationService.updateAdminSettings({
			organizationId: this.props.currentOrganization.id,
			userPurchasable,
		});
		await this.props.updateOrganization({ id: this.props.currentOrganization.id, userPurchasable });
	};

	// Add User methods
	private openAddUser = () => {
		this.setState({ addingUser: true });
	};

	private closeAddMember = () => {
		this.setState({ addingUser: false });
	};

	private addMember = async (values: AddUserFormValues) => {
		const { currentOrganization, reloadData } = this.props;
		await OrganizationService.addMembers({
			organizationId: currentOrganization.id,
			emails: [values.email!],
			roleId: values.roleId!,
		});
		await reloadData();
		setTimeout(() => {
			this.closeAddMember();
		}, 0);
	};

	// Edit User methods
	private openEditUser = (user: Edge.Models.ManuallyEditedUser) => {
		this.setState({ editingUser: user });
	};

	private closeEditUser = () => {
		this.setState({ editingUser: undefined });
	};

	// Remove Member methods
	private openRemoveMember = (member: Edge.Models.OrganizationMember) => {
		this.setState({ removingMember: member });
	};

	private closeRemoveMember = () => {
		this.setState({ removingMember: undefined });
	};

	private removeMember = async () => {
		const { currentOrganization, reloadData, currentUserId, getPermissions } = this.props;
		const { removingMember } = this.state;
		await OrganizationService.removeMember(currentOrganization.id, removingMember!.user.id);
		await reloadData();
		if (removingMember!.user.id === currentUserId) {
			await getPermissions();
		}

		this.closeRemoveMember();
	};

	// Change Role methods
	private openChangeRole = (member: Edge.Models.OrganizationMember) => {
		this.setState({ newRoleMember: member });
	};

	private closeChangeRole = () => {
		this.setState({ newRoleMember: undefined });
	};

	private onChangeRole = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const newRole = this.props.organizationRoles!.filter((i) => i.id === event.target.value)[0];
		this.setState({ newRole });
	};

	private changeUserRole = () => {
		const { newRoleMember, newRole } = this.state;

		if (newRoleMember && newRole && newRoleMember.organizationRole.id !== newRole.id) {
			alert('multiple organization roles not implemented yet');
		}
	};

	// Misc
	private uploadImage = async (imageData: string): Promise<void> => {
		const { currentOrganization } = this.props;
		const url = await OrganizationService.uploadLogo(currentOrganization.id, imageData);

		await this.props.updateOrganization({
			id: currentOrganization.id,
			logoUrl: url,
		});
	};

	private profileSubmit = async (values: OrganizationProfileFormValues) => {
		const { currentOrganization } = this.props;
		const updates = {
			id: currentOrganization.id,
			name: values.name!,
		};
		await OrganizationService.update({ organizationId: updates.id, organizationName: updates.name });
		await this.props.updateOrganization(updates);
	};

	private removeOrganization = () => {
		this.props.removeOrganization(this.props.currentOrganization.id);
	};
}

function mapStateToProps(state: AppState) {
	const isAdmin = state.loginState.decodedToken && state.loginState.decodedToken.is_admin;
	const { organizationState, organizationRoleState, loginState } = state;
	const { currentOrganization } = organizationState;
	const permissions = getUserPermissionService(state);

	return {
		isAdmin,
		isLoading: organizationState.isLoading || organizationRoleState.isLoading || !permissions.ready(),
		loadingError:
			organizationState.loadingError || organizationRoleState.loadingError || permissions.loadingError(),
		currentOrganization: currentOrganization!,
		permissions: (currentOrganization && permissions.getOrganizationRole(currentOrganization.id))!,
		organizationRoles: organizationRoleState.data!,
		currentUserId: loginState.decodedToken && loginState.decodedToken.sub,
	};
}

export default connect(
	mapStateToProps,
	{
		updateOrganization,
		removeOrganization,
		getOrganizationRoles,
		getPermissions,
	}
)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(
			ManageOrganization,
			(props: ManageOrganizationProps) =>
				!!props.currentOrganization &&
				!!props.organizationMembers &&
				!!props.organizationRoles &&
				!!props.permissions,
			() => undefined,
			(props: ManageOrganizationProps) => {
				if (!props.organizationRoles) {
					props.getOrganizationRoles();
				}
			},
			{ loadingOptions: { blockItem: true } }
		),
		(props: ManageOrganizationProps) => {
			return { id: props.currentOrganization && props.currentOrganization.id };
		},
		async ({ id }: { id: string }) => {
			if (!id) {
				return { organizationMembers: undefined };
			}
			const organizationMembers = await OrganizationService.getMembers(id);
			return { organizationMembers };
		}
	)
);
