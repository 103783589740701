import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import * as Edge from '../../core';
import * as LoginActions from '../../store/login/actions';
import { AppState } from '../../store';
import { Login } from './login';

import './index.scss';

const login = LoginActions.login;

interface LoginRouteProps {
	from?: string;
}

interface LoginContainerProps extends RouteComponentProps<LoginRouteProps> {
	login: (loginUserCommand: Edge.Models.LoginUserCommand) => Promise<void>;
	isLoading: boolean;
	isAuthenticated: boolean;
	error?: string;
}

interface LoginContainerState {
	username: string;
	password: string;
	loginInProgress?: boolean;
}

class LoginContainer extends React.Component<LoginContainerProps, LoginContainerState> {
	constructor(props: LoginContainerProps) {
		super(props);

		this.state = {
			username: '',
			password: '',
		};
	}
	public render(): JSX.Element {
		const { isLoading, isAuthenticated, error, match } = this.props;
		const { loginInProgress } = this.state;
		if (isAuthenticated && !loginInProgress) {
			const target = match.params.from ? decodeURIComponent(match.params.from) : '/';
			return <Redirect to={target} />;
		}

		return (
			<Login
				{...this.state}
				isLoading={loginInProgress || isLoading}
				onUsernameChange={this.handleUsernameChange}
				onPasswordChange={this.handlePasswordChange}
				onSubmit={this.handleSubmit}
				errorText={error}
			/>
		);
	}

	private handleUsernameChange: (e: any) => void = (e) => {
		this.setState({ username: e.target.value });
	};

	private handlePasswordChange: (e: any) => void = (e) => {
		this.setState({ password: e.target.value });
	};

	private handleSubmit: (e: any) => void = async (e) => {
		e.preventDefault();
		this.setState({ loginInProgress: true });
		try {
			await this.props.login({
				username: this.state.username,
				password: this.state.password,
			});
		} catch {
			// error is handled via redux
		}
		this.setState({ loginInProgress: false });
	};
}

const mapStateToProps = (state: AppState) => {
	return {
		isLoading: state.loginState.isLoading,
		isAuthenticated: state.loginState.authenticated === true,
		error: state.loginState.error,
	};
};

export default connect(
	mapStateToProps,
	{
		login,
	}
)(LoginContainer);
