import * as React from 'react';

import { BaseExercisePage } from '../baseExercisePage';
import ContrastSensitivityCanvas from './canvas';
import * as Edge from '../../../core';

import ContrastSensitivityResults from './results';
import ContrastSensitivityConfigForm from './configForm';

export default class ContrastSensitivityPage extends BaseExercisePage<
	Edge.Models.ContrastSensitivityExerciseConfiguration,
	Edge.Models.ContrastSensitivityExerciseResult
> {
	protected renderConfiguration(): JSX.Element {
		const { lockConfiguration, operationName, mode } = this.props;
		const { configuration } = this.state;

		return (
			<ContrastSensitivityConfigForm
				mode={mode}
				operationName={operationName}
				onSubmit={this.startExercise}
				initialConfiguration={configuration}
				lockConfiguration={lockConfiguration}
			/>
		);
	}

	protected renderExercise(): JSX.Element {
		const { currentProfile, mode } = this.props;
		const { configuration, fullScreen } = this.state;

		return (
			<ContrastSensitivityCanvas
				mode={mode}
				debugMode={this.props.debugMode}
				renderScale={currentProfile.renderScale}
				touchControls={currentProfile.touchControls}
				fullScreen={fullScreen!}
				completeExercise={this.finishExercise}
				abortingExercise={this.abortingExercise}
				configuration={configuration}
			/>
		);
	}

	protected renderResults(): JSX.Element {
		const { hideExerciseResults } = this.props;

		return (
			<ContrastSensitivityResults
				results={this.state.result!}
				complete={this.complete}
				retry={this.retryExercise}
				completeText={this.props.completeText}
				hideExerciseResults={hideExerciseResults}
			/>
		);
	}
}
