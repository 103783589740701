import * as React from 'react';

import * as Edge from '../../../core';
import Input from '../../../components/global/input';
import Error from '../../../components/global/error';
import { CreditMode } from '../../../components/assignCredits';
import { SessionService } from '../../../services/sessionService';
import Loading from '../../../components/loading';
import ModalContainer from '../../../components/global/modal';

import './changeManually.scss';

export interface ChangeAssignmentsManuallyProps {
	currentOrganization: Edge.Models.Organization;
	mode: CreditMode;
	reloadData: () => Promise<void>;
}

interface ChangeAssignmentsManuallyState {
	creditQuantity: number;
	confirming?: Confirming;
	error?: Edge.Models.EdgeError;
	loading?: boolean;
}

type Confirming = 'add' | 'remove';

export class ChangeAssignmentsManually extends React.Component<
	ChangeAssignmentsManuallyProps,
	ChangeAssignmentsManuallyState
> {
	constructor(props: ChangeAssignmentsManuallyProps) {
		super(props);
		this.state = {
			creditQuantity: 0,
		};
	}
	public render() {
		const { loading, error } = this.state;
		return (
			<div className="change_manually">
				<div className="header">
					Update {this.props.mode === CreditMode.Subscriptions ? 'Seats' : 'Sessions'}
				</div>
				<Input
					className="admin_text_input"
					type="number"
					onChange={this.updateCreditQuantity}
					value={this.state.creditQuantity}
				/>
				<br />
				<button className="cta_btn admin_btn" onClick={() => this.setState({ confirming: 'add' })}>
					Add
				</button>
				<button className="cta_btn btn_light admin_btn" onClick={() => this.setState({ confirming: 'remove' })}>
					Remove
				</button>
				{this.state.confirming && (
					<ModalContainer title="Are you sure?" open={true} onClose={this.closeModal}>
						<p>{this.getConfirmingText()}</p>
						{loading && <Loading buttonLoader />}
						{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
						<button className="cta_btn" onClick={this.updateAssignment}>
							Yes
						</button>
						<button className="cta_btn btn_light" onClick={this.closeModal}>
							Cancel
						</button>
					</ModalContainer>
				)}
			</div>
		);
	}

	private updateAssignment = async () => {
		this.setState({ loading: true, error: undefined });
		const { creditQuantity } = this.state;
		const command: Edge.Models.UpdatePlanCommand = {
			entityId: this.props.currentOrganization.id,
			entityType: 'Organization',
			creditQuantity: this.state.confirming === 'add' ? creditQuantity : -1 * creditQuantity,
		};
		try {
			this.props.mode === CreditMode.Subscriptions
				? await this.updateSubscription(command)
				: await this.updateSessions(command);
			this.setState({ loading: false, confirming: undefined });
			await this.props.reloadData();
		} catch (error) {
			this.setState({ error, loading: false });
		}
	};

	private updateSubscription = async (command: Edge.Models.UpdatePlanCommand) => {
		await SessionService.updateSubscription(command);
	};

	private updateSessions = async (command: Edge.Models.UpdatePlanCommand) => {
		await SessionService.updateSession(command);
	};

	private updateCreditQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ creditQuantity: +event.target.value });
	};

	private getConfirmingText = (): string => {
		var creditType = this.props.mode === CreditMode.Subscriptions ? 'subscription' : 'session';
		creditType = this.state.creditQuantity === 1 ? creditType : creditType + 's';
		const action = this.state.confirming;
		const toFrom = this.state.confirming === 'add' ? 'to' : 'from';
		return `Do you want to ${action} ${this.state.creditQuantity} ${creditType} ${toFrom} ${
			this.props.currentOrganization.name
		}?`;
	};

	private closeModal = () => {
		this.setState({ confirming: undefined, error: undefined });
	};
}
export default ChangeAssignmentsManually;
