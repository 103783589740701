/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-script-url */
import * as React from 'react';
import { BaseExercise, BaseExerciseProps } from '../baseExercise';
import * as Edge from '../../../core';
import ExerciseLayout from '../../../layouts/exerciseLayout';

declare var VizEdge: any;

interface AlignProps
	extends BaseExerciseProps<Edge.Models.AlignmentExerciseResult, Edge.Models.AlignmentExerciseConfiguration> {}

interface AlignConfiguration {
	fullScreen: boolean;
}

interface AlignResult {
	exercise: 'align';
	audio: boolean;
	evaluation: boolean;
	horizAlign: number;
	vertAlign: number;
}

export class Align extends BaseExercise<
	AlignProps,
	Edge.Models.AlignmentExerciseResult,
	Edge.Models.AlignmentExerciseConfiguration,
	{}
> {
	createSession() {
		return this.doCreateSession(
			this.props.debugMode ? 'true' : 'false',
			this.props.renderScale,
			this.props.touchControls,
			{ fullScreen: this.props.fullScreen },
			this.completeExercise
		);
	}

	/**
	 * Typed wrapper over `new VizEdge.AlignExercise`.
	 */
	private doCreateSession = (
		debugMode: 'true' | 'false',
		renderScale: number,
		touchControls: boolean,
		configuration: AlignConfiguration,
		completeExercise: (result: AlignResult) => void
	) => {
		return new VizEdge.AlignExercise(debugMode, renderScale, touchControls, configuration, completeExercise);
	};

	private completeExercise = (result: AlignResult) => {
		const { configuration, completeExercise } = this.props;
		completeExercise({
			exerciseTypeId: Edge.Models.ExerciseTypeId.Alignment,
			audio: result.audio,
			exerciseConfigurationId: configuration && configuration.id,
			horizontalAlignment: result.horizAlign,
			verticalAlignment: result.vertAlign,

			// not relevant to this exercise type, but makes inheritance easier
			durationSeconds: 0,
			size: 0,
			correctPercent: 0,
			responseTimeMilliseconds: 0,
		});
	};

	public render(): JSX.Element {
		return (
			<ExerciseLayout
				endExercise={this.endSession}
				completeExercise={this.completeSession}
				exerciseName="Alignment"
			>
				<div id="images">
					<img id="target-logo" src="/images/ve-logo-red-trans.png" alt="Ve logo red trans" />
					<img id="dpad-arrow" src="/images/dpad-arrow.png" alt="Dpad arrow" />
				</div>
			</ExerciseLayout>
		);
	}
}
