import * as React from 'react';

import Input from '../../components/global/input';

export interface EnterUsernameProps {
	username: string;
	updateUsername: (event: React.ChangeEvent<HTMLInputElement>) => void;
	changePage: () => void;
}

export const EnterUsername: React.FunctionComponent<EnterUsernameProps> = (props) => {
	return (
		<>
			<div className="forgot_message">
				Don't worry! Resetting your password is easy, just type in the username you registered to Vizual Edge.
			</div>
			<label htmlFor="Username">Username</label>
			<Input
				name="Username"
				id="Username"
				type="text"
				placeholder="Username"
				value={props.username}
				onChange={props.updateUsername}
				maxLength={256}
			/>
			<Input onClick={props.changePage} type="submit" value="Enter" />
			<a href="/forgotUsername">Forgot your username?</a>
		</>
	);
};

export default EnterUsername;
