import * as React from 'react';
import { Switch, Route } from 'react-router';

import PageSwitcher from './pageSwitcher';

import Landing from './landing';
import Page1 from './page1';
import Page2 from './page2';

import './register.scss';
import { AppState } from '../../store';
import { getPrices } from '../../store/staticData';
import { connect } from 'react-redux';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import * as Edge from '../../core';
import JsonDebug from '../../components/jsonDebug';
import { CheckoutPurchaseModel } from '../checkout/models';

export interface RegisterState {
	model: CheckoutPurchaseModel;
}
export interface RegisterProps {
	prices: Edge.Models.Price[];
	getPrices: () => Promise<void>;
}

const defaultModel: CheckoutPurchaseModel = {
	numberOfGlasses: 0,
	quantity: 1,
};

export class Register extends React.Component<RegisterProps, RegisterState> {
	constructor(props: RegisterProps) {
		super(props);
		this.state = { model: Object.assign({}, defaultModel) };
	}
	public render() {
		const baseProps = {
			onChange: this.onChange,
			model: this.state.model,
		};

		return (
			<>
				<h1>Create An Account</h1>
				<p className="headerSecondary">Provide us with your information.</p>
				<PageSwitcher />
				<Switch>
					<Route
						path="/register/page-1/:priceId?/:couponId?"
						render={(props) => <Page1 {...props} {...baseProps} />}
					/>
					<Route
						path="/register/page-2"
						render={(props) => <Page2 {...props} {...baseProps} prices={this.props.prices} />}
					/>
					<Route
						path="/register/:priceId?/:couponId?"
						render={(props) => <Landing {...props} {...baseProps} />}
					/>
				</Switch>
				<JsonDebug hide data={{ model: this.state.model, prices: this.props.prices }} />
			</>
		);
	}
	private onChange = (modelUpdates: Partial<CheckoutPurchaseModel>) => {
		return new Promise((resolve) =>
			this.setState({ model: Object.assign({}, this.state.model, modelUpdates) }, resolve)
		) as Promise<void>;
	};
}

export default connect(
	({ priceState }: AppState) => {
		return {
			isLoading: priceState.isLoading,
			loadingError: priceState.loadingError,
			prices: priceState.data!,
		};
	},
	{ getPrices }
)(
	errorLoadingWrapperHOC(
		Register,
		(props) => !!props.prices,
		() => undefined,
		(props) => {
			if (!props.isLoading) {
				props.getPrices();
			}
		},
		{ loadingOptions: { blockItem: true } }
	)
);
