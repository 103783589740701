import * as React from 'react';
import * as Edge from '../../../core';
import moment from 'moment';

import './index.scss';
import Table from '../../../components/table';
import pdfBanner from '../../../assets/images/pdf_banner.jpg';
import { withLoadDataDefaultConfig } from '../../../components/loadData';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { RouteComponentProps } from 'react-router-dom';
import { StatsService } from '../../../services/statsService';

class TrainingUsageTable extends Table<Edge.Models.AthleteTrainingUsage> {}

interface TrainingUsageRouteProps {
	id: string; // Team ID
	days: string; // reportRange in days
}

export interface TrainingUsageProps {
	trainingUsage: Edge.Models.TrainingUsageResponse;
	reportRangeDays: number;
}

export interface TrainingUsageWrapperProps extends TrainingUsageProps, RouteComponentProps<TrainingUsageRouteProps> {}

export class TrainingUsage extends React.PureComponent<TrainingUsageProps> {
	public render() {
		const { trainingUsage, reportRangeDays } = this.props;

		return (
			<div className="training_usage">
				<img className="no_screen training_usage_banner" alt="banner" src={pdfBanner} />
				<h2 className="no_screen">{trainingUsage.organizationName}</h2>
				<h3>{trainingUsage.teamName}</h3>
				<h3>{moment(trainingUsage.reportUtc).format(Edge.Constants.DATE_FORMAT)}</h3>
				<h3>{reportRangeDays}-Day Training Usage Report</h3>
				<p className="no_screen">
					This Vizual Edge training report serves as an overview of training usage from the previous{' '}
					{reportRangeDays} days from active users under {trainingUsage.teamName}, which includes:
				</p>
				<p className="no_screen">
					<b>(ID) Name</b> – Identifier and athlete's name
					<br />
					<b>Baseline Edge Score</b> – Initial Edge Score value from baseline evaluation
					<br />
					<b># Sessions</b> – Total number of training sessions initiated during report range
					<br />
					<b># Total Exercises</b> – Total number completed exercises during report range
					<br />
					<b>Latest Edge Score</b> – Edge Score value reported from latest evaluation from use
				</p>
				<TrainingUsageTable
					items={trainingUsage.athleteUsages}
					renderTableHeader={() => (
						<tr>
							<th>(ID) Name</th>
							<th>Baseline Edge Score</th>
							<th># Sessions</th>
							<th># Total Exercises</th>
							<th>Latest Edge Score</th>
						</tr>
					)}
					renderTableItem={(i) => (
						<tr>
							<td>
								({i.id.substr(0, 2).toUpperCase()}) {i.name}
							</td>
							<td>{this.getScore(i.baselineEdgeScore)}</td>
							<td>{i.sessionCount}</td>
							<td>{i.exerciseCount}</td>
							<td>{this.getScore(i.latestEdgeScore)}</td>
						</tr>
					)}
					renderNoItems={() => (
						<tr>
							<td>No stats to show</td>
						</tr>
					)}
					pagingMode="none"
				/>
			</div>
		);
	}

	private getScore = (score: number) => {
		if (score) return (score * 100).toFixed(1);
		else return 'N/A';
	};
}

const TrainingUsageWrapper: React.FunctionComponent<TrainingUsageWrapperProps> = (props) => (
	<TrainingUsage trainingUsage={props.trainingUsage} reportRangeDays={props.reportRangeDays} />
);

export default withLoadDataDefaultConfig(
	errorLoadingWrapperHOC(TrainingUsageWrapper),
	(props: TrainingUsageWrapperProps) => ({
		teamId: props.match.params.id,
		days: Number(props.match.params.days),
	}),
	async ({ teamId, days }: { teamId: string; days: number }) => ({
		trainingUsage: await StatsService.getTrainingUsage(teamId, days),
		reportRangeDays: days,
	})
);
