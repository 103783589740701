import * as React from 'react';

import * as Edge from '../../core';
import Input from '../../components/global/input';
import Loading from '../../components/loading';
import Error from '../../components/global/error';

export interface UpdateOrganizationProps {
	userPurchasable: boolean;
	updateOrganization: (userPurchasable: boolean) => Promise<void>;
	closeAdminOptions: () => void;
}

export interface UpdateOrganizationState {
	userPurchasable: boolean;
	loading: boolean;
	error?: Edge.Models.EdgeError;
}

export class UpdateOrganization extends React.Component<UpdateOrganizationProps, UpdateOrganizationState> {
	constructor(props: UpdateOrganizationProps) {
		super(props);
		this.state = {
			userPurchasable: props.userPurchasable,
			loading: false,
		};
	}
	public render() {
		const { userPurchasable, loading, error } = this.state;
		return (
			<div>
				<Input
					type="checkbox"
					id="allowUserPurchase"
					checked={userPurchasable}
					onChange={this.userPurchasableChecked}
				/>
				<label htmlFor="allowUserPurchase">Allow User Purchase</label>
				{error && <Error>{Edge.API.getErrorMessage(error)}</Error>}
				{loading && <Loading buttonLoader />}
				<button className="cta_btn" type="submit" onClick={this.updateOrganization}>
					Submit
				</button>
			</div>
		);
	}

	private userPurchasableChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ userPurchasable: event.target.checked, error: undefined });
	};

	private updateOrganization = async () => {
		this.setState({ loading: true, error: undefined });
		try {
			await this.props.updateOrganization(this.state.userPurchasable);
			this.setState({ loading: false });
			this.props.closeAdminOptions();
		} catch (error) {
			this.setState({ error, loading: false });
		}
	};
}

export default UpdateOrganization;
