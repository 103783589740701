import * as React from 'react';

import * as Edge from '../../../core';
import SingleScore from '../../../components/singleScore';
import { ReactComponent as CompassIcon } from '../../../assets/images/icons/Compass.svg';

import './index.scss';

export interface SkillScoresProps {
	results: Edge.Models.SkillScores;
}
export const SkillScores: React.FunctionComponent<SkillScoresProps> = (props) => {
	const { results } = props;
	return (
		<div className="skill_scores">
			<h4>
				Skill Scores{' '}
				<span className="iconWrapper">
					<CompassIcon />
				</span>
			</h4>
			<div className="avg_scores_wrapper">
				<div className="avg_score_box">
					<SingleScore value={results.depth.correctPercent * 100} />
					<div className="detail">&mdash;</div>
					<div className="title">Depth</div>
				</div>
				<div className="avg_score_box">
					<SingleScore value={results.convergence.convergenceCorrectPercent * 100} />
					<div className="detail">{results.convergence.convergenceStationScore}</div>
					<div className="title">Convergence</div>
				</div>
				<div className="avg_score_box">
					<SingleScore value={results.divergence.divergenceCorrectPercent * 100} />
					<div className="detail">{results.divergence.divergenceStationScore}</div>
					<div className="title">Divergence</div>
				</div>
				<div className="avg_score_box">
					<SingleScore value={results.recognition.correctPercent * 100} />
					<div className="detail">{(results.recognition.responseTimeMilliseconds / 1000).toFixed(2)} s</div>
					<div className="title">Recognition</div>
				</div>
				<div className="avg_score_box">
					<SingleScore value={results.tracking.correctPercent * 100} />
					<div className="detail">{(results.tracking.responseTimeMilliseconds / 1000).toFixed(2)} s</div>
					<div className="title">Tracking</div>
				</div>
			</div>
		</div>
	);
};

export default SkillScores;
