import * as Edge from './../core';

export abstract class CommerceService {
	public static async getPrices(): Promise<Edge.Models.Price[]> {
		const route = Edge.API.ServerRoute.forAction('commerce', 'prices');
		return await Edge.API.get(route);
	}

	public static async getPriceDetails(): Promise<Edge.Models.PriceDetail[]> {
		const prices = await CommerceService.getPrices();
		return await Promise.all(prices.map((i) => CommerceService.getPriceDetail(i.id)));
	}

	public static async getPrice(price: string): Promise<Edge.Models.Price> {
		const route = Edge.API.ServerRoute.forAction('commerce', `prices/${price}`);
		return await Edge.API.get(route);
	}

	public static async getPriceDetail(price: string): Promise<Edge.Models.PriceDetail> {
		const route = Edge.API.ServerRoute.forAction('commerce', `price/details/${price}`);
		return await Edge.API.get(route);
	}

	public static async createCheckoutSession(
		command: Edge.Models.CreateCheckoutSessionCommand
	): Promise<Edge.Models.CreateCheckoutSessionResponse> {
		const route = Edge.API.ServerRoute.forAction('commerce', 'orders');
		return await Edge.API.post(route, command);
	}

	public static async getOrderAndCheckoutSessionDetail(
		externalOrderId: string
	): Promise<Edge.Models.GetOrderDetail[]> {
		const route = Edge.API.ServerRoute.forAction('commerce', `order/${externalOrderId}`);
		return await Edge.API.get(route);
	}

	public static async getOrdersAndCheckoutsSummaries(): Promise<Edge.Models.CheckoutSessionSummary> {
		const route = Edge.API.ServerRoute.forAction('commerce', 'orders');
		return await Edge.API.get(route);
	}

	public static async pay(command: Edge.Models.PayOrderCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('commerce', 'orders/pay');
		await Edge.API.patch(route, command);
	}

	public static async getCoupon(coupon: string): Promise<Edge.Models.Coupon> {
		const route = Edge.API.ServerRoute.forAction('commerce', `coupons/${coupon}`);
		return await Edge.API.get(route);
	}

	public static async getOrganizationAllowedPrices(
		organizationId: string,
		entityType: Edge.Models.EntityType,
		entityId: string
	): Promise<Edge.Models.OrganizationAllowedPrice[]> {
		const route = Edge.API.ServerRoute.forAction(
			'commerce',
			`restrictions/${organizationId}/${entityType}/${entityId}`
		);
		return await Edge.API.get(route);
	}

	public static async getFullOrganizationAllowedPrices(
		organizationId: string
	): Promise<Edge.Models.FullCommerceRestrictionsResponse[]> {
		const route = Edge.API.ServerRoute.forAction('commerce', `fullRestrictions/${organizationId}`);
		return await Edge.API.get(route);
	}

	public static async updateOrganizationAllowedPrices(
		command: Edge.Models.UpdateCommerceRestrictionsCommand
	): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('commerce', `restrictions`);
		return await Edge.API.put(route, command);
	}

	public static async updateActivePrice(command: Edge.Models.updateActivePriceCommand): Promise<void> {
		const route = Edge.API.ServerRoute.forAction('commerce', 'price/active');
		return await Edge.API.patch(route, command);
	}
}
