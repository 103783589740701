import * as Edge from '../core';

export abstract class KeysService {
	// Gets one key associated to entityID
	public static async getKey(entityType: string, entityId: string): Promise<Edge.Models.ApiKey> {
		const route = Edge.API.ServerRoute.forAction('keys', `${entityType}/${entityId}`);
		return await Edge.API.get(route);
	}

	// Gets all keys associated to organizationID
	public static async getKeys(organizationId: string): Promise<Edge.Models.ApiKey[]> {
		const route = Edge.API.ServerRoute.forAction('keys', organizationId);
		return await Edge.API.get(route);
	}

	// Creates new key associated to entityID
	public static async createKey(command: Edge.Models.EditKeyCommand): Promise<Edge.Models.ApiKey> {
		const route = Edge.API.ServerRoute.forAction('keys');
		return await Edge.API.post(route, command);
	}

	// Refreshes key token associated to entityID
	public static async refreshKey(command: Edge.Models.EditKeyCommand): Promise<Edge.Models.ApiKey> {
		const route = Edge.API.ServerRoute.forAction('keys', 'refresh');
		return await Edge.API.patch(route, command);
	}

	// Toggles key associated to entityID
	public static async toggleKey(command: Edge.Models.EditKeyCommand): Promise<Edge.Models.ApiKey> {
		const route = Edge.API.ServerRoute.forAction('keys', 'toggle');
		return await Edge.API.patch(route, command);
	}
}
