import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import classNames from 'classnames';

import * as Edge from './core';
import { store } from './store';
import CustomBrowserRouter from './components/global/customBrowserRouter';

import { LayoutRoute } from './components/global/routing/layoutRoute';
import { AuthenticatedLayoutRoute } from './components/global/routing/authenticatedLayoutRoute';

import LoginLayout from './layouts/loginLayout';
import AuthenticatedLayout from './layouts/authenticatedLayout';
import { RegisterLayout } from './layouts/greyScreenLayout';

import Login from './pages/login';
import Logout from './pages/logout';
import Register from './pages/register';

import Dashboard from './pages/dashboard';
import Training from './pages/training';
import ManageTeams from './pages/manageTeams';
import Stats from './pages/stats';
import ManageOrganization from './pages/manageOrganization';
import MyAccount from './pages/myAccount';
import RegisterViaInvite from './pages/registerViaInvite';

import './variables.scss';
import './App.scss';
import { resumeFromCache } from './store/login/actions';
import NotFoundPage from './pages/notFound';
import OrganizationSearch from './pages/organizationSearch';
import Home from './pages/home';
import TermsPage from './pages/terms';
import EvaluationResults from './pages/training/evaluation/results';
import ForgotUsername from './pages/forgotUsername';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import AthleteStats from './pages/stats/athlete';
import EvaluationComplete from './pages/training/evaluationComplete';
import AdminEdgeScores from './pages/admin/edgeScores';
import AdminOperations from './pages/admin/operations';
import UserRoleSearch from './pages/userRoleSearch';
import TrainingUsage from './pages/training/trainingUsage';
import Orders from './pages/orders';
import CheckoutPage from './pages/checkout';
import CheckoutSuccessfulModal from './pages/register/checkoutSuccesfulModal';

const EmptyLayout: React.FunctionComponent = (props) => <>{props.children}</>;

class App extends React.Component {
	public componentDidMount() {
		store.dispatch(resumeFromCache() as any);
	}
	public render(): JSX.Element {
		return (
			<div className={classNames(Edge.Configuration.domRootClass)}>
				<Provider store={store}>
					<CustomBrowserRouter>{this.renderRootRoute()}</CustomBrowserRouter>
				</Provider>
			</div>
		);
	}

	private renderRootRoute(): JSX.Element {
		return (
			<Switch>
				<AuthenticatedLayoutRoute exact path="/" component={Home} layout={RegisterLayout} />
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/dashboard"
					component={Dashboard}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute
					path="/training/evaluation/complete"
					layout={AuthenticatedLayout}
					component={EvaluationComplete}
				/>
				<AuthenticatedLayoutRoute
					path="/training/evaluation/:id"
					layout={AuthenticatedLayout}
					onlyNormalUsers
					component={EvaluationResults}
				/>
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/training/usage/:id/:days"
					component={TrainingUsage}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute path="/training" component={Training} layout={AuthenticatedLayout} />
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/manage-teams/:teamId?"
					component={ManageTeams}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/stats/:userId/:teamId"
					component={AthleteStats}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/stats"
					component={Stats}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/resources"
					component={() => {
						window.location.replace(Edge.Constants.URL_RESOURCES);
						return null;
					}}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/manage-organization"
					component={ManageOrganization}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/my-account"
					component={MyAccount}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/checkout/:entityType/:entityId/:checkoutSessionType"
					component={CheckoutPage}
					layout={EmptyLayout}
				/>

				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/organizations"
					component={OrganizationSearch}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/user-roles"
					component={UserRoleSearch}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/order-history"
					component={Orders}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/admin_edge_scores"
					component={AdminEdgeScores}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path="/admin"
					component={AdminOperations}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute
					onlyNormalUsers
					path={'/checkout-successful'}
					component={CheckoutSuccessfulModal}
					layout={EmptyLayout}
				/>
				<LayoutRoute path="/register" component={Register} layout={RegisterLayout} />
				<LayoutRoute path="/invite/:token" component={RegisterViaInvite} layout={RegisterLayout} />
				<LayoutRoute path="/login/:from?" component={Login} layout={LoginLayout} />
				<LayoutRoute path="/forgotUsername" component={ForgotUsername} layout={LoginLayout} />
				<LayoutRoute path="/forgotPassword" component={ForgotPassword} layout={LoginLayout} />
				<LayoutRoute path="/resetPassword/:userId/:code" component={ResetPassword} layout={LoginLayout} />
				<Route path="/logout" exact component={Logout} />
				<AuthenticatedLayoutRoute
					path="/terms/:from?"
					allowAccessWithoutTerms
					component={TermsPage}
					layout={AuthenticatedLayout}
				/>
				<AuthenticatedLayoutRoute path="/" component={NotFoundPage} layout={AuthenticatedLayout} />
			</Switch>
		);
	}
}

export default App;
