import * as Edge from './../core';

export abstract class DeviceProfileService {
	public static async getProfiles(): Promise<Edge.Models.DeviceProfile[]> {
		const route = Edge.API.ServerRoute.forAction('deviceProfiles');
		return await Edge.API.get(route);
	}

	public static async createProfile(profile: Edge.Models.DeviceProfile): Promise<Edge.Models.DeviceProfile> {
		const route = Edge.API.ServerRoute.forAction('deviceProfiles');
		return await Edge.API.post(route, profile);
	}

	public static async updateProfile(profile: Edge.Models.DeviceProfile): Promise<Edge.Models.DeviceProfile> {
		const route = Edge.API.ServerRoute.forAction('deviceProfiles');
		return await Edge.API.patch(route, profile);
	}
}
