import * as Edge from './../core';

export default abstract class TeamTypeService {
	public static isProspectOnlyTeam(teamType: Edge.Models.TeamType): boolean {
		return (
			teamType.id === Edge.Models.TeamTypeId.ProspectOnly.toString() ||
			teamType.id === Edge.Models.TeamTypeId.Event.toString()
		);
	}

	public static isEventTeam(teamType: Edge.Models.TeamType): boolean {
		return teamType.id === Edge.Models.TeamTypeId.Event.toString();
	}
}
