import * as React from 'react';

export interface ContactSupportLinkProps {
	text?: string;
}

const ContactSupportLink: React.FunctionComponent<ContactSupportLinkProps> = (props) => (
	<a href="mailto:support@vizualedge.com">{props.text || 'contact support'}</a>
);
export default ContactSupportLink;
