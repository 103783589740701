import React, { useState, useEffect } from 'react';
import { ModalContainer } from '../../../components/global/modal';
import { Link } from 'react-router-dom';
import history from '../../../services/history';

export const CheckoutSuccessfulModal = () => {
	const [linkClicked, setLinkClicked] = useState(false);

	useEffect(() => {
		const timeoutId = setTimeout(
			() => {
				history.push('/');
			},
			linkClicked ? 0 : 5000
		);

		return () => clearTimeout(timeoutId);
	}, [linkClicked]);

	const handleLinkClick = () => {
		setLinkClicked(true);
	};

	return (
		<ModalContainer className="order" open title="Order Confirmation - Thank You">
			<p>A confirmation email will be sent to you shortly.</p>
			<p>
				<Link to="/" onClick={handleLinkClick}>
					Proceed to the Edge Trainer App.
				</Link>
			</p>
		</ModalContainer>
	);
};

export default CheckoutSuccessfulModal;
