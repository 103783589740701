import * as React from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import classNames from 'classnames';

import './index.scss';

export interface ModalContainerProps {
	title?: string;
	open?: boolean;
	onClose?: () => void;
	className?: string;
}

class ModalInner extends React.PureComponent<ModalContainerProps> {
	handleClickOutside = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};
	public render(): JSX.Element {
		const realClassName = classNames(this.props.open ? 'open' : '', this.props.className, 'modalContainer');
		return (
			<div className={realClassName}>
				<div className="modal_content">
					<div className="modal_title">{this.props.title}</div>
					{this.props.onClose && <div className="closeBtn" onClick={this.props.onClose} />}
					{this.props.children}
				</div>
			</div>
		);
	}
}

const RealModalInner = enhanceWithClickOutside(ModalInner);

export class ModalContainer extends React.PureComponent<ModalContainerProps> {
	public render(): JSX.Element {
		return (
			<>
				<div className={classNames('modalHost', { open: this.props.open })} />
				<RealModalInner {...this.props} />
			</>
		);
	}
}

export default ModalContainer;
