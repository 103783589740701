import screenfull from 'screenfull';

export default class FullScreen {
	static setAsync = async (isFullScreen: boolean): Promise<void> => {
		if (!screenfull.isEnabled) return;

		if (isFullScreen && !screenfull.isFullscreen) {
			// request full screen and full screen is currently off
			await screenfull.request();
		} else if (!isFullScreen && screenfull.isFullscreen) {
			// request exit full screen and full screen is currently on
			await screenfull.exit();
		}
	};
}
