/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-script-url */
import * as Edge from '../../../core';
import * as Helper from './helper';
import * as React from 'react';
import { BaseExercise, BaseExerciseProps } from '../baseExercise';
import ExerciseLayout from '../../../layouts/exerciseLayout';
import { Howler } from 'howler';
import { DPad, Drawing, FullScreen, Keyboard, Stage, Starter, Timer } from '../../../exercises';
import ExerciseComponent from './exerciseComponent';

const QuestionTimeoutSeconds: number = 5;
// Moves trainee to next station if ceiling(StationQuestionsBestOf / 2) answers are correct.
const StationQuestionsBestOf: number = 3;

interface ContrastSensitivityProps
	extends BaseExerciseProps<
		Edge.Models.ContrastSensitivityExerciseResult,
		Edge.Models.ContrastSensitivityExerciseConfiguration
	> {}

interface ExerciseResponse {
	correct: boolean;
	responseTime: number;
}

interface ExerciseConfiguration extends Edge.Models.ContrastSensitivityExerciseConfiguration {
	fullScreen: boolean;
	renderScale: number;
	sessionLength: number;
	touchControls: boolean;
}

/**
 * Coordinates all the components required to run this exercise.
 */
export default class ContrastSensitivityCanvas extends BaseExercise<
	ContrastSensitivityProps,
	Edge.Models.ContrastSensitivityExerciseResult,
	Edge.Models.ContrastSensitivityExerciseConfiguration,
	{}
> {
	// Canvas
	private ctx: CanvasRenderingContext2D = null!;

	// Components
	private dPad: DPad = null!;
	private exerciseComponent: ExerciseComponent;
	private starter: Starter = null!;
	private timer: Timer = null!;

	// Exercise
	private animationId?: number; // Keeps track of animation frame request for cancelling.
	private exerciseConfiguration: ExerciseConfiguration;
	private maxStationOfLevel: number;

	// State
	private acceptingInput: boolean = false;
	private lastPromptTime?: number;
	private previousFixedUpdateTimestamp?: DOMHighResTimeStamp;
	private stationQuestionsCorrect: number = 0;
	private stationQuestionsIncorrect: number = 0;
	private responses: ExerciseResponse[] = [];
	private stationCurr: number = 1;
	private stationMax: number = 1;

	// Timers
	private questionTimeout: ReturnType<typeof setTimeout> | null = null;
	private sessionTimer: ReturnType<typeof setTimeout> | null = null;

	constructor(props: ContrastSensitivityProps) {
		super(props);

		// Sets session length depending on debug mode.
		this.exerciseConfiguration = Object.assign(props.configuration, {
			...props,
			sessionLength: props.debugMode
				? Stage.DebugSessionLength * 1000
				: props.configuration.durationSeconds * 1000,
		});

		const contrastIncrement = Math.min(this.exerciseConfiguration.level / 2, 2);
		this.maxStationOfLevel = Math.floor((100 - Helper.StartingContrast) / contrastIncrement);

		this.exerciseComponent = new ExerciseComponent(
			contrastIncrement,
			this.exerciseConfiguration.size,
			this.exerciseConfiguration.renderScale
		);

		// Mutes sound on debug.
		Howler.mute(props.debugMode === true);
	}

	// From BaseExercise
	public createSession = (): Edge.Models.ExerciseSession => {
		const session = {
			endSession: this.endExercise,
			startSession: this.startExercise,
		};

		return session;
	};

	public render = (): JSX.Element => {
		return (
			<ExerciseLayout
				cleanUpExercise={this.cleanUp}
				completeExercise={this.completeSession}
				endExercise={this.endSession}
				exerciseName="Contrast Sensitivity"
			>
				<canvas
					id={Stage.Name}
					style={{
						backgroundColor: '#fff',
						display: 'inline',
						height: window.innerHeight - Stage.Margin.vertical,
						width: window.innerWidth - Stage.Margin.horizontal,
					}}
				/>
			</ExerciseLayout>
		);
	};

	private endExercise = async (): Promise<void> => {
		// Exits fullscreen.
		await FullScreen.setAsync(false);

		// Calculates exercise results.
		const { configuration, completeExercise } = this.props;
		const resultCount = this.responses.length;
		const correctCount = this.responses.filter((r) => r.correct).length;
		const totalResponseTime = this.responses.map((r) => r.responseTime).reduce((p, c) => (c += p), 0);

		completeExercise({
			audio: true,
			correctPercent: resultCount > 0 ? correctCount / resultCount : 0, // JS is out of 100, server is out of 1
			durationSeconds: this.exerciseConfiguration.sessionLength / 1000,
			exerciseTypeId: Edge.Models.ExerciseTypeId.ContrastSensitivity,
			exerciseConfigurationId: configuration.id,
			level: this.exerciseConfiguration.level,
			responseTimeMilliseconds: resultCount > 0 ? totalResponseTime / resultCount : 0,
			size: this.exerciseConfiguration.size,
			stationMax: this.stationMax,
		});
	};

	/**
	 * Cleans up after exercise. Expected to run when a React component unmounts to avoid memory leaks.
	 */
	private cleanUp = (): void => {
		// Cancels remaining animation.
		cancelAnimationFrame(this.animationId!);

		// Prevents further animation and trainee input.
		this.acceptingInput = false;
		this.starter.setIsRunning(false);

		// Removes event listeners.
		document.removeEventListener('keydown', this.handleKeyDown);
		this.ctx.canvas.removeEventListener('click', this.handleCanvasClicked);

		// Clears timers.
		if (this.questionTimeout) clearTimeout(this.questionTimeout);
		if (this.sessionTimer) clearTimeout(this.sessionTimer);
	};

	/**
	 * Updates canvas; called by requestAnimationFrame().
	 * @param timestamp is the time in which requestAnimationFrame executed this method.
	 */
	private fixedUpdate = (timestamp: DOMHighResTimeStamp): void => {
		// Determines elapsed time since last update.
		const elapsed: number = timestamp - (this.previousFixedUpdateTimestamp || timestamp);

		// Clears canvas for redraw.
		this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

		// Updates exercise element positions.
		this.exerciseComponent.updatePosition(elapsed);

		// Draws exercise elements; order is significant.
		this.exerciseComponent.draw();
		this.timer.draw();
		this.dPad.draw(this.exerciseConfiguration.touchControls);

		// Decrements timer.
		this.timer.elapseTime(elapsed);

		this.previousFixedUpdateTimestamp = timestamp;
		this.animationId = requestAnimationFrame(this.fixedUpdate);
	};

	private handleCanvasClicked = (event: MouseEvent) => this.dPad.handleCanvasClicked(event);

	private handleKeyDown = (event: KeyboardEvent) => Keyboard.handleKeyDown(event, this.selectDirection);

	private recordResponse = (correct: boolean, responseTime?: number): void => {
		const response = {
			correct,
			responseTime: responseTime || Date.now() - this.lastPromptTime!,
		};

		// Plays correct/wrong response sound and updates response counts.
		if (response.correct) {
			this.stationQuestionsCorrect++;
			this.crystalSound.play();
		} else {
			this.stationQuestionsIncorrect++;
			this.buzzerSound.play();
		}

		// Determines whether station is complete.
		const stationComplete = this.stationQuestionsCorrect + this.stationQuestionsIncorrect >= StationQuestionsBestOf;
		if (stationComplete) {
			const stationPassed: boolean = this.stationQuestionsCorrect >= Math.ceil(StationQuestionsBestOf / 2);
			this.stationCurr = stationPassed
				? Math.min(this.stationCurr + 1, this.maxStationOfLevel)
				: Math.max(this.stationCurr - 1, 1);

			this.stationQuestionsCorrect = this.stationQuestionsIncorrect = 0;

			// Determines max station of session.
			this.stationMax = Math.max(this.stationMax, this.stationCurr);
		}

		// Adds response to array.
		this.responses.push(response);
	};

	private selectDirection = (direction: string): void => {
		if (!this.acceptingInput) return;

		this.recordResponse(direction === this.exerciseComponent.getCorrectCircleDirection());
		this.setupQuestion();
	};

	private setupQuestion = (): void => {
		this.exerciseComponent.setupNextQuestion(this.stationCurr);
		this.lastPromptTime = Date.now();

		// Clears question timeout if needed.
		if (this.questionTimeout) {
			clearTimeout(this.questionTimeout);
			this.questionTimeout = null;
		}

		// Question is automatically timed out and marked incorrect after some time.
		this.questionTimeout = setTimeout(() => {
			// Passes in responseTime for accuracy.
			this.recordResponse(false, QuestionTimeoutSeconds * 1000);
			this.setupQuestion();
		}, QuestionTimeoutSeconds * 1000);
	};

	private startExercise = async (): Promise<void> => {
		// Sets up full screen.
		await FullScreen.setAsync(this.exerciseConfiguration.fullScreen);

		// Sets up canvas.
		const canvas = document.getElementById(Stage.Name)! as HTMLCanvasElement;

		const canvasHeight: number = window.innerHeight - Stage.Margin.vertical;
		canvas.height = canvasHeight;
		canvas.style.height = `${canvasHeight}px`;

		const canvasWidth: number = window.innerWidth - Stage.Margin.horizontal;
		canvas.width = canvasWidth;
		canvas.style.width = `${canvasWidth}px`;

		this.ctx = canvas.getContext('2d')!;
		Drawing.fixCanvasDpi(this.ctx);

		// Creates new instance of canvas element controls.
		this.dPad = new DPad(this.ctx, false, this.selectDirection, this.exerciseConfiguration.renderScale);
		this.timer = new Timer(
			this.ctx,
			this.exerciseConfiguration.sessionLength,
			this.exerciseConfiguration.renderScale
		);

		// Initializes exercise component.
		this.exerciseComponent.initialize(this.ctx, this.timer.posYTop, this.ctx.canvas.width);

		// Wires up event listeners.
		document.addEventListener('keydown', this.handleKeyDown);
		this.ctx.canvas.addEventListener('click', this.handleCanvasClicked);

		// Ready! Go!
		this.starter = new Starter(this.ctx, this.onStarterFinishCallback);
		this.starter.run();
	};

	private onStarterFinishCallback = (): void => {
		// Starts update loop.
		this.acceptingInput = true;
		this.setupQuestion();

		this.animationId = requestAnimationFrame(this.fixedUpdate);

		// Sets up session end.
		this.sessionTimer = setTimeout(async () => {
			await this.endExercise();
		}, this.exerciseConfiguration.sessionLength);
	};
}
