import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import * as Edge from '../../../core';
import FormField from '../../../components/formField';
import Error from '../../../components/global/error';
import { AppState } from '../../../store';
import Loading from '../../../components/loading';

export interface CreateTeamProps {
	initialValues?: Partial<CreateTeamValues>;
	isAdmin?: boolean;
	levels: Edge.Models.Level[];
	onSubmit: (values: CreateTeamValues) => Promise<void>;
	sports: Edge.Models.Sport[];
}

export interface CreateTeamValues {
	eventPageUrl?: string;
	levelId: string;
	sportId: string;
	teamName: string;
	teamTypeId: Edge.Models.TeamTypeId;
}

const createAccountSchema = Yup.object().shape({
	eventPageUrl: Yup.string().notRequired(),
	levelId: Yup.string().required('Level is required'),
	sportId: Yup.string().required('Sport is required'),
	teamName: Yup.string().required('Team name is required'),
	teamTypeId: Yup.string().required('Team Type is required'),
});

export class CreateTeam extends React.Component<CreateTeamProps> {
	public render() {
		const { initialValues, sports, levels, onSubmit } = this.props;

		return (
			<div>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							levelId: '',
							sportId: '',
							teamName: '',
							teamTypeId: Edge.Models.TeamTypeId.Standard.toString(),
						},
						initialValues || {}
					)}
					validationSchema={createAccountSchema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await onSubmit(values);
						} catch (e) {
							actions.setStatus(Edge.API.getErrorMessage(e));
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<CreateTeamValues>) => (
						<Form>
							<FormField type="text" name="teamName" description="Team name" />
							<FormField component="select" name="sportId" label="Sport">
								<option value="" />
								{_.orderBy(sports, (i) => i.name).map((t, ix) => {
									return (
										<option key={ix} value={t.id}>
											{t.name}
										</option>
									);
								})}
							</FormField>
							<FormField component="select" name="levelId" label="Level">
								<option key={-1} value="" />
								{_.orderBy(levels, (l: Edge.Models.Level) => l.name).map((l: Edge.Models.Level) => (
									<option key={l.id} value={l.id}>
										{l.id === Edge.Models.LevelId.Youth
											? Edge.Constants.YOUTH_LEVEL_LONG_DESCRIPTION
											: l.name}
									</option>
								))}
							</FormField>
							{this.props.isAdmin && (
								<FormField
									component="select"
									name="teamTypeId"
									label="Team type"
									onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
										// Resets Event Page URL field every time event type changes.
										const isEvent = e.target.value === Edge.Models.TeamTypeId.Event.toString();

										props.setFieldValue(
											'eventPageUrl',
											isEvent ? Edge.Configuration.teamEventPageUrl : undefined
										);
									}}
								>
									<option value={Edge.Models.TeamTypeId.Standard.toString()}>Standard</option>
									<option value={Edge.Models.TeamTypeId.ProspectOnly.toString()}>
										Prospect only
									</option>
									<option value={Edge.Models.TeamTypeId.Event.toString()}>
										Event (prospect only)
									</option>
									<option value={Edge.Models.TeamTypeId.StationMode.toString()}>Station Mode</option>
								</FormField>
							)}
							{props.values.teamTypeId.toString() === Edge.Models.TeamTypeId.Event.toString() && (
								<FormField type="text" name="eventPageUrl" description="Event Page URL" />
							)}
							{props.status && <Error>{props.status}</Error>}
							{props.isSubmitting && <Loading buttonLoader />}
							<button type="submit" disabled={props.isSubmitting}>
								Create Team
							</button>
						</Form>
					)}
				/>
			</div>
		);
	}
}

function mapStateToProps({ loginState }: AppState) {
	return { isAdmin: loginState.decodedToken && loginState.decodedToken.is_admin };
}

export default connect(mapStateToProps)(CreateTeam);
