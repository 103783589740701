import * as ReduxThunk from 'redux-thunk';
import * as Redux from 'redux';

import * as Edge from './../../core';

import { StaticDataItemDataState, StaticDataActionTypes } from './types';

export default function create<T, TLoading extends string, TSuccess extends string, TFailure extends string>(
	load: () => Promise<T[]>,
	loadingType: TLoading,
	successType: TSuccess,
	failureType: TFailure
) {
	// action creators
	const loadAction = () => {
		return {
			type: loadingType,
		};
	};

	const successAction = (items: T[]) => {
		return {
			type: successType,
			payload: items,
		};
	};

	const failureAction = (error: Edge.Models.EdgeError) => {
		return {
			type: failureType,
			payload: error,
			error: true,
		};
	};

	// thunk actions
	const get = (): ReduxThunk.ThunkAction<Promise<void>, {}, null, Edge.Models.EdgeAction> => async (dispatch) => {
		dispatch(loadAction());

		try {
			const items = await load();
			dispatch(successAction(items));
		} catch (error) {
			dispatch(failureAction(error));
		}
	};

	const initialState: StaticDataItemDataState<T> = {
		isLoading: false,
	};

	const reducer: Redux.Reducer<StaticDataItemDataState<T>> = (
		state = initialState,
		action: StaticDataActionTypes<T, TLoading, TSuccess, TFailure>
	): StaticDataItemDataState<T> => {
		switch (action.type) {
			case loadingType:
				return {
					...state,
					isLoading: true,
					loadingError: undefined,
				};
			case successType:
				const payload: T[] = action.payload || [];
				return {
					...state,
					isLoading: false,
					data: payload,
					loadingError: undefined,
				};
			case failureType:
				return {
					...state,
					isLoading: false,
					loadingError: action.payload as Edge.Models.EdgeError,
				};
			default:
				return state;
		}
	};

	return {
		get,
		reducer,
	};
}
