/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-script-url */
import * as Edge from '../../../core';
import * as Helper from './helper';
import { Animate, Drawing, Vector } from '../../../exercises';

const DebugLineThickness: number = 3;
const DebugLineLength: number = 120;

const DividerThickness: number = 5; // Impacts boundary margin.
const DividerBottomPadding: number = 10;
const DefaultCircleDiam: number = 50; // Equal to SVG height/width attrs.
const BoundaryPadding: number = 2;

const ImageScales: number[] = [0, 1.0, 1.2, 1.4]; // assumes that `ExerciseSize` has 4 values. should probably refactor to accommodate
const SpeedScales: number[] = [0, 1.5, 3.0, 6.0, 9.0]; // assumes that `ExerciseSpeed` has 5 values. should probably refactor to accommodate

interface Divider {
	left: Animate.Boundary;
	middle: Animate.Boundary;
	right: Animate.Boundary;
}

interface QuadrantParam {
	boundary?: Animate.Boundary;
	circle?: HTMLImageElement;
	posCurr: Vector;
	quadrant: number;
	vCurr: Vector;
	vNext?: Vector;
}

/**
 * Splits the canvas into quadrants top, right, bottom left.
 *  ____ ________ ____
 * |   ||        ||   |
 * |   ||________||   |
 * |   ||‾‾‾‾‾‾‾‾||   |
 * |   ||        ||   |
 *  ‾‾‾‾ ‾‾‾‾‾‾‾‾ ‾‾‾‾
 * Each quadrant contains one circle that moves in a certain matter depending on Tracking Mode:
 * Pursuits: Objects move randomly within the respective quadrants.
 * Saccades: Objects flashes onto the screen in random locations (within the respective quadrants).
 *
 * @see ContrastTrackingExercise.draw() for how this componet draws elements.
 * @see ContrastTrackingExercise.updatePosition() for how this component calculates position elements.
 *
 * The contrast of all circles but one are set to -100%. The circle that doesn't have its contrast reduced
 * is the first correct answer and will continue to decrease as the station # increases.
 */
export default class ContrastTrackingExerciseComponent {
	// Canvas
	private ctx: CanvasRenderingContext2D = null!;

	// Exercise
	private allowPositionUpdate: boolean = false;
	private circleDiam: number;
	private circleTurnRadius: number;
	private contrast: number; // Starts at 0, -1 for a completely gray image.
	private contrastIncrement: number;
	private correctQuadrant?: number;
	private divider?: Divider;
	private speed: number;
	private quadrantParams: QuadrantParam[];
	private trackingMode: Edge.Models.TrackingMode;
	private warningTrackBuffer: number;

	// Debug
	private showDebugElements: boolean = false;

	constructor(
		contrastIncrement: number,
		exerciseSize: Edge.Models.ExerciseSize,
		exerciseSpeed: Edge.Models.ExerciseSpeed,
		renderScale: number,
		trackingMode: Edge.Models.TrackingMode
	) {
		this.circleDiam = DefaultCircleDiam * ImageScales[exerciseSize] * renderScale;
		this.circleTurnRadius = this.circleDiam;
		this.contrastIncrement = contrastIncrement;
		this.speed = SpeedScales[exerciseSpeed] * renderScale;
		this.trackingMode = trackingMode;

		this.contrast = this.calculateContrast(1);

		// Gives enough space for a 360deg turn.
		this.warningTrackBuffer = this.circleTurnRadius * 2;

		// Instantiates current circle positions, vectors for each quadrant.
		this.quadrantParams = [];
		for (let i = 0; i < 4; i++) {
			this.quadrantParams[i] = {
				quadrant: i,
				posCurr: new Vector(0, 0, 0),
				vCurr: new Vector(0, 0, 0),
			};
		}
	}

	public draw = (): void => {
		// Draws quadrant dividers.
		this.drawDivider();

		// Draws border and circles.
		this.drawCircles();

		// Draws debug borders if enabled.
		if (this.showDebugElements) this.drawDebugElements();
	};

	public getCorrectQuadrantDirection = (): string => {
		return Helper.getQuadrantDirection(this.correctQuadrant);
	};

	public initialize = async (
		ctx: CanvasRenderingContext2D,
		sideQHeight: number,
		middleQHeight: number,
		width: number
	): Promise<void> => {
		const margin = DividerThickness + this.circleDiam / 2 + BoundaryPadding;
		this.ctx = ctx;

		// Sets up quadrant boundaries and initializes current circle position/vector.
		// Quardant number/order is defined here as
		//  [0: top, 1: right, 2: bottom, 3: left]
		this.quadrantParams.forEach(async (p: QuadrantParam) => {
			const height = p.quadrant === 1 || p.quadrant === 3 ? sideQHeight : middleQHeight;

			p.boundary = {
				bottom: height * Helper.bottomPct(p.quadrant) - margin,
				left: width * Helper.leftPct(p.quadrant) + margin,
				right: width * Helper.rightPct(p.quadrant) - margin,
				top: height * Helper.topPct(p.quadrant) + margin,
			};

			// Starts circle at center of quadrant.
			p.posCurr.init(
				p.boundary.left + (p.boundary.right - p.boundary.left) / 2,
				p.boundary.top + (p.boundary.bottom - p.boundary.top) / 2,
				0
			);

			// Starts circle movement in random direction.
			// X, Y values can be -1, 0, 1.
			const coordinate: { x: number; y: number } = Animate.getStartingVectorCoordinate();
			p.vCurr
				.init(coordinate.x, coordinate.y, 0)
				.unit()
				.multiply(this.speed);

			p.circle = await Drawing.loadImageAsync('/images/circle-spacial-freq.svg');
		}, this);

		// Determines border boundaries.
		this.divider = {
			left: {
				bottom: middleQHeight - DividerBottomPadding,
				left: width * Helper.rightPct(3) - DividerThickness,
				right: width * Helper.rightPct(3) + DividerThickness,
				top: 0,
			},
			middle: {
				bottom: middleQHeight * Helper.bottomPct(0) + DividerThickness,
				left: width * Helper.leftPct(0),
				right: width * Helper.rightPct(0),
				top: middleQHeight * Helper.bottomPct(0) - DividerThickness,
			},
			right: {
				bottom: middleQHeight - DividerBottomPadding,
				left: width * Helper.leftPct(1) - DividerThickness,
				right: width * Helper.leftPct(1) + DividerThickness,
				top: 0,
			},
		};
	};

	public setupNextQuestion = (stationCurr: number): void => {
		this.contrast = this.calculateContrast(stationCurr);
		this.correctQuadrant = this.getNewCorrectQuadrant(this.correctQuadrant);
		this.allowPositionUpdate = true;
	};

	public updatePosition = (elapsedTime: number): void => {
		// Updates element positions depending on Tracking Mode.
		this.quadrantParams.forEach((p: QuadrantParam, i: number, a: QuadrantParam[]) => {
			if (!this.allowPositionUpdate) return;

			switch (this.trackingMode) {
				case Edge.Models.TrackingMode.Saccades:
					const posX: number =
						Math.round(Math.random() * (p.boundary!.right - p.boundary!.left)) + p.boundary!.left;
					const posY: number =
						Math.round(Math.random() * (p.boundary!.bottom - p.boundary!.top)) + p.boundary!.top;
					p.posCurr = p.posCurr.init(posX, posY, 0);

					// Prevents position updates after last quadrant.
					this.allowPositionUpdate = i < a.length - 1;
					break;
				case Edge.Models.TrackingMode.Pursuits:
				default:
					if (!p.vNext) {
						const coordinate: { x: number; y: number } = Animate.getStartingVectorCoordinate();
						p.vNext = new Vector(coordinate.x, coordinate.y, 0).unit().multiply(this.speed);
					}

					const radiansPerFrame: number = this.speed / this.circleTurnRadius;

					let framesRemaining: number = elapsedTime > 0 ? elapsedTime / (1000 / Animate.FpsTarget) : 1;
					while (framesRemaining > 0) {
						const newValues = Animate.updatePositionAndVector(
							p.boundary!,
							this.warningTrackBuffer,
							framesRemaining,
							p.posCurr,
							radiansPerFrame,
							this.speed,
							p.vCurr,
							p.vNext!
						);

						p.posCurr = newValues.positionCurrent;
						p.vCurr = newValues.vectorCurrent;
						p.vNext = newValues.vectorNext;

						// Decrements frames remaining.
						framesRemaining =
							framesRemaining > newValues.framesToNext ? framesRemaining - newValues.framesToNext : 0;
					}

					// Always updates position.
					this.allowPositionUpdate = true;
					break;
			}
		}, this);
	};

	// Calculates contrast reduction percent.
	// Returns a float ranging from (100 - StartingContrast)% ~ 100%
	private calculateContrast = (stationCurr: number): number => {
		return (Helper.StartingContrast + (stationCurr - 1) * this.contrastIncrement) / 100 - 1;
	};

	private drawDivider = (): void => {
		if (!this.divider) return;

		this.ctx.save();

		this.ctx.fillStyle = 'black';
		Object.values(this.divider).forEach((b: Animate.Boundary) => {
			this.ctx.fillRect(b.left, b.top, b.right - b.left, b.bottom - b.top);
		});

		this.ctx.restore();
	};

	private drawCircles = (): void => {
		// Adds an extra unit to the diameter to avoid object clipping.
		const drawingObjectSize: number = this.circleDiam + 1;

		this.ctx.save();

		this.quadrantParams.forEach((p: QuadrantParam) => {
			const x: number = p.posCurr.x - this.circleDiam / 2;
			const y: number = p.posCurr.y - this.circleDiam / 2;

			this.ctx.drawImage(p.circle!, x, y, drawingObjectSize, drawingObjectSize);

			const bits: ImageData = Animate.generateContrastImageData(
				this.ctx.getImageData(x, y, drawingObjectSize, drawingObjectSize),
				p.quadrant === this.correctQuadrant ? this.contrast : 0
			);

			this.ctx.putImageData(bits, x, y);
		}, this);

		this.ctx.restore();
	};

	private drawDebugElements = (): void => {
		this.ctx.save();

		this.ctx.lineWidth = DebugLineThickness;

		this.quadrantParams.forEach((p: QuadrantParam) => {
			const b: Animate.Boundary = p.boundary!;

			// Draws warning border.
			this.ctx.strokeStyle = 'orange';
			this.ctx.strokeRect(
				b.left + this.warningTrackBuffer,
				b.top + this.warningTrackBuffer,
				b.right - b.left - 2 * this.warningTrackBuffer,
				b.bottom - b.top - 2 * this.warningTrackBuffer
			);

			// Draws absolute border.
			this.ctx.strokeStyle = 'red';
			this.ctx.strokeRect(b.left, b.top, b.right - b.left, b.bottom - b.top);

			// Draws current travel vector.
			this.ctx.strokeStyle = 'green';
			const currentVector: Vector = p.vCurr.unit().multiply(DebugLineLength);

			this.ctx.beginPath();
			this.ctx.moveTo(p.posCurr.x, p.posCurr.y);
			this.ctx.lineTo(p.posCurr.x + currentVector.x, p.posCurr.y + currentVector.y);
			this.ctx.stroke();

			// Draws next travel vector.
			if (p.vNext) {
				this.ctx.strokeStyle = 'blue';
				const nextVector: Vector = p.vNext.unit().multiply(DebugLineLength * 0.75);

				this.ctx.beginPath();
				this.ctx.moveTo(p.posCurr.x, p.posCurr.y);
				this.ctx.lineTo(p.posCurr.x + nextVector.x, p.posCurr.y + nextVector.y);
				this.ctx.stroke();
			}
		}, this);
		this.ctx.restore();
	};

	// Generates next correct quadrant randomly; never consecutive.
	private getNewCorrectQuadrant = (previous?: number): number => {
		let next: number;

		do next = Math.round(Math.random() * 3);
		while (typeof previous !== 'undefined' && previous === next);

		return next;
	};
}
