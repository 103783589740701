import * as React from 'react';

import * as Edge from '../../../../core';
import SingleScore from '../../../../components/singleScore';

export interface TeamExerciseRowProps {
	title: string;
	exercise: Edge.Models.TeamExercise;
	showResponseTime?: boolean;
	showConvergenceStationScore?: boolean;
	showDivergenceStationScore?: boolean;
}

export const TeamExerciseRow: React.FunctionComponent<TeamExerciseRowProps> = (props) => {
	const {
		lastScore,
		totalSessions,
		responseTimeMilliseconds,
		convergenceStationScore,
		divergenceStationScore,
	} = props.exercise;
	return (
		<div className="exercise_row">
			<div className="sessions_name">{props.title}</div>
			<div className="sessions_total">
				{totalSessions === 0 ? (
					<div>&mdash;</div>
				) : (
					<SingleScore value={totalSessions} precision={0} size="small" />
				)}
			</div>
			<div className="sessions_last_score">
				{lastScore === null || lastScore === undefined ? (
					<div>&mdash;</div>
				) : (
					<SingleScore value={lastScore * 100} size="small" />
				)}
			</div>
			{props.showConvergenceStationScore ? (
				convergenceStationScore === null || convergenceStationScore === undefined ? (
					<div className="sessions_avg_time">&mdash;</div>
				) : (
					<div className="sessions_avg_time">{convergenceStationScore.toFixed(0)}</div>
				)
			) : (
				<React.Fragment />
			)}
			{props.showDivergenceStationScore ? (
				divergenceStationScore === null || divergenceStationScore === undefined ? (
					<div className="sessions_avg_time">&mdash;</div>
				) : (
					<div className="sessions_avg_time">{divergenceStationScore.toFixed(0)}</div>
				)
			) : (
				<React.Fragment />
			)}
			{props.showResponseTime ? (
				responseTimeMilliseconds === null || responseTimeMilliseconds === undefined ? (
					<div className="sessions_avg_time">&mdash;</div>
				) : (
					<div className="sessions_avg_time">{(responseTimeMilliseconds / 1000).toFixed(2)} s</div>
				)
			) : (
				<React.Fragment />
			)}
		</div>
	);
};
