import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Edge from '../../core';
import Input from '../../components/global/input';
import Error from '../../components/global/error';
import TermsFormFields from '../../components/createAccountForm/termsFormFields';
import Loading from '../../components/loading';

export interface TermsFormProps {
	initialValues?: Partial<TermsFormValues>;
	onSubmit: (values: TermsFormValues) => Promise<void>;
}

export interface TermsFormValues extends Edge.Models.ProfileRegistrationFlags {}

const termsSchema = Yup.object().shape({
	wasAtLeast13YearsOldAtRegistration: Yup.boolean().oneOf([true], 'You must be at least 13 years old.'),
	agreedToCookiesAtRegistration: Yup.boolean().oneOf([true], 'Your consent for us to use cookies is required.'),
	agreedToLicenseAgreementAtRegistration: Yup.boolean().oneOf(
		[true],
		'Compliance with our license agreement is required.'
	),
});

export default class TermsForm extends React.Component<TermsFormProps> {
	public render() {
		const { initialValues, onSubmit } = this.props;
		return (
			<>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							agreedToCookiesAtRegistration: false,
							agreedToLicenseAgreementAtRegistration: false,
							wasAtLeast13YearsOldAtRegistration: false,
						},
						initialValues || {}
					)}
					validationSchema={termsSchema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await onSubmit(values);
						} catch (e) {
							const message = Edge.API.getErrorMessage(e);
							actions.setStatus(message);
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<TermsFormValues>) => (
						<Form>
							<TermsFormFields />
							{props.status && <Error>{props.status}</Error>}
							{props.isSubmitting && <Loading buttonLoader />}
							<Input type="submit" disabled={props.isSubmitting} value="submit" />
						</Form>
					)}
				/>
			</>
		);
	}
}
