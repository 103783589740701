import * as React from 'react';
import { connect } from 'react-redux';

import * as Edge from '../../core';
import { AppState } from '../../store';
import { setCurrentOrganization } from '../../store/organization/actions';
import Table from '../../components/table';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { UserService } from '../../services/userService';
import PageTitle from '../../components/pageTitle';
import AppPageContainer from '../../components/pageContainer';

import './index.scss';
import { Avatar } from '../../components/avatar';
import { Redirect } from 'react-router-dom';
import ErrorDisplay from '../../components/error/errorDisplay';
import Loading from '../../components/loading';

class UserRoleSearchTable extends Table<Edge.Models.UserRoleResponse> {}

export interface UserRoleSearchProps {
	userRoles?: Edge.Models.UserRoleResponse[];
	organizations?: Edge.Models.Organization[];
	isLoading?: boolean;
	loadingError?: Edge.Models.EdgeError;

	setCurrentOrganization: (organization: Edge.Models.Organization) => void;
}

interface UserRoleSearchState {
	redirectToTeam?: boolean;
	teamId?: string;
}

const availableSearches: ((item: Edge.Models.UserRoleResponse) => string | undefined)[] = [
	(item) => item.firstName,
	(item) => item.lastName,
	(item) => item.role,
	(item) => item.teamName,
	(item) => item.organizationName,
];

const availableSorts: { [x: string]: (item: Edge.Models.UserRoleResponse) => any } = {
	firstName: (item) => item.firstName.toLowerCase(),
	lastName: (item) => item.lastName.toLowerCase(),
	role: (item) => item.role.toLowerCase(),
	teamName: (item) => item.teamName && item.teamName.toLowerCase(),
	organizationName: (item) => item.organizationName.toLowerCase(),
};

export class UserRoleSearch extends React.PureComponent<UserRoleSearchProps, UserRoleSearchState> {
	constructor(props: UserRoleSearchProps) {
		super(props);
		this.state = {};
	}

	public render() {
		if (this.props.loadingError) {
			return <ErrorDisplay />;
		}

		const { userRoles } = this.props;
		const { redirectToTeam, teamId } = this.state;

		if (!this.props.organizations) {
			return <Loading />;
		}

		if (redirectToTeam) {
			return <Redirect to={`/manage-teams/${teamId}`} />;
		}

		return (
			<>
				<PageTitle title="User Role Search" />
				<AppPageContainer>
					{!this.props.isLoading && (
						<UserRoleSearchTable
							className="user_role_search_table"
							items={userRoles!}
							availableSearches={availableSearches}
							availableSorts={availableSorts}
							searchPlaceholderText="Search User Roles"
							initialSort={['role']}
							renderHeader={() => <>&nbsp;</>}
							renderTableHeader={(classNames, toggleSort) => {
								return (
									<tr>
										<th
											className={classNames('firstName')}
											onClick={toggleSort.bind(this, 'firstName')}
										>
											First
										</th>
										<th
											className={classNames('lastName')}
											onClick={toggleSort.bind(this, 'lastName')}
										>
											Last
										</th>
										<th className={classNames('role')} onClick={toggleSort.bind(this, 'role')}>
											Role
										</th>
										<th
											className={classNames('teamName')}
											onClick={toggleSort.bind(this, 'teamName')}
										>
											Team
										</th>
										<th
											className={classNames('organizationName')}
											onClick={toggleSort.bind(this, 'organizationName')}
										>
											Organization
										</th>
									</tr>
								);
							}}
							renderTableItem={(i) => {
								return (
									<tr onClick={this.redirectToTeam.bind(this, i.teamId, i.organizationId)}>
										<td>
											<Avatar src={i.avatarUrl} />
											<span>{i.firstName}</span>
										</td>
										<td>{i.lastName}</td>
										<td>{i.role}</td>
										<td>{i.teamName ? i.teamName : ''}</td>
										<td>{i.organizationName}</td>
									</tr>
								);
							}}
							renderNoItems={() => (
								<tr>
									<td colSpan={5}>No user roles to show</td>
								</tr>
							)}
							pagingMode="seeMore"
							seeMoreText="See more roles"
						/>
					)}
				</AppPageContainer>
			</>
		);
	}

	private redirectToTeam = (teamId: string | undefined, organizationId: string) => {
		const currentOrganization = this.props.organizations!.filter((i) => i.id === organizationId)[0];
		this.props.setCurrentOrganization(currentOrganization);

		this.setState({ redirectToTeam: true, teamId });
	};
}

function mapStateToProps({ organizationState }: AppState) {
	return {
		organizations: organizationState.organizations,
		isLoading: organizationState.isLoading,
		loadingError: organizationState.loadingError,
	};
}

export default connect(
	mapStateToProps,
	{
		setCurrentOrganization,
	}
)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(UserRoleSearch, undefined, undefined, undefined, {
			loadingOptions: { blockItem: true },
		}),
		(props) => {},
		async () => {
			const userRoles = await UserService.getAllUserRoles();
			return { userRoles };
		}
	)
);
