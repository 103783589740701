import * as React from 'react';
import { AppState } from '../../store';
import { getIsNormalUser } from '../../store/permissions/selector';
import { connect } from 'react-redux';
import Loading from '../../components/loading';
import { Redirect } from 'react-router';

export interface HomeProps {
	isLoading: boolean;
	isNormalUser: boolean;
}

interface HomeState {}

export class Home extends React.PureComponent<HomeProps, HomeState> {
	constructor(props: HomeProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { isLoading, isNormalUser } = this.props;
		if (isLoading) {
			return <Loading />;
		}

		if (isNormalUser) {
			return <Redirect to="/dashboard" />;
		}

		return <Redirect to="/training/evaluation" />;
	}
}

function mapStateToProps(state: AppState) {
	return getIsNormalUser(state);
}

export default connect(mapStateToProps)(Home);
