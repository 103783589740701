import * as React from 'react';
import * as Edge from '../../../core';
import ExerciseResultLayout from '../exerciseResultLayout';
import { ExerciseMetadataService } from '../../../services/exerciseMetadataService';

export interface ContrastSensitivityResultsProps {
	results: Edge.Models.ContrastSensitivityExerciseResult;
	completeText: string;
	retry: () => void;
	complete: () => void;
	hideExerciseResults: boolean;
}

export class ContrastSensitivityResults extends React.PureComponent<ContrastSensitivityResultsProps> {
	constructor(props: ContrastSensitivityResultsProps) {
		super(props);
		this.state = {};
	}

	public render() {
		const { complete, completeText, results, retry, hideExerciseResults } = this.props;
		const exercise = ExerciseMetadataService.getMetadata(results);

		return (
			<ExerciseResultLayout
				className="contrast_sensitivity_results"
				exerciseName="Contrast Sensitivity"
				description={exercise && exercise.description}
				headerActions={
					<>
						<button className="cta_btn" onClick={retry}>
							Retry Exercise
						</button>
						<button className="cta_btn" onClick={complete}>
							{completeText}
						</button>
					</>
				}
				results={[results]}
				hideExerciseResults={hideExerciseResults}
			/>
		);
	}
}

export default ContrastSensitivityResults;
