/* eslint-disable */
$( function() {

	VizEdge.DepthExercise = function() {
	    this.initialize.apply(this, arguments);
	};

	_.extend( VizEdge.DepthExercise.prototype, {

    scoreMessageTemplate: _.template( "Your score is <%= scorePercent.toFixed(2) %>%. Your average response time was <%= (responseTime/1000).toFixed(2) %> seconds." ),

    // log messages
    ringSetLogMessage: _.template("drawing rings for round <%=round%> in <%=mode%> mode, level <%=level%>"),
    ringLogMessage: _.template( "drawing ring at (<%=x%>,<%=y%>) depth: <%=depth%>, correct: <%=correct%>" ),

		initialize: function( mode, debugMode, renderScale, touchControls, sounds, configuration, completeExercise ) {

      var canvas = document.createElement('canvas');
      canvas.id = "stage";
      canvas.width = $(window).width() - 40;
      canvas.height = $(window).height() - 200;
      canvas.style.display = "inline";

      $(".stage-wrapper").append(canvas);
			paper.setup(canvas);
      this.canvasEl = canvas;

      _.bindAll( this, "onOK", "onKeyDown", "onExitFullScreen" );

      // install keyboard handler
      this.acceptingInput = false;
      this.tool = new paper.Tool();
      this.keyboard = new VizEdge.KeyboardControl({ keydown: this.onKeyDown });

      // constants
      this.ringSizes = { small: 20 , medium: 30, large: 40 };
      this.groupDepthMax = [11,8,5,2];
      this.evaluationDepth = [10,10,5,5,3,3,2,2];
      this.roundTimeout = 10000;
      this.roundPause = 250;
      this.blue = "#0000b3";
      this.red = "#b30000";

      // scale rendering constants
      this.renderScale = renderScale;
      this.ringSizes = _.mapObject( this.ringSizes, function( size ) { return size * renderScale; } );
      this.groupDepthMax = _.map( this.groupDepthMax, function( depth ) { return depth * renderScale; } );
      this.evaluationDepth = _.map( this.evaluationDepth, function( depth ) { return depth * renderScale; } );

      // settings
      this.mode = mode;
      this.level = 1;
      this.sessionLength = 2 * 60 * 1000; // 2 mins
      this.ringSize = 'large';
      this.ringRadius = this.ringSizes[this.ringSize];
      this.debugMode = (debugMode != 'true' ) ? false : true;
      this.fullScreen = false;
      this.touchControls = touchControls;
      this.completeExercise = completeExercise;

      if (configuration) {
        this.level = parseInt(configuration.level);

        this.ringSize = configuration.circleSize;
        this.ringRadius = this.ringSizes[this.ringSize];

        var sessionDuration = parseInt(configuration.sessionDuration);
        this.sessionLength = sessionDuration * 1000; // seconds to milliseconds

        this.setFullScreen(configuration.fullScreen);
      }

      // load sound effects
      this.buzzerSound = sounds.buzzer;
      this.crystalSound = sounds.crystal;
    
      // load arrow image
      this.selectionArrowImage = new paper.Raster('arrow-up');
      this.selectionArrowImage.visible = false;
      this.selectionArrowImage.scaling = new paper.Point(this.renderScale,this.renderScale);

      // no sounds in debug mode
      if( this.debugMode ) {
        Howler.mute();
      }

		},

    onKeyDown: function( event ) {

      switch(event.key) {
        case 'left':
          this.moveArrow('left');
          return false;

        case 'right':
          this.moveArrow('right');
          return false;

        case 'enter':
          this.selectRing();
          return false;

        default:
          return true;

      }

    },

    drawRing: function( ring ) {

      this.ringLayer.activate();

      var blueCircle = new paper.Shape.Circle(new paper.Point(ring.x+ring.depth, ring.y), ring.r);
      blueCircle.strokeColor = this.blue;
      blueCircle.strokeWidth = ring.w;

      var redCircle = new paper.Shape.Circle(new paper.Point(ring.x, ring.y), ring.r);
      redCircle.strokeColor = this.red;
      redCircle.strokeWidth = ring.w;
      redCircle.blendMode = 'difference';

      var redBlueRing = new paper.Group();
      redBlueRing.addChild(blueCircle);
      redBlueRing.addChild(redCircle);

      // log drawing this ring
      if( this.debugMode ) {
        console.log(this.ringLogMessage(ring));
      }

      paper.view.draw();
    },

    startSessionTimer: function() {

      // set up a timer for the entire session
      this.sessionTimer = window.setTimeout( _.bind( function() {
        this.endRound();
        this.endSession();
      }, this), this.sessionLength );

      // render timer
      this.timerLayer.activate();

      var viewBounds = paper.project.view.bounds;
      var maxTimerBars = 60;

      var barSpacing = 5;
      var barWidth = 8;
      var barHeight = 20;
      var oneBarWidth = barWidth + barSpacing;
      var totalBarWidth = oneBarWidth * maxTimerBars;
      var xStartOffset = (viewBounds.width - totalBarWidth) / 2;
      var yOffset = viewBounds.height - barHeight - 10;

      for( var i=0; i < maxTimerBars; i++ ) {
        var xOffset = (oneBarWidth * i) + xStartOffset;
        var rectangle = new paper.Rectangle(new paper.Point(xOffset, yOffset), new paper.Size(barWidth, barHeight));
        var shape = new paper.Shape.Rectangle(rectangle);
        if( i == 5 ) {
          // when we reach this bar, time to turn red
          shape.redMarker = true;
        }
        shape.fillColor = 'green';
      }

      var barInterval = this.sessionLength / maxTimerBars;

      // set up a timer to remove the next bar
      var nextBarDisappears = function nextBarDisappears() {
        if( !this.timerLayer.isEmpty() ) {

          var bar = this.timerLayer.lastChild;

          // if we reach the marked bar, turn red
          if( bar.redMarker ) {
            _.each( this.timerLayer.children, function( bar ) {
              bar.fillColor = 'red';
            }, this );
          }

          bar.remove();
          paper.view.draw();

          // set up next tick
          window.setTimeout( _.bind( nextBarDisappears, this), barInterval );
        }
      };

      // start ticking
      window.setTimeout( _.bind( nextBarDisappears, this), barInterval );
    },

    moveArrow: function( direction ) {
      // Prevents method from executing if exercise is not ready to accept input
      //  regardless of input source.
      if( !this.acceptingInput ) return;

      var lastPosition = this.rings.length-1;

      if( direction == 'left' ) {
        if( this.selectedRing > 0 ) {
          this.selectedRing = this.selectedRing - 1;
        } else {
          this.selectedRing = lastPosition;
        }
      } else {
        if( this.selectedRing < lastPosition ) {
          this.selectedRing = this.selectedRing + 1;
        } else {
          this.selectedRing = 0;
        }
      }

      this.eraseArrow();
      this.drawArrow(this.rings[this.selectedRing]);

    },

    selectRing: function() {
      // Prevents method from executing if exercise is not ready to accept input
      //  regardless of input source.
      if( !this.acceptingInput ) return;

      var ring = this.rings[this.selectedRing];

      if( ring.correct ) {
        this.points = this.points + 1;
        this.crystalSound.play();
      } else {
        this.buzzerSound.play();
      }

      this.endRound();
      this.startRound();
    },

    drawArrow: function( ring ) {

      var arrowPadding = 10 * this.renderScale;
      var arrowX = ring.x;
      var arrowY = ring.y + ring.r + this.selectionArrowImage.height/2 + arrowPadding;

      this.selectionArrowImage.position = new paper.Point(arrowX,arrowY);
      this.selectionArrowImage.visible = true;
    },

    eraseArrow: function() {
      this.selectionArrowImage.visible = false;
    },

    startSession: function() {

      // exercise score
      this.points = 0;
      this.roundCount = 0;
      this.totalResponseTime = 0;

      // setup rendering layers
      this.ringLayer = new paper.Layer();
      this.timerLayer = new paper.Layer();

      // begin session
      new VizEdge.ReadyGo( _.bind( function() {
        this.initTouchControls();
        if (this.mode == 'train') {
          this.startSessionTimer();
        }
        this.startRound();
      }, this) );

    },

    initTouchControls: function() {
      if( this.touchControls ) {

        // bind touch handlers to exercise events
        var handlers = {  left: _.bind( this.moveArrow, this, 'left' ),
                          right: _.bind( this.moveArrow, this, 'right' ),
                          ok: _.bind( this.selectRing, this )
                       };

        this.dPad = new VizEdge.DPadControl( { mode: 'left-right', handlers: handlers, tool: this.tool } );
      }
    },

    onOK: function() {

      // close dialog box
      $('#depth-options-dialog').modal('hide');

      // parse settings from dialog box
      this.level = parseInt($("#exercise-level").val());

      this.ringSize = $("#circle-size").val();
      this.ringRadius = this.ringSizes[this.ringSize];

      var sessionDuration = parseInt($("#session-duration").val());
      this.sessionLength = sessionDuration * 60 * 1000; // mins to milliseconds

       new VizEdge.ReadyGo( _.bind( function() {
         this.initTouchControls();
         this.startSessionTimer();
         this.startRound();
       }, this) );

    },

    setFullScreen: function(value) {

      if( !BigScreen.enabled ) return;

      this.fullScreen = value;

      if( this.fullScreen ) {
        if (!BigScreen.element) {
          BigScreen.toggle( null, null, this.onExitFullScreen );
        }
      } else {
        if (BigScreen.element) {
          BigScreen.exit();
        }
        this.onExitFullScreen();
      }

    },

    onExitFullScreen: function() {
    },

    endSession: function() {

      if( !this.isRoundOver() ) {
        this.endRound();
      }

      if( this.sessionTimer ) {
        window.clearTimeout( this.sessionTimer );
        this.sessionTimer = null;
      }

      // clean up
      paper.project.clear();
      paper.view.draw();
      this.tool.remove();
      this.keyboard.remove();

      this.setFullScreen(false);

      this.sendScore();
    },

    sendScore: function() {
      this.completeExercise({
        exercise: 'depth',
        evaluation: this.mode == "evaluate",
        size: this.ringSize,
        duration: Math.round(this.sessionLength/1000),
        audio: true,
        scorePercent: this.calcScorePercent(),
        responseTime: this.calcAverageResponseTime(),
      })
    },

    calcScorePercent: function() {
			var per = 0.0;
      if( this.roundCount > 0 ) {
        per = (this.points / this.roundCount) * 100.0;
      }

			//Do not let percentage exceede 100%
			if(per > 100.0){
				per = 100.0;
			}

			return per;
    },

    calcAverageResponseTime: function() {
      if( this.roundCount > 0 ) {
        return this.totalResponseTime / this.roundCount;
      } else {
        return 0.0;
      }
    },

    generateRings: function() {

      this.rings = [];

      var numRings = 5;
      var correctRing = _.random(numRings-1);
      var groupDepth, correctDepth;

      if( this.mode == "train" ) {
        groupDepth = _.random( this.groupDepthMax[this.level-1] );
        var depthSign = ( _.random(1) == 1 ) ? 1 : -1;
        var correctDepthDelta = (_.random(1,3) * depthSign) * (5-this.level);
        correctDepth = groupDepth + correctDepthDelta;
      } else {
        groupDepth = 0;
        correctDepth = this.evaluationDepth[this.roundCount];
      }

      var viewBounds = paper.project.view.bounds;
      var ringSpacing = 40 * this.renderScale;
      var ringWidth = 6 * this.renderScale;     // width of stroke
      var oneRingWidth = (this.ringRadius * 2) + ringSpacing;
      var ringSetWidth = oneRingWidth * numRings;
      var startXOffset = (viewBounds.width - (ringSetWidth-ringSpacing*3)) / 2;
      var yOffset = (viewBounds.height - oneRingWidth) / 2;

      for( var i=0; i < numRings; i++ ) {

        var xOffset = (i * oneRingWidth) + startXOffset;
        var ring = { x: xOffset, y: yOffset, r: this.ringRadius, w: ringWidth };

        if( i == correctRing ) {
          ring.correct = true;
          ring.depth = correctDepth;
        } else {
          ring.correct = false;
          ring.depth = groupDepth;
        }

        this.rings[i] = ring;
      }

    },

    startRound: function() {

      if( this.isSessionOver() ) return;

      var _startRound = _.bind( function _startRound() {

        this.roundPauseTimer = null;

        this.generateRings();
        this.selectedRing = 0;

        this.render( _.bind( function() {

          // session may have ended while rings were displaying
          if( this.isSessionOver() ) return;

          this.roundTimer = window.setTimeout( _.bind( function() {
            this.buzzerSound.play();
            this.endRound();
            this.startRound();
          }, this), this.roundTimeout );

          this.acceptingInput = true;
          this.roundStartTime = this.getTime();
          this.roundCount = this.roundCount + 1;

        }, this));

      }, this);

      // if this isn't the first round, pause between the rounds and
      // accumulate total response time, otherwise do Ready! Go!
      if( this.roundCount > 0 ) {
        this.roundPauseTimer = window.setTimeout( _startRound, this.roundPause );
      } else {
        _startRound();
      }

    },

    getTime: function() {
      var d = new Date();
      return d.getTime();
    },

    isSessionOver: function() {
      if( this.mode == "evaluate" ) {
        return (this.roundCount == this.evaluationDepth.length);
      } else {
        return (this.sessionTimer == null);
      }
    },

    isRoundOver: function() {
      return (this.roundTimer == null);
    },

    endRound: function() {

      // we must be in a round to accept input
      this.acceptingInput = false;

      // accumulate total response time
      var responseTime  = this.getTime() - this.roundStartTime;
      this.totalResponseTime = responseTime + this.totalResponseTime;

      if( this.roundPauseTimer ) {
        window.clearTimeout( this.roundPauseTimer );
        this.roundPauseTimer = null;
      }

      if( this.roundTimer ) {
        window.clearTimeout( this.roundTimer );
        this.roundTimer = null;
      }

      this.eraseArrow();

      if( this.mode == "evaluate" && this.roundCount == this.evaluationDepth.length ) {
        this.endSession();
      }
    },

		render: function( afterRender ) {

      var n = 0, delayAmount = 110;
      var lastRing = _.last(this.rings);

      if( this.debugMode ) {
        console.log( this.ringSetLogMessage({ round: this.roundCount, mode: this.mode, level: this.level }));
      }

      // remove any existing rings
      this.ringLayer.removeChildren();

      _.each( this.rings, function( ring ) {
        window.setTimeout( _.bind( function() {
          this.drawRing(ring);
          if( ring == lastRing ) {
            // afterRender is called after render is complete
            afterRender();
          }
        }, this), delayAmount * n );
        n = n + 1;
      }, this);

      this.drawArrow(this.rings[this.selectedRing]);
		}

	});

});
