// Must be above >= 0.
export const StartingContrast: number = 64;

// Determines bottom, left, right top window dimension percentage of quadrant boundaries.
// Assumes canvas topLeft as X0,Y0.
export const bottomPct = (quadrant: number): number => {
	switch (quadrant) {
		case 0:
			return 0.5;
		case 1:
		case 2:
		case 3:
		default:
			return 1;
	}
};

export const leftPct = (quadrant: number): number => {
	switch (quadrant) {
		case 0:
		case 2:
			return 0.25;
		case 1:
			return 0.75;
		case 3:
		default:
			return 0;
	}
};

export const rightPct = (quadrant: number): number => {
	switch (quadrant) {
		case 0:
		case 2:
			return 0.75;
		case 3:
			return 0.25;
		case 1:
		default:
			return 1;
	}
};

export const topPct = (quadrant: number): number => {
	switch (quadrant) {
		case 2:
			return 0.5;
		case 0:
		case 1:
		case 3:
		default:
			return 0;
	}
};

// Converts quadrant number to compare against trainee input.
// @param quarant is 0 <= quadrant < 4
export const getQuadrantDirection = (quadrant?: number): string => {
	switch (quadrant) {
		case 0:
			return 'up';
		case 1:
			return 'right';
		case 2:
			return 'down';
		case 3:
			return 'left';
		default:
			return 'none';
	}
};
