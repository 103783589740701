import classNames from 'classnames';
import * as React from 'react';

import './index.scss';
// eslint-disable-next-line import/no-webpack-loader-syntax
import logo from '!url-loader!../../assets/images/logo.svg';

export interface AvatarProps {
	src?: string;
	size?: 'small' | 'large';
}

export const Avatar: React.FunctionComponent<AvatarProps> = (props) => {
	return (
		<div className={classNames('avatar_wrapper', props.size || 'small')}>
			<img src={props.src || logo} alt="logo" />
		</div>
	);
};
