/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-script-url */
import * as React from 'react';
import { BaseExercise, BaseExerciseProps } from '../baseExercise';
import * as Edge from '../../../core';
import ExerciseLayout from '../../../layouts/exerciseLayout';

declare var VizEdge: any;
declare var $: any;

interface DepthProps
	extends BaseExerciseProps<Edge.Models.DepthExerciseResult, Edge.Models.DepthExerciseConfiguration> {}

interface DepthConfiguration {
	fullScreen: boolean;
	level: string; // integer level
	circleSize: 'small' | 'medium' | 'large';
	sessionDuration: number; // seconds
}

interface DepthResult {
	exercise: 'depth';
	evaluation: boolean;
	size: number;
	duration: number; // seconds
	audio: boolean;
	scorePercent: number; // out of 100
	responseTime: number; // ms
}

export class Depth extends BaseExercise<
	DepthProps,
	Edge.Models.DepthExerciseResult,
	Edge.Models.DepthExerciseConfiguration,
	{}
> {
	createSession() {
		const { configuration } = this.props;
		return this.doCreateSession(
			this.props.mode,
			this.props.debugMode ? 'true' : 'false',
			this.props.renderScale,
			this.props.touchControls,
			{
				fullScreen: this.props.fullScreen,
				level: configuration.level.toString(),
				circleSize: ['', 'small', 'medium', 'large'][configuration.size] as any,
				sessionDuration: configuration.durationSeconds,
			},
			this.completeExercise
		);
	}

	/**
	 * Typed wrapper over `new VizEdge.DepthExercise`.
	 */
	private doCreateSession = (
		mode: 'train' | 'evaluate',
		debugMode: 'true' | 'false',
		renderScale: number,
		touchControls: boolean,
		configuration: DepthConfiguration,
		completeExercise: (result: DepthResult) => void
	) => {
		return new VizEdge.DepthExercise(
			mode,
			debugMode,
			renderScale,
			touchControls,
			{ buzzer: this.buzzerSound, crystal: this.crystalSound },
			configuration,
			completeExercise
		);
	};

	private completeExercise = (result: DepthResult) => {
		const { configuration, completeExercise } = this.props;
		completeExercise({
			exerciseTypeId: Edge.Models.ExerciseTypeId.Depth,
			audio: result.audio,
			exerciseConfigurationId: configuration.id,
			level: configuration.level,
			correctPercent: result.scorePercent / 100, // JS is out of 100, server is out of 1
			size: result.size,
			durationSeconds: result.duration,
			responseTimeMilliseconds: result.responseTime,
		});
	};

	public render(): JSX.Element {
		return (
			<ExerciseLayout
				endExercise={this.endSession}
				completeExercise={this.completeSession}
				exerciseName="Depth Perception"
			>
				<div id="images">
					<img id="arrow-up" src="/images/arrow-up-q.gif" alt="Arrow up q" />
					<img id="dpad-arrow" src="/images/dpad-arrow.png" alt="Dpad arrow" />
				</div>
			</ExerciseLayout>
		);
	}
}
