import * as React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

import { RegisterViaInviteProps } from './props';
import { UserService } from '../../services/userService';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { preloadData } from '../../store/login/actions';

export interface CompleteProps extends RegisterViaInviteProps {
	preloadData: () => Promise<void>;
}

export const Complete: React.FunctionComponent<CompleteProps> = () => {
	return <Redirect to="/" />;
};

function mapStateToProps() {
	return {};
}
export default connect(
	mapStateToProps,
	{
		preloadData,
	}
)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(Complete),
		(props) => {
			const { token, positionId } = props.match.params;
			return { token, positionId };
		},
		async ({ token, positionId }: { token: string; positionId?: string }, props) => {
			// the 'data' we need to load is the fulfillment of the token.  Once that's done, we can redirect to the homepage
			await UserService.fulfillToken(token, positionId);
			await props.preloadData();
			return {};
		}
	)
);
