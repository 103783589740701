import * as React from 'react';
import classNames from 'classnames';
import * as _ from 'lodash';

import './index.scss';

export interface ExpandableAccordionItem {
	isSpacer: false;
	header: React.ReactNode;
	body: React.ReactNode;
}

export interface SpacerAccordionItem {
	isSpacer: true;
	content: React.ReactNode;
}

export type AccordionItem = ExpandableAccordionItem | SpacerAccordionItem;

export interface AccordionGroupProps {
	items: AccordionItem[];
	initialExpanded?: number[];
}

interface AccordionGroupState {
	expanded: number[];
}

export class AccordionGroup extends React.PureComponent<AccordionGroupProps, AccordionGroupState> {
	constructor(props: AccordionGroupProps) {
		super(props);
		this.state = {
			expanded: props.initialExpanded || [0],
		};
	}
	public render() {
		const { items } = this.props;
		let { expanded } = this.state;

		return (
			<div className="accordion_group">
				{items.map((i, ix) =>
					i.isSpacer ? (
						<div className="accordion_spacer">{i.content}</div>
					) : (
						<div
							className={classNames('accordion_item', { expanded: expanded.some((ii) => ii === ix) })}
							key={ix}
						>
							<div className="accordion_item_header">
								<span className="expand_collapse" onClick={this.toggleExpand.bind(this, ix)} />
								<div className="accordion_item_header_content">{i.header}</div>
							</div>
							<div className="accordion_item_body">{i.body}</div>
						</div>
					)
				)}
			</div>
		);
	}
	private toggleExpand = (index: number) => {
		if (this.state.expanded.some((i) => i === index)) {
			this.setState({ expanded: _.difference(this.state.expanded, [index]) });
		} else {
			this.setState({ expanded: [...this.state.expanded, index] });
		}
	};
}
export default AccordionGroup;
