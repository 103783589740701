import * as ReduxThunk from 'redux-thunk';
import * as Edge from '../../core';
import { AppState } from '..';
import { SessionService } from '../../services/sessionService';
import {
	SESSION_GETACTIVE_START,
	SESSION_GETACTIVE_SUCCESS,
	SESSION_GETACTIVE_FAILURE,
	SESSION_CHANGE_START,
	SESSION_CHANGE_SUCCESS,
	SESSION_CHANGE_FAILURE,
} from './types';

// action creators
export const getActiveSessionStart = () => {
	return {
		type: SESSION_GETACTIVE_START,
	};
};

export const getActiveSessionSuccess = (result: Edge.Models.ActiveSessionResponse) => {
	return {
		type: SESSION_GETACTIVE_SUCCESS,
		payload: result,
	};
};

export const getActiveSessionFailure = (error: Edge.Models.EdgeError) => {
	return {
		type: SESSION_GETACTIVE_FAILURE,
		payload: error,
		error: true,
	};
};

export const changeSessionStart = () => {
	return {
		type: SESSION_CHANGE_START,
	};
};

export const changeSessionSuccess = (result: Edge.Models.ActiveSessionResponse) => {
	return {
		type: SESSION_CHANGE_SUCCESS,
		payload: result,
	};
};

export const changeSessionFailure = (error: Edge.Models.EdgeError) => {
	return {
		type: SESSION_CHANGE_FAILURE,
		payload: error,
		error: true,
	};
};

// thunk actions
export const getActiveSession = (): ReduxThunk.ThunkAction<
	Promise<void>,
	AppState,
	null,
	Edge.Models.EdgeAction
> => async (dispatch) => {
	dispatch(getActiveSessionStart());
	try {
		const result = await SessionService.getActiveSession();
		dispatch(getActiveSessionSuccess(result));
	} catch (error) {
		dispatch(getActiveSessionFailure(error));
	}
};

export const startSession = (
	command: Edge.Models.StartSessionCommand
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(changeSessionStart());
	try {
		const result = await SessionService.startSession(command);
		dispatch(changeSessionSuccess(result));
	} catch (error) {
		dispatch(changeSessionFailure(error));
		throw error;
	}
};

export const addPlanPhase = (
	command: Edge.Models.AddPlanPhaseCommand
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(changeSessionStart());
	try {
		const result = await SessionService.addPlanPhase(command);
		dispatch(changeSessionSuccess(result));
	} catch (error) {
		dispatch(changeSessionFailure(error));
		throw error;
	}
};

export const completeExercise = (
	command: Edge.Models.CompleteExerciseCommand
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(changeSessionStart());
	try {
		const result = await SessionService.completeExercise(command);
		dispatch(changeSessionSuccess(result));
	} catch (error) {
		dispatch(changeSessionFailure(error));
		throw error;
	}
};

export const endSession = (
	command: Edge.Models.EndSessionCommand
): ReduxThunk.ThunkAction<Promise<void>, AppState, null, Edge.Models.EdgeAction> => async (dispatch) => {
	dispatch(changeSessionStart());
	try {
		const { responseDateUtc, receivedDateUtc } = await SessionService.endSession(command);
		// we get a session on the response, but it's our now-expired one, which we won't want to show
		dispatch(
			changeSessionSuccess({
				responseDateUtc,
				receivedDateUtc,
				active: undefined,
			})
		);
	} catch (error) {
		dispatch(changeSessionFailure(error));
		throw error;
	}
};

export const clearCachedSession = (): ReduxThunk.ThunkAction<
	Promise<void>,
	AppState,
	null,
	Edge.Models.EdgeAction
> => async (dispatch) => {
	dispatch(
		changeSessionSuccess({
			responseDateUtc: new Date(),
			receivedDateUtc: new Date(),
			active: undefined,
		})
	);
};
