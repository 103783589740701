import * as _ from 'lodash';
import * as Redux from 'redux';
import * as Edge from './../../core';
import {
	PermissionState,
	PermissionActionTypes,
	PERMISSIONS_LOADING,
	PERMISSIONS_SUCCESS,
	PERMISSIONS_FAILURE,
} from './types';
import { LOGIN_CLEAR_PRELOADED_DATA } from '../login/types';

const initialState: PermissionState = {
	isLoading: false,
};

/**
 * Merge two permission assignments together, keeping the highest rights of either.
 * This is designed to be usable as the method passed to Array.reduce()
 * @param p1 A role assignment
 * @param p2 A role assignment
 */
export const mergePermissions = (
	p1: Edge.Models.AssignedRole | undefined,
	p2: Edge.Models.AssignedRole
): Edge.Models.AssignedRole => {
	if (!p1) {
		return p2;
	}
	if (!p2) {
		return p1;
	}

	return {
		entityId: p1.entityId,
		canEvaluate: p1.canEvaluate || p2.canEvaluate,
		canAddProspects: p1.canAddProspects || p2.canAddProspects,
		isNormalUser: p1.isNormalUser || p2.isNormalUser,
		organizationPermissions: Math.max(
			p1.organizationPermissions,
			p2.organizationPermissions
		) as Edge.Models.PermissionLevel,
		teamPermissions: Math.max(p1.teamPermissions, p2.teamPermissions) as Edge.Models.PermissionLevel,
	};
};

const permissionReducer: Redux.Reducer<PermissionState> = (
	state = initialState,
	action: PermissionActionTypes
): PermissionState => {
	switch (action.type) {
		case LOGIN_CLEAR_PRELOADED_DATA:
			return initialState;
		case PERMISSIONS_LOADING:
			return {
				...state,
				isLoading: true,
				loadingError: undefined,
			};
		case PERMISSIONS_SUCCESS:
			const { payload } = action;
			return {
				...state,
				isLoading: false,
				permissions: {
					organizations: _.mapValues(_.groupBy(payload.organizationRoles, (i) => i.entityId), (roles) =>
						roles.reduce(mergePermissions)
					),
					teams: _.mapValues(_.groupBy(payload.teamRoles, (i) => i.entityId), (roles) =>
						roles.reduce(mergePermissions)
					),
				},
				loadingError: undefined,
			};
		case PERMISSIONS_FAILURE:
			return {
				...state,
				isLoading: false,
				permissions: undefined,
				loadingError: action.payload as Edge.Models.EdgeError,
			};
		default:
			return state;
	}
};

export default permissionReducer;
