import * as React from 'react';

import './mainRow.scss';

export const MainRow: React.FunctionComponent = (props) => {
	return (
		<div className="mainRow_container">
			<div className="content_box">{props.children}</div>
		</div>
	);
};

export default MainRow;
