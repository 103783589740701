import * as Animate from './animate';

const AlphaFinal: number = 0.05;
const AlphaDeltaPerFrame: number = 0.02;
const DisplayTexts: string[] = ['Ready', 'Go!'];
const ScalingFactor: number = 1.05;

export default class Starter {
	private animationId?: number;
	private canvasScale: number = 1.0;
	private ctx: CanvasRenderingContext2D;
	private currentStarterStringIndex: number = 0;
	private isRunning: boolean = false;
	private onStarterFinishCallback: () => void;
	private previousFixedUpdateTimestamp?: number;

	/**
	 * Class constructor.
	 * @param ctx Current HTML canvas 2D context.
	 * @param onStarterFinishCallback Method that executes code that runs after Starter animation ends.
	 */
	constructor(ctx: CanvasRenderingContext2D, onStarterFinishCallback: () => void) {
		this.ctx = ctx;
		this.onStarterFinishCallback = onStarterFinishCallback;
	}

	public run = (): void => {
		// Sets text style.
		this.ctx.fillStyle = '#3266cc';
		this.ctx.font = '60px Arial';
		this.ctx.textAlign = 'center';
		this.ctx.textBaseline = 'middle';

		this.setIsRunning(true);
		this.animationId = requestAnimationFrame(this.fixedUpdate);
	};

	public setIsRunning = (isRunning: boolean) => (this.isRunning = isRunning);

	private fixedUpdate = (timestamp: DOMHighResTimeStamp): void => {
		if (!this.isRunning) {
			cancelAnimationFrame(this.animationId!);
			return;
		}

		// Determines elapsed time since last update.
		const elapsedTime = timestamp - (this.previousFixedUpdateTimestamp || timestamp);
		const elapsedFrames = elapsedTime / (1000 / Animate.FpsTarget);

		this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

		// Decrements alpha/opacity.
		this.ctx.globalAlpha -= AlphaDeltaPerFrame * elapsedFrames;

		this.canvasScale *= ScalingFactor;
		Animate.scaleCanvas(this.ctx, this.canvasScale);

		this.ctx.fillText(
			DisplayTexts[this.currentStarterStringIndex],
			this.ctx.canvas.width / 2,
			this.ctx.canvas.height / 2
		);

		// Halts and resets animation loop.
		if (this.ctx.globalAlpha <= AlphaFinal) {
			this.ctx.globalAlpha = 1.0;

			this.canvasScale = 1.0;
			Animate.scaleCanvas(this.ctx, this.canvasScale);

			// Executes callback if animation for the last text is complete.
			if (this.currentStarterStringIndex >= DisplayTexts.length - 1) {
				this.setIsRunning(false);
				this.onStarterFinishCallback();
			}

			this.currentStarterStringIndex++;
		}

		// Continues animation while starter is running.
		this.previousFixedUpdateTimestamp = timestamp;
		this.animationId = requestAnimationFrame(this.fixedUpdate);
	};
}
