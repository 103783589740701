import * as React from 'react';
import { Link } from 'react-router-dom';
import { getUserPermissionService } from '../../../store/permissions/selector';
import { AppState } from '../../../store';
import * as Edge from '../../../core';
import { UserPermissionService } from '../../../services/userPermissionService';
import { connect } from 'react-redux';

export interface AddSessionsLinkProps {
	className?: string;

	currentOrganization?: Edge.Models.Organization;
	permissions: UserPermissionService;
}

const AddSessionsLink: React.FunctionComponent<AddSessionsLinkProps> = (props) => {
	const { className, currentOrganization, permissions } = props;

	const linkToOrg =
		currentOrganization &&
		permissions.getOrganizationAccess(currentOrganization) >= Edge.Models.PermissionLevels.Edit;
	const linkTo = linkToOrg ? '/manage-organization/manage-plan' : '/my-account/manage-plan';

	return (
		<Link to={linkTo} {...className && { className }}>
			Upgrade / Add Sessions
		</Link>
	);
};

function mapStateToProps(state: AppState) {
	const permissions = getUserPermissionService(state);
	return {
		permissions,
		currentOrganization: state.organizationState.currentOrganization,
	};
}

export default connect(mapStateToProps)(AddSessionsLink);
