import * as React from 'react';
import enhanceWithClickOutside from 'react-click-outside';

import './index.scss';

interface AdminMenuProps {}

interface AdminMenuState {
	menuOpen: boolean;
}

// #1434: https://dev.azure.com/vizualedge/Edge%20Trainer/_backlogs/backlog/Edge%20Trainer%20Team/Stories/?workitem=1434
// TODO: Refactor so that either:
//  1. Menu doesn't close when user clicks on class admin_menu_item
//  2. admin_menu_item's child spans the entire parent div so that person actually clicks on {item} and not parent
export class AdminMenu extends React.Component<AdminMenuProps, AdminMenuState> {
	constructor(props: AdminMenuProps) {
		super(props);
		this.state = {
			menuOpen: false,
		};
	}

	handleClickOutside = () => {
		this.setState({ menuOpen: false });
	};

	public render() {
		return (
			<div className="admin_menu">
				<button
					className="admin_menu_button"
					onClick={() => {
						this.setState({ menuOpen: !this.state.menuOpen });
					}}
				>
					...
				</button>
				{this.state.menuOpen && (
					<div className="admin_menu_dropdown">
						{this.props.children &&
							React.Children.map(this.props.children, (item, ix) => {
								return (
									<div
										key={ix}
										onClick={() => {
											this.setState({ menuOpen: false });
										}}
										className="admin_menu_item"
									>
										{item}
									</div>
								);
							})}
					</div>
				)}
			</div>
		);
	}
}

export default enhanceWithClickOutside(AdminMenu);
