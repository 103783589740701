import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as moment from 'moment';

import * as Edge from '../../../core';
import FormField from '../../../components/formField';
import Input from '../../../components/global/input';
import Error from '../../../components/global/error';
import ConfigLayout from '../configLayout';
import { FullScreenService } from '../../../services/fullScreenService';

export interface ContrastSensitivityConfigFormProps {
	mode: Edge.Models.ExerciseMode;
	initialConfiguration: Partial<Edge.Models.ContrastSensitivityExerciseConfiguration>;
	lockConfiguration: boolean;
	onSubmit: (
		configuration: Edge.Models.ContrastSensitivityExerciseConfiguration,
		fullScreen: boolean
	) => Promise<void>;

	operationName: string;
}

export interface ContrastSensitivityConfigFormValues {
	durationSeconds: string;
	fullScreen: boolean;
	level: string;
	size: string;
}

const schema = Yup.object().shape({
	durationSeconds: Yup.number(),
	fullScreen: Yup.boolean(),
	level: Yup.number().required('Level is required'),
	size: Yup.string().required('Circle Size is required'),
});

interface InstructionProps {
	config: ContrastSensitivityConfigFormValues;
	startText: string;
}

const instructions = ({ config, startText }: InstructionProps) => (
	<ul>
		<li>Red/Blue glasses are NOT worn during this exercise.</li>
		<li>After clicking the '{startText}' button, four circles will appear near the middle of the screen.</li>
		<li>
			Identify which circle is different by pressing the arrow key based on where it is located (up, down, left,
			or right), as fast as possible. An on-screen D-Pad will appear if touch controls are enabled.
		</li>
		<li>
			The contrast and difficulty will increase as you progress through each station. In order to move onto the
			next station you will need to get two out of three correct.
		</li>
		<li>
			If you get two out of three incorrect the station will revert by one, decreasing contrast and difficulty.
		</li>
		<li>This exercise is scored based on speed, accuracy and maximum station achieved.</li>
		<li>
			This exercise will last {moment.duration(parseInt(config.durationSeconds), 'seconds').humanize()} and you
			have <strong>5 seconds</strong> to answer each round.
		</li>
	</ul>
);

export default class ContrastSensitivityConfigForm extends React.Component<ContrastSensitivityConfigFormProps> {
	public render() {
		const { initialConfiguration, lockConfiguration, onSubmit, operationName, mode } = this.props;
		const startText = mode === 'evaluate' ? 'Start Exercise' : 'Train Contrast Sensitivity';

		// Spaces out a string value when a capital letter is found.
		const spaceOutWords = (value: string): string => value.replace(/([A-Z])/g, ' $1').trim();
		const coalesce = (value: number | undefined, fallback: number): number =>
			typeof value !== 'undefined' ? value : fallback;

		return (
			<Formik
				initialValues={Object.assign(
					{},
					{
						/** anything not specified here won't show an error message after an attempted submit */
						durationSeconds: coalesce(initialConfiguration.durationSeconds, 60).toString(),
						fullScreen: false,
						level: coalesce(initialConfiguration.level, 1).toString(),
						size: coalesce(initialConfiguration.size, Edge.Models.ExerciseSize.Medium).toString(),
					}
				)}
				validationSchema={schema}
				onSubmit={async (values, actions) => {
					actions.setStatus(undefined);
					try {
						await onSubmit(
							{
								durationSeconds: parseInt(values.durationSeconds),
								exerciseTypeId: Edge.Models.ExerciseTypeId.ContrastSensitivity,
								id: initialConfiguration.id,
								level: parseInt(values.level),
								size: parseInt(values.size),
							},
							values.fullScreen
						);
					} catch (e) {
						actions.setStatus(Edge.API.getErrorMessage(e));
					}
					actions.setSubmitting(false);
				}}
				render={(props: FormikProps<ContrastSensitivityConfigFormValues>) => (
					<Form>
						<ConfigLayout
							operationName={operationName}
							exerciseName="Contrast Sensitivity"
							headerActions={
								<>
									<Input type="submit" disabled={props.isSubmitting} value={startText} />
									{props.status && <Error>{props.status}</Error>}
									{FullScreenService.isEnabled() && (
										<FormField type="checkbox" name="fullScreen" description="View Full Screen" />
									)}
								</>
							}
							instructions={instructions({ config: props.values, startText })}
							settings={
								// TODO #1584: https://dev.azure.com/vizualedge/Edge%20Trainer/_workitems/edit/1584
								// Extract and consolidate.
								<>
									<FormField
										component="select"
										name="durationSeconds"
										description="Session Duration"
										disabled={lockConfiguration}
									>
										<option value="60">1 minute</option>
										<option value="120">2 minutes</option>
										<option value="180">3 minutes</option>
										<option value="300">5 minutes</option>
									</FormField>
									<FormField
										component="select"
										name="level"
										description="Level"
										disabled={lockConfiguration}
									>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
									</FormField>
									<FormField
										component="select"
										name="size"
										description="Circle Size"
										disabled={lockConfiguration}
									>
										{Object.keys(Edge.Models.ExerciseSize)
											.filter((size: any) => typeof Edge.Models.ExerciseSize[size] === 'number')
											.map((size: any) => ({
												key: +Edge.Models.ExerciseSize[size],
												value: spaceOutWords(size),
											}))
											.filter((size) => size.key > 0)
											.map((size) => (
												<option key={size.key} value={size.key}>
													{size.value}
												</option>
											))}
									</FormField>
								</>
							}
							videoUrl={Edge.Constants.VIDEOS.CONTRAST_SENSITIVITY}
						/>
					</Form>
				)}
			/>
		);
	}
}
