import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Edge from '../../core';
import FormField from '../../components/formField';
import Input from '../../components/global/input';

import './index.scss';
import Error from '../../components/global/error';
import Info from '../../components/global/info';
import Loading from '../../components/loading';

export interface UserPasswordFormProps {
	initialValues?: Partial<UserPasswordFormValues>;
	onSubmit: (values: UserPasswordFormValues) => Promise<void>;
}

export interface UserPasswordFormValues {
	currentPassword: string;
	newPassword: string;
	newPasswordConfirm: string;
}

const UserPasswordFormSchema = Yup.object().shape({
	currentPassword: Yup.string().required('Old password is required'),
	newPassword: Edge.Validation.password('New Password'),
	newPasswordConfirm: Yup.string()
		.required('New password is required')
		.oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});

export default class UserPasswordForm extends React.Component<UserPasswordFormProps> {
	public render() {
		const { initialValues, onSubmit } = this.props;
		return (
			<div className="formWrap password">
				<h2>Change Password</h2>
				<Formik
					initialValues={Object.assign(
						{},
						{
							/** anything not specified here won't show an error message after an attempted submit */
							currentPassword: '',
							newPassword: '',
							newPasswordConfirm: '',
						},
						initialValues || {}
					)}
					validationSchema={UserPasswordFormSchema}
					onSubmit={async (values, actions) => {
						actions.setStatus(undefined);
						try {
							await onSubmit(values);
							actions.setStatus({ success: 'Your password has been changed' });
						} catch (e) {
							actions.setStatus({ error: Edge.API.getErrorMessage(e) });
						}
						actions.setSubmitting(false);
					}}
					render={(props: FormikProps<Partial<UserPasswordFormValues>>) => (
						<Form>
							<FormField component="input" type="password" name="currentPassword" label="Old Password" />
							<FormField component="input" type="password" name="newPassword" label="New Password" />
							<FormField
								component="input"
								type="password"
								name="newPasswordConfirm"
								label="Confirm New Password"
							/>
							{props.status && props.status.error && <Error>{props.status.error}</Error>}
							{props.status && props.status.success && <Info>{props.status.success}</Info>}
							{props.isSubmitting && <Loading buttonLoader />}
							<Input type="submit" disabled={props.isSubmitting} value="submit" />
						</Form>
					)}
				/>
			</div>
		);
	}
}
