import * as React from 'react';
import * as _ from 'lodash';
import classNames from 'classnames';

import './index.scss';
import Input from '../global/input';
import SeeMorePaging from './seeMorePaging';
import NumberPaging from './numberPaging';

export type PagingComponent<T> = React.ComponentType<PagingComponentProps<T>>;
export type PagingMode = 'none' | 'seeMore' | 'numbers';
export interface PagingComponentProps<T> {
	pageSize: number;
	seeMoreText?: string;
	items: T[];
	render: (items: T[]) => React.ReactNode;
}

export interface TableProps<T> {
	className?: string;
	initialSort?: string[];
	availableSorts?: { [x: string]: (item: T) => any };
	availableSearches?: ((item: T) => string | undefined)[];
	items: T[];
	searchPlaceholderText?: string;
	renderHeader?: () => React.ReactNode;
	renderAfterSearch?: () => React.ReactNode;
	renderSubHeader?: () => React.ReactNode;
	renderTableHeader: (classNames: (field: string) => string, toggleSort: (field: string) => void) => React.ReactNode;
	renderTableItem: (item: T) => React.ReactNode;
	renderNoItems?: () => React.ReactNode;

	pageSize?: number;
	seeMoreText?: string;
	pagingMode: PagingMode | PagingComponent<T>;
}

interface TableState {
	sort?: string[];
	search?: string;
}

const defaultPageSize = 10;
const noPaging: React.ComponentType<PagingComponentProps<any>> = (props) => <>{props.render(props.items)}</>;

export class Table<T> extends React.Component<TableProps<T>, TableState> {
	constructor(props: TableProps<T>) {
		super(props);
		this.state = {};
	}

	public render() {
		const { searchPlaceholderText, className, pageSize, seeMoreText } = this.props;
		const { search } = this.state;
		const PagingComponent = this.getPagingComponent();
		const toRender = this.itemsToRender();
		return (
			<div className={classNames('edge-table', className)}>
				{this.props.renderHeader && (
					<div className="edge-table-header">
						<h2>{this.props.renderHeader()}</h2>
						{searchPlaceholderText && (
							<span className="search_wrapper">
								<Input
									type="text"
									className="edge-table-search"
									placeholder={searchPlaceholderText}
									value={this.state.search}
									onChange={this.onSearchChanged}
								/>
							</span>
						)}
						{this.props.renderAfterSearch && this.props.renderAfterSearch()}
					</div>
				)}
				{this.props.renderSubHeader && (
					<div className="edge-table-sub-header">
						<h3>{this.props.renderSubHeader()}</h3>
					</div>
				)}
				<PagingComponent
					key={search || ''}
					pageSize={pageSize || defaultPageSize}
					seeMoreText={seeMoreText}
					items={toRender}
					render={(filteredItems) => (
						<table className="edge-table-table">
							<thead>{this.props.renderTableHeader(this.classNames, this.toggleSort)}</thead>
							<tbody>
								{filteredItems.map((i, ix) => (
									<React.Fragment key={ix}>{this.props.renderTableItem(i)}</React.Fragment>
								))}
								{filteredItems.length === 0 && this.props.renderNoItems && this.props.renderNoItems()}
							</tbody>
						</table>
					)}
				/>
			</div>
		);
	}

	private getPagingComponent = (): PagingComponent<T> => {
		const { pagingMode } = this.props;
		switch (pagingMode) {
			case 'none':
				return noPaging;
			case 'seeMore':
				return SeeMorePaging;
			case 'numbers':
				return NumberPaging;
			default:
				return pagingMode;
		}
	};

	private onSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ search: event.target.value });
	};

	private currentSort = () => {
		const { sort } = this.state;
		const { initialSort } = this.props;
		return sort || initialSort;
	};

	private sortDirection = (field: string) => {
		const sort = (this.currentSort() || [])[0];
		if (sort === field) {
			return true;
		}
		if (sort === '!' + field) {
			return false;
		}
		return undefined;
	};

	private classNames = (field: string) => {
		const direction = this.sortDirection(field);
		return classNames({
			asc: direction === true,
			desc: direction === false,
			sortable: !!(this.props.availableSorts || {})[field],
		});
	};

	private toggleSort = (field: string) => {
		const { availableSorts } = this.props;
		if (!availableSorts || !availableSorts[field]) {
			return;
		}

		const sort = this.currentSort() || [];
		const newSort = sort[0] && sort[0] === field ? '!' + field : field;
		this.setState({
			sort: [newSort, ...sort.filter((i) => i !== field && i !== '!' + field)],
		});
	};

	private itemsToRender = () => {
		let { items } = this.props;
		const { initialSort, availableSorts, availableSearches } = this.props;
		let { search, sort } = this.state;

		if (search && availableSearches) {
			const searchParts = search.toLowerCase().split(' ');
			items = items.filter((i) => {
				const targetParts = availableSearches.map((s) => (s(i) || '').toLowerCase());
				return !searchParts.some((sp) => !targetParts.some((tp) => tp.indexOf(sp) >= 0));
			});
		}

		sort = sort || initialSort;
		if (sort && availableSorts) {
			const sorters = sort.map((i) => {
				const reverse = i.startsWith('!');
				const field = reverse ? i.substring(1) : i;
				const mapping = availableSorts[field];
				return {
					mapping,
					order: reverse ? 'desc' : ('asc' as ('desc' | 'asc')),
				};
			});
			items = _.orderBy(items, sorters.map((i) => i.mapping), sorters.map((i) => i.order)) as T[];
		}

		return items;
	};
}

export default Table;
