import * as Redux from 'redux';

// #1455: https://dev.azure.com/vizualedge/Edge%20Trainer/_workitems/edit/1455/?workitem=1455
// TODO: break models.ts into smaller model files (i.e. commandModels.ts, responseModels.ts, etc)
//  Believe there's a way to import files into one file and then export that in turn.

export interface LoginUserCommand {
	username: string;
	password: string;
}

export interface AddUsersToOrganizationCommand {
	organizationId: string;
	roleId: string;
	emails: string[];
}

export interface CreateTeamCommand {
	eventPageUrl?: string;
	levelId: string;
	organizationId: string;
	sportId: string;
	teamName: string;
	teamTypeId: string;
}

export interface EditTeamCommand {
	eventPageUrl?: string;
	teamId: string;
	teamName?: string;
}

export interface AddTeamMembersCommand {
	teamId: string;
	roleId: string;
	emails: string[];
}

export interface AddTeamMembersCommandResponse {
	insufficientCredits: boolean;
}

export interface UserRoleResponse {
	userId: string;
	firstName: string;
	lastName: string;
	avatarUrl: string;
	role: string;
	teamId?: string;
	teamName?: string;
	organizationId: string;
	organizationName: string;
}

export interface EmailUserCommand {
	entityId: string;
	entityType: EntityType;
	userId: string;
	message: string;
}

export interface EmailTeamCommand {
	teamId: string;
	message: string;
}

export interface AssignUserToPositionCommand {
	userTeamRoleId: string;
	teamId: string;
	positionId: string;
}

export interface CreateOrganizationCommand {
	organizationName: string;
	organizationTypeId: string;
}

export interface CreateUserCommand {
	email: string;
	firstName: string;
	lastName: string;
	password: string;
	username: string;
	wasAtLeast13YearsOldAtRegistration: boolean;
	agreedToCookiesAtRegistration: boolean;
	agreedToLicenseAgreementAtRegistration: boolean;
	recaptchaConfirmation: string;
}

export type CreateUserAndOrganizationCommand =
	| CreateUserAndOrganizationOrganizationCommand
	| CreateUserAndOrganizationUserCommand;

export interface CreateUserAndOrganizationOrganizationCommand extends CreateUserCommand {
	organizationName: string;
	isIndividual: boolean;
	organizationTypeId?: string;
}

export interface CreateUserAndOrganizationUserCommand extends CreateUserCommand {
	organizationName: string;
	isIndividual: boolean;
	levelId?: string;
	sportId?: string;
	positionId?: string;
}

export interface CreateUserResult {
	userId: string;
}

export interface CreateUserAndOrganizationResult extends CreateUserResult {
	organizationId: string;
}

export interface CreateTeamMemberCommand {
	email: string;
	firstName: string;
	lastName: string;
	password: string;
	username: string;
	teamId?: string;
	positionId?: string;
	teamRoleId?: string;
}

export interface EditUserCommand {
	userId: string;
	email: string;
	firstName: string;
	lastName: string;
	newPassword: string;
}

export interface EditOrganizationCommand {
	organizationId: string;
	organizationName: string;
}

export interface UpdateAdminSettingsCommand {
	organizationId: string;
	userPurchasable: boolean;
}

export interface CreateCheckoutSessionCommand {
	glassesQuantity?: number;
	price?: string;
	quantity: number;
	country?: string;
	entityId?: string;
	entityType: EntityType;
	organizationId?: string;
	checkoutSessionType: CheckoutSessionType;
	successUrl: string;
	cancelUrl: string;
}

export enum CheckoutSessionType {
	AddSeats = 'AddSeats',
	Extend = 'Extend',
	Initial = 'Initial',
}

export enum EntityType {
	Organization = 'Organization',
	Team = 'Team',
	User = 'User',
}

export interface Address {
	line1: string;
	line2: string;
	city: string;
	state: string;
	zip: string;
	country: string;
}

export interface CheckoutSessionItem {
	priceId: string;
	quantity: number;
}

export interface BasePrice {
	active: boolean;
	unitAmount: number;
	productId: string;
	id: string;
	object: string;
	metadata: { [key: string]: string };
}

export enum PriceTypes {
	Subscription = 'SubscriptionPrice',
	Sessions = 'SessionsPrice',
	Glasses = 'GlassesPrice',
}

export type PriceType = PriceTypes.Subscription | PriceTypes.Sessions | PriceTypes.Glasses;

export interface SubscriptionPrice extends BasePrice {
	priceType: PriceTypes.Subscription;
	months: number;
}

export interface SessionsPrice extends BasePrice {
	priceType: PriceTypes.Sessions;
	sessions: number;
}

export interface GlassesPrice extends BasePrice {
	priceType: PriceTypes.Glasses;
}

export type Price = SubscriptionPrice | SessionsPrice | GlassesPrice;
export interface PriceDetail {
	price: Price;
	product: Product;
}

export interface Product extends BaseObject {
	name: string;
	caption: string;
	active: boolean;
	metadata: { [key: string]: string };
	object: any;
}

export interface BaseCoupon {
	discountCode: string;
}

export interface Coupon extends BaseCoupon {
	amountOff: number;
	percentOff: number;
}

export interface OrganizationAllowedPrice extends PriceDetail {
	discount?: Coupon;
}

export interface FullCommerceRestrictionsResponse {
	discount?: BaseCoupon;
	price: Price;
	active: boolean;
}

export interface PriceDiscountPair {
	priceId: string;
	discountCode?: string;
}

export interface UpdateCommerceRestrictionsCommand {
	organizationId: string;
	priceDiscount: PriceDiscountPair[];
}

export interface updateActivePriceCommand {
	entityId: string;
	entityType: string;
	priceId: string;
}

export interface CreateCheckoutSessionResponse {
	amount: number;
	coupon: string;
	customerId: string;
	checkoutSessionItems: CheckoutSessionItemResponse[];
	status: string;
	id: string;
	object: string;
	shippingMethods: ShippingMethod[];
	url: string;
}

export interface GetOrderDetail {
	label: string;
	values: string[];
}

export interface CheckoutSessionSummary extends BaseObject {
	recipient: string;
	createdAtUtc: Date;
	checkoutSessionType: string;
	glassesQuantity: number;
	price: string;
	quantity: number;
	amount: number;
}

export interface ShippingMethod extends BaseObject {
	amount: number;
	description: string;
	currency: string;
}

export interface CheckoutSessionItemResponse {
	amount: number;
	description: string;
	quantity: number;
	type: CheckoutSessionItemType;
	id: string;
	object: string;
}

export enum CheckoutSessionItemType {
	Shipping = 'shipping',
	Price = 'price',
	Tax = 'tax',
	Discount = 'discount',
}

export interface PayOrderCommand {
	orderId: string;
	cardToken: string;
}

export interface BaseObject {
	id: string;
}

export interface NamedObject extends BaseObject {
	name: string;
}

export interface ApiKey extends NamedObject {
	entityId: string;
	type: string;
	token: string;
	isEnabled: boolean;
	lastEnabledUtc: Date;
}

export interface EditKeyCommand {
	entityType: string;
	entityId: string;
}

export interface Organization extends NamedObject {
	logoUrl?: string;
	organizationType?: OrganizationType;
	distributor?: Distributor;
	userPurchasable?: boolean;
}

export interface Distributor extends NamedObject {}

export interface Team extends NamedObject {
	eventPageUrl?: string;
	hideExerciseResults: boolean;
	level: Level;
	logoUrl?: string;
	organizationId: string;
	sport: Sport;
	type: TeamType;
}

export interface TeamMemberBase extends BaseObject {
	user?: User;
	email?: string;
	teamRole: Role;
	position?: Position;
}

export interface TeamMember extends TeamMemberBase {
	sessions?: number;
	lastSession?: Date;
	lastEvaluation?: Date;
}

export interface TeamProspect extends BaseObject {
	firstName: string;
	lastName: string;
	redactedEmail: string;
}

export interface OrganizationMember extends BaseObject {
	user: User;
	organizationRole: Role;
	email?: string;
}

export interface User extends BaseObject {
	firstName: string;
	lastName: string;
	avatarUrl?: string;
	email?: string;
}

export interface ManuallyEditedUser {
	user: User;
	email?: string;
}

export interface Position extends NamedObject {
	sportId: string;
}

export interface Role extends NamedObject {}

export interface AssignedRoles {
	organizationRoles: AssignedRole[];
	teamRoles: AssignedRole[];
}

export interface AssignedRole {
	canEvaluate: boolean; // false if distributor or GM
	canAddProspects: boolean; // false if athlete, distributor or prospect
	isNormalUser: boolean; // false if user is a prospect
	organizationPermissions: PermissionLevel;
	teamPermissions: PermissionLevel;
	entityId: string;
}

// "0 as 0" gets typescript to type this as the literal numbers 0, etc. vs. 'number'.  Ie. typescript will know that '4' isn't a valid permission level, and neither is '-1' or '1.5'.
export const PermissionLevels = {
	Forbidden: 0 as 0,
	List: 1 as 1,
	Details: 2 as 2,
	Edit: 3 as 3,
};
export type PermissionLevel =
	| typeof PermissionLevels.Forbidden
	| typeof PermissionLevels.List
	| typeof PermissionLevels.Details
	| typeof PermissionLevels.Edit;

export interface TeamType extends NamedObject {
	id: TeamTypeId;
}

export enum TeamTypeId {
	Event = 'a6d2f372-7fdb-4c2f-b41c-3aafb9ba5f34',
	Standard = 'af0735d7-f0fa-4832-95dc-735103246c9e',
	ProspectOnly = '06f768bc-cf44-4ba8-9bbd-deca7ba60572',
	StationMode = '584a40e6-cdd7-4857-a47c-c7623727ccf3',
}

export enum RoleId {
	Coach = 'a60314f1-0bbf-4376-b757-ed9d352f2721',
	Athlete = 'c3a9cd6e-529c-4105-ab12-61e95ffda5b4',
	Scout = '66ed2d8a-d2ed-4274-8db6-983f960638a9',
	Prospect = 'd22362fc-8c2e-4090-9960-db8cf2b66b16',
}

export enum LevelId {
	All = '7a70c156-734f-4395-9f19-a7f6fae10d3c',
	Collegiate = 'cb461330-4aad-4b74-8600-223805c689d9',
	Other = '11089aba-e8e5-4347-a1df-6dce2689810d',
	Pro = 'f4c923dd-9398-45e1-942d-d9751d51a5e5',
	Youth = '141da16b-8139-4663-9e6f-95d2cffb259a',
}

export enum OrganizationTypeId {
	Concussion = 'a34b34e3-1b5a-4636-9b5c-c7b7727fdf7e',
	Event = 'ae1ca465-07e6-4b66-b668-8a0374d73067',
	EyeCare = '9b5786d8-0ff9-4fba-a8b0-f202ebe61b69',
	Facility = 'e15a5ed3-f73d-4c2b-b922-bbbb976d4758',
	Individual = '30e9f146-d457-4229-9640-9386e30e4861',
	Team = '17635840-28b5-462d-9da5-f4e2cb1cd06c',
	TeamPayLater = '82485dbf-ad98-466f-9334-11c6f4822592',
}

export interface Sport extends NamedObject {}

export interface Level extends NamedObject {}

export interface OrganizationType extends NamedObject {
	id: OrganizationTypeId;
}

export interface Profile extends ProfileRegistrationFlags {
	avatarUrl?: string;
}

export interface ProfileRegistrationFlags {
	agreedToCookiesAtRegistration: boolean;
	agreedToLicenseAgreementAtRegistration: boolean;
	wasAtLeast13YearsOldAtRegistration: boolean;
}

export interface DeviceProfile extends NamedObject {
	renderScale: number;
	touchControls: boolean;
	userId: string;
}

export interface CreateProspectCommand {
	email?: string;
	firstName: string;
	lastName: string;
	positionId?: string;
	teamId: string;
}

export interface LoginProspectCommand {
	prospectUserId: string;
	teamId: string;
}

export interface UserDetailsCommand {
	firstName: string;
	lastName: string;
	email: string;
}

export interface ForgotPasswordCommand {
	username: string;
	email: string;
}

export interface ResetPasswordCommand {
	userId: string;
	newPassword: string;
	code: string;
}

export interface ForgotUsernameCommand {
	email: string;
}

export interface ChangePasswordCommand {
	currentPassword: string;
	newPassword: string;
}

export enum SessionTypeId {
	Train = '06f768bc-cf44-4ba8-9bbd-deca7ba60572',
	Evaluate = 'c9243ae7-b52b-4d29-97d4-350b8d6064ac',
}

export enum GameMode {
	MyPlan = 0,
	OpenGym = 1,
	GameDay = 2,
}

export enum SessionType {
	Train = 'train',
	Evaluate = 'evaluate',
}

export interface StartSessionCommand {
	teamId: string;
	sessionTypeId: SessionTypeId;
}

export interface EndSessionCommand {
	sessionId: string;
	abandon: boolean;
}

export interface AddPlanPhaseCommand {
	sessionId: string;
	planPhaseId: string;
}

export interface MigrateSessionsAndPlansCommand {
	prospectUsername: string;
	newUsername: string;
}

export interface ConvertUser {
	prospectId: string;
	teamId?: string;
	newUserName: string;
}

export interface MigrateUserTeamRoleCommand {
	userTeamRoleId: string;
	newTeamId: string;
}

export interface HasResponseDate {
	responseDateUtc: Date;
	receivedDateUtc: Date;
}

export interface ActiveSessionResponse extends HasResponseDate {
	active?: BaseSessionResponse;
}

export interface BaseSessionResponse {
	sessionId: string;
	sessionTypeId: SessionTypeId;
	expiresUtc: string;
	teamId: string;
	user?: User;
	completionPercent: number;
	edgeScore?: number | null;
	explanationPercentile?: number | null;
	explanationRankingText?: string | null;
	planPhaseId?: string;
	exerciseResults: ExerciseResult[];
}

export interface MyCreditsResponse extends HasResponseDate {
	sessionCredits: number;
	subscriptionCredits: number;
}

export interface LatestPurchasePlanSubscription {
	seats: number;
	lifetimeDays: number;
	startedDateUtc?: string;
	expirationDateUtc?: string;
}

export interface LatestPurchasePlanResponse extends BaseObject {
	price: Price;
	subscription?: LatestPurchasePlanSubscription;
	addSeatPrice?: number;
	sessionCreditTotal?: number;
	sessionCreditBankBalance?: number;
}

export interface TimeSpan {
	days: number;
}

// Tag: New Exercise
// Add new enum value. GUID must match back end!
// Enum name affects the API endpoint name.
// @see SessionService.getRouteName().
export enum ExerciseTypeId {
	Alignment = '7718e1e1-c22e-4612-93e6-2fb9f5c46586',
	Alternating = '9992f88e-79d0-4e83-abbb-fc353d6e1e0f',
	ContrastSensitivity = '7f4a8891-2b34-4f06-a07c-2cd4b9058a49',
	ContrastTracking = 'c2f4281a-6bde-40fa-9ec5-c8ad133ab9e6',
	Convergence = '1f91cdcd-ffad-46df-8b6d-61bd85ae0224',
	Depth = 'ad5655d9-c282-4be7-9121-59d4def94ca7',
	Divergence = 'ab7a886c-2b5b-4339-8852-471f4accc62d',
	Pursuits = '088dae05-81f0-49ef-ab80-6ad874dcb3c9',
	Recognition = '4bac5900-597d-4382-9fc3-506160cb8035',
	Tracking = 'be83cdfc-f369-46e2-9056-273c4a1bf2eb',
}

// Tag: New Exercise
// Create new interface that extends ExerciseBase.
// Naming convention: `${exerciseName}ExerciseBase`
export interface ExerciseBase {
	id?: string;
	durationSeconds: number;
	size: ExerciseSize;
}

export interface ExerciseSession {
	endSession: () => void;
	startSession: () => void;
}

// Tag: New Exercise
// Create new interface that extends ExerciseConfigurationBase; said interface
//  also extends `${exerciseName}ExerciseBase`.
// Naming convention: `${exerciseName}ExerciseConfiguration`.
export interface ExerciseConfigurationBase extends ExerciseBase {
	sequenceOrder?: number;
}

// Tag: New Exercise
// Create new interface that extends ExerciseResultBase; said interface
//  also extends `${exerciseName}ExerciseBase`.
// Naming convention: `${exerciseName}ExerciseResult`.
export interface ExerciseResultBase extends ExerciseBase {
	exerciseConfigurationId?: string;
	audio: boolean;
	correctPercent: number;
	responseTimeMilliseconds: number;
	complete?: boolean;
	explanationPercentile?: number | null;
	explanationDescription?: string | null;
	explanationRankingText?: string | null;
	gameMode?: GameMode | null;
}

export enum ExerciseSize {
	None = 0,
	Small = 1,
	Medium = 2,
	Large = 3,
}

export enum ExerciseSpeed {
	None = 0,
	Slow = 1,
	Normal = 2,
	Fast = 3,
	VeryFast = 4,
}

export enum TrackingMode {
	Pursuits = 1,
	Saccades = 2,
}

export interface TrackingExerciseBase extends ExerciseBase {
	exerciseTypeId: ExerciseTypeId.Tracking;
	flashTimeMilliseconds: number;
	fixation: boolean;
}

export interface TrackingExerciseConfiguration extends TrackingExerciseBase, ExerciseConfigurationBase {}

export interface TrackingExerciseResult extends TrackingExerciseBase, ExerciseResultBase {}

export interface RecognitionExerciseBase extends ExerciseBase {
	exerciseTypeId: ExerciseTypeId.Recognition;
	flashTimeMilliseconds: number;
	arrows: number;
}

export interface RecognitionExerciseConfiguration extends RecognitionExerciseBase, ExerciseConfigurationBase {}

export interface RecognitionExerciseResult extends RecognitionExerciseBase, ExerciseResultBase {}

export interface FlexibilityExerciseBase extends ExerciseBase {
	level: number;
}

export interface FlexibilityExerciseResultBase extends FlexibilityExerciseBase, ExerciseResultBase {
	stationMax: number;
}

export interface ConvergenceExerciseBase extends FlexibilityExerciseBase {
	exerciseTypeId: ExerciseTypeId.Convergence;
}

export interface ConvergenceExerciseConfiguration extends ConvergenceExerciseBase, ExerciseConfigurationBase {}

export interface ConvergenceExerciseResult extends ConvergenceExerciseBase, FlexibilityExerciseResultBase {
	convergenceCorrectPercent: number;
	convergenceStationScore: number;
}

export interface DivergenceExerciseBase extends FlexibilityExerciseBase {
	exerciseTypeId: ExerciseTypeId.Divergence;
}

export interface DivergenceExerciseConfiguration extends DivergenceExerciseBase, ExerciseConfigurationBase {}

export interface DivergenceExerciseResult extends DivergenceExerciseBase, FlexibilityExerciseResultBase {
	divergenceCorrectPercent: number;
	divergenceStationScore: number;
}

export interface AlternatingExerciseBase extends FlexibilityExerciseBase {
	exerciseTypeId: ExerciseTypeId.Alternating;
}

export interface AlternatingExerciseConfiguration extends AlternatingExerciseBase, ExerciseConfigurationBase {}

export interface AlternatingExerciseResult extends AlternatingExerciseBase, FlexibilityExerciseResultBase {
	convergenceCorrectPercent: number;
	convergenceStationScore: number;
	divergenceCorrectPercent: number;
	divergenceStationScore: number;
}

export type FlexibilityExerciseConfiguration =
	| ConvergenceExerciseConfiguration
	| DivergenceExerciseConfiguration
	| AlternatingExerciseConfiguration;
export type FlexibilityExerciseResult =
	| ConvergenceExerciseResult
	| DivergenceExerciseResult
	| AlternatingExerciseResult;

export interface DepthExerciseBase extends ExerciseBase {
	exerciseTypeId: ExerciseTypeId.Depth;
	level: number;
}

export interface DepthExerciseConfiguration extends DepthExerciseBase, ExerciseConfigurationBase {}

export interface DepthExerciseResult extends DepthExerciseBase, ExerciseResultBase {}

export interface AlignmentExerciseBase extends ExerciseBase {
	exerciseTypeId: ExerciseTypeId.Alignment;
}

export interface AlignmentExerciseConfiguration extends AlignmentExerciseBase, ExerciseConfigurationBase {}

export interface AlignmentExerciseResult extends AlignmentExerciseBase, ExerciseResultBase {
	horizontalAlignment: number;
	verticalAlignment: number;
}

export interface PursuitsExerciseBase extends ExerciseBase {
	exerciseTypeId: ExerciseTypeId.Pursuits;
	speed: ExerciseSpeed;
}

export interface PursuitsExerciseConfiguration extends PursuitsExerciseBase, ExerciseConfigurationBase {}

export interface PursuitsExerciseResult extends PursuitsExerciseBase, ExerciseResultBase {}

export interface ContrastSensitivityExercise extends ExerciseBase {
	exerciseTypeId: ExerciseTypeId.ContrastSensitivity;
	level: number;
}

export interface ContrastSensitivityExerciseConfiguration
	extends ContrastSensitivityExercise,
		ExerciseConfigurationBase {}

export interface ContrastSensitivityExerciseResult extends ContrastSensitivityExercise, ExerciseResultBase {
	stationMax: number;
}

export interface ContrastTrackingExercise extends ExerciseBase {
	exerciseTypeId: ExerciseTypeId.ContrastTracking;
	level: number;
	speed: ExerciseSpeed;
	trackingMode: TrackingMode;
}

export interface ContrastTrackingExerciseConfiguration extends ContrastTrackingExercise, ExerciseConfigurationBase {}

export interface ContrastTrackingExerciseResult extends ContrastTrackingExercise, ExerciseResultBase {
	stationMax: number;
}

// Tag: New Exercise
// Add to `type` the new ExerciseResult interface as an OR value.
export type ExerciseResult =
	| AlignmentExerciseResult
	| ContrastSensitivityExerciseResult
	| ContrastTrackingExerciseResult
	| DepthExerciseResult
	| FlexibilityExerciseResult
	| PursuitsExerciseResult
	| RecognitionExerciseResult
	| TrackingExerciseResult;

// Tag: New Exercise
// Add to `type` the new ExerciseConfiguration interface as an OR value.
export type ExerciseConfiguration =
	| AlignmentExerciseConfiguration
	| ContrastSensitivityExerciseConfiguration
	| ContrastTrackingExerciseConfiguration
	| DepthExerciseConfiguration
	| FlexibilityExerciseConfiguration
	| PursuitsExerciseConfiguration
	| RecognitionExerciseConfiguration
	| TrackingExerciseConfiguration;

export type ExerciseResultWithSession = ExerciseResult & {
	session: BaseSessionResponse;
};

export type CreateExerciseResultCommand = ExerciseResult & {
	sessionId: string;
};

export enum PercentileType {
	EdgeScore = 'edgeScore',
	Exercise = 'exercise',
}

export interface ProPercentile extends BaseObject {
	// Object will not exist without a percentile value
	type: PercentileType;
	percentilePro: number;
	rankingTextPro: string;
	descriptionPro: string;
}

export type ResultWithProCompare = ExerciseResult & ProPercentile;

export type CompleteExerciseCommand = {
	sessionId: string;
	exerciseConfigurationId: string;
	gameMode?: GameMode | null;
};

export interface TrainingPlanResult {
	userFirstName: string;
	currentPlanPhaseId: string;
	remainingDaysOfCurrentPlan: number;
	plan: Plan;
	sessions: BaseSessionResponse[];
}

export interface GameDayPlanResult {
	currentPlanPhaseId: string;
	plan: Plan;
}

export interface Plan extends NamedObject {
	phases: PlanPhase[];
}

export interface PlanPhase extends BaseObject {
	durationDays: number;
	requiredSessions: number;
	useSequenceOrder: boolean;
	exerciseConfigurations: ExerciseConfiguration[];
}

export interface EvaluationResult {
	id: string;
	user: User;
	activeUser: boolean;
	teamId: string;
	teamName: string;
	organizationId: string;
	organizationName: string;
	completedDateUtc: Date;
	edgeScore?: number;
	eyeAlignment?: number;
	depth?: number;
	convergencePercentage?: number;
	convergenceStationScore?: number;
	divergencePercentage?: number;
	divergenceStationScore?: number;
	recognitionResponseTime?: number;
	recognitionPercentage?: number;
	trackingResponseTime?: number;
	trackingPercentage?: number;
	percentileRank?: number;
}

export interface EvaluationResultSummary {
	edgeScore: number;
	completedDateUtc: string;
}

export interface SkillScores {
	depth: DepthExerciseResult;
	convergence: ConvergenceExerciseResult;
	divergence: DivergenceExerciseResult;
	recognition: RecognitionExerciseResult;
	tracking: TrackingExerciseResult;
	alignment: AlignmentExerciseResult;
}

export interface ScoreCardResult {
	averageRecentEdgeScore: number;
	averagePreviousEdgeScore: number;
	totalSessions: number;
	totalHoursTrained: number;
}

export interface TopPerformer {
	user: User;
	totalSessions: number;
	lastScore: number;
}

export interface TeamExercise {
	totalSessions: number;
	lastScore: number | null;
	responseTimeMilliseconds: number | null;
	convergenceStationScore?: number;
	divergenceStationScore?: number;
}

export interface TeamExerciseResult {
	convergence: TeamExercise;
	divergence: TeamExercise;
	depth: TeamExercise;
	tracking: TeamExercise;
	recognition: TeamExercise;
}

export interface ProspectEvaluationResult {
	firstName: string;
	lastName: string;
	completionPercent: number;
	completed: string;
}

export interface CreditsResponse {
	entity: CreditResponseItem;
	children: CreditResponseItem[];
}

export interface CreditResponseItem {
	entityId: string;
	subscriptionCredits: number;
	sessionCredits: number;
}

export type CreditsEntityType = 'Organization' | 'Team' | 'UserTeamRole' | 'User';
export interface ReassignCreditsRequest {
	sourceEntityId: string;
	sourceEntityType: CreditsEntityType;
	destinationEntityId: string;
	destinationEntityType: CreditsEntityType;
	credits: number;
}

export interface UpdatePlanCommand {
	entityId: string;
	entityType: CreditsEntityType;
	creditQuantity: number;
}

export interface UpdateSubscriptionEndDateCommand {
	entityId: string;
	entityType: CreditsEntityType;
	endDateUtc: Date;
}

export type ExerciseMode = 'train' | 'evaluate';

export interface InviteTokenMetadata {
	sportId?: string;
	roleId?: string;
}

export interface ChangeTeamCommand {
	userTeamRoleId: string;
	newTeamId: string;
}

export interface ChangeOrganizationCommand {
	teamId: string;
	newOrganizationId: string;
}

export interface AthleteTrainingUsage extends NamedObject {
	// id is user id
	// name is person's name
	baselineEdgeScore: number;
	latestEdgeScore: number;
	sessionCount: number;
	exerciseCount: number;
}

export interface TrainingUsageResponse extends BaseObject {
	// id is org id
	organizationName: string;
	teamName: string;
	reportUtc: Date;
	athleteUsages: AthleteTrainingUsage[];
}

export interface TeamMemberStats extends TeamMemberBase {
	edgeScore: number;
	evaluationSessionId: string;
	completedDateUtc: Date;
	depth?: number;
	convergence?: number;
	convergenceStation?: number;
	divergence?: number;
	divergenceStation?: number;
	recognition?: number;
	recognitionResponseTime?: number;
	tracking?: number;
	trackingResponseTime?: number;
	alignment?: number;
}

export interface AthleteCard {
	user: User;
	position: Position;
	latestEdgeScore?: number;
	previousEdgeScore?: number;
	totalSessions: number;
	totalHoursTrained: number;
}

/**
 * API calls to the server will typically throw an exception that looks like this (but trust nothing).
 */
export interface EdgeError extends Error {
	response?: {
		data?:
			| {
					errors?: { [k: string]: string };
					title?: string;
			  }
			| string;
		status: number;
	};
}

// https://github.com/redux-utilities/flux-standard-action
export interface EdgeAction<T = any, TT = string> extends Redux.Action<TT> {
	readonly type: TT;
	readonly payload?: T;
	readonly error?: boolean;
	readonly meta?: any;
}

export interface File {
	blob: Blob;
	filename?: string;
}
