import * as React from 'react';
import { Avatar } from '../avatar';
import { ImageUploader } from '../imageUploader';

import './index.scss';

interface ChangeImageSectionProps {
	src?: string;
	uploadImage: (imageSource: string) => Promise<void>;
}

interface ChangeImageSectionState {}

export class ChangeImageSection extends React.Component<ChangeImageSectionProps, ChangeImageSectionState> {
	public render(): JSX.Element {
		return (
			<div className="change_image_section">
				<Avatar src={this.props.src} size="large" />
				<div className="content_wrap">
					<div className="text">
						<h3 className="change_avatar_heading">Change Avatar</h3>
						<p>Personalize your avatar/logo by clicking ‘Upload Image’ to the right.</p>
					</div>
					<ImageUploader uploadImage={this.props.uploadImage}>Upload Image</ImageUploader>
				</div>
			</div>
		);
	}
}

export default ChangeImageSection;
