import * as React from 'react';

declare const paper: any;

export interface ScaleIndicatorProps {
	scale: number;
}

// this magic value comes from the old system - it's how PPI is converted to a scale factor
// I don't know how this value was derived, but with it exactly this, the rendered component matches pixel-perfect to the old system
export const scaleToPixels = 113;

/**
 * This component is basically a direct-port of the rendering part of the similar component in the old system.
 */
export class ScaleIndicator extends React.PureComponent<ScaleIndicatorProps> {
	private readonly element = React.createRef<HTMLCanvasElement>();
	constructor(props: ScaleIndicatorProps) {
		super(props);
		this.state = {};
	}
	public componentDidMount() {
		paper.setup(this.element.current);
		this.drawRuler(this.props.scale);
	}
	public componentDidUpdate() {
		this.drawRuler(this.props.scale);
	}
	public render() {
		return <canvas ref={this.element} width="320" height="100" />;
	}

	private drawBar = (xOffset: number, yOffset: number, height: number) => {
		const from = new paper.Point(xOffset, yOffset);
		const to = new paper.Point(xOffset, yOffset + height);
		const bar = new paper.Path.Line(from, to);
		bar.strokeColor = '#009ad7';
		bar.strokeWidth = 4;
	};

	private drawCrossBar = (startX: number, endX: number, yOffset: number) => {
		// draw bar
		const from = new paper.Point(startX, yOffset);
		const to = new paper.Point(endX, yOffset);
		const bar = new paper.Path.Line(from, to);
		bar.strokeColor = '#009ad7';
		bar.strokeWidth = 2;
		bar.dashArray = [4, 6];

		// draw label
		const textWidth = endX - startX;
		const textHeight = 10;
		const textYOffset = yOffset - textHeight;
		const textXOffset = textWidth / 2 - 20;
		const textRect = new paper.Rectangle(textXOffset, textYOffset, textWidth, textHeight);
		const label = new paper.PointText(textRect);
		label.content = '1 inch';
		label.fillColor = '#009ad7';
		label.fontFamily = "'robotoregular', Helvetica, Verdana, sans-serif";
		label.fontSize = 16;
	};

	private drawRuler = (scale: number) => {
		paper.project.clear();

		const leftBarX = 10;
		const rightBarX = leftBarX + Math.round(scale * scaleToPixels);
		const barY = 20;
		const barHeight = 60;

		this.drawBar(leftBarX, barY, barHeight);
		this.drawBar(rightBarX, barY, barHeight);
		this.drawCrossBar(leftBarX, rightBarX, barY + barHeight / 2);

		paper.view.draw();
	};
}
export default ScaleIndicator;
