import * as React from 'react';
import _ from 'lodash';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import * as Edge from '../../../core';
import FormField from '../../../components/formField';
import Input from '../../../components/global/input';
import Error from '../../../components/global/error';
import Loading from '../../../components/loading';

export interface CreateProspectFormProps {
	initialValues?: Partial<CreateProspectFormValues>;
	isEvent: boolean;
	positions?: Edge.Models.Position[];
	onSubmit: (values: CreateProspectFormValues) => Promise<void>;
}

export interface CreateProspectFormValues {
	email?: string;
	firstName: string;
	lastName: string;
	positionId?: string;
}

const CreateProspectForm = (props: CreateProspectFormProps) => {
	const { initialValues, isEvent, onSubmit, positions } = props;

	const normalRequiredField = Yup.string()
		.max(100, 'Too long')
		.required();

	const createProspectSchema = (isEvent: boolean) =>
		Yup.object().shape({
			email: isEvent
				? Yup.string()
						.email()
						.required('Email is required')
				: Yup.string()
						.email()
						.notRequired(),
			firstName: normalRequiredField.required('First name is required'),
			lastName: normalRequiredField.required('Last name is required'),
			positionId: Yup.string().notRequired(),
		});

	return (
		<Formik
			initialValues={Object.assign(
				{},
				{
					/** anything not specified here won't show an error message after an attempted submit */
					email: (isEvent && '') || undefined,
					firstName: '',
					lastName: '',
				},
				initialValues || {}
			)}
			validationSchema={createProspectSchema(isEvent)}
			onSubmit={async (values, actions) => {
				actions.setStatus(undefined);
				try {
					await onSubmit(values);
				} catch (e) {
					const message = Edge.API.getErrorMessage(e);
					actions.setStatus(message);
				}
				actions.setSubmitting(false);
			}}
			render={(props: FormikProps<CreateProspectFormValues>) => (
				<Form>
					<FormField type="text" name="firstName" description="First name" />
					<FormField type="text" name="lastName" description="Last name" />
					{isEvent && <FormField type="text" name="email" description="Email" />}
					{isEvent && positions && (
						<FormField component="select" name="positionId" description="Position" defaultValue={undefined}>
							<option value={undefined}>{''}</option>
							{_.orderBy(positions, (p) => p.name).map((p, i) => (
								<option key={i} value={p.id}>
									{p.name}
								</option>
							))}
						</FormField>
					)}
					{props.status && <Error>{props.status}</Error>}
					{props.isSubmitting && <Loading buttonLoader />}
					<Input type="submit" disabled={props.isSubmitting} value="submit" />
				</Form>
			)}
		/>
	);
};

export default CreateProspectForm;
