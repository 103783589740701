import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import * as Edge from '../../core';
import { AppState } from '../../store';
import errorLoadingWrapperHOC from '../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { RouteComponentProps, generatePath } from 'react-router';
import { withLoadDataDefaultConfig } from '../../components/loadData';
import { CommerceService } from '../../services/commerceService';
import { CheckoutPurchaseModel } from './models';
import ChooseProduct from './chooseProduct';
import { getPrices } from '../../store/staticData';
import { ChooseProductLayout, GlassesOptionLayout } from '../../layouts/greyScreenLayout';
import ScrollToTopOnMount from '../../components/global/scrollToTopOnMount';
import _ from 'lodash';
import { SessionService } from '../../services/sessionService';
import GlassesOption from './GlassesOption';
import { EdgeError } from '../../core/models';
import Error from '../../components/global/error';

export interface CheckoutPageOptions {}

export interface CheckoutSession {
	quantity: number;
	country?: string;
	price: string;
	entityId: string;
	entityType: Edge.Models.EntityType;
	glassesPrice?: string;
	glassesQuantity?: number;
	checkoutSessionType: Edge.Models.CheckoutSessionType;
	organizationId: string;
	successUrl: string;
	cancelUrl: string;
}

interface CheckoutPageParams {
	entityType: Edge.Models.EntityType;
	entityId: string;
	checkoutSessionType: Edge.Models.CheckoutSessionType;
}

enum CheckoutPageStage {
	ChooseProduct,
	GlassesOption,
}
export interface CheckoutPageProps extends RouteComponentProps<CheckoutPageParams> {
	organizationId: string;
	latestPurchasePlan?: Edge.Models.LatestPurchasePlanResponse;
	prices: Edge.Models.OrganizationAllowedPrice[];
	entityType: Edge.Models.EntityType;
	addSeatPrice?: Edge.Models.OrganizationAllowedPrice;
	addSeatAmount?: number;
}

export const generatePurchasePageLink = (
	entityType: Edge.Models.EntityType,
	entityId: string,
	checkoutSessionType: Edge.Models.CheckoutSessionType,
	options: CheckoutPageOptions = {}
) => {
	const query = queryString.stringify(options);
	return (
		generatePath('/checkout/:entityType/:entityId/:checkoutSessionType', {
			entityType,
			entityId,
			checkoutSessionType,
		}) +
		'?' +
		query
	);
};

/**
 * Usage scenarios:
 * 1. Extend/upgrade existing subscription (orderType: Extend)
 * 2. Add seats to existing subscription (orderType: AddSeats)
 * 3. Add sessions/subscription (cannot have an active subscription) (orderType: Initial)
 *
 * Scope:
 * - Can operate at the organization or user level (specified by route parameters)
 */

export function CheckoutPage(props: CheckoutPageProps) {
	const [stage, setStage] = useState<CheckoutPageStage>(CheckoutPageStage.ChooseProduct);
	const [checkout, setCheckout] = useState<CheckoutSession>();
	const [error, setError] = useState<EdgeError>();
	const { match, addSeatPrice, prices: allPrices } = props;
	const { checkoutSessionType } = match.params;

	const addSeats = props.match.params.checkoutSessionType === Edge.Models.CheckoutSessionType.AddSeats;
	const extend = props.match.params.checkoutSessionType === Edge.Models.CheckoutSessionType.Extend;

	const [model, setModel] = useState<CheckoutPurchaseModel>({
		price: checkoutSessionType === Edge.Models.CheckoutSessionType.AddSeats ? props.addSeatPrice : undefined,
		numberOfGlasses: 0,
		quantity:
			(extend
				? props.latestPurchasePlan &&
				  props.latestPurchasePlan.subscription &&
				  props.latestPurchasePlan.subscription.seats
				: undefined) || 1,
		overrideProductPrice: addSeats ? props.latestPurchasePlan && props.latestPurchasePlan.addSeatPrice : undefined,
	});

	const onSelectPrice = async (price: Edge.Models.OrganizationAllowedPrice) => {
		setModel({ ...model, price: price, coupon: price.discount });

		const glassPrice = props.prices.find((p) => p.price.priceType === Edge.Models.PriceTypes.Glasses);

		const checkout: CheckoutSession = {
			glassesQuantity: 1,
			glassesPrice: glassPrice && glassPrice && glassPrice.price.id,
			entityId: match.params.entityId,
			entityType: match.params.entityType,
			organizationId: props.organizationId,
			quantity: 1,
			checkoutSessionType: checkoutSessionType,
			price: price.price.id,
			successUrl: `${origin}/checkout-successful`,
			cancelUrl: `${origin}/manage-organization/manage-plan`,
		};

		setCheckout(checkout);
		setStage(CheckoutPageStage.GlassesOption);
	};

	const prices =
		checkoutSessionType === Edge.Models.CheckoutSessionType.AddSeats
			? [addSeatPrice!]
			: checkoutSessionType === Edge.Models.CheckoutSessionType.Extend
			? allPrices.filter(
					(i: Edge.Models.OrganizationAllowedPrice) => i.price.priceType !== Edge.Models.PriceTypes.Sessions
			  )
			: allPrices;

	switch (stage) {
		case CheckoutPageStage.ChooseProduct:
			return (
				<ChooseProductLayout>
					<ScrollToTopOnMount />
					<ChooseProduct
						prices={prices}
						count={
							checkoutSessionType === Edge.Models.CheckoutSessionType.Extend ? model.quantity : undefined
						}
						onSelectPrice={onSelectPrice}
					/>
				</ChooseProductLayout>
			);
		case CheckoutPageStage.GlassesOption:
			if (checkout) {
				return (
					<GlassesOptionLayout>
						<GlassesOption checkout={checkout} setError={setError} />
						{error && <Error>{Edge.API.getStatus(error) === 500 && 'Active subscription exists'}</Error>}
					</GlassesOptionLayout>
				);
			}
	}
}

function mapStateToProps({ organizationState, priceState }: AppState) {
	return {
		organizationId: (organizationState.currentOrganization && organizationState.currentOrganization.id)!,
		allPricesIsLoading: priceState.isLoading,
		allPrices: priceState.data,
	};
}

//
interface MappedProps extends CheckoutPageProps {
	allPricesIsLoading?: boolean;
	allPrices?: Edge.Models.Price[];
	getPrices: () => void;
}

export default connect(
	mapStateToProps,
	{ getPrices: getPrices }
)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC<MappedProps>(
			CheckoutPage as React.FunctionComponent<MappedProps>,
			(props) => !!props.organizationId && !!props.allPrices,
			undefined,
			(props) => {
				if (!props.allPrices && !props.allPricesIsLoading) {
					props.getPrices();
				}
			}
		),
		(props) => {
			return {
				..._.pick(props.match.params, 'entityType', 'entityId', 'checkoutSessionType'),
				organizationId: props.organizationId,
			};
		},
		async (props) => {
			const [latestPurchasePlan, prices] = await Promise.all([
				SessionService.getLatestPurchasePlan(props.entityType!, props.entityId!, props.organizationId),
				CommerceService.getOrganizationAllowedPrices(props.organizationId, props.entityType!, props.entityId!),
			]);

			return {
				prices,
				latestPurchasePlan,
				addSeatPrice: prices.filter((i) => latestPurchasePlan && i.price.id === latestPurchasePlan.price.id)[0],
			};
		}
	)
);
