import * as React from 'react';

import * as Edge from '../../../core';

import './index.scss';
import ErrorDisplay from '../../../components/error/errorDisplay';
import AnalyticsService from '../../../services/analyticsService';

import { ReactComponent as GlassesIcon } from '../../../assets/images/icons/glasses.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/icons/Clock.svg';
import { ReactComponent as DifficultyIcon } from '../../../assets/images/icons/Difficulty.svg';
import { ExerciseMetadataService } from '../../../services/exerciseMetadataService';
import * as StrUtil from '../../../utilities/strUtil';

const NewExerciseDays = 120;

export interface ExerciseLineProps {
	config: Edge.Models.ExerciseConfiguration;
	isAdmin?: boolean;
}

interface ExerciseLineState {}

export class ExerciseLine extends React.PureComponent<ExerciseLineProps, ExerciseLineState> {
	constructor(props: ExerciseLineProps) {
		super(props);
		this.state = {};
	}

	public render() {
		const { config, isAdmin } = this.props;
		const exercise = ExerciseMetadataService.getMetadata(config);

		if (!exercise) {
			const e = new Error(`Attempted to render exercise line for unknown exercise: ${config.exerciseTypeId}`);
			console.error(e);
			AnalyticsService.exception(e);
			return <ErrorDisplay>Unknown exercise type</ErrorDisplay>;
		}

		// Prevents unreleased exercises from displaying if not admin.
		const unreleased = exercise.implementedDate.getTime() > Date.now();
		if (!isAdmin && unreleased) return <></>;

		// Retrieves exercise logo component.
		const Logo = exercise.logo;

		const durationMinutes: number = config.durationSeconds / 60;
		const isExerciseNew: boolean =
			Date.now() - exercise.implementedDate.getTime() <= NewExerciseDays * 1000 * 60 * 60 * 24;
		const level: number = (config as any).level;

		return (
			<div className="exercise-line">
				<div className="icon_wrapper">
					<Logo className="exercise-line-icon" />
				</div>
				<div className="text_wrapper">
					<div className="exercise-title">
						<h3>{exercise.title}</h3>
						{isExerciseNew && <h4>new</h4>}
						{isAdmin && unreleased && <h4>(unreleased)</h4>}
					</div>
					<div className="exercise_description">
						<p>{exercise.description}</p>
					</div>
					<div className="exercise-line-attributes">
						<span className="duration">
							<span className="icon icon-duration">
								<ClockIcon />
							</span>
							<span className="text-duration">
								{durationMinutes} {StrUtil.pluralize('minute', durationMinutes)}
							</span>
						</span>
						{level && (
							<span className="difficulty">
								<span className="icon icon-difficulty">
									<DifficultyIcon />
								</span>
								<span className="text-difficulty">Difficulty {level}</span>
							</span>
						)}
						{exercise.usesGlasses && (
							<span className="glasses">
								<span className="icon icon-glasses">
									<GlassesIcon />
								</span>
								<span className="text-glasses">3D glasses</span>
							</span>
						)}
					</div>
					{this.props.children}
				</div>
			</div>
		);
	}
}
export default ExerciseLine;
