/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-script-url */
import * as React from 'react';
import { BaseExercise, BaseExerciseProps } from '../baseExercise';
import * as Edge from '../../../core';
import ExerciseLayout from '../../../layouts/exerciseLayout';
import _, { Dictionary } from 'lodash';
import { Howler } from 'howler';
import { Animate, DPad, Drawing, FullScreen, Keyboard, Stage, Starter, Timer, Vector } from '../../../exercises';

const DebugLineThickness: number = 3;
const DebugLineLength: number = 120;

const DefaultArrowSize: number = 50;

const QuestionTimeoutSeconds: number = 3;

interface PursuitsProps
	extends BaseExerciseProps<Edge.Models.PursuitsExerciseResult, Edge.Models.PursuitsExerciseConfiguration> {}

interface ExerciseResponse {
	correct: boolean;
	responseTime: number;
}

interface ExerciseConfiguration extends Edge.Models.PursuitsExerciseConfiguration {
	fullScreen: boolean;
	renderScale: number;
	sessionLength: number;
	touchControls: boolean;
}

const ImageScales: number[] = [0, 0.42, 1.0, 1.18]; // assumes that `ExerciseSize` has 4 values. should probably refactor to accommodate
const SpeedScales: number[] = [0, 1.5, 3.0, 6.0, 9.0]; // assumes that `ExerciseSpeed` has 5 values. should probably refactor to accommodate
const Directions: Dictionary<number> = {
	right: 180,
	down: 270,
	left: 0,
	up: 90,
}; // this assumes we're loading one left-facing arrow

interface Boundary {
	left: number;
	top: number;
	right: number;
	bottom: number;
}

export default class Pursuits extends BaseExercise<
	PursuitsProps,
	Edge.Models.PursuitsExerciseResult,
	Edge.Models.PursuitsExerciseConfiguration,
	{}
> {
	// canvas
	private ctx: CanvasRenderingContext2D = null!;

	// exercise specifics
	private exerciseConfiguration: ExerciseConfiguration;

	// components
	private dPad: DPad = null!;
	private starter: Starter = null!;
	private timer: Timer = null!;

	// state vars
	private acceptingInput: boolean = false;
	private animationId?: number; // Keeps track of animation frame request for cancelling.
	private arrow: HTMLImageElement = null!;
	private arrowCurrentPosition: Vector = new Vector(0, 0, 0); // initialized later on
	private arrowCurrentVector: Vector = new Vector(0, 0, 0); // initialized later on
	private arrowDimension: number;
	private arrowDirection: string = 'right';
	private arrowNextVector: Vector | null = null;
	private arrowSpeed: number;
	private arrowTurnRadius: number;
	private boundary?: Boundary;
	private lastPromptTime?: number;
	private previousFixedUpdateTimestamp?: DOMHighResTimeStamp;
	private responses: ExerciseResponse[] = [];
	private warningTrackBuffer: number;

	// Timers
	private questionTimeout: ReturnType<typeof setTimeout> | null = null;
	private sessionTimer: ReturnType<typeof setTimeout> | null = null;

	private showBoundaries: boolean = false;

	constructor(props: PursuitsProps) {
		super(props);

		this.exerciseConfiguration = Object.assign(props.configuration, {
			...props,
			sessionLength: props.debugMode
				? Stage.DebugSessionLength * 1000
				: props.configuration.durationSeconds * 1000,
		});

		this.arrowDimension =
			DefaultArrowSize * ImageScales[this.exerciseConfiguration.size] * this.exerciseConfiguration.renderScale;
		this.arrowSpeed = SpeedScales[this.exerciseConfiguration.speed] * this.exerciseConfiguration.renderScale;
		this.arrowTurnRadius = this.arrowDimension; // this also auto-scales the radius to fit the render scale

		// triple the radius to give the arrow space to turn a full circle and a half in the buffer area.
		// the valid case here is this: let's say the arrow is 1 pixel from the warning track (every move is still legal) and moving in the direction
		// of -1 degree (aka 89 degrees E by NE). let's say the next vector is due north. this means that the arrow ends up halfway through the warning track
		// from a legal movement, and might require up to a full diameter and a half to recover and return to center.
		this.warningTrackBuffer = this.arrowTurnRadius * 3;

		// mute sound on debug
		Howler.mute(props.debugMode === true);
	}

	public createSession = (): Edge.Models.ExerciseSession => {
		const session = {
			endSession: this.endExercise,
			startSession: this.startExercise,
		};

		return session;
	};

	public render = (): JSX.Element => {
		return (
			<ExerciseLayout
				cleanUpExercise={this.cleanUp}
				completeExercise={this.completeSession}
				endExercise={this.endSession}
				exerciseName="Pursuits"
			>
				<canvas
					id={Stage.Name}
					style={{
						backgroundColor: '#fff',
						display: 'inline',
						height: window.innerHeight - Stage.Margin.vertical,
						width: window.innerWidth - Stage.Margin.horizontal,
					}}
				/>
			</ExerciseLayout>
		);
	};

	private cleanUp = (): void => {
		// Cancels remaining animation.
		cancelAnimationFrame(this.animationId!);
		this.acceptingInput = false;
		this.starter.setIsRunning(false);

		// removing event listeners, shutting app down
		document.removeEventListener('keydown', this.handleKeyDown);
		this.ctx.canvas.removeEventListener('click', this.handleCanvasClicked);

		// Clears timers.
		if (this.questionTimeout) clearTimeout(this.questionTimeout);
		if (this.sessionTimer) clearTimeout(this.sessionTimer);
	};

	private fixedUpdate = (timestamp: DOMHighResTimeStamp): void => {
		// Determines elapsed time since last update.
		const elapsed = timestamp - (this.previousFixedUpdateTimestamp || timestamp);

		// clear canvas for redraw
		this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

		if (!this.arrowNextVector) {
			const coordinate: { x: number; y: number } = Animate.getStartingVectorCoordinate();
			this.arrowNextVector = new Vector(coordinate.x, coordinate.y, 0).unit().multiply(this.arrowSpeed);
		}

		const radiansPerFrame = this.arrowSpeed / this.arrowTurnRadius;

		let framesRemaining = elapsed > 0 ? elapsed / (1000 / Animate.FpsTarget) : 1;
		while (framesRemaining > 0) {
			const newValues = Animate.updatePositionAndVector(
				this.boundary!,
				this.warningTrackBuffer,
				framesRemaining,
				this.arrowCurrentPosition,
				radiansPerFrame,
				this.arrowSpeed,
				this.arrowCurrentVector,
				this.arrowNextVector!
			);

			this.arrowCurrentPosition = newValues.positionCurrent;
			this.arrowCurrentVector = newValues.vectorCurrent;
			this.arrowNextVector = newValues.vectorNext;

			// Decrements frames remaining.
			framesRemaining = framesRemaining > newValues.framesToNext ? framesRemaining - newValues.framesToNext : 0;
		}

		// great for debugging arrow motion math
		if (this.showBoundaries) this.drawDebugElements();

		// draw canvas elements
		this.timer.draw();
		this.dPad.draw(this.exerciseConfiguration.touchControls);
		this.drawTarget();

		// decrement
		this.timer.elapseTime(elapsed);

		this.previousFixedUpdateTimestamp = timestamp;
		this.animationId = requestAnimationFrame(this.fixedUpdate);
	};

	private drawDebugElements = (): void => {
		if (!this.boundary) return;

		// draw boundaries
		this.ctx.save();

		this.ctx.lineWidth = DebugLineThickness;

		// draw warning track
		this.ctx.strokeStyle = 'orange';
		this.ctx.strokeRect(
			this.boundary.left + this.warningTrackBuffer,
			this.boundary.top + this.warningTrackBuffer,
			this.boundary.right - this.boundary.left - 2 * this.warningTrackBuffer,
			this.boundary.bottom - this.boundary.top - 2 * this.warningTrackBuffer
		);
		this.ctx.font = '12px Arial';
		this.ctx.textAlign = 'center';
		this.ctx.textBaseline = 'middle';
		this.ctx.fillText('top', this.ctx.canvas.width / 2, this.boundary.top + this.warningTrackBuffer);
		this.ctx.fillText('bottom', this.ctx.canvas.width / 2, this.boundary.bottom - this.warningTrackBuffer);

		// draw absolute edge
		this.ctx.strokeStyle = 'red';
		this.ctx.strokeRect(
			this.boundary.left,
			this.boundary.top,
			this.boundary.right - this.boundary.left,
			this.boundary.bottom - this.boundary.top
		);

		// Draws current travel vector.
		this.ctx.strokeStyle = 'green';
		const currentVector: Vector = this.arrowCurrentVector.unit().multiply(DebugLineLength);

		this.ctx.beginPath();
		this.ctx.moveTo(this.arrowCurrentPosition.x, this.arrowCurrentPosition.y);
		this.ctx.lineTo(this.arrowCurrentPosition.x + currentVector.x, this.arrowCurrentPosition.y + currentVector.y);
		this.ctx.stroke();

		// Draws next travel vector.
		if (this.arrowNextVector) {
			this.ctx.strokeStyle = 'blue';
			const nextVector: Vector = this.arrowNextVector.unit().multiply(DebugLineLength * 0.75);

			this.ctx.beginPath();
			this.ctx.moveTo(this.arrowCurrentPosition.x, this.arrowCurrentPosition.y);
			this.ctx.lineTo(this.arrowCurrentPosition.x + nextVector.x, this.arrowCurrentPosition.y + nextVector.y);
			this.ctx.stroke();
		}

		this.ctx.restore();
	};

	private drawTarget = (): void => {
		// get current rotation
		const rotation = Directions[this.arrowDirection];

		// rotate and draw arrow, restoring canvas context afterward
		this.ctx.save();
		this.ctx.translate(this.arrowCurrentPosition.x, this.arrowCurrentPosition.y); // translate to the center of the image
		this.ctx.rotate((rotation * Math.PI) / 180); // rotate by `rotation` degrees
		this.ctx.translate(-this.arrowCurrentPosition.x, -this.arrowCurrentPosition.y); // translate back to original origin (upper left)
		this.ctx.drawImage(
			this.arrow!,
			this.arrowCurrentPosition.x - this.arrowDimension / 2,
			this.arrowCurrentPosition.y - this.arrowDimension / 2,
			this.arrowDimension,
			this.arrowDimension
		);
		this.ctx.restore();
	};

	private setupQuestion = (): void => {
		const availableDirections = _.without(Object.keys(Directions), this.arrowDirection);
		this.arrowDirection = _.sample(availableDirections)!;
		this.lastPromptTime = Date.now();

		// clear interval in preparation for restarting it since we want to rotate the arrow immediately
		if (this.questionTimeout) {
			clearTimeout(this.questionTimeout);
			this.questionTimeout = null;
		}

		// Question is automatically timed out and marked incorrect after some time.
		if (!this.questionTimeout) {
			this.questionTimeout = setTimeout(() => {
				this.recordResponse(false, QuestionTimeoutSeconds * 1000); // send incorrect with forced 3000
				this.setupQuestion();
			}, QuestionTimeoutSeconds * 1000);
		}
	};

	private handleCanvasClicked = (event: MouseEvent) => this.dPad.handleCanvasClicked(event);

	private handleKeyDown = (event: KeyboardEvent) => Keyboard.handleKeyDown(event, this.selectDirection);

	private startExercise = async (): Promise<void> => {
		// set full screen prior to setting up canvas
		await FullScreen.setAsync(this.exerciseConfiguration.fullScreen);

		// set up canvas
		const canvas = document.getElementById(Stage.Name)! as HTMLCanvasElement;
		const canvasHeight = window.innerHeight - Stage.Margin.vertical;
		canvas.height = canvasHeight;
		canvas.style.height = `${canvasHeight}px`;
		const canvasWidth = window.innerWidth - Stage.Margin.horizontal;
		canvas.width = canvasWidth;
		canvas.style.width = `${canvasWidth}px`;

		this.ctx = canvas.getContext('2d')!;
		Drawing.fixCanvasDpi(this.ctx);

		// load main arrow image
		const arrow = await Drawing.loadImageAsync('/images/arrow-left-black.svg');
		this.arrow = arrow;

		this.boundary = {
			left: this.arrowDimension / 2,
			top: this.arrowDimension / 2,
			right: this.ctx.canvas.width - this.arrowDimension / 2,
			bottom: this.ctx.canvas.height - this.arrowDimension / 2 - 110 * this.exerciseConfiguration.renderScale, // 110 keeps it above the timer. this value was taken from the legacy files
		};

		// create new instance of canvas element controls
		this.dPad = new DPad(this.ctx, false, this.selectDirection, this.exerciseConfiguration.renderScale);
		this.timer = new Timer(
			this.ctx,
			this.exerciseConfiguration.sessionLength,
			this.exerciseConfiguration.renderScale
		);

		// wire up event listeners
		document.addEventListener('keydown', this.handleKeyDown);
		this.ctx.canvas.addEventListener('click', this.handleCanvasClicked);

		// initialize arrow position and vector
		this.arrowCurrentPosition.x = this.ctx.canvas.width / 2;
		this.arrowCurrentPosition.y = this.ctx.canvas.height / 2;
		this.arrowCurrentVector = new Vector(0, 1, 0).unit().multiply(this.arrowSpeed);

		// Starts update loop.
		this.starter = new Starter(this.ctx, this.onStarterFinishCallback);
		this.starter.run();
	};

	private onStarterFinishCallback = (): void => {
		// Starts update loop.
		this.acceptingInput = true;
		this.setupQuestion();

		this.animationId = requestAnimationFrame(this.fixedUpdate);

		// Sets up session end.
		this.sessionTimer = setTimeout(async () => {
			await this.endExercise();
		}, this.exerciseConfiguration.sessionLength);
	};

	private endExercise = async (): Promise<void> => {
		await FullScreen.setAsync(false);

		const { configuration, completeExercise } = this.props;
		const resultCount = this.responses.length;
		const correctCount = this.responses.filter((r) => r.correct).length;
		const totalResponseTime = this.responses.map((r) => r.responseTime).reduce((p, c) => (c += p), 0);

		completeExercise({
			exerciseTypeId: Edge.Models.ExerciseTypeId.Pursuits,
			audio: true,
			exerciseConfigurationId: configuration.id,
			size: this.exerciseConfiguration.size,
			speed: this.exerciseConfiguration.speed,
			correctPercent: resultCount > 0 ? correctCount / resultCount : 0, // JS is out of 100, server is out of 1
			durationSeconds: this.exerciseConfiguration.sessionLength / 1000,
			responseTimeMilliseconds: resultCount > 0 ? totalResponseTime / resultCount : 0,
		});
	};

	private recordResponse = (correct: boolean, responseTime?: number): void => {
		const response = {
			correct,
			responseTime: responseTime || Date.now() - this.lastPromptTime!,
		};

		// play sound
		if (response.correct) {
			this.crystalSound.play();
		} else {
			this.buzzerSound.play();
		}

		// push to array
		this.responses.push(response);
	};

	private selectDirection = (direction: string): void => {
		if (!this.acceptingInput) return;

		this.recordResponse(direction === this.arrowDirection);
		this.setupQuestion();
	};
}
