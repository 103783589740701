/* eslint-disable */
$( function() {
	
	VizEdge.RecognitionExercise = function() {
	    this.initialize.apply(this, arguments);
	};
	
	_.extend( VizEdge.RecognitionExercise.prototype, {
    
    arrowImageIDTemplate: _.template( "arrow-<%= direction %>-<%= type %>" ),
    
    answerRecordedLogMessage: _.template( "<%= status %> answer recorded." ),
    
    youScoredTemplate: _.template( "You scored a point! Your score is <%= points %>" ),
                
		initialize: function( mode, debugMode, renderScale, touchControls, sounds, configuration, completeExercise ) {
      
      var canvas = document.createElement('canvas');
      canvas.id = "stage";
      canvas.width = $(window).width() - 40;
      canvas.height = $(window).height() - 200;
      canvas.style.display = "inline";

      $(".stage-wrapper").append(canvas);
			paper.setup(canvas);
      this.canvasEl = canvas;
      
      _.bindAll( this, "onKeyDown", "onExitFullScreen" );
      
      // install keyboard handler
      this.acceptingInput = false;
      this.tool = new paper.Tool();      
      this.keyboard = new VizEdge.KeyboardControl({ keydown: this.onKeyDown });
      
      // constants
      this.imageScales = { small: 0.42, medium: 1, large: 1.18 };
      this.roundTimeout = 10000;
      this.roundPause = 500;
      this.directions = ["up","left","right","down"];
      this.spaceBetweenArrows = 20 * renderScale;

      // settings
      this.mode = mode;
      this.debugMode = (debugMode != 'true' ) ? false : true;
      this.completeExercise = completeExercise;

      if( this.debugMode ) {
        this.sessionLength = 30 * 1000; // 30 secs
      } else {
        this.sessionLength = 60 * 1000; // 1 min
      }

      this.fullScreen = false;
      this.imageScale = "medium";
      this.flashTime = 600;
      this.numArrows = 3;
      this.renderScale = renderScale;
      this.touchControls = touchControls;
      
      this.imageScales = _.mapObject( this.imageScales, function( imageScale ) { return imageScale * renderScale; });

      
      if (configuration) {
        this.imageScale = configuration.imageScale;
        this.numArrows = configuration.numArrows;
        this.flashTime = configuration.flashTime;
  
        this.sessionLength = configuration.sessionDuration * 1000; // seconds to milliseconds
        this.setFullScreen(configuration.fullScreen);
      }

      // load sound effects      
      this.bubbleSound = sounds.bubble;
      this.buzzerSound = sounds.buzzer;
      this.crystalSound = sounds.crystal;
            
      // no sounds in debug mode
      if( this.debugMode ) {
        Howler.mute();
      }
      
		},
        
    onKeyDown: function( event ) {
      
      switch(event.key) {        
        case 'left':
          this.selectDirection('left');
          return false;

        case 'right':
          this.selectDirection('right');
          return false;
          
        case 'up':
          this.selectDirection('up');
          return false;

        case 'down':
          this.selectDirection('down');
          return false;
          
        default: 
          return true;
        
      }

    },
    
    initTouchControls: function() {
      if( this.touchControls ) {
        
        // bind touch handlers to exercise events
        var handlers = { left: _.bind( this.selectDirection, this, 'left' ),
                         right: _.bind( this.selectDirection, this, 'right' ),
                         up: _.bind( this.selectDirection, this, 'up' ),
                         down: _.bind( this.selectDirection, this, 'down' )
                        };

        this.dPad = new VizEdge.DPadControl( { showOK: false, handlers: handlers, tool: this.tool } );
      }
    },
    
    startSessionTimer: function() {
      
      // set up a timer for the entire session
      this.sessionTimer = window.setTimeout( _.bind( function() {
        this.endRound();
        this.endSession();        
      }, this), this.sessionLength );    

      // render timer
      this.timerLayer = new paper.Layer();
      this.timerLayer.activate();

      var viewBounds = paper.project.view.bounds;
      var maxTimerBars = 60;
      
      var barSpacing = 5;
      var barWidth = 8;
      var barHeight = 20;
      var oneBarWidth = barWidth + barSpacing;
      var totalBarWidth = oneBarWidth * maxTimerBars;
      var xStartOffset = (viewBounds.width - totalBarWidth) / 2;
      var yOffset = viewBounds.height - barHeight - 10;
      
      for( var i=0; i < maxTimerBars; i++ ) {
        var xOffset = (oneBarWidth * i) + xStartOffset;
        var rectangle = new paper.Rectangle(new paper.Point(xOffset, yOffset), new paper.Size(barWidth, barHeight));
        var shape = new paper.Shape.Rectangle(rectangle);
        if( i == 5 ) { 
          // when we reach this bar, time to turn red
          shape.redMarker = true;
        }
        shape.fillColor = 'green';        
      }
      
      var barInterval = this.sessionLength / maxTimerBars;

      // set up a timer to remove the next bar      
      var nextBarDisappears = function nextBarDisappears() {
        if( !this.timerLayer.isEmpty() ) {

          var bar = this.timerLayer.lastChild;
          
          // if we reach the marked bar, turn red
          if( bar.redMarker ) {
            _.each( this.timerLayer.children, function( bar ) {
              bar.fillColor = 'red';              
            }, this );
          }
          
          bar.remove();          
          paper.view.draw();

          // set up next tick
          window.setTimeout( _.bind( nextBarDisappears, this), barInterval );   
        }
      };

      // start ticking
      window.setTimeout( _.bind( nextBarDisappears, this), barInterval );    
    },
    
    selectDirection: function(direction) {
      // Prevents method from executing if exercise is not ready to accept input
      //  regardless of input source.
      if( !this.acceptingInput ) return;
      // Prevents extra (i.e. erroneous/unintended) inputs from running the rest of the function
      if(this.sequencePosition >= this.rightAnswers.length) return;

      var rightAnswer = this.rightAnswers[this.sequencePosition];

      if( direction == rightAnswer.direction ) {
        rightAnswer.correct = true;
        this.displayAnswer(direction, "correct");    
        this.crystalSound.play();
      } else {
        this.displayAnswer(direction, "wrong");
        this.buzzerSound.play();
      }
      
      this.sequencePosition++;

      // if this was the last arrow in the sequence, end the round
      if( this.sequencePosition == this.rightAnswers.length ) {
        
        // did we get them all right?
        var wrongAnswers = _.find( this.rightAnswers, function(answer) { return !answer.correct; });
        if( !wrongAnswers ) {
          this.points = this.points + 1;
          this.logger(this.youScoredTemplate({ points: this.points }));
        } else {
          this.logger("You got this one wrong.");      
        }
        
        this.endRound();
        this.startRound();
      }

    },
    
    startSession: function() {
            
      this.roundCount = 0;
      this.points = 0;
      this.totalResponseTime = 0;
      this.sequencePosition = 0;

      this.loadImages();
      new VizEdge.ReadyGo( _.bind( function() {
        this.initTouchControls();
        this.startSessionTimer();
        this.startRound();
      }, this) );

    },
    
    isSessionOver: function() {
      return (this.sessionTimer == null);
    },
    
    isRoundOver: function() {
      return (this.roundTimer == null);
    },
            
    endSession: function() {
            
      if( !this.isRoundOver() ) {
        this.endRound();
      }
                  
      if( this.sessionTimer ) {
        window.clearTimeout( this.sessionTimer );	
        this.sessionTimer = null;
      }

      // clean up
      paper.project.clear();
      paper.view.draw();
      this.tool.remove();
      this.keyboard.remove();

      this.setFullScreen(false);

      this.sendScore();
    },
    
    sendScore: function() {
      this.completeExercise({
        exercise: 'recognize',
        evaluation: this.mode == "evaluate",
        duration: Math.round(this.sessionLength/1000),
        audio: true,
        arrows: this.numArrows,
        size: this.imageScale,
        flashTime: this.flashTime,
        responseTime: this.calcAverageResponseTime(),
        scorePercent: this.calcScorePercent(),
      });
    },
    
    calcScorePercent: function() {
      if( this.roundCount > 0 ) {
        return (this.points / this.roundCount) * 100.0;
      } else {
        return 0.0;
      }
    },
    
    calcAverageResponseTime: function() {
      if( this.roundCount > 0 ) {
        return this.totalResponseTime / this.roundCount;
      } else {
        return 0.0;
      }
    },
    
    startRound: function() {
      
      // don't start a new round if the session has ended.
      if( this.isSessionOver() ) return;
                  
      var _startRound = _.bind( function _startRound() {
        
        this.roundPauseTimer = null;
        this.displayNextSequence( _.bind( function() {
          
          // session might have ended while sequence was fading out
          if( this.isSessionOver() ) return;
          
          this.roundTimer = window.setTimeout( _.bind( function() {
            this.buzzerSound.play();
            this.endRound();
            this.startRound();
          }, this), this.roundTimeout );
    
          this.sequencePosition = 0;
          this.acceptingInput = true;  
          this.roundStartTime = this.getTime();
          this.roundCount = this.roundCount + 1;
          
        }, this));
      }, this);

      if( this.roundCount > 0 ) {
        this.roundPauseTimer = window.setTimeout( _startRound, this.roundPause );  
      } else {
        _startRound();
      }
    
    },
    
    loadImages: function() {
      
      this.arrowLayer = new paper.Layer();
      
      var arrowTypes = [ "q", "correct", "wrong" ];
      
      // load arrows
      this.arrowImages = {};
      _.each( this.directions, function(direction) {
        _.each( arrowTypes, function(arrowType) {          
          var imageID = this.arrowImageIDTemplate({ direction: direction, type: arrowType });
          var arrowImage = new paper.Raster(imageID);
          arrowImage.scale(this.imageScales[this.imageScale]);
          this.arrowImages[imageID] = new paper.Symbol(arrowImage);
        }, this );
      }, this );
    },
    
    setFullScreen: function(value) {

      if( !BigScreen.enabled ) return;

      this.fullScreen = value;

      if( this.fullScreen ) {
        if (!BigScreen.element) {
          BigScreen.toggle( null, null, this.onExitFullScreen );
        }
      } else {
        if (BigScreen.element) {
          BigScreen.exit();
        }
        this.onExitFullScreen();
      }

    },
    
    onExitFullScreen: function() {  
    },
        
    getTime: function() {
      var d = new Date();
      return d.getTime();
    },
    
    logger: function( message ) {
      if( this.debugMode ) {
        console.log(message);
      }      
    },
    
    endRound: function() {
      
      // we must be in a round to accept input
      this.acceptingInput = false;

      // accumulate total response time
      var responseTime  = this.getTime() - this.roundStartTime;
      this.totalResponseTime = responseTime + this.totalResponseTime;
      
      if( this.roundPauseTimer ) {
        window.clearTimeout( this.roundPauseTimer );	
        this.roundPauseTimer = null;
      }

      if( this.roundTimer ) {
        window.clearTimeout( this.roundTimer );	
        this.roundTimer = null;
      }
             
    },
    
    displayAnswer: function( direction, answerStatus ) {
      this.logger(this.answerRecordedLogMessage( { status: answerStatus } ));
      
      var rightAnswer = this.rightAnswers[this.sequencePosition];

      this.arrowLayer.activate();
      
      // display the correct answer
      this.placeArrow(rightAnswer.srcImage, this.sequencePosition, 0 );
      
      // display the user's response
      var correctArrowID = this.arrowImageIDTemplate( {direction: direction, type: answerStatus } );
      this.placeArrow( this.arrowImages[correctArrowID], this.sequencePosition, 1 );

      paper.view.draw();          
    },  
    
    fadeOutArrows: function( arrows, afterRender ) {
      
      var fadeOut = function fadeOut( event ) {
        if( arrows.opacity > 0.0 ) {
          // fade out in 250ms at 60fps = 15 steps, fade 6.66% per step
          arrows.opacity = Math.max( arrows.opacity-0.0666, 0.0 );
        } else {
          paper.view.off( "frame", fadeOut );
          afterRender(); // callback after all done
        }
      };
            
      // fade out the item after flashTime milliseconds
      window.setTimeout( function() {
        paper.view.on( "frame", fadeOut );
      }, this.flashTime);
    },
    
    placeArrow: function( arrowImage, position, row ) {
      
      var scaledCenter = new paper.Point( paper.view.center.x * this.renderScale, (paper.view.center.y-50) * this.renderScale );
      var arrowWidth = arrowImage.definition.bounds.width;
      var arrowHeight = arrowImage.definition.bounds.height;
      var arrowsWidth = (this.numArrows*arrowWidth) + (this.spaceBetweenArrows * (this.numArrows-1));
      var xOffset = scaledCenter.x - (arrowsWidth/2);
      var xPosition = xOffset + (position * (arrowWidth + this.spaceBetweenArrows));
      
      var yOffset = scaledCenter.y;
      var yPosition = yOffset + (row * (arrowHeight + this.spaceBetweenArrows));
      
      return arrowImage.place( new paper.Point(xPosition, yPosition) );
    },
    
		displayNextSequence: function( afterRender ) {
            
      // choose arrows, clone prototypes, and place them on the screen in the correct order
      this.rightAnswers = [];
      for( var i=0; i < this.numArrows; i++ ) {
        
        // on first round, all arrows point right
        if( this.roundCount == 0 ) {
          var direction = 'right';
        } else {
          var direction = this.directions[_.random(0,this.directions.length-1)];    
        }
        
        var arrowImageID = this.arrowImageIDTemplate( {direction: direction, type: "q"} );
        var srcImage = this.arrowImages[arrowImageID];
        this.rightAnswers[i] = { direction: direction, srcImage: srcImage, correct: false };
      }
            
      this.arrowLayer.removeChildren();
      this.arrowLayer.activate();
      
      var arrows = [];
      
      _.each( this.rightAnswers, function( rightAnswer, i ) {
        // display with a delay       
        window.setTimeout( _.bind( function() {
          var arrow = this.placeArrow(rightAnswer.srcImage, i, 0 );
          this.bubbleSound.play();
          arrows.push(arrow);
          paper.view.draw();          
          
          // after the last arrow is drawn
          if( _.last(this.rightAnswers) == rightAnswer ) {
            
            // schedule fadeout
            var arrowGroup = new paper.Group( arrows );            
            this.fadeOutArrows( arrowGroup, afterRender );
          }
        }, this), 110 * i );
      }, this);
                        
		}
    					
	});
	
});
			
