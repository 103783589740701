import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as Edge from '../../../core';
import { AppState } from '../../../store';
import { withLoadDataDefaultConfig } from '../../../components/loadData';
import errorLoadingWrapperHOC from '../../../components/errorLoadingWrapper/errorLoadingWrapperHOC';
import { StatsService } from '../../../services/statsService';
import { TopPerformerRow } from './topPerformerRow';

import './index.scss';

export interface TopPerformersProps {
	currentTeam?: Edge.Models.Team;
	daysTimeSpan?: number;
	topPerformers: Edge.Models.TopPerformer[];
}

export const TopPerformers: React.FunctionComponent<TopPerformersProps> = (props) => {
	return (
		<div className="top_performers">
			<div className="title_row">
				<h4>Top Performers</h4>
				<div>Total Sessions</div>
				<div>Last Score</div>
			</div>
			{props.topPerformers.length === 0 ? (
				<div className="add_athletes">
					<Link to={`/manage-teams/${props.currentTeam!.id}`}>
						<span className="plus">+</span>Add athletes to team
					</Link>
				</div>
			) : (
				props.topPerformers
					.slice(0, 8)
					.map((topPerformer: Edge.Models.TopPerformer, index: number) => (
						<TopPerformerRow index={index + 1} topPerformer={topPerformer} key={index} />
					))
			)}
			{props.topPerformers.length === 0 ? null : (
				<div className="see_more">
					<Link to="/stats" className="cta_btn btn_light">
						See Full Team
					</Link>
				</div>
			)}
		</div>
	);
};

function mapStateToProps(state: AppState) {
	const { currentTeam } = state.teamState;
	const { timeSpan } = state.timeSpanState;
	return { currentTeam, daysTimeSpan: timeSpan && timeSpan.days };
}

export default connect(mapStateToProps)(
	withLoadDataDefaultConfig(
		errorLoadingWrapperHOC(TopPerformers, (props) => !!props.currentTeam && !!props.daysTimeSpan),
		(props) => {
			return { teamId: props.currentTeam!.id, daysTimeSpan: props.daysTimeSpan! };
		},
		async (props) => {
			const topPerformers = await StatsService.getTopPerformers(props.teamId, props.daysTimeSpan);
			return { topPerformers };
		}
	)
);
