import * as React from 'react';
import * as Edge from '../../../core';
import ExerciseResultLayout from '../exerciseResultLayout';
import { ExerciseMetadataService } from '../../../services/exerciseMetadataService';

export interface FlexibilityResultsProps {
	results: Edge.Models.FlexibilityExerciseResult;
	completeText: string;
	retry: () => void;
	complete: () => void;
	hideExerciseResults: boolean;
}

export class FlexibilityResults extends React.PureComponent<FlexibilityResultsProps> {
	constructor(props: FlexibilityResultsProps) {
		super(props);
		this.state = {};
	}
	public render() {
		const { complete, completeText, results, retry, hideExerciseResults } = this.props;
		const exercise = ExerciseMetadataService.getMetadata(results);
		return (
			<ExerciseResultLayout
				className="flexibility_results"
				exerciseName={(exercise && exercise.title) || 'Flexibility'}
				description={exercise && exercise.description}
				headerActions={
					<>
						<button className="cta_btn" onClick={retry}>
							Retry Exercise
						</button>
						<button className="cta_btn" onClick={complete}>
							{completeText}
						</button>
					</>
				}
				results={[results]}
				hideExerciseResults={hideExerciseResults}
			/>
		);
	}
}

export default FlexibilityResults;
